import React, { FC, useState, useEffect } from 'react';
import {
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
  Card,
  SCLogo,
  Button,
} from '@src/components/sc-design-system';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Stack, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import { useQueryParams } from '@src/utils';
import { AppLoader } from '@src/components/app/AppLoader';
import { EmployeeDetails } from './components/EmployeeDetails';
import { OverallRating } from './components/OverallRating';
import { GoalAttainment } from './components/GoalAttainment';
import { Competencies } from './components/Competencies';
import { ManagerEvaluation } from './components/ManagerEvaluation';
import { SelfEvaluation } from './components/SelfEvaluation';
import { generateReportDownload } from '@src/utils/reports';
import { useNavigation } from '@src/navigation';
import { AxiosError } from 'axios';

const PerformanceEvaluationReport: FC = () => {
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);
  const query = useQueryParams();
  const clientId = query.get('clientId');
  const performanceCycleId = query.get('performanceCycleId');
  const userId = query.get('userId');
  const userName = query.get('name');
  const navigation = useNavigation();

  const { data, isLoading, error } = useQuery({
    queryKey: [
      'client',
      clientId,
      'performance-cycle',
      performanceCycleId,
      'report',
      userId,
    ],
    queryFn: async () =>
      api.clients.getEmployeePerformanceEvaluationReport(
        clientId,
        performanceCycleId,
        userId,
      ),
    select: ({ data }) => data,
  });

  useEffect(() => {
    if ((error as AxiosError)?.response?.status === 403) {
      navigation.replace('/');
    }
  }, [error, navigation]);

  if (isLoading || !data) {
    return <AppLoader />;
  }

  function generateDownload() {
    // react is not disabling/setting the loading icon on the
    // download button so we need too wait 2 seconds to give that time
    setIsDownloadingPDF(true);
    setTimeout(function () {
      generateReportDownload(
        `SkillCycle Performance Evaluation for ${userName}.pdf`,
      );
      setIsDownloadingPDF(false);
    }, 2000);
  }

  const ratingScaleEnabled = data?.performance_cycle?.rating_scale_labels;
  const goalAttainmentEnabled =
    data?.performance_cycle?.goal_cycles &&
    data?.performance_cycle?.goal_cycles.length > 0;
  const selfEvaluationEnabled =
    data?.performance_cycle?.self_evaluation_form_id;
  const managerEvaluationEnabled =
    data?.performance_cycle?.manager_evaluation_form_id;

  return (
    <View>
      <ViewHeader
        title={`Performance Evaluation Report for ${userName}`}
        actions={[
          {
            render: (
              <Tooltip
                title='This request may take some time. Thank you for your patience.'
                disableFocusListener
                key='download-tooltip'
              >
                <span>
                  <Button
                    disabled={isDownloadingPDF}
                    loading={isDownloadingPDF}
                    onClick={generateDownload}
                    text='Download report'
                    startIcon={<SaveAltIcon fontSize='small' />}
                  />
                </span>
              </Tooltip>
            ),
          },
        ]}
      />
      <ViewBody>
        <ViewBodySection title='Report'>
          <Card bodyStyle={{ padding: '0 !important' }}>
            <Stack spacing={4} id='report' sx={{ padding: '2em' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <SCLogo showText size={52} />
              </div>
              <EmployeeDetails
                data={data}
                userName={userName}
                clientId={clientId}
              />
              {ratingScaleEnabled && <OverallRating data={data} />}
              {goalAttainmentEnabled && (
                <GoalAttainment data={data} userId={userId} />
              )}
              <Competencies
                clientId={clientId}
                performanceCycleId={performanceCycleId}
                userId={userId}
              />
              {managerEvaluationEnabled && <ManagerEvaluation data={data} />}
              {selfEvaluationEnabled && <SelfEvaluation data={data} />}
            </Stack>
          </Card>
        </ViewBodySection>
      </ViewBody>
    </View>
  );
};

export { PerformanceEvaluationReport };
