import { useQuery } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import { useActiveClient, ActiveClientKey } from './useActiveClient';
import { CompetencySet } from '@src/models/CompetencySet';
import User from '@src/models/User';

const useUserQueries = (userId: string) => {
  const { client: activeClient } = useActiveClient({
    queryKey: ActiveClientKey.profile_active_client_id,
  });
  const { client: adminClient } = useActiveClient({
    queryKey: ActiveClientKey.sc_admin_active_client_id,
  });

  const useGetUser = () => {
    const { data, ...rest } = useQuery({
      queryKey: ['user', userId],
      queryFn: async () => api.users.get(userId, activeClient?.id),
      select: ({ data }) => new User(data),
    });

    return {
      user: data ?? null,
      ...rest,
    };
  };

  const useGetUserGoalData = ({ enabled } = { enabled: true }) => {
    const { data, ...rest } = useQuery({
      queryKey: ['user', userId, 'goals'],
      queryFn: async () => api.users.getUserGoals(userId),
      enabled,
      select: ({ data }) => {
        return data?.filter(entity => {
          if (entity.type === 'client') {
            if (entity.client_id !== activeClient?.id) {
              return false;
            }
          } else {
            // Always add Personal Goals if not fetched from db
            if (!entity.goal_cycles.length) {
              entity.goal_cycles = [
                {
                  entityType: 'user',
                  name: 'Personal Goals',
                  goals: [],
                  description:
                    'Capture goals related to your job, your professional development or your personal growth here. These goals will remain private to you unless you share with coaches and mentors in your programs, or later align with company goals.',
                },
              ];
            }
          }
          return true;
        });
      },
    });

    return {
      performanceData: data?.find(entity => entity.type === 'client') ?? null,
      personalGrowthData: data?.find(entity => entity.type === 'user') ?? null,
      ...rest,
    };
  };

  const useGetUserGoalDataForCoach = (programId: string) => {
    const { data, ...rest } = useQuery({
      queryKey: ['user', userId, 'goals'],
      queryFn: async () => api.users.getUserGoals(userId),
      select: ({ data }) => data,
    });
    return {
      performanceData:
        data?.find(
          entity =>
            entity.type === 'client' &&
            entity.performance_cycles?.find(pc =>
              pc.goal_cycles?.find(gc =>
                gc.goals?.find(g => g.program_ids?.includes(programId)),
              ),
            ),
        ) ?? null,
      personalGrowthData: data?.find(entity => entity.type === 'user') ?? null,
      ...rest,
    };
  };

  const useGetUserGoalDataForAdmin = ({ enabled } = { enabled: true }) => {
    const { data, ...rest } = useQuery({
      queryKey: ['user', userId, 'goals'],
      queryFn: async () => api.users.getUserGoals(userId),
      enabled,
      select: ({ data }) => {
        return data?.filter(entity => {
          if (entity.type === 'client') {
            if (entity.client_id !== adminClient?.id) {
              return false;
            }
          } else {
            // Always add Personal Goals if not fetched from db
            if (!entity.goal_cycles.length) {
              entity.goal_cycles = [
                {
                  entityType: 'user',
                  name: 'Personal Goals',
                  goals: [],
                  description:
                    'Capture goals related to your job, your professional development or your personal growth here. These goals will remain private to you unless you share with coaches and mentors in your programs, or later align with company goals.',
                },
              ];
            }
          }
          return true;
        });
      },
    });

    return {
      performanceData: data?.find(entity => entity.type === 'client') ?? null,
      personalGrowthData: data?.find(entity => entity.type === 'user') ?? null,
      ...rest,
    };
  };

  const useGetUserCompetencySets = () => {
    const { data, ...rest } = useQuery({
      queryKey: ['user', userId, 'competency-sets'],
      queryFn: async () => api.goals_competencies.getSets('user', userId),
      select: ({ data }) => {
        return data?.reduce((arr, entity) => {
          if (entity?.competency_sets) {
            if (entity.id && entity.id !== activeClient?.id) {
              return arr;
            }
            entity.competency_sets.forEach(set =>
              arr.push(new CompetencySet(set)),
            );
          }
          return arr;
        }, []);
      },
    });

    return {
      competencySets: data ?? null,
      ...rest,
    };
  };

  const useGetUserPrograms = (
    role: 'coach' | 'coachee',
    options: { enabled?: boolean; includeClient?: boolean } = {},
  ) => {
    const defaultOptions = { enabled: true, includeClient: false };
    const { enabled, includeClient } = { ...defaultOptions, ...options };
    const { data, ...rest } = useQuery({
      queryKey: [
        'user',
        userId,
        'programs',
        role,
        includeClient ? activeClient?.id : null,
      ],
      queryFn: async () =>
        api.users.getPrograms(
          userId,
          role,
          includeClient ? activeClient?.id : undefined,
        ),
      select: ({ data }) => data,
      enabled: !!userId && !!activeClient?.id && enabled,
    });

    return {
      userPrograms: data ?? null,
      ...rest,
    };
  };

  const useGetUserProgramsForAdmin = (
    role: 'coach' | 'coachee',
    { enabled } = { enabled: true },
  ) => {
    const { data, ...rest } = useQuery({
      queryKey: ['user', userId, 'programs', role],
      queryFn: async () => api.users.getPrograms(userId, role, adminClient?.id),
      select: ({ data }) => data,
      enabled: !!userId && !!adminClient?.id && enabled,
    });

    return {
      userPrograms: data ?? null,
      ...rest,
    };
  };

  const useGetUserSurveySubmission = (
    params: {
      surveyId: string;
      email?: string;
      performance_cycle_id?: string;
      program_id?: string;
      collection_id?: string;
      notification_id?: string;
    },
    enabled = true,
  ) => {
    const { surveyId, ...optionalArgs } = params;
    const { data, ...rest } = useQuery({
      queryKey: ['user', userId, 'submission', surveyId, optionalArgs],
      queryFn: async () =>
        api.surveys.getSurveySubmission(userId, surveyId, optionalArgs),
      select: ({ data }) => data,
      enabled: !!surveyId && enabled,
    });

    return {
      surveySubmission: data ?? null,
      ...rest,
    };
  };

  const useGetUserAidaConversations = (
    clientId: string,
    role: ShareWith['role'],
  ) => {
    const { data, ...rest } = useQuery({
      queryKey: ['user', userId, 'conversations', clientId],
      queryFn: async () =>
        api.users.getUserAidaConversations(userId, { clientId, role }),
      select: ({ data }) => data,
      retry: 0,
    });

    return { conversations: data, ...rest };
  };

  return {
    useGetUser,
    useGetUserGoalData,
    useGetUserGoalDataForCoach,
    useGetUserGoalDataForAdmin,
    useGetUserCompetencySets,
    useGetUserPrograms,
    useGetUserProgramsForAdmin,
    useGetUserSurveySubmission,
    useGetUserAidaConversations,
  };
};

export { useUserQueries };
