import React from 'react';
import { useParams } from 'react-router';
import { useClientQueries } from '@src/hooks/useClientQueries';
import LoadingIcon from '@src/components/common/LoadingIcon';
import { Stack } from '@mui/material';
import { FeedbackForm } from './FeedbackForm';
import { FeedbackStatus } from './FeedbackStatus';

const Feedback: React.FunctionComponent = () => {
  const { id: clientId, performanceCycleId } = useParams();
  const { useGetPerformanceCycleById } = useClientQueries(clientId);
  const { performanceCycle, isLoading, refetch } =
    useGetPerformanceCycleById(performanceCycleId);
  return (
    <>
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <Stack spacing={5}>
          <FeedbackForm
            performanceCycle={performanceCycle}
            refetchPerformanceCycle={refetch}
          />
          <FeedbackStatus
            performanceCycle={performanceCycle}
            clientId={clientId}
          />
        </Stack>
      )}
    </>
  );
};

export { Feedback };
