import React, { FC, useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import Program, { AssessmentStakeholder } from '@src/models/Program';
import {
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
  Card,
} from '@src/components/sc-design-system';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import StakeholderManager from './Dashboards/StakeholderManager/StakeholderManager';
import { api } from '@src/lib/client';
import { useStore } from '@src/models/Store';
import { AppLoader } from '@src/components/app/AppLoader';
import { useNavigate } from 'react-router';

async function getProgramStakeholders(
  programId: string,
  memberId: string,
  setProgram: Function,
  setLoading?: Function,
) {
  if (setLoading) setLoading(true);
  try {
    const res = await api.programs.get360Stakeholders({ programId, memberId });
    setProgram(new Program(res.data));
    if (setLoading) setLoading(false);
  } catch (e) {
    if (setLoading) setLoading(false);
  }
}

const CoacheeProgram: FC = () => {
  const { user } = useStore();
  const { programId } = useParams();

  const [program, setProgram] = useState<{
    id: string;
    name: string;
    stakeholders: AssessmentStakeholder[];
    is_active: boolean;
  }>();
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    programId &&
      getProgramStakeholders(programId, user.id, setProgram, setLoading);
  }, [programId, user]);

  const updateLocalRecord = useCallback(() => {
    getProgramStakeholders(programId, user.id, setProgram);
  }, [programId, user]);

  if (loading) {
    return <AppLoader />;
  }

  return (
    <View>
      <ViewHeader
        title={program?.name}
        actions={[
          {
            onClick: () => navigate('/my-programs'),
            title: 'Back to My Programs',
          },
        ]}
      />
      <ViewBody>
        <ViewBodySection title='Overview'>
          <Card title='360 Feedback'>
            <Stack spacing={1}>
              <Typography variant='h4' sx={{ marginBottom: '1em' }}>
                Send Invites
              </Typography>
              <Typography variant='body1'>
                Use the space below to request 360 feedback from your
                colleagues. We have suggested stakeholders that will typically
                provide a good perspective on your performance. You may add and
                remove respondents as appropriate to your position.
              </Typography>
            </Stack>
            <Divider />
            <StakeholderManager
              programId={programId}
              memberId={user.id}
              assessmentStakeholders={program?.stakeholders ?? []}
              updateLocalRecord={updateLocalRecord}
              isActive={program.is_active}
            />
          </Card>
        </ViewBodySection>
      </ViewBody>
    </View>
  );
};

export default observer(CoacheeProgram);
