import React, { FC, useState } from 'react';
import { InputField, SelectField } from '@src/components/sc-design-system';
import { Form, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import usStates from '@src/lib/statics/us-states';
import caProvinces from '@src/lib/statics/ca-provinces';
import countries from '@src/lib/statics/countries';

interface AddressDataIF {
  street_address_1: string;
  street_address_2?: string;
  city: string;
  state: string;
  country?: string;
  zip?: string;
  type?: string;
}

const validate = address => {
  const valid =
    address?.street_address_1?.length > 0 &&
    address?.city?.length > 0 &&
    address?.state?.length === 2 &&
    address?.zip?.length > 0 &&
    address?.country?.length > 0;

  return valid;
};

const Address: FC<{ address: AddressDataIF; onChange: Function }> = ({
  address,
  onChange,
}) => {
  const { t } = useTranslation();
  const [currentAddress, setCurrentAddress] = useState({
    street_address_1: address?.street_address_1 || '',
    street_address_2: address?.street_address_2 || '',
    city: address?.city || '',
    state: address?.state,
    country: address?.country || '',
    zip: address?.zip || '',
    isValid: validate(address || {}),
    type: address?.type,
  });

  const onFieldChange = (name: string, val: any): void => {
    const current = currentAddress;
    current[name] = val;
    const updated = Object.assign({}, current, { isValid: validate(current) });
    setCurrentAddress(updated);
    onChange(updated);
  };
  return (
    <Form
      layout='vertical'
      className='address-form'
      style={{ marginTop: '1em' }}
    >
      <Row>
        <Col flex={1}>
          <InputField
            name='street_address_1'
            label={t('Street Address 1')}
            placeholder='Street Address 1'
            value={currentAddress.street_address_1}
            onChange={val => {
              onFieldChange('street_address_1', val);
            }}
          />
        </Col>
        <Col flex={1}>
          <InputField
            name='street_address_2'
            placeholder='Street Address 2'
            label={t('Street Address 2')}
            value={currentAddress.street_address_2}
            onChange={val => onFieldChange('street_address_2', val)}
          />
        </Col>
      </Row>
      <Row>
        <Col flex='1'>
          <InputField
            name='city'
            label={t('City')}
            value={currentAddress.city}
            onChange={val => onFieldChange('city', val)}
          />
        </Col>
        <Col flex='1'>
          <SelectField
            allowClear={true}
            hasFeedback={false}
            name='state'
            label={t('State/Province')}
            value={currentAddress.state}
            initialValue={currentAddress.state}
            options={[
              {
                label: 'United States',
                options: Object.keys(usStates).reduce((acc, prop) => {
                  acc.push({ label: usStates[prop], value: prop });
                  return acc;
                }, []),
              },
              {
                label: 'Canada',
                options: Object.keys(caProvinces).reduce((acc, prop) => {
                  acc.push({ label: caProvinces[prop], value: prop });
                  return acc;
                }, []),
              },
            ]}
            optionFilterProp='label'
            onChange={val => onFieldChange('state', val)}
          />
        </Col>
        <Col style={{ width: '10em' }}>
          <InputField
            name='zip'
            type='text'
            label={t('Zip')}
            value={currentAddress.zip}
            onKeyPress={e => {
              // This needs to be a text field, but only allow digits
              // to avoid issues with leading zeroes
              if (!e.key.match(/[0-9]/)) {
                e.preventDefault();
              }
            }}
            onChange={val => onFieldChange('zip', val)}
          />
        </Col>
        <Col flex='1'>
          <SelectField
            hasFeedback={false}
            allowClear
            name='country'
            label={t('Country')}
            value={currentAddress.country}
            initialValue={currentAddress.country}
            options={Object.keys(countries).reduce((acc, prop) => {
              acc.push({ label: countries[prop], value: prop });
              return acc;
            }, [])}
            optionFilterProp='label'
            onChange={val => onFieldChange('country', val)}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default Address;
