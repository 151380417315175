import React, { FC } from 'react';
import { Tooltip, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

const Icon = shared => {
  return shared ? (
    <CheckIcon color='success' fontSize='small' />
  ) : (
    <CloseIcon color='error' fontSize='small' />
  );
};

const WhoCanSeeThis: FC<{
  shared?: boolean;
  gcShared?: boolean;
  userRole: string;
  survey?: boolean;
  companyShared?: boolean;
  v: (word: string, capitalize?: boolean) => string; // dictionary
}> = ({
  shared = false,
  gcShared = false,
  userRole,
  survey = false,
  companyShared = false,
  v,
}) => (
  <Tooltip
    title={
      <div>
        <Stack direction='row' spacing={1} alignItems='center'>
          {Icon(shared)}
          <Typography variant='subtitle2'>
            My {userRole === 'coach' ? v('coachee', true) : v('coach', true)}
          </Typography>
        </Stack>
        <Stack direction='row' spacing={1} alignItems='center'>
          {Icon(companyShared)}
          <Typography variant='subtitle2'>
            {userRole === 'coach' && !survey
              ? `${v('coachee', true)}'s Company`
              : userRole === 'coach' && survey
              ? `${v('coachee', true)}'s Company (anonymized aggregation)`
              : survey
              ? 'My Company (anonymized aggregation)'
              : 'My Company'}
          </Typography>
        </Stack>
        <Stack direction='row' spacing={1} alignItems='center'>
          {Icon(gcShared)}
          <Typography variant='subtitle2'>SkillCycle</Typography>
        </Stack>
      </div>
    }
  >
    <Stack direction='row' spacing={1} alignItems='center'>
      <Typography variant='subtitle2'>Who can see this?</Typography>
      <InfoOutlined sx={{ width: '.7em', height: '.7em' }} color='action' />
    </Stack>
  </Tooltip>
);

export { WhoCanSeeThis };
