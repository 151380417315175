import { useQuery } from '@tanstack/react-query';
import { api } from '@src/lib/client';

const useResources = () => {
  const { data, ...rest } = useQuery({
    queryKey: ['resources'],
    queryFn: async () => api.resources.get(),
    select: ({ data }) => data,
  });
  return {
    resources: data ?? null,
    ...rest,
  };
};

export { useResources };
