import { HTTPClient } from '@src/lib/client';

export class GoalsCompetenciesAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async getNonAlignedGoals(id: string) {
    return this.http.request({
      url: `/goals-competencies/non-aligned-goals/get?id=${id}`,
      method: 'get',
    });
  }

  async updateGoalWeights(
    memberId: string,
    goals: Array<{ id: string; weight: number }>,
  ) {
    return this.http.request({
      url: `/goals-competencies/goals/update-weights?memberId=${memberId}`,
      method: 'patch',
      data: { goals },
    });
  }

  async getSets(type: string, id: string) {
    return this.http.request({
      url: `/goals-competencies/sets/get?type=${type}&id=${id}`,
      method: 'get',
    });
  }

  // Tee-hee
  async setSet(id: string, data) {
    return this.http.request({
      url: `/goals-competencies/set/set?id=${id}`,
      method: 'post',
      data,
    });
  }

  async deleteSet(id: string, competency_set_id: string) {
    return this.http.request({
      url: `/goals-competencies/set/delete?id=${id}&competency_set_id=${competency_set_id}`,
      method: 'delete',
    });
  }

  async saveGoal(data, performanceCycleId?: string) {
    return this.http.request({
      url: `/goals-competencies/goal/${data.id ? data.id : 'create'}`,
      method: data.id ? 'patch' : 'post',
      data: { ...data, performanceCycleId },
    });
  }

  async deleteGoal(id) {
    return this.http.request({
      url: `/goals-competencies/goal/${id}`,
      method: 'delete',
    });
  }

  async saveCompetency(data) {
    return this.http.request({
      url: `/goals-competencies/competency/${data.id ? data.id : 'create'}`,
      method: data.id ? 'patch' : 'post',
      data,
    });
  }

  async deleteCompetency(id) {
    return this.http.request({
      url: `/goals-competencies/competency/${id}`,
      method: 'delete',
    });
  }
}
