import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';

obey.creator('uuid', () => uuidv4());

export const ResourceViewSchema = {
  id: { type: 'string', required: true, creator: 'uuid' },
  type: { type: 'string' },
  resource_id: { type: 'string' },
  user_id: { type: 'string' },
  program_id: { type: 'string' },
  client_id: { type: 'string' },
  duration: { type: 'number' },
};

// eslint-disable-next-line no-unused-vars
export type ResourceViewType = { [K in keyof typeof ResourceViewSchema]?: any };

export const ResourceViewModel = obey.model(ResourceViewSchema);
