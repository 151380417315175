import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Table, Card } from '@src/components/sc-design-system';
import GoalStatusIndicator from '@src/components/common/GoalCard/GoalStatusIndicator';
import dayjs from 'dayjs';
import User from '@src/models/User';
import { GoalManagerDrawer } from '@src/components/common/GoalManagerDrawer';
import { GoalCycleDetailsStack } from '@src/components/common/GoalCycleDetailsStack';
import { Stack, Typography } from '@mui/material';

const GoalActivityCards: FC<{
  goalCycles: any[];
  user: User;
  clientId: string;
  onGoalStatusUpdate: Function;
}> = ({ goalCycles, user, clientId, onGoalStatusUpdate }) => {
  const [showAllGoalCycles, setShowAllGoalCycles] = useState(false);
  const [openDrawer, setOpenDrawer] = useState<false | 'edit' | 'add'>(false);
  const [currentGoal, setCurrentGoal] = useState(null);
  const [currentGoalCycleId, setCurrentGoalCycleId] = useState(null);
  const [numCycles, setNumCycles] = useState(0);

  const showDrawer = () => {
    setOpenDrawer('edit');
  };

  useEffect(() => {
    const numGC = goalCycles?.reduce((acc, gC) => {
      const userGoal = gC?.goals.find(
        g => g.user_ids && g.user_ids.includes(user?.id),
      );
      if (userGoal) {
        acc++;
      }
      return acc;
    }, 0);
    setNumCycles(numGC);
  }, [goalCycles, user?.id]);

  useEffect(() => {
    if (currentGoal) {
      const updatedGoalCycle = goalCycles.find(
        gC => gC.id === currentGoalCycleId,
      );

      const updatedGoal = updatedGoalCycle?.goals.find(
        g => g.id === currentGoal.id,
      );
      setCurrentGoal(updatedGoal);
    }
  }, [goalCycles, currentGoalCycleId, currentGoal]);

  return numCycles > 0 ? (
    <>
      {goalCycles
        ?.sort((a, b) => {
          if (
            dayjs().isBetween(a.start, a.end) ===
            dayjs().isBetween(b.start, b.end)
          ) {
            return b.end < a.end ? -1 : 1;
          } else {
            return dayjs().isBetween(a.start, a.end) ? -1 : 1;
          }
        })
        ?.map((gC, i) => {
          const goalsDataSource = gC.goals
            .map((g, i) => {
              g['key'] = i;
              return g;
            })
            .filter(g => {
              return g.user_ids && g.user_ids.length;
            });

          const columns = [
            {
              title: 'Goal Name',
              dataIndex: 'title',
              key: 'title',
            },
            {
              title: 'Type',
              dataIndex: 'type',
              key: 'type',
              render: text => {
                return (
                  <span>
                    {text === 'professional_development'
                      ? 'Professional Development'
                      : text.charAt(0).toUpperCase() + text.slice(1)}
                  </span>
                );
              },
            },
            {
              title: 'Status',
              dataIndex: 'current_status',
              key: 'current_status',
              render: (_, goal) => {
                const currentStatus =
                  goal.status_history?.[goal.status_history.length - 1];
                return <GoalStatusIndicator status={currentStatus?.status} />;
              },
            },
            {
              title: 'Target Date',
              dataIndex: ' target_date',
              key: ' target_date',
              render: (_, rec) => {
                return <span>{dayjs(rec.target_date).format('l')}</span>;
              },
            },
            {
              title: 'Aligned with Company Goal',
              dataIndex: 'aligned_goal_id',
              key: 'aligned_goal_id',
              render: (text, goal) => {
                let alignedGoal;
                if (goal.aligned_goal_id) {
                  alignedGoal = goalCycles
                    .find(gc => gc.id === goal.goal_cycle_id)
                    .goals.find(g => g.id === text);
                }

                return <span>{alignedGoal?.title}</span>;
              },
            },
            {
              title: 'Actions',
              dataIndex: 'id',
              key: 'id',
              render: (text, goal) => {
                return (
                  <Typography
                    variant='a2'
                    onClick={() => {
                      setCurrentGoal(goal);
                      setCurrentGoalCycleId(goal.goal_cycle_id);
                      showDrawer();
                    }}
                  >
                    View Goal
                  </Typography>
                );
              },
            },
          ];

          if (gC.goal_weighting_enabled) {
            columns.splice(3, 0, {
              title: 'Goal Weight',
              dataIndex: 'weight',
              key: 'weight',
              render: weight => <span>{weight || 0}%</span>,
            });
          }
          let numCyclesDisplayed = 0;
          if (
            goalsDataSource.length &&
            ((!showAllGoalCycles && numCyclesDisplayed < 2) ||
              showAllGoalCycles)
          ) {
            numCyclesDisplayed++;
            return (
              <Card key={i}>
                <div key={i}>
                  <Typography variant='h3' sx={{ marginBottom: '0.5em' }}>
                    {gC.name}
                  </Typography>
                  <GoalCycleDetailsStack
                    cycle={gC}
                    sx={{ marginBottom: '2em' }}
                    showStatusChip
                  />
                  <Table
                    dataSource={goalsDataSource}
                    columns={columns}
                    key={i}
                    pagination={false}
                    expandable={{
                      rowExpandable: record => record.description,
                      expandedRowRender: record => (
                        <div
                          className='description'
                          dangerouslySetInnerHTML={{
                            __html: record.description,
                          }}
                        />
                      ),
                    }}
                  />
                  {currentGoal && openDrawer && (
                    <GoalManagerDrawer
                      userId={user?.id}
                      clientId={clientId}
                      open={openDrawer}
                      onClose={() => {
                        setOpenDrawer(false);
                        setCurrentGoal(null);
                        setCurrentGoalCycleId(null);
                      }}
                      selectedGoalCycle={goalCycles.find(
                        gC => gC.id === currentGoal.goal_cycle_id,
                      )}
                      selectedGoal={currentGoal}
                      onSave={() => {
                        onGoalStatusUpdate();
                      }}
                      onDeleteGoal={() => {}}
                      entityType='team_manager'
                    />
                  )}
                </div>
              </Card>
            );
          }
        })}
      {2 < numCycles ? (
        <Stack direction='row' justifyContent='center'>
          <Typography
            variant='a2'
            onClick={() => {
              setShowAllGoalCycles(!showAllGoalCycles);
            }}
          >
            {showAllGoalCycles ? 'View Less' : 'View More'}
          </Typography>
        </Stack>
      ) : null}
    </>
  ) : (
    <Stack direction='row' justifyContent='center'>
      This member has not entered any goals in an organizational goal cycle.
    </Stack>
  );
};

export default observer(GoalActivityCards);
