import React from 'react';
import dayjs from 'dayjs';
import {
  Stack,
  Typography,
  Divider,
  Chip,
  Link,
  Alert,
  AlertTitle,
} from '@mui/material';
import LinearScale from '@mui/icons-material/LinearScale';
import PersonOutlined from '@mui/icons-material/PersonOutlined';
import { useQueryParams } from '@src/utils';
import { useNavigation } from '@src/navigation';
import User from '@src/models/User';
import { GreyHeaderBox } from '@src/components/sc-design-system';
import { ManagerRatingModal } from './ManagerRatingModal';
import { ViewRatingModal } from '@src/components/common/PerformanceCycle/ViewRatingModal';
import { SurveySubmissionModal } from '@src/components/common/SurveySubmissionModal';
import { useUserQueries } from '@src/hooks/useUserQueries';
import { useStore } from '@src/models/Store';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { EvaluationChip } from '@src/components/common/PerformanceCycle/EvaluationChip';
import { RatingChip } from '@src/components/common/PerformanceCycle/RatingChip';

interface EvaluationForProps {
  profileUser: User;
  selectedPerformanceCycle: PerformanceCycleI;
  clientId: string;
  performanceCycleResults: PerformanceCycleResultObj;
}

interface ManagerEvaluationProps extends EvaluationForProps {
  isCurrentUserManager: boolean;
  hasEvaluationPeriodEnded: boolean;
}

interface SelfRatingProps extends EvaluationForProps {
  isCurrentUserManager: boolean;
  hasEvaluationPeriodEnded: boolean;
}

function ManagerEvaluation(props: ManagerEvaluationProps) {
  const {
    profileUser,
    selectedPerformanceCycle,
    clientId,
    isCurrentUserManager,
    hasEvaluationPeriodEnded,
  } = props;

  const query = useQueryParams();
  const store = useStore();
  const navigation = useNavigation();
  const { useGetUserSurveySubmission } = useUserQueries(profileUser.id);
  const { useGetClientMembers } = useClientQueries(clientId);
  const [submissionModalOpen, setSubmissionModalOpen] = React.useState(false);

  const profileUserManagerId = React.useMemo(() => {
    return profileUser?.clients.find(c => c.id === clientId)?.manager_id;
  }, [profileUser, clientId]);

  const { members, isLoading: isMembersLoading } = useGetClientMembers(
    {},
    { enabled: !isCurrentUserManager },
  );

  const profileManagerEmail = React.useMemo(() => {
    if (isCurrentUserManager) return store.user.email;
    if (isMembersLoading) return null;
    const manager = members?.find(m => m.id === profileUserManagerId);
    return manager?.email || null;
  }, [
    members,
    isMembersLoading,
    isCurrentUserManager,
    store,
    profileUserManagerId,
  ]);

  const { surveySubmission } = useGetUserSurveySubmission({
    surveyId: selectedPerformanceCycle?.manager_evaluation_form_id,
    email: profileManagerEmail,
    performance_cycle_id: selectedPerformanceCycle.id,
  });

  return (
    <Stack width={{ md: '50%' }} spacing={2} padding='2em'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <PersonOutlined />
        <Typography>Evaluation of {profileUser.first_name}</Typography>
      </Stack>
      <EvaluationChip
        hasEvaluationPeriodEnded={hasEvaluationPeriodEnded}
        surveySubmission={surveySubmission}
      />
      {isCurrentUserManager && (
        <Typography color='grey'>
          Provide feedback for {profileUser.first_name} at the end of the
          performance cycle. You can save your evaluation and return to it
          later.
        </Typography>
      )}
      {(!surveySubmission || surveySubmission.is_draft) &&
        !hasEvaluationPeriodEnded &&
        isCurrentUserManager && (
          <Link
            onClick={() => {
              query.set(
                'survey_id',
                selectedPerformanceCycle?.manager_evaluation_form_id,
              );
              query.set('user_first_name', profileUser.first_name);
              query.set('user_last_name', profileUser.last_name);
              query.set('user_id', profileUser.id);
              query.set('email', profileManagerEmail);
              query.set('performance_cycle_id', selectedPerformanceCycle?.id);
              query.set('client_id', clientId);

              navigation.replace(`${navigation.location.pathname}?${query}`);
            }}
            sx={{ width: 'fit-content' }}
          >
            {surveySubmission?.is_draft ? 'Edit' : 'Start'}
          </Link>
        )}

      {surveySubmission && !surveySubmission?.is_draft && (
        <>
          {submissionModalOpen && (
            <SurveySubmissionModal
              surveySubmission={surveySubmission}
              onClose={() => setSubmissionModalOpen(false)}
              ratingType='manager'
              firstName={profileUser.first_name}
            />
          )}
          <Link
            onClick={() => {
              setSubmissionModalOpen(true);
            }}
            sx={{ width: 'fit-content' }}
          >
            View
          </Link>
        </>
      )}
    </Stack>
  );
}

function OverallRating(props: SelfRatingProps) {
  const {
    profileUser,
    selectedPerformanceCycle,
    performanceCycleResults,
    clientId,
    isCurrentUserManager,
    hasEvaluationPeriodEnded,
  } = props;
  const [openModal, setOpenModal] = React.useState(false);
  const [viewModal, setViewModal] = React.useState(false);
  return (
    <Stack spacing={2} width={{ md: '50%' }} padding='2em'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <LinearScale />
        <Typography>Overall rating for {profileUser.first_name}</Typography>
      </Stack>
      {!performanceCycleResults?.manager?.rating ? (
        <>
          <RatingChip hasEvaluationPeriodEnded={hasEvaluationPeriodEnded} />
          {isCurrentUserManager && (
            <>
              <Typography color='grey'>
                Provide a rating for {profileUser.first_name}'s performance
                during this cycle.
              </Typography>
              {!hasEvaluationPeriodEnded && (
                <>
                  <ManagerRatingModal
                    profileUser={profileUser}
                    performanceCycle={selectedPerformanceCycle}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    clientId={clientId}
                  />
                  <Link
                    sx={{ width: 'fit-content' }}
                    onClick={() => setOpenModal(true)}
                  >
                    Give rating
                  </Link>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <Chip
            color='success'
            label='Submitted'
            sx={{ width: 'fit-content' }}
          />
          {isCurrentUserManager && (
            <Typography color='grey'>
              Provide a rating for {profileUser.first_name}'s performance during
              this cycle.
            </Typography>
          )}
          <ViewRatingModal
            viewModal={viewModal}
            setViewModal={setViewModal}
            profileUser={profileUser}
            performanceCycle={selectedPerformanceCycle}
            rating={performanceCycleResults?.manager.rating}
            ratingType='manager'
          />
          <Link
            sx={{ width: 'fit-content' }}
            onClick={() => setViewModal(true)}
          >
            View
          </Link>
        </>
      )}
    </Stack>
  );
}

const EvaluationFor: React.FunctionComponent<EvaluationForProps> = props => {
  const { profileUser, clientId, selectedPerformanceCycle } = props;
  const store = useStore();
  const profileUserManagerId = React.useMemo(() => {
    return profileUser?.clients.find(c => c.id === clientId)?.manager_id;
  }, [profileUser, clientId]);
  const isCurrentUserManager = profileUserManagerId === store.user.id;
  const hasEvaluationPeriodEnded = dayjs().isAfter(
    dayjs(selectedPerformanceCycle.manager_evaluation_end),
  );

  return (
    <Stack spacing={1} gap={2} sx={{ marginBottom: '1.5em' }}>
      <Typography variant='h3'>
        Evaluation for {profileUser?.first_name}
      </Typography>
      {hasEvaluationPeriodEnded && (
        <Alert severity='info' sx={{ marginBottom: '.5em !important' }}>
          <AlertTitle>The evaluation period has ended.</AlertTitle>
          <Typography variant='body2'>All submissions are final.</Typography>
        </Alert>
      )}
      <GreyHeaderBox
        header={
          <Stack
            direction='row'
            divider={<Divider orientation='vertical' flexItem />}
            spacing={2}
          >
            <>
              <Typography variant='body1sb'>Evaluation open</Typography>
              <Typography variant='body1'>
                {dayjs(
                  selectedPerformanceCycle?.manager_evaluation_start,
                ).format('MMM D, YYYY')}
              </Typography>
            </>
            <>
              <Typography variant='body1sb'>Evaluation due</Typography>
              <Typography variant='body1'>
                {dayjs(selectedPerformanceCycle?.manager_evaluation_end).format(
                  'MMM D, YYYY',
                )}
              </Typography>
            </>
          </Stack>
        }
      >
        {selectedPerformanceCycle?.manager_evaluation_form_id ||
        Array.isArray(selectedPerformanceCycle?.rating_scale_labels) ? (
          <Stack
            direction={{ md: 'row', sm: 'column' }}
            divider={<Divider orientation='vertical' flexItem />}
          >
            {selectedPerformanceCycle?.manager_evaluation_form_id && (
              <ManagerEvaluation
                {...props}
                isCurrentUserManager={isCurrentUserManager}
                hasEvaluationPeriodEnded={hasEvaluationPeriodEnded}
              />
            )}
            {Array.isArray(selectedPerformanceCycle?.rating_scale_labels) &&
              selectedPerformanceCycle.rating_scale_labels.length > 0 && (
                <OverallRating
                  {...props}
                  isCurrentUserManager={isCurrentUserManager}
                  hasEvaluationPeriodEnded={hasEvaluationPeriodEnded}
                />
              )}
          </Stack>
        ) : (
          <div style={{ textAlign: 'center', padding: '2em' }}>
            <Typography variant='body1'>
              Your organization has not yet set up the performance evaluation
            </Typography>
          </div>
        )}
      </GreyHeaderBox>
    </Stack>
  );
};

export default EvaluationFor;
