import React, { FC, useMemo } from 'react';
import { List, ListItem, Stack, Typography } from '@mui/material';

const sx = { color: 'grey.700' };

const AnswerList = ({ answers }) => (
  <List sx={{ listStyleType: 'disc', pl: 2 }}>
    {answers.map((answer, idx) => (
      <ListItem
        key={idx}
        sx={{
          display: 'list-item',
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <Typography variant='body1' sx={sx}>
          {answer}
        </Typography>
      </ListItem>
    ))}
  </List>
);

const formatAnswers = (results = [], compiledAnswers = false) => {
  return results.reduce((acc, { question, answer }) => {
    if (!question || question === null || question.type === 'message') {
      return acc;
    }
    let answerRenderVal;
    if (question.type === 'multi-select' && answer) {
      const translatedAnswers = answer.map(a => {
        if (question.allow_other && a === 'other') {
          return 'Other';
        }
        return question.options.find(option => option.value === a)?.text;
      });
      answerRenderVal = <AnswerList answers={translatedAnswers} />;
    } else {
      let selectionText;
      if (question.type === 'single-select') {
        selectionText = question.options.find(
          option => option.value === answer,
        )?.text;
        if (!selectionText && question.allow_other && answer === 'other') {
          selectionText = 'Other';
        }
      }
      if (question.type === 'range' && question.range_labels?.length) {
        const label = question.range_labels.find(
          ({ value }) => value === answer,
        )?.text;
        selectionText = label || answer;
      }
      answerRenderVal = compiledAnswers ? (
        <AnswerList answers={answer} />
      ) : (
        <Typography variant='body1' sx={sx}>
          {answer == null ? 'No response provided' : selectionText || answer}
        </Typography>
      );
    }
    acc.push({
      id: question.id,
      title: question.title,
      description: answerRenderVal,
    });
    return acc;
  }, []);
};

interface SurveySubmissionStackProps {
  results: SurveySubmission['results'];
  compiledAnswers?: boolean;
  emptyState?: string;
}

const SurveySubmissionStack: FC<SurveySubmissionStackProps> = ({
  results,
  compiledAnswers = false,
  emptyState,
}) => {
  const formattedResults = useMemo(() => {
    return formatAnswers(results, compiledAnswers);
  }, [results, compiledAnswers]);

  return (
    <Stack spacing={2}>
      {formattedResults.length > 0 ? (
        formattedResults.map(r => {
          return (
            <Stack key={r.id} spacing={1}>
              <Typography variant='body1m'>{r.title}</Typography>
              {r.description}
            </Stack>
          );
        })
      ) : emptyState ? (
        <Typography variant='body1m' color='grey.700'>
          {emptyState}
        </Typography>
      ) : null}
    </Stack>
  );
};

export { SurveySubmissionStack };
