import React, { FC, ReactChild, useEffect, useState } from 'react';
import { api } from '@src/lib/client';
import ResourceClass from '@src/models/Resource';
import ReactPlayer from 'react-player';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { Button } from '@src/components/sc-design-system';
import { handleKeyboardNav } from '@src/utils';

const getNameFromUri = (name?: string) => {
  if (!name) return undefined;
  const trail = name.split('-').pop();
  const extension = trail.split('.').pop();
  return `${name.replace(`-${trail}`, '')}.${extension}`;
};

const ResourceViewer: FC<{
  resource: ResourceClass | Resource;
  trackView?: Function;
  children?: JSX.Element | ReactChild;
  maxWidth?: number;
}> = ({ resource, trackView, children, maxWidth }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const openedAt = Date.now();

  const getDocumentUrl = async (uri: string) => {
    const res = await api.files.getDownloadUrl(uri);
    return res.data;
  };

  useEffect(() => {
    if (resource.type === 'document') getDocumentUrl(resource.uri);
    return () => {};
  }, [resource]);

  const openResource = async () => {
    if (resource.new_window && resource.type !== 'document') {
      // Track view here since not opened in modal (no onclose event to track)
      trackView && trackView(resource, openedAt);
      window.open(resource.uri);
      return;
    }
    if (resource.type === 'document') {
      // Track view here since not opened in modal (no onclose event to track)
      trackView && trackView(resource, openedAt);
      const url = await getDocumentUrl(resource.uri);
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.download = getNameFromUri(resource.uri);
      link.click();
      return;
    }
    setModalOpen(true);
  };

  return (
    <>
      <Dialog
        onClose={() => {
          trackView && trackView(resource, openedAt);
          setModalOpen(false);
        }}
        open={modalOpen}
        maxWidth='lg'
      >
        <DialogContent>
          {resource.type === 'embed' ? (
            <div
              style={{
                backgroundColor: '#000',
                borderRadius: '.5em',
                overflow: 'hidden',
                marginTop: '1.5em',
                position: 'relative',
                paddingTop: '58%',
              }}
            >
              <ReactPlayer
                style={{ position: 'absolute', top: 0, left: 0 }}
                width={'100%'}
                height={'100%'}
                url={resource.uri}
                playing
                controls
              />
            </div>
          ) : (
            <iframe
              title={`iframe ${resource.uri}`}
              src={resource.uri}
              style={{
                border: '1px solid #ececec',
                borderRadius: '.4em',
                width: '100%',
                height: '75vh',
                marginTop: '1em',
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            text='Close'
            onClick={() => {
              trackView && trackView(resource, openedAt);
              setModalOpen(false);
            }}
          />
        </DialogActions>
      </Dialog>
      <span
        role='button'
        tabIndex={0}
        style={{
          cursor: 'pointer',
          maxWidth: maxWidth ? `${maxWidth}px` : undefined,
        }}
        onKeyDown={e => handleKeyboardNav(e.key, openResource)}
        onClick={async () => openResource()}
      >
        {children}
      </span>
    </>
  );
};

export default ResourceViewer;
