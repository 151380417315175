import React from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Typography, Stack } from '@mui/material';
import WYSIWYG from '@src/components/common/WYSIWYG';
import { Button, useToast, Select } from '@src/components/sc-design-system';
import {
  GoalStatuses,
  goalStatusUpdateOptions,
  goalAttainmentUpdateOptions,
} from '@shared/schemas';
import { capitalizeAllWords } from '@shared/lib/src/utils';

interface GoalStatusUpdateFormProps {
  setShowUpdateStatusForm: (b: boolean) => void;
  setUpdatedGoalStatus: (s) => void;
  updatedGoalStatus: GoalStatuses;
  setUpdatedGoalStatusNotes: (n) => void;
  updatedGoalStatusNotes: string;
  updateGoalStatus: () => void;
  goalCycle: GoalCycleWithGoals;
}

const GoalStatusUpdateForm: React.FunctionComponent<GoalStatusUpdateFormProps> =
  observer(function (props: GoalStatusUpdateFormProps) {
    const toast = useToast();
    const {
      setShowUpdateStatusForm,
      setUpdatedGoalStatus,
      updatedGoalStatus,
      setUpdatedGoalStatusNotes,
      updatedGoalStatusNotes,
      updateGoalStatus,
      goalCycle,
    } = props;

    React.useEffect(
      function () {
        if (!updatedGoalStatus) setUpdatedGoalStatus('not_started');
      },
      [updatedGoalStatus, setUpdatedGoalStatus],
    );

    const canEditGoalStatus = dayjs().isSameOrBefore(
      goalCycle.goal_evaluation_start,
    );

    const selectFieldOptions = React.useMemo(() => {
      return [
        {
          label: 'Update Goal Status',
          options: goalStatusUpdateOptions.map(s => ({
            label: capitalizeAllWords(s, '_'),
            value: s,
            disabled: !canEditGoalStatus,
          })),
        },
        {
          label: 'Evaluate Goal Attainment',
          options: goalAttainmentUpdateOptions.map(a => ({
            label: capitalizeAllWords(a, '_'),
            value: a,
          })),
        },
      ];
    }, [canEditGoalStatus]);

    return (
      <div style={{ padding: '0 32px' }}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='h5' sx={{ marginBottom: 0 }}>
            Update Goal Status
          </Typography>
          <Select
            sx={{ width: '325px' }}
            defaultValue={updatedGoalStatus}
            value={updatedGoalStatus}
            onChange={event => setUpdatedGoalStatus(event.target.value)}
            options={selectFieldOptions}
          />
        </Stack>
        <Stack sx={{ margin: '1.5em 0' }}>
          <WYSIWYG
            value={updatedGoalStatusNotes}
            onChange={setUpdatedGoalStatusNotes}
            placeholder='Add comments about goals and action plans for next steps...'
          />
        </Stack>
        <Stack
          direction='row'
          justifyContent='flex-end'
          spacing={1}
          sx={{ marginBottom: '1em' }}
        >
          <Button
            variant='outlined'
            text='Cancel'
            onClick={() => setShowUpdateStatusForm(false)}
          />
          <Button
            text='Update'
            disabled={!updatedGoalStatus}
            onClick={async () => {
              try {
                await updateGoalStatus();
                setUpdatedGoalStatusNotes(undefined);
              } catch (error) {
                toast.error('Unable to update goal status');
              }
            }}
          />
        </Stack>
      </div>
    );
  });

export default GoalStatusUpdateForm;
