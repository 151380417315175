import React, { FC } from 'react';
import {
  TableState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  PaginationState,
  useReactTable,
  TableOptions,
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import { TableProps as MUITableProps } from '@mui/material/Table';
import { Spin } from './Spin';

// TODO: dial in types
export interface TableProps extends MUITableProps {
  data: any[];
  columns: any[];
  tableState?: Partial<TableState>;
  isFilterable?: boolean;
  initialRowsPerPage?: number;
  dataIsLoading?: boolean;
  emptyState?: string;
  // selectedRows set should contain a list of identifiers needed to determine if a row should be selected
  // it will usually corresponds with selecting rows in the table
  selectedRows?: Set<string>;
  // selectedRowProp will default to id but you can pass email or another data prop identifier
  selectedRowProp?: string;
}

// TODO: expandable rows
// https://tanstack.com/table/latest/docs/api/features/expanding
// https://mui.com/material-ui/react-table/#collapsible-table

// https://tanstack.com/table/latest/docs/framework/react/examples/filters
const filterRows = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({ itemRank });
  return itemRank.passed;
};

const TableMUI: FC<TableProps> = ({
  data,
  columns,
  sx,
  isFilterable,
  initialRowsPerPage,
  tableState = {},
  dataIsLoading = false,
  emptyState,
  selectedRows = new Set(),
  selectedRowProp = 'id',
}) => {
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: initialRowsPerPage,
  });
  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination(Object.assign({}, pagination, { pageIndex: newPage }));
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPagination({ pageIndex: 0, pageSize: parseInt(event.target.value, 10) });
  };
  const useReactTableOptions: TableOptions<any> = {
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    ...(isFilterable && {
      getFilteredRowModel: getFilteredRowModel(),
      filterFns: { fuzzy: filterRows },
      globalFilterFn: filterRows,
    }),
    state: { ...tableState },
  };

  const paginationEnabled = initialRowsPerPage >= 0;

  if (paginationEnabled) {
    useReactTableOptions.getPaginationRowModel = getPaginationRowModel();
    useReactTableOptions.onPaginationChange = setPagination;
    useReactTableOptions.state.pagination = pagination;
  }

  const table = useReactTable(useReactTableOptions);

  function EmptyState() {
    return (
      <div style={{ textAlign: 'center', padding: '2m' }}>
        <Typography variant='body2' color='grey.700'>
          {emptyState || 'No records found'}
        </Typography>
      </div>
    );
  }

  return (
    <TableContainer>
      <Table sx={sx}>
        <TableHead>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const fitContent = header.column.columnDef?.meta?.fitContent;
                const sx = fitContent
                  ? { maxWidth: header.getSize(), width: header.getSize() }
                  : { width: 'auto' };
                return (
                  <TableCell key={header.id} sx={sx} colSpan={header.colSpan}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        {dataIsLoading || (Array.isArray(data) && data.length === 0) ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={columns.length}>
                {dataIsLoading && <Spin sectionLoader />}
                {!dataIsLoading && Array.isArray(data) && data.length === 0 && (
                  <EmptyState />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {table.getRowModel().rows.map(row => {
              return (
                <TableRow
                  key={row.id}
                  sx={{
                    backgroundColor: selectedRows?.has(
                      row?.original?.[selectedRowProp],
                    )
                      ? 'grey.100'
                      : 'inherit',
                  }}
                >
                  {row.getVisibleCells().map(cell => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
            {paginationEnabled && (
              <TableRow>
                <TablePagination
                  component='td'
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  count={data.length}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageIndex}
                  SelectProps={{
                    IconComponent: KeyboardArrowDown,
                    sx: { fontSize: '14px', marginTop: '0.3em' },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export { TableMUI };
