import React from 'react';
import ReactMarkdown from 'react-markdown';
import { observer } from 'mobx-react';
import { Stack } from '@mui/material';

import { CoachesFollowUp } from './CoachesFollowUp';
import { ResourcesFollowUp } from './ResourcesFollowUp';
import { SummaryInputTasks } from './SummaryInputTasks';
import { useUserQueries } from '@src/hooks/useUserQueries';
import { useUser } from '@src/hooks/useUser';
import { GuidedAida } from './GuidedAida';
import { OpenEndedAida } from './OpenEndedAida';
import { CompleteStepsAlert } from './CompleteStepsAlert';

interface AidaPromptProps {
  handlePromptSelection: HandlePromptSelection;
  selectedPrompt: Prompts;
  userDisplayMessage: string;
  assistantMessage: AidaMessage;
  setAssistantMessage: (m: AidaMessage) => void;
  setUserDisplayMessage: (m: string) => void;
  isAssistantMessageLoading: boolean;
  conversations: Conversations;
  aidaError: boolean;
  fetchConversations: Function;
  setAidaError: (b: boolean) => void;
  setExistingAidaRequest: (a: AbortController) => void;
  activeClientMember: { title: string; department: string; name: string };
  clientId: string;
}

const AidaPrompt: React.FunctionComponent<AidaPromptProps> = observer(props => {
  const {
    handlePromptSelection,
    selectedPrompt,
    userDisplayMessage,
    assistantMessage,
    setAssistantMessage,
    setUserDisplayMessage,
    isAssistantMessageLoading,
    conversations,
    aidaError,
    fetchConversations,
    setAidaError,
    setExistingAidaRequest,
    activeClientMember,
    clientId,
  } = props;

  const { user } = useUser();
  const { useGetUserPrograms } = useUserQueries(user.id);
  const {
    userPrograms: userProgramsData,
    isLoading: isUserProgramsDataLoading,
  } = useGetUserPrograms('coachee');

  const isCoachingAvailable = React.useMemo(() => {
    if (isUserProgramsDataLoading) return false;
    return userProgramsData?.some(
      p => p.type !== 'platform_only' && p.is_active,
    );
  }, [userProgramsData, isUserProgramsDataLoading]);

  const activeProgramNoCoach = React.useMemo(() => {
    if (!isUserProgramsDataLoading && isCoachingAvailable) {
      return userProgramsData?.find(p => p.is_active && !p.coach);
    }
  }, [userProgramsData, isUserProgramsDataLoading, isCoachingAvailable]);

  let initialResponse, followUpContent, footerContent;

  switch (selectedPrompt) {
    case 'resources':
      initialResponse = assistantMessage?.summary;
      followUpContent = (
        <ResourcesFollowUp assistantMessage={assistantMessage} />
      );
      break;

    case 'coaches':
      initialResponse = assistantMessage?.summary ? (
        <ReactMarkdown children={assistantMessage?.summary} />
      ) : null;
      followUpContent = (
        <>
          {(!conversations?.summary || !conversations?.focus_areas) && (
            <CompleteStepsAlert
              selectedPrompt={selectedPrompt}
              conversations={conversations}
              handlePromptSelection={handlePromptSelection}
              setUserDisplayMessage={setUserDisplayMessage}
              setAssistantMessage={setAssistantMessage}
            />
          )}
          <CoachesFollowUp
            assistantMessage={assistantMessage}
            isCoachingAvailable={isCoachingAvailable}
            programType={activeProgramNoCoach?.type}
            programId={activeProgramNoCoach?.id}
          />
        </>
      );
      break;

    case 'summary':
      initialResponse = <ReactMarkdown children={assistantMessage?.summary} />;
      footerContent = <SummaryInputTasks />;
      break;

    default:
      initialResponse = <ReactMarkdown children={assistantMessage?.summary} />;
      break;
  }

  const shareWith = React.useMemo(
    function () {
      let share = [];
      if (user?.clients) {
        user.clients.forEach(c => {
          if (
            c.id === clientId &&
            c.manager_id &&
            c.manager_first_name &&
            c.manager_last_name
          ) {
            share.push({
              id: c.manager_id,
              role: 'manager',
              first_name: c.manager_first_name,
              last_name: c.manager_last_name,
            });
          }
        });
      }
      if (!isUserProgramsDataLoading && Array.isArray(userProgramsData)) {
        userProgramsData.forEach(p => {
          if (p.is_active && p.coach) {
            share.push({
              id: p.coach.id,
              role: p.type === 'mentoring' ? 'mentor' : 'coach',
              first_name: p.coach.first_name,
              last_name: p.coach.last_name,
            });
          }
        });
      }
      return share;
    },
    [userProgramsData, isUserProgramsDataLoading, user, clientId],
  );

  const shareCount = React.useMemo(
    function () {
      if (user?.aida_share) {
        try {
          return shareWith.filter(s => {
            return user.aida_share[selectedPrompt].find(
              aS => aS.user_id === s.id,
            );
          }).length;
        } catch (error) {
          return 0;
        }
      }
    },
    [user?.aida_share, shareWith, selectedPrompt],
  );

  return (
    <Stack spacing={3} display='grid' marginTop='2em'>
      {selectedPrompt !== 'ask' ? (
        <GuidedAida
          selectedPrompt={selectedPrompt}
          handlePromptSelection={handlePromptSelection}
          userDisplayMessage={userDisplayMessage}
          setUserDisplayMessage={setUserDisplayMessage}
          assistantMessage={assistantMessage}
          setAssistantMessage={setAssistantMessage}
          isAssistantMessageLoading={isAssistantMessageLoading}
          aidaError={aidaError}
          setAidaError={setAidaError}
          conversations={conversations}
          fetchConversations={fetchConversations}
          followUpContent={followUpContent}
          initialResponse={initialResponse}
          footerContent={footerContent}
          shareWith={shareWith}
          shareCount={shareCount}
        />
      ) : (
        <OpenEndedAida
          aidaError={aidaError}
          setAidaError={setAidaError}
          footerContent={footerContent}
          clientId={clientId}
          activeClientMember={activeClientMember}
          setExistingAidaRequest={setExistingAidaRequest}
          conversations={conversations}
          handlePromptSelection={handlePromptSelection}
          setUserDisplayMessage={setUserDisplayMessage}
          setAssistantMessage={setAssistantMessage}
        />
      )}
    </Stack>
  );
});

export { AidaPrompt };
