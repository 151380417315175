import React from 'react';
import { Typography } from '@mui/material';

interface MessageProps {
  type: keyof typeof messages;
}

const messages = {
  error: 'Please try again later.',
  aggregateCompetencyResultsEmpty:
    'There is no data available for this section.',
  selfAssessmentEmpty: 'Self-assessment responses have not been submitted yet.',
  stakeholderEmpty:
    'Stakeholder assessment responses have not been submitted yet.',
};

export function Message({ type }: MessageProps) {
  return (
    <div style={{ textAlign: 'center' }}>
      <Typography variant='body1'>{messages[type]}</Typography>
    </div>
  );
}
