import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Button } from '@src/components/sc-design-system';
import { StyledCard } from '../../../../StyledComponents';

interface RecipientsSectionProps {
  isDraftMode: boolean;
  onButtonClick: Function;
  isSaving?: boolean;
}

const RecipientsSection: FC<RecipientsSectionProps> = ({
  isDraftMode,
  onButtonClick,
  isSaving,
}) => {
  const { watch } = useFormContext();
  const recipients = watch('recipients');
  const filteredRecipients = isDraftMode
    ? recipients
    : recipients?.filter(r => !r.last_sent);
  const isEmpty = !filteredRecipients.length;

  return (
    <div>
      <Typography variant='h4' sx={{ marginBottom: 1 }}>
        Recipients
      </Typography>
      <StyledCard style={isEmpty ? { textAlign: 'center' } : null}>
        <Stack spacing={2}>
          {isEmpty ? (
            <>
              {isDraftMode && (
                <Typography variant='body1'>
                  Invite registered members to take your survey
                </Typography>
              )}
              <div>
                <Button
                  startIcon='add'
                  text='Add recipients'
                  onClick={() => {
                    onButtonClick();
                  }}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Stack gap='.5em'>
                <Typography variant='h5'>
                  {filteredRecipients.length} total{' '}
                  {filteredRecipients.length === 1 ? 'recipient' : 'recipients'}
                </Typography>
                <Typography variant='body1'>
                  {filteredRecipients.length} registered{' '}
                  {filteredRecipients.length === 1 ? 'member' : 'members'}
                </Typography>
              </Stack>
              <Button
                variant='text'
                text='Edit'
                onClick={() => onButtonClick()}
                disabled={isSaving}
              />
            </div>
          )}
        </Stack>
      </StyledCard>
    </div>
  );
};

export { RecipientsSection };
