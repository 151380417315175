import React, { FC, useMemo, useState } from 'react';
import {
  Card,
  ListGridToggle,
  useLayoutToggle,
  Button,
} from '@src/components/sc-design-system';
import { GoalTooltip } from '@src/components/common/GoalTooltip';
import { GoalCycleDetailsStack } from '@src/components/common/GoalCycleDetailsStack';
import './GoalCycleCard.less';
import { Stack, Tabs, Tab, Typography } from '@mui/material';
import { GoalWeightingModal } from '../GoalWeightingModal';

interface GoalCycleCardProps {
  cycle: GoalCycle;
  performanceCycleId?: string;
  view: 'user' | 'client';
  userId?: string;
  titleActions?: JSX.Element;
  defaultOpen?: boolean;
  children?: React.ReactNode;
  subheaderActions?: JSX.Element;
  showStatusChip?: boolean;
  showGoalWeightButton?: boolean;
  tabDefinitions?: any[];
  onChangeLayout?: Function;
  onChangeTab?: Function;
  refetchGoals: Function;
}

const GoalCycleCard: FC<GoalCycleCardProps> = ({
  cycle,
  view,
  userId,
  defaultOpen = true,
  titleActions,
  subheaderActions = null,
  showStatusChip,
  performanceCycleId,
  tabDefinitions,
  onChangeLayout,
  onChangeTab,
  showGoalWeightButton,
  refetchGoals,
  children,
}) => {
  const [isGoalWeightModalOpen, setIsGoalWeightModalOpen] = useState(false);
  const [layoutType, setLayoutType] = useLayoutToggle(cycle.id);
  const tabs = useMemo(() => {
    if (!tabDefinitions || !tabDefinitions.length) return null;
    return tabDefinitions;
  }, [tabDefinitions]);
  const [activeTab, setActiveTab] = useState<string | null>(null);

  const handleLayoutChange = key => {
    setLayoutType(key);
    onChangeLayout(key);
  };
  return (
    <Card
      title={cycle.name}
      collapsible
      defaultOpen={defaultOpen}
      variant='elevation'
      titleActions={
        Boolean(performanceCycleId) && view === 'user' ? (
          <GoalTooltip type='organization' chipSx={{ margin: 0 }} />
        ) : view === 'client' ? (
          titleActions
        ) : null
      }
      subheader={
        <GoalCycleDetailsStack
          cycle={cycle}
          actions={subheaderActions}
          showStatusChip={showStatusChip}
        />
      }
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ marginBottom: '1em' }}
      >
        {tabs ? (
          <Tabs
            value={activeTab || tabs[0].label}
            onChange={(_, v) => {
              setActiveTab(v);
              onChangeTab(v);
            }}
          >
            {tabs.map(tab => (
              <Tab key={tab.key} label={tab.label} value={tab.label} />
            ))}
          </Tabs>
        ) : (
          <Typography variant='h3'>Goals</Typography>
        )}
        <Stack direction='row' alignItems='center' sx={{ marginLeft: 'auto' }}>
          {showGoalWeightButton && (
            <Button
              text='Manage Goal Weighting'
              variant='text'
              size='small'
              onClick={() => {
                setIsGoalWeightModalOpen(true);
              }}
            />
          )}
          <ListGridToggle
            layoutType={layoutType}
            onChange={handleLayoutChange}
          />
        </Stack>
      </Stack>
      {children}
      {isGoalWeightModalOpen && (
        <GoalWeightingModal
          userId={userId}
          goalCycle={cycle}
          onClose={() => setIsGoalWeightModalOpen(false)}
          open={isGoalWeightModalOpen}
          onGoalUpdate={() => {
            refetchGoals();
          }}
        />
      )}
    </Card>
  );
};

export { GoalCycleCard };
