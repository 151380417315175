import React, { FC } from 'react';
import { useParams } from 'react-router';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import InsightsIcon from '@mui/icons-material/Insights';
import {
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
} from '@src/components/sc-design-system';
import { ClientSurveys } from './tabs/Surveys';
import Insights from './tabs/Insights/Insights';

const SurveysAndInsights: FC = () => {
  const { client_id } = useParams();

  return (
    <View>
      <ViewHeader
        title='Surveys & Insights'
        titleIcon={<FeedOutlinedIcon fontSize='large' />}
      />
      <ViewBody>
        <ViewBodySection title='Insights' icon={<InsightsIcon />} fullScreen>
          <Insights />
        </ViewBodySection>
        <ViewBodySection title='Surveys' icon={<FeedOutlinedIcon />}>
          <ClientSurveys clientId={client_id} />
        </ViewBodySection>
      </ViewBody>
    </View>
  );
};

export { SurveysAndInsights };
