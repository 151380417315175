import type { HTTPClient } from '@src/lib/client';
import type { PlatformConfig } from '@shared/schemas';
export type { PlatformConfig };

export class PlatformAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async getConfiguration(environment: string): Promise<PlatformConfig> {
    const res = await this.http.request({
      url: `/platform/config`,
      method: 'get',
      params: {
        environment,
      },
    });
    return res.data;
  }
}
