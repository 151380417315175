import React, { FC, KeyboardEvent, FocusEvent, ReactNode } from 'react';
import { Form, Input, InputNumber } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { Typography } from '@mui/material';

export interface InputFieldPropsIF {
  name?: string;
  required?: boolean;
  label?: string | ReactNode;
  placeholder?: string;
  type?: string;
  value: string | number | readonly string[];
  rules?: Array<any>;
  addonBefore?: string;

  onChange: Function;
  onKeyPress?: Function;
  onFocus?: Function;
  onBlur?: Function;
  formatter?: Function;
  autoFocus?: boolean;
  mask?: string;
  min?: number;
  max?: number;
  disabled?: boolean;
  validateStatus?: any;
  validateTrigger?: any;
  step?: string;
  precision?: number;
  propagateChangeEvent?: boolean;
  style?: object;
}

const InputField: FC<InputFieldPropsIF> = props => {
  const {
    name,
    label,
    placeholder,
    type,
    value,
    rules,
    onChange,
    onKeyPress,
    onBlur,
    onFocus,
    mask,
    min,
    max,
    disabled = false,
    step,
    precision,
    validateStatus,
    validateTrigger,
    addonBefore,
    propagateChangeEvent = false,
    style,
  } = props;
  const appliedRules = rules || [];
  const appliedValidateTrigger = validateTrigger || ['onChange', 'onBlur'];
  return (
    <Form.Item
      label={label}
      name={name || label?.toString()}
      rules={appliedRules}
      validateTrigger={appliedValidateTrigger}
      help={
        <Typography variant='body2' color='error'>
          {validateStatus}
        </Typography>
      }
      initialValue={value}
    >
      {mask ? (
        <MaskedInput
          mask={mask}
          name={name}
          value={value.toString()}
          onChange={(e: { target: HTMLInputElement }) => {
            onChange(e.target.value);
          }}
          disabled={disabled}
          style={style}
        />
      ) : type === 'password' ? (
        <Input.Password
          onKeyPress={(e: KeyboardEvent) => onKeyPress && onKeyPress(e)}
          onFocus={(e: FocusEvent) => onFocus && onFocus(e)}
          onBlur={(e: FocusEvent) => onBlur && onBlur(e)}
          value={value}
          placeholder={
            placeholder ? placeholder : typeof label === 'string' ? label : name
          }
          onChange={(e: any) => {
            onChange(e.target.value);
          }}
          disabled={disabled}
          addonBefore={addonBefore}
          style={style}
        />
      ) : type === 'number' ? (
        <InputNumber
          onKeyPress={(e: KeyboardEvent) => onKeyPress && onKeyPress(e)}
          onFocus={(e: FocusEvent) => onFocus && onFocus(e)}
          onBlur={(e: FocusEvent) => onBlur && onBlur(e)}
          value={+value}
          placeholder={
            placeholder ? placeholder : typeof label === 'string' ? label : name
          }
          onChange={val => {
            onChange(val);
          }}
          min={min || 0}
          max={max}
          controls={false}
          disabled={disabled}
          addonBefore={addonBefore}
          step={step}
          precision={precision}
          style={style}
        />
      ) : (
        <Input
          onKeyPress={(e: KeyboardEvent) => onKeyPress && onKeyPress(e)}
          onFocus={(e: FocusEvent) => onFocus && onFocus(e)}
          onBlur={(e: FocusEvent) => onBlur && onBlur(e)}
          type={type}
          value={value || ''}
          placeholder={
            placeholder ? placeholder : typeof label === 'string' ? label : name
          }
          onChange={(e: any) => {
            if (propagateChangeEvent) {
              onChange(e);
            } else {
              onChange(type === 'number' ? +e.target.value : e.target.value);
            }
          }}
          min={min}
          max={max}
          disabled={disabled}
          addonBefore={addonBefore}
          name={name}
          style={style}
        />
      )}
    </Form.Item>
  );
};

export { InputField };
