import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export function generateReportDownload(filename) {
  const data = document.getElementById('report');
  html2canvas(data, {
    useCORS: true,
    backgroundColor: null,
    onclone(clone) {
      const report = clone.getElementById('report');
      const chips = Array.from(
        report.getElementsByClassName(
          'MuiChip-root',
        ) as HTMLCollectionOf<HTMLElement>,
      );
      // Chip labels are not rendered correctly with the filled variant,
      // so remove the background as a workaround
      chips.forEach(chip => {
        chip.style.background = 'transparent';
        chip.style.color = 'black';
      });
    },
  }).then((c: any) => {
    const imgWidth = 208;
    const pageHeight = 295;
    const imgHeight = (c.height * imgWidth) / c.width;
    let heightRemaining = imgHeight;
    let position = 0;
    heightRemaining -= pageHeight;
    const doc = new jsPDF('p', 'mm');
    doc.addImage(c, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
    while (heightRemaining >= 0) {
      position = heightRemaining - imgHeight;
      doc.addPage();
      doc.addImage(c, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      heightRemaining -= pageHeight;
    }
    doc.save(filename);
  });
}
