import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import _groupBy from 'lodash/groupBy';
import _toPairs from 'lodash/toPairs';
import {
  Select,
  MenuItem,
  ListSubheader,
  IconButton,
  Typography,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import {
  UseFormReturn,
  Controller,
  ControllerRenderProps,
} from 'react-hook-form';
import { PublishedSurveyType } from '@shared/schemas';

interface FeedbackFormFormProps {
  onSubmit: (data: OngoingFeedbackFormOnSubmitProps) => void;
  form: UseFormReturn<OngoingFeedbackFormFormDefaultValues, undefined>;
  surveys: PublishedSurveyType[];
}

const FeedbackFormForm: FC<FeedbackFormFormProps> = ({
  onSubmit,
  form,
  surveys,
}) => {
  const [isSelectOpen, setIsSelectOpen] = React.useState(false);

  const surveyOptions = React.useMemo(() => {
    const surveyGroups = _toPairs(_groupBy(surveys, s => s.survey_id));
    return surveyGroups
      .map(([_, versions]) => ({
        label: versions[0].title,
        options:
          versions.length > 1
            ? versions
                .sort((a, b) => b.published_at - a.published_at)
                .map((s, idx) => {
                  const optionLabel = isSelectOpen
                    ? dayjs(s.published_at).format('dddd, MMM DD, YYYY, h:mm A')
                    : s.title;
                  return {
                    label: `${optionLabel} ${
                      idx === 0 && versions.length > 1 && isSelectOpen
                        ? ' (LATEST)'
                        : ''
                    }`,
                    value: s.id,
                  };
                })
            : null,
        value: versions.length === 1 ? versions[0].id : null,
      }))
      .sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });
  }, [isSelectOpen, surveys]);

  const { control, handleSubmit } = form;

  return (
    <form onSubmit={handleSubmit(onSubmit)} id='feedback-form-form'>
      <Controller
        name='ongoing_feedback_form_id'
        control={control}
        render={({ field }) => {
          return (
            <SurveySelect
              field={field}
              setIsSelectOpen={setIsSelectOpen}
              surveyOptions={surveyOptions}
              form={form}
            />
          );
        }}
      />
    </form>
  );
};

interface SurveySelectProps {
  field: ControllerRenderProps<
    OngoingFeedbackFormFormDefaultValues,
    'ongoing_feedback_form_id'
  >;
  setIsSelectOpen: Function;
  surveyOptions: Array<{
    label: string;
    options?: Array<{ label: string; value: string }> | null;
    value?: string | null;
  }>;
  form: FeedbackFormFormProps['form'];
}

const SurveySelect: FC<SurveySelectProps> = ({
  field,
  setIsSelectOpen,
  surveyOptions,
  form,
}) => {
  const { ref, ...fieldProps } = field;
  const [showClear, setShowClear] = useState(false);
  return (
    <Select
      label=''
      {...fieldProps}
      ref={ref}
      onOpen={() => {
        setIsSelectOpen(true);
      }}
      onChange={e => {
        field.onChange(e);
        setIsSelectOpen(false);
        setShowClear(false);
      }}
      onMouseOver={() => {
        setShowClear(true);
      }}
      onMouseLeave={() => {
        setShowClear(false);
      }}
      endAdornment={
        showClear && fieldProps.value ? (
          <IconButton
            aria-label='clear select input'
            onClick={() => {
              form.setValue(field.name, '', {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
            sx={{ marginRight: '.5em' }}
          >
            <Close fontSize='small' />
          </IconButton>
        ) : null
      }
      displayEmpty
      renderValue={v => {
        if (!v) {
          return <Typography sx={{ color: 'grey.500' }}>Select...</Typography>;
        }
        return surveyOptions.find(s => s.value === v)?.label || '';
      }}
      fullWidth
    >
      {surveyOptions.map(item => {
        if (!item.options) {
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          );
        }
        return [
          <ListSubheader key={item.label} sx={{ fontWight: 600 }}>
            {item.label}
          </ListSubheader>,
          ...item.options.map(s => (
            <MenuItem
              key={s.value}
              value={s.value}
              sx={{ paddingLeft: '2em !important' }}
            >
              {s.label}
            </MenuItem>
          )),
        ];
      })}
    </Select>
  );
};

export { FeedbackFormForm };
