import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Card, Avatar } from '@src/components/sc-design-system';
import Client from '@src/models/Client';
import { ClientLogoInput } from '@src/components/common/ClientLogoInput';
import { AddressBoxes } from './AddressBoxes';

const GeneralInfoCard: FC<{ client: Client }> = ({ client }) => {
  return (
    <Card title='General' titleActions={null}>
      <Stack spacing={4}>
        <Box
          sx={{
            backgroundColor: 'grey.50',
            width: '100%',
            height: '135px',
            display: 'flex',
            alignItems: 'center',
            padding: '2em',
            justifyContent: 'space-between',
          }}
        >
          <Stack spacing={2}>
            <Typography variant='body1m'>Logo</Typography>
            <Typography variant='body1'>
              Image size should not exceed 1 MB and should be 1:1 ratio.
            </Typography>
          </Stack>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Avatar
              srcId={client?.id}
              sx={{ height: '60px', width: '60px' }}
              alt={client.name}
            >
              {/* TODO: this is a fallback until logos are fully migrated */}
              {client?.logo_image ? (
                <img
                  src={client?.logo_image}
                  alt={client?.name}
                  style={{
                    width: 'inherit',
                    height: 'inherit',
                  }}
                />
              ) : null}
            </Avatar>
            <ClientLogoInput client={client} />
          </div>
        </Box>
        <AddressBoxes client={client} />
      </Stack>
    </Card>
  );
};

export { GeneralInfoCard };
