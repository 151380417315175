import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Grid } from '@src/components/sc-design-system';
import { Competency, CompetencySet } from '@src/models/CompetencySet';
import { CompetencyCard } from '@src/components/common/CompetencyCard';

import { GridAddItem } from '@src/components/sc-design-system/Grid';
import { nanoid } from 'nanoid';

interface CompetencySetCardProps {
  set: CompetencySet;
  canEditCompetencies?: boolean;
  canEditSet?: boolean;
  onAddOrEditCompetency?: (set: CompetencySet, c?: Competency) => void;
  onDeleteCompetency?: (c: Competency) => void;
}

const CompetencySetCard: FC<CompetencySetCardProps> = observer(
  ({ set, canEditCompetencies, onAddOrEditCompetency, onDeleteCompetency }) => {
    const competencies = [...set.competencies];

    if (canEditCompetencies)
      competencies.push(
        new Competency({
          title: 'New competency',
          indicators: [{ id: nanoid(), title: 'New indicator' }],
        }),
      );

    return (
      <Grid
        data={competencies}
        renderer={competency => (
          <div style={{ width: '100%' }}>
            {competency.title === 'New competency' ? (
              <GridAddItem
                onClick={() => onAddOrEditCompetency(set)}
                label={
                  set.competency_label === 'competency'
                    ? 'Add Competency'
                    : 'Add Value'
                }
              />
            ) : (
              <CompetencyCard
                competency={competency}
                key={competency.id}
                isEditable={canEditCompetencies}
                onToggleEdit={() => onAddOrEditCompetency(set, competency)}
                onDelete={() => onDeleteCompetency(competency)}
              />
            )}
          </div>
        )}
      />
    );
  },
);

export { CompetencySetCard };
