import React, { FC } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import {
  Box,
  Typography,
  IconButton,
  Stack,
  Grid,
  CircularProgress,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import { TextField, useToast } from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import { FileUploadModal } from '@src/components/common/FileUploadModal';

const AttachmentContext = React.createContext(null);

export const AttachmentProvider = ({ children }) => {
  const toast = useToast();
  const [attachments, setAttachments] = React.useState([]);
  const [activeAttachments, setActiveAttachments] = React.useState([]);
  const [attachmentState, setAttachmentState] = React.useState({});

  const addAttachments = async (files: any[]) => {
    files.forEach(f => {
      f.id = nanoid();
    });
    setActiveAttachments([
      ...activeAttachments,
      ...files.map(f => ({
        id: f.id,
        name: f.name,
        type: f?.type,
        size: f?.size,
      })),
    ]);
    files.forEach(file => {
      updateFileState(file.id, 'loading', true);
    });

    const promises = files.map(async (file: any) => {
      try {
        const uploadUrlRes = await api.files.getUploadUrl(file.name);
        const uploadFileRes = await api.files.uploadFile(
          uploadUrlRes.data.url,
          file,
          uploadUrlRes.data.ContentType,
        );
        if (uploadFileRes.data.error) throw new Error('Could not upload file');
        setActiveAttachments(prev => {
          const updated = prev.map(a => {
            if (a.id === file.id) {
              return { ...a, key: uploadUrlRes.data.Key };
            }
            return a;
          });
          return updated;
        });
      } catch (e) {
        toast.error(`Error uploading ${file.name}`);
        setActiveAttachments(activeAttachments.filter(a => a.id !== file.id));
      }
      updateFileState(file.id, 'loading', false);
    });
    await Promise.all(promises);
  };

  const updateFileState = (id, key, value) => {
    setAttachmentState({
      ...attachmentState,
      [id]: {
        ...(attachmentState?.[id] || {}),
        [key]: value,
      },
    });
  };

  const addAttachment = (newAttachment: Attachment) => {
    setActiveAttachments([...activeAttachments, newAttachment]);
  };

  const { mutate: deleteAttachment } = useMutation({
    mutationFn: async (a: Attachment) => {
      updateFileState(a.id, 'loading', true);
      return api.files.deleteFile(a.key);
    },
    onSuccess: async (_, data) => {
      setActiveAttachments(activeAttachments.filter(a => a.id !== data.id));
    },
    onSettled: (_, __, data) => {
      updateFileState(data?.id, 'loading', false);
    },
  });

  const resetInput = () => {
    setAttachments([...attachments, ...activeAttachments]);
    setActiveAttachments([]);
    setAttachmentState({});
  };

  const removeAttachment = (key: string) => {
    setActiveAttachments(activeAttachments.filter(a => a.key !== key));
  };

  React.useEffect(() => {
    // Delete files on unmount.
    return () => {
      for (const a of attachments) {
        deleteAttachment(a);
      }
    };
  }, [attachments, deleteAttachment]);

  return (
    <AttachmentContext.Provider
      value={{
        attachments,
        attachmentState,
        activeAttachments,
        addAttachments,
        addAttachment,
        deleteAttachment,
        resetInput,
        removeAttachment,
      }}
    >
      {children}
    </AttachmentContext.Provider>
  );
};

export const useAttachments = () => {
  return React.useContext(AttachmentContext);
};

interface AttachmentInputProps {
  onSubmit: (attachments: Attachment[]) => void;
  disabled: boolean;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

const AttachmentInput: FC<AttachmentInputProps> = ({
  onSubmit,
  disabled,
  onChangeInput,
  value,
}) => {
  const toast = useToast();
  const {
    activeAttachments,
    attachmentState,
    addAttachments,
    addAttachment,
    deleteAttachment,
    resetInput,
    removeAttachment,
  } = useAttachments();
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] =
    React.useState(false);

  const accept = {
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      '.docx',
    ],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
    ],
    'text/csv': ['.csv'],
    'text/plain': ['.txt'],
  };

  const maxFiles = 5;

  const maxFilesSize = 10000000; // 10 MB
  const maxFilesSizeCode = 'files-too-large';
  const maxFilesSizeMessage = `Size of all files must not exceed ${maxFilesSize} bytes.`;
  const maxFilesSizeFunction = file => {
    if (
      file.size +
        activeAttachments.reduce((acc, aa) => {
          return acc + aa.size;
        }, 0) >
      maxFilesSize
    ) {
      return {
        code: maxFilesSizeCode,
        message: maxFilesSizeMessage,
      };
    }
  };

  const { getRootProps } = useDropzone({
    onDrop: addAttachments,
    disabled: activeAttachments?.length >= maxFiles,
    multiple: true,
    noClick: true,
    maxFiles: maxFiles - activeAttachments?.length ?? 0,
    validator: maxFilesSizeFunction,
    onDropRejected: event => {
      const error = event?.[0]?.errors?.[0];
      if (error) {
        switch (error.code) {
          case 'file-invalid-type':
            toast.error(
              `Files must be of the following types: ${Object.values(accept)
                .flat()
                .join(', ')}.`,
            );
            break;
          case 'too-many-files':
            toast.error(`Maximum number of files is ${maxFiles}.`);
            break;
          case maxFilesSizeCode:
            toast.error(maxFilesSizeMessage);
            break;
          default:
            toast.error(error.message);
        }
      } else {
        toast.error('An unspecified error occured during file attachment.');
      }
    },
    accept,
  });

  const handleSubmit = () => {
    onSubmit(activeAttachments);
    resetInput();
  };

  return (
    <Box
      {...getRootProps()}
      sx={{
        borderRadius: '24px',
        boxShadow:
          '0 1px 3px 0 rgba(26,26,46,0.08), 0 0 0px rgba(26,26,46,0.1)',
        ...(activeAttachments?.length > 0 && {
          border: '1px solid black',
          borderColor: 'grey.400',
          borderTop: 'none',
        }),
      }}
    >
      <TextField
        id='message'
        size='small'
        placeholder='Message Aida'
        value={value}
        onKeyDown={e => {
          if (e.key === 'Enter' && !!value) handleSubmit();
        }}
        multiline
        maxRows={4}
        onChange={onChangeInput}
        fullWidth
        disabled={disabled}
        InputProps={{
          sx: {
            minHeight: '64px',
            borderRadius: '24px',
            backgroundColor: 'grey.50',
            '.MuiOutlinedInput-notchedOutline': {
              borderStyle: 'solid',
              borderColor: 'grey.100',
            },
            left: activeAttachments?.length ? -1 : 0,
            width: activeAttachments?.length ? 'calc(100% + 2px)' : '100%',
          },
          endAdornment: (
            <IconButton
              size='small'
              variant='outlined'
              children={<ArrowUpwardIcon />}
              disabled={disabled || !value}
              onClick={handleSubmit}
            />
          ),
          startAdornment: (
            <IconButton
              size='small'
              children={<AttachFileIcon />}
              disabled={disabled || activeAttachments?.length >= 5}
              onClick={() => {
                setIsFileUploadModalOpen(true);
              }}
              sx={{ marginRight: 1 }}
            />
          ),
        }}
      />
      {activeAttachments?.length > 0 && (
        <Grid
          container
          direction='row'
          spacing={{ xs: 1, sm: 2 }}
          alignItems='center'
          padding='1em 2em'
        >
          {activeAttachments.map(a => (
            <Grid item key={a.id}>
              <Stack
                sx={{
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderColor: 'grey.400',
                  borderRadius: '8px',
                  padding: '.5em .5em .5em 1em',
                }}
                direction='row'
                alignItems='center'
              >
                <FilePresentIcon sx={{ fontSize: '1.5em' }} />
                <Typography variant='body1' sx={{ marginLeft: '.5em' }}>
                  {a.name}
                </Typography>
                <IconButton
                  sx={{ marginLeft: '1em' }}
                  size='small'
                  children={
                    attachmentState?.[a.id]?.loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <CancelOutlined color='primary' />
                    )
                  }
                  disabled={attachmentState?.[a.id]?.loading}
                  onClick={() => {
                    deleteAttachment(a);
                  }}
                />
              </Stack>
            </Grid>
          ))}
        </Grid>
      )}
      {isFileUploadModalOpen && (
        <FileUploadModal
          open={isFileUploadModalOpen}
          onClose={() => setIsFileUploadModalOpen(false)}
          onSuccess={(key, name, size) => {
            addAttachment({ id: nanoid(), name, key, size });
          }}
          onFileDelete={key => {
            removeAttachment(key);
          }}
          accept={accept}
          customValidator={{
            validatorFunction: maxFilesSizeFunction,
            errorCode: maxFilesSizeCode,
            errorMessage: maxFilesSizeMessage,
          }}
        />
      )}
    </Box>
  );
};

export { AttachmentInput };
