import React from 'react';
import { Button, EmptyState } from '@src/components/sc-design-system';
import { CreatePerformanceCycleModal } from '@src/components/views/MyBusiness/Performance/tabs/PerformanceCycles/modals/CreatePerformanceCycleModal';

const CreatePerformanceCycleEmptyState: React.FunctionComponent<{
  performanceCycleNames: string[];
}> = ({ performanceCycleNames }) => {
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <EmptyState
      title='Create a performance cycle for your organization'
      body='The performance cycle establishes the timeframe and framework for your performance management.'
      EmptyStateActionArea={
        <>
          <CreatePerformanceCycleModal
            setOpenModal={setOpenModal}
            openModal={openModal}
            performanceCycleNames={performanceCycleNames}
          />
          <Button text='Create now' onClick={() => setOpenModal(true)} />
        </>
      }
    />
  );
};

export { CreatePerformanceCycleEmptyState };
