import { useQuery } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import Client from '@src/models/Client';

const useClients = (
  params: GetClientsListParamsIF = { limit: 1000 },
  { enabled } = { enabled: true },
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['clients', params],
    queryFn: async () => await api.clients.getList(params),
    select: ({ data }) => {
      return {
        data: data.data
          ?.sort((a, b) => (a.name > b.name ? 1 : -1))
          .map(c => new Client(c)), // TODO: remove this eventually
        totalRecords: data.totalRecords,
      };
    },
    enabled,
  });

  return {
    clients: data?.data ?? null,
    totalRecords: data?.totalRecords ?? null,
    ...rest,
  };
};

export { useClients };
