import React, { FC } from 'react';

import { Icon } from '@src/components/sc-design-system';
import { Carousel, Space } from 'antd';
import ReactPlayer from 'react-player';
import User from '@src/models/User';
import { useSkills } from '@src/hooks/useSkills';
import { Chip, Link, List, ListItem, Stack, Typography } from '@mui/material';

const getTagTitle = (skills: object[], skill_id: string): string | boolean => {
  const cache = {}; // Memoize
  const foundSkill = cache[skill_id]
    ? { title: cache[skill_id] }
    : skills?.find((s: any) => s.id === skill_id);
  if (foundSkill) cache[skill_id] = (foundSkill as any).title;
  return foundSkill ? (foundSkill as any).title : false;
};

const CoachInfo: FC<{ viewCoach: User }> = ({ viewCoach }) => {
  const { skills } = useSkills({ enabled: !!viewCoach });
  if (!viewCoach) return null;
  return (
    <>
      <List sx={{ display: 'flex', width: 'fit-content' }}>
        {viewCoach.coaching_topics?.map(topic => {
          const title = getTagTitle(skills, topic);
          return title ? (
            <ListItem key={topic} sx={{ padding: '0 4px' }}>
              <Chip label={title} custom-color='black' />
            </ListItem>
          ) : null;
        })}
      </List>

      {viewCoach.biography && (
        <Typography
          variant='body1'
          sx={{ whiteSpace: 'pre-line', marginTop: '25px' }}
        >
          {viewCoach.biography}
        </Typography>
      )}

      <Stack direction='column' spacing={1}>
        {viewCoach.social_media_links &&
          viewCoach.social_media_links.map((link, i) => {
            if (link.type === 'website') {
              return (
                <Link
                  key={i}
                  component='button'
                  onClick={() => window.open(link.value, '_blank')}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <Icon name={link?.type?.toLowerCase()} size='small' />
                  {link.value}
                </Link>
              );
            }
          })}
        {viewCoach.social_media_links && (
          <Stack direction='row' spacing={1}>
            {viewCoach.social_media_links.map((link, i) => {
              if (link.type !== 'video' && link.type !== 'website') {
                return (
                  <Link
                    key={i}
                    onClick={() => window.open(link.value, '_blank')}
                    component='button'
                  >
                    <Icon name={link?.type?.toLowerCase()} />
                  </Link>
                );
              }
            })}
          </Stack>
        )}
      </Stack>
      <div className='coach-videos'>
        {viewCoach.social_media_links && (
          <>
            <Carousel dotPosition='top'>
              {viewCoach.social_media_links.map((link, i) => {
                if (link.type === 'video') {
                  return (
                    <ReactPlayer
                      key={i}
                      className='videoFrame'
                      url={link.value + i}
                      controls
                      height='20em'
                      width='100%'
                    />
                  );
                }
              })}
            </Carousel>
          </>
        )}
      </div>
      {viewCoach.testimonials?.length > 0 && (
        <>
          <div className='coach-testimonials'>
            {viewCoach.testimonials.map((t, i) => (
              <div className='testimonial-block' key={t.name + i}>
                <Space direction='horizontal' align='start'>
                  <span
                    className='quote'
                    style={{ fontSize: '2em', fontFamily: 'cursive' }}
                  >
                    &quot;
                  </span>
                  <blockquote>{t.review}</blockquote>
                </Space>

                <span className='testimonial-author'>
                  &mdash; <strong>{t.name}</strong> {t.title}, {t.company}
                </span>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default CoachInfo;
