import * as React from 'react';
import {
  // Please send well-wishes to the react-router devs. They're recovering from
  // a traumatic brain injury:
  // https://github.com/remix-run/react-router/issues/8264#issuecomment-962648674
  //
  // They feel really bad about these API decisions:
  // https://c.tenor.com/dYLvyNRhV8sAAAAC/woody-harrelson-crying.gif
  //
  // Per the comments in the linked GitHub issue, HistoryRouter is not actually
  // unstable. It's just a warning that the `history` dependency may be
  // duplicated because they include their own copy. I've verified that we're
  // reusing the same copy. The unstable_ prefix is just their way of mimicing
  // React's API syntax so it looks like they know what they're doing.
  unstable_HistoryRouter as HistoryRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import { ConfigProvider } from 'antd';
import { datadogRum } from '@datadog/browser-rum';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Store, { StoreContext } from '@src/models/Store';
import { AppLoader } from './AppLoader';
import { PublicView } from './PublicView/PublicView';
import { AuthenticatedView } from './AuthenticatedView/AuthenticatedView';
import { LoginForm } from '@src/components/forms/LoginForm/LoginForm';
import SignupForm from '@src/components/forms/SignupForm/SignupForm';
import PasswordRecovery from '@src/components/forms/PasswordRecovery/PasswordRecovery';
import Meeting from '@src/components/views/Meetings/Meetings';
import ErrorBoundary from './ErrorBoundary';
import { IntercomProvider } from '@src/lib/intercom';
import { INavigation, HomeRouteHandler } from '@src/navigation';
import { World, WorldContext } from '@src/world';
import { theme } from '@src/theme';
import { ThemeProvider } from '@emotion/react';
import { ConfirmProvider } from 'material-ui-confirm';
import {
  BreadcrumbsProvider,
  ToastProvider,
} from '@src/components/sc-design-system';
import { ActiveClientKey, fetchClient } from '@src/hooks/useActiveClient';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
  },
});

export let App: React.FC<{
  store: Store;
  world: World;
}> = ({ store, world }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <AntdConfigProvider>
          <WorldContext.Provider value={world}>
            <StoreContext.Provider value={store}>
              <IntercomProvider>
                <ToastProvider>
                  <BreadcrumbsProvider>
                    <ConfirmProvider>
                      <AppUI store={store} world={world} />
                    </ConfirmProvider>
                  </BreadcrumbsProvider>
                </ToastProvider>
              </IntercomProvider>
            </StoreContext.Provider>
          </WorldContext.Provider>
        </AntdConfigProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition='bottom-left' />
    </QueryClientProvider>
  );
};

let AppUI = ({ store, world }: { store: Store; world: World }) => {
  const prefetchQueryKey = store.user.is_admin
    ? ActiveClientKey.sc_admin_active_client_id
    : ActiveClientKey.profile_active_client_id;
  queryClient.prefetchQuery({
    queryKey: [prefetchQueryKey],
    queryFn: async () => fetchClient(prefetchQueryKey, store.user, queryClient),
    staleTime: 60 * 60 * 1000,
  });

  const loading = store.loading || world.platform.initializing;

  if (loading) {
    return <AppLoader />;
  }
  return (
    <ErrorBoundary addError={datadogRum?.addError}>
      <Router navigation={world.navigation} />
    </ErrorBoundary>
  );
};
AppUI = observer(AppUI);

const Router = ({ navigation }: { navigation: INavigation }) => {
  return (
    <HistoryRouter history={navigation}>
      <Routes>
        <Route path='/' element={<HomeRouteHandler />} />
        <Route
          path='/login'
          element={<PublicView title='Login' component={LoginForm} />}
        />
        <Route
          path='/signup'
          element={<PublicView title='Create Account' component={SignupForm} />}
        />
        <Route
          path='/password-recovery'
          element={
            <PublicView
              title='Password Recovery'
              component={PasswordRecovery}
            />
          }
        />
        <Route
          path='/home'
          element={<AuthenticatedView title='Home' componentName='Home' />}
        />
        <Route
          path='/my-coachees'
          element={
            <AuthenticatedView
              title='My Coachees'
              componentName='Home'
              createBreadcrumb
            />
          }
        />
        <Route
          path='/my-mentees'
          element={
            <AuthenticatedView
              title='My Mentees'
              componentName='Home'
              createBreadcrumb
            />
          }
        />
        <Route
          path='/my-programs'
          element={
            <AuthenticatedView
              title='Learning and Development'
              componentName='Home'
              createBreadcrumb
            />
          }
        />
        <Route
          path='/my-programs/:programId'
          element={
            <AuthenticatedView
              title='My Programs'
              componentName='CoacheeProgram'
            />
          }
        />

        <Route
          path='/coaches'
          element={
            <AuthenticatedView title='Coaches' componentName='CoachSearch' />
          }
        />
        <Route
          path='/sessions/:sessionId'
          element={
            <AuthenticatedView title='Session' componentName='Session' />
          }
        />
        <Route
          path='/aida'
          element={<AuthenticatedView title='Aida' componentName='Aida' />}
        />
        <Route
          path='/performance-and-growth'
          element={
            <AuthenticatedView
              title='Performance and Growth'
              componentName='PerformanceAndGrowth'
              createBreadcrumb
            />
          }
        />
        <Route
          path='/profile/:id'
          element={
            <AuthenticatedView
              title='Direct Report Profile'
              componentName='DirectReportProfile'
            />
          }
        />
        <Route
          path='/administration'
          element={
            <AuthenticatedView
              title='Administration'
              componentName='Administration'
              createBreadcrumb
            />
          }
        />
        <Route
          path='/administration/users'
          element={
            <AuthenticatedView
              title='User Management'
              componentName='AdminUsersList'
              createBreadcrumb
            />
          }
        />
        <Route
          path='/administration/clients'
          element={
            <AuthenticatedView
              title='Client Management'
              componentName='AdminClientsList'
              createBreadcrumb
            />
          }
        />
        <Route
          path='/administration/sows'
          element={
            <AuthenticatedView
              title='SOWs'
              componentName='SOWsList'
              createBreadcrumb
            />
          }
        />
        <Route
          path='/administration/sows/:id'
          element={<AuthenticatedView title='SOWs' componentName='SOWsList' />}
        />
        <Route
          path='/administration/programs'
          element={
            <AuthenticatedView
              title='Programs'
              componentName='ProgramsList'
              createBreadcrumb
            />
          }
        />
        <Route
          path='/administration/programs/:id'
          element={
            <AuthenticatedView title='Programs' componentName='ProgramsList' />
          }
        />
        <Route
          path='/my-business/:id/admin'
          element={
            <AuthenticatedView
              title='My Business'
              componentName='ClientAdmin'
              createBreadcrumb
            />
          }
        />
        <Route
          path='/my-business/:id/performance'
          element={
            <AuthenticatedView
              title='Performance'
              componentName='Performance'
              createBreadcrumb
            />
          }
        />
        <Route
          path='/my-business/:id/performance/:performanceCycleId'
          element={
            <AuthenticatedView
              title='Status Dashboard'
              componentName='PerformanceCycleLandingPage'
            />
          }
        />
        <Route
          path='/my-business/:client_id/performance/competency-set/:competency_set_id'
          element={
            <AuthenticatedView
              title='My Business'
              componentName='CompetencySetLandingPage'
            />
          }
        />
        <Route
          path='/insights/:client_id'
          element={
            <AuthenticatedView
              title='Surveys & Insights'
              componentName='SurveysAndInsights'
              createBreadcrumb
            />
          }
        />
        <Route
          path='/program/:id'
          element={
            <AuthenticatedView title='Program' componentName='Program' />
          }
        />
        <Route
          path='/resources'
          element={
            <AuthenticatedView
              title='Resources'
              componentName='ResourcesManager'
            />
          }
        />
        <Route
          path='/surveys'
          element={
            <AuthenticatedView
              title='Surveys'
              componentName='SurveysManager'
              createBreadcrumb
            />
          }
        />
        <Route
          path='/surveys/editor'
          element={
            <AuthenticatedView
              title='Survey Editor'
              componentName='SurveyEditor'
            />
          }
        />
        <Route
          path='/my-business/:client_id/surveys/editor'
          element={
            <AuthenticatedView
              title='Survey Editor'
              componentName='SurveyEditor'
            />
          }
        />
        <Route
          path='/insights/:client_id/surveys/editor'
          element={
            <AuthenticatedView
              title='Survey Editor'
              componentName='SurveyEditor'
            />
          }
        />
        <Route
          path='/my-business/:id'
          element={
            <AuthenticatedView
              title='Programs'
              componentName='ClientPrograms'
              createBreadcrumb
            />
          }
        />
        <Route
          path='/meetings/:id'
          element={<PublicView title='Meetings' component={Meeting} />}
        />
        <Route
          path='/my-team'
          element={
            <AuthenticatedView
              title='My Team'
              componentName='TeamDashboard'
              createBreadcrumb
            />
          }
        />
        <Route
          path='/team/:id'
          element={
            <AuthenticatedView
              title='Team View'
              componentName='TeamDashboard'
            />
          }
        />
        <Route
          path='/report/:user_id/:program_id'
          element={<AuthenticatedView title='Report' componentName='Report' />}
        />
        <Route
          path='/evaluation-report'
          element={
            <AuthenticatedView
              title='Performance Evaluation Report'
              componentName='PerformanceEvaluationReport'
            />
          }
        />
        <Route
          path='/feedback-report'
          element={
            <AuthenticatedView
              title='Feedback Report'
              componentName='PerformanceCycleFeedbackReport'
            />
          }
        />
      </Routes>
    </HistoryRouter>
  );
};

const AntdConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const nonce = Date.now().toString();

  const formConfig = {
    // All validation messages:
    // https://github.com/react-component/field-form/blob/master/src/utils/messages.ts
    validateMessages: {
      default: t('Field invalid'),
      required: t('Field required'),
      types: { email: t('Must be valid email address') },
      string: {
        min: t('Must be minimum'),
        max: t('Must not exceed maximum'),
      },
      enum: t('Must be one of'),
      whitespace: t('Cannot be empty'),
      date: {
        format: t('Invalid date'),
        parse: t('Invalid date'),
        invalid: t('Invalid date'),
      },
    },
  };
  return (
    <ConfigProvider csp={{ nonce }} form={formConfig}>
      {children}
    </ConfigProvider>
  );
};
