import React, { FC, useMemo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import {
  Button,
  ConfirmDialog,
  DialogTitle,
  useToast,
  Spin,
} from '@src/components/sc-design-system';
import { FeedbackFormForm } from './FeedbackFormForm';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { api } from '@src/lib/client';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { useClientQueries } from '@src/hooks/useClientQueries';

interface FeedbackFormModalProps {
  open: boolean;
  handleClose: (b: boolean) => void;
  performanceCycle: PerformanceCycleI;
  edit?: boolean;
  refetchPerformanceCycle: Function;
}

const FeedbackFormModal: FC<FeedbackFormModalProps> = ({
  open,
  performanceCycle,
  handleClose,
  edit,
  refetchPerformanceCycle,
}) => {
  const toast = useToast();
  const { id: clientId } = useParams();
  const navigate = useNavigate();
  const { useGetClientPublishedSurveys } = useClientQueries(clientId);
  const { surveys, isLoading } = useGetClientPublishedSurveys();

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const form = useForm({
    mode: 'all',
    defaultValues: {
      ongoing_feedback_form_id:
        performanceCycle?.ongoing_feedback_form_id ?? '',
    } as OngoingFeedbackFormFormDefaultValues,
  });
  const {
    reset,
    formState: { isValid, isDirty },
    watch,
  } = form;

  const { mutate: setFeedbackForm, isPending } = useMutation({
    mutationFn: async (payload: Partial<PerformanceCycleI>) => {
      return api.clients.editPerformanceCycle(clientId, {
        id: performanceCycle.id,
        ...payload,
      });
    },
    onSuccess: () => {
      refetchPerformanceCycle();
      handleClose(false);
    },
    onError: () => {
      toast.error('Unable to set feedback form');
    },
  });

  const onSubmit = () => {
    setConfirmDialogOpen(true);
  };

  function onClose() {
    handleClose(false);
    reset();
  }

  const selectedSurvey = watch('ongoing_feedback_form_id');
  const selectedSurveyTitle = useMemo(() => {
    return surveys?.find(s => s.id === selectedSurvey)?.title;
  }, [selectedSurvey, surveys]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='sm'>
        <DialogTitle
          title={`${edit ? 'Edit' : 'Add'} feedback forms`}
          buttonOnClick={onClose}
        />
        <DialogContent dividers>
          <Typography variant='h4' sx={{ marginBottom: '.5em' }}>
            Select form to add
          </Typography>
          <Typography variant='body2' sx={{ marginBottom: '1.5em' }}>
            Select an existing survey for the feedback form.
          </Typography>
          {isLoading ? (
            <Spin sectionLoader />
          ) : (
            <FeedbackFormForm
              onSubmit={onSubmit}
              form={form}
              surveys={surveys}
            />
          )}
          <Divider />
          <Typography variant='h4' sx={{ marginBottom: '.5em' }}>
            Create new form to add
          </Typography>
          <Typography variant='body2' sx={{ marginBottom: '1.5em' }}>
            Don't see the form you want to attach? Create a new survey in the
            survey editor.
          </Typography>
          <Button
            startIcon={<EditOutlined />}
            text='Create new form'
            variant='outlined'
            onClick={() => {
              navigate(`/insights/${clientId}/?tab=1&new=true`);
              onClose();
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button text='Cancel' variant='outlined' onClick={onClose} />
          <Button
            text='Save'
            type='submit'
            sx={{ marginLeft: '.5em' }}
            form='feedback-form-form'
            disabled={!isValid || !isDirty}
            loading={isPending}
          />
        </DialogActions>
      </Dialog>
      {confirmDialogOpen && (
        <ConfirmDialog
          open={confirmDialogOpen}
          setOpenModal={setConfirmDialogOpen}
          title='Confirm feedback form'
          body={
            watch('ongoing_feedback_form_id') ? (
              <Typography>
                The feedback form you have set is{' '}
                <strong>{selectedSurveyTitle}</strong>. Once a member requests
                feedback this form cannot be changed to another form.
              </Typography>
            ) : (
              'Are you sure you want to remove this feedback form?'
            )
          }
          buttonProps={{
            text: 'Confirm',
            onClick: () => {
              setFeedbackForm(form.getValues());
            },
          }}
        />
      )}
    </>
  );
};

export { FeedbackFormModal };
