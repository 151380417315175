import React, { FC } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Table, Pagination } from 'antd';
import {
  Button,
  Card,
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
  useBreadcrumbs,
} from '@src/components/sc-design-system';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import ProgramEditor from './ProgramEditor';
import Program from '@src/models/Program';
import ProgramMemberModal from './ProgramMemberModal';
import { api } from '@src/lib/client';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { useQueryParams } from '@src/utils';
import { ActiveClientKey } from '@src/hooks/useActiveClient';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import WebIcon from '@mui/icons-material/Web';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { usePrograms } from '@src/hooks/usePrograms';
import { useUser } from '@src/hooks/useUser';
import { Tooltip } from '@mui/material';

const PAGE_LIMIT = 25;

const setQueryParams = (
  navigate: Function,
  query: any,
  name: string,
  value: string | number,
) => {
  let currentPage = query.get('page') || 1;
  if (name === 'page') {
    currentPage = value;
  }

  // Build query
  const composedQuery = `?${currentPage && `page=${currentPage}`}`;
  navigate({
    pathname: window.location.pathname,
    search: composedQuery,
  });
};

const ProgramsList: FC = observer(() => {
  const { user } = useUser();
  const query = useQueryParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { resetBreadcrumbs } = useBreadcrumbs();
  const pathParameters = useParams();
  const setQuery = setQueryParams.bind(null, navigate, query);
  const page = query.get('page') || 1;
  const sowId = query.get('sow_id');
  const clientId = (pathParameters && pathParameters.id) || undefined;
  const { programs, isLoading } = usePrograms({
    enabled: true,
    params: {
      page,
      queryBy: sowId ? 'sow' : clientId ? 'client' : false,
      queryId: sowId ? sowId : clientId,
    },
  });
  const { data: currentSOW, isLoading: isLoadingSOW } = useQuery({
    queryKey: ['sows', sowId],
    queryFn: async () => api.sows.getSOWById(sowId),
    enabled: !!sowId,
    select: ({ data }) => data,
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => (a.name > b.name ? -1 : 1),
    },
    {
      title: 'SOW',
      sorter: (a, b) => (a.sows[0].name > b.sows[0].name ? -1 : 1),
      render: (text: string, program) =>
        sowId ? (
          <span>
            {program.sows && program.sows[0] ? program.sows[0].name : 'Missing'}
          </span>
        ) : (
          <Tooltip title={`Filter by ${program.sows[0].name}`} enterDelay={500}>
            <Link
              variant='a2dash'
              onClick={() =>
                navigate(
                  `/administration/programs/${program.client_id}?sow_id=${program.sow_id}`,
                )
              }
            >
              {program.sows && program.sows[0]
                ? program.sows[0].name
                : 'Missing'}
            </Link>
          </Tooltip>
        ),
    },
    {
      title: 'Members',
      align: 'center' as 'center',
      responsive: ['lg' as Breakpoint],
      width: 30,
      render: (text: string, program) => (
        <span>{program.members ? program.members.length : 0}</span>
      ),
    },
    {
      title: 'Start',
      align: 'center' as 'center',
      responsive: ['lg' as Breakpoint],
      width: 30,
      sorter: (a: any, b: any) => a.start_date - b.start_date,
      render: (text: string, program) => (
        <span>{dayjs(program.start_date).format('l')}</span>
      ),
    },
    {
      title: 'End',
      align: 'center' as 'center',
      responsive: ['lg' as Breakpoint],
      width: 30,
      sorter: (a: any, b: any) => a.end_date - b.end_date,
      render: (text: string, program) => (
        <span>{dayjs(program.end_date).format('l')}</span>
      ),
    },
    {
      width: 30,
      title: 'Actions',
      key: 'actions',
      align: 'center' as 'center',
      responsive: ['lg' as Breakpoint],
      render: (text: string, program) => (
        <Stack direction='row' alignItems='center' spacing={1}>
          <ProgramEditor
            key={`edit-${program.id}`}
            program={program}
            isAdmin={user.is_admin}
          >
            <IconButton>
              <EditOutlinedIcon fontSize='small' />
            </IconButton>
          </ProgramEditor>
          <ProgramMemberModal
            program={program}
            isAdmin={user.is_admin}
            onUpdate={() => {
              queryClient.invalidateQueries({ queryKey: ['programs'] });
            }}
          >
            <IconButton>
              <PeopleOutlinedIcon fontSize='small' />
            </IconButton>
          </ProgramMemberModal>
        </Stack>
      ),
    },
    {
      title: 'View',
      width: 1,
      key: 'view',
      align: 'center' as 'center',
      render: (text: string, program) => (
        <Stack direction='row' alignItems='center' spacing={1}>
          <IconButton
            onClick={() => {
              const activeClientId = localStorage.getItem(
                ActiveClientKey.sc_admin_active_client_id,
              );
              if (activeClientId !== program.client_id) {
                localStorage.setItem(
                  ActiveClientKey.sc_admin_active_client_id,
                  program.client_id,
                );
                queryClient.invalidateQueries();
              }
              navigate(`/program/${program.id}`);
              resetBreadcrumbs([]);
            }}
          >
            <ChevronRightIcon fontSize='small' />
          </IconButton>
        </Stack>
      ),
    },
  ];

  if (!clientId) {
    columns.splice(1, 0, {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      sorter: (a, b) => (a.clients[0].name > b.clients[0].name ? -1 : 1),
      render: (text, program) =>
        clientId ? (
          <div style={{ whiteSpace: 'nowrap' }}>
            {program.clients && program.clients[0]
              ? program.clients[0].name
              : 'Missing'}
          </div>
        ) : (
          <Tooltip
            title={`Filter by ${program.clients[0].name}`}
            enterDelay={500}
          >
            <Link
              variant='a2dash'
              onClick={() =>
                navigate(`/administration/programs/${program.client_id}`)
              }
            >
              {program.clients && program.clients[0]
                ? program.clients[0].name
                : 'Missing'}
            </Link>
          </Tooltip>
        ),
    } as any);
  }

  return (
    <View>
      <ViewHeader
        title='Programs'
        titleIcon={<WebIcon fontSize='large' />}
        actions={[
          {
            render:
              clientId && sowId && currentSOW ? (
                <ProgramEditor
                  program={
                    new Program({
                      name: 'New Program',
                      client_id: clientId,
                      sow_id: sowId,
                      allowed_durations: currentSOW.allowed_durations,
                    })
                  }
                  currentSOW={currentSOW}
                  onSaveNew={() => {
                    queryClient.invalidateQueries({ queryKey: ['programs'] });
                  }}
                  isAdmin={user.is_admin}
                >
                  <Button text='New Program' size='small' />
                </ProgramEditor>
              ) : null,
          },
        ]}
      />
      <ViewBody>
        <ViewBodySection title='Programs List'>
          <Card>
            <Table
              bordered
              loading={isLoading || isLoadingSOW}
              pagination={false}
              dataSource={programs?.data
                .slice()
                .sort((a, b) => (a.start > b.start ? 1 : -1))}
              columns={columns}
              locale={{ emptyText: 'No Programs found' }}
              rowKey={'id'}
            />
            <Pagination
              style={{ marginTop: '2em' }}
              pageSize={PAGE_LIMIT}
              current={+page}
              total={programs?.totalRecords}
              onChange={val => setQuery('page', val)}
            />
          </Card>
        </ViewBodySection>
      </ViewBody>
    </View>
  );
});

export default ProgramsList;
