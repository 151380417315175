import React from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Spin } from '@src/components/sc-design-system';

interface FileDropzoneProps {
  currentFileName: string;
  getRootProps: Function;
  isDragActive: boolean;
  getInputProps: Function;
  onFileDelete?: () => void;
  isUploading?: boolean;
}

const FileDropzone: React.FunctionComponent<FileDropzoneProps> = props => {
  const {
    currentFileName,
    getRootProps,
    isDragActive,
    getInputProps,
    onFileDelete,
    isUploading,
  } = props;
  return (
    <Stack
      justifyContent='center'
      {...getRootProps()}
      sx={{
        '&:hover': {
          cursor: 'pointer',
          borderColor: 'grey.500',
          backgroundColor: 'grey.100',
        },
        '&:active': {
          backgroundColor: 'primary.100',
          borderColor: 'primary.500',
        },
        backgroundColor: isDragActive
          ? 'grey.50'
          : currentFileName
          ? 'primary.100'
          : 'white.main',
        borderRadius: '6px',
        border: '1px dashed',
        borderColor: currentFileName ? 'primary.500' : 'grey.500',
        padding: '.75em 1.5em',
        height: '80px',
      }}
    >
      <input {...getInputProps()} />
      <Stack direction='row' spacing={2} alignItems='center'>
        {!currentFileName ? (
          <FileUploadOutlinedIcon fontSize='small' />
        ) : isUploading ? (
          <Spin />
        ) : (
          <FilePresentOutlinedIcon fontSize='small' />
        )}
        <Typography
          sx={{ '&:hover': { cursor: 'pointer' }, wordBreak: 'break-all' }}
          variant='body2'
        >
          {isDragActive
            ? 'Drop your file here'
            : isUploading
            ? 'Uploading file...'
            : currentFileName
            ? currentFileName
            : 'Drag-and-drop or click here to browse file'}
        </Typography>
        {currentFileName && onFileDelete && (
          <IconButton
            onClick={e => {
              e.stopPropagation();
              onFileDelete();
            }}
            size='small'
            sx={{ marginLeft: 'auto !important' }}
          >
            <ClearOutlinedIcon fontSize='small' />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export default FileDropzone;
