import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import Store from '@src/models/Store';
import './Session.less';
import { useNavigate } from 'react-router';
import Jitsi from '@src/components/common/Jitsi/Jitsi';
import { api } from '@src/lib/client';
import { SessionGetResponse } from '@src/lib/client/apis/sessions';
import LoadingIcon from '@src/components/common/LoadingIcon';
import * as useVerbiage from '@shared/lib/src/use-verbiage';
import { Link } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

interface SessionPropsIF {
  store: Store;
}

let role;
let v;

const Session: FC<SessionPropsIF> = observer(({ store }) => {
  const pathParameters = useParams();
  const navigate = useNavigate();
  const idOrSlug = pathParameters.sessionId;
  const [sessionData, setSessionData] = React.useState<
    undefined | SessionGetResponse
  >();
  const [leavingSession, setLeavingSession] = React.useState(false);
  const onJoin = async () => {
    await api.sessions.recordJoin(idOrSlug, store.user.id);
  };

  const onLeave = async () => {
    if (!leavingSession) {
      setLeavingSession(true);
      const res = (await api.sessions.recordLeave(idOrSlug, store.user.id))
        .data;
      const path = {
        coachee: 'my-programs',
        mentee: 'my-programs',
        coach: 'my-coachees',
        mentor: 'my-mentees',
      };
      if (res.current_status === 'completed') {
        navigate(`/${path[role]}/?session_id=${res.id}`);
      } else {
        navigate(`/${path[role]}`);
      }
    }
  };

  // Bind to beforeunload to record leave event if user closes tab/window
  useEffect(() => {
    (async () => {
      try {
        let session: SessionGetResponse | undefined;
        if (store.user?.active_session?.id === idOrSlug) {
          session = store.user.active_session;
        } else {
          const { data } = await api.sessions.get({
            sessionId: idOrSlug,
          });
          [session] = data.data;
        }
        setSessionData(session);
        store.user.updateActiveSession(session);
        v = useVerbiage.init(
          session?.program_info?.type === 'mentoring' ? ['isMentor'] : [],
        );
        role = session?.members.some(
          m => m.id === store.user.id && m.role === 'coach',
        )
          ? v('coach')
          : v('coachee');
      } catch (e) {
        // use default copy if session data unavailable
      }
    })();
    const handleTabClose = (event: BeforeUnloadEvent): void => {
      event.preventDefault();
      onLeave();
    };
    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
    // FIXME: invalid dependency array
    // eslint-disable-next-line
  }, []);

  return (
    <div className='page-session'>
      {!sessionData ? (
        <LoadingIcon />
      ) : (
        <>
          <Link
            component='button'
            className='close-session'
            onClick={() => onLeave()}
          >
            <CancelOutlinedIcon fontSize='large' />
          </Link>
          <Jitsi
            meetingId={idOrSlug}
            meetingName={
              sessionData?.type === 'interview'
                ? `${v('coach', true)}-Interview`
                : `${v('coaching', true)}-Session`
            }
            userName={`${store.user.first_name} ${store.user.last_name}`}
            userId={store.user.id}
            jwt={
              sessionData.members.find(m => m.id === store.user.id)?.signed_jwt
            }
            onJoin={onJoin}
            onLeave={onLeave}
          />
        </>
      )}
    </div>
  );
});

export default Session;
