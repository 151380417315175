import React, { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Divider from '@mui/material/Divider';
import { useParams } from 'react-router';
import { Card, Spin } from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import { useQueryParams } from '@src/utils';
import { SurveyActivity } from './components/SurveyActivity';
import { ExistingSurveyUse } from './components/ExistingSurveyUse';
import { SendSurveyModal } from './components/SendSurveyModal';
import { RemindRecipientsModal } from './components/RemindRecipientsModal';
import { ActiveClientKey, useActiveClient } from '@src/hooks/useActiveClient';
import { RetrySendModal } from './components/RetrySendModal';
import { useStore } from '@src/models/Store';

const CollectResponses: FC<{ surveyName: string }> = ({ surveyName }) => {
  const [showSendSurvey, setShowSendSurvey] = useState(false);
  const [failedRecipients, setFailedRecipients] = useState([]);
  const [showRetryModal, setShowRetryModal] = useState(false);
  const [showRemindRecipientsModal, setShowRemindRecipientsModal] =
    useState(false);
  const [activeCollection, setActiveCollection] = useState(null);
  const queryParams = useQueryParams();
  const { client_id } = useParams();
  const survey_id = queryParams.get('id');
  const store = useStore();
  const { client } = useActiveClient({
    queryKey: store.user.is_admin
      ? ActiveClientKey.sc_admin_active_client_id
      : ActiveClientKey.profile_active_client_id,
  });

  const { data: programs, isLoading } = useQuery({
    queryKey: ['survey', survey_id, 'programs'],
    queryFn: async () =>
      api.surveys.getProgramsUsingSurvey(survey_id, client_id),
    select: ({ data }) => data,
    enabled: Boolean(survey_id),
  });

  const { data: collections, isLoading: isCollectionsLoading } = useQuery({
    queryKey: ['survey', survey_id, 'collections'],
    queryFn: async () =>
      api.surveys.listSurveyCollections(survey_id, client_id),
    select: ({ data }) => data,
  });

  const toggleSendSurveyModal = (collection = null) => {
    setActiveCollection(collection);
    setShowSendSurvey(!showSendSurvey);
  };

  const toggleReminderModal = (collection = null) => {
    setActiveCollection(collection);
    setShowRemindRecipientsModal(!showRemindRecipientsModal);
  };

  return (
    <>
      <Card title='Collect Responses'>
        {
          <SurveyActivity
            isEmpty={!isCollectionsLoading && collections?.length === 0}
            onClickCollect={toggleSendSurveyModal}
            onClickResponseRate={toggleReminderModal}
            collections={collections}
          />
        }
        {isLoading && <Spin sectionLoader />}
        {!isLoading && window.location.pathname.includes('my-business') && (
          <>
            <Divider />
            <ExistingSurveyUse programs={programs} />
          </>
        )}
      </Card>
      {showSendSurvey && (
        <SendSurveyModal
          isModalOpen={showSendSurvey}
          onClose={collection => {
            toggleSendSurveyModal(collection);
          }}
          clientId={client_id}
          clientName={client?.name}
          surveyId={survey_id}
          surveyName={surveyName}
          collection={activeCollection}
          isDraftMode={
            activeCollection?.status
              ? activeCollection?.status === 'draft'
              : true
          }
          onSendFailures={errors => {
            setFailedRecipients(errors);
            setShowRetryModal(true);
          }}
        />
      )}
      {showRetryModal && (
        <RetrySendModal
          isModalOpen={showRetryModal}
          onClose={() => {
            setFailedRecipients([]);
            setActiveCollection(null);
            setShowRetryModal(false);
          }}
          clientId={client_id}
          clientName={client?.name}
          surveyId={survey_id}
          surveyName={surveyName}
          collection={activeCollection}
          failedRecipients={failedRecipients}
        />
      )}
      {showRemindRecipientsModal && (
        <RemindRecipientsModal
          isModalOpen={showRemindRecipientsModal}
          clientId={client_id}
          clientName={client?.name}
          surveyId={survey_id}
          surveyName={surveyName}
          collection={activeCollection}
          onClose={() => {
            toggleReminderModal();
          }}
        />
      )}
    </>
  );
};

export { CollectResponses };
