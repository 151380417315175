import React, { FC } from 'react';
import User from '@src/models/User';
import { UserGoalCycleCard } from '../components/UserGoalCycleCard';

const PersonalGrowth: FC<{
  user: User;
  goalCycles: GoalCycleWithGoals[];
  allGoalCycles: GoalCycleWithGoals[];
  clientId?: string;
}> = ({ user, goalCycles, allGoalCycles, clientId }) => {
  return (
    <>
      {goalCycles?.map((c, idx) => (
        <UserGoalCycleCard
          key={c.id}
          cycle={c}
          goalCycles={allGoalCycles}
          clientId={clientId}
          userId={user.id}
          defaultOpen={idx === 0}
          showStatusChip={false}
        />
      ))}
    </>
  );
};

export { PersonalGrowth };
