import './CoacheeEngagement.less';
import React, { FC, useMemo, useState } from 'react';
import { api } from '@src/lib/client';
import { Select, Space, Switch, Table } from 'antd';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/lib/table';
import { UserEngagementRecord } from '@src/lib/client/apis/engagement';
import { Card, useToast } from '@src/components/sc-design-system';
import { Stack, Link } from '@mui/material';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import NotesIcon from '@mui/icons-material/Notes';
import { EngagementRecordNotesModal } from './components/EngagementRecordNotesModal';
import { EngagementRecord360sModal } from './components/EngagementRecord360sModal';
import { Send360NotificationsModal } from './components/Send360NotificationsModal';
import { useStore } from '@src/models/Store';

let clientDebounce;

const debounceClientSearch = async (
  keyword: string,
  setClientSearchOptions: Function,
  setClientSearchLoading: Function,
) => {
  if (clientDebounce) clearTimeout(clientDebounce);
  clientDebounce = setTimeout(async () => {
    setClientSearchLoading(true);
    const res = await api.clients.quickSearch(keyword, 10);
    setClientSearchOptions(
      res.data.map(o => {
        return {
          label: o.name,
          value: o.id,
        };
      }),
    );
    setClientSearchLoading(false);
  }, 250);
};

export const CoacheeEngagement: FC = () => {
  const store = useStore();
  const toast = useToast();
  const { user } = store;
  const [clientSearchOptions, setClientSearchOptions] = useState([]);
  const [clientSearchLoading, setClientSearchLoading] = useState(false);
  const [selectedNotesRecord, setSelectedNotesRecord] =
    useState<UserEngagementRecord | null>(null);
  const [selected360sRecord, setSelected360sRecord] =
    useState<UserEngagementRecord | null>(null);
  const [showNotificationsModal, setShowNotificationsModal] = useState(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [data, setData] = useState<UserEngagementRecord[]>([]);
  const [filters, setFilters] = useState<TableFilters>((): TableFilters => {
    return {
      tags: [],
      statuses: [],
      programs: [],
      clients: [],
      coaches: [],
      attentionNeeded: [],
      notes: [{ text: 'Has Notes', label: 'Has Notes', value: 'true' }],
    };
  });

  const updateLocalRecord = (email: string, programId: string, delta: any) => {
    setData(
      data.map(record =>
        record.email === email && record.programId === programId
          ? Object.assign({}, record, delta)
          : record,
      ),
    );
  };

  const columns = useMemo(
    () =>
      createColumns(
        setSelectedNotesRecord,
        setSelected360sRecord,
        filters,
        setLoadingData,
        setData,
        toast,
      ),
    [filters, toast],
  );

  return (
    <Card className='dashboard-coachee-engagement'>
      <Space direction='vertical'>
        <Select
          style={{ width: '100%' }}
          showSearch
          labelInValue
          filterOption={false}
          notFoundContent={null}
          placeholder='Search for a client'
          options={
            clientSearchOptions && !clientSearchLoading
              ? clientSearchOptions
              : [
                  {
                    label: <div style={{ color: '#999' }}>Searching...</div>,
                  },
                ]
          }
          loading={clientSearchLoading}
          onSearch={e => {
            debounceClientSearch(
              e,
              setClientSearchOptions,
              setClientSearchLoading,
            );
          }}
          onSelect={async e => {
            setLoadingData(true);
            let isCanceled = false;
            const response = await api.engagement.getData(e.value);
            if (isCanceled) {
              return;
            }

            const statuses = new Set<string>();
            const programNames = new Set<string>();
            const clientNames = new Set<string>();
            const coachNames = new Set<string>();
            const attentionNeeded = new Set<string>();
            for (const record of response.data) {
              if (record.status) {
                statuses.add(record.status);
              }
              if (record.programName) {
                programNames.add(record.programName);
              }
              if (record.clientName) {
                clientNames.add(record.clientName);
              }
              if (record.coachName) {
                coachNames.add(record.coachName);
              }
              if (record.attentionNeeded) {
                attentionNeeded.add(record.attentionNeeded);
              }
            }

            setFilters(filters => {
              return {
                ...filters,
                statuses: toFilterOptions(statuses),
                programs: toFilterOptions(programNames),
                clients: toFilterOptions(clientNames),
                coaches: toFilterOptions(coachNames),
                attentionNeeded: toFilterOptions(attentionNeeded),
              };
            });
            setData(response.data);
            setLoadingData(false);
            return () => (isCanceled = true);
          }}
        />
        <Table
          pagination={false}
          size='small'
          dataSource={data}
          columns={columns}
          bordered
          loading={!data || loadingData}
          rowKey={record => `${record.userId}-${record.programId}`}
          scroll={{ y: '51vh' }}
          locale={{
            emptyText: (
              <div style={{ padding: '2em' }}>
                No data found, please search for a client
              </div>
            ),
          }}
        />
      </Space>
      {selectedNotesRecord && (
        <EngagementRecordNotesModal
          record={selectedNotesRecord}
          user={user}
          updateLocalRecord={updateLocalRecord}
          onClose={() => setSelectedNotesRecord(null)}
        />
      )}
      {selected360sRecord && (
        <EngagementRecord360sModal
          record={selected360sRecord}
          setSelected360sRecord={setSelected360sRecord}
          updateLocalRecord={updateLocalRecord}
          setShowNotificationsModal={setShowNotificationsModal}
          onClose={() => setSelected360sRecord(null)}
        />
      )}
      {showNotificationsModal && (
        <Send360NotificationsModal
          record={selected360sRecord}
          updateLocalRecord={updateLocalRecord}
          onClose={() => {
            setSelected360sRecord(null);
            setShowNotificationsModal(false);
          }}
        />
      )}
    </Card>
  );
};

interface FilterOption {
  label: string;
  value: string;
  text: string;
}

const toFilterOptions = (set: Set<string>): FilterOption[] => {
  return [...set].map(status => {
    return {
      text: status,
      label: status,
      value: status,
    };
  });
};

interface TableFilters {
  tags: FilterOption[];
  statuses: FilterOption[];
  programs: FilterOption[];
  clients: FilterOption[];
  coaches: FilterOption[];
  attentionNeeded: FilterOption[];
  notes: FilterOption[];
}

const createColumns = (
  setSelectedNotesRecord: (record: UserEngagementRecord) => void,
  setSelected360sRecord: (record: UserEngagementRecord) => void,
  filters: TableFilters,
  setLoadingData: (record: boolean) => void,
  setData: (record: UserEngagementRecord[]) => void,
  toast,
): ColumnsType<UserEngagementRecord> => {
  const columns: ColumnsType<UserEngagementRecord> = [
    {
      title: 'Program',
      dataIndex: 'programName',
      ellipsis: true,
      key: 'programName',
      filters: filters.programs,
      onFilter: (value: string, record) => record.programName === value,
      filterSearch: true,
      render(_, record) {
        return (
          <Link variant='a2dash' href={`program/${record.programId}`}>
            {record.programName}
          </Link>
        );
      },
    },
    {
      title: 'User',
      key: 'user',
      ellipsis: true,
      render(record) {
        return (
          <Stack direction='row' gap={0.5}>
            <Link title={record.email} href={`mailto:${record.email}`}>
              <MailOutlinedIcon fontSize='small' />
            </Link>
            <strong>{record.name}</strong>
          </Stack>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      filters: filters.statuses,
      onFilter: (value: string, record) => record.status === value,
      filterSearch: true,
    },
    {
      title: 'Coach',
      dataIndex: 'coachName',
      key: 'coachName',
      ellipsis: true,
      filters: filters.coaches,
      onFilter: (value: string, record) => record.coachName === value,
      filterSearch: true,
    },
    {
      title: 'Activity',
      dataIndex: ['lastActivity'],
      defaultSortOrder: 'descend',
      width: 110,
      sorter: (a, b) => {
        return +a.lastActivity - +b.lastActivity;
      },
      render: (lastActivity: Date) => {
        if (!lastActivity) {
          return null;
        }
        return dayjs(lastActivity).format('M/D h:mm a');
      },
      key: 'lastActivity',
    },
    {
      title: 'Issues',
      dataIndex: 'attentionNeeded',
      key: 'attentionNeeded',
      ellipsis: true,
      filters: [
        ...filters.attentionNeeded,
        {
          text: 'No issues',
          label: 'No issues',
          value: '',
        },
      ],
      onFilter: (value: string, record) => record.attentionNeeded === value,
      filterSearch: true,
    },
    {
      title: 'Ses. Missed',
      dataIndex: 'missedSessions',
      align: 'center' as 'center',
      width: 100,
      render: missedSessions => {
        if (!missedSessions) {
          return 'None';
        }
        return missedSessions;
      },
      key: 'missedSessions',
    },
    {
      title: 'Eng. Sur.',
      dataIndex: 'midpointEngagementSurvey',
      key: 'midpointEngagementSurvey',
      align: 'center' as 'center',
      width: 90,
      render: midpointEngagementSurvey => {
        if (!midpointEngagementSurvey) {
          return 'None';
        }
        return dayjs(midpointEngagementSurvey).format('M/D/YY');
      },
    },
    {
      title: '360s',
      align: 'center' as 'center',
      width: 50,
      render: record => {
        return record.userId ? (
          <Link
            component='button'
            variant='a2dash'
            onClick={() => {
              setSelected360sRecord(record);
            }}
          >
            {record.assessmentStakeholders?.filter(
              as => as.survey_submission_id,
            )?.length || 0}
            /{record.assessmentStakeholders?.length || 0}
          </Link>
        ) : (
          <>N/A</>
        );
      },
    },
    {
      title: 'Notes',
      align: 'center' as 'center',
      width: 70,
      render: record => (
        <Link
          component='button'
          className={`record-notes ${
            record.administration?.notes?.length ? 'record-has-notes' : ''
          }`}
          onClick={() => {
            setSelectedNotesRecord(record);
          }}
        >
          <NotesIcon fontSize='small' />
        </Link>
      ),
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      align: 'center' as 'center',
      width: 80,
      render: (_, record) => (
        <Switch
          defaultChecked={record.administration?.priority}
          size='small'
          onChange={async val => {
            try {
              await api.engagement.setPriority({
                user_email: record.email,
                priority: val,
                program_id: record.programId,
              });
              setLoadingData(true);
              const newData = await api.engagement.getData(record.clientId);
              setData(newData.data);
              setLoadingData(false);
            } catch (e) {
              toast.error('Could not change priority');
            }
          }}
        />
      ),
      filters: [
        {
          text: 'Priority',
          value: true,
        },
        {
          text: 'Non priority',
          value: false,
        },
      ],
      onFilter: (value: boolean, record) =>
        record.administration?.priority === value ? true : false,
    },
  ];
  return columns;
};
