import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';
import { UserType } from './user';

obey.creator('uuid', () => uuidv4());
obey.creator('now', () => Date.now());

export interface Note {
  createdAt: number;
  createdBy: UserType;
  content: string;
}

export interface Administration {
  id: string;
  user_email: string;
  program_id: string;
  notes: Note[];
  priority: boolean;
  createdAt: number;
  createdBy: string;
  updatedAt?: number;
  updatedBy?: string;
}

export const AdministrationSchema = {
  id: { type: 'string', required: true, creator: 'uuid' },
  user_email: { type: 'string', requried: true },
  program_id: { type: 'string', requried: true },
  notes: {
    type: 'array',
    empty: true,
    default: [],
    values: {
      type: 'object',
      keys: {
        createdAt: { type: 'number', creator: 'now' },
        createdBy: { type: 'string' },
        content: { type: 'string' },
      },
    },
  },
  priority: { type: 'boolean', required: true, default: false },
  createdAt: { type: 'number', required: true, creator: 'now' },
  createdBy: { type: 'string', required: true },
  updatedAt: { type: 'number' },
  updatedBy: { type: 'string' },
};

export const AdministrationModel = obey.model(AdministrationSchema);
