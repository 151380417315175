import obey from 'obey';

export interface PlatformConfig {
  features: {
    [key: string]: boolean;
  };
}

export const PlatformConfigSchema = {
  features: {
    type: 'object',
    strict: false,
  },
};

export const PlatformConfigModel = obey.model(PlatformConfigSchema);
