import React, { FC, useState, useMemo } from 'react';
import { Divider, Link, Stack, Typography } from '@mui/material';
import { Card, useBreadcrumbs } from '@src/components/sc-design-system';
import User from '@src/models/User';
import { FeedbackStatusModal } from '@src/components/common/FeedbackStatusModal';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { useNavigate } from 'react-router';
import { useUser } from '@src/hooks/useUser';
import { RequestFeedbackModal } from '@src/components/common/RequestFeedbackModal';

interface OngoingFeedbackProps {
  profileUser: User;
  clientId: string;
  selectedPerformanceCycle: PerformanceCycleI;
}

const OngoingFeedback: FC<OngoingFeedbackProps> = ({
  profileUser,
  clientId,
  selectedPerformanceCycle,
}) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { appendBreadcrumb } = useBreadcrumbs();
  const [feedbackStatusModalOpen, setFeedbackStatusModalOpen] = useState(false);
  const [feedbackInviteModalOpen, setFeedbackInviteModalOpen] = useState(false);
  const { useGetPerformanceCycleFeedbackStatusForMember } =
    useClientQueries(clientId);
  const { feedbackData } = useGetPerformanceCycleFeedbackStatusForMember(
    selectedPerformanceCycle.id,
    selectedPerformanceCycle.ongoing_feedback_form_id,
    profileUser.id,
  );

  const isReportReady = useMemo(() => {
    if (!feedbackData) return false;
    if (feedbackData?.stakeholders?.length < 5) return false;
    const completedTotal = feedbackData?.stakeholders.reduce((num, s) => {
      if (s.feedback_submission_id) {
        return num + 1;
      }
      return num;
    }, 0);
    return completedTotal >= 5;
  }, [feedbackData]);

  return (
    <Stack sx={{ marginBottom: '1.5em' }}>
      <Typography variant='h3' sx={{ marginBottom: '0.5em' }}>
        Ongoing feedback
      </Typography>
      <Card elevation={5} variant='elevation'>
        <Typography variant='h4' sx={{ marginBottom: '0.5em' }}>
          Feedback
        </Typography>
        <Typography sx={{ marginBottom: '1.5em' }}>
          Manage stakeholders and view feedback (once available) for your direct
          report. You will be able to view report once there is enough feedback.
        </Typography>
        <Stack
          direction='row'
          spacing={2}
          divider={<Divider orientation='vertical' flexItem />}
        >
          {isReportReady && (
            <Link
              variant='a1'
              onClick={() => {
                const path = `/feedback-report?clientId=${clientId}&performanceCycleId=${
                  selectedPerformanceCycle.id
                }&feedbackFormId=${
                  selectedPerformanceCycle.ongoing_feedback_form_id
                }&userId=${profileUser.id}&name=${encodeURIComponent(
                  `${profileUser.first_name} ${profileUser.last_name}`,
                )}`;
                appendBreadcrumb({
                  path,
                  label: `Report for ${profileUser.first_name} ${profileUser.last_name}`,
                });
                navigate(path, {
                  state: {
                    employeeName: `${profileUser.first_name} ${profileUser.last_name}`,
                  },
                });
              }}
            >
              View report
            </Link>
          )}
          <Link
            variant='a1'
            sx={{ width: 'fit-content' }}
            onClick={() => {
              if (user.is_admin) {
                setFeedbackInviteModalOpen(true);
              } else {
                setFeedbackStatusModalOpen(true);
              }
            }}
          >
            Manage participants
          </Link>
        </Stack>
        {feedbackStatusModalOpen && (
          <FeedbackStatusModal
            open
            onClose={() => setFeedbackStatusModalOpen(false)}
            clientId={clientId}
            userId={profileUser.id}
            userFirstName={profileUser.first_name}
            userLastName={profileUser.last_name}
            performanceCycleId={selectedPerformanceCycle.id}
            feedbackFormId={selectedPerformanceCycle.ongoing_feedback_form_id}
            view='member'
          />
        )}
        {feedbackInviteModalOpen && (
          <RequestFeedbackModal
            open={feedbackInviteModalOpen}
            onClose={() => setFeedbackInviteModalOpen(false)}
            clientId={clientId}
            userId={profileUser.id}
            userFirstName={profileUser.first_name}
            userLastName={profileUser.last_name}
            performanceCycleId={selectedPerformanceCycle.id}
            feedbackFormId={selectedPerformanceCycle.ongoing_feedback_form_id}
          />
        )}
      </Card>
    </Stack>
  );
};

export { OngoingFeedback };
