import Program from '@src/models/Program';
import { observer } from 'mobx-react-lite';
import React, { useState, ReactChild, useEffect, FC } from 'react';
import ProgramEditorForm from '@src/components/forms/ProgramEditorForm/ProgramEditorForm';
import { api } from '@src/lib/client';
import SOW from '@src/models/SOW';
import {
  useToast,
  DialogTitle,
  Button,
} from '@src/components/sc-design-system';
import { handleKeyboardNav } from '@src/utils';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

interface ProgramEditorProps {
  program: Program;
  children: ReactChild;
  onSaveNew?: Function;
  updateCurrentProgram?: Function;
  currentSOW?: SOW;
  isAdmin?: boolean;
}

const ProgramEditor: FC<ProgramEditorProps> = observer(
  ({
    program,
    children,
    onSaveNew,
    updateCurrentProgram,
    currentSOW,
    isAdmin,
  }) => {
    const queryClient = useQueryClient();
    const toast = useToast();
    const [modalOpen, setModalOpen] = useState(false);
    const [currentProgram, setCurrentProgram] = useState<Program>();
    const [isSaving, setIsSaving] = useState(false);
    const [isValid, setIsValid] = useState(true);
    useEffect(
      () => {
        setCurrentProgram(new Program(program.data));
      },
      // FIXME: invalid dependency array
      // eslint-disable-next-line
      [modalOpen],
    );
    return (
      <>
        {modalOpen && (
          <Dialog open onClose={() => setModalOpen(false)}>
            <DialogTitle
              title='Program Editor'
              buttonOnClick={() => setModalOpen(false)}
            />
            <DialogContent>
              {currentProgram && (
                <ProgramEditorForm
                  setIsValid={setIsValid}
                  currentProgram={currentProgram}
                  sow={currentSOW || program.sows[0]}
                  isAdmin={isAdmin}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant='outlined'
                text='Close'
                onClick={() => setModalOpen(false)}
              />
              <Button
                text='Save'
                onClick={async () => {
                  setIsSaving(true);
                  let nonMemberSessionsUsed = [];

                  if (!onSaveNew) {
                    const programMembers = program.members.map(
                      member => member.id,
                    );
                    const programSessions = await api.sessions.getByProgram({
                      programId: program.id,
                    });
                    nonMemberSessionsUsed = programSessions.data.data.filter(
                      ps =>
                        ps.members.every(
                          member => !programMembers.includes(member.id),
                        ) &&
                        ps.type === 'coaching' &&
                        ps.current_status !== 'canceled',
                    );
                    const sessions_allocated =
                      program.members.reduce((acc, member) => {
                        return acc + member.sessions_allocated;
                      }, 0) || 0;
                    const minimum =
                      sessions_allocated + nonMemberSessionsUsed.length;
                    if (currentProgram.sessions_reserved < minimum) {
                      const errorMessage = `Sessions reserved must equal or exceed ${minimum}`;
                      toast.error(errorMessage);
                      setIsSaving(false);
                      return;
                    }
                  }

                  const sow = currentSOW || program.sows[0];
                  const sowSessionsReserved = sow.sessions_reserved || 0;
                  const allPrograms = await api.programs.get({
                    queryBy: 'sow',
                    queryId: sow.id,
                  });
                  const otherPrograms = allPrograms.data.data.filter(
                    p => p.id !== program.id,
                  );
                  const otherProgramsSessionsReserved =
                    otherPrograms.reduce((acc, program) => {
                      return acc + program.sessions_reserved;
                    }, 0) || 0;

                  const calculatedMax =
                    sowSessionsReserved -
                    otherProgramsSessionsReserved -
                    nonMemberSessionsUsed.length;
                  const maximum = calculatedMax < 0 ? 0 : calculatedMax;

                  if (currentProgram.sessions_reserved > maximum) {
                    const errorMessage = `Sessions reserved cannot exceed ${maximum}`;
                    toast.error(errorMessage);
                    setIsSaving(false);
                    return;
                  }

                  try {
                    await currentProgram.save();
                    if (onSaveNew) {
                      onSaveNew(currentProgram);
                    } else if (updateCurrentProgram) {
                      updateCurrentProgram(new Program(currentProgram.data));
                    } else {
                      program.mergeData(currentProgram.data);
                    }
                    setModalOpen(false);
                    queryClient.invalidateQueries({ queryKey: ['programs'] });
                    toast.success('Program record saved successfully');
                  } catch (e) {
                    /* no-op, message from class */
                  }
                  setIsSaving(false);
                }}
                loading={isSaving}
                disabled={!isValid}
              />
            </DialogActions>
          </Dialog>
        )}
        <span
          role='button'
          tabIndex={0}
          className='modal-open-click-target'
          style={{ cursor: 'pointer' }}
          onClick={() => setModalOpen(true)}
          onKeyDown={e => handleKeyboardNav(e.key, () => setModalOpen(true))}
        >
          {children}
        </span>
      </>
    );
  },
);

export default ProgramEditor;
