import React, { FC, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, DialogTitle } from '@src/components/sc-design-system';
import { RecipientsSection } from './components/RecipientsSection';
import { NotificationsSection } from './components/NotificationsSection';
import { StyledCard } from '../../../StyledComponents';

interface SendStepProps {
  isDraftMode: boolean;
  handleClose: Function;
  onClickAddRecipients: Function;
  isSaving?: boolean;
  isSendReady: boolean;
}

const SendStep: FC<SendStepProps> = ({
  isDraftMode,
  handleClose,
  onClickAddRecipients,
  isSaving = false,
  isSendReady,
}) => {
  const {
    control,
    formState: { isValid },
    getValues,
    setValue,
  } = useFormContext();
  const [isEditingMessage, setIsEditingMessage] = useState(false);

  return (
    <>
      <DialogTitle
        title='Send Survey'
        buttonIsDisabled={isSaving}
        buttonOnClick={() => handleClose()}
      />
      <DialogContent>
        <Typography variant='h4' sx={{ marginBottom: 1 }}>
          Collection Name
        </Typography>
        {isDraftMode ? (
          <>
            <Controller
              name='name'
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    label=''
                    fullWidth
                    placeholder='Enter name here'
                    disabled={isSaving}
                    {...field}
                  />
                );
              }}
            />
          </>
        ) : (
          <StyledCard style={{ padding: '1.5em' }}>
            {getValues('name')}
          </StyledCard>
        )}
        <Divider />
        <RecipientsSection
          onButtonClick={onClickAddRecipients}
          isDraftMode={isDraftMode}
          isSaving={isSaving}
        />
        <Divider />
        <NotificationsSection
          isDraftMode={isDraftMode}
          onEditMessage={setIsEditingMessage}
          isSaving={isSaving}
        />
      </DialogContent>
      <DialogActions>
        {isDraftMode && (
          <Button
            id='save'
            text='Save for later'
            variant='outlined'
            type='submit'
            disabled={isSaving || !isValid}
          />
        )}
        <Button
          text='Send Survey'
          type='submit'
          id='send-survey'
          disabled={isSaving || !isValid || isEditingMessage || !isSendReady}
          onClick={() => {
            setValue('status', 'sent');
          }}
        />
      </DialogActions>
    </>
  );
};

export { SendStep };
