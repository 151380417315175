import { HTTPClient } from '@src/lib/client';

export class AdminAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async getSessionsCalendarData(start: number, end: number) {
    return this.http.request({
      url: `/admin/sessions-calendar?start=${start}&end=${end}`,
      method: 'get',
    });
  }
}
