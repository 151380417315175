import React, { FC, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { Select as SelectAntd } from 'antd';
import { Typography, Link, Tabs, Tab, Stack, Switch } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { UserSchema } from '@shared/schemas';
import {
  Card,
  Avatar,
  Spin,
  Button,
  Icon,
  Table,
  useToast,
  ConfirmDialog,
  TextField,
  Select,
} from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import User from '@src/models/User';
import { useStore } from '@src/models/Store';
import { useNavigate } from 'react-router';
import * as useVerbiage from '@shared/lib/src/use-verbiage';
import { MemberFeedbackCell } from './MemberFeedbackCell';
import ProgramMemberModal from '@src/components/views/Administration/Programs/ProgramMemberModal';
import { StakeholderManagerModal } from '@src/components/common/StakeholderManagerModal';
import { ProgramMemberViewModel, ProgramViewModel } from '../ProgramViewModel';
import SessionTracker from '@src/components/common/SessionTracker/SessionTracker';
import {
  TabPanelWrapper,
  generateTabProps,
} from '@src/components/common/TabPanelWrapper';
import Program from '@src/models/Program';

interface ProgramMembersCardProps {
  vm: ProgramViewModel;
  program: Program;
}

export const ProgramMembersCard: FC<ProgramMembersCardProps> = ({
  vm,
  program,
}) => {
  const store = useStore();
  const [activeTab, setActiveTab] = useState(0);
  const tabs = useMemo(() => {
    const items = [];
    if (vm?.program?.type === 'custom_coach_selection') {
      items.push({ label: 'Program Members', ...generateTabProps(0) });
      items.push({
        label: 'Program Coaches',
        ...generateTabProps(1),
      });
    }
    if (vm?.program?.type === 'mentoring') {
      items.push({ label: 'Program Members', ...generateTabProps(0) });
      items.push({
        label: 'Program Mentors',
        ...generateTabProps(1),
      });
    }
    return items;
  }, [vm?.program?.type]);

  return (
    <Card
      title='Members'
      titleActions={
        <ProgramMemberModal
          key={`${program?.id}-members`}
          program={program}
          isAdmin={store.user.is_admin}
          sessions={vm?.coachingSessions}
          onUpdate={() => {
            vm?.loadMembers();
          }}
        >
          <Button text='Manage Members' size='small' />
        </ProgramMemberModal>
      }
    >
      <div className='program-view'>
        {tabs.length > 0 && (
          <Tabs
            onChange={(_, v) => {
              setActiveTab(v);
            }}
            value={activeTab}
          >
            {tabs.map(tab => (
              <Tab key={tab.label} label={tab.label} />
            ))}
          </Tabs>
        )}
        <TabPanelWrapper value={activeTab} index={0}>
          <ProgramMembers
            vm={vm}
            isAdmin={store.user.is_admin}
            programId={program?.id}
          />
        </TabPanelWrapper>
        <TabPanelWrapper value={activeTab} index={1}>
          {vm?.program?.type === 'custom_coach_selection' ? (
            <CustomCoaches vm={vm} />
          ) : (
            <ClientMentors vm={vm} />
          )}
        </TabPanelWrapper>
      </div>
    </Card>
  );
};

let ProgramMembers = ({
  isAdmin,
  vm,
  programId,
}: {
  isAdmin: boolean;
  vm: ProgramViewModel;
  programId: string;
}) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState('');
  const [isStakeholderModalOpen, setIsStakeholderModalOpen] = useState(false);
  const [stakeholderModalProps, setStakeholderModalProps] = useState(null);
  const rows = vm?.members.filter(({ user }) => {
    return (
      user.first_name.toLowerCase().includes(filter) ||
      user.last_name.toLowerCase().includes(filter)
    );
  });
  const v = useVerbiage.init(
    vm?.program?.type === 'mentoring' ? ['isMentor'] : [],
  );

  const toggleStakeholderModal = props => {
    setStakeholderModalProps(props);
    setIsStakeholderModalOpen(!isStakeholderModalOpen);
  };

  const columns: any[] = [
    {
      title: 'Name',
      key: 'name',
      width: '150px',
      dataIndex: ['first_name', 'last_name'],
      render(_, uservm: ProgramMemberViewModel) {
        return <UserAvatar user={uservm?.user} />;
      },
    },
    {
      title: 'Interviews',
      key: 'interviews',
      width: '72px',
      responsive: ['lg'],
      align: 'center',
      render(_, uservm: ProgramMemberViewModel) {
        const render = (info: {
          color: 'success' | 'warning' | 'error';
          icon: string;
          label?: string;
          altText: string;
        }) => {
          return (
            <div className='interviews-cell'>
              <Icon
                name={info.icon}
                color={info.color}
                className='cell-icon'
                altText={info.altText}
                tooltipText={info.altText}
              />
              {info.label && <span>{info.label}</span>}
            </div>
          );
        };

        const { stats } = uservm;
        if (stats.completedInterviews) {
          const label = `(${stats.completedInterviews})`;
          return render({
            color: 'success',
            icon: 'checkCircleOutline',
            label,
            altText: 'Completed',
          });
        } else if (stats.scheduledInterviews) {
          const label = `(${stats.scheduledInterviews})`;
          return render({
            color: 'warning',
            icon: 'calendarTodayOutlined',
            label,
            altText: 'Scheduled',
          });
        } else {
          return render({
            color: 'error',
            icon: 'cancelOutlined',
            altText: 'No Interviews',
          });
        }
      },
    },
    {
      title: 'Self-Assessment',
      key: 'self_assessment',
      width: '140px',
      align: 'center',
      render(_, uservm: ProgramMemberViewModel) {
        const render = (info: {
          color: 'success' | 'warning' | 'error';
          icon: string;
          altText: string;
        }) => {
          return (
            <div className='interviews-cell'>
              <Icon
                className='cell-icon'
                name={info.icon}
                color={info.color}
                altText={info.altText}
                tooltipText={info.altText}
              />
            </div>
          );
        };

        const { user } = uservm;
        if (user.self_assessment_submitted) {
          return render({
            color: 'success',
            icon: 'checkCircleOutline',
            altText: 'Submitted',
          });
        } else {
          return render({
            color: 'error',
            icon: 'cancelOutlined',
            altText: 'Not Submitted',
          });
        }
      },
    },
    {
      title: 'Feedback',
      key: 'feedback',
      width: '90px',
      align: 'right',
      render(_, uservm: ProgramMemberViewModel) {
        const { user } = uservm;
        return (
          <MemberFeedbackCell
            programId={vm.program.id}
            memberId={user.id}
            onButtonClick={() =>
              toggleStakeholderModal({
                programId: vm?.program?.id,
                memberId: user.id,
                isActive: true,
              })
            }
          />
        );
      },
    },
    ...(isAdmin
      ? [
          {
            title: 'Report',
            width: '75px',
            align: 'center',
            render(_, uservm: ProgramMemberViewModel) {
              const { user } = uservm;
              const stakeholders = user?.assessment_stakeholders || [];
              const hasResponses = stakeholders.some(
                s => s.survey_submission_id,
              );
              return hasResponses && user.self_assessment_submitted ? (
                <Link
                  variant='a1'
                  component='button'
                  onClick={() => {
                    navigate(`/report/${user.id}/${programId}`);
                  }}
                >
                  <OpenInNewIcon fontSize='small' />
                </Link>
              ) : (
                'n/a'
              );
            },
          },
        ]
      : []),
    {
      title: v('coach', true),
      key: 'coach',
      width: '180px',
      dataIndex: ['first_name', 'lastname'],
      render(_, uservm: ProgramMemberViewModel) {
        return <CoachSelector user={uservm?.user} vm={vm} />;
      },
    },
    {
      title: 'Allocated',
      key: 'allocated',
      responsive: ['md'],
      inputType: 'a',
      width: '90px',
      render(_, uservm: ProgramMemberViewModel) {
        return <SessionAllocator vm={vm} uservm={uservm} />;
      },
    },
    {
      title: 'Sessions',
      key: 'sessions',
      responsive: ['md'],
      render(_, uservm: ProgramMemberViewModel) {
        return <SessionTrackerCell vm={vm} uservm={uservm} />;
      },
    },
  ];
  return (
    <Stack spacing={3}>
      {isStakeholderModalOpen && (
        <StakeholderManagerModal
          {...stakeholderModalProps}
          isEditable={isAdmin}
          onCancel={() => toggleStakeholderModal(null)}
          open
        />
      )}
      <TextField
        id='search'
        placeholder='Search Members'
        onChange={e => {
          const value = e.target.value.trim().toLocaleLowerCase();
          setFilter(value);
        }}
      />
      <Table
        dataSource={rows.filter(r => !r.removedAt)}
        rowKey='id'
        columns={columns}
        scroll={{ x: true }}
      />
    </Stack>
  );
};
ProgramMembers = observer(ProgramMembers);

let CustomCoaches = ({ vm }: { vm: ProgramViewModel }) => {
  const [filter, setFilter] = React.useState('');
  const [availabilityChangeLoading, setAvailabilityChangeLoading] =
    useState(false);
  const rows = vm?.coaches.filter(coach => {
    return (
      coach.first_name.toLowerCase().includes(filter) ||
      coach.last_name.toLowerCase().includes(filter)
    );
  });

  const columns: any[] = [
    {
      title: 'Name',
      key: 'name',
      width: 'auto',
      dataIndex: ['first_name', 'last_name'],
      render(_, coach) {
        return <UserAvatar user={coach} />;
      },
    },
    {
      title: 'Program Availability',
      key: 'available',
      width: 'auto',
      render(_, coach) {
        return (
          <Switch
            key={coach.id}
            checked={!!vm?.program?.coaches.find(m => m === coach.id)}
            disabled={availabilityChangeLoading === coach.id}
            onChange={async e => {
              setAvailabilityChangeLoading(coach.id);
              await vm?.program?.changeCoachAvailability(
                coach.id,
                e.target.checked,
              );
              setAvailabilityChangeLoading(false);
            }}
          />
        );
      },
    },
  ];
  return (
    <Stack spacing={3}>
      <TextField
        id='search'
        placeholder='Search Coaches'
        onChange={e => {
          const value = e.target.value.trim().toLocaleLowerCase();
          setFilter(value);
        }}
      />
      <Table
        dataSource={rows}
        rowKey='id'
        columns={columns}
        bordered={true}
        scroll={{ x: true }}
      />
    </Stack>
  );
};
CustomCoaches = observer(CustomCoaches);

let ClientMentors = ({ vm }: { vm: ProgramViewModel }) => {
  const [filter, setFilter] = React.useState('');
  const [availabilityChangeLoading, setAvailabilityChangeLoading] =
    useState(false);
  const rows = vm?.mentors.filter(mentor => {
    return (
      mentor.first_name.toLowerCase().includes(filter) ||
      mentor.last_name.toLowerCase().includes(filter)
    );
  });

  const columns: any[] = [
    {
      title: 'Name',
      key: 'name',
      width: 'auto',
      dataIndex: ['first_name', 'last_name'],
      render(_, mentor) {
        return <UserAvatar user={mentor} />;
      },
    },
    {
      title: 'Program Availability',
      key: 'available',
      width: 'auto',
      render(_, mentor) {
        return (
          <Switch
            key={mentor.id}
            checked={!!vm?.program?.mentors.find(m => m === mentor.id)}
            disabled={availabilityChangeLoading === mentor.id}
            onChange={async e => {
              setAvailabilityChangeLoading(mentor.id);
              await vm?.program?.changeMentorAvailability(
                mentor.id,
                e.target.checked,
              );
              setAvailabilityChangeLoading(false);
            }}
          />
        );
      },
    },
  ];
  return (
    <Stack spacing={3}>
      <TextField
        id='search'
        placeholder='Search Mentors'
        onChange={e => {
          const value = e.target.value.trim().toLocaleLowerCase();
          setFilter(value);
        }}
      />
      <Table
        dataSource={rows}
        rowKey='id'
        columns={columns}
        bordered={true}
        scroll={{ x: true }}
      />
    </Stack>
  );
};
ClientMentors = observer(ClientMentors);

let SessionAllocator = ({
  vm,
  uservm,
}: {
  vm: ProgramViewModel;
  uservm: ProgramMemberViewModel;
}) => {
  const options: { label: string; value: number }[] = [];
  const allowed = uservm?.allowedSessionAllocations;
  for (let i = allowed.min; i <= allowed.max; i++) {
    options.push({ label: `${i}`, value: i });
  }
  return (
    <Select
      sx={{ minWidth: '24px' }}
      className='session-allocation-selector'
      options={[{ options }]}
      value={uservm?.sessions_allocated}
      onChange={async e => {
        const val = e.target.value as number;
        await vm?.program?.updateMemberSessionAllocation(uservm?.id, val);
      }}
    />
  );
};
SessionAllocator = observer(SessionAllocator);

let SessionTrackerCell = ({
  vm,
  uservm,
}: {
  vm: ProgramViewModel;
  uservm: ProgramMemberViewModel;
}) => {
  const store = useStore();
  const allowedSessions = uservm?.sessions_allocated;
  return (
    <SessionTracker
      coacheeSessions={uservm?.coachingSessions}
      user={store.user}
      member={uservm?.user}
      allowedSessions={allowedSessions}
      program={vm?.program}
    />
  );
};
SessionTrackerCell = observer(SessionTrackerCell);

let CoachSelector = ({ vm, user }: { vm: ProgramViewModel; user: User }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = React.useState(false);
  const [removeCoachDialogOpen, setRemoveCoachDialogOpen] =
    React.useState(false);
  const [list, setList] = useState([]);
  const store = useStore();
  const coach = user.selected_coach;
  const v = useVerbiage.init(
    vm?.program?.type === 'mentoring' ? ['isMentor'] : [],
  );

  // Handler for onClear and onChange
  const updateCoach = async (
    userId: string,
    newCoachId: string | undefined,
  ): Promise<void> => {
    const oldCoach = user.selected_coach;
    const oldCoachSelectedAt = user.selected_coach_at;
    const newCoach = list.find(c => c.id === newCoachId);
    if (newCoachId && !newCoach) {
      throw new Error(`Could not retrieve ${v('coach')} object`);
    }
    runInAction(() => {
      user.selected_coach = newCoach;
      user.selected_coach_at = Date.now();
    });
    try {
      await vm?.program?.changeMemberSelectedCoach(userId, newCoachId);
    } catch (e) {
      toast.error(`Could not update selected ${v('coach')}`);
      runInAction(() => {
        user.selected_coach = oldCoach;
        user.selected_coach_at = oldCoachSelectedAt;
      });
    }
  };
  const element = coach ? (
    <UserAvatar user={coach} />
  ) : (
    <Typography variant='body2' color='grey.700'>
      None
    </Typography>
  );

  if (
    store.user.is_admin ||
    (vm?.program?.clients[0].members.find(
      m => m.role === 'manager' && m.id === store.user.id,
    ) &&
      vm?.program?.type === 'mentoring')
  ) {
    return (
      <>
        <ConfirmDialog
          open={removeCoachDialogOpen}
          title={`Remove this ${v('coach')}?`}
          setOpenModal={setRemoveCoachDialogOpen}
          body={
            <Typography>
              <strong>
                {coach?.first_name} {coach?.last_name}
              </strong>{' '}
              will be removed as{' '}
              <strong>
                {user?.first_name} {user?.last_name}&apos;s
              </strong>{' '}
              {v('coach')}.
            </Typography>
          }
          buttonProps={{
            text: 'Confirm',
            onClick: () => {
              updateCoach(user.id, undefined);
            },
          }}
        />
        <SelectAntd
          allowClear={focused}
          filterOption
          labelInValue
          loading={loading}
          notFoundContent={
            loading ? (
              <Spin />
            ) : (
              <div style={{ textAlign: 'center' }}>
                Search for a {v('coach')}
              </div>
            )
          }
          optionFilterProp='label'
          options={list.map(li => {
            return {
              label: `${li.first_name} ${li.last_name}`,
              value: li.id,
            };
          })}
          placeholder='None'
          showArrow={false}
          showSearch
          style={{ width: '100%' }}
          value={
            coach
              ? {
                  label: `${coach.first_name} ${coach.last_name}`,
                  value: coach.id,
                }
              : undefined
          }
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setList([]);
            setFocused(false);
          }}
          onClear={() => {
            setRemoveCoachDialogOpen(true);
          }}
          onChange={val => {
            if (val?.value) {
              updateCoach(user.id, val.value);
            }
          }}
          onSearch={val => {
            if (vm?.program?.type === 'custom_coach_selection') {
              searchProgramCoaches(vm?.program?.id, setList, setLoading);
            } else if (vm?.program?.type === 'mentoring') {
              searchMentors(vm?.program?.id, setList, setLoading);
            } else {
              searchCoaches(
                {
                  type: 'coaches',
                  keyword: val,
                  limit: 20,
                },
                setList,
                setLoading,
              );
            }
          }}
        />
      </>
    );
  } else {
    return element;
  }
};
CoachSelector = observer(CoachSelector);

const UserAvatar = ({
  user,
}: {
  user: User | { id; first_name; last_name };
}) => {
  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Avatar srcId={user.id} alt={`${user.first_name} ${user.last_name}`} />
      <Typography variant='body2'>
        {user.first_name}&nbsp;{user.last_name}
      </Typography>
    </Stack>
  );
};

let currentGetReqTimeout: any;

interface listData {
  type: 'coaches';
  keyword: string;
  limit: number;
}

const searchCoaches = async (
  params: listData,
  setList: Function,
  setLoading: Function,
): Promise<void> => {
  // Debounce to prevent keyword onchange from blasting the api
  if (currentGetReqTimeout) clearTimeout(currentGetReqTimeout);
  currentGetReqTimeout = setTimeout(async () => {
    setLoading(true);
    const res = await api.users.getList(params);
    setList(res.data.data.map((d: typeof UserSchema) => new User(d)));
    setLoading(false);
  }, 250);
};

const searchProgramCoaches = async (
  programId: string,
  setList: Function,
  setLoading: Function,
): Promise<void> => {
  // Debounce to prevent keyword onchange from blasting the api
  if (currentGetReqTimeout) clearTimeout(currentGetReqTimeout);
  currentGetReqTimeout = setTimeout(async () => {
    setLoading(true);
    const res = await api.programs.getProgramCoaches(programId);
    setList(res.data);
    setLoading(false);
  }, 250);
};

const searchMentors = async (
  programId: string,
  setList: Function,
  setLoading: Function,
): Promise<void> => {
  // Debounce to prevent keyword onchange from blasting the api
  if (currentGetReqTimeout) clearTimeout(currentGetReqTimeout);
  currentGetReqTimeout = setTimeout(async () => {
    setLoading(true);
    const res = await api.programs.getProgramMentors(programId);
    setList(res.data);
    setLoading(false);
  }, 250);
};
