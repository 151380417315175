import './Program.less';
import React from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { Row, Col } from 'antd';
import {
  Button,
  Card,
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
} from '@src/components/sc-design-system';
import Program from '../../../models/Program';
import { useStore } from '../../../models/Store';
import ProgramEditor from '../Administration/Programs/ProgramEditor';
import { useProgramViewModel } from './ProgramViewModel';
import { ClientDashboardProgramActivity } from '@src/components/common/ClientDashboardProgramActivity';
import InviteProgramModal from '@src/components/forms/InviteModal/InviteProgramModal';
import { ProgramSurveys } from './ProgramSurveys';
import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import ActiveInactiveChip from '@src/components/common/ActiveInactiveChip';
import { ProgramMembersCard } from './components/ProgramMembersCard';
import { useClientQueries } from '@src/hooks/useClientQueries';

export const ProgramView = () => {
  const params = useParams();
  const store = useStore();
  const [result, retry] = useProgramViewModel(params.id);
  const vm = result?.value;
  const { program } = vm || {};

  const isActive = (start: number, end: number): boolean =>
    start < Date.now() && end > Date.now();

  const programTypes = {
    platform_only: 'Platform-only',
    mentoring: 'Mentoring',
    standard_coaching: 'Standard Coaching',
    custom_coach_selection: '1:1 Coaching',
  };
  const { useGetClientMetrics } = useClientQueries(program?.client_id);
  const { data: clientMetrics } = useGetClientMetrics();

  const {
    membersByProgram,
    allocatedSessionsByProgram,
    pairedByProgram,
    inCoachingByProgram,
    usedSessionsByProgram,
  } = clientMetrics || {};

  return (
    <View
      className='program-view'
      isLoading={result.state === 'loading'}
      isError={result.state === 'error'}
      refreshFn={retry}
    >
      <ViewHeader
        title={program?.name}
        titleIcon={<LayersOutlinedIcon fontSize='large' />}
        actions={[
          {
            render: (
              <ProgramEditor
                key={`${program?.id}-editor`}
                isAdmin={store.user.is_admin}
                program={program}
                updateCurrentProgram={({ data }: Program) => {
                  program?.mergeData(data);
                }}
              >
                <Button text='Edit' size='small' />
              </ProgramEditor>
            ),
          },
          {
            render: (
              <InviteProgramModal
                key={`${program?.id}-invites`}
                program={program}
                surveys={result?.value?.surveys}
              />
            ),
          },
        ]}
      />
      <ViewBody>
        <ViewBodySection title='Program Overview' icon={<LayersOutlinedIcon />}>
          <Card title='Overview'>
            <Row>
              <Col>
                <Typography variant='body1b'>Dates</Typography>
                <Stack direction='row' spacing={2} alignItems='center'>
                  <Typography variant='body1'>
                    {dayjs(program?.start_date).format('ll')} -
                    {dayjs(program?.end_date).format('ll')}
                  </Typography>
                  <ActiveInactiveChip
                    active={isActive(program?.start_date, program?.end_date)}
                    sx={{ width: 'fit-content' }}
                  />
                </Stack>
              </Col>
              <Col offset={1}>
                <Typography variant='body1b'>Type</Typography>
                <Typography variant='body1'>
                  {programTypes[program?.type]}
                </Typography>
              </Col>
            </Row>
            {program?.description && (
              <Row>
                <Col>
                  <Typography variant='body1b'>Description</Typography>
                  <Typography variant='body1'>
                    {program?.description}
                  </Typography>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Typography variant='body1b'>Total Members</Typography>
                <Typography variant='body1'>
                  {program?.members.filter(m => !m.removedAt).length}
                </Typography>
              </Col>
              <Col offset={1}>
                <Typography variant='body1b'>Sessions Reserved</Typography>
                <Typography variant='body1'>
                  {program?.sessions_reserved}
                </Typography>
              </Col>
              <Col offset={1}>
                <Typography variant='body1b'>
                  Default Session Allocation
                </Typography>
                <Typography variant='body1'>
                  {program?.sessions_per_member}
                </Typography>
              </Col>
            </Row>
          </Card>
          <Card title='Insights'>
            <Typography variant='body1'>
              Here is how your members are progressing through their coaching or
              mentoring programs.
            </Typography>
            <ClientDashboardProgramActivity
              clientMetrics={{
                members: membersByProgram?.[program?.id],
                allocatedSessions: allocatedSessionsByProgram?.[program?.id],
                paired: pairedByProgram?.[program?.id],
                inCoaching: inCoachingByProgram?.[program?.id],
                usedSessions: usedSessionsByProgram?.[program?.id],
              }}
            />
          </Card>
          <ProgramMembersCard vm={vm} program={program} />
        </ViewBodySection>
        <ViewBodySection title='Surveys' icon={<ContentPasteIcon />}>
          <ProgramSurveys vm={vm} />
        </ViewBodySection>
      </ViewBody>
    </View>
  );
};

export default observer(ProgramView);
