import React, { FC } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react';
import {
  View,
  ViewBody,
  ViewBodySection,
  ViewHeader,
} from '@src/components/sc-design-system';
import Overview from './components/Overview';
import Aida from './components/Aida';
import { useActiveClient, ActiveClientKey } from '@src/hooks/useActiveClient';
import { ClientSelector } from '@src/components/common/ClientSelector';
import { useUserQueries } from '@src/hooks/useUserQueries';
import { useUser } from '@src/hooks/useUser';

import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

const DirectReportProfile: FC = () => {
  const params = useParams();
  const { user } = useUser();
  const { client, isLoading: isActiveClientLoading } = useActiveClient({
    queryKey: ActiveClientKey.profile_active_client_id,
  });
  // Get User Data
  const { useGetUser } = useUserQueries(params.id);
  const { user: profileUser, isLoading: isProfileUserLoading } = useGetUser();
  return (
    <View>
      <ViewHeader
        titleAvatarId={profileUser?.id}
        title={
          !isProfileUserLoading &&
          `${profileUser?.first_name} ${profileUser?.last_name}`
        }
        actions={[
          {
            render: user.clients?.length > 1 && (
              <ClientSelector
                user={user}
                key='client-selector'
                disabled={isActiveClientLoading}
                selectedClient={client}
                localStorageKey={ActiveClientKey.profile_active_client_id}
              />
            ),
          },
        ]}
      />
      <ViewBody>
        <ViewBodySection title='Overview' icon={<PersonOutlinedIcon />}>
          <Overview />
        </ViewBodySection>
        {!isActiveClientLoading && client?.is_aida_active && (
          <ViewBodySection title='Aida' icon={<AutoAwesomeOutlinedIcon />}>
            <Aida clientId={client.id} />
          </ViewBodySection>
        )}
      </ViewBody>
    </View>
  );
};

export default observer(DirectReportProfile);
