import { api } from '@src/lib/client';
import Store from '@src/models/Store';
import React, { FC, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Table, Input, Select, Pagination, Space, Drawer, Popover } from 'antd';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { IconButton, Stack, Divider } from '@mui/material';

import {
  Card,
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
  useBreadcrumbs,
  Avatar,
} from '@src/components/sc-design-system';
import User from '@src/models/User';
import { UserSchema } from '@shared/schemas';
import UserStatus from '@src/components/common/UserStatus';
import UserProfileEditor from '@src/components/views/Administration/UsersList/UserProfileEditor';
import UserProfile from '@src/components/forms/UserProfile/UserProfile';
import Program from '@src/models/Program';
import { observer } from 'mobx-react';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { useQueryParams } from '@src/utils';
import { ActiveClientKey } from '@src/hooks/useActiveClient';
import MuiLink from '@mui/material/Link';

const PAGE_LIMIT = 25;
interface UsersListIF {
  store: Store;
}

const setQueryParams = (
  navigate: Function,
  query: any,
  name: string,
  value: string | number,
) => {
  let currentType = query.get('type') || '';
  let currentKeyword = query.get('keyword') || '';
  let currentPage = query.get('page') || 1;

  // Handle prop change
  if (name === 'type') {
    currentPage = 1; // Need reset on type change
    currentType = value;
  }
  if (name === 'keyword') {
    currentPage = 1; // Need reset on search
    currentKeyword = value;
  }
  if (name === 'page') {
    currentPage = value;
  }

  // Build query
  const composedQuery = `?type=${currentType || 'all'}${
    currentKeyword && currentKeyword.length > 1
      ? `&keyword=${currentKeyword}`
      : ''
  }${currentPage && `&page=${currentPage}`}`;
  navigate({
    pathname: window.location.pathname,
    search: composedQuery,
  });
};

let currentGetReqTimeout: any;

const getList = async (
  params: object,
  setList: Function,
  setTotal: Function,
  setLoading: Function,
): Promise<void> => {
  // Debounce to prevent keyword onchange from blasting the api
  if (currentGetReqTimeout) clearTimeout(currentGetReqTimeout);
  currentGetReqTimeout = setTimeout(async () => {
    const res = await api.users.getList(
      Object.assign(params, { limit: PAGE_LIMIT }),
    );
    setList(res.data.data.map((d: typeof UserSchema) => new User(d)));
    setTotal(res.data.totalRecords);
    setLoading(false);
  }, 200);
};

const TypeSelector: FC<{ type: string; setQuery: Function }> = ({
  type,
  setQuery,
}) => {
  const label = {
    all: 'All Users',
    administrators: 'Administrators',
    coaches: 'Coaches',
    coachees: 'Coachees',
  };
  return (
    <Select
      style={{ width: '180px' }}
      bordered={false}
      defaultValue={type}
      onChange={value => setQuery('type', value)}
    >
      {Object.keys(label).map(key => (
        <Select.Option key={key} value={key}>
          {label[key]}
        </Select.Option>
      ))}
    </Select>
  );
};

interface ClientProgramMemberships {
  id: string;
  name: string;
  role: string;
  programs: Program[];
}

const memberClientsAndPrograms = (user: User): ClientProgramMemberships[] =>
  user.clients.length > 0
    ? user.clients.reduce((acc, client) => {
        const clientRole = client.members.find(cM => cM.id === user.id).role;
        const clientPrograms =
          user.programs && user.programs.length > 0
            ? user.programs.filter(p => p.client_id === client.id)
            : [];
        acc.push({
          id: client.id,
          name: client.name,
          role: clientRole,
          programs: clientPrograms,
        });
        return acc;
      }, [])
    : [];

const UsersList: FC<UsersListIF> = ({ store }) => {
  const query = useQueryParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname: previousPath } = useLocation();
  const setQuery = setQueryParams.bind(null, navigate, query);
  const type = query.get('type') || '';
  const keyword = query.get('keyword') || '';
  const page = query.get('page') || 1;
  const [list, setList] = useState([]);
  const { resetBreadcrumbs } = useBreadcrumbs();

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editingUser, setEditingUser] = useState<boolean | string>(false);

  useEffect(() => {
    setLoading(true);
    getList({ type, keyword, page }, setList, setTotal, setLoading);
  }, [type, keyword, page]);

  const tableColumns = [
    {
      width: 30,
      dataIndex: 'profile_image',
      key: 'avatar',
      align: 'center' as 'center',
      title: <EditOutlinedIcon />,
      render: (text: string, user: User) => (
        <UserProfileEditor user={user} list={list} setList={setList}>
          <Avatar
            variant='interactive'
            sx={{ cursor: 'pointer' }}
            srcId={user.id}
            alt={`${user.first_name} ${user.last_name}`}
          />
        </UserProfileEditor>
      ),
    },
    {
      title: 'Name',
      key: 'name',

      sorter: (a, b) => (a.first_name > b.first_name ? -1 : 1),
      render: (text: string, user: User) => (
        <>
          {user.first_name} {user.last_name}
        </>
      ),
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      sorter: (a, b) => (a.email > b.email ? -1 : 1),
      responsive: ['xl' as Breakpoint],
    },
    {
      title: 'Role',
      key: 'role',
      filters: [
        {
          text: <span>Admin</span>,
          value: 'Admin',
        },
        {
          text: <span>Coach</span>,
          value: 'Coach',
        },
        {
          text: <span>Coachee</span>,
          value: 'Coachee',
        },
        {
          text: <span>Client Admin</span>,
          value: 'Client Admin',
        },
      ],
      onFilter: (value: string, user) => {
        const client = user.clients && user.clients[0];
        let isClientAdmin = false;
        if (client) {
          const memberRecord = client.members.find(m => m.id === user.id);
          isClientAdmin = Boolean(memberRecord.role === 'manager');
        }
        const role = user.is_admin
          ? 'Admin'
          : user.is_coach
          ? 'Coach'
          : isClientAdmin
          ? 'Client Admin'
          : 'Coachee';
        return role === value;
      },
      filterSearch: true,
      render: (text: string, user: User) => {
        const client = user.clients && user.clients.length && user.clients[0];
        let isClientAdmin = false;
        if (client) {
          const memberRecord = client.members.find(m => m.id === user.id);
          isClientAdmin = Boolean(memberRecord.role === 'manager');
        }
        const role = user.is_admin
          ? 'Admin'
          : user.is_coach
          ? 'Coach'
          : isClientAdmin
          ? 'Client Admin'
          : 'Coachee';
        return <>{role}</>;
      },
    },
    {
      title: 'Client',
      key: 'client',
      align: 'center' as 'center',

      render: (text: string, user: User) => {
        const memberships = memberClientsAndPrograms(user);
        return (
          <>
            {memberships.length > 0 ? (
              <Space
                style={{ width: '100%', textAlign: 'center' }}
                split={
                  <Divider
                    style={{
                      marginTop: '.5em',
                      marginBottom: '.5em',
                    }}
                  />
                }
                direction='vertical'
              >
                {memberships.map(membership => (
                  <Popover
                    key={membership.id}
                    content={
                      membership.programs &&
                      membership.programs.length === 0 ? (
                        <span style={{ color: '#999' }}>
                          Not assigned to program
                        </span>
                      ) : (
                        <Space
                          split={
                            <Divider
                              style={{
                                marginTop: '.5em',
                                marginBottom: '.5em',
                              }}
                            />
                          }
                          direction='vertical'
                        >
                          {membership.programs &&
                            membership.programs.map(p => (
                              <Link
                                key={p.id}
                                to={`/program/${p.id}`}
                                state={{ previousPath }}
                              >
                                <MuiLink variant='a2dash'>{p.name}</MuiLink>
                              </Link>
                            ))}
                        </Space>
                      )
                    }
                  >
                    <Link
                      key={membership.id}
                      to={`/my-business/${membership.id}`}
                      state={{ previousPath }}
                      onClick={() => {
                        const activeClientId = localStorage.getItem(
                          ActiveClientKey.sc_admin_active_client_id,
                        );
                        if (activeClientId !== membership.id) {
                          localStorage.setItem(
                            ActiveClientKey.sc_admin_active_client_id,
                            membership.id,
                          );
                          queryClient.invalidateQueries();
                        }
                        resetBreadcrumbs([]);
                      }}
                    >
                      <MuiLink variant='a2dash'>
                        <div
                          style={{
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            width: '100%',
                          }}
                        >
                          <div style={{ textAlign: 'center' }}>
                            {membership.name}
                          </div>
                          {membership.role === 'manager' && (
                            <div
                              style={{
                                display: 'flex',
                                paddingLeft: '.5em',
                                marginTop: '-.5em',
                                opacity: '.6',
                              }}
                            />
                          )}
                        </div>
                      </MuiLink>
                    </Link>
                  </Popover>
                ))}
              </Space>
            ) : (
              <div style={{ textAlign: 'center', color: '#ccc' }}>N/A</div>
            )}
          </>
        );
      },
    },
    {
      width: 30,
      title: 'Status',
      key: 'status',
      align: 'center' as 'center',
      responsive: ['md' as Breakpoint],
      render: (text: string, user: User) => (
        <UserStatus onChange={user.setStatus} user={user} />
      ),
    },
    {
      width: 30,
      title: 'Actions',
      key: 'actions',
      align: 'center' as 'center',
      responsive: ['lg' as Breakpoint],
      render: (_, user: User) => (
        <Stack direction='row' alignItems='center' spacing={1}>
          <UserProfileEditor user={user} list={list} setList={setList}>
            <IconButton>
              <SettingsOutlinedIcon fontSize='small' />
            </IconButton>
          </UserProfileEditor>
          <IconButton onClick={() => setEditingUser(user.id)}>
            <EditOutlinedIcon fontSize='small' />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Drawer
        placement='right'
        onClose={() => setEditingUser(false)}
        open={Boolean(editingUser)}
        size='large'
        width={920}
        bodyStyle={{ padding: 0 }}
      >
        {editingUser && (
          <UserProfile store={store} usersList={list} userId={editingUser} />
        )}
      </Drawer>
      <View>
        <ViewHeader
          title='Users'
          titleIcon={<GroupOutlinedIcon fontSize='large' />}
        />
        <ViewBody>
          <ViewBodySection title='User List'>
            <Card>
              <Input
                size='middle'
                placeholder='Search...'
                defaultValue={keyword || ''}
                addonBefore={
                  <TypeSelector type={type || 'all'} setQuery={setQuery} />
                }
                allowClear={true}
                onChange={e => setQuery('keyword', e.target.value)}
              />
              <Divider />
              <Table
                bordered
                loading={loading}
                pagination={false}
                dataSource={list}
                columns={tableColumns}
                locale={{ emptyText: 'No users found' }}
                rowKey={'id'}
              />
              <Pagination
                style={{ marginTop: '2em' }}
                pageSize={PAGE_LIMIT}
                current={+page}
                total={total}
                onChange={val => setQuery('page', val)}
              />
            </Card>
          </ViewBodySection>
        </ViewBody>
      </View>
    </>
  );
};

export default observer(UsersList);
