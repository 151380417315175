import * as React from 'react';
import LoadingIcon from '../common/LoadingIcon';

export const AppLoader = () => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoadingIcon />
    </div>
  );
};
