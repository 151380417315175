import React, { FC, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Space, Col, Row, List } from 'antd';
import ListIcon from '@mui/icons-material/List';
import {
  Button,
  Card,
  MenuButton,
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
  ConfirmDialog,
  useBreadcrumbs,
} from '@src/components/sc-design-system';
import { Competency, CompetencySet } from '@src/models/CompetencySet';
import CompetencySetManager from '@src/components/common/CompetencySetManager/CompetencySetManager';
import { CompetencyManagerModal } from '@src/components/common/CompetencyManagerModal';
import CompetencySetRatingModal from './CompetencySetRatingModal';
import { CompetencyCard } from '@src/components/common/CompetencyCard';
import { useClientQueries } from '@src/hooks/useClientQueries';

import './CompetencySetLandingPage.less';
import { useNavigation } from '@src/navigation';
import { Stack } from '@mui/material';
import Add from '@mui/icons-material/Add';

const InitialRatingScale: FC<{
  setRatingScaleModalOpen: Function;
}> = ({ setRatingScaleModalOpen }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Space direction='vertical'>
        <div>
          Create a rating scale for this set to use in surveys for evaluation
          purposes.
        </div>
        <Button
          onClick={() => setRatingScaleModalOpen(true)}
          text='Start Now'
        />
      </Space>
    </div>
  );
};

const RatingScale: FC<{ competencySet: CompetencySet }> = ({
  competencySet,
}) => {
  return (
    <Space direction='vertical'>
      <div>{`1-${competencySet.range_maximum} Scale`}</div>
      <List className={'survey-select-options range'}>
        <Row>
          {competencySet.range_labels.map(rl => {
            return (
              <Space key={rl.value} direction='vertical' align='center'>
                <List.Item key={rl.value} className='selection-box'>
                  {rl.text || rl.value}
                </List.Item>
              </Space>
            );
          })}
        </Row>
      </List>
      <div>
        Note: An optional comment field will appear with competency or value
        sets when added to surveys.
      </div>
    </Space>
  );
};

const CompetencySetLandingPage: FC = () => {
  const { resetBreadcrumbs } = useBreadcrumbs();
  const { client_id, competency_set_id } = useParams();
  const queryClient = useQueryClient();
  const navigation = useNavigation();
  const { useGetClientCompetencies } = useClientQueries(client_id);
  const { competencySets, isLoading, isError, refetch } =
    useGetClientCompetencies();
  const competencySet = useMemo(() => {
    return competencySets?.find(c => c.id === competency_set_id);
  }, [competencySets, competency_set_id]);

  const [showSetModal, setShowSetModal] = useState<boolean>(false);
  const [isDeleteSetModalOpen, setIsDeleteSetModalOpen] =
    useState<boolean>(false);
  const [showCompetencyModal, setShowCompetencyModal] =
    useState<boolean>(false);
  const [showRatingScaleModal, setShowRatingScaleModal] =
    useState<boolean>(false);
  const [isDeleteRatingScaleModalOpen, setIsDeleteRatingScaleModalOpen] =
    useState<boolean>(false);
  const [selectedCompetency, setSelectedCompetency] =
    useState<Competency>(undefined);

  return (
    <>
      {showRatingScaleModal && (
        <CompetencySetRatingModal
          clientId={client_id}
          competencySet={competencySet}
          setRatingScaleModalOpen={setShowRatingScaleModal}
        />
      )}
      <View isLoading={isLoading} isError={isError} refreshFn={refetch}>
        <ViewHeader
          title={competencySet?.name}
          titleIcon={<ListIcon fontSize='large' />}
        />
        <ViewBody>
          <ViewBodySection title='Overview'>
            <Card
              title='Overview'
              titleActions={
                <MenuButton
                  primaryActionText='Edit'
                  disablePadding
                  variant='outlined'
                  secondaryActions={[
                    {
                      key: 'delete',
                      label: 'Delete',
                      onClick: () => setIsDeleteSetModalOpen(true),
                    },
                  ]}
                  onClickPrimaryAction={() => setShowSetModal(true)}
                />
              }
            >
              <Row>
                <Col span={24}>
                  <div style={{ fontWeight: 400 }}>Description</div>
                </Col>
                <Col span={24}>{competencySet?.description}</Col>
              </Row>
            </Card>
            <Card
              title={
                competencySet?.competency_label === 'value'
                  ? 'Values'
                  : 'Competencies'
              }
            >
              <Stack spacing={4}>
                {competencySet?.competencies?.map(competency => (
                  <CompetencyCard
                    competency={competency}
                    key={competency.id}
                    isEditable={true}
                    onToggleEdit={() => {
                      setSelectedCompetency(competency);
                      setShowCompetencyModal(true);
                    }}
                    onDelete={async () => {
                      setSelectedCompetency(competency);
                      await competency.delete();
                      queryClient.invalidateQueries({
                        queryKey: ['client', client_id, 'competencies'],
                      });
                    }}
                  />
                ))}
                <div style={{ textAlign: 'center' }}>
                  <Button
                    variant='text'
                    onClick={() => setShowCompetencyModal(true)}
                    startIcon={<Add />}
                    text={
                      competencySet?.competency_label === 'competency'
                        ? 'Add Competency'
                        : 'Add Value'
                    }
                  />
                </div>
              </Stack>
            </Card>
            <Card
              title='Rating Scale'
              titleActions={
                competencySet?.range_labels && (
                  <MenuButton
                    variant='outlined'
                    disablePadding
                    primaryActionText='Edit'
                    secondaryActions={[
                      {
                        key: 'delete',
                        label: 'Delete',
                        onClick: () => setIsDeleteRatingScaleModalOpen(true),
                      },
                    ]}
                    onClickPrimaryAction={() => setShowRatingScaleModal(true)}
                  />
                )
              }
            >
              {competencySet?.range_labels ? (
                <RatingScale competencySet={competencySet} />
              ) : (
                <InitialRatingScale
                  setRatingScaleModalOpen={setShowRatingScaleModal}
                />
              )}
            </Card>
          </ViewBodySection>
        </ViewBody>
      </View>
      {showSetModal && (
        <CompetencySetManager
          clientId={client_id}
          selectedSet={new CompetencySet(competencySet)}
          open={showSetModal}
          onClose={() => setShowSetModal(false)}
          onSave={async () => {
            queryClient.invalidateQueries({
              queryKey: ['client', client_id, 'competencies'],
            });
            setShowSetModal(false);
          }}
        />
      )}
      {showCompetencyModal && (
        <CompetencyManagerModal
          clientId={client_id}
          open={showCompetencyModal}
          selectedCompetency={selectedCompetency}
          selectedSet={competencySet}
          onClose={() => {
            setSelectedCompetency(undefined);
            setShowCompetencyModal(false);
          }}
          onSave={async () => {
            queryClient.invalidateQueries({
              queryKey: ['client', client_id, 'competencies'],
            });
            setSelectedCompetency(undefined);
            setShowCompetencyModal(false);
          }}
          type={
            competencySet?.competency_label.charAt(0).toUpperCase() +
              competencySet?.competency_label.slice(1) || 'Competency'
          }
        />
      )}
      {isDeleteSetModalOpen && (
        <ConfirmDialog
          open={isDeleteSetModalOpen}
          setOpenModal={setIsDeleteSetModalOpen}
          title='Delete this competency set?'
          body='This action cannot be undone.'
          buttonProps={{
            text: 'Delete',
            onClick: async () => {
              await competencySet.delete(client_id);
              navigation.replace(`/my-business/${client_id}/performance?tab=1`);
              resetBreadcrumbs([]);
            },
          }}
        />
      )}
      {isDeleteRatingScaleModalOpen && (
        <ConfirmDialog
          open={isDeleteRatingScaleModalOpen}
          setOpenModal={setIsDeleteRatingScaleModalOpen}
          title='Delete this rating scale?'
          body='This action cannot be undone.'
          buttonProps={{
            text: 'Delete',
            onClick: async () => {
              competencySet.range_maximum = 10;
              competencySet.range_labels = undefined;
              await competencySet.save(client_id);
            },
          }}
        />
      )}
    </>
  );
};

export default observer(CompetencySetLandingPage);
