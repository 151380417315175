import { api } from '@src/lib/client';
import { message } from 'antd';
import { action, computed, makeAutoObservable, toJS } from 'mobx';

const indicatorDataProps = ['id', 'title'];

interface IndicatorIF {
  id: string;
  title: string;
}

export class Indicator {
  id: string;
  title: string;

  constructor(indicator: IndicatorIF) {
    makeAutoObservable(this, {
      data: computed,
    });
    Object.keys(indicator).forEach(prop => (this[prop] = indicator[prop]));
  }

  get data() {
    return indicatorDataProps.reduce((acc, prop) => {
      acc[prop] = toJS(this[prop]);
      return acc;
    }, {});
  }
}

const competencyDataProps = [
  'id',
  'title',
  'indicators',
  'description',
  'updatedAt',
  'client_id',
  'competency_set_id',
];

export interface CompetencyIF {
  title: string;
  indicators: IndicatorIF[];
  description?: string;
  updatedAt?: number;
  client_id?: string;
  competency_set_id?: string;
}

export class Competency {
  id?: string;
  title: string;
  indicators: Indicator[];
  description?: string;
  updatedAt?: number;
  client_id?: string;
  competency_set_id?: string;

  constructor(competency: CompetencyIF) {
    makeAutoObservable(this, {
      data: computed,
      mergeData: action,
    });
    this.mergeData(competency);
  }

  mergeData(competency) {
    Object.keys(competency).forEach(prop => {
      if (prop === 'indicators') {
        this[prop] = competency[prop].map(i => new Indicator(i));
      } else {
        this[prop] = competency[prop];
      }
    });
  }

  get data() {
    return competencyDataProps.reduce((acc, prop) => {
      if (prop === 'indicators') {
        acc[prop] = this[prop].map(item => item.data);
      } else {
        acc[prop] = toJS(this[prop]);
      }
      return acc;
    }, {});
  }

  save = async () => {
    try {
      const res = await api.goals_competencies.saveCompetency(this.data);
      this.mergeData(res.data);
      message.success('Competency saved');
    } catch (e) {
      message.error('Could not save competency');
    }
  };

  delete = async () => {
    try {
      await api.goals_competencies.deleteCompetency(this.id);
      message.success('Competency deleted');
    } catch (e) {
      message.error('Could not delete competency');
    }
  };
}

const competencySetDataProps = [
  'id',
  'name',
  'description',
  'competency_label',
  'range_maximum',
  'range_labels',
];

interface CompetencySetIF {
  id?: string;
  name?: string;
  competencies?: CompetencyIF[];
  description?: string;
  range_maximum?: number;
  range_labels?: { value: number; text: string }[];
}
export class CompetencySet {
  id: string;
  name: string;
  competencies: Competency[] = [];
  description?: string;
  competency_label: 'competency' | 'value' = 'competency';
  range_maximum: number = 10;
  range_labels?: { value: number; text: string }[];

  constructor(competencySet: CompetencySetIF) {
    makeAutoObservable(this, {
      data: computed,
      mergeData: action,
      save: action,
    });
    this.mergeData(competencySet);
  }

  mergeData(competencySet) {
    Object.keys(competencySet).forEach(prop => {
      if (prop === 'competencies') {
        this[prop] = competencySet[prop].map(c => new Competency(c));
      } else {
        this[prop] = competencySet[prop];
      }
    });
  }

  get data() {
    return competencySetDataProps.reduce((acc, prop) => {
      if (prop === 'competencies') {
        acc[prop] = this[prop].map(item => item.data);
      } else {
        acc[prop] = toJS(this[prop]);
      }
      return acc;
    }, {});
  }

  save = async (clientId: string) => {
    try {
      const res = await api.goals_competencies.setSet(clientId, this.data);
      this.mergeData(res.data);
      message.success('Competency set saved');
    } catch (e) {
      message.error('Could not save competency set');
    }
  };

  delete = async (clientId: string) => {
    try {
      const res = await api.goals_competencies.deleteSet(clientId, this.id);
      this.mergeData(res.data);
      message.success('Competency set deleted');
    } catch (e) {
      message.error('Could not delete competency set');
    }
  };
}
