import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, TextField, useToast } from '@src/components/sc-design-system';
import StartSessionButton from './StartSessionButton';
import { WhoCanSeeThis } from '../WhoCanSeeThis';
import * as useVerbiage from '@shared/lib/src/use-verbiage';
import WYSIWYG from '../WYSIWYG';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {
  FormControlLabel,
  Stack,
  Typography,
  Switch,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { SessionRating } from './SessionRating';

const ratingMessages = {
  0: 'Enter text here',
  1: 'Oh no. Can you tell us why?',
  2: 'Oh no. Can you tell us why?',
  3: 'What could have gone better?',
  4: 'Great! Care to share what went well?',
  5: 'Great! Care to share what went well?',
};

interface sectionsUpdatedIF {
  notes?: boolean;
  ratings?: boolean;
  feedback?: boolean;
  missed_reason?: boolean;
}

const SessionDetails: FC<{
  userRole: string;
  session: any;
  setSectionsUpdated?: Function;
  sectionsUpdated?: sectionsUpdatedIF;
  programType?: string;
}> = ({
  userRole,
  session,
  setSectionsUpdated,
  sectionsUpdated,
  programType,
}) => {
  const toast = useToast();
  const [hoverRating, setHoverRating] = useState<number>(-1);
  const [loading, setLoading] = useState(false);
  const [otherParticipantName, setOtherParticipantName] = useState(undefined);
  const [otherParticipantRole, setOtherParticipantRole] = useState(undefined);
  const v = useVerbiage.init(
    programType === 'mentoring' || session.programType === 'mentoring'
      ? ['isMentor']
      : [],
  );

  useEffect(() => {
    if (session?.otherParticipantName) {
      setOtherParticipantName(session.otherParticipantName);
    }
    if (session?.otherParticipantRole) {
      setOtherParticipantRole(session.otherParticipantRole);
    } else {
      // this is absolute fail safe. we are assuming we only have a coach or coachee user role
      setOtherParticipantRole(userRole === 'coach' ? 'coachee' : 'coach');
    }
  }, [session, userRole]);

  interface ButtonPropsIF {
    type: string;
    setSectionsUpdated?: Function;
    sectionsUpdated?: object | sectionsUpdatedIF;
  }

  const SubmitOrSaveButton: FC<ButtonPropsIF> = ({ type }) => {
    return (
      <div style={{ textAlign: 'center' }}>
        <Button
          disabled={
            loading ||
            (type === 'ratings' &&
              session.current_status === 'completed' &&
              !session.ratingValue) ||
            (type === 'notes' && session.notesText.length === 0) ||
            (type === 'feedback' && session.feedbackText.length === 0)
          }
          loading={loading && sectionsUpdated[type]}
          onClick={async () => {
            setLoading(true);
            await session.saveFollowUp(type, toast);
            delete sectionsUpdated[type];
            setSectionsUpdated({ ...sectionsUpdated });
            setLoading(false);
          }}
          text={type === 'notes' ? 'Save' : 'Submit'}
          sx={{ width: 'fit-content' }}
        />
      </div>
    );
  };

  return (
    <Stack>
      {session.current_status === 'completed' && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary>
              <Typography
                variant='body1sb'
                color='primary'
                sx={{ marginRight: 'auto' }}
              >
                Session Rating
              </Typography>
              <WhoCanSeeThis userRole={userRole} gcShared v={v} />
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body1' sx={{ margin: '1em 0' }}>
                Overall, how are you feeling after this session?
              </Typography>
              <Stack spacing={2}>
                <label className='session-reflection-label'>
                  Select an emoji below that reflects how your session went{' '}
                  <span>*</span>
                </label>
                <SessionRating
                  defaultValue={session.ratingValue}
                  readOnly={session.ratingReadOnly}
                  value={hoverRating > 0 ? hoverRating : session.ratingValue}
                  onChange={e => {
                    if (!session.ratingReadOnly) {
                      session.ratingValue = +(e.target as any).value;

                      setSectionsUpdated({
                        ...sectionsUpdated,
                        ratings: true,
                      });
                    }
                  }}
                  onChangeActive={(_, val) => {
                    if (!session.ratingReadOnly) setHoverRating(val);
                  }}
                />
                {session.ratingReadOnly ? (
                  session.ratingNotes && (
                    <Typography>{session.ratingNotes}</Typography>
                  )
                ) : (
                  <>
                    <TextField
                      id='ratingNotes'
                      label='Please provide an explanation below (optional)'
                      placeholder={ratingMessages[session.ratingValue]}
                      value={session.ratingNotes}
                      multiline
                      rows={5}
                      onChange={e => {
                        session.ratingNotes = e.target.value;
                      }}
                    />
                    {!session.ratingReadOnly && (
                      <SubmitOrSaveButton type='ratings' />
                    )}
                  </>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
          {session.type !== 'interview' && (
            <Accordion>
              <AccordionSummary>
                <Typography
                  variant='body1sb'
                  color='primary'
                  sx={{ marginRight: 'auto' }}
                >
                  Session Notes
                </Typography>
                <WhoCanSeeThis
                  userRole={userRole}
                  shared={session.notesShared}
                  v={v}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  <Typography variant='body1' sx={{ margin: '1em 0' }}>
                    Enter any notes, action-items, or follow-ups you took away
                    from this session below.
                  </Typography>
                  {session.notesSavedAt !== 0 && (
                    <Typography
                      component='span'
                      color='grey.500'
                      sx={{
                        fontSize: '0.85em',
                        margin: '1em 0 0 0.4em',
                      }}
                    >
                      Last saved {dayjs(session.notesSavedAt).format('lll')}
                    </Typography>
                  )}
                  <WYSIWYG
                    value={session.notesText}
                    onChange={val => {
                      session.notesText = val;
                      if (val.length) {
                        setSectionsUpdated({
                          ...sectionsUpdated,
                          notes: true,
                        });
                      } else {
                        delete sectionsUpdated?.feedback;
                        setSectionsUpdated({ ...sectionsUpdated });
                      }
                    }}
                  />
                  <Stack
                    direction='row'
                    alignItems='center'
                    marginTop='1em'
                    marginBottom='1em'
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={session.notesShared}
                          onChange={async (e, shared: boolean) => {
                            session.notesShared = shared;
                            if (session.notesSavedAt !== 0) {
                              await session.saveFollowUp('notes');
                            }
                          }}
                        />
                      }
                      componentsProps={{
                        typography: {
                          variant: 'subtitle2',
                        },
                      }}
                      label={`Share with ${
                        otherParticipantName ||
                        'your ' + v(otherParticipantRole)
                      }`}
                    />
                    <Tooltip
                      title={`You can keep this to yourself or share it with ${
                        otherParticipantName ||
                        `your ${v(otherParticipantRole)}`
                      } to make sure you are on the same page.`}
                    >
                      <InfoOutlined
                        sx={{ width: '.7em', height: '.7em' }}
                        color='action'
                      />
                    </Tooltip>
                  </Stack>
                  <Accordion>
                    <AccordionSummary>
                      <Typography variant='body1sb' color='primary'>
                        Notes from{' '}
                        {otherParticipantName ||
                          `your ${v(otherParticipantRole)}`}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {!session.otherParticipantFeedback?.notes ? (
                        <Typography variant='body1'>
                          {otherParticipantName ||
                            `Your ${v(otherParticipantRole)}`}{' '}
                          has not yet provided any notes for this session
                        </Typography>
                      ) : (
                        <>
                          <Typography variant='h3'>Session Notes:</Typography>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: session.otherParticipantFeedback.notes,
                            }}
                          />
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                  <SubmitOrSaveButton type='notes' />
                </Stack>
              </AccordionDetails>
            </Accordion>
          )}
          {session.type !== 'interview' && (
            <Accordion>
              <AccordionSummary>
                <Typography
                  variant='body1sb'
                  color='primary'
                  sx={{ marginRight: 'auto' }}
                >
                  Session Feedback
                </Typography>
                <WhoCanSeeThis userRole={userRole} shared v={v} />
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  <Typography variant='body1' sx={{ margin: '1em 0' }}>
                    Share feedback directly with{' '}
                    {otherParticipantName || `your ${v(otherParticipantRole)}`}
                  </Typography>

                  {session.feedbackText && session.feedbackReadOnly ? (
                    <>
                      <Typography variant='body1'>
                        You submitted the following feedback:
                      </Typography>
                      <Typography
                        variant='body1'
                        sx={{ whiteSpace: 'pre-line' }}
                      >
                        {session.feedbackText}
                      </Typography>
                    </>
                  ) : (
                    <TextField
                      id='feedbackText'
                      multiline
                      rows={5}
                      placeholder={`Use this space to share any feedback directly with ${
                        otherParticipantName ||
                        `your ${v(otherParticipantRole)}`
                      }`}
                      value={session.feedbackText || ''}
                      onChange={e => {
                        session.feedbackText = e.target.value || '';
                        if (e.target.value.length) {
                          setSectionsUpdated({
                            ...sectionsUpdated,
                            feedback: true,
                          });
                        } else {
                          delete sectionsUpdated?.feedback;
                          setSectionsUpdated({ ...sectionsUpdated });
                        }
                      }}
                    />
                  )}
                  <Accordion>
                    <AccordionSummary>
                      <Typography variant='body1sb' color='primary'>
                        Feedback from{' '}
                        {otherParticipantName ||
                          `your ${v(otherParticipantRole)}`}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {!session.otherParticipantFeedback?.feedback ? (
                        <Typography variant='body1'>
                          {otherParticipantName ||
                            `Your ${v(otherParticipantRole)}`}{' '}
                          has not yet provided any feedback for this session
                        </Typography>
                      ) : (
                        <div>
                          <Typography variant='h3'>
                            Feedback from
                            {otherParticipantName ||
                              `your ${v(otherParticipantRole)}`}
                            :
                          </Typography>
                          <Typography
                            variant='body1'
                            sx={{ whiteSpace: 'pre-line' }}
                          >
                            {session.otherParticipantFeedback.feedback}
                          </Typography>
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                  {!session.feedbackReadOnly && (
                    <SubmitOrSaveButton type='feedback' />
                  )}
                </Stack>
              </AccordionDetails>
            </Accordion>
          )}
        </>
      )}
      {session.current_status === 'scheduled' && (
        <Stack>
          <Typography variant='h4' color='primary'>
            Scheduled
          </Typography>
          <Typography variant='body1m'>
            {dayjs(session?.start_time).format('LLL')}
          </Typography>
          <Divider />
          {session?.start_time - 1000 * 60 * 15 > Date.now() && (
            <Typography>
              You can join your session here within 15 minutes of the start time
            </Typography>
          )}
          {session?.start_time - 1000 * 60 * 15 <= Date.now() && (
            <Typography>Join your session using the button below:</Typography>
          )}
          <StartSessionButton session={session} />
        </Stack>
      )}
      {session.current_status === 'missed' && (
        <Stack>
          <Typography variant='h4' color='error'>
            Missed
          </Typography>
          <Typography variant='body1m'>
            {dayjs(session?.start_time).format('LLL')}
          </Typography>
          <Divider />
          <Typography variant='h4' sx={{ marginBottom: '1em' }}>
            It looks like this session was missed.
          </Typography>
          <FormControl sx={{ marginBottom: '1em' }}>
            <FormLabel
              id='missed-reason-radio-group-label'
              sx={{ marginBottom: '.5em' }}
            >
              Can you please provide some information on what happened?
            </FormLabel>
            <RadioGroup
              aria-labelledby='missed-reason-radio-group-label'
              name='missed-reason-radio-group'
              onChange={e => {
                session.missedFeedback = e.target.value;
                e.target.value.length
                  ? (sectionsUpdated['missed_reason'] = true)
                  : delete sectionsUpdated?.missed_reason;
              }}
              value={session.missedFeedback}
            >
              <FormControlLabel
                value='late-conflict'
                control={<Radio />}
                label='There was a late conflict and we were unable to reschedule'
              />
              <FormControlLabel
                value='no-show'
                control={<Radio />}
                label='The other participant did not show'
              />
              <FormControlLabel
                value='technical-problems'
                control={<Radio />}
                label='We had technical problems'
              />
            </RadioGroup>
          </FormControl>
          <SubmitOrSaveButton type='missed_reason' />
        </Stack>
      )}
      {session.current_status === 'started' && (
        <Stack>
          <Typography variant='h4' color='success.main'>
            Started
          </Typography>
          <Typography variant='body1m'>
            {dayjs(session?.start_time).format('LLL')}
          </Typography>
          <Divider />
          <Typography sx={{ marginBottom: '1em' }}>
            Rejoin your session using the button below:
          </Typography>
          <StartSessionButton session={session} />
        </Stack>
      )}
    </Stack>
  );
};

export default observer(SessionDetails);
