import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';

obey.creator('uuid', () => uuidv4());
obey.creator('now', () => Date.now());

const allowedStatuses = [
  'requested',
  'scheduled',
  'started',
  'completed',
  'canceled',
];

const originalStatuses = allowedStatuses.concat(['upcoming']);

const allowedActivities = allowedStatuses.concat([
  'joined',
  'left',
  'waiting',
  'rescheduled',
]);

const calendlyStatuses = ['active', 'canceled'];

export const SessionSchema = {
  id: { type: 'string', required: true, creator: 'uuid' },
  short_id: { type: 'string' },
  type: { type: 'string', allow: ['interview', 'coaching'] },
  members: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        id: { type: 'string', required: true },
        role: { type: 'string', allow: ['coach', 'coachee'], default: 'coach' },
        joinedAt: { type: 'number' },
        signed_jwt: { type: 'string' },
      },
    },
  },
  program_id: { type: 'string' },
  sow_id: { type: 'string' },
  client_id: { type: 'string' },
  session_activity: {
    type: 'array',
    empty: 'true',
    default: [],
    values: {
      type: 'object',
      keys: {
        timestamp: { type: 'number' },
        type: {
          type: 'string',
          allow: allowedActivities,
        },
        user_id: { type: 'string' },
        notes: { type: 'string', empty: true },
      },
    },
  },
  whereby_meeting: {
    type: 'object',
    keys: {
      meetingId: { type: 'string' },
      roomUrl: { type: 'string' },
      hostRoomUrl: { type: 'string' },
      endDate: { type: 'number' },
    },
  },
  calendly_event: {
    type: 'object',
    keys: {
      uuid: { type: 'string' },
      status: { type: 'string', allow: calendlyStatuses, default: 'active' },
      start_time: { type: 'number' },
      end_time: { type: 'number' },
      cancel_url: { type: 'string', empty: true },
      reschedule_url: { type: 'string', empty: true },
    },
  },
  current_status: {
    type: 'string',
    default: 'requested',
    allow: allowedStatuses,
  },
  status_overridden: {
    type: 'object',
    keys: {
      original_status: {
        type: 'string',
        allow: originalStatuses,
        default: 'completed',
      },
      notes: { type: 'string', empty: 'true' },
      timestamp: { type: 'number', creator: 'now' },
      user_id: { type: 'string' },
    },
  },
  zoom_link: { type: 'string', empty: true },
  ratings: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        user_id: { type: 'string' },
        rating: { type: 'number' },
        notes: { type: 'string', empty: true },
      },
    },
  },
  notes: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        user_id: { type: 'string' },
        timestamp: { type: 'number', creator: 'now' },
        note: { type: 'string', empty: true },
        shared: { type: 'boolean', default: false },
      },
    },
  },
  feedback: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        user_id: { type: 'string' },
        timestamp: { type: 'number', creator: 'now' },
        feedback: { type: 'string', empty: true },
      },
    },
  },
  missed_reason: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        user_id: { type: 'string' },
        timestamp: { type: 'number', creator: 'now' },
        reason: { type: 'string', empty: true },
      },
    },
  },
};

// eslint-disable-next-line no-unused-vars
export type SessionType = { [K in keyof typeof SessionSchema]?: any };

export const SessionModel = obey.model(SessionSchema);
