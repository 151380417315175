import React, { FC, useMemo, useState } from 'react';
import { Card, Button } from '@src/components/sc-design-system';
import { Box, Stack, Typography } from '@mui/material';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import { useUser } from '@src/hooks/useUser';
import { ActiveClientKey, useActiveClient } from '@src/hooks/useActiveClient';
import { TaskLabel } from './components/TaskLabel';
import { NavAction } from './components/NavAction';
import { ModalAction } from './components/ModalAction';
import { UploadedFilesModal } from './components/UploadedFilesModal';

const SummaryInputTasks: FC = () => {
  const [allItemsVisible, setAllItemsVisible] = useState(false);
  const { client } = useActiveClient({
    queryKey: ActiveClientKey.profile_active_client_id,
  });
  const { useGetUserAidaTasks } = useUser();
  const { tasks, isLoading } = useGetUserAidaTasks(client?.id);

  const visibleTasks = useMemo(() => {
    if (isLoading) return [];
    if (allItemsVisible) return tasks;
    return tasks?.slice(0, 2);
  }, [isLoading, tasks, allItemsVisible]);

  const allItemsComplete = useMemo(() => {
    return tasks?.every(t => t.isComplete);
  }, [tasks]);

  return (
    <Card sx={{ maxWidth: '95%' }}>
      <Typography variant='h3' component='h4' sx={{ marginBottom: '.5em' }}>
        Suggestions to enhance your development guidance
      </Typography>
      <Typography>
        Self-reflection, goal setting and collecting feedback are great ways to
        inform your development. Aida will adapt your development
        recommendations with this information.
      </Typography>
      <Stack direction='row' spacing={2} marginTop='2em' width='100%'>
        <Stack spacing={2} width='100%'>
          {visibleTasks?.map((task, i) => (
            <Stack
              direction='row'
              alignItems='center'
              width='100%'
              spacing={2}
              key={task.type}
            >
              <TaskLabel
                isFirstItem={i === 0}
                isComplete={task.isComplete}
                isLastItem={i === visibleTasks?.length - 1}
                allItemsComplete={allItemsComplete}
              />
              <Card sx={{ width: '100%' }}>
                <Stack
                  direction={{ md: 'row', xs: 'column' }}
                  spacing={2}
                  justifyContent={{ md: 'space-between', xs: null }}
                  alignItems={{ md: 'center', xs: 'flex-start' }}
                >
                  <Stack spacing={1}>
                    <Typography
                      variant='body1m'
                      component={task.isComplete ? 's' : 'p'}
                    >
                      {task.title}
                    </Typography>
                    <Typography variant='body2' color='grey.700'>
                      {task.description}
                    </Typography>
                  </Stack>
                  <TaskActions task={task} />
                </Stack>
                <Subtasks task={task} clientId={client?.id} />
              </Card>
            </Stack>
          ))}
          <div style={{ textAlign: 'center' }}>
            <Button
              variant='text'
              formatText={false}
              sx={{ width: 'fit-content', margin: 'auto' }}
              startIcon={
                allItemsVisible ? <RemoveCircleOutline /> : <AddCircleOutline />
              }
              text={
                allItemsVisible
                  ? 'Show me less of this list'
                  : 'Show me what else I can do'
              }
              onClick={() => setAllItemsVisible(!allItemsVisible)}
            />
          </div>
        </Stack>
      </Stack>
    </Card>
  );
};

const IntakeActions = ({ task }) => {
  return task.meta.url ? (
    <NavAction path={task.meta.url} buttonText='Complete survey' />
  ) : (
    <ModalAction task={task} variant='outlined' buttonText='View responses' />
  );
};

const AssessmentActions = ({ task }) => {
  if (task.subtasks?.length) {
    return null;
  }

  return (
    <Box minWidth='fit-content'>
      <NavAction
        path='/performance-and-growth?tab=3'
        buttonText='View responses'
        variant='outlined'
      />
    </Box>
  );
};

const FileActions = ({ task }) => {
  const [uploadedFilesModalOpen, setUploadedFilesModalOpen] = useState(false);

  const { user } = useUser();

  return (
    <Stack spacing={1}>
      {task.isComplete && (
        <Button
          text='View files'
          formatText={false}
          onClick={() => setUploadedFilesModalOpen(true)}
          size='small'
          variant='outlined'
          sx={{ width: { md: '195px', xs: 'fit-content' } }}
        />
      )}
      <ModalAction
        task={task}
        buttonText='Upload files'
        buttonDisabled={(user.documents?.length || 0) >= 5}
        variant={task.isComplete ? 'text' : 'contained'}
      />
      {uploadedFilesModalOpen && (
        <UploadedFilesModal
          open={uploadedFilesModalOpen}
          onClose={() => setUploadedFilesModalOpen(false)}
        />
      )}
    </Stack>
  );
};

const GoalActions = ({ task }) => {
  const buttonText = task.isComplete ? 'View goals' : 'Set goals';
  const variant = task.isComplete ? 'outlined' : 'contained';
  return (
    <Box minWidth='fit-content'>
      <NavAction
        path='/performance-and-growth'
        buttonText={buttonText}
        variant={variant}
      />
    </Box>
  );
};

const TaskActions = ({ task }) => {
  const Actions = {
    intake: IntakeActions,
    assessments: AssessmentActions,
    files: FileActions,
    goals: GoalActions,
  }[task.type];

  return Actions ? <Actions task={task} /> : null;
};

const Subtasks = ({ task, clientId }) => {
  if (!task.subtasks?.length) {
    return null;
  }

  const buttonTextMap = {
    assessments: {
      false: 'Complete assessment',
    },
    feedback: {
      false: 'Collect feedback',
      true: 'Manage participants',
    },
  };

  return (
    <Stack spacing={2} marginTop='2em' width='100%'>
      {task.subtasks.map(s => {
        const buttonText = buttonTextMap[task.type][`${s.isComplete}`] ?? '';
        const variant = s.isComplete ? 'outlined' : 'contained';
        return (
          <Box
            key={s.key}
            sx={{
              backgroundColor: 'primary.100',
              width: '100%',
              padding: '16px 24px',
              borderRadius: '8px',
              display: 'flex',
              gap: 2,
              flexDirection: { sm: 'row', xs: 'column' },
              justifyContent: { sm: 'space-between', xs: null },
              alignItems: { sm: 'center', xs: 'flex-start' },
            }}
          >
            <Stack spacing={1}>
              <Typography variant='body1m'>{s.title}</Typography>
              {!!s.description && (
                <Typography variant='body2'>{s.description}</Typography>
              )}
            </Stack>
            <Box>
              {s.meta?.url ? (
                <NavAction
                  path={s.meta?.url}
                  buttonText={buttonText}
                  variant={variant}
                />
              ) : (
                <ModalAction
                  task={s}
                  buttonText={buttonText}
                  variant={variant}
                  clientId={clientId}
                />
              )}
            </Box>
          </Box>
        );
      })}
    </Stack>
  );
};

export { SummaryInputTasks };
