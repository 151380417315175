import React, { FC, useMemo } from 'react';
import { Card } from '@src/components/sc-design-system';
import { SurveySubmissionStack } from '@src/components/common/SurveySubmissionStack';

const questionTypes = ['single-select', 'multi-select', 'range', 'text'];
const cardStyle = { borderColor: 'grey.400' };

interface ManagerEvaluationProps {
  data?: EvaluationReport;
}

const ManagerEvaluation: FC<ManagerEvaluationProps> = ({ data }) => {
  const filteredResults = useMemo(() => {
    if (!data) return [];
    return data?.manager_eval_submission?.results.filter(r => {
      return questionTypes.includes(r.question.type) && !r.question.meta_data;
    });
  }, [data]);

  return (
    <Card
      title='Manager evaluation'
      sx={{
        ...cardStyle,
        '&.MuiPaper-outlined': cardStyle,
        '.MuiCardHeader-root': cardStyle,
      }}
    >
      <SurveySubmissionStack
        results={filteredResults}
        emptyState='Manager evaluation has not been completed yet.'
      />
    </Card>
  );
};

export { ManagerEvaluation };
