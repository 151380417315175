import { message } from 'antd';
import Compressor from 'compressorjs';

const resizeAndCompress = async (file: Blob): Promise<Blob> =>
  await new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.8,
      checkOrientation: true,
      width: 600,
      height: 600,
      success: resolve,
      error: reject,
    });
  });

const fileToDataUri = (file: Blob): Promise<any> =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = event => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });

function beforeUpload(file: any): boolean | string {
  if (!file) return;
  const isJpgOrPng =
    file.type && (file.type === 'image/jpeg' || file.type === 'image/png');
  if (!isJpgOrPng) {
    message.error('File must be jpg or png format');
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error('Image must be smaller than 5MB');
    return false;
  }
  return isJpgOrPng && isLt2M;
}

const uploadImageUri = async (file: Blob): Promise<string> => {
  try {
    const checks = beforeUpload(file);
    if (!checks || !file) return;
    const img = await resizeAndCompress(file);
    const dataUri = await fileToDataUri(img);
    return dataUri;
  } catch (e) {
    message.error('Unable to upload image, please try a different file');
  }
};

export default uploadImageUri;
