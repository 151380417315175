import React, { FC, useState, useMemo } from 'react';
import _startCase from 'lodash/startCase';
import _capitalize from 'lodash/capitalize';
import dayjs from 'dayjs';
import Fuse from 'fuse.js';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import { Box, InputAdornment, Stack, Typography } from '@mui/material';
import {
  TableMUI as Table,
  TextField,
  Button,
} from '@src/components/sc-design-system';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { useMutation } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import { useStore } from '@src/models/Store';

interface PerformanceCycleFeedbackInvitesTableProps {
  clientId: string;
  performanceCycleId: string;
  feedbackFormId: string;
  userId: string;
  userFirstName: string;
  userLastName: string;
  view?: 'client' | 'member';
}

const PerformanceCycleFeedbackInvitesTable: FC<
  PerformanceCycleFeedbackInvitesTableProps
> = ({
  clientId,
  performanceCycleId,
  feedbackFormId,
  userId,
  userFirstName,
  userLastName,
  view = 'client',
}) => {
  const store = useStore();
  const [searchValue, setSearchValue] = useState('');
  const [rowButtonLoading, setRowButtonLoading] = useState<string | null>(null);
  const { useGetClientMembers, useGetPerformanceCycleFeedbackStatusForMember } =
    useClientQueries(clientId);
  const { members: allClientMembers } = useGetClientMembers();
  const {
    feedbackData,
    isLoading: isFeedbackDataLoading,
    refetch,
  } = useGetPerformanceCycleFeedbackStatusForMember(
    performanceCycleId,
    feedbackFormId,
    userId,
  );

  const { mutate: inviteStakeholders } = useMutation({
    mutationFn: async (data: any) => {
      return api.clients.updatePerformanceCycleFeedback(
        clientId,
        performanceCycleId,
        feedbackFormId,
        data,
      );
    },
    onSuccess: () => {
      refetch();
    },
    onSettled: () => {
      setRowButtonLoading(null);
    },
  });

  const formattedInvites = useMemo(() => {
    if (isFeedbackDataLoading || !feedbackData?.stakeholders) return [];
    return feedbackData?.stakeholders?.map(s => {
      const member = allClientMembers?.find(m => {
        return m.id === s.user_id;
      });
      return {
        ...s,
        email: member?.email,
        first_name: member?.first_name,
        last_name: member?.last_name,
      };
    });
  }, [isFeedbackDataLoading, feedbackData, allClientMembers]);

  const filteredInvites = useMemo(() => {
    const fuse = new Fuse(formattedInvites, {
      keys: ['name', 'email'],
      minMatchCharLength: 2,
      ignoreLocation: true,
    });

    return searchValue
      ? fuse.search(searchValue)?.map(({ item }) => item)
      : formattedInvites;
  }, [formattedInvites, searchValue]);

  const buttonSx = useMemo(() => {
    return filteredInvites.some(i => !i.feedback_pending) && view === 'member'
      ? { width: '100%' }
      : { width: 'fit-content' };
  }, [filteredInvites, view]);

  const columns = useMemo(() => {
    return [
      {
        header: view === 'client' ? 'Participant' : 'Member',
        accessor: 'first_name',
        size: 250,
        cell: ({ row }) => (
          <Stack>
            <Typography variant={view === 'client' ? 'body2m' : 'body2'}>
              {row?.original?.first_name} {row?.original?.last_name}
            </Typography>
            {view === 'client' && (
              <Typography variant='body2'>{row?.original?.email}</Typography>
            )}
          </Stack>
        ),
      },
      {
        header: 'Relationship',
        accessor: 'relationship',
        size: 50,
        cell: ({ row }) => {
          return (
            <Typography variant='body2'>
              {_capitalize(_startCase(row?.original?.relationship))}
            </Typography>
          );
        },
      },
      {
        header: 'Invite sent',
        accessor: 'requested_at',
        size: 50,
        cell: ({ row }) => {
          return (
            <Typography variant='body2'>
              {dayjs(row?.original?.requested_at).format('MM/DD/YYYY')}
            </Typography>
          );
        },
      },
      {
        header: 'Status',
        accessor: 'feedback_submission_id',
        size: 50,
        cell: ({ row }) => {
          return row?.original?.feedback_pending ? (
            <CancelOutlined color='error' />
          ) : (
            <CheckCircleOutline color='success' />
          );
        },
      },
      {
        header: '',
        id: 'actions',
        accessor: 'feedback_pending',
        size: 50,
        cell: ({ row }) => {
          const feedbackPending = row?.original?.feedback_pending;

          if (view === 'client' && !feedbackPending) {
            return null;
          }

          return (
            <Box sx={{ textAlign: 'center' }}>
              <Button
                text={feedbackPending ? 'Remind' : 'Request feedback again'}
                formatText={false}
                variant={
                  feedbackPending && view === 'member'
                    ? 'outlined'
                    : 'contained'
                }
                onClick={() => {
                  setRowButtonLoading(row.original.user_id);
                  inviteStakeholders({
                    member_id: userId,
                    member_first_name: userFirstName,
                    member_last_name: userLastName,
                    stakeholders: [row?.original],
                  });
                }}
                sx={buttonSx}
                loading={rowButtonLoading === row.original.user_id}
                disabled={rowButtonLoading === row.original.user_id}
                size='small'
              />
            </Box>
          );
        },
      },
    ];
  }, [
    inviteStakeholders,
    setRowButtonLoading,
    userId,
    userFirstName,
    userLastName,
    rowButtonLoading,
    view,
    buttonSx,
  ]);

  return (
    <Stack gap='1em'>
      <Typography variant='h4' component='h3'>
        Sent invites
      </Typography>
      <TextField
        fullWidth
        id='search'
        placeholder='Search'
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon fontSize='small' />
            </InputAdornment>
          ),
          sx: {
            borderRadius: '28px',
            paddingLeft: '1.5em',
          },
        }}
      />
      <Table
        columns={columns}
        data={filteredInvites}
        dataIsLoading={isFeedbackDataLoading}
        emptyState={`Data will appear here once ${
          userId === store.user.id
            ? 'you begin'
            : userFirstName
            ? `${userFirstName} begins`
            : 'members begin'
        } requesting feedback from stakeholders.`}
      />
    </Stack>
  );
};

export { PerformanceCycleFeedbackInvitesTable };
