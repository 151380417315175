import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { startCase } from 'lodash/fp';
import { Stack, Typography } from '@mui/material';
import Add from '@mui/icons-material/Add';
import {
  Button,
  Card,
  ConfirmDialog,
  MenuButton,
  Table,
  useBreadcrumbs,
} from '@src/components/sc-design-system';
import { ClientType } from '@shared/schemas';
import { Competency, CompetencySet } from '@src/models/CompetencySet';
import CompetencySetManager from '@src/components/common/CompetencySetManager/CompetencySetManager';
import { CompetencyManagerModal } from '@src/components/common/CompetencyManagerModal';
import LoadingIcon from '@src/components/common/LoadingIcon';
import { useClientQueries } from '@src/hooks/useClientQueries';

const ClientCompetencies: FC<{
  client: ClientType;
  basePath?: string;
}> = ({ client, basePath = '' }) => {
  const { useGetClientCompetencies } = useClientQueries(client.id);
  const { competencySets, isLoading, refetch } = useGetClientCompetencies();

  const navigate = useNavigate();
  const [showCompetencySetModal, setShowCompetencySetModal] = useState(false);
  const [showCompetencyModal, setShowCompetencyModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedSet, setSelectedSet] = useState(new CompetencySet({}));
  const [selectedCompetency, setSelectedCompetency] =
    useState<Competency | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { appendBreadcrumb } = useBreadcrumbs();

  const toggleCompetencyManager = (set = null, competency = null) => {
    setSelectedSet(new CompetencySet(set));
    setSelectedCompetency(competency ? new Competency(competency) : null);
    setShowCompetencyModal(!showCompetencyModal);
  };

  const toggleSetManager = (set = {}) => {
    setSelectedSet(new CompetencySet(set));
    setShowCompetencySetModal(!showCompetencySetModal);
  };

  const handleDeleteSet = async set => {
    await set.delete(client.id);
    refetch && (await refetch());
  };

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <div className='client-competencies'>
      {competencySets?.length ? (
        <Stack spacing={4}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography variant='h2'>Competency Sets</Typography>
            <Button
              startIcon={<Add />}
              variant='text'
              onClick={() => toggleSetManager()}
              text='Add Set'
            />
          </Stack>
          {competencySets.map((competencySet: CompetencySet, idx) => (
            <Card
              key={competencySet.id}
              collapsible
              defaultOpen={idx === 0}
              className='competency-set-card'
              title={competencySet.name}
              titleActions={
                <MenuButton
                  primaryActionText='View'
                  variant='outlined'
                  onClickPrimaryAction={() => {
                    const path = `${basePath}competency-set/${competencySet.id}`;
                    navigate(path);
                    appendBreadcrumb({
                      path: `/${path}`,
                      label: competencySet.name,
                    });
                  }}
                  secondaryActions={[
                    {
                      key: 'edit',
                      label: 'Edit',
                      onClick: () => toggleSetManager(competencySet),
                    },
                    {
                      key: 'delete',
                      label: 'Delete',
                      onClick: () => {
                        setSelectedSet(competencySet);
                        setIsDeleteModalOpen(true);
                      },
                    },
                  ]}
                />
              }
              subheader={competencySet.description}
            >
              <Table
                hidePagination
                columns={[{ title: 'Competencies', dataIndex: 'title' }]}
                dataSource={competencySet.competencies}
              />
            </Card>
          ))}
          <div style={{ textAlign: 'center' }}>
            <Button
              startIcon={<Add />}
              variant='text'
              onClick={() => toggleSetManager()}
              text='Add Set'
            />
          </div>
        </Stack>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Typography variant='h3' sx={{ marginBottom: '1em' }}>
            Get started creating your own competency sets.
          </Typography>
          <Typography variant='body1' sx={{ marginBottom: '.7em' }}>
            Share the competencies and values that matter to your organization.
          </Typography>
          <Button
            onClick={() => setShowCompetencySetModal(true)}
            text='Start Now'
          />
        </div>
      )}
      {showCompetencySetModal && (
        <CompetencySetManager
          clientId={client.id}
          selectedSet={selectedSet}
          open={showCompetencySetModal}
          onClose={() => setShowCompetencySetModal(false)}
          onCreate={newSet => {
            setSelectedSet(new CompetencySet(newSet));
            setShowConfirmation(true);
          }}
          onSave={refetch}
        />
      )}
      {showCompetencyModal && (
        <CompetencyManagerModal
          clientId={client.id}
          open={showCompetencyModal}
          selectedCompetency={selectedCompetency}
          selectedSet={selectedSet}
          onClose={() => toggleCompetencyManager({})}
          onSave={refetch}
          type={
            selectedSet.competency_label.charAt(0).toUpperCase() +
              selectedSet.competency_label.slice(1) || 'Competency'
          }
        />
      )}
      <ConfirmDialog
        open={showConfirmation}
        setOpenModal={setShowConfirmation}
        title='Competency Set Created'
        body={`${selectedSet?.name} was successfully created. You can now add ${
          selectedSet?.competency_label === 'value' ? 'values' : 'competencies'
        } to it.`}
        cancelText='View Set'
        onCancel={() => {
          setShowConfirmation(false);
          setSelectedSet(null);
        }}
        buttonProps={{
          text: `Add ${startCase(selectedSet?.competency_label)}`,
          onClick: () => {
            toggleCompetencyManager(selectedSet);
          },
        }}
      />
      {isDeleteModalOpen && (
        <ConfirmDialog
          open={isDeleteModalOpen}
          setOpenModal={setIsDeleteModalOpen}
          title='Delete this competency set?'
          body='This action cannot be undone.'
          buttonProps={{
            text: 'Delete',
            onClick: () => handleDeleteSet(selectedSet),
          }}
        />
      )}
    </div>
  );
};

export { ClientCompetencies };
