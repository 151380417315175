import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const EmptyContent = () => (
  <Stack sx={{ width: '100%', textAlign: 'center' }}>
    <Typography variant='h4' sx={{ marginBottom: '0.5em' }}>
      Add Departments
    </Typography>
    <Typography variant='body1' color='grey.700'>
      You can now create and manage departments within your organization.
    </Typography>
    <Typography variant='body1' color='grey.700'>
      Members within an organization can be assigned to departments under the{' '}
      <strong>Members</strong> tab.
    </Typography>
  </Stack>
);

export { EmptyContent };
