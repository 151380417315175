import React, { FC } from 'react';
import { SvgIconProps, Tooltip } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BoltIcon from '@mui/icons-material/Bolt';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InsightsIcon from '@mui/icons-material/Insights';
import InstagramIcon from '@mui/icons-material/Instagram';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import LinkIcon from '@mui/icons-material/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import TheatersIcon from '@mui/icons-material/Theaters';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import TuneIcon from '@mui/icons-material/Tune';
import TwitterIcon from '@mui/icons-material/Twitter';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import WebIcon from '@mui/icons-material/Web';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Schedule from '@mui/icons-material/Schedule';

export const availableIcons = {
  accountCircleOutlined: AccountCircleOutlinedIcon,
  add: AddIcon,
  addCircleOutline: AddCircleOutlineIcon,
  arrowBack: ArrowBackIcon,
  arrowForward: ArrowForwardIcon,
  bolt: BoltIcon,
  business: BusinessIcon,
  calendarTodayOutlined: CalendarTodayOutlinedIcon,
  cancelOutlined: CancelOutlinedIcon,
  check: CheckIcon,
  checkCircleOutline: CheckCircleOutlineIcon,
  close: CloseIcon,
  contentPaste: ContentPasteIcon,
  dashboardOutlined: DashboardOutlinedIcon,
  editOutlined: EditOutlinedIcon,
  emojiEventsOutlined: EmojiEventsOutlinedIcon, // same as trophyOutlined because this is a weird name
  feedOutlined: FeedOutlinedIcon,
  formatListNumbered: FormatListNumberedIcon,
  groupOutlined: GroupOutlinedIcon,
  groupsOutlined: GroupsOutlinedIcon,
  homeOutlined: HomeOutlinedIcon,
  howToRegOutlined: HowToRegOutlinedIcon,
  insertChartOutlined: InsertChartOutlinedIcon,
  insights: InsightsIcon,
  keyboardArrowLeft: KeyboardArrowLeftIcon,
  libraryBooksOutlined: LibraryBooksOutlinedIcon,
  linearScale: LinearScaleIcon,
  listAlt: ListAltIcon,
  logout: LogoutIcon,
  menuBook: MenuBookIcon,
  openInNew: OpenInNewIcon,
  personAddAlt: PersonAddAltIcon,
  personOutlined: PersonOutlinedIcon,
  removeCircleOutline: RemoveCircleOutlineIcon,
  schedule: Schedule,
  search: SearchIcon,
  settingsOutlined: SettingsOutlinedIcon,
  spaceDashboardOutlined: SpaceDashboardOutlinedIcon,
  starOutline: StarOutlineIcon,
  trackChanges: TrackChangesIcon,
  trophyOutlined: EmojiEventsOutlinedIcon,
  tune: TuneIcon,
  videocamOutlined: VideocamOutlinedIcon,
  visibilityOutlined: VisibilityOutlinedIcon,
  web: WebIcon,
  workOutline: WorkOutlineIcon,

  // ICONS USED FOR COACH SOCIAL MEDIA LINKS - names have to match items in db
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  linkedin: LinkedInIcon,
  twitter: TwitterIcon,
  video: TheatersIcon,
  website: LinkIcon,
  youtube: YouTubeIcon,
};

const Icon: FC<{
  name: keyof typeof availableIcons | string;
  className?: string;
  size?: 'inherit' | 'large' | 'medium' | 'small';
  color?: SvgIconProps['color'];
  style?: any;
  altText?: string;
  tooltipText?: string;
}> = ({ name, className, size, color, style = {}, altText, tooltipText }) => {
  const MUIIcon = availableIcons[name];

  if (!MUIIcon) {
    console.error(`Icon ${name} is not available`);
    return <span />;
  }

  // @FYI display block was added so the icon takes up space and can be flex middle aligned with text
  return (
    <Tooltip title={tooltipText} aria-hidden aria-label='Icon tooltip'>
      <MUIIcon
        className={className}
        fontSize={size}
        style={{ ...style, display: 'block' }}
        color={color}
        alt={altText || name}
      />
    </Tooltip>
  );
};

export { Icon };
