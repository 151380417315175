import { ResourceType } from '@shared/schemas';
import { HTTPClient } from '@src/lib/client';

interface TrackResourceViewPayload {
  resource_id: string;
  type: string;
  user_id: string;
  program_id: string;
  client_id: string;
  duration: number;
}

export class ResourcesAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async get(sow?: string) {
    return this.http.request({
      url: `/resources/list${sow ? `?sow=${sow}` : ''}`,
      method: 'get',
    });
  }

  async save(data: ResourceType) {
    return this.http.request({
      url: `/resources/${data.id || 'create'}`,
      method: data.id ? 'put' : 'post',
      data,
    });
  }

  async remove(id: string) {
    return this.http.request({
      url: `/resources/${id}`,
      method: 'delete',
    });
  }

  async trackView(data: TrackResourceViewPayload) {
    return this.http.request({
      url: `/resources/track-view`,
      method: 'post',
      data,
    });
  }
}
