import React from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import {
  Typography,
  Stack,
  Chip,
  IconButton,
  Popover,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table as TableMUI,
  TableBody,
  TableRow,
  TableCell,
  ChipOwnProps,
  TableHead,
} from '@mui/material';
import * as useVerbiage from '@shared/lib/src/use-verbiage';

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const CreateSessionButton: React.FunctionComponent<{
  setCreateSessionModalOpen: Function;
}> = observer(({ setCreateSessionModalOpen }) => {
  return (
    <IconButton
      onClick={() => {
        setCreateSessionModalOpen(true);
      }}
      size='small'
    >
      <AddCircleOutlineIcon fontSize='inherit' />
    </IconButton>
  );
});

function SessionChip({
  session,
  updateSelectedSessionIndex,
  onClick = undefined,
  variant,
}) {
  return (
    <Chip
      key={session.index}
      sx={{
        textAlign: 'center',
        height: '30px',
        width: '65px',
        borderRadius: '8px',
      }}
      variant={variant}
      color={session.color}
      onClick={event => {
        onClick && onClick(event);
        updateSelectedSessionIndex(session.index, session.status);
      }}
      label={session.index + 1}
    />
  );
}

function SessionChipWithPopover({
  session,
  updateSelectedSessionIndex,
  sessionsTrackerInfo,
  program,
  user,
  variant,
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `view-session-details-${session.key}` : undefined;

  const sessionTagInfo = i => {
    const session = sessionsTrackerInfo.list[i.index];
    const v = useVerbiage.init(
      program.type === 'mentoring' ? ['isMentor'] : [],
    );

    const columns = [
      { title: 'Name', dataIndex: 'name', key: 'name' },
      {
        title: 'Role',
        dataIndex: 'user_role',
        key: 'role',
        render: text => (
          <>{text === 'coach' ? v('coach', true) : v('coachee', true)}</>
        ),
      },
      {
        title: 'Activity',
        dataIndex: 'type',
        key: 'activity',
        render: text => {
          let Icon = LogoutIcon;
          let color: ChipOwnProps['color'] = 'error';
          switch (text) {
            case 'scheduled':
              Icon = CalendarTodayOutlinedIcon;
              color = 'primary';
              break;
            case 'joined':
              Icon = LoginIcon;
              color = 'tertiary';
              break;
          }
          return (
            <Chip
              color={color}
              icon={<Icon sx={{ fontSize: '22px', paddingLeft: '5px' }} />}
              label={text.charAt(0).toUpperCase() + text.slice(1)}
            />
          );
        },
      },
      { title: 'Timestamp', dataIndex: 'date', key: 'date' },
    ];

    if (session) {
      const coachId = session.members.find(m => m.role === 'coach').id;
      const userId = session.members.find(m => m.role !== 'coach').id;
      const coach = session.member_info[coachId];
      const coachee = session.member_info[userId];
      const displayType = ['scheduled', 'joined', 'left'];
      const sessionData = session.session_activity.filter(s => {
        if (s.user_id === coachId) {
          s.user_role = 'coach';
          s.name = coach.first_name.concat(' ', coach.last_name);
        } else {
          s.user_role = 'coachee';
          s.name = coachee.first_name.concat(' ', coachee.last_name);
        }
        s.date = dayjs(s.timestamp).format('ddd M/D LT');
        if (displayType.includes(s.type)) return s;
      });

      return (
        <Box sx={{ padding: '1em' }}>
          {user.is_admin ? (
            <Stack spacing={2} padding={2}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography
                  variant='body1sb'
                  color='primary'
                  sx={{ width: '85px' }}
                >
                  {v('coach', true)}
                </Typography>
                <Typography variant='body1'>{`${coach.first_name} ${coach.last_name}`}</Typography>
              </Stack>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography
                  variant='body1sb'
                  color='primary'
                  sx={{ width: '85px' }}
                >
                  Session ID
                </Typography>
                <Typography variant='body1'>{session.id}</Typography>
              </Stack>
            </Stack>
          ) : null}
          <Accordion>
            <AccordionSummary>
              <Typography variant='body1sb' color='primary'>
                Activity
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableMUI>
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell key={`header-${column.key}`}>
                        {column.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sessionData.map((row, idx) => (
                    <TableRow key={columns[0].key}>
                      {columns.map(column => (
                        <TableCell key={`cell-${column.key}-${idx}`}>
                          {column.render
                            ? column.render(row[column.dataIndex])
                            : row[column.dataIndex]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </TableMUI>
            </AccordionDetails>
          </Accordion>
        </Box>
      );
    }
  };

  return (
    <>
      <SessionChip
        aria-describedby={id}
        session={session}
        updateSelectedSessionIndex={updateSelectedSessionIndex}
        onClick={handleClick}
        variant={variant}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        {sessionTagInfo(session)}
      </Popover>
    </>
  );
}
export { CreateSessionButton, SessionChip, SessionChipWithPopover };
