import { useCallback } from 'react';
import * as Yup from 'yup';

const addressSchema = Yup.object({
  type: Yup.string<'all' | 'mailing' | 'billing'>().required(),
  street_address_1: Yup.string().required(
    'Street address 1 is required if any other fields are entered',
  ),
  street_address_2: Yup.string(),
  city: Yup.string().required(
    'City is required if any other fields are entered',
  ),
  state: Yup.string().required(
    'State is required if any other fields are entered',
  ),
  zip: Yup.string().required('Zip is required if any other fields are entered'),
  country: Yup.string().required(
    'Country is required if any other fields are entered',
  ),
});

const addressSchemaResolver = data => {
  const addresses = data.addresses?.map(a => a.data);
  let result = { values: data, errors: {} };
  for (const a of addresses) {
    if (!a) continue;
    const index = a.type === 'billing' ? 1 : 0;
    const props = Object.values(a).filter(v => v);
    // if only `type` is set, it's valid
    if (props.length === 1) {
      continue;
    }
    // only validate editable fields
    const filteredProps = [
      'street_address_1',
      'street_address_2',
      'city',
      'state',
      'country',
      'zip',
    ];
    // if anything else is entered, everything should be entered,
    // but if everything is empty, it's valid
    const condition = props.length > 1;
    const otherValueEntered = filteredProps.some(prop => {
      if (prop === 'street_address_2') {
        return false;
      }
      return Boolean(a[prop]) === condition;
    });
    if (!otherValueEntered) {
      continue;
    }
    if (condition) {
      try {
        addressSchema.validateSync(a, { abortEarly: false });
        continue;
      } catch (errors) {
        result = {
          ...result,
          errors: {
            ...result.errors,
            ...errors.inner.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [`addresses.${index}.${currentError.path}`]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {},
            ),
          },
        };
      }
    }
  }
  return result;
};

const useSchemaResolver = schema =>
  useCallback(addressSchemaResolver, [schema]);

export { addressSchema, addressSchemaResolver, useSchemaResolver };
