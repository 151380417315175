import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { DatePicker } from '@src/components/sc-design-system';
import { Controller, UseFormReturn } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';

interface EvaluationPeriodFormProps {
  onSubmit: (data: EvaluationPeriodOnSubmitProps) => void;
  form: UseFormReturn<EvaluationPeriodFormDefaultValues, undefined>;
  performanceCycleStartDate: number;
}

const EvaluationPeriodForm: React.FunctionComponent<
  EvaluationPeriodFormProps
> = props => {
  const { onSubmit, form, performanceCycleStartDate } = props;
  const {
    control,
    handleSubmit,
    getValues,
    resetField,
    formState: { errors, touchedFields },
  } = form;
  return (
    <form onSubmit={handleSubmit(onSubmit)} id='evaluation-period-form'>
      <Typography variant='body1' sx={{ marginBottom: '1em' }}>
        Self-evaluation period
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            name='self_evaluation_start'
            control={control}
            rules={{
              required: 'Self-evaluation start date is required',
              validate: {
                start: (v: Dayjs) => v.isValid() || 'Please enter a valid date',
              },
            }}
            render={({ field }) => {
              const { ref, onChange, ...fieldProps } = field;
              return (
                <DatePicker
                  {...fieldProps}
                  onChange={e => {
                    onChange(e);
                    // the following will ensure end is cleared out if a user sets a start/end and then change the start date
                    resetField('self_evaluation_end', { defaultValue: '' });
                  }}
                  label='Start Date'
                  fullWidth
                  innerRef={ref}
                  minDate={
                    performanceCycleStartDate &&
                    dayjs(performanceCycleStartDate)
                  }
                  error={Boolean(
                    touchedFields.self_evaluation_start &&
                      errors?.self_evaluation_start,
                  )}
                  errorMessage={
                    touchedFields.self_evaluation_start &&
                    typeof errors?.self_evaluation_start?.message ===
                      'string' &&
                    errors?.self_evaluation_start?.message
                  }
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name='self_evaluation_end'
            control={control}
            rules={{
              required: 'Self-evaluation end date is required',
              validate: {
                end: (v: Dayjs) => {
                  if (!v.isValid()) return 'Please enter a valid date';
                  if (
                    getValues('self_evaluation_start') &&
                    v.isBefore(dayjs(getValues('self_evaluation_start')))
                  ) {
                    return 'Self-evaluation end date must be after self-evaluation start date.';
                  }
                },
              },
            }}
            render={({ field }) => {
              const { ref, ...fieldProps } = field;
              return (
                <DatePicker
                  {...fieldProps}
                  label='End Date'
                  innerRef={ref}
                  minDate={
                    getValues('self_evaluation_start') &&
                    dayjs(getValues('self_evaluation_start')).add(1, 'day')
                  }
                  fullWidth
                  error={Boolean(
                    touchedFields.self_evaluation_end &&
                      errors?.self_evaluation_end,
                  )}
                  errorMessage={
                    touchedFields.self_evaluation_end &&
                    typeof errors?.self_evaluation_end?.message === 'string' &&
                    errors?.self_evaluation_end?.message
                  }
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <Typography variant='body1' sx={{ marginBottom: '1em' }}>
        Manager evaluation period
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            name='manager_evaluation_start'
            control={control}
            rules={{
              required: 'Manager evaluation start date is required',
              validate: {
                start: (v: Dayjs) => v.isValid() || 'Please enter a valid date',
              },
            }}
            render={({ field }) => {
              const { ref, onChange, ...fieldProps } = field;
              return (
                <DatePicker
                  {...fieldProps}
                  onChange={e => {
                    onChange(e);
                    // the following will ensure end is cleared out if a user sets a start/end and then change the start date
                    resetField('manager_evaluation_end', { defaultValue: '' });
                  }}
                  label='Start Date'
                  minDate={
                    performanceCycleStartDate &&
                    dayjs(performanceCycleStartDate)
                  }
                  fullWidth
                  innerRef={ref}
                  error={Boolean(
                    touchedFields.manager_evaluation_start &&
                      errors?.manager_evaluation_start,
                  )}
                  errorMessage={
                    touchedFields.manager_evaluation_start &&
                    typeof errors?.manager_evaluation_start?.message ===
                      'string' &&
                    errors?.manager_evaluation_start?.message
                  }
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name='manager_evaluation_end'
            control={control}
            rules={{
              required: 'Manager evaluation end date is required',
              validate: {
                end: (v: Dayjs) => {
                  if (!v.isValid()) return 'Please enter a valid date';
                  if (
                    getValues('manager_evaluation_start') &&
                    v.isBefore(dayjs(getValues('manager_evaluation_start')))
                  ) {
                    return 'Manager evaluation end date must be after manager evaluation start date.';
                  }
                },
              },
            }}
            render={({ field }) => {
              const { ref, ...fieldProps } = field;
              return (
                <DatePicker
                  {...fieldProps}
                  label='End Date'
                  innerRef={ref}
                  minDate={
                    getValues('manager_evaluation_start') &&
                    dayjs(getValues('manager_evaluation_start')).add(1, 'day')
                  }
                  fullWidth
                  error={Boolean(
                    touchedFields.manager_evaluation_end &&
                      errors?.manager_evaluation_end,
                  )}
                  errorMessage={
                    touchedFields.manager_evaluation_end &&
                    typeof errors?.manager_evaluation_end?.message ===
                      'string' &&
                    errors?.manager_evaluation_end?.message
                  }
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export { EvaluationPeriodForm };
