import React, { FC, useState } from 'react';
import MUICard, { CardProps as MUICardProps } from '@mui/material/Card';
import MuiCardHeader from '@mui/material/CardHeader';
import CardContent, { CardContentProps } from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Stack from '@mui/material/Stack';
import { Box, Typography, TypographyOwnProps } from '@mui/material';
import { handleKeyboardNav } from '@src/utils';

type TitleStylesOptions = 'h2styleh3' | 'h2styleh4' | 'h3styleh4';

type TitleStyle = {
  [key in TitleStylesOptions]: {
    variant: TypographyOwnProps['variant'];
    component: React.ElementType<any>;
  };
};

const titleStyles: TitleStyle = {
  h2styleh3: { variant: 'h3', component: 'h2' },
  h2styleh4: { variant: 'h4', component: 'h2' },
  h3styleh4: { variant: 'h4', component: 'h3' },
};

export interface CardProps extends Omit<MUICardProps, 'title'> {
  title?: JSX.Element | string;
  titleStyle?: TitleStylesOptions;
  titleActions?: any;
  titleIcon?: any;
  bodyStyle?: CardContentProps['sx'];
  subheader?: JSX.Element | string;
  collapsible?: boolean;
  defaultOpen?: boolean;
  headerColor?: 'primary' | 'grey';
  headerOnClick?: () => void;
}

const Card: FC<CardProps> = ({
  title,
  titleActions = null,
  titleStyle = 'h2styleh3',
  titleIcon,
  bodyStyle = {},
  subheader = null,
  collapsible = false,
  defaultOpen = true,
  children,
  headerColor,
  headerOnClick,
  ...props
}) => {
  const [open, setOpen] = useState(defaultOpen);
  let iconButtonColor;
  let iconButtonVariant;
  let headerSx = {};
  let typefaceSx = {};
  let actionAreaSx = {
    '& .MuiCardHeader-root:hover': { backgroundColor: 'grey.200' },
    '& .MuiCardHeader-root:active, & .MuiCardHeader-root:focus-visible': {
      backgroundColor: 'grey.300',
    },
  };
  if (headerColor === 'grey') {
    headerSx = { backgroundColor: 'grey.300' };
    iconButtonVariant = 'over-grey';
    iconButtonColor = 'primary';
    actionAreaSx = {
      '& .MuiCardHeader-root:hover': { backgroundColor: 'grey.400' },
      '& .MuiCardHeader-root:active, & .MuiCardHeader-root:focus-visible': {
        backgroundColor: 'grey.500',
      },
    };
  }
  if (headerColor === 'primary') {
    headerSx = { backgroundColor: 'primary.main' };
    typefaceSx = { color: 'primary.contrastText' };
    iconButtonVariant = 'over-primary';
    iconButtonColor = 'grey';
    actionAreaSx = {
      '& .MuiCardHeader-root:hover': { backgroundColor: 'primary.600' },
      '& .MuiCardHeader-root:active, & .MuiCardHeader-root:focus-visible': {
        backgroundColor: 'primary.700',
      },
    };
  }

  const Title = () => {
    return typeof title === 'string' ? (
      <Typography
        variant={titleStyles[titleStyle].variant}
        component={titleStyles[titleStyle].component}
        sx={{ marginLeft: 0, ...typefaceSx }}
      >
        {title}
      </Typography>
    ) : (
      title
    );
  };

  const CardHeader = () => {
    return (
      <MuiCardHeader
        avatar={titleIcon}
        disableTypography
        title={
          collapsible ? (
            <Stack direction='row' alignItems='center' spacing={1}>
              <IconButton
                color={iconButtonColor}
                variant={iconButtonVariant}
                onClick={event => {
                  if (headerOnClick) event.stopPropagation();
                  setOpen(!open);
                }}
                sx={{ transform: open ? 'rotate(90deg)' : '' }}
              >
                <ChevronRightIcon />
              </IconButton>
              <Title />
            </Stack>
          ) : (
            <Title />
          )
        }
        action={titleActions}
        subheader={
          subheader ? (
            <div
              style={{
                marginLeft: collapsible ? '3.2em' : '0',
                marginTop: '4px',
              }}
            >
              {subheader}
            </div>
          ) : null
        }
        sx={{
          '.MuiCardHeader-avatar': {
            marginRight: '.75em',
            '.MuiSvgIcon-root': {
              height: '24px',
              width: '24px',
            },
          },
          '.MuiCardHeader-action': {
            padding: collapsible ? '1em' : '.5em 1em',
          },
          ...headerSx,
        }}
      />
    );
  };

  return (
    <MUICard {...props}>
      {title &&
        (headerOnClick ? (
          <Box
            onClick={headerOnClick}
            onKeyDown={e => handleKeyboardNav(e.key, headerOnClick)}
            sx={{
              cursor: 'pointer',
              ...actionAreaSx,
            }}
            tabIndex={0}
            component='div'
            role='button'
          >
            <CardHeader />
          </Box>
        ) : (
          <CardHeader />
        ))}
      {collapsible && (
        <Collapse in={open}>
          <CardContent sx={bodyStyle}>{children}</CardContent>
        </Collapse>
      )}
      {!collapsible && <CardContent sx={bodyStyle}>{children}</CardContent>}
    </MUICard>
  );
};

export { Card };
