import React, { FC, useState, useMemo } from 'react';
import '@fullcalendar/react/dist/vdom'; // hack around vite esm issue
import FullCalendar, { EventApi } from '@fullcalendar/react'; // must go before plugins
import timeGridPlugin from '@fullcalendar/timegrid';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import {
  Card,
  Spin,
  DialogTitle,
  Select,
  useToast,
} from '@src/components/sc-design-system';
import { SessionStatusOverride } from '@src/models/Session';
import Session from '@src/models/Session';
import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Dialog,
  DialogContent,
  Divider,
  Stack,
} from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';

import './SessionsCalendar.less';
import { api } from '@src/lib/client';
import { useStore } from '@src/models/Store';
import { useQuery } from '@tanstack/react-query';

const sessionBackgroundColor = 'rgb(128, 40, 126)';
const interviewBackgroundColor = 'rgb(14, 191, 218)';
const missedBackgroundColor = 'rgb(237, 34, 36)';
const canceledBackgroundColor = '#ccc';
const inAlertBackgroundColor = 'rgb(255, 201, 7)';

export const SessionsCalendar: FC = () => {
  const store = useStore();
  const toast = useToast();
  const userId = store.user.id;
  const [detailsModal, setDetailsModal] = useState<EventApi | undefined>();
  const navigate = useNavigate();
  const calStart = useMemo(() => dayjs().startOf('week').valueOf(), []);
  const calEnd = useMemo(() => dayjs().endOf('week').valueOf(), []);

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['dashboard', 'sessions', { calStart, calEnd }],
    queryFn: async () => {
      try {
        return await api.admin.getSessionsCalendarData(calStart, calEnd);
      } catch (e) {
        toast.error('Unable to load sessions');
        return { data: {} };
      }
    },
    select: ({ data }) => ({
      sessions: data.map(s => {
        const session = new Session(s);
        const missed = session.isMissed;
        const canceled = session.isCancelled;
        const inAlert =
          !['completed', 'canceled', 'rescheduled'].includes(
            s.current_status,
          ) &&
          s.start < Date.now() &&
          !s.status_overridden;

        return {
          title: s.members
            .sort((a, b) => (a.coach > b.coach ? -1 : 1))
            .map(m => m.first_name)
            .join(' - '),
          date: new Date(s.start),
          end: new Date(s.end),
          backgroundColor: missed
            ? missedBackgroundColor
            : canceled
            ? canceledBackgroundColor
            : inAlert
            ? inAlertBackgroundColor
            : s.type === 'coaching'
            ? sessionBackgroundColor
            : interviewBackgroundColor,
          borderColor: missed
            ? missedBackgroundColor
            : canceled
            ? canceledBackgroundColor
            : inAlert
            ? inAlertBackgroundColor
            : s.type === 'coaching'
            ? sessionBackgroundColor
            : interviewBackgroundColor,
          textColor: canceled ? missedBackgroundColor : '#ffffff',
          extendedProps: {
            id: s.id,
            program: s.program,
            client: s.client,
            type: s.type,
            fullNameMembers: s.members
              .sort((a, b) => (a.coach > b.coach ? -1 : 1))
              .map(m => `${m.first_name} ${m.last_name}`)
              .join(' - '),
            current_status: s.current_status,
            status_overridden: s.status_overridden,
            calculated_status: missed ? 'missed' : s.current_status,
            start: s.start,
          },
        };
      }),
    }),
  });

  return (
    <Card className='dashboard-sessions-calendar'>
      {detailsModal && (
        <Dialog open onClose={() => setDetailsModal(undefined)}>
          <DialogTitle
            title={
              <Stack>
                <Typography variant='h3'>
                  {detailsModal.extendedProps.fullNameMembers}
                </Typography>
                <Typography variant='subtitle1' color='grey.700'>
                  {detailsModal.extendedProps.type.toUpperCase()}
                </Typography>
              </Stack>
            }
            buttonOnClick={() => setDetailsModal(undefined)}
          />
          <DialogContent>
            <List dense={true}>
              <ListItem>
                <ListItemIcon
                  children={<CalendarTodayOutlinedIcon fontSize='small' />}
                />
                <ListItemText
                  primary={
                    <>
                      {dayjs(detailsModal.start).format('LLL')} -{' '}
                      {dayjs(detailsModal.end).format('H:mm a')}
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon children={<WorkOutlineIcon fontSize='small' />} />
                <ListItemText
                  primary={
                    <>
                      <Link
                        variant='a2'
                        onClick={() =>
                          navigate(
                            `/my-business/${detailsModal.extendedProps.client.id}`,
                          )
                        }
                      >
                        {detailsModal.extendedProps.client.name}
                      </Link>
                      :{' '}
                      <Link
                        variant='a2'
                        onClick={() =>
                          navigate(
                            `/program/${detailsModal.extendedProps.program.id}`,
                          )
                        }
                      >
                        {detailsModal.extendedProps.program.name}
                      </Link>
                    </>
                  }
                />
              </ListItem>
            </List>
            <Divider />
            <Typography
              variant='body1'
              sx={{
                textTransform: 'capitalize',
                marginBottom: '0.5em',
              }}
            >
              Current Status: {detailsModal.extendedProps.calculated_status}{' '}
              {detailsModal.extendedProps.status_overridden && (
                <em>(Overridden)</em>
              )}
            </Typography>
            {detailsModal.extendedProps.start < Date.now() && (
              <Select
                placeholder='Update Status'
                disabled={isFetching}
                options={[
                  {
                    options: [
                      { label: 'Completed', value: 'completed' },
                      { label: 'Canceled', value: 'canceled' },
                      { label: 'Missed', value: 'scheduled' },
                    ],
                  },
                ]}
                value={detailsModal.extendedProps.current_status}
                onChange={async e => {
                  try {
                    const { data } = await api.sessions.get({
                      sessionId: detailsModal.extendedProps.id,
                    });
                    const session = new Session(data.data[0]);
                    session.status_overridden = new SessionStatusOverride({
                      original_status: session.current_status,
                      timestamp: Date.now(),
                      user_id: userId,
                    });
                    session.current_status = e.target.value as string;
                    await session.save(true);
                  } catch (e) {
                    toast.error('Could not update session status');
                  }
                  refetch();
                  setDetailsModal(undefined);
                }}
                sx={{ width: '40%' }}
                fullWidth
              />
            )}
            <Divider />
            <Typography
              variant='body2'
              sx={{ marginBottom: '0.3em' }}
              color='grey.700'
            >
              Session ID: {detailsModal.extendedProps.id}
            </Typography>
          </DialogContent>
        </Dialog>
      )}
      {isLoading ? (
        <Spin sectionLoader />
      ) : (
        <>
          <FullCalendar
            plugins={[timeGridPlugin]}
            initialView='timeGridWeek'
            eventClick={arg => {
              const { event } = arg;
              setDetailsModal(event);
            }}
            allDaySlot={false}
            slotMinTime='05:00:00'
            slotMaxTime='22:00:00'
            height='60vh'
            events={data?.sessions}
          />
          <CalendarLegend />
        </>
      )}
    </Card>
  );
};

function CalendarLegend() {
  const legends = [
    { color: sessionBackgroundColor, label: 'Coaching Session' },
    { color: interviewBackgroundColor, label: 'Interview Session' },
    {
      color: inAlertBackgroundColor,
      label: 'Session In Progress/Requires Attention',
    },
    { color: missedBackgroundColor, label: 'Missed Session' },
    { color: canceledBackgroundColor, label: 'Canceled Session' },
  ];
  return (
    <ul className='calendar-legend-container'>
      {legends.map(({ color, label }) => (
        <li key={label} style={{ display: 'flex' }}>
          <div
            className='calendar-legend'
            style={{ backgroundColor: color }}
          ></div>
          {label}
        </li>
      ))}
    </ul>
  );
}
