import React from 'react';
import { Stack, Typography, Link, Divider } from '@mui/material';
import User from '@src/models/User';
import { Card } from '@src/components/sc-design-system';
import { ViewRatingModal } from '@src/components/common/PerformanceCycle/ViewRatingModal';
import { SurveySubmissionModal } from '@src/components/common/SurveySubmissionModal';
import { useUserQueries } from '@src/hooks/useUserQueries';

interface SelfEvaluationProps {
  profileUser: User;
  selectedPerformanceCycle: PerformanceCycleI;
  performanceCycleResults: PerformanceCycleResultObj;
}

const SelfEvaluation: React.FunctionComponent<SelfEvaluationProps> = props => {
  const { profileUser, selectedPerformanceCycle, performanceCycleResults } =
    props;
  const [viewModal, setViewModal] = React.useState(false);
  const [submissionModalOpen, setSubmissionModalOpen] = React.useState(false);

  const { useGetUserSurveySubmission } = useUserQueries(profileUser.id);
  const { surveySubmission } = useGetUserSurveySubmission(
    {
      surveyId: selectedPerformanceCycle?.self_evaluation_form_id,
      performance_cycle_id: selectedPerformanceCycle?.id,
    },
    Boolean(performanceCycleResults?.direct_report?.evaluation_submission_id),
  );

  const ratingSubmitted =
    performanceCycleResults?.direct_report?.rating != null;
  const evaluationSubmitted =
    !!performanceCycleResults?.direct_report?.evaluation_submission_id;
  const bothSubmitted = ratingSubmitted && evaluationSubmitted;

  if (!ratingSubmitted && !evaluationSubmitted) {
    return null;
  }

  return (
    <Card elevation={5} variant='elevation' sx={{ margin: '.75em 0' }}>
      <Typography variant='h4' sx={{ marginBottom: '1em' }}>
        {profileUser.first_name}'s self-evaluation
      </Typography>
      <Typography variant='body1'>
        You can now view {profileUser.first_name}'s{' '}
        {evaluationSubmitted ? 'self-evaluation responses' : ''}
        {bothSubmitted ? ' and ' : ''}
        {ratingSubmitted ? 'overall rating' : ''}.
      </Typography>
      <Stack
        direction='row'
        divider={<Divider orientation='vertical' flexItem />}
        spacing={2}
        sx={{ marginTop: '1.5em' }}
      >
        {evaluationSubmitted && surveySubmission && (
          <>
            {submissionModalOpen && (
              <SurveySubmissionModal
                surveySubmission={surveySubmission}
                onClose={() => setSubmissionModalOpen(false)}
                ratingType='report'
                firstName={profileUser.first_name}
              />
            )}
            <Link
              variant='a1'
              onClick={() => {
                setSubmissionModalOpen(true);
              }}
            >
              View evaluation
            </Link>
          </>
        )}
        {ratingSubmitted && (
          <>
            <ViewRatingModal
              viewModal={viewModal}
              setViewModal={setViewModal}
              profileUser={profileUser}
              performanceCycle={selectedPerformanceCycle}
              rating={performanceCycleResults.direct_report.rating}
              ratingType='report'
            />
            <Link variant='a1' onClick={() => setViewModal(true)}>
              View overall rating
            </Link>
          </>
        )}
      </Stack>
    </Card>
  );
};

export default SelfEvaluation;
