import React from 'react';
import { Stack, Chip } from '@mui/material';
import { useSkills } from '@src/hooks/useSkills';

function CoacheeSearchTopics(props: { coachee: any; isOpen: boolean }) {
  const { coachee, isOpen } = props;
  const { skills } = useSkills({ enabled: isOpen });
  return (
    coachee?.search_topics?.length > 0 && (
      <Stack direction='row' spacing={1} sx={{ marginTop: '1.5em' }}>
        {coachee.search_topics.map(sT => {
          const topic = skills?.find(s => s.id === sT)?.title;
          return (
            topic && (
              <Chip
                custom-color='black'
                key={sT?.replace(' ', '&nbsp;')}
                label={topic}
                size='medium'
                className='shape-rounded-square'
              />
            )
          );
        })}
      </Stack>
    )
  );
}

export default CoacheeSearchTopics;
