import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import {
  Button,
  DialogTitle,
  useToast,
  TextField,
} from '@src/components/sc-design-system';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { api } from '@src/lib/client';

interface ConversationFeedbackModalProps {
  setOpenModal: (b: boolean) => void;
  openModal: boolean;
  thumbsUp: boolean;
  conversationUserId: string;
  conversationId: string;
  fetchConversations: Function;
}

const ConversationFeedbackModal: React.FunctionComponent<
  ConversationFeedbackModalProps
> = props => {
  const toast = useToast();
  const { thumbsUp, conversationId, conversationUserId, fetchConversations } =
    props;
  const { setOpenModal, openModal } = props;
  const form = useForm({
    mode: 'all',
    defaultValues: { feedback: '' },
  });
  const { control, handleSubmit } = form;

  const { mutate: submitFeedback, isPending } = useMutation({
    mutationFn: async (payload: Partial<AidaConversationRating>) => {
      return api.users.updateAidaConversationRating(
        conversationUserId,
        conversationId,
        payload,
      );
    },
    onSuccess: async () => {
      await fetchConversations();
      setOpenModal(false);
    },
    onError: () => toast.error('Unable to save feedback'),
  });

  const onSubmit = (data: Partial<AidaConversationRating>) => {
    submitFeedback({ thumbs_up: thumbsUp, feedback: data.feedback });
  };

  function handleClose() {
    setOpenModal(false);
  }

  return (
    <Dialog open={openModal} onClose={() => handleClose()} maxWidth='sm'>
      <DialogTitle title='Provide feedback' />
      <DialogContent dividers>
        <form onSubmit={handleSubmit(onSubmit)} id='feedback-form'>
          <Typography variant='body1' sx={{ marginBottom: '1em' }}>
            Please provide details about your rating:
          </Typography>
          <Typography
            variant='body2'
            color='grey.700'
            sx={{ marginBottom: '1em' }}
          >
            Optional
          </Typography>
          <Controller
            name='feedback'
            control={control}
            render={({ field }) => {
              const { ref, ...fieldProps } = field;
              return (
                <TextField
                  {...fieldProps}
                  innerRef={ref}
                  fullWidth
                  placeholder=''
                  id={field.name}
                />
              );
            }}
          />
          <Typography
            variant='body1'
            color='grey.700'
            sx={{ marginTop: '1em' }}
          >
            Your feedback is used to improve our models and will be anonymized
            and stored in our databases.
          </Typography>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          key='cancel'
          text='Cancel'
          variant='outlined'
          onClick={handleClose}
        />
        <Button
          sx={{ marginLeft: '.5em' }}
          type='submit'
          form='feedback-form'
          key='submit'
          text='Submit'
          loading={isPending}
        />
      </DialogActions>
    </Dialog>
  );
};

export { ConversationFeedbackModal };
