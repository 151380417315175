import React from 'react';
import {
  Grid,
  Typography,
  Button,
  Stack,
  Alert,
  AlertTitle,
} from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import User from '@src/models/User';

interface OverallRatingFormProps {
  onSubmit: (data: OverallRatingOnSubmitProps) => void;
  profileUser: User;
  form: UseFormReturn<OverallRatingFormDefaultValues, undefined>;
  ratingScale: { value: number; text?: string }[];
  ratingType: 'self' | 'manager';
}

const OverallRatingForm: React.FunctionComponent<
  OverallRatingFormProps
> = props => {
  const { onSubmit, form, ratingScale, profileUser, ratingType } = props;
  const { control, handleSubmit, watch, setValue } = form;
  return (
    <form onSubmit={handleSubmit(onSubmit)} id='overall-rating-form'>
      <Typography variant='body1' sx={{ marginBottom: '1.5em' }}>
        {ratingType === 'self' ? (
          <>
            Provide a rating that best reflects your performance for this cycle:{' '}
          </>
        ) : (
          <>
            Provide a rating that best reflects {profileUser.first_name}'s
            performance for this cycle.
          </>
        )}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name='rating'
            control={control}
            render={({ field }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                  {ratingScale.map(({ value, text }) => {
                    const selected = watch('rating') === value;
                    return (
                      <Button
                        {...fieldProps}
                        onClick={() => {
                          const newValue = selected ? undefined : value;
                          setValue('rating', newValue, {
                            shouldDirty: true,
                          });
                        }}
                        key={value}
                        className='shape-rounded-square'
                        sx={{ width: '166px' }}
                        onKeyDown={e => {
                          if (e.key === 'Tab') {
                            e.target.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                              inline: 'nearest',
                            });
                          }
                        }}
                        variant={selected ? 'contained' : 'outlined'}
                      >
                        {text.length ? text : value}
                      </Button>
                    );
                  })}
                </Stack>
              );
            }}
          />
          <Alert severity='info' sx={{ margin: '2.5em 0 0' }}>
            <AlertTitle>Important!</AlertTitle>
            <Typography variant='body2'>
              {ratingType === 'self' ? (
                <>
                  Once you submit your rating, it is final and cannot be
                  changed. It will also be visible by your manager and other
                  members of your organization with administrative access.
                </>
              ) : (
                <>
                  Once your overall rating is submitted, your direct report will
                  be able to view it and you will not be able to edit it.
                </>
              )}
            </Typography>
          </Alert>
        </Grid>
      </Grid>
    </form>
  );
};

export { OverallRatingForm };
