import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import querystring from 'node:querystring';
import {
  Stack,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Skeleton,
  Backdrop,
  Box,
} from '@mui/material';
import { Button } from '@src/components/sc-design-system';
import { ChatBubble } from './ChatBubble';
import { useIntercom } from 'react-use-intercom';

function CoachCard(props: { coach: AidaCoach }) {
  const { id, first_name, biography, city, state, country } = props.coach;
  const COUNTRIES = { US: 'United States', CA: 'Canada' };
  const geography = [];
  if (city) geography.push(city);
  if (state) geography.push(state);
  if (country) geography.push(COUNTRIES[country] || country);

  const { data: avatarSrc, isLoading } = useQuery({
    queryKey: ['avatar', id],
    queryFn: async () => {
      return `${process.env.ASSETS_ROOT}/${id}/avatar.png?cache=${Date.now()}`;
    },
    staleTime: 1000 * 60 * 60,
    enabled: !!id,
  });

  return (
    <Card key={`${id}-id`} sx={{ width: '195px', overflow: 'hidden' }}>
      <CardMedia>
        {isLoading ? (
          <Skeleton
            animation='wave'
            variant='rectangular'
            width='195px'
            height='200px'
          />
        ) : (
          <img style={{ maxWidth: '195px' }} src={avatarSrc} alt={first_name} />
        )}
      </CardMedia>
      <CardContent sx={{ padding: '1em' }}>
        <Typography sx={{ marginBottom: '.5em' }}>{first_name}</Typography>
        {geography.length > 0 && (
          <Typography variant='body2' color='grey.700'>
            {geography.join(', ')}
          </Typography>
        )}
        <Typography
          variant='body3'
          sx={{
            marginTop: '1em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
          }}
          color='grey.700'
        >
          {biography}
        </Typography>
      </CardContent>
    </Card>
  );
}

interface ExploreMarketplaceContentProps {
  programId: string;
  programType: ProgramType;
  keywords: AidaTag[];
}

interface CallToActionCardProps extends ExploreMarketplaceContentProps {
  isCoachingAvailable: boolean;
}

function CallToActionCard({
  isCoachingAvailable,
  ...props
}: CallToActionCardProps) {
  return (
    <Card
      sx={{
        width: '195px',
        padding: '1em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {!isCoachingAvailable ? (
        <ContactSupportContent />
      ) : (
        <ExploreMarketplaceContent {...props} />
      )}
    </Card>
  );
}

function CoachesFollowUp(props: {
  assistantMessage: AidaMessage;
  programType: ProgramType;
  programId: string;
  isCoachingAvailable: boolean;
}) {
  const { assistantMessage, isCoachingAvailable, programType, programId } =
    props;

  const showBackdrop = !assistantMessage.summary;

  return (
    <>
      <ChatBubble sender='aida'>
        <Typography sx={{ marginBottom: '1.5em' }}>
          {assistantMessage?.summary
            ? 'Explore our curated list of coaches based on areas of support you want to focus on:'
            : 'Explore coaches in the marketplace'}
        </Typography>
        {Array.isArray(assistantMessage?.additionalData) && (
          <Box sx={{ position: 'relative' }}>
            <Backdrop
              open={showBackdrop}
              sx={{
                position: 'absolute',
                borderRadius: '8px',
                alignItems: 'center',
                backdropFilter: 'blur(3px)',
              }}
            >
              <Card sx={{ width: '20em' }}>
                <CardContent
                  sx={{
                    padding: '24px',
                    paddingBottom: '24px !important',
                    textAlign: 'center',
                  }}
                >
                  {!isCoachingAvailable ? (
                    <ContactSupportContent />
                  ) : (
                    <ExploreMarketplaceContent
                      programId={programId}
                      programType={programType}
                      keywords={assistantMessage?.data as AidaTag[]}
                    />
                  )}
                </CardContent>
              </Card>
            </Backdrop>
            <Stack spacing={1} direction='row' useFlexGap flexWrap='wrap'>
              {assistantMessage.additionalData.map((c, i) => (
                <CoachCard key={`coach-${i}`} coach={c as AidaCoach} />
              ))}
              {!showBackdrop && (
                <CallToActionCard
                  isCoachingAvailable={isCoachingAvailable}
                  programId={programId}
                  programType={programType}
                  keywords={assistantMessage?.data as AidaTag[]}
                />
              )}
            </Stack>
          </Box>
        )}
      </ChatBubble>
    </>
  );
}

function ContactSupportContent() {
  const intercom = useIntercom();

  return (
    <>
      <Typography variant='body2' sx={{ marginBottom: '1em' }}>
        Coaching is currently not offered for your organization. Reach out if
        you're interested in coaching.
      </Typography>
      <Button
        text='Contact support'
        formatText={false}
        onClick={() => intercom.show()}
        size='small'
      />
    </>
  );
}

function ExploreMarketplaceContent({
  programId,
  programType,
  keywords,
}: ExploreMarketplaceContentProps) {
  const navigate = useNavigate();
  return (
    <>
      <Typography variant='body2' sx={{ marginBottom: '1em' }}>
        Explore more coaches in the marketplace
      </Typography>
      <Button
        size='small'
        onClick={() => {
          const qs = `?${querystring.encode({
            program_id: programId,
            program_type: programType || 'standard_coaching',
            keywords,
          })}`;
          navigate(`/coaches${qs}`);
        }}
        text='Explore coaches'
      />
    </>
  );
}

export { CoachesFollowUp };
