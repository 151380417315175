import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';

obey.modifier('forceString', val => (val ? val.toString() : ''));

obey.modifier('trim', val =>
  val && typeof val === 'string' ? val.trim() : val,
);

obey.creator('uuidv4', () => uuidv4());
obey.creator('now', () => Date.now());

export const UserSchema = {
  id: { type: 'string', required: true },
  cognito_user_name: { type: 'string' },
  cognito_id: { type: 'string' },
  email: { type: 'string', required: true, modifier: 'trim', empty: true },
  first_name: { type: 'string', required: true, modifier: 'trim', empty: true },
  last_name: { type: 'string', required: true, modifier: 'trim', empty: true },
  phone: { type: 'string' },
  location: { type: 'string' },
  status: {
    type: 'string',
    default: 'active',
    allow: ['active', 'pending', 'disabled'],
  },
  is_coach: { type: 'boolean', default: false },
  is_admin: { type: 'boolean', default: false },
  profile_image: { type: 'string' },
  biography: { type: 'string', empty: true },
  search_topics: { type: 'array', values: { type: 'string' }, empty: true },
  coaching_topics: { type: 'array', values: { type: 'string' }, empty: true },
  title: { type: 'string' },
  department: { type: 'string' },
  years_organization: { type: 'number' },
  years_experience: { type: 'number' },
  coaching_durations: {
    type: 'array',
    values: { type: 'number' },
    default: [30, 60],
  },
  calendly_id: { type: 'string' },
  calendly_username: { type: 'string' },
  saved_coaches: {
    type: 'array',
    values: { type: 'string' },
    empty: true,
    default: [],
  },
  addresses: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        type: { type: 'string', allow: ['billing', 'mailing', 'all'] },
        street_address_1: { type: 'string', empty: true },
        street_address_2: { type: 'string', empty: true },
        city: { type: 'string', empty: true },
        state: { type: 'string', empty: true },
        zip: {
          type: 'any',
          modifier: 'forceString',
          default: '',
          empty: true,
        },
        country: { type: 'string', default: 'United States' },
      },
    },
    empty: true,
    default: [],
  },
  social_media_links: {
    type: 'array',
    empty: true,
    default: [],
    values: {
      type: 'object',
      keys: {
        type: { type: 'string' },
        value: { type: 'string', empty: true },
      },
    },
  },
  testimonials: {
    type: 'array',
    empty: true,
    default: [],
    values: {
      type: 'object',
      keys: {
        name: { type: 'string' },
        company: { type: 'string', empty: true },
        title: { type: 'string', empty: true },
        review: { type: 'string' },
      },
    },
  },
  goal_cycles: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        id: { type: 'string', creator: 'uuidv4' },
        name: { type: 'string', required: true },
        start: { type: 'number', creator: 'now' },
        end: { type: 'number' },
        description: { type: 'string' },
        goal_weighting_enabled: { type: 'boolean', default: false },
      },
    },
    default: [],
    empty: true,
  },
  accepted_terms: { type: 'number' },
  last_platform_visit: { type: 'number' },
  documents: {
    type: 'array',
    values: { type: 'string' },
    empty: true,
    max: 5,
  },
  aida_share: {
    type: 'object',
    keys: {
      summary: {
        type: 'array',
        values: {
          type: 'object',
          keys: {
            user_id: { type: 'string' },
            role: { type: 'string' },
          },
        },
        empty: true,
      },
      focus_areas: {
        type: 'array',
        values: {
          type: 'object',
          keys: {
            user_id: { type: 'string' },
            role: { type: 'string' },
          },
        },
        empty: true,
      },
    },
  },
};

// eslint-disable-next-line no-unused-vars
export type UserType = { [K in keyof typeof UserSchema]?: any };

export const UserModel = obey.model(UserSchema);
