import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export function generateAidaResponse(filename) {
  const data = document.getElementById('aida-download-area');

  html2canvas(data, {
    useCORS: true,
    backgroundColor: null,
  }).then((c: any) => {
    const imgWidth = 210;
    const pageHeight = 297;
    const imgHeight = (c.height * imgWidth) / c.width;
    let heightLeft = imgHeight; // total height of html element scaled to pdf dimensions
    const margin = 10;

    const doc = new jsPDF('p', 'mm');
    doc.addImage(
      c,
      'PNG',
      margin,
      margin,
      imgWidth - margin * 2,
      imgHeight - margin * 2,
      '',
      'FAST',
    );

    heightLeft -= pageHeight - margin * 2;

    while (heightLeft >= 0) {
      doc.addPage();
      doc.addImage(
        c,
        'PNG',
        margin,
        heightLeft - imgHeight + margin,
        imgWidth - margin * 2,
        imgHeight - margin * 2,
        '',
        'FAST',
      );
      heightLeft -= pageHeight;
    }
    doc.save(filename);
  });
}
