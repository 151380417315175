import React from 'react';
import {
  Box,
  Typography,
  Stack,
  Divider,
  Link,
  Grid,
  Switch,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {
  Card,
  ConfirmDialog,
  useToast,
} from '@src/components/sc-design-system';
import Client from '@src/models/Client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import { useStore } from '@src/models/Store';
import { TermsAndConditions, AboutAida } from './AidaInfoModals';
import { ActiveClientKey } from '@src/hooks/useActiveClient';

const AidaCard: React.FunctionComponent<{ client: Client }> = ({ client }) => {
  const [openTAndC, setOpenTAndC] = React.useState(false);
  const [openAboutAida, setOpenAboutAida] = React.useState(false);
  return (
    <Card title='Aida integration' titleActions={null}>
      <Box
        sx={{
          backgroundColor: 'grey.50',
          padding: '2em',
        }}
      >
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item xs={11}>
            <Typography variant='body1sb'>Enable Aida</Typography>
            <Typography
              variant='body2'
              color='grey.700'
              sx={{ margin: '.5em 0' }}
            >
              Introducing SkillCycle’s new Adaptive Individual Development
              Advisor (Aida) to personalize and guide your employees’
              professional development and growth. Turning this on will initiate
              the process to accept the Terms & Conditions for AIDA.
            </Typography>
            <Stack
              direction='row'
              spacing={1}
              divider={<Divider orientation='vertical' flexItem />}
            >
              <Link variant='a2' onClick={() => setOpenAboutAida(true)}>
                Learn More
              </Link>
              <Link variant='a2' onClick={() => setOpenTAndC(true)}>
                Terms & Conditions
              </Link>
            </Stack>
          </Grid>
          <Grid item>
            <AidaSwitch setOpenTAndC={setOpenTAndC} client={client} />
          </Grid>
        </Grid>
        <TermsAndConditions open={openTAndC} setOpen={setOpenTAndC} />
        <AboutAida open={openAboutAida} setOpen={setOpenAboutAida} />
      </Box>
    </Card>
  );
};

interface AidaSwitchProps {
  setOpenTAndC: (b: boolean) => void;
  client: Client;
}

type OpenModal = 'enable' | 'disable' | false;

function AidaSwitch({ client, setOpenTAndC }: AidaSwitchProps) {
  const queryClient = useQueryClient();
  const toast = useToast();
  const [openModal, setOpenModal] = React.useState<OpenModal>(false);
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  React.useEffect(
    () => setTermsAccepted(!!client?.aida_terms_accepted),
    [setTermsAccepted, client],
  );

  const { mutate: setAidaTerms, isPending } = useMutation({
    mutationFn: async (
      aida_terms_accepted: Client['aida_terms_accepted'] | null,
    ) => {
      return api.clients.saveClient({
        id: client.id,
        aida_terms_accepted,
      });
    },
    onSuccess: ({ data: updatedClient }, variables) => {
      queryClient.setQueryData(
        ['client', client.id],
        new Client(updatedClient),
      );
      queryClient.invalidateQueries({
        queryKey: [ActiveClientKey.profile_active_client_id],
      });
      toast.success(
        `Aida has been successfully ${
          variables?.accepted_on ? 'enabled' : 'disabled'
        }`,
      );
    },
    onError: (error, variables) => {
      toast.error(
        `Unable to ${variables?.accepted_on ? 'enable' : 'disable'} Aida`,
      );
    },
  });
  return (
    <>
      <EnableAidaConfirmDialog
        openModal={openModal}
        setOpenModal={setOpenModal}
        setAidaTerms={setAidaTerms}
        isPending={isPending}
        termsAccepted={termsAccepted}
        setTermsAccepted={setTermsAccepted}
        setOpenTAndC={setOpenTAndC}
      />
      <DisableAidaConfirmDialog
        openModal={openModal}
        setOpenModal={setOpenModal}
        setAidaTerms={setAidaTerms}
        isPending={isPending}
      />
      <Switch
        onClick={() =>
          setOpenModal(client?.aida_terms_accepted ? 'disable' : 'enable')
        }
        checked={!!client?.aida_terms_accepted}
        disabled={isPending}
      />
    </>
  );
}

interface AidaConfirmProps {
  openModal: OpenModal;
  setOpenModal: (v: OpenModal) => void;
  setAidaTerms: Function;
  isPending: boolean;
}

interface EnableAidaConfirmDialogProps extends AidaConfirmProps {
  termsAccepted: boolean;
  setTermsAccepted: (b: boolean) => void;
  setOpenTAndC: (b: boolean) => void;
}

interface DisableAidaConfirmDialogProps extends AidaConfirmProps {}

function EnableAidaConfirmDialog(props: EnableAidaConfirmDialogProps) {
  const store = useStore();
  const {
    openModal,
    setOpenModal,
    setAidaTerms,
    isPending,
    termsAccepted,
    setTermsAccepted,
    setOpenTAndC,
  } = props;
  return (
    <ConfirmDialog
      open={openModal === 'enable'}
      setOpenModal={setOpenModal}
      buttonProps={{
        text: 'Enable',
        onClick: () =>
          setAidaTerms({
            accepted_on: Date.now(),
            admin_uuid: store.user.id,
          }),
        disabled: !termsAccepted || isPending,
        loading: isPending,
      }}
      title='Enable Aida'
      body={
        <>
          <Typography variant='body1' sx={{ marginBottom: '1em' }}>
            Enabling Aida will allow it to personalize and guide your employees
            professional development and growth.
          </Typography>
          <Stack direction='row' alignItems='center'>
            <FormControlLabel
              control={
                <Checkbox
                  size='small'
                  checked={termsAccepted}
                  onChange={e => setTermsAccepted(e.target.checked)}
                />
              }
              label={
                <Typography variant='body1m' component='span'>
                  I have read and agreed to the{' '}
                  <Link variant='a1' onClick={() => setOpenTAndC(true)}>
                    Terms & Conditions
                  </Link>
                  .
                </Typography>
              }
            />
          </Stack>
        </>
      }
    />
  );
}

function DisableAidaConfirmDialog(props: DisableAidaConfirmDialogProps) {
  const { openModal, setOpenModal, setAidaTerms } = props;
  return (
    <ConfirmDialog
      open={openModal === 'disable'}
      setOpenModal={setOpenModal}
      buttonProps={{
        text: 'Disable',
        onClick: () => setAidaTerms(null),
      }}
      title='Disable Aida'
      body='Employees will no longer be able to access their automated development plans once Aida is disabled. Previously saved versions will be stored by SkillCycle.'
    />
  );
}

export { AidaCard };
