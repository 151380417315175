import React from 'react';
import { Button } from '@src/components/sc-design-system';
import uhOhMonkey from '@shared/theme/src/assets/uh-oh-monkey.png';
import { Typography, Divider } from '@mui/material';

interface EBState {
  hasError?: boolean;
}

interface EBProps {
  addError: Function;
  children: React.ReactChild;
}

export default class ErrorBoundary extends React.Component<EBProps> {
  state: EBState = { hasError: false };
  children: React.ReactChild;
  addError: Function;
  constructor(props: EBProps) {
    super(props);
    this.addError = props?.addError;
    this.children = props?.children;
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.addError(error, errorInfo);
  }

  render() {
    return this.state.hasError ? (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: '300px', textAlign: 'center' }}>
          <img alt='Illustration of embarrassed monkey' src={uhOhMonkey} />
          <Typography variant='h1' sx={{ marginTop: '1em' }}>
            Oh no!
          </Typography>
          <Divider />
          <Typography variant='body1' color='grey.700'>
            Something went wrong. We&apos;ve let the team know and will fix it
            ASAP!
          </Typography>
          <Divider />
          <Button
            onClick={() => {
              window.location.href = `/?cache=${Date.now()}`;
            }}
            text='Reload'
          />
        </div>
      </div>
    ) : (
      this.children
    );
  }
}
