import React from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import {
  Divider,
  FormControlLabel,
  Link,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { Card, Button, useToast } from '@src/components/sc-design-system';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AddIcon from '@mui/icons-material/Add';
import { SetEvaluationPeriodModal } from '@src/components/views/MyBusiness/Performance/tabs/PerformanceCycles/PerformanceCycleLandingPage/modals/SetEvaluationPeriodModal';
import { useMutation } from '@tanstack/react-query';
import { api } from '@src/lib/client';

const EvaluationPeriod: React.FunctionComponent<{
  performanceCycle: PerformanceCycleI;
  refetchPerformanceCycle: Function;
  hasNotStartedOrIsAdmin: boolean;
}> = props => {
  const { performanceCycle, refetchPerformanceCycle, hasNotStartedOrIsAdmin } =
    props;
  const evaluationPeriodSet =
    performanceCycle.manager_evaluation_start &&
    performanceCycle.manager_evaluation_end &&
    performanceCycle.self_evaluation_start &&
    performanceCycle.self_evaluation_end;
  return !evaluationPeriodSet ? (
    <SetEvaluationPeriod
      performanceCycle={performanceCycle}
      refetchPerformanceCycle={refetchPerformanceCycle}
    />
  ) : (
    <ViewEvaluationPeriod
      performanceCycle={performanceCycle}
      refetchPerformanceCycle={refetchPerformanceCycle}
      hasNotStartedOrIsAdmin={hasNotStartedOrIsAdmin}
    />
  );
};

const SetEvaluationPeriod: React.FunctionComponent<{
  performanceCycle: PerformanceCycleI;
  refetchPerformanceCycle: Function;
}> = props => {
  const { performanceCycle, refetchPerformanceCycle } = props;
  const [openModal, setOpenModal] = React.useState(false);
  return (
    <Card
      variant='elevation'
      title='Set an evaluation period'
      titleIcon={<CalendarTodayIcon />}
      titleStyle='h2styleh4'
      sx={{ margin: '2em 0', padding: '.5em' }}
    >
      <Typography variant='body1' color='grey.700' sx={{ margin: '.5em 0' }}>
        Select dates to kickoff the evaluation period and make your evaluation
        experience available to both employees and their managers.
      </Typography>
      <SetEvaluationPeriodModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        performanceCycle={performanceCycle}
        refetchPerformanceCycle={refetchPerformanceCycle}
      />
      <Button
        sx={{ marginTop: '1em' }}
        size='small'
        variant='outlined'
        startIcon={<AddIcon />}
        text='Set dates'
        onClick={() => setOpenModal(true)}
      />
    </Card>
  );
};

const ViewEvaluationPeriod: React.FunctionComponent<{
  performanceCycle: PerformanceCycleI;
  refetchPerformanceCycle: Function;
  hasNotStartedOrIsAdmin: boolean;
}> = props => {
  const { performanceCycle, refetchPerformanceCycle, hasNotStartedOrIsAdmin } =
    props;
  const toast = useToast();
  const { id: clientId } = useParams();
  const [openModal, setOpenModal] = React.useState(false);

  const { mutate: updatePerformanceCycle, isPending } = useMutation({
    mutationFn: async (payload: Partial<PerformanceCycleI>) => {
      return api.clients.editPerformanceCycle(clientId, {
        ...payload,
        id: performanceCycle.id,
      });
    },
    onSuccess: () => {
      refetchPerformanceCycle();
    },
    onError: () => {
      toast.error('Unable to update performance cycle');
    },
  });

  const handleSwitchToggle = (checked: boolean) => {
    updatePerformanceCycle({
      release_evaluations: checked,
    });
  };

  return (
    <Card
      title='Evaluation period'
      titleStyle='h2styleh4'
      titleIcon={<CalendarTodayIcon />}
      titleActions={[
        hasNotStartedOrIsAdmin && (
          <Link
            key='edit-evaluation-period'
            variant='a1'
            onClick={() => setOpenModal(true)}
          >
            Edit
          </Link>
        ),
      ]}
    >
      <Typography variant='body1sb'>Self-evaluation period</Typography>
      <Typography variant='body1' sx={{ margin: '.5em 0 1.5em' }}>
        {dayjs(performanceCycle.self_evaluation_start).format('MMM DD, YYYY')} -{' '}
        {dayjs(performanceCycle.self_evaluation_end).format('MMM DD, YYYY')}
      </Typography>
      <Typography variant='body1sb'>Manager evaluation period</Typography>
      <Stack
        spacing={2}
        direction='row'
        alignItems='center'
        divider={<Divider orientation='vertical' flexItem />}
      >
        <Typography variant='body1'>
          {dayjs(performanceCycle.manager_evaluation_start).format(
            'MMM DD, YYYY',
          )}{' '}
          -{' '}
          {dayjs(performanceCycle.manager_evaluation_end).format(
            'MMM DD, YYYY',
          )}
        </Typography>
        <FormControlLabel
          control={
            <Switch
              disabled={isPending}
              checked={performanceCycle.release_evaluations}
              onChange={e => {
                handleSwitchToggle(e.target.checked);
              }}
            />
          }
          labelPlacement='start'
          label='Release evaluations to employee'
        />
      </Stack>
      <SetEvaluationPeriodModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        performanceCycle={performanceCycle}
        refetchPerformanceCycle={refetchPerformanceCycle}
        edit
      />
    </Card>
  );
};

export { EvaluationPeriod };
