import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Card } from '@src/components/sc-design-system';
import ResourceViewer from '@src/components/common/ResourceViewer';
import { ResourceTypeChip } from '@src/components/common/ResourceTypeChip';

function ResourcesFollowUp(props: { assistantMessage: AidaMessage }) {
  const { data } = props.assistantMessage;

  return (
    <>
      <Stack spacing={2}>
        {Array.isArray(data) &&
          data?.slice(0, 3).map((r, i) => (
            <ResourceViewer resource={r} key={`resource-${i}`} maxWidth={800}>
              <Card variant='elevation' elevation={5}>
                <ResourceTypeChip type={r.type} />
                <Typography variant='body1m' sx={{ margin: '1em 0 .5em' }}>
                  {r.name}
                </Typography>
                <Typography variant='body2' color='grey.700'>
                  {r.description}
                </Typography>
              </Card>
            </ResourceViewer>
          ))}
      </Stack>
    </>
  );
}

export { ResourcesFollowUp };
