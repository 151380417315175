import React from 'react';
import dayjs from 'dayjs';
import { Row, Col, Space } from 'antd';
import { Spin } from '@src/components/sc-design-system';
import { Message } from './components';
import { Typography, Divider } from '@mui/material';

function ReportHeader(props: ReportHeaderProps) {
  const {
    reportData,
    numberOfResponses,
    latestSubmissionDate,
    isLoadingGet360ReportData,
  } = props;

  interface HeaderRowProps {
    label: string;
    value: string | number;
    showRow?: Boolean;
  }

  function HeaderRow({ label, value, showRow }: HeaderRowProps) {
    const condition = typeof showRow === 'boolean' ? showRow : value;
    return condition ? (
      <Row>
        <Col style={{ width: '200px' }}>
          <Typography variant='body1m' sx={{ margin: '0 !important' }}>
            {label}
          </Typography>
        </Col>
        <Col>{value}</Col>
      </Row>
    ) : null;
  }

  return (
    <div className='report-header'>
      {isLoadingGet360ReportData === true && <Spin sectionLoader />}
      {!isLoadingGet360ReportData && reportData && (
        <>
          <Row justify='space-between'>
            <Col>
              <Row>
                <Col>
                  <Typography variant='h3' sx={{ marginBottom: '.5em' }}>
                    {reportData.first_name} {reportData.last_name}
                  </Typography>
                </Col>
              </Row>
              <HeaderRow label='Title' value={reportData?.title} />
              <HeaderRow label='Department' value={reportData?.department} />
              <HeaderRow
                label='Manager'
                value={`${reportData.manager_first_name} ${reportData.manager_last_name}`}
                showRow={!!reportData?.manager_first_name}
              />
              <HeaderRow
                label='360 Self'
                value={reportData?.self_survey_name}
              />
              <HeaderRow
                label='360 Stakeholder'
                value={reportData?.stakeholder_survey_name}
              />
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <Space style={{ padding: '8px 12px' }}>
                <img
                  height={50}
                  src={reportData.client_logo}
                  alt={`${reportData.client_name} logo`}
                />
                <span>{reportData.client_name}</span>
              </Space>
            </Col>
          </Row>
          {((reportData.stakeholder_survey_name && numberOfResponses) ||
            latestSubmissionDate) && (
            <>
              <Divider />
              <HeaderRow
                label='Number of Responses'
                value={numberOfResponses}
                showRow={!!reportData?.stakeholder_survey_name}
              />
              {latestSubmissionDate && (
                <Row>
                  <Col className='report-header-label'>
                    <Typography variant='body1m'>Last Updated</Typography>
                  </Col>
                  <Col>
                    {dayjs(latestSubmissionDate).format('dddd, MMMM Do, YYYY')}
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      )}
      {isLoadingGet360ReportData === 'error' && <Message type='error' />}
    </div>
  );
}

export default ReportHeader;
