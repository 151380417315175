import React from 'react';
import { GoalStatuses } from '@shared/schemas/src/goal';
import { Chip, ChipOwnProps } from '@mui/material';

type GoalStatusesIndicatorProps = {
  [key in GoalStatuses]: {
    color: ChipOwnProps['color'];
    label: string;
  };
};

const goalStatusesIndicatorProps: GoalStatusesIndicatorProps = {
  on_track: {
    color: 'success',
    label: 'On Track',
  },
  off_track: {
    color: 'warning',
    label: 'Off Track',
  },
  at_risk: {
    color: 'error',
    label: 'At Risk',
  },
  met: {
    color: 'success',
    label: 'Met',
  },
  partially_met: {
    color: 'warning',
    label: 'Partially Met',
  },
  not_met: {
    color: 'error',
    label: 'Not Met',
  },
  not_started: {
    color: 'default',
    label: 'Not Started',
  },
  frequently_exceeded_expectations: {
    color: 'success',
    label: 'Frequently Exceeded Expectations',
  },
  consistently_exceeded_expectations: {
    color: 'success',
    label: 'Consistently Exceeded Expectations',
  },
};

interface GoalStatusIndicatorProps {
  status: GoalStatuses;
}

export default function GoalStatusIndicator(props: GoalStatusIndicatorProps) {
  const { status = 'not_started' } = props;
  return <Chip {...goalStatusesIndicatorProps[status]} />;
}
