import './styles/global.less';
import './styles/global.css';
import './polyfills';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import axe from '@axe-core/react';
import { configure as configureMobx } from 'mobx';
import { App } from './components/app/App';
import Store from './models/Store';
import { createWorld } from './world';
import { consumeInviteTokens } from './session';
import { initDataDogTelemetry } from './lib/telemetry';

import dayjs from 'dayjs';
/* globally import dayjs plugins */
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

const main = async () => {
  configureMobx({ enforceActions: 'never' });

  const store = new Store();
  const world = createWorld(store);

  initDataDogTelemetry(store);
  consumeInviteTokens();
  initAccessibilityChecker();

  world.platform.setGlobalFlags();
  world.platform.loadConfiguration(process.env.NODE_ENV!);
  store.init();

  const target = document.getElementById('app');
  const root = ReactDOM.createRoot(target);
  root.render(<App store={store} world={world} />);
};

const initAccessibilityChecker = () => {
  if (!['development', 'uat'].includes(process.env.NODE_ENV)) {
    return;
  }

  console.debug('Axe running a11y checking...');
  axe(React, ReactDOM, 1000, {
    rules: [{ id: 'color-contrast', enabled: false }],
    allowedOrigins: ['<same_origin>'],
  });
};

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

main();
