import React, { FC, useState, useMemo, ReactChild } from 'react';
import {
  Stack,
  Dialog,
  DialogContent,
  DialogActions,
  Alert,
  AlertTitle,
  Tabs,
  Tab,
} from '@mui/material';
import { Button, DialogTitle } from '@src/components/sc-design-system';
import ProgramMembersList from '@src/components/common/ProgramMembersList';
import Program from '@src/models/Program';
import Session from '@src/models/Session';
import { observer } from 'mobx-react';
import FindAndAddMember from '@src/components/common/FindAndAddMember';
import { handleKeyboardNav } from '@src/utils';
import {
  TabPanelWrapper,
  generateTabProps,
} from '@src/components/common/TabPanelWrapper';

const ProgramMemberModal: FC<{
  program: Program;
  children: ReactChild;
  isAdmin?: boolean;
  sessions?: Session[];
  onUpdate?: Function;
}> = observer(({ program, children, isAdmin = false, sessions, onUpdate }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [lastLoad, setLastLoad] = useState(Date.now());
  const [loadList, setLoadList] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const tabs = useMemo(() => {
    return [
      { label: 'Current Members', ...generateTabProps(0) },
      { label: 'Add Members', ...generateTabProps(1) },
    ];
  }, []);
  const programMembers = program.members.map(member => member.id);
  // Still need to get nonMemberSessionsUsed when called from ProgramsList (get sessions and pass in)
  const nonMemberSessionsUsed = sessions?.filter(session =>
    session.members.every(member => !programMembers.includes(member.id)),
  );
  const sessionsAllocated = program.members.reduce((acc, member) => {
    return acc + member.sessions_allocated;
  }, 0);
  const sessionsAvailable =
    program.sessions_reserved -
    sessionsAllocated -
    (nonMemberSessionsUsed?.length ?? 0);
  return (
    <>
      <Dialog
        onClose={() => {
          onUpdate && onUpdate();
          setModalOpen(false);
          setLoadList(false);
        }}
        open={modalOpen}
      >
        <DialogTitle
          title={program.name}
          buttonOnClick={() => setModalOpen(false)}
        />
        <DialogContent>
          <Tabs
            onChange={(_, v) => {
              setLastLoad(Date.now()); // TODO: remove this when react-query is implemented
              setActiveTab(v);
            }}
            value={activeTab}
            sx={{ marginBottom: '2em' }}
          >
            {tabs.map(tab => (
              <Tab key={tab.label} label={tab.label} />
            ))}
          </Tabs>
          <TabPanelWrapper value={activeTab} index={0}>
            <ProgramMembersList
              program={program}
              lastLoad={lastLoad}
              loadList={loadList}
            />
          </TabPanelWrapper>
          <TabPanelWrapper value={activeTab} index={1}>
            <Stack>
              {program.type !== 'platform_only' && (
                <>
                  {sessionsAvailable < program.sessions_per_member &&
                    sessionsAvailable > 0 && (
                      <Alert severity='warning'>
                        <AlertTitle>
                          {`There ${
                            sessionsAvailable === 1 ? 'is' : 'are'
                          } only ${sessionsAvailable} available session${
                            sessionsAvailable === 1 ? '' : 's'
                          } remaining. Users added will have limited sessions.`}
                        </AlertTitle>
                      </Alert>
                    )}
                  {sessionsAvailable <= 0 && (
                    <Alert severity='warning'>
                      <AlertTitle>
                        There are no available sessions remaining. Users added
                        will have no sessions allocated.
                      </AlertTitle>
                    </Alert>
                  )}
                </>
              )}
              <FindAndAddMember
                program={program}
                isAdmin={isAdmin}
                setLoadList={setLoadList}
              />
            </Stack>
          </TabPanelWrapper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onUpdate && onUpdate();
              setModalOpen(false);
              setLoadList(false);
            }}
            text='OK'
            formatText={false}
          />
        </DialogActions>
      </Dialog>
      <span
        role='button'
        tabIndex={0}
        className='modal-open-click-target'
        onClick={() => setModalOpen(true)}
        onKeyDown={e => handleKeyboardNav(e.key, () => setModalOpen(true))}
      >
        {children}
      </span>
    </>
  );
});

export default ProgramMemberModal;
