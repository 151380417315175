import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';

obey.creator('uuidv4', () => uuidv4());
obey.creator('now', () => Date.now());

// use this for the dropdown field
export const goalStatuses = [
  // Goal Status
  'not_started',
  'on_track',
  'off_track',
  'at_risk',
  // Goal Attainment
  'not_met',
  'partially_met',
  'met',
  'frequently_exceeded_expectations',
  'consistently_exceeded_expectations',
] as const;

const goalStatusTypeDelimiter = goalStatuses.indexOf('not_met');

export const goalStatusUpdateOptions = goalStatuses.slice(
  0,
  goalStatusTypeDelimiter,
);
export const goalAttainmentUpdateOptions = goalStatuses.slice(
  goalStatusTypeDelimiter,
);

// use this for interfaces
export type GoalStatuses = typeof goalStatuses[number];

export const GoalSchema = {
  id: { type: 'string', required: true, creator: 'uuidv4' },
  user_ids: { type: 'array', values: { type: 'string' }, empty: true },
  client_id: { type: 'string', allowNull: true },
  goal_cycle_id: { type: 'string' },
  aligned_goal_id: { type: 'string', allowNull: true },
  title: { type: 'string' },
  description: { type: 'string' },
  target_date: { type: 'number' },
  type: {
    type: 'string',
    allow: ['personal', 'professional_development', 'business'],
  },
  program_ids: {
    type: 'array',
    empty: true,
    values: {
      type: 'string',
    },
  },
  status_history: {
    type: 'array',
    empty: true,
    values: {
      type: 'object',
      keys: {
        id: { type: 'string', creator: 'uuid' },
        notes: { type: 'string' },
        status: { type: 'string', allow: goalStatuses, required: true },
        createdAt: { type: 'number', creator: 'now' },
        creator_id: { type: 'string', required: true },
        creator_first_name: { type: 'string', required: true },
        creator_last_name: { type: 'string', required: true },
      },
    },
  },
  weight: { type: 'number', default: 0 },
};

// eslint-disable-next-line no-unused-vars
export type GoalType = { [K in keyof typeof GoalSchema]?: any };

export const GoalModel = obey.model(GoalSchema);
