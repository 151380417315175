import { HTTPClient } from '@src/lib/client';

export class QuestionsAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async get() {
    return this.http.request({
      url: `/questions/list`,
      method: 'get',
    });
  }

  async save(data) {
    return this.http.request({
      url: `/questions/${data.id ? data.id : ''}`,
      method: data.id ? 'patch' : 'post',
      data,
    });
  }

  async submitAnswer(id: string, answer: any) {
    return this.http.request({
      url: `/questions/answer/${id}`,
      method: 'post',
      data: { answer },
    });
  }

  async submitSurvey(id: string, answers: any) {
    return this.http.request({
      url: `/questions/survey/${id}`,
      method: 'post',
      data: { answers },
    });
  }

  async getUserAnswers(id: string) {
    return this.http.request({
      url: `/questions/answers/${id}`,
      method: 'get',
    });
  }
}
