import React, { FC } from 'react';
import SurveyQuestion from '@src/models/SurveyQuestion';
import { observer } from 'mobx-react';
import { List, Space, Row } from 'antd';
import { QuestionText, QuestionTitle } from './components';
import { Typography } from '@mui/material';

const SQCompetency: FC<{
  surveyQuestion: SurveyQuestion;
  isSurveyEditable: boolean;
}> = ({ surveyQuestion, isSurveyEditable }) => {
  return (
    <div className='sq-editor-competency'>
      <label className={`question-type-label ${surveyQuestion.type}`}>
        {surveyQuestion.type}
      </label>
      <QuestionTitle surveyQuestion={surveyQuestion} readOnly={true} />
      <QuestionText
        surveyQuestion={surveyQuestion}
        propName='helpText'
        isSurveyEditable={isSurveyEditable}
      />
      {surveyQuestion.group?.questions?.map(q => {
        return (
          <>
            <Typography variant='body1' sx={{ margin: '.5em 0 1em' }}>
              {q.title}
            </Typography>
            {q.type === 'range' && (
              <List className={`survey-select-options ${q.type}`}>
                <Row align='bottom'>
                  {new Array(q.range_maximum || 10).fill('').map((_, idx) => {
                    return (
                      <>
                        <Space direction='vertical' align='center'>
                          <List.Item key={idx} className='selection-box '>
                            {q.range_labels?.[idx]?.text || idx + 1}
                          </List.Item>
                        </Space>
                      </>
                    );
                  })}
                </Row>
              </List>
            )}
          </>
        );
      })}
    </div>
  );
};

export default observer(SQCompetency);
