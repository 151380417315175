import React, { FC, useEffect, useState, useMemo } from 'react';
import { Row, Col, Space } from 'antd';
import { Button, Card, useToast, Spin } from '@src/components/sc-design-system';
import CalendlyScheduler from '@src/components/common/CalendlyScheduler';
import { api } from '@src/lib/client';
import User from '@src/models/User';
import Program from '@src/models/Program';
import CoachInfo from './CoachInfo';
import SaveOrUnsaveCoach from '@src/components/common/SaveOrUnsaveCoach';
import SelectCoach from '@src/components/common/SelectCoach';
import './CoachView.less';
import Store from '@src/models/Store';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import * as useVerbiage from '@shared/lib/src/use-verbiage';
import { useQueryParams } from '@src/utils';
import { Link, Stack, Typography, CardMedia, Divider } from '@mui/material';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useUser } from '@src/hooks/useUser';
import { useUserQueries } from '@src/hooks/useUserQueries';

type MeetingLength = 'short' | 'long';

const getCalendlyUrl = async (
  coachId: string,
  meetingLength: MeetingLength,
): Promise<string> => {
  const url = await api.integrations.getCalendlySchedulingLink(
    coachId,
    meetingLength,
  );
  return url.data;
};

const getNewSessionData = (user: User, coachId: string, program: Program) => {
  const sessionData = {
    program_id: program.id,
    client_id: program.client_id,
    sow_id: program.sow_id,
    type: program?.coach?.id === coachId ? 'coaching' : 'interview',
    session_activity: [
      {
        timestamp: Date.now(),
        user_id: user.id,
        type: 'requested',
      },
    ],
    members: [
      {
        id: user.id,
        role: 'coachee',
        joinedAt: Date.now(),
      },
      {
        id: coachId,
        role: 'coach',
        joinedAt: Date.now(),
      },
    ],
  };
  return sessionData;
};

const CoachView: FC<{
  viewCoach: any;
  store: Store;
  isBooking?: boolean;
  bookingProgram?: Program;
  lastRerender?: number;
}> = ({
  viewCoach,
  store,
  isBooking = false,
  bookingProgram,
  lastRerender,
}) => {
  const toast = useToast();
  const { user } = useUser();
  const { useGetUserPrograms } = useUserQueries(user.id);
  const { userPrograms } = useGetUserPrograms('coachee');
  const query = useQueryParams();
  const programId = query.get('program_id');
  const [viewState, setViewState] = useState('coach_info');
  const [calendlyUrl, setCalendlyUrl] = useState<string>();
  const [session, setSession] = useState<any>();

  const currentBookingProgram = useMemo(() => {
    return (
      bookingProgram ||
      userPrograms?.find(
        p =>
          (programId && p.id === programId) ||
          (!programId && !p.coach && p.is_active),
      )
    );
  }, [bookingProgram, programId, userPrograms]);

  const intercom = useIntercom();
  const v = useVerbiage.init(
    currentBookingProgram?.type === 'mentoring' ? ['isMentor'] : [],
  );

  const initBooking = async () => {
    setViewState('preparing_booking');
    try {
      if (!currentBookingProgram) {
        setViewState('coach_info');
        toast.warn('No programs currently available');
        return;
      }
      const newSession = getNewSessionData(
        user,
        viewCoach.id,
        currentBookingProgram,
      );
      setSession(newSession);
      const allowedDuration =
        newSession.type === 'interview'
          ? 'short'
          : !currentBookingProgram?.allowed_durations
          ? 'short'
          : currentBookingProgram?.allowed_durations[0] === 60 // Program can only have 1 duration
          ? 'long'
          : 'short';
      setCalendlyUrl(await getCalendlyUrl(viewCoach.id, allowedDuration));
      setViewState('show_calendly_selector');
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(
    () => {
      setViewState('coach_info'); // Reset to init view on coach context change
      if (isBooking) {
        setSession(undefined);
        initBooking();
      }
    },
    // FIXME: invalid dependency array
    // eslint-disable-next-line
    [viewCoach.id, lastRerender],
  );

  const navigate = useNavigate();

  const hasSelectedOrInterviewed = ['selected', 'interviewed'].includes(
    viewCoach.association,
  );

  const avatarImgSrc = process.env.ASSETS_ROOT
    ? `${process.env.ASSETS_ROOT}/${viewCoach.id}/avatar.png`
    : viewCoach.profile_image;

  return (
    <div className='coach-view'>
      <Row gutter={30}>
        <Col md={6} lg={9}>
          <Card>
            <CardMedia
              sx={{ height: '400px' }}
              title={`${viewCoach.first_name} ${viewCoach.last_name}`}
              image={avatarImgSrc}
            />
            <div style={{ marginTop: '.5em' }}>
              {userPrograms?.length === 0 ||
              currentBookingProgram?.sessions_allocated === 0 ? (
                <div className='not-in-program'>
                  You must be enrolled in a program to select or interview a{' '}
                  {v('coach')}. <Divider /> For assistance, contact{' '}
                  <Link
                    component='button'
                    variant='a1'
                    onClick={() => intercom.show()}
                  >
                    {process.env.SUPPORT_TEAM_EMAIL}
                  </Link>
                </div>
              ) : user.id !== viewCoach.id ? (
                <Stack spacing={2} textAlign='center' justifyContent='center'>
                  {!hasSelectedOrInterviewed && (
                    <div>
                      <SaveOrUnsaveCoach
                        user={user}
                        coach={viewCoach}
                        onSaved={() => setViewState('coach_saved')}
                        programType={currentBookingProgram?.type}
                      />
                    </div>
                  )}
                  <Stack direction='row' spacing={1} justifyContent='center'>
                    {currentBookingProgram &&
                      !['selected', 'interviewed'].includes(
                        viewCoach.association,
                      ) && (
                        <Button
                          size='small'
                          startIcon={<CalendarTodayOutlined />}
                          onClick={initBooking}
                          disabled={[
                            'preparing_booking',
                            'show_calendly_selector',
                          ].includes(viewState)}
                          text='Interview'
                          variant='outlined'
                        />
                      )}
                    {currentBookingProgram &&
                      viewCoach.association !== 'selected' && (
                        <SelectCoach
                          user={user}
                          coach={viewCoach}
                          programType={currentBookingProgram?.type}
                          programId={programId}
                        />
                      )}
                  </Stack>
                </Stack>
              ) : null}
            </div>
          </Card>
        </Col>
        <Col md={18} lg={15}>
          {viewState === 'coach_info' && <CoachInfo viewCoach={viewCoach} />}
          {viewState === 'preparing_booking' && <Spin sectionLoader />}
          {viewState === 'show_calendly_selector' && (
            <CalendlyScheduler
              store={store}
              calendlyUrl={calendlyUrl}
              programType={currentBookingProgram.type}
              programName={currentBookingProgram.name}
              session={session}
              onBookingComplete={async () => {
                store.user.saved_coaches = [
                  ...new Set([...store.user.saved_coaches, viewCoach.id]),
                ];
                await store.user.save(false);
              }}
            />
          )}
          {viewState === 'coach_saved' && (
            <div style={{ textAlign: 'center', paddingTop: '2em' }}>
              <TaskAltIcon sx={{ fontSize: '100px', marginBottom: '.3em' }} />
              <Typography
                variant='h2'
                component='h3'
                sx={{ marginBottom: '1em' }}
              >
                You have saved{' '}
                <strong>
                  {viewCoach.first_name} {viewCoach.last_name}
                </strong>
                <br />
                to your list of {v('coaches')}
              </Typography>
              <Typography
                variant='body2'
                sx={{ width: '75%', display: 'inline-block' }}
              >
                You can schedule an interview with {viewCoach.first_name} now by
                clicking the Schedule Interview button or schedule from your
                dashboard at any time.
              </Typography>
              <Divider />
              <Space>
                <Button
                  onClick={() => {
                    navigate('/my-programs');
                  }}
                  startIcon={<HomeOutlined />}
                  text='Go to Dashboard'
                />
                <Button
                  startIcon={<CancelOutlined />}
                  onClick={() => setViewState('coach_info')}
                  text='Close Message'
                />
              </Space>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default observer(CoachView);
