export function isDuplicateEmail(allMembers, invitees) {
  return function (email) {
    const registeredEmails = allMembers.map(m => m.email.toLowerCase());
    const invitedEmails = (invitees || []).map(m =>
      m.invitee_email.toLowerCase(),
    );
    const allEmails = [...invitedEmails, ...registeredEmails];
    return allEmails.includes(email);
  };
}
