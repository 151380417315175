import React, { FC } from 'react';
import { Typography, Stack } from '@mui/material';
import { CompetencySet } from '@src/models/CompetencySet';
import { CompetencySetCard } from '@src/components/common/CompetencySetCard';
import { Card } from '@src/components/sc-design-system';

const Competencies: FC<{
  sets: CompetencySet[];
  clientName: string;
}> = ({ sets, clientName }) => (
  <Stack spacing={4}>
    <Typography variant='h2' sx={{ marginBottom: '.5em' }}>
      {clientName} Values & Competencies
    </Typography>

    {sets.map((competencySet: CompetencySet, idx) => (
      <Card
        className='my-profile-competency-set-card'
        key={competencySet.id}
        collapsible
        defaultOpen={idx === 0}
        title={competencySet.name}
        subheader={competencySet.description}
      >
        <CompetencySetCard set={competencySet} />
      </Card>
    ))}
  </Stack>
);

export { Competencies };
