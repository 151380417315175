import React, { FC } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { Spin, DialogTitle } from '@src/components/sc-design-system';
import StakeholderManager from '@src/components/views/Coachees/Program/Dashboards/StakeholderManager/StakeholderManager';
import { useQuery } from '@tanstack/react-query';
import { api } from '@src/lib/client';

export const StakeholderManagerModal: FC<{
  open: boolean;
  programId: string;
  memberId: string;
  isActive: boolean;
  onCancel: Function;
  isEditable?: boolean;
}> = ({ open, programId, memberId, isActive, onCancel, isEditable = true }) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['program', programId, 'member', memberId, 'stakeholders'],
    queryFn: async () =>
      api.programs.get360Stakeholders({ programId, memberId }),
    select: ({ data }) => data,
    enabled: open,
  });

  return (
    <Dialog maxWidth='md' open={open} onClose={() => onCancel()}>
      <DialogTitle title='360 Feedback' buttonOnClick={() => onCancel()} />
      <DialogContent>
        {isLoading ? (
          <Spin sectionLoader />
        ) : (
          <div style={{ padding: '1em' }}>
            <StakeholderManager
              programId={programId}
              memberId={memberId}
              assessmentStakeholders={data?.stakeholders ?? []}
              updateLocalRecord={refetch}
              isActive={isActive}
              isEditable={isEditable}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
