import { makeAutoObservable, observable, action, computed, toJS } from 'mobx';
import jwt from 'jwt-decode';
import { api } from '@src/lib/client';
import Address from './Address';
import { message } from 'antd';
import { user as UserSchema } from '@shared/schemas';
import { deprecatedGlobalUserSession } from '@src/session';
import { GoalCycle } from './GoalCycle';
import { SessionGetResponse } from '@src/lib/client/apis/sessions';

import { ActiveClientKey } from '@src/hooks/useActiveClient';

interface IFSignupData {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
}

// Defines props passed in API call reqs and res'
const dataProps = [
  'id',
  'cognito_user_name',
  'cognito_id',
  'first_name',
  'last_name',
  'email',
  'status',
  'is_admin',
  'is_coach',
  'profile_image',
  'biography',
  'search_topics',
  'coaching_topics',
  'title',
  'department',
  'years_organization',
  'years_experience',
  'saved_coaches',
  'calendly_id',
  'calendly_username',
  'addresses',
  'social_media_links',
  'testimonials',
  'coaching_durations',
  'accepted_terms',
  'goal_cycles',
  'documents',
  'aida_share',
];

interface SocialMediaLink {
  type: string;
  value: string;
}

export class Testimonial {
  name: string = '';
  company: string = '';
  title: string = '';
  review: string = '';

  constructor(testimonial = {}) {
    makeAutoObservable(this, {
      isValid: computed,
    });

    Object.keys(testimonial).forEach(prop => {
      this[prop] = testimonial[prop];
    });
  }
  get isValid() {
    return Boolean(
      this.name?.length > 0 &&
        this.title?.length > 0 &&
        this.review?.length > 0,
    );
  }
  get data() {
    return JSON.parse(
      JSON.stringify({
        name: this.name,
        company: this.company,
        title: this.title,
        review: this.review,
      }),
    );
  }
}

export default class User {
  _id?: string; // not used
  createdAt?: number;
  updatedAt?: number;
  id?: string;
  cognito_user_name?: string;
  clients?: any[] = [];
  programs?: any[] = [];
  cognito_id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  status: string;
  mentor_programs?: [
    { program_id: string; program_name: string; client_name: string },
  ];
  is_coach?: boolean = false;
  is_admin?: boolean = false;
  biography?: string;
  search_topics?: string[] = [];
  title?: string;
  department?: string;
  years_organization?: number;
  years_experience?: number;
  coaching_topics?: string[] = [];
  coaching_durations?: number[] = [30, 60];
  saved_coaches?: string[] = [];
  session?: any;
  calendly_id?: string;
  calendly_username?: string;
  addresses: Address[] = [
    new Address({ type: 'mailing' }),
    new Address({ type: 'billing' }),
  ];
  social_media_links?: SocialMediaLink[] = [];
  testimonials?: Testimonial[];
  goal_cycles: GoalCycle[] = [];

  coachee_interviews?: any[] = [];
  coaching_interviews?: any[] = [];
  saved_and_selected_coaches?: any[] = [];

  coachingSessionsByProgram?: any[] = [];
  selected_coach;
  selected_coach_at?: number;
  profileImageUrl?: string;
  hasProfileImage = true;
  accepted_terms?: number;
  avatarCacheTime: number = Date.now();
  last_platform_visit: number;
  active_session: SessionGetResponse;

  self_assessment_submitted?: boolean;
  assessment_stakeholders?: any[];
  documents?: string[] = [];
  aida_share?: AidaShare;

  constructor(user: UserSchema.UserType = {}) {
    makeAutoObservable(this, {
      // Other
      session: observable,
      last_platform_visit: observable,
      mentor_programs: observable,
      goal_cycles: observable,
      // Actions
      forgotPassword: action,
      signIn: action,
      signUp: action,
      confirmSignUp: action,
      resendCode: action,
      signOut: action,
      getSession: action,
      getProfile: action,
      setStatus: action,
      setRole: action,
      save: action,
      setProfileImageUrl: action,
      checkHasProfileImage: action,
      updateProfileImage: action,
      // Computeds
      role: computed,
      data: computed,
      homeRoute: computed,
      isClientAdmin: computed,
    });
    // Setup passed in props
    this.mergeData(user);
  }

  mergeData = (user: UserSchema.UserType) => {
    Object.keys(user).forEach(prop => {
      if (!(prop in this)) {
        console.warn(`Missing user property observable for ${prop}`);
      } else if (prop === 'addresses') {
        this[prop] = (user[prop] || []).map(address => new Address(address));
      } else if (prop === 'testimonials') {
        this[prop] = (user[prop] || []).map(t => new Testimonial(t));
      } else if (prop === 'goal_cycles') {
        this[prop] = (user[prop].length ? user[prop] : this[prop]).map(
          g => new GoalCycle(g),
        );
      } else {
        this[prop] = user[prop];
      }
    });
    this.setProfileImageUrl();
  };

  get _session() {
    return deprecatedGlobalUserSession();
  }

  // Computed
  // ~~~~~~~~~~~~~~~~~~~

  get role() {
    return this.is_admin
      ? 'admin'
      : this.is_coach
      ? 'coach'
      : this.isMentor
      ? 'mentor'
      : 'coachee';
  }

  get isCoachOrMentor() {
    return this.is_coach || this.isMentor;
  }

  get isMentor() {
    return this.mentor_programs && this.mentor_programs.length > 0;
  }

  get isClientAdmin() {
    if (this.is_admin) return false;
    return (
      Boolean(this.clients?.length) &&
      this.clients?.find(({ id }) => id === this.activeClientId)?.role ===
        'manager'
    );
  }

  get activeClientId() {
    return (
      localStorage.getItem(ActiveClientKey.profile_active_client_id) ||
      this.clients?.[0]?.id
    );
  }

  get homeRoute() {
    if (this.is_admin) {
      return '/administration';
    } else if (this.isClientAdmin) {
      return `/my-business/${this.activeClientId}`;
    } else if (this.is_coach) {
      return '/my-coachees';
    } else if (this.isMentor) {
      return '/my-mentees';
    } else {
      return '/my-programs';
    }
  }

  // Actions
  // ~~~~~~~~~~~~~~~~~~~

  signIn = async (email: string, password: string) => {
    try {
      await this._session.signIn(email.trim().toLowerCase(), password);
      await this.getSession();
      await api.users.updateLastPlatformVisit(this.id);
    } catch (e) {
      console.error('Could not login');
      throw new Error('Could not login with email and password provided');
    }
  };

  signUp = async (data: IFSignupData) => {
    // Call to Cognito to create account
    const res = await this._session.signUp({
      email: data.email.trim().toLowerCase(),
      password: data.password,
      firstName: data.first_name,
      lastName: data.last_name,
    });
    // Store props in case of reload, ensure user can continue
    // after confirmation code entry
    localStorage.setItem(
      'gc-temp-signup',
      JSON.stringify(Object.assign({}, data, { cognito_id: res.userSub })),
    );
    return true;
  };

  get hasTempSignup() {
    try {
      return JSON.parse(localStorage.getItem('gc-temp-signup'));
    } catch (e) {
      return false;
    }
  }

  get data() {
    return JSON.parse(
      JSON.stringify(
        toJS(
          dataProps.reduce((acc: object, prop: string) => {
            if (prop === 'addresses') {
              acc[prop] = this[prop] ? this[prop].map(a => a.data) : [];
            } else if (prop === 'testimonials') {
              acc[prop] = this[prop] ? this[prop].map(t => t.data) : [];
            } else if (prop === 'goal_cycles') {
              acc[prop] = this[prop] ? this[prop].map(gc => gc.data) : [];
            } else {
              acc[prop] = this[prop];
            }
            return acc;
          }, {}),
        ),
      ),
    );
  }

  get hasTempPasswordRecovery() {
    try {
      return JSON.parse(localStorage.getItem('gc-temp-password-recovery'));
    } catch (e) {
      return false;
    }
  }

  getInterviews = async (role: 'coach' | 'coachee') => {
    const actorType =
      role === 'coachee' ? 'coachee_interviews' : 'coaching_interviews';
    try {
      const interviews = await api.users.getInterviews(this.id, role);
      this[actorType] = interviews.data;
    } catch (e) {
      message.error('Could not load your interviews at this time');
    }
  };

  get savedAndInterviewCoachList() {
    const saved = this.saved_and_selected_coaches || [];
    return [
      ...saved.map(s =>
        Object.assign({}, s, {
          interview: {
            status: false,
            start: 1,
          },
        }),
      ),
    ];
  }

  confirmSignUp = async (code: string): Promise<void> => {
    // Get stored signup data
    const storedTempSignupData = JSON.parse(
      localStorage.getItem('gc-temp-signup'),
    );
    const storedClientToken = localStorage.getItem('client-invite-token');
    const storedProgramToken = localStorage.getItem('program-invite-token');
    const coach = localStorage.getItem('coach');
    try {
      // Confirm on cognito
      await this._session.confirmSignUp(
        storedTempSignupData.email.trim().toLowerCase(),
        code,
      );
      // Add to our database
      await api.users.create(
        {
          id: storedTempSignupData.cognito_id,
          cognito_user_name: storedTempSignupData.email.trim().toLowerCase(),
          cognito_id: storedTempSignupData.cognito_id,
          email: storedTempSignupData.email.trim().toLowerCase(),
          first_name: storedTempSignupData.first_name.trim(),
          last_name: storedTempSignupData.last_name.trim(),
          accepted_terms: Date.now(),
        },
        coach === 'true' ? true : false,
        storedClientToken ? atob(storedClientToken) : 'false',
        storedProgramToken ? atob(storedProgramToken) : 'false',
      );
      // Process sign-in and redirect
      await this.signIn(
        storedTempSignupData.email,
        storedTempSignupData.password,
      );
      localStorage.removeItem('gc-temp-signup');
      window.location.href = '/';
    } catch (e) {
      throw new Error(
        'Could not create account, Please check verification code',
      );
    }
  };

  resendCode = async (email?: string): Promise<void> => {
    const gcTempSignupData = this.hasTempSignup;
    if (!email && !gcTempSignupData) throw new Error('No account found');
    await this._session.resendCode(gcTempSignupData.email);
  };

  forgotPassword = async (email: string): Promise<void> => {
    await this._session.forgotPassword(email);
    localStorage.setItem(
      'gc-temp-password-recovery',
      JSON.stringify({ email }),
    );
  };

  forgotPasswordSubmit = async (
    code: string,
    password: string,
  ): Promise<boolean> => {
    const gcTempPasswordRecoveryData = this.hasTempPasswordRecovery;
    await this._session.forgotPasswordSubmit(
      gcTempPasswordRecoveryData.email.trim().toLowerCase(),
      code,
      password,
    );
    localStorage.removeItem('gc-temp-password-recovery');
    return true;
  };

  signOut = async (): Promise<void> => {
    await this._session.signOut();
    this.session = false;
    localStorage.clear();
    if (location.pathname !== '/login') {
      window.location.href = '/';
    }
  };

  checkInviteTokens = async () => {
    const clientInviteToken = window.localStorage.getItem(
      'client-invite-token',
    );
    const programInviteToken = window.localStorage.getItem(
      'program-invite-token',
    );
    const email = window.localStorage.getItem('email');
    try {
      if (clientInviteToken)
        await api.clients.addClientMember({
          memberId: this.session.decodedJWT.sub,
          clientId: atob(clientInviteToken),
          email: email,
        });
      if (programInviteToken)
        await api.programs.addProgramMembers({
          memberId: this.session.decodedJWT.sub,
          programId: atob(programInviteToken),
          email: email,
        });
      window.localStorage.removeItem('client-invite-token');
      window.localStorage.removeItem('program-invite-token');
    } catch (e) {
      /* no-op, don't block session */
    }
  };

  getSession = async (): Promise<object | boolean> => {
    try {
      const amplifySession = await this._session.getSessionInfo();
      this.session = Object.assign({}, amplifySession, {
        // @ts-expect-error
        decodedJWT: jwt(amplifySession ? amplifySession.jwt : {}),
      });
      await this.getProfile();
      return this.session;
    } catch (e) {
      return false;
    }
  };

  getProfile = async (): Promise<void> => {
    try {
      if (!this.session) return;
      await this.checkInviteTokens();
      const profile = await api.users.get(this.session.decodedJWT.sub);
      if (profile.data.status === 'disabled') this.signOut();
      // Loop over all keys and set
      this.mergeData(profile.data);
      if (this.saved_and_selected_coaches) this.getSavedAndSelectedCoaches();
    } catch (e) {
      await this.signOut();
      throw new Error('Could not find valid account');
    }
  };

  getSavedAndSelectedCoaches = async (): Promise<any> => {
    try {
      const res = await api.users.getUserSavedAndSelectedCoaches(this.id);
      this.saved_and_selected_coaches = res.data;
    } catch (e) {
      /* no-op */
    }
  };

  cleanupArraysOfObjects = () => {
    [{ prop: 'social_media_links', check: ['type', 'value'] }].forEach(item => {
      this[item.prop] = (this[item.prop] || []).filter(
        d =>
          item.check.filter(p => d[p] && d[p].length > 0).length ===
          item.check.length,
      );
    });
  };

  selectProgramCoach = async (coach_id: string, program_id: string) => {
    await api.programs.selectProgramCoach(this.id, coach_id, program_id);
    await this.getSavedAndSelectedCoaches();
  };

  save = async (reloadProfile: boolean = true): Promise<void> => {
    try {
      this.cleanupArraysOfObjects();
      await api.users.update(this.data);
      reloadProfile && (await this.getProfile());
    } catch (e) {
      throw new Error('Could not update');
    }
  };

  // Admin status change
  setStatus = async (newStatus: string): Promise<void> => {
    const initStatus = this.data.status;
    try {
      this.status = newStatus;
      await this.save();
    } catch (e) {
      this.status = initStatus;
      message.error('Could not change user status');
    }
  };

  setRole = async (roleName: string, value: boolean): Promise<void> => {
    try {
      this[roleName] = value;
      if (roleName === 'is_coach' && value) this.status = 'pending';
      await this.save();
    } catch (e) {
      this[roleName] = !value;
      message.error('Failed to change the user role');
    }
  };

  setProfileImageUrl = (updatedAt = Date.now()): void => {
    if (!process.env.ASSETS_ROOT) return;

    this.profileImageUrl = `${process.env.ASSETS_ROOT}/${this.id}/avatar.png?cache=${updatedAt}`;
    this.checkHasProfileImage();
  };

  checkHasProfileImage = async () => {
    if (!this.id) return;
    try {
      const res = await fetch(this.profileImageUrl, { method: 'HEAD' });
      this.hasProfileImage = res.status === 200;
    } catch (e) {
      this.hasProfileImage = false;
    }
  };

  updateProfileImage = () => {
    this.avatarCacheTime = Date.now();
    this.checkHasProfileImage();
  };

  updateActiveSession = function (session) {
    this.active_session = session;
  };
}
