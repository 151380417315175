import { HTTPClient } from '@src/lib/client';

export interface SOWGetParams {
  queryBy?: 'client';
  queryId?: string;
  client?: string;
  page?: number;
  limit?: number;
}

export class SOWsAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async getSOWById(id: string) {
    return this.http.request({
      url: `/sows/${id}`,
      method: 'get',
    });
  }

  async get(params: SOWGetParams) {
    return this.http.request({
      url: `/sows/list?${Object.keys(params)
        .map(key =>
          params[key] && params[key] === 'queryBy'
            ? params[key] + '=' + encodeURIComponent(params.queryId)
            : params[key]
            ? key + '=' + encodeURIComponent(params[key])
            : undefined,
        )
        .filter(n => n)
        .join('&')}`,
      method: 'get',
    });
  }

  async saveSOW(data: SOWEditorValues) {
    return this.http.request({
      url: `/sows/${data.id && data.id.length > 0 ? data.id : 'create'}`,
      method: data.id && data.id.length > 0 ? 'patch' : 'post',
      data,
    });
  }
}
