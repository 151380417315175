import React from 'react';
import { Chip } from '@mui/material';

interface EvaluationChipProps {
  surveySubmission: any;
  hasEvaluationPeriodEnded: boolean;
}

const EvaluationChip: React.FunctionComponent<EvaluationChipProps> = props => {
  const { hasEvaluationPeriodEnded, surveySubmission } = props;

  return (
    <Chip
      color={
        !hasEvaluationPeriodEnded && !surveySubmission
          ? 'default'
          : hasEvaluationPeriodEnded && !surveySubmission
          ? 'warning'
          : !surveySubmission
          ? 'default'
          : surveySubmission.is_draft
          ? 'warning'
          : 'success'
      }
      label={
        hasEvaluationPeriodEnded && !surveySubmission
          ? 'Not Submitted'
          : !surveySubmission
          ? 'Not Started'
          : surveySubmission.is_draft
          ? 'Draft'
          : 'Submitted'
      }
      sx={{ width: 'fit-content' }}
    />
  );
};

export { EvaluationChip };
