import React, { FC, useState, useEffect } from 'react';
import { Input, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import { api } from '@src/lib/client';
import Resource from '@src/models/Resource';
import Program from '@src/models/Program';
import ResourceViewer from '../../ResourceViewer';
import {
  Spin,
  Grid,
  ListGridToggle,
  LayoutType,
  useLayoutToggle,
  DialogTitle,
  Button,
} from '@src/components/sc-design-system';
import { useStore } from '@src/models/Store';
import './ProgramResources.less';
import {
  Link,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Stack,
} from '@mui/material';
import { ResourceTypeChip } from '@src/components/common/ResourceTypeChip';

const ProgramResources: FC<{ program: Program; onClose: Function }> = ({
  program,
  onClose,
}) => {
  const [layoutType, setLayoutType] = useLayoutToggle('program-resources');
  const [resources, setResources] = useState<Resource[]>([]);
  const [loadingResources, setLoadingResources] = useState<boolean>(false);

  const [keyword, setKeyword] = useState<string>();

  const [filteredResources, setFilteredResources] = useState<Resource[]>([]);

  const { user } = useStore();

  useEffect(() => {
    const getResources = async () => {
      setLoadingResources(true);
      try {
        const res = await api.resources.get(program.sow_id);
        setResources(res.data?.map(r => new Resource(r)) || []);
        setFilteredResources(res.data?.map(r => new Resource(r)) || []);
      } catch (e) {
        setResources([]);
      }
      setLoadingResources(false);
    };
    getResources();
  }, [program]);

  const trackResourceView = async (resource: Resource, openedAt: number) => {
    await resource.trackView(
      resource.type,
      user.id,
      program.id,
      program.client_id,
      resource.type === 'document' || resource.new_window
        ? 0
        : Date.now() - openedAt,
    );
  };

  useEffect(() => {
    setFilteredResources(
      keyword && keyword.length
        ? resources.filter(resource => {
            return (
              resource.name.toLowerCase().includes(keyword.toLowerCase()) ||
              resource.description.toLowerCase().includes(keyword.toLowerCase())
            );
          })
        : resources,
    );
  }, [keyword, resources]);

  return (
    <Dialog
      maxWidth='lg'
      className='program-resources-modal'
      open
      onClose={() => onClose()}
    >
      <DialogTitle
        title={`${program.name} Resources`}
        buttonOnClick={() => onClose()}
      />
      <DialogContent>
        {loadingResources && <Spin sectionLoader />}
        {!loadingResources && resources && resources.length > 0 && (
          <Stack spacing={3} className='program-resources'>
            <Row>
              <Col flex={1}>
                <Input
                  placeholder='Search resources'
                  value={keyword}
                  onChange={e => {
                    setKeyword(e.target.value);
                  }}
                  allowClear
                />
              </Col>
              <ListGridToggle
                layoutType={layoutType}
                onChange={setLayoutType}
              />
            </Row>
            {Boolean(filteredResources.length) && (
              <Grid
                className='resource-grid'
                data={filteredResources}
                cols={layoutType === LayoutType.grid ? 2 : 1}
                renderer={resource => (
                  <div className='resource-grid-item'>
                    <ResourceViewer
                      resource={resource}
                      trackView={trackResourceView}
                    >
                      <>
                        <ResourceTypeChip type={resource.type} />
                        <Typography
                          variant='h4'
                          color='primary'
                          sx={{ marginTop: '.75em' }}
                        >
                          {resource.name}
                        </Typography>
                        <Typography
                          variant='body2'
                          className='resource-description'
                        >
                          {resource.description}
                        </Typography>
                      </>
                    </ResourceViewer>
                  </div>
                )}
              />
            )}
            {Boolean(!filteredResources.length) && (
              <div className='no-resources'>
                <Typography variant='body2'>
                  No resources were found matching your search
                </Typography>
                <Link
                  component='button'
                  onClick={() => setKeyword('')}
                  sx={{ marginTop: '15px' }}
                >
                  Clear Search
                </Link>
              </div>
            )}
          </Stack>
        )}
        {!loadingResources && resources?.length === 0 && (
          <div className='no-resources'>
            There are currently no resources available for this program
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button text='Close' onClick={() => onClose()} />
      </DialogActions>
    </Dialog>
  );
};

export default observer(ProgramResources);
