import React, { FC } from 'react';
import {
  Stack,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  DialogTitle,
  Button,
  ConfirmDialog,
} from '@src/components/sc-design-system';
import { useUser } from '@src/hooks/useUser';
import { api } from '@src/lib/client';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface UploadedFilesModalProps {
  open: boolean;
  onClose: Function;
}

const UploadedFilesModal: FC<UploadedFilesModalProps> = ({ open, onClose }) => {
  const [openConfirmModal, setOpenConfirmModal] = React.useState<
    string | false
  >(false);
  const [deletingFile, setDeletingFile] = React.useState<string | false>(false);
  const queryClient = useQueryClient();
  const handleClose = () => onClose();
  const { user } = useUser();

  const { mutate: deleteResource, isPending } = useMutation({
    mutationFn: async (key: string) => {
      setDeletingFile(key);
      return api.files.deleteFile(key);
    },
    onSuccess: async (_, key) => {
      // TODO: refactor to remove mobx dep
      user.documents = user.documents.filter(doc => doc !== key);
      await user.save();
      if (!user.documents.length) {
        queryClient.invalidateQueries({
          queryKey: ['user', user.id, 'summary-inputs'],
        });
        handleClose();
      }
    },
    onSettled: () => {
      setDeletingFile(false);
    },
  });

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm'>
      <DialogTitle title='Uploaded files' buttonOnClick={handleClose} />
      <DialogContent>
        <Alert severity='info'>
          <Typography variant='body2'>
            This data is confidential and is not viewable by your employer.
          </Typography>
        </Alert>
        <Stack spacing={2} divider={<Divider />}>
          {user?.documents.map(d => {
            return (
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                key={d}
              >
                <Typography variant='body2'>{d}</Typography>
                <ConfirmDialog
                  open={openConfirmModal === d}
                  setOpenModal={setOpenConfirmModal}
                  buttonProps={{
                    text: 'Delete',
                    onClick: async () => {
                      deleteResource(d);
                    },
                  }}
                  title='Delete document'
                  body='Are you sure you want to delete this document?'
                />
                {deletingFile === d ? (
                  <CircularProgress size={20} />
                ) : (
                  <IconButton
                    onClick={() => setOpenConfirmModal(d)}
                    disabled={isPending}
                  >
                    <DeleteOutlineOutlinedIcon fontSize='small' color='error' />
                  </IconButton>
                )}
              </Stack>
            );
          })}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button text='Close' onClick={handleClose} />
      </DialogActions>
    </Dialog>
  );
};

export { UploadedFilesModal };
