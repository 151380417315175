import React, { FC, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from '@emotion/styled';
import User from '@src/models/User';
import Client from '@src/models/Client';
import { Avatar } from '@src/components/sc-design-system';

import { ActiveClientKey } from '@src/hooks/useActiveClient';

const ClientSelector: FC<{
  user: User;
  disabled?: boolean;
  onSelect?: Function;
  selectedClient: Client;
  localStorageKey: ActiveClientKey;
}> = ({ user, onSelect, selectedClient, disabled, localStorageKey }) => {
  const queryClient = useQueryClient();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchor);

  const unselectedClients = user.clients?.filter(
    ({ id }) => id !== selectedClient?.id,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchor(null);
  };
  return (
    <div>
      <StyledButton
        id='client-selector'
        onClick={handleClick}
        aria-controls={open ? 'client-selector-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='text'
        endIcon={
          <KeyboardArrowDownIcon sx={{ width: '24px', height: '24px' }} />
        }
        className='client-selector'
        disabled={disabled}
      >
        <ClientBranding client={selectedClient} />
      </StyledButton>
      <StyledMenu
        id='client-selector-menu'
        MenuListProps={{ 'aria-labelledby': 'client-selector' }}
        open={open}
        anchorEl={menuAnchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className='menu-header'>
          <ClientBranding client={selectedClient} />
          <Typography
            variant='body2'
            sx={{ marginLeft: '40px', fontSize: '12px' }}
          >
            Selected
          </Typography>
        </div>
        {Boolean(unselectedClients.length) && (
          <div>
            <Divider style={{ marginBottom: 14 }} />

            {unselectedClients.map(c => (
              <MenuItem
                key={c.id}
                onClick={() => {
                  if (onSelect) onSelect(c);
                  handleClose();
                  localStorage.setItem(localStorageKey, c.id);
                  queryClient.invalidateQueries({
                    queryKey: ['user', user.id],
                  });
                  queryClient.invalidateQueries({
                    queryKey: [localStorageKey],
                  });
                }}
              >
                <ClientBranding client={c} />
              </MenuItem>
            ))}
          </div>
        )}
      </StyledMenu>
    </div>
  );
};

const ClientBranding = ({ client }) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
    <Avatar srcId={client?.id ?? ''} alt={client?.name}>
      {/* TODO: this is a fallback until logos are fully migrated */}
      {client?.logo_image ? (
        <img
          src={client?.logo_image}
          alt={client?.name}
          style={{
            width: 'inherit',
            height: 'inherit',
          }}
        />
      ) : null}
    </Avatar>
    <Typography>{client?.name}</Typography>
  </div>
);

export { ClientSelector };

const StyledButton = styled(Button)({
  '&.client-selector': {
    textTransform: 'none',
    borderRadius: '6px',
    color: '#585858',
    '&:hover': {
      backgroundColor: '#D8D8D8',
    },
  },
});

const StyledMenu = styled(Menu)({
  '.MuiMenu-paper': {
    border: '1px solid #D8D8D8',
    boxShadow: 'none',
    marginLeft: '-8px',
    width: 300,
    maxHeight: 278,
    padding: '12px',
    '.menu-header': {
      gap: '4px',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: '0px 8px',
      marginBottom: '0.75em',
    },
  },
});
