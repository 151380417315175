import React, { useState, FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { Stack, Typography, Button, Menu, MenuItem } from '@mui/material';
import Session, { SessionStatusOverride } from '@src/models/Session';
import { useToast } from '@src/components/sc-design-system';

import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import EditOutlined from '@mui/icons-material/EditOutlined';

interface SessionTrackerStatusPropsIF {
  session: Session;
  status: string;
  userId?: string;
  isAdmin?: boolean;
  onSessionStatusChange?: Function;
}

const SessionTrackerStatus: FC<SessionTrackerStatusPropsIF> = observer(
  ({ session, status, userId, isAdmin, onSessionStatusChange }) => {
    const toast = useToast();
    const [currentStatus, setCurrentStatus] = useState(
      session?.current_status === 'scheduled' &&
        session?.calendly_event?.end_time < Date.now() + 1000 * 60 * 30
        ? 'missed'
        : status,
    );
    useEffect(
      () => {
        setCurrentStatus(
          session?.current_status === 'scheduled' &&
            session?.calendly_event?.end_time < Date.now() + 1000 * 60 * 30
            ? 'missed'
            : status,
        );
      },
      // FIXME: invalid dependency array
      // eslint-disable-next-line
      [session],
    );

    function SessionStatusOverrideDropDown() {
      const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
      const open = Boolean(anchorEl);
      const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };

      const overrides = [
        { label: 'Canceled', value: 'canceled' },
        { label: 'Completed', value: 'completed' },
      ];
      const items = overrides.map(or => {
        return {
          key: or.value,
          label: <>{or?.label}</>,
        };
      });

      const onClick = async function (key) {
        const originalStatus =
          currentStatus === 'missed' ? 'scheduled' : currentStatus;
        if (originalStatus === key) {
          return; // NOOP
        }
        session.current_status = key;
        session.status_overridden = new SessionStatusOverride({
          original_status: originalStatus,
          timestamp: Date.now(),
          user_id: userId,
        });
        try {
          await session.updateStatus(true);
          setCurrentStatus(key);
          session.current_status_data = key;
          onSessionStatusChange && onSessionStatusChange();
        } catch (e) {
          toast.error('Could not update session status');
        }
      };

      return (
        <>
          <Button
            aria-controls={open ? 'session-status-override-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            size='small'
            variant='text'
            className='shape-pill'
            onClick={handleClick}
            startIcon={<EditOutlined />}
            sx={{ textTransform: 'capitalize' }}
          >
            {currentStatus || session.current_status}
          </Button>
          <Menu
            id='session-status-override-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'session-status-override-button',
            }}
          >
            {items.map(item => (
              <MenuItem key={item.key} onClick={() => onClick(item.key)}>
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      );
    }

    return (
      <Stack
        direction='row'
        alignItems='center'
        key={session?.id}
        sx={{ marginTop: '1em' }}
        spacing={2}
      >
        {isAdmin ? (
          <SessionStatusOverrideDropDown />
        ) : (
          <Typography variant='body2b' sx={{ textTransform: 'capitalize' }}>
            {currentStatus}
          </Typography>
        )}
        <Stack direction='row' spacing={1} alignItems='center'>
          <CalendarTodayOutlined fontSize='small' />
          <Typography variant='body2'>
            {dayjs(session.calendly_event.start_time).format('ddd M/D')}
          </Typography>
        </Stack>
        <Stack direction='row' spacing={1} alignItems='center'>
          <ScheduleIcon fontSize='small' />
          <Typography variant='body2'>
            {dayjs(session.calendly_event.start_time).format('LT')}
          </Typography>
        </Stack>
      </Stack>
    );
  },
);

export default SessionTrackerStatus;
