import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { api } from '@src/lib/client';

const MemberFeedbackCell: FC<{
  programId: string;
  memberId: string;
  onButtonClick: Function;
}> = ({ programId, memberId, onButtonClick }) => {
  const { data, isLoading } = useQuery({
    queryKey: ['program', programId, 'member', memberId, 'stakeholders'],
    queryFn: async () =>
      api.programs.get360Stakeholders({ programId, memberId }),
    select: ({ data }) => data,
  });

  const responses = data?.stakeholders.reduce((count, s) => {
    if (s.survey_submission_id) count++;
    return count;
  }, 0);

  if (isLoading) {
    return <Typography>...</Typography>;
  }

  return (
    <Link component='button' variant='a2' onClick={() => onButtonClick()}>
      {isLoading ? '...' : `${responses}/${data?.stakeholders.length}`}
    </Link>
  );
};

export { MemberFeedbackCell };
