import * as React from 'react';
import { History } from 'history';
import { createBrowserHistory } from 'history';
import { useStore } from './models/Store';
import { WorldContext, useWorld } from './world';

export interface INavigation extends History {}

export const createNavigation = (): INavigation => {
  return createBrowserHistory();
};

export const useNavigation = () => {
  return React.useContext(WorldContext).navigation;
};

export const createHref = (history: History, pathname) =>
  pathname + history.location.search;

/**
 * Sets the document title. Title changes are considered page views and will
 * be automatically recorded by telemetry.
 */
export const usePageTitle = (title: string) => {
  const { telemetry } = useWorld();
  React.useEffect(() => {
    document.title = `${title} - SkillCycle`;
    telemetry.recordPageView(title);
  }, [telemetry, title]);
};

/**
 * Redirects the user from the index route to the login page if they are not
 * authenticated, otherwise the proper homepage based on their role.
 */
export const HomeRouteHandler = () => {
  const store = useStore();
  const navigation = useNavigation();

  React.useEffect(() => {
    const route = store.session ? store.user.homeRoute : '/login';
    navigation.replace(createHref(navigation, route));
  }, [store, navigation]);
  return null;
};

/**
 * Ensures the user is authenticated, otherwise redirects to the login page.
 */
export const useMustBeAuthenticated = () => {
  const store = useStore();
  const navigation = useNavigation();
  React.useEffect(() => {
    if (!store.session && !store.loading) {
      try {
        localStorage.setItem(
          'redirect',
          createHref(navigation, navigation.location.pathname),
        );
      } catch (e) {}
      navigation.replace(createHref(navigation, '/login'));
    }
  }, [store, navigation]);
};

/**
 * Restores the route that the user intended to visit before they were
 * redirected to the login page.
 */
export const restoreIntendedRoute = (navigation: INavigation) => {
  let route: string;
  try {
    route = localStorage.getItem('redirect');
    localStorage.removeItem('redirect');
  } catch (e) {}
  if (!route) {
    route = createHref(navigation, '/');
  }
  navigation.push(route);
};
