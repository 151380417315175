import React from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import {
  Button,
  DialogTitle,
  useToast,
  ConfirmDialog,
} from '@src/components/sc-design-system';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import User from '@src/models/User';
import { OverallRatingForm } from '@src/components/common/PerformanceCycle/OverallRatingForm';

interface ManagerRatingModalProps {
  setOpenModal: (b: boolean) => void;
  openModal: boolean;
  performanceCycle: PerformanceCycleI;
  profileUser: User;
  clientId: string;
}

const ManagerRatingModal: React.FunctionComponent<
  ManagerRatingModalProps
> = props => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const { setOpenModal, openModal, performanceCycle, profileUser, clientId } =
    props;
  const defaultRatingScaleObj = { rating: undefined };
  const form = useForm({
    mode: 'all',
    defaultValues: defaultRatingScaleObj as OverallRatingFormDefaultValues,
  });
  const { reset, formState } = form;
  const { isDirty } = formState;

  const { mutate: setOverallManagerRating, isPending } = useMutation({
    mutationFn: async ({ rating }: { rating: number }) => {
      return api.clients.updatePerformanceCycleResults(
        clientId,
        performanceCycle.id,
        profileUser.id,
        { rating },
      );
    },
    onSuccess: results => {
      queryClient.setQueryData(
        [
          'client',
          clientId,
          'performanceCycle',
          performanceCycle.id,
          'results',
          'directReportId',
          profileUser.id,
        ],
        results,
      );
      setOpenModal(false);
    },
    onError: () => {
      toast.error('Unable to set manager rating');
    },
  });

  const onSubmit = (data: { rating: number }) => {
    setOverallManagerRating({
      rating: data.rating,
    });
  };

  function handleClose() {
    setOpenModal(false);
    reset();
  }

  return (
    <Dialog open={openModal} onClose={() => handleClose()}>
      <DialogTitle title={`Overall rating for ${profileUser.first_name}`} />
      <DialogContent dividers>
        <OverallRatingForm
          onSubmit={onSubmit}
          form={form}
          ratingScale={performanceCycle.rating_scale_labels}
          profileUser={profileUser}
          ratingType='manager'
        />
      </DialogContent>
      <DialogActions>
        <ConfirmDialog
          open={openConfirmModal}
          setOpenModal={setOpenConfirmModal}
          title='Confirm overall rating'
          body={`Once your overall rating is submitted, ${profileUser.first_name} will be able view it and you will not be able to edit it.`}
          buttonProps={{
            text: 'Submit for review',
            type: 'submit',
            form: 'overall-rating-form',
            key: 'submit',
          }}
        />
        <Button
          sx={{ marginLeft: '.5em' }}
          disabled={!isDirty}
          key='submit'
          text='Submit'
          onClick={() => setOpenConfirmModal(true)}
          loading={isPending}
        />
      </DialogActions>
    </Dialog>
  );
};

export { ManagerRatingModal };
