import React, { FC } from 'react';
import { InputLabel, Stack, Select, MenuItem } from '@mui/material';

interface PerformanceCycleSelectorProps {
  onChange: Function;
  performanceCycles: PerformanceCycleI[];
  selectedPerformanceCycle: PerformanceCycleI | null;
  whiteLabel?: boolean;
}

const PerformanceCycleSelector: FC<PerformanceCycleSelectorProps> = ({
  performanceCycles,
  onChange,
  selectedPerformanceCycle,
  whiteLabel,
}) => {
  if (
    !performanceCycles ||
    performanceCycles?.length < 2 ||
    !selectedPerformanceCycle
  ) {
    return null;
  }

  const options = performanceCycles.map(pc => ({
    label: pc.name,
    value: pc.id,
  }));

  return (
    <Stack gap={1}>
      <InputLabel
        id='performance-cycle-select-label'
        sx={whiteLabel ? { color: 'primary.contrastText' } : null}
      >
        Select performance cycle
      </InputLabel>
      <Select
        labelId='performance-cycle-select-label'
        value={selectedPerformanceCycle?.id}
        onChange={e => {
          const cycle = performanceCycles.find(pc => pc.id === e.target.value);
          onChange(cycle);
        }}
        sx={{ width: '200px', backgroundColor: 'white.main' }}
      >
        {options.map(pc => {
          return (
            <MenuItem key={pc.value} value={pc.value}>
              {pc.label}
            </MenuItem>
          );
        })}
      </Select>
    </Stack>
  );
};

export { PerformanceCycleSelector };
