import React, { useState, FC } from 'react';
import { api } from '@src/lib/client';
import { Table } from 'antd';
import {
  Button,
  Avatar,
  Select,
  TextField,
} from '@src/components/sc-design-system';
import Client, { ClientMember } from '@src/models/Client';
import { observer } from 'mobx-react';
import Program from '@src/models/Program';

let searchTimeout;

const searchUsers = async (
  keyword: string,
  setOptions: Function,
  currentMembers: string[] = [],
  setLoading: Function,
  recordId: string,
  isAdmin?: boolean,
  client?: string,
) => {
  if (searchTimeout) clearTimeout(searchTimeout);
  searchTimeout = setTimeout(async () => {
    if (!keyword || keyword.length === 0) {
      setOptions([]);
      return;
    }
    setLoading(true);
    const res = await api.users.getList({
      keyword,
      client,
      limit: 15,
      page: 1,
    });
    if (res.data && res.data.data && res.data.data.length > 0) {
      setOptions(
        res.data.data
          .filter(m => !currentMembers.includes(m.id))
          .map(m => {
            m.key = m.id;
            return new ClientMember(m);
          }),
      );
    } else {
      setOptions([]);
    }
    setLoading(false);
  }, 400);
};

const FindAndAddMember: FC<{
  client?: Client;
  program?: Program;
  isAdmin?: boolean;
  setLoadList?: Function;
}> = observer(({ client, program, isAdmin = false, setLoadList }) => {
  const record = client || program;
  const currentMembers = program
    ? (program.members &&
        program.members.filter(m => !m.removedAt).map(m => m.id)) ||
      []
    : (client.members &&
        client.members.filter(m => !m.removedAt).map(m => m.id)) ||
      [];
  const [keyword, setKeyword] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  return program || (client && isAdmin) ? (
    <>
      <TextField
        id={record.id}
        value={keyword}
        fullWidth
        placeholder='Search users'
        onChange={e => {
          setKeyword(e.target.value);
          searchUsers(
            e.target.value,
            setOptions,
            currentMembers,
            setLoading,
            record.id,
            isAdmin,
            program ? program.client_id : undefined,
          );
        }}
      />
      <Table
        locale={{ emptyText: 'No users found' }}
        style={{ marginTop: '2em' }}
        bordered
        loading={loading}
        pagination={false}
        columns={[
          {
            key: 'avatar',
            width: 30,
            render: (text, user) => {
              return (
                <Avatar
                  srcId={user.id}
                  alt={`${user.first_name} ${user.last_name}`}
                />
              );
            },
          },
          {
            key: 'info',
            title: 'Name',
            render: (text, user) => {
              return (
                <span>
                  {user.first_name} {user.last_name} <br />
                  <span
                    style={{
                      color: '#ccc',
                      fontSize: '.8em',
                      lineHeight: 0,
                    }}
                  >
                    {user.email}
                  </span>
                </span>
              );
            },
          },
          {
            key: 'add',
            title: 'Add',
            width: '25%',
            align: 'center' as 'center',
            render: (text, user) => {
              const isAdded =
                record &&
                Array.isArray(record.members) &&
                record?.members?.some(m => m.id === user.id && !m.removedAt);
              return isAdded ? (
                <Button
                  color='error'
                  onClick={() => {
                    // @ts-expect-error
                    record.removeMember(user.id);
                    setLoadList && setLoadList(true);
                  }}
                  text='Remove'
                  size='small'
                />
              ) : program ? (
                <Button
                  onClick={() => {
                    program.addMember(user.id, 'member', user.email);
                    setLoadList && setLoadList(true);
                  }}
                  size='small'
                  text='Add to Program'
                />
              ) : (
                <Select
                  options={[
                    {
                      options: [
                        {
                          label: 'As Administrator',
                          value: 'manager',
                        },
                        {
                          label: 'As Member',
                          value: 'member',
                        },
                      ],
                    },
                  ]}
                  fullWidth
                  onChange={e =>
                    client.addMember(
                      user.id,
                      e.target.value as string,
                      user.email,
                    )
                  }
                />
              );
            },
          },
        ]}
        dataSource={options}
      />
    </>
  ) : null;
});

export default FindAndAddMember;
