import React, { FC, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Switch, Popover } from 'antd';
import {
  Button,
  InputField,
  TextareaField,
  SelectField,
  Spin,
  useToast,
} from '@src/components/sc-design-system';
import ImageUploader from '@src/components/forms/ImageUpload/ImageUpload';
import User from '@src/models/User';
import { observer } from 'mobx-react';
import AddressForm from '@src/components/forms/AddressForm/AddressForm';
import LinksManager from '../LinksManager/LinksManager';
import TestimonialsManager from '../TestimonialsManager/TestimonialsManager';
import Store from '@src/models/Store';
import Address from '@src/models/Address';
import { api } from '@src/lib/client';
import { useSkills } from '@src/hooks/useSkills';
import {
  Typography,
  Tabs,
  Tab,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Divider,
} from '@mui/material';
import {
  TabPanelWrapper,
  generateTabProps,
} from '@src/components/common/TabPanelWrapper';

const UserProfileAddresses: FC<{ currentUser: User }> = observer(
  ({ currentUser }) => {
    useEffect(() => {
      currentUser.addresses = [
        currentUser.addresses.length > 0
          ? currentUser.addresses[0]
          : new Address({ type: 'mailing' }),
        currentUser.addresses.length > 1
          ? currentUser.addresses[1]
          : new Address({ type: 'billing' }),
      ];
    }, [currentUser]);
    const [activeTab, setActiveTab] = useState(0);
    const tabs = useMemo(() => {
      return [
        { label: 'Mailing Address', ...generateTabProps(0) },
        { label: 'Billing Address', ...generateTabProps(1) },
      ];
    }, []);
    return (
      <>
        <Tabs
          onChange={(_, v) => {
            setActiveTab(v);
          }}
          value={activeTab}
        >
          {tabs.map(tab => (
            <Tab key={tab.label} label={tab.label} />
          ))}
        </Tabs>
        {tabs.map((tab, idx) => (
          <TabPanelWrapper key={tab.label} value={activeTab} index={idx}>
            <AddressForm
              address={currentUser.addresses[idx]}
              onChange={values => {
                Object.keys(values).forEach(prop => {
                  if (prop !== 'isValid') {
                    currentUser.addresses[idx][prop] = values[prop];
                  }
                });
              }}
            />
            {idx === 0 && (
              <div
                style={{
                  textAlign: 'left',
                  marginLeft: '.6em',
                  marginTop: '.5em',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <label aria-label='address-switch-label'>
                  <Switch
                    aria-labelledby='address-switch-label'
                    checked={currentUser.addresses[0]?.type === 'all'}
                    onChange={v => {
                      currentUser.addresses[0].type = v ? 'all' : 'mailing';
                    }}
                  />{' '}
                  Use this address for mailing and billing
                </label>
              </div>
            )}
          </TabPanelWrapper>
        ))}
      </>
    );
  },
);

const checkValidity = (
  currentUser: User,
  setIsValid: Function,
  isMentor: boolean,
) => {
  const fieldReqs = [
    ['first_name', 1],
    ['last_name', 1],
  ];

  if (currentUser.is_coach || isMentor) {
    fieldReqs.push(['calendly_username', 1]);
    fieldReqs.push(['biography', 1]);
    fieldReqs.push(['coaching_topics', 2]);
  }
  const isValid = fieldReqs.reduce((acc, req) => {
    const [prop, reqLength] = req;
    if (currentUser[prop] && currentUser[prop]?.length >= reqLength && acc) {
      return true;
    } else {
      return false;
    }
  }, true);

  setIsValid(isValid);
};

const UserProfile: FC<{
  store?: Store;
  userId?: string | boolean;
  usersList?: User[];
}> = observer(({ store, userId, usersList }) => {
  const { skills, isLoading: isLoadingSkills } = useSkills();
  const toast = useToast();
  const { t } = useTranslation();
  const [uploadingImage, setUploadingImage] = useState(false);
  const [savingProfile, setSavingProfile] = useState(false);
  const refUser = userId
    ? usersList?.find(u => u.id === userId) || store.user
    : store.user;
  const [isValid, setIsValid] = useState(true);
  const [currentUser, setCurrentUser] = useState(new User(refUser));
  const [errMessage, setErrMessage] = useState(null);
  const [calErrMessage, setCalErrMessage] = useState(null);
  const [isMentor, setIsMentor] = useState(false);

  const getIsMentor = async () => {
    const res = await api.clients.getIsClientMentor(
      currentUser.clients?.[0]?.id,
      currentUser.id,
    );
    if (res.data) {
      setIsMentor(true);
    }
  };

  useEffect(() => {
    getIsMentor();
  });

  useEffect(() => {
    refUser.data && setCurrentUser(new User(refUser));
  }, [refUser]);

  useEffect(
    () => {
      checkValidity(currentUser, setIsValid, isMentor);
      if ((currentUser.is_coach || isMentor) && !isValid) {
        currentUser.coaching_topics.length < 2
          ? setErrMessage('Must select 2 to 5 topics')
          : setErrMessage(null);
        !currentUser.calendly_username ||
        currentUser.calendly_username.length < 1
          ? setCalErrMessage('Must provide a calendly username')
          : setCalErrMessage(null);
        currentUser.testimonials.filter(t => t.isValid === false).length > 0
          ? setCalErrMessage('Must complete all fields for each testimonial')
          : setCalErrMessage(null);
      }
    },
    // FIXME: invalid dependency array
    // eslint-disable-next-line
    [
      currentUser.first_name,
      currentUser.last_name,
      currentUser.biography,
      currentUser.calendly_username,
      currentUser.coaching_topics,
      errMessage,
      currentUser.testimonials,
    ],
  );

  const uploadImage = async image => {
    setUploadingImage(true);
    try {
      await api.images.uploadImage({
        resourceId: currentUser.id,
        image,
      });
      refUser.updateProfileImage();
      currentUser.updateProfileImage();
      store.user.updateProfileImage();
      toast.success('Profile image updated');
    } catch (e) {
      toast.error('Unable to upload profile image');
    }
    setUploadingImage(false);
  };

  const saveUserProfile = async () => {
    setSavingProfile(true);
    try {
      const currTestimonials = currentUser.testimonials;
      const invalidTestimonials = currTestimonials.filter(
        t => t.isValid === false,
      );

      if (!invalidTestimonials.length) {
        await currentUser.save();
        refUser.mergeData(currentUser.data);
        toast.success('Profile saved');
      } else {
        toast.error(
          'Profile could not be saved, please ensure all fields for Testimonials are filled out correctly',
        );
      }
    } catch (e) {
      toast.error(
        'Profile could not be saved, please ensure all fields are filled out correctly',
      );
    }
    setSavingProfile(false);
  };

  const topics = React.useMemo(
    function () {
      if (!isLoadingSkills) {
        if (currentUser?.coaching_topics.length === 5) {
          return currentUser.coaching_topics.map(id => {
            const currentSkill = skills?.find(s => s.id === id);
            if (currentSkill)
              return {
                label: currentSkill.title,
                value: currentSkill.id,
              };
          });
        } else {
          return skills
            .slice()
            .sort((a, b) => (a.title > b.title ? 1 : -1))
            .map(skill => ({
              label: skill.title,
              value: skill.id,
            }));
        }
      }
    },
    [currentUser?.coaching_topics, skills, isLoadingSkills],
  );

  return (
    <div style={{ padding: '2em', overflow: 'scroll' }}>
      <Form layout='vertical'>
        <Row>
          <Col flex={2}>
            <Row>
              <Col flex={1}>
                <InputField
                  name='first_name'
                  label={t('First Name')}
                  rules={[{ required: true }]}
                  value={currentUser.first_name}
                  onChange={(val: string) => {
                    currentUser.first_name = val;
                  }}
                />
              </Col>
              <Col flex={1}>
                <InputField
                  name='last_name'
                  label={t('Last Name')}
                  rules={[{ required: true }]}
                  value={currentUser.last_name}
                  onChange={(val: string) => {
                    currentUser.last_name = val;
                  }}
                />
              </Col>
            </Row>
            {isMentor ? (
              <Row>
                <Col flex={1}>
                  <InputField
                    name='years_organization'
                    label={t('Years with Organization')}
                    type='number'
                    rules={[{ required: true }]}
                    value={currentUser.years_organization}
                    onChange={(val: number) => {
                      currentUser.years_organization = +val;
                    }}
                  />
                </Col>
                <Col flex={1}>
                  <InputField
                    name='years_experience'
                    label={t('Years of Experience')}
                    type='number'
                    rules={[{ required: true }]}
                    value={currentUser.years_experience}
                    onChange={(val: number) => {
                      currentUser.years_experience = +val;
                    }}
                  />
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row>
              <TextareaField
                name='biography'
                label='About Me / Biography'
                rules={[{ required: currentUser.is_coach || isMentor }]}
                placeholder={t('Some information about yourself')}
                value={currentUser.biography}
                onChange={(val: string) => {
                  currentUser.biography = val;
                }}
                rows={10}
              />
            </Row>
          </Col>
          <Col
            flex={'1 1'}
            style={{
              paddingLeft: '1em',
            }}
          >
            <div className='ant-form-item-label'>
              <label className='ant-form-item'>{t('Profile Image')}</label>
            </div>
            {uploadingImage ? (
              <Spin />
            ) : (
              <ImageUploader
                imageUrl={`${process.env.ASSETS_ROOT}/${currentUser.id}/avatar.png?cache=${currentUser.avatarCacheTime}`}
                hasProfileImage={currentUser.hasProfileImage}
                onChange={imgUrl => {
                  uploadImage(imgUrl);
                }}
              />
            )}
          </Col>
        </Row>
        {currentUser.is_coach || isMentor ? (
          <>
            <Divider />
            <Row>
              <Col flex={1}>
                <InputField
                  name='calendly_username'
                  rules={[{ required: true }]}
                  validateStatus={calErrMessage}
                  label={
                    <span>
                      <div
                        style={{
                          float: 'right',
                          paddingRight: '1em',
                          cursor: 'help',
                        }}
                      >
                        <Popover
                          style={{ maxWidth: '300px' }}
                          content={
                            <div>
                              This is the username in your Calendly account that
                              typically appears in your Calendly share links.
                              <br />
                              You can find it in Calendly under{' '}
                              <strong>
                                Account / Account Settings / My link
                              </strong>
                              .
                              <br />
                              Only include the portion after the forward slash.
                            </div>
                          }
                        >
                          <span style={{ fontSize: '.9em', color: '#a8b2ff' }}>
                            What is this?
                          </span>
                        </Popover>
                      </div>
                      Calendly Username
                    </span>
                  }
                  value={currentUser.calendly_username}
                  onChange={(val: string) => {
                    currentUser.calendly_username = val;
                  }}
                  onBlur={() => {
                    currentUser.calendly_username =
                      currentUser.calendly_username?.split('/')?.pop();
                  }}
                />
              </Col>
              <Col flex={1} hidden={true}>
                <SelectField
                  name='coaching_durations'
                  label='Session Durations'
                  search={false}
                  multiple
                  value={currentUser.coaching_durations}
                  initialValue={currentUser.coaching_durations}
                  onChange={val => {
                    currentUser.coaching_durations = val;
                  }}
                  options={[
                    { label: '30m', value: 30 },
                    { label: '60m', value: 60 },
                  ]}
                  style={{ width: '100%', minWidth: '15em' }}
                />
              </Col>
            </Row>
            {Array.isArray(topics) && (
              <SelectField
                name='coaching_topics'
                validateStatus={errMessage}
                rules={[
                  { required: true },
                  {
                    validator: async (_, topics) => {
                      if (topics.length < 2) {
                        return Promise.reject('Must select 2 to 5 topics');
                      }
                    },
                  },
                ]}
                label={
                  <>
                    <div
                      style={{
                        float: 'right',
                        color:
                          currentUser.coaching_topics.length >= 5
                            ? 'red'
                            : '#999',
                      }}
                    >
                      Select 2 to 5 {isMentor ? 'Skills' : 'Topics'}
                    </div>
                    {isMentor ? 'Skills' : 'Coaching Topics'}
                  </>
                }
                placeholder={`Select 2 to 5 ${
                  isMentor ? '' : 'coaching '
                }skills`}
                multiple
                value={currentUser.coaching_topics || []}
                initialValue={currentUser.coaching_topics}
                options={topics}
                optionFilterProp='label'
                onChange={val => {
                  if (val.length <= 5) {
                    currentUser.coaching_topics = val;
                  } else {
                    return;
                  }
                }}
              />
            )}
          </>
        ) : null}
      </Form>

      {currentUser.is_coach && (
        <Accordion>
          <AccordionSummary>
            <Typography variant='h3'>Testimonials</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TestimonialsManager
              testimonials={currentUser.testimonials || []}
              onChange={val => {
                currentUser.testimonials = val;
              }}
            />
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion>
        <AccordionSummary>
          <Typography variant='h3'>Social Media Links</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LinksManager
            links={currentUser.social_media_links}
            onChange={val => {
              currentUser.social_media_links = val;
            }}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography variant='h3'>Addresses</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UserProfileAddresses currentUser={currentUser} />
        </AccordionDetails>
      </Accordion>

      <Button
        sx={{ marginTop: '2em' }}
        disabled={savingProfile || !isValid}
        loading={savingProfile}
        onClick={() => {
          currentUser.cleanupArraysOfObjects();
          saveUserProfile();
        }}
        text={t('Save')}
      />
      {!isValid && (
        <Alert sx={{ marginTop: '1em' }} severity='error'>
          <Typography variant='body2'>
            Please ensure that all fields are properly filled out
          </Typography>
        </Alert>
      )}
    </div>
  );
});

export default UserProfile;
