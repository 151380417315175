import React from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { Card, Button } from '@src/components/sc-design-system';
import LinearScale from '@mui/icons-material/LinearScale';
import AddIcon from '@mui/icons-material/Add';
import { SetRatingScaleModal } from '@src/components/views/MyBusiness/Performance/tabs/PerformanceCycles/PerformanceCycleLandingPage/modals/SetRatingScaleModal';
import { EditRatingScaleModal } from '@src/components/views/MyBusiness/Performance/tabs/PerformanceCycles/PerformanceCycleLandingPage/modals/EditRatingScaleModal';
import { RatingScaleBox } from '@src/components/common/RatingScaleBox';

const RATING_SCALE_OPTIONS = [4, 5, 10];

const RatingScale: React.FunctionComponent<{
  performanceCycle: PerformanceCycleI;
  refetchPerformanceCycle: Function;
  hasNotStartedOrIsAdmin: boolean;
}> = props => {
  const { performanceCycle, hasNotStartedOrIsAdmin, refetchPerformanceCycle } =
    props;
  const ratingScaleSet =
    performanceCycle?.rating_scale_labels &&
    Array.isArray(performanceCycle?.rating_scale_labels);

  return ratingScaleSet ? (
    <ViewRatingScale
      performanceCycle={performanceCycle}
      refetchPerformanceCycle={refetchPerformanceCycle}
      hasNotStartedOrIsAdmin={hasNotStartedOrIsAdmin}
    />
  ) : !ratingScaleSet && hasNotStartedOrIsAdmin ? (
    <SetRatingScale
      performanceCycle={performanceCycle}
      refetchPerformanceCycle={refetchPerformanceCycle}
    />
  ) : (
    <EvaluationPeriodEnded />
  );
};

const SetRatingScale: React.FunctionComponent<{
  performanceCycle: PerformanceCycleI;
  refetchPerformanceCycle: Function;
}> = props => {
  const { performanceCycle, refetchPerformanceCycle } = props;
  const [openModal, setOpenModal] = React.useState(false);
  return (
    <Card
      variant='elevation'
      title='Set a rating scale'
      titleIcon={<LinearScale />}
      titleStyle='h2styleh4'
      sx={{ margin: '2em 0', padding: '.5em' }}
    >
      <Typography variant='body1' color='grey.700' sx={{ margin: '.5em 0' }}>
        Create and set a rating scale that employees will be evaluated against
        by their managers and themselves
      </Typography>
      <SetRatingScaleModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        performanceCycle={performanceCycle}
        selfRatingOptions={RATING_SCALE_OPTIONS}
        refetchPerformanceCycle={refetchPerformanceCycle}
      />
      <Button
        sx={{ marginTop: '1em' }}
        size='small'
        variant='outlined'
        startIcon={<AddIcon />}
        text='Set rating scale'
        onClick={() => setOpenModal(true)}
      />
    </Card>
  );
};

const ViewRatingScale: React.FunctionComponent<{
  performanceCycle: PerformanceCycleI;
  refetchPerformanceCycle: Function;
  hasNotStartedOrIsAdmin: boolean;
}> = props => {
  const { performanceCycle, hasNotStartedOrIsAdmin, refetchPerformanceCycle } =
    props;
  const [openModal, setOpenModal] = React.useState(false);
  return (
    <Card
      title='Rating scale'
      titleStyle='h2styleh4'
      titleIcon={<LinearScale />}
      titleActions={[
        hasNotStartedOrIsAdmin && (
          <Link
            key='edit-rating-scale'
            variant='a1'
            onClick={() => setOpenModal(true)}
          >
            Edit
          </Link>
        ),
      ]}
    >
      <Stack direction='row' spacing={2}>
        {performanceCycle.rating_scale_labels.map(({ value, text }) => (
          <RatingScaleBox key={value} value={value} text={text} />
        ))}
      </Stack>
      <EditRatingScaleModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        performanceCycle={performanceCycle}
        selfRatingOptions={RATING_SCALE_OPTIONS}
        refetchPerformanceCycle={refetchPerformanceCycle}
      />
    </Card>
  );
};

function EvaluationPeriodEnded() {
  return (
    <Card variant='elevation' title='Rating scale' titleStyle='h2styleh4'>
      <Typography variant='body1' color='grey.700' sx={{ margin: '.5em 0 0' }}>
        Not set
      </Typography>
    </Card>
  );
}

export { RatingScale };
