import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import { Row, Space } from 'antd';
import { Competency } from '@src/models/CompetencySet';

import './CompetencyCard.less';
import {
  Card,
  ConfirmDialog,
  MenuButton,
} from '@src/components/sc-design-system';
import { Typography, useTheme, Stack } from '@mui/material';

const CompetencyCard: FC<{
  competency: Competency;
  isEditable?: boolean;
  onToggleEdit?: () => void;
  onDelete?: () => void;
}> = ({ competency, isEditable, onToggleEdit, onDelete }) => {
  const theme = useTheme();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  return (
    <Card
      title={
        <Typography
          variant='h3'
          sx={{ color: `${theme.palette.black.main} !important` }}
        >
          {competency.title}
        </Typography>
      }
      subheader={
        <Stack spacing={1}>
          {competency.updatedAt && (
            <Typography color='grey.600'>
              Last Updated {dayjs(competency.updatedAt).format('MM/DD/YYYY')}
            </Typography>
          )}
          <Typography variant='subtitle1'>{competency.description}</Typography>
        </Stack>
      }
      titleActions={
        isEditable ? (
          <MenuButton
            variant='outlined'
            disablePadding
            primaryActionText='Edit'
            secondaryActions={[
              {
                key: 'delete',
                label: 'Delete',
                onClick: () => setIsDeleteModalOpen(true),
              },
            ]}
            onClickPrimaryAction={onToggleEdit}
          />
        ) : null
      }
    >
      <Row className='indicators-wrapper'>
        <Space wrap>
          {competency.indicators.map(({ title }) => (
            <div key={title}>{title}</div>
          ))}
        </Space>
      </Row>
      {isDeleteModalOpen && (
        <ConfirmDialog
          open={isDeleteModalOpen}
          setOpenModal={setIsDeleteModalOpen}
          title='Delete this competency?'
          body='This action cannot be undone.'
          buttonProps={{ text: 'Delete', onClick: onDelete }}
        />
      )}
    </Card>
  );
};

export { CompetencyCard };
