import React, { FC } from 'react';

import { Chip, ChipProps, Tooltip, Typography, Stack } from '@mui/material';
import TrackChanges from '@mui/icons-material/TrackChanges';
import PeopleOutlined from '@mui/icons-material/PeopleOutlined';
import WorkOutline from '@mui/icons-material/WorkOutline';

type TooltipType = 'organization' | 'shared-with-programs' | 'aligned';

interface GoalTooltipProps {
  type: TooltipType;
  tooltipData?: any;
  chipSx?: ChipProps['sx'];
}

interface TooltipConfig {
  icon: ChipProps['icon'];
  label?: string;
  tooltipContent?: JSX.Element;
  color?: ChipProps['color'];
  customColor?: MuiChipCustomColors;
  iconColor?: string;
}

const GoalTooltip: FC<GoalTooltipProps> = ({ type, tooltipData, chipSx }) => {
  const configMap: Record<TooltipType, TooltipConfig> = {
    organization: {
      icon: <WorkOutline />,
      label: 'Goals shared with organization',
      customColor: 'black',
      tooltipContent: (
        <Typography variant='body2'>
          Goals entered within organization goal cycles are visible to members
          with administrative access.
        </Typography>
      ),
    },
    'shared-with-programs': {
      icon: <PeopleOutlined />,
      tooltipContent: (
        <Stack gap={1}>
          <Typography variant='body2m'>
            Shared with {tooltipData?.programCount} program
            {tooltipData?.programCount > 1 ? 's' : ''}
          </Typography>
          <Typography variant='body2'>
            This goal is visible to your coach or mentor for each selected
            program.
          </Typography>
        </Stack>
      ),
      color: 'tertiary',
      iconColor: 'black.main',
    },
    aligned: {
      icon: <TrackChanges />,
      tooltipContent: tooltipData?.isUserGoal ? (
        <Typography variant='body2'>
          This goal is aligned to{' '}
          <Typography variant='body2m'>
            {tooltipData?.alignedGoals?.[0].title}
          </Typography>
        </Typography>
      ) : (
        <Typography variant='body2'>
          {tooltipData?.alignedGoals?.length} of your goals{' '}
          {tooltipData?.alignedGoals?.length > 1 ? 'are' : 'is'} aligned
        </Typography>
      ),
      color: 'secondary',
    },
  };

  const {
    tooltipContent,
    icon,
    label = '',
    color = 'default',
    customColor = null,
    iconColor = 'white.main',
  } = configMap[type];

  return (
    <Tooltip title={tooltipContent}>
      <Chip
        icon={icon}
        color={color}
        custom-color={customColor}
        label={label || ''}
        sx={{
          ...chipSx,
          borderRadius: '4px',
          height: '32px',
          padding: '8px',
          ...(!label && {
            width: '32px',
            '.MuiChip-label': {
              display: 'none',
            },
          }),
          '.MuiChip-icon': {
            margin: label ? null : 0,
            color: iconColor,
            height: '16px',
            width: '16px',
          },
        }}
      />
    </Tooltip>
  );
};

export { GoalTooltip };
