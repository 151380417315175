import React from 'react';
import ReactMarkdown from 'react-markdown';
import { v4 as uuid } from 'uuid';
import { Stack, Typography, Grid } from '@mui/material';
import { Button } from '@src/components/sc-design-system';
import { useMutation } from '@tanstack/react-query';
import {
  AttachmentInput,
  AttachmentProvider,
} from '@src/components/common/AttachmentInput';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { ChatBubble } from './ChatBubble';
import {
  ErrorState,
  AidaExperimentWarning,
  AnimatedThinkingIcon,
  Download,
} from './helper';
import { api } from '@src/lib/client';
import { useUser } from '@src/hooks/useUser';
import { CompleteStepsAlert } from './CompleteStepsAlert';

interface OpenEndedAidaProps {
  aidaError: boolean;
  setAidaError: (b: boolean) => void;
  footerContent: React.ReactNode;
  clientId: string;
  activeClientMember: { title: string; department: string; name: string };
  setExistingAidaRequest: (a: AbortController) => void;
  conversations: Conversations;
  handlePromptSelection: HandlePromptSelection;
  setUserDisplayMessage: (m: string) => void;
  setAssistantMessage: (m: AidaMessage) => void;
}

interface OpenEndedConversation {
  id: string;
  user: string;
  aida?: string;
  aidaTimestamp?: number;
  attachments?: Attachment[];
}

const HELPER_PROMPTS = [
  'I need to improve my leadership skills. Where do I start?',
  'I have an upcoming 1:1 with my manager and would like support on reaching my goals and focus areas. How should I communicate my needs?',
  'Based on my feedback, what are my gaps and blindspots?',
];

export function OpenEndedAida(props: OpenEndedAidaProps) {
  const {
    aidaError,
    setAidaError,
    footerContent,
    clientId,
    activeClientMember,
    setExistingAidaRequest,
    conversations,
    handlePromptSelection,
    setUserDisplayMessage,
    setAssistantMessage,
  } = props;
  const { user } = useUser();
  // capture user message pre-submit/enter press
  const [temporaryUserMessage, setTemporaryUserMessage] =
    React.useState<string>();
  // non completed prompt/response
  const [latestPrompt, setLatestPrompt] = React.useState<
    OpenEndedConversation | undefined
  >();
  // completed prompts/responses
  const [conversation, setConversation] = React.useState([]);

  async function sendMessage(attachments: Attachment[] = []) {
    setLatestPrompt({ id: uuid(), user: temporaryUserMessage, attachments });
    messageAidaAgent({ prompt: temporaryUserMessage, attachments });
  }

  const { mutate: messageAidaAgent, isPending: isAgentMessageLoading } =
    useMutation({
      mutationFn: async (data: {
        prompt: string;
        attachments?: Attachment[];
      }) => {
        setAidaError(false);
        const axiosController = new AbortController();
        setExistingAidaRequest(axiosController);
        return api.integrations.messageAidaAgent(
          {
            userId: user?.id,
            firstName: user?.first_name,
            lastName: user?.last_name,
            clientId,
            title: activeClientMember?.title,
            department: activeClientMember?.department,
            organization: activeClientMember?.name,
          },
          data,
          axiosController,
        );
      },
      onSuccess: async ({ data }) => {
        setTemporaryUserMessage(undefined);
        setLatestPrompt(undefined);
        setConversation([
          ...conversation,
          {
            id: latestPrompt.id,
            user: latestPrompt.user,
            aida: data.completion,
            aidaTimestamp: data.timestamp,
            attachments: latestPrompt.attachments,
          },
        ]);
      },
      onError: err => err.message !== 'canceled' && setAidaError(true),
    });

  return !conversations?.summary ? (
    <CompleteStepsAlert
      selectedPrompt='ask'
      conversations={conversations}
      handlePromptSelection={handlePromptSelection}
      setUserDisplayMessage={setUserDisplayMessage}
      setAssistantMessage={setAssistantMessage}
    />
  ) : (
    <AttachmentProvider>
      <Stack spacing={3} display='grid' marginTop='2em' id='aida-download-area'>
        {/* successful prompts/responses */}
        {conversation.length === 0 && !latestPrompt && (
          <>
            <ChatBubble
              sender='aida'
              children='Here are some prompts that you may be interested in for your
            development. You can either select one below or type in a question
            related to your development.'
            />
            <Grid
              container
              direction={{ xs: 'column', md: 'row' }}
              spacing={2}
              sx={{ width: '90%' }}
            >
              {HELPER_PROMPTS.map((userMessage, idx) => (
                <Grid item key={idx}>
                  <Button
                    sx={{ height: '144px', width: '285px' }}
                    onClick={() => {
                      setTemporaryUserMessage(userMessage);
                      setLatestPrompt({ id: uuid(), user: userMessage });
                      messageAidaAgent({ prompt: userMessage });
                    }}
                    text={userMessage}
                    variant='outlined'
                    shape='rounded-square'
                    formatText={false}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {conversation.map((c, idx) => {
          return (
            <React.Fragment key={idx}>
              {c.attachments?.map(a => (
                <Stack
                  key={a.id}
                  sx={{
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'grey.400',
                    borderRadius: '8px',
                    padding: '1em',
                  }}
                  width='fit-content'
                  direction='row'
                  justifySelf='end'
                  alignItems='center'
                >
                  <FilePresentIcon sx={{ fontSize: '1.5em' }} />
                  <Typography variant='body1' sx={{ marginLeft: '.5em' }}>
                    {a.name}
                  </Typography>
                </Stack>
              ))}
              <ChatBubble
                sender='user'
                children={<Typography variant='body1'>{c.user}</Typography>}
              />
              <ChatBubble
                sender='aida'
                children={<ReactMarkdown children={c.aida} />}
                timestamp={c.aidaTimestamp}
              />
            </React.Fragment>
          );
        })}
        {/* incomplete pending prompt/responses */}
        {isAgentMessageLoading && (
          <>
            {latestPrompt?.user && (
              <React.Fragment>
                {latestPrompt.attachments?.map(a => (
                  <Stack
                    key={a.id}
                    sx={{
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: 'grey.400',
                      borderRadius: '8px',
                      padding: '1em',
                    }}
                    width='fit-content'
                    direction='row'
                    justifySelf='end'
                    alignItems='center'
                  >
                    <FilePresentIcon sx={{ fontSize: '1.5em' }} />
                    <Typography variant='body1' sx={{ marginLeft: '.5em' }}>
                      {a.name}
                    </Typography>
                  </Stack>
                ))}
                <ChatBubble
                  sender='user'
                  children={
                    <Typography variant='body1'>{latestPrompt.user}</Typography>
                  }
                />
              </React.Fragment>
            )}
            <ChatBubble
              sender='aida'
              children={<AnimatedThinkingIcon />}
              isLoading={true}
            />
          </>
        )}
        {aidaError && (
          <>
            <ChatBubble
              sender='user'
              children={
                <Typography variant='body1'>{latestPrompt.user}</Typography>
              }
            />
            <ChatBubble
              sender='aida'
              children={<ErrorState retryRequest={() => sendMessage()} />}
            />
          </>
        )}
      </Stack>
      <>
        <Stack direction='row'>
          {conversation.length > 0 && <Download selectedPrompt='ask' />}
        </Stack>
        <AttachmentInput
          onSubmit={sendMessage}
          value={temporaryUserMessage || ''}
          disabled={isAgentMessageLoading}
          onChangeInput={e => setTemporaryUserMessage(e.target.value)}
        />
        {footerContent}
        <AidaExperimentWarning />
      </>
    </AttachmentProvider>
  );
}
