import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import TuneIcon from '@mui/icons-material/Tune';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
} from '@src/components/sc-design-system';
import ClientMembers from './tabs/ClientMembers';
import { ClientDepartments } from './tabs/ClientDepartments';
import { ClientSettings } from './tabs/ClientSettings';
import { useClientQueries } from '@src/hooks/useClientQueries';

const ClientAdmin: FC = observer(() => {
  const { id } = useParams();

  const { useGetClient } = useClientQueries(id);
  const {
    data: client,
    isLoading,
    isFetching,
    refetch,
    isError,
  } = useGetClient();

  return (
    <View isLoading={isLoading} isError={isError} refreshFn={refetch}>
      <ViewHeader title='Admin' titleIcon={<TuneIcon fontSize='large' />} />
      <ViewBody>
        <ViewBodySection title='Members' icon={<GroupOutlinedIcon />}>
          <ClientMembers
            client={client}
            isFetchingClient={isFetching}
            refetch={refetch}
          />
        </ViewBodySection>
        {!client?.hris_enabled && (
          <ViewBodySection title='Departments' icon={<BusinessIcon />}>
            <ClientDepartments
              client={client}
              isClientLoading={isLoading || isFetching}
            />
          </ViewBodySection>
        )}
        <ViewBodySection title='Settings' icon={<SettingsOutlinedIcon />}>
          <ClientSettings client={client} />
        </ViewBodySection>
      </ViewBody>
    </View>
  );
});

export { ClientAdmin };
