import { QueryClient, useQuery } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import { useStore } from '@src/models/Store';
import User from '@src/models/User';

export enum ActiveClientKey {
  sc_admin_active_client_id = 'sc_admin_active_client_id',
  profile_active_client_id = 'profile_active_client_id',
}

export const fetchClient = async (
  queryKey: ActiveClientKey,
  user: User,
  queryClient?: QueryClient,
) => {
  let activeClientId = localStorage.getItem(queryKey);
  if (
    !activeClientId &&
    queryKey === ActiveClientKey.profile_active_client_id
  ) {
    activeClientId = user?.clients?.[0]?.id;
    if (activeClientId) {
      localStorage.setItem(queryKey, activeClientId);
    }
  }

  // prefetching sows allows side nav items to appear at the same time
  if (activeClientId && queryClient) {
    queryClient.prefetchQuery({
      queryKey: ['client', activeClientId, 'sows'],
      queryFn: async () => fetchSOWs(activeClientId),
    });
  }

  return api.clients.getClient(activeClientId);
};

const fetchSOWs = async clientId => {
  return api.sows.get({ client: clientId });
};

export const useActiveClient = (opts: {
  queryKey: ActiveClientKey;
  enabled?: boolean;
}) => {
  const { queryKey, enabled = true } = opts;
  const { user } = useStore();

  const isClientQueryEnabled = Boolean(
    enabled &&
      (queryKey === ActiveClientKey.sc_admin_active_client_id
        ? localStorage.getItem(queryKey)
        : localStorage.getItem(queryKey) || user?.clients?.[0]?.id),
  );

  const { data, ...rest } = useQuery({
    queryKey: [opts.queryKey],
    queryFn: async () => fetchClient(queryKey, user),
    enabled: isClientQueryEnabled,
    select: ({ data }) => {
      return {
        activeClient: data,
        hasDirectReports: data?.members?.some(m => m.manager === user.id),
        userIsClientAdmin:
          data?.members.find(m => m.id === user.id)?.role === 'manager',
      };
    },
    staleTime: 60 * 60 * 1000,
  });

  return {
    client: data?.activeClient
      ? {
          ...data?.activeClient,
          is_aida_active: Boolean(
            data?.activeClient?.aida_enabled &&
              data?.activeClient?.aida_terms_accepted?.admin_uuid,
          ),
        }
      : null,
    activeClientMeta: {
      hasDirectReports: data?.hasDirectReports ?? false,
      userIsClientAdmin: data?.userIsClientAdmin ?? false,
    },
    ...rest,
  };
};
