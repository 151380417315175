import React, { FC } from 'react';
import SurveyQuestion from '@src/models/SurveyQuestion';
import { observer } from 'mobx-react';
import { QuestionTitle, QuestionText } from './components';

const SQNumber: FC<{
  surveyQuestion: SurveyQuestion;
  isSurveyEditable: boolean;
}> = ({ surveyQuestion, isSurveyEditable }) => {
  return (
    <div className='sq-editor-number'>
      <div
        className={`question-type-label ${
          surveyQuestion.type && surveyQuestion.isNumberInput
            ? 'number'
            : 'text'
        }`}
      >
        Number
      </div>
      <QuestionTitle
        surveyQuestion={surveyQuestion}
        readOnly={!isSurveyEditable}
      />
      <QuestionText
        surveyQuestion={surveyQuestion}
        propName='helpText'
        isSurveyEditable={isSurveyEditable}
      />
    </div>
  );
};

export default observer(SQNumber);
