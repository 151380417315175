import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';

import {
  BtnBold,
  BtnItalic,
  BtnUnderline,
  BtnBulletList,
  BtnNumberedList,
  BtnClearFormatting,
  BtnLink,
  Editor,
  EditorProvider,
  Toolbar,
  Separator,
} from 'react-simple-wysiwyg';

const WYSIWYG: FC<{
  value?: string;
  onChange?: Function;
  placeholder?: string;
  innerRef?: (el: HTMLElement) => void;
}> = ({ value, onChange, placeholder, innerRef }) => {
  const [interactionState, setInteractionState] = useState<
    false | 'focused' | 'mouseover'
  >(false);
  return (
    <div
      className={`wysiwyg ${
        interactionState ? `interaction-state-${interactionState}` : ''
      }`}
      onFocus={() => {
        setInteractionState('focused');
      }}
      onMouseOver={() => {
        if (interactionState !== 'focused') setInteractionState('mouseover');
      }}
      onMouseOut={() => {
        if (interactionState !== 'focused') setInteractionState(false);
      }}
      onBlur={() => {
        setInteractionState(false);
      }}
    >
      <EditorProvider>
        <Editor
          value={value}
          onChange={e => {
            onChange(e.target.value);
          }}
          placeholder={placeholder}
          contentEditableRef={innerRef}
        >
          <Toolbar>
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <Separator />
            <BtnBulletList />
            <BtnNumberedList />
            <Separator />
            <BtnLink />
            <Separator />
            <BtnClearFormatting />
          </Toolbar>
        </Editor>
      </EditorProvider>
    </div>
  );
};

export default observer(WYSIWYG);
