import React, { useState, FC, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox, Form, Space, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import Store from '@src/models/Store';
import {
  Card,
  Button,
  InputField,
  SCLogo,
  useToast,
} from '@src/components/sc-design-system';
import PasswordReset from '../PasswordReset/PasswordReset';
import { Link, Grid, Typography, Divider } from '@mui/material';

import '@src/lib/i18n.config';
import VerificationCodeInput, {
  VCOnChangeResIF,
} from '@src/components/forms/VerificationCodeInput/VerificationCodeInput';

import { ShellFooter } from '@src/components/app/Shell/AppShell';
import { TermsModal } from '@src/components/common/TermsModal';

interface SFProps {
  store: Store;
}

const minPasswordLength = 8;
const SignupForm: FC<SFProps> = ({ store }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [selectedTerms, setSelectedTerms] = React.useState<null | {
    title: string;
    articleId: string;
  }>(null);

  // Form fields
  const [firstName, setFirstName] = useState(
    localStorage.getItem('first-name') || '',
  );
  const [lastName, setLastName] = useState(
    localStorage.getItem('last-name') || '',
  );
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [password, setPassword] = useState('');
  const [accepted, setAccepted] = useState(false);

  // Form validation
  const [formValid, setFormValid] = useState(false);

  // Auth code
  const [authCode, setAuthCode] = useState('');
  const [authCodeValid, setAuthCodeValid] = useState(false);
  const [awaitingCode, setAwaitingCode] = useState(store.user.hasTempSignup);
  const [authCodeSent, setAuthCodeSent] = useState(false);

  // State
  const [processingSignup, setProcessingSignup] = useState(false);
  const [processingAuthCode, setProcessingAuthCode] = useState(false);

  const processSignup = async () => {
    setProcessingSignup(true);
    try {
      await store.user.signUp({
        email,
        password,
        first_name: firstName,
        last_name: lastName,
      });
      setAwaitingCode(true);
      toast.success(t('Please check your email for confirmation code'));
      // Disable auth code resend for 5s
      setAuthCodeSent(true);
      setTimeout(() => setAuthCodeSent(false), 5000);
    } catch (e) {
      toast.error(e.message);
    }
    setProcessingSignup(false);
  };

  const processAuthCode = async () => {
    setProcessingAuthCode(true);
    try {
      await store.user.confirmSignUp(authCode);
      navigate('/home');
    } catch (e) {
      toast.error(e.message);
    }
    setProcessingAuthCode(false);
  };

  const resendAuthCode = async () => {
    try {
      await store.user.resendCode();
      setAwaitingCode(true);
      toast.success(t('Please check your email for confirmation code'));
      // Disable auth code resend for 5s
      setAuthCodeSent(true);
      setTimeout(() => setAuthCodeSent(false), 5000);
    } catch (e) {
      toast.error(e.message);
    }
  };

  const cancelSignup = () => {
    localStorage.removeItem('gc-temp-signup');
    navigate('/');
  };

  useEffect(() => {
    const validHasAllValues = Boolean(
      firstName.length &&
        lastName.length &&
        email.length &&
        password.length >= minPasswordLength &&
        accepted,
    );
    setFormValid(validHasAllValues);
  }, [firstName, lastName, email, password, accepted]);

  // if user is authenticated, redirect them to their programs page
  useEffect(() => {
    if (store.session) {
      navigate('/my-programs');
    }
  }, [store, navigate]);

  // Shore up duplication calls, need toString for valid `name` param on Form.Item's

  return (
    <Space direction='vertical'>
      <Card>
        <div style={{ paddingLeft: '1em', marginBottom: '.5em' }}>
          <SCLogo showText size={50} />
        </div>
        <div style={{ textAlign: 'center' }}>
          <Typography variant='h2' sx={{ marginBottom: '.5em' }}>
            {localStorage.getItem('client-name')}
          </Typography>
          <Typography variant='h4'>
            {localStorage.getItem('program-name')}
          </Typography>
        </div>
        <Divider />
        <Row justify='center'>
          <Col>
            <Link variant='a1' href='/login'>
              Already a member? Login &#10784;
            </Link>
          </Col>
        </Row>
        <Divider />
        <Form layout='vertical'>
          {awaitingCode ? (
            <Fragment>
              <Typography variant='body2'>
                {t('Check email for authorization code')}
              </Typography>
              <VerificationCodeInput
                onChange={(res: VCOnChangeResIF) => {
                  setAuthCode(res.value);
                  setAuthCodeValid(res.valid);
                }}
              />
              <Button
                style={{ marginTop: '.5em' }}
                onClick={processAuthCode}
                disabled={!authCodeValid || processingAuthCode}
                loading={processingAuthCode}
                text={t('Continue')}
              />
              <Divider />
              {authCodeSent ? (
                <Typography variant='body2' color='grey.700'>
                  {t('Check email for authorization code')}
                </Typography>
              ) : (
                <Grid container justifyContent='space-between'>
                  <Link variant='a2' onClick={resendAuthCode}>
                    {t('Resend Code')}
                  </Link>
                  <Link variant='a2' color='grey.700' onClick={cancelSignup}>
                    {t('Cancel')}
                  </Link>
                </Grid>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <InputField
                label={t('First Name')}
                rules={[{ required: true }]}
                value={firstName}
                onChange={setFirstName}
              />
              <InputField
                label={t('Last Name')}
                rules={[{ required: true }]}
                value={lastName}
                onChange={setLastName}
              />
              <InputField
                label={t('Email Address')}
                rules={[{ required: true, type: 'email' }]}
                value={email}
                onChange={setEmail}
              />
              <Divider />
              <PasswordReset
                isRecovery
                onChange={res => {
                  setPassword(res.valid ? res.newPassword : '');
                }}
              />
              {/* <InputField
              label={t('Password')}
              type='password'
              rules={[{ required: true, min: minPasswordLength }]}
              value={password}
              onChange={setPassword}
            />
            <InputField
              label={t('Confirm Password')}
              type='password'
              rules={[
                {
                  required: true,
                },
                () => ({
                  validator(_, value) {
                    if (!value || password === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('Passwords must match')));
                  },
                }),
              ]}
              value={passwordConfirm}
              onChange={setPasswordConfirm}
            /> */}
              <Space>
                <Checkbox
                  style={{ marginBottom: '3em' }}
                  defaultChecked={false}
                  onChange={e => {
                    setAccepted(e.target.checked);
                  }}
                />
                <div style={{ marginBottom: '1.5em' }}>
                  Check to acknowledge that you have read and accept the{' '}
                  <Link
                    variant='a2'
                    onClick={() => {
                      setSelectedTerms({
                        title: 'Terms of Service',
                        articleId: 'UVdoalMwNXVlVWhNT0V0VFRIYzlQUT09',
                      });
                      setAccepted(false);
                    }}
                  >
                    terms of service
                  </Link>{' '}
                  and{' '}
                  <Link
                    variant='a2'
                    onClick={() =>
                      setSelectedTerms({
                        title: 'Privacy Policy',
                        articleId: 'VUhKWFNURXlVVGh2YWxJdmRXYzlQUT09',
                      })
                    }
                  >
                    privacy policy
                  </Link>
                </div>
              </Space>
              {selectedTerms && (
                <TermsModal
                  title={selectedTerms.title}
                  articleId={selectedTerms.articleId}
                  onClose={() => setSelectedTerms(null)}
                />
              )}
              <Row justify='center'>
                <Button
                  disabled={!formValid || processingSignup}
                  onClick={processSignup}
                  loading={processingSignup}
                  text={t('Signup')}
                />
              </Row>
              <Divider />
              <Link
                variant='a1'
                onClick={() => localStorage.removeItem('gc-temp-signup')}
              >
                {t('Cancel')}
              </Link>
            </Fragment>
          )}
        </Form>
      </Card>
      <ShellFooter />
    </Space>
  );
};

export default SignupForm;
