import React from 'react';
import { observer } from 'mobx-react';
import { Card, SelectField } from '@src/components/sc-design-system';
import Chart from './Chart';
import { ComparisonType } from '@shared/schemas';

import './Insights.less';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Typography, Stack } from '@mui/material';

interface QuestionViewProps {
  selectedQuestion: Question;
  comparisonType: ComparisonType;
  setComparisonType: (t: ComparisonType) => void;
  selectedSurvey: Survey;
  client_id: string;
  disableCompareBy: boolean;
  setDisableCompareBy: (b: boolean) => void;
}

function QuestionView(props: QuestionViewProps) {
  const {
    selectedQuestion,
    comparisonType,
    setComparisonType,
    selectedSurvey,
    client_id,
    disableCompareBy,
    setDisableCompareBy,
  } = props;

  return (
    <>
      <Card
        title={
          <Stack spacing={1}>
            <Typography variant='subtitle1'>Question</Typography>
            <Typography variant='h3'>
              <ReactMarkdown
                components={{ p: 'span' }}
                children={selectedQuestion.title}
              />
            </Typography>
          </Stack>
        }
        titleActions={
          <Stack spacing={1} width='15em'>
            <Typography>Compare results by</Typography>
            <SelectField
              allowClear
              search={false}
              style={{ width: '100%', maxWidth: '240px' }}
              placeholder='No Comparison'
              value={comparisonType}
              options={Object.keys(ComparisonType).map(label => ({
                label,
                value: ComparisonType[label],
              }))}
              onChange={setComparisonType}
              disabled={disableCompareBy}
            />
          </Stack>
        }
      >
        {selectedSurvey && (
          <Chart
            clientId={client_id}
            surveyId={selectedSurvey.id}
            questionId={selectedQuestion.id}
            comparisonType={comparisonType}
            setDisableCompareBy={setDisableCompareBy}
          />
        )}
      </Card>
    </>
  );
}

export default observer(QuestionView);
