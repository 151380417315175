import * as React from 'react';
import { observable, runInAction, IObservableValue } from 'mobx';

type ResizeListener = (entry: ResizeObserverEntry) => void;
const resizeListeners = new WeakMap<Element, ResizeListener>();

const handleResize = (entries: ResizeObserverEntry[]) => {
  for (let entry of entries) {
    let listener = resizeListeners.get(entry.target);
    if (listener) {
      listener(entry);
    }
  }
};
const resizeObserver = new ResizeObserver(handleResize);

export const observe = (element: Element, listener: ResizeListener) => {
  resizeObserver.observe(element);
  resizeListeners.set(element, listener);
};

export const unobserve = (element: Element) => {
  resizeObserver.unobserve(element);
  resizeListeners.delete(element);
};

export const useElementRect = (
  ref: React.MutableRefObject<HTMLElement>,
): IObservableValue<DOMRect | null> => {
  const result = React.useMemo(() => {
    return observable.box(null);
  }, []);
  React.useLayoutEffect(() => {
    let container = ref.current;
    if (!container) {
      return null;
    }
    const notify = (entry: ResizeObserverEntry) => {
      runInAction(() => {
        result.set(entry.contentRect);
      });
    };
    if (container) {
      runInAction(() => {
        const rect = container.getBoundingClientRect();
        result.set(rect);
      });
      observe(container, notify);
      return () => {
        unobserve(container);
      };
    }
  }, [result, ref]);
  return result;
};
