import * as Yup from 'yup';
import {
  DEFAULT_EMAIL_CONTENT,
  DEFAULT_PLATFORM_NOTIFICATION,
  STATIC_EMAIL_CONTENT,
} from './constants';

const baseSchema = Yup.object({
  id: Yup.string(),
  name: Yup.string().required(),
  status: Yup.string<'draft' | 'sent'>().default('draft'),
  client_id: Yup.string(),
  survey_id: Yup.string(),
  platform_notification: Yup.object({
    enabled: Yup.bool().default(true),
    message: Yup.string().default(DEFAULT_PLATFORM_NOTIFICATION),
  }),
  email_notification: Yup.object({
    enabled: Yup.bool().default(true),
    message: Yup.string().default(DEFAULT_EMAIL_CONTENT + STATIC_EMAIL_CONTENT),
  }),
  recipients: Yup.array()
    .of(
      Yup.object({
        id: Yup.string(),
        email: Yup.string().email().default(''),
        submission_id: Yup.string(),
        first_name: Yup.string(),
        last_name: Yup.string(),
        last_sent: Yup.number(),
        send_error: Yup.boolean(),
      }),
    )
    .when('status', {
      is: val => val === 'sent',
      then: schema => schema.min(1),
      otherwise: schema => schema.min(0),
    }),
});

const getSchemaForCollection = collection => {
  return Yup.object({
    id: Yup.string(),
    name: Yup.string().required(),
    status: Yup.string<'draft' | 'sent'>().default('draft'),
    client_id: Yup.string(),
    survey_id: Yup.string(),
    platform_notification: Yup.object({
      enabled: Yup.bool().default(true),
      message: Yup.string().default(DEFAULT_PLATFORM_NOTIFICATION),
    }),
    email_notification: Yup.object({
      enabled: Yup.bool().default(true),
      message: Yup.string().default(
        DEFAULT_EMAIL_CONTENT + STATIC_EMAIL_CONTENT,
      ),
    }),
    recipients: Yup.array()
      .of(
        Yup.object({
          id: Yup.string(),
          email: Yup.string().email().default(''),
          submission_id: Yup.string(),
          first_name: Yup.string(),
          last_name: Yup.string(),
          last_sent: Yup.number(),
          send_error: Yup.boolean(),
        }),
      )
      .when('status', {
        is: val => val === 'sent',
        then: schema => {
          // if collection is actually already sent, require at least 1 more recipient
          return collection?.status === 'sent'
            ? schema.min(collection?.recipients?.length + 1)
            : schema.min(1);
        },
        otherwise: schema => schema.min(0),
      }),
  });
};

type CollectionSchema = Yup.InferType<typeof baseSchema>;
export { baseSchema, getSchemaForCollection, CollectionSchema };
