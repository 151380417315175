import React from 'react';
import {
  Typography,
  DialogTitle as MuiDialogTitle,
  DialogTitleOwnProps,
  IconButton,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface DialogTitleProps extends DialogTitleOwnProps {
  title: string | React.ReactElement;
  buttonOnClick?: () => void;
  buttonIsDisabled?: boolean;
}

const DialogTitle: React.FunctionComponent<DialogTitleProps> = ({
  title,
  buttonOnClick,
  buttonIsDisabled = false,
}) => {
  return (
    /* needs to be a div otherwise its an h2 with h6 class name styles */
    <MuiDialogTitle component='div' className=''>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ minHeight: '43px' }}
      >
        {typeof title === 'string' ? (
          <Typography variant='h3' component='h2'>
            {title}
          </Typography>
        ) : (
          <>{title}</>
        )}
        {buttonOnClick && (
          <IconButton onClick={buttonOnClick} disabled={buttonIsDisabled}>
            <CloseIcon />
          </IconButton>
        )}
      </Stack>
    </MuiDialogTitle>
  );
};

export { DialogTitle };
