import React, { FC, useCallback, useMemo } from 'react';
import { ReactSortable } from 'react-sortablejs';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  Autocomplete,
  Divider,
  Typography,
  TextField,
  Stack,
} from '@mui/material';
import { useResources } from '@src/hooks/useResources';
import { Spin } from '@src/components/sc-design-system';
import { useFieldArray, UseFormReturn } from 'react-hook-form';

interface ResourcesTabProps {
  form: UseFormReturn<SOWFormDefaultValues, undefined>;
}

const ResourcesTab: FC<ResourcesTabProps> = ({ form }) => {
  const { resources, isLoading: isResourcesLoading } = useResources();
  const { control } = form;
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'resources',
  });

  const getResourceName = useCallback(
    ({ value }) => {
      const res = resources?.find(r => r.id.toString() === value?.toString());
      return res?.name;
    },
    [resources],
  );

  const filteredResourcesList = useMemo(() => {
    if (isResourcesLoading) return [];
    return fields?.filter(r => {
      return getResourceName(r);
    });
  }, [getResourceName, fields, isResourcesLoading]);

  return isResourcesLoading ? (
    <Spin sectionLoader={true} />
  ) : (
    <>
      <Autocomplete
        clearOnBlur
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
            label=''
          />
        )}
        options={resources
          ?.filter(r => fields.every(f => f.value !== r.id))
          .map(r => ({
            value: r.id,
            label: r.name,
          }))}
        onChange={(e, resource: { value: string; label: string }) => {
          if (resource?.value) {
            append({ id: resource?.value, value: resource?.value });
          }
        }}
      />
      <Divider />
      <Stack>
        <ReactSortable
          handle='.drag-handle'
          list={filteredResourcesList}
          setList={() => {}}
          onEnd={e => {
            move(e.oldIndex, e.newIndex);
          }}
        >
          {filteredResourcesList.length > 0 ? (
            filteredResourcesList.map((item, idx) =>
              getResourceName(item) ? (
                <Stack
                  spacing={2}
                  direction='row'
                  alignItems='center'
                  key={item.id}
                  sx={{ padding: '.5em 0' }}
                >
                  <DragIndicatorIcon
                    className='drag-handle'
                    sx={{
                      opacity: '.7',
                      cursor: 'grab',
                    }}
                  />
                  <IconButton
                    onClick={() => {
                      remove(idx);
                    }}
                  >
                    <RemoveCircleOutlineIcon color='error' fontSize='small' />
                  </IconButton>
                  <div style={{ marginTop: '-.2em' }}>
                    {getResourceName(item)}
                  </div>
                </Stack>
              ) : null,
            )
          ) : (
            <Typography variant='body2'>
              No resources have been assigned to this SOW. Please select from
              dropdown above.
            </Typography>
          )}
        </ReactSortable>
      </Stack>
    </>
  );
};

export { ResourcesTab };
