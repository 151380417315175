import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';

obey.creator('uuid', () => uuidv4());
obey.creator('now', () => Date.now());
obey.creator('90d', () => Date.now() + 1000 * 60 * 60 * 24 * 90);

export const SOWSchema = {
  id: { type: 'string', creator: 'uuid' },
  client_id: { type: 'string', required: true },
  name: { type: 'string', required: true },
  description: { type: 'string', empty: true },
  type: { type: 'string', empty: true, allow: ['standard', 'stipend'] },
  salesforce_opp_id: { type: 'string' },
  sessions_reserved: { type: 'number', default: 0 },
  sessions_per_member: { type: 'number', default: 0 },
  seats_reserved: { type: 'number', default: 0 },
  rate: { type: 'number', default: 0 },
  coach_rate: { type: 'number', default: 0 },
  start_date: { type: 'number', creator: 'now' },
  end_date: { type: 'number', creator: '90d' },
  is_active: { type: 'boolean', default: true },
  allowed_durations: {
    type: 'array',
    values: { type: 'number', allow: [30, 60] },
    default: [60],
  },
  notes: {
    type: 'array',
    empty: true,
    values: {
      type: 'object',
      keys: {
        timestamp: { type: 'number', creator: 'now' },
        author: {
          type: 'object',
          keys: {
            id: { type: 'string' },
            name: { type: 'string' },
          },
        },
        message: { type: 'string' },
      },
    },
  },
  default_program_id: { type: 'any', default: false },
  resources: {
    type: 'array',
    values: { type: 'string' },
    default: [],
    empty: true,
  },
  options: {
    type: 'object',
    keys: {
      custom_coach_selection: { type: 'boolean', default: false },
      mentoring: { type: 'boolean', default: false },
      goals: { type: 'boolean', default: true },
      competencies: { type: 'boolean', default: true },
    },
  },
};

// eslint-disable-next-line no-unused-vars
export type SOWType = { [K in keyof typeof SOWSchema]?: any };

export const SOWModel = obey.model(SOWSchema);
