import React, { FC } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

type MenuButtonItem = {
  key: string;
  label: string;
  onClick: Function;
  disabled?: boolean;
};

export interface IconMenuButtonProps {
  items: Array<MenuButtonItem[] | MenuButtonItem>;
}

const PAPER_PADDING = 16;

const IconMenuButton: FC<IconMenuButtonProps> = ({ items = [] }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <IconButton
        aria-owns={open ? 'icon-button-menu' : undefined}
        aria-haspopup='true'
        onClick={handleOpen}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '.MuiMenu-paper': {
            minWidth: '200px',
            marginLeft: '8px',
            padding: `${PAPER_PADDING}px`,
          },
        }}
      >
        {items.map((item, idx) => {
          if (Array.isArray(item)) {
            return [
              ...item.map(subItem => {
                return (
                  <MenuItem
                    disableRipple
                    key={subItem.key}
                    onClick={e => {
                      subItem.onClick(e);
                      handleClose();
                    }}
                    sx={{ padding: '8px', borderRadius: '4px' }}
                    disabled={subItem.disabled}
                  >
                    {subItem.label}
                  </MenuItem>
                );
              }),
              ...(items[idx + 1] != null
                ? [
                    <Divider
                      key='divider'
                      sx={{
                        marginLeft: `-${PAPER_PADDING}px`,
                        width: `calc(100% + ${PAPER_PADDING * 2}px)`,
                      }}
                    />,
                  ]
                : []),
            ];
          }
          return (
            <MenuItem
              disableRipple
              key={item.key}
              onClick={e => {
                item.onClick(e);
                handleClose();
              }}
              sx={{ padding: '8px', borderRadius: '4px' }}
              disabled={item.disabled}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
};

export { IconMenuButton };
