import querystring from 'node:querystring';
import { HTTPClient } from '@src/lib/client';

interface MergeLinkTokenIf {
  adminEmail: string;
  clientName: string;
  clientId: string;
}

interface MergeAccountTokenIf {
  mergePublicToken: string;
  clientId: string;
}

interface MessageAidaParams {
  userId: string;
  firstName: string;
  lastName: string;
  prompt: 'summary' | 'focus_areas' | 'coaches' | 'resources' | 'ask' | string;
  clientId: string;
  title?: string;
  department?: string;
  organization?: string;
}

interface MessageAidaAgentParams {
  userId: string;
  firstName: string;
  lastName: string;
  clientId: string;
  title?: string;
  department?: string;
  organization?: string;
}

export class IntegrationsAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async getCalendlySchedulingLink(
    coachId: string,
    meetingLength: 'short' | 'long',
  ) {
    return this.http.request({
      url: `/integrations/calendly/book-coach/${coachId}/${meetingLength}`,
      method: 'get',
    });
  }

  async getCalendlyEventDetails(eventUuid: string, inviteeUri: string) {
    return this.http.request({
      url: `/integrations/calendly/events?eventUuid=${eventUuid}&inviteeUri=${inviteeUri}`,
      method: 'get',
    });
  }

  async getMergeLinkToken(params: MergeLinkTokenIf) {
    return this.http.request({
      url: `/integrations/merge/link-token`,
      method: 'post',
      data: params,
    });
  }

  async getMergeAccountToken(params: MergeAccountTokenIf) {
    return this.http.request({
      url: `/integrations/merge/account-token`,
      method: 'post',
      data: params,
    });
  }

  async getMergeHrisIntegration(clientId: string) {
    return this.http.request({
      url: `/integrations/merge/hris-integration/${clientId}`,
      method: 'get',
    });
  }

  async deleteMergeHrisIntegration(clientId: string) {
    return this.http.request({
      url: `/integrations/merge/hris-integration/${clientId}`,
      method: 'delete',
    });
  }

  async messageAida(params: MessageAidaParams, controller) {
    return this.http.request({
      // querystring.stringify throws an error over MessageAidaParams bc it wants `[key: string]: string;` but our interface already asserts key strings, we are just specific about it
      // @ts-expect-error
      url: `/integrations/aida?${querystring.stringify(params)}`,
      method: 'get',
      signal: controller.signal,
    });
  }

  async messageAidaAgent(
    params: MessageAidaAgentParams,
    data: { prompt: string; attachments?: any[] },
    controller,
  ) {
    return this.http.request({
      // querystring.stringify throws an error over MessageAidaParams bc it wants `[key: string]: string;` but our interface already asserts key strings, we are just specific about it
      // @ts-expect-error
      url: `/integrations/aida-agent?${querystring.stringify(params)}`,
      method: 'post',
      data,
      signal: controller.signal,
    });
  }
}
