import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Typography } from '@mui/material';
import { CreateGoalCycleButton } from './CreateGoalCycleButton';
import { Card } from '@src/components/sc-design-system';

interface GetStartedCardProps {
  performanceCycleId: string;
  clientId: string;
}

const GetStartedCard: React.FunctionComponent<GetStartedCardProps> = props => {
  const { clientId, performanceCycleId } = props;
  const queryClient = useQueryClient();
  return (
    <Card
      sx={{ textAlign: 'center' }}
      bodyStyle={{ padding: '2em !important' }}
    >
      <Typography variant='h4'>Create a goal cycle</Typography>
      <Typography
        variant='body1'
        color='grey.700'
        sx={{ margin: '.5em 0 1.5em' }}
      >
        Create organization and member alignment with goal cycles and goals
      </Typography>
      <CreateGoalCycleButton
        clientId={clientId}
        performanceCycleId={performanceCycleId}
        variant='contained'
        showIcon={false}
        onCreate={() => {
          queryClient.invalidateQueries({
            queryKey: [
              'client',
              clientId,
              'performanceCycle',
              performanceCycleId,
              'goalCycles',
            ],
          });
        }}
      />
    </Card>
  );
};

export { GetStartedCard };
