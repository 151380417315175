import React, { useState } from 'react';
import {
  useToast,
  DialogTitle,
  Button,
} from '@src/components/sc-design-system';
import { Table } from 'antd';
import dayjs from 'dayjs';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { UserEngagementRecord } from '@src/lib/client/apis/engagement';
import { api } from '@src/lib/client';

export const Send360NotificationsModal = ({
  record,
  updateLocalRecord,
  onClose,
}: {
  record: UserEngagementRecord;
  updateLocalRecord: Function;
  onClose(): void;
}) => {
  const toast = useToast();
  const [saving, setSaving] = useState<boolean>(false);

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle
        title={`${record.name} ${
          record.programName ? '- ' + record.programName : ''
        }`}
        buttonOnClick={onClose}
      />
      <DialogContent>
        <Typography
          variant='body1'
          color='grey.500'
          sx={{ marginBottom: '1em' }}
        >
          Would you like to send request/reminder emails now?
        </Typography>
        <Table
          rowKey='email'
          dataSource={record.assessmentStakeholders}
          columns={[
            {
              dataIndex: 'email',
              title: 'Email',
            },
            {
              dataIndex: 'sent',
              title: 'Request sent',
              render: sent => {
                return sent ? dayjs(sent).format('M/D/YY') : '';
              },
            },
            {
              dataIndex: 'sent_reminder',
              title: 'Reminder sent',
              render: sentReminder => {
                return sentReminder ? dayjs(sentReminder).format('M/D/YY') : '';
              },
            },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button text='No' onClick={onClose} variant='outlined' />
        <Button
          disabled={saving || !record.assessmentStakeholders.length}
          onClick={async () => {
            setSaving(true);
            try {
              const res = await api.programs.send360Notifications({
                programId: record.programId,
                memberId: record.userId,
                stakeholders: record.assessmentStakeholders,
              });
              updateLocalRecord(record.email, record.programId, {
                assessmentStakeholders: res.data.members.find(
                  m => m.id === record.userId,
                ).assessment_stakeholders,
              });
              onClose();
            } catch {
              toast.error('Could not send 360 notifications');
            }
            setSaving(false);
          }}
          text='Send'
        />
      </DialogActions>
    </Dialog>
  );
};
