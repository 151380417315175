import './AppShell.less';
import * as React from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { ShellSidebar } from './ShellSidebar';
import { useElementRect } from '@src/lib/dom';
import { SCLogo } from '@src/components/sc-design-system';
import { ShellHeader } from './ShellHeader';
import { Link } from '@mui/material';
import { TermsModal } from '@src/components/common/TermsModal';

export let AppShell = ({ children }: { children: React.ReactNode }) => {
  const ref = React.useRef<HTMLDivElement>(null!);
  const rectObservable = useElementRect(ref);

  // null indicates that we don't know if the sidebar should be collapsed. We
  // can't know this until we measure the size of the shell.
  const [sidebarCollapsed, setSidebarCollapsed] = React.useState<
    boolean | null
  >(null);
  // Here's where we measure the shell to determine sidebar visibility.
  React.useLayoutEffect(() => {
    return autorun(() => {
      const rect = rectObservable.get();
      if (rect) {
        // The primary sidebar will automatically expand/collapse based on the
        // amount of available space.
        setSidebarCollapsed(false);
      }
    });
  }, [rectObservable]);

  return (
    <div className='shell' ref={ref}>
      {/* Wait to render the shell body until we know the sidebar state. */}
      {sidebarCollapsed !== null && (
        <div className='shell-body'>
          <ShellSidebar
            toggleSidebar={() => setSidebarCollapsed(!sidebarCollapsed)}
            collapsed={sidebarCollapsed}
          />
          <main className='shell-viewport'>
            <ShellHeader isFullWidth={sidebarCollapsed} />
            <div className='shell-viewport-content shell-content-container'>
              {children}
            </div>
          </main>
        </div>
      )}
    </div>
  );
};
AppShell = observer(AppShell);

export const ShellFooter = () => {
  const [selectedTerms, setSelectedTerms] = React.useState<null | {
    title: string;
    articleId: string;
  }>(null);

  return (
    <footer className='shell-footer shell-content-container'>
      <Link
        href='https://skillcycle.com'
        target='_blank'
        variant='a2blank'
        color='grey.700'
      >
        <SCLogo size={22} showText clickable uppercase={false} />
      </Link>
      <Link
        variant='a2blank'
        color='grey.700'
        onClick={() => {
          setSelectedTerms({
            title: 'Terms of Service',
            articleId: 'UVdoalMwNXVlVWhNT0V0VFRIYzlQUT09',
          });
        }}
      >
        Terms of Service
      </Link>
      <Link
        variant='a2blank'
        color='grey.700'
        onClick={() => {
          setSelectedTerms({
            title: 'Privacy Policy',
            articleId: 'VUhKWFNURXlVVGh2YWxJdmRXYzlQUT09',
          });
        }}
      >
        Privacy Policy
      </Link>
      {selectedTerms && (
        <TermsModal
          title={selectedTerms.title}
          articleId={selectedTerms.articleId}
          onClose={() => setSelectedTerms(null)}
        />
      )}
    </footer>
  );
};
