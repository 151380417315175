import React, { FC } from 'react';
import { observer } from 'mobx-react';
import ClientMembersList from '@src/components/common/ClientMembersList/ClientMembersList';
import { Card, EmptyState } from '@src/components/sc-design-system';
import Client from '@src/models/Client';
import { useStore } from '@src/models/Store';
import InviteClientModal from '@src/components/forms/InviteModal/InviteClientModal';

interface ClientMemberProps {
  client: Client;
  isFetchingClient: boolean;
  refetch: Function;
}

const ClientMembers: FC<ClientMemberProps> = props => {
  const { client, isFetchingClient, refetch } = props;
  const store = useStore();
  const [toInvite, setToInvite] = React.useState<Set<string>>(new Set());
  const [toRemind, setToRemind] = React.useState<Set<string>>(new Set());
  const hrisEnabled =
    client?.merge_account_token &&
    Array.isArray(client?.merge_hris_data) &&
    client?.merge_hris_data.length > 0;

  return (Array.isArray(client?.members) && client.members.length > 0) ||
    (Array.isArray(client?.invitees) && client.invitees.length > 0) ? (
    <Card
      title='Members'
      titleActions={
        <InviteClientModal
          key={`${client?.id}-invites`}
          enableAdvancedInvites={true}
          hrisEnabled={hrisEnabled}
          toInvite={toInvite}
          setToInvite={setToInvite}
          toRemind={toRemind}
          setToRemind={setToRemind}
        />
      }
    >
      <ClientMembersList
        client={client}
        currentUserId={store.user.id}
        isFetchingClient={isFetchingClient}
        enableRowSelectionColumn={true}
        toInvite={toInvite}
        setToInvite={setToInvite}
        toRemind={toRemind}
        setToRemind={setToRemind}
        hrisEnabled={hrisEnabled}
      />
    </Card>
  ) : (
    <EmptyState
      title='You don’t have any members yet'
      body='Get started by inviting members by email'
      EmptyStateActionArea={
        <InviteClientModal
          key={`${client?.id}-invites`}
          enableAdvancedInvites={false}
          refetchClient={refetch}
        />
      }
    />
  );
};

export default observer(ClientMembers);
