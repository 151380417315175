import React, { FC, Fragment, useState, useMemo } from 'react';
import { nanoid } from 'nanoid';
import { observer } from 'mobx-react';
import Add from '@mui/icons-material/Add';
import {
  Button,
  useToast,
  ConfirmDialog,
} from '@src/components/sc-design-system';
import { AssessmentStakeholder } from '@src/models/Program';
import { api } from '@src/lib/client';
import StakeholderRow from './StakeholderRow';
import { Typography, Divider } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

const emptyRow = role => ({ name: '', email: '', role, id: nanoid() });

const mapRole = (stakeholders, role) => {
  const filtered = stakeholders.filter(s => {
    return (s.role || 'peer') === role;
  });
  return filtered.length ? filtered : [emptyRow(role)];
};

const roles: Array<{ header: string; role: 'manager' | 'report' | 'peer' }> = [
  { header: 'Direct Manager', role: 'manager' },
  { header: 'Direct Reports', role: 'report' },
  { header: 'Peers', role: 'peer' },
];

const StakeholderManager: FC<{
  programId: string;
  memberId: string;
  assessmentStakeholders: AssessmentStakeholder[];
  updateLocalRecord: Function;
  isActive: boolean;
  isEditable?: boolean;
}> = ({
  programId,
  memberId,
  assessmentStakeholders,
  updateLocalRecord,
  isActive,
  isEditable = true,
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [isUpdating, setIsUpdating] = useState(false);
  const [manager, setManager] = useState(
    mapRole([...assessmentStakeholders], 'manager'),
  );
  const [report, setReport] = useState(
    mapRole([...assessmentStakeholders], 'report'),
  );
  const [peer, setPeer] = useState(
    mapRole([...assessmentStakeholders], 'peer'),
  );
  const [showModal, setShowModal] = useState(false);
  const [modalStakeholderName, setModalStakeholderName] = useState('');
  const [modalStakeholderEmail, setModalStakeholderEmail] = useState('');
  const [modalStakeholderRole, setModalStakeholderRole] =
    useState<AssessmentStakeholder['role']>('peer');

  const updateAidaInputs = () => {
    queryClient.invalidateQueries({
      queryKey: ['user', memberId, 'summary-inputs'],
    });
  };

  const roleMap = {
    manager: [manager, setManager],
    report: [report, setReport],
    peer: [peer, setPeer],
  };

  const stakeholders = useMemo(
    () => ({ manager, report, peer }),
    [manager, report, peer],
  );

  const saveStakeholder = async stakeholder => {
    setIsUpdating(true);
    let existingStakeholder = !!stakeholder.added;

    const updates = assessmentStakeholders.reduce((arr, s) => {
      if (s.email.toLowerCase() === stakeholder.email.toLowerCase()) {
        existingStakeholder = true;
        arr.push({
          ...s,
          ...stakeholder,
          id: s.id,
          email: stakeholder.email.toLowerCase(),
        });
      } else if (s.id === stakeholder.id) {
        arr.push(stakeholder);
      } else {
        arr.push({ ...s, added: Date.now() });
      }
      return arr;
    }, []);

    if (!existingStakeholder) {
      updates.push({ ...stakeholder, added: Date.now() });
    }

    try {
      if (existingStakeholder) {
        await api.programs.add360Stakeholders({
          stakeholders: updates,
          programId,
          memberId,
        });
      } else {
        await api.programs.add360Stakeholder({
          stakeholder,
          programId,
          memberId,
        });
      }
      setIsUpdating(false);

      updateLocalRecord();
      setModalStakeholderName(stakeholder.name);
      setModalStakeholderEmail(stakeholder.email);
      setModalStakeholderRole(stakeholder.role);
      const [, setRoleList] = roleMap[stakeholder.role];
      setRoleList(mapRole(updates, stakeholder.role));
      toast.success(
        existingStakeholder ? 'Stakeholder updated' : 'Stakeholder added',
      );
      setShowModal(true);
    } catch (err) {
      setIsUpdating(false);
      toast.error(
        `Unable to ${existingStakeholder ? 'update' : 'add'} stakeholder`,
      );
    }
  };

  const addRow = role => {
    const [roleList, setRoleList] = roleMap[role];
    const newRow = emptyRow(role);
    setRoleList([...roleList, newRow]);
  };

  const removeRow = ({ email, role, id }) => {
    const [roleList, setRoleList] = roleMap[role];

    // if not empty row, filter out given email
    // otherwise filter by id
    const filtered = email
      ? roleList.filter(s => s.email !== email)
      : roleList.filter(s => s.id !== id);

    setRoleList(filtered.length ? filtered : [emptyRow(role)]);
  };

  const removeStakeholder = async stakeholder => {
    setIsUpdating(true);
    const { email } = stakeholder;

    if (!email) {
      // skip api call for empty row
      removeRow(stakeholder);
      setIsUpdating(false);
      return;
    }

    try {
      await api.programs.remove360Stakeholder({
        stakeholder,
        programId,
        memberId,
      });
      removeRow(stakeholder);
      setIsUpdating(false);
      updateLocalRecord();
      toast.success('Stakeholder removed');
      updateAidaInputs();
    } catch (err) {
      setIsUpdating(false);
      toast.error('Unable to remove stakeholder');
    }
  };

  const sendInvite = async ({
    email,
    role,
  }:
    | AssessmentStakeholder
    | { email: string; role: 'manager' | 'report' | 'peer' }) => {
    const filtered = assessmentStakeholders.filter(s => s.email === email);
    try {
      await api.programs.send360Notifications({
        programId,
        memberId,
        stakeholders: filtered,
      });
      const updated = assessmentStakeholders.map(s => {
        if (s.email === email) {
          return { ...s, sent: Date.now() };
        }
        return s;
      });
      updateLocalRecord();
      const [, setRoleList] = roleMap[role];
      setRoleList(mapRole(updated, role));

      toast.success('Email sent');
      updateAidaInputs();
    } catch (err) {
      toast.error('Unable to send 360 invite');
    }
  };

  return (
    <>
      <ConfirmDialog
        cancelText='No, continue editing'
        buttonProps={{
          text: 'Yes, send invite',
          onClick: () => {
            sendInvite({
              email: modalStakeholderEmail,
              role: modalStakeholderRole,
            });
          },
        }}
        title='Send Invite Email'
        body={`Would you like to send ${modalStakeholderName} (${modalStakeholderRole}) an invite email at ${modalStakeholderEmail}?`}
        open={showModal}
        setOpenModal={() => setShowModal(false)}
      />
      {window.location.pathname.includes('/my-programs') && (
        <>
          {roles.map(({ header, role }) => (
            <Fragment key={`${role}-section`}>
              <Typography variant='h3' style={{ marginBottom: 24 }}>
                {header}
              </Typography>
              {stakeholders[role].map(stakeholder => {
                const { survey_submission_id } = stakeholder;
                return (
                  <StakeholderRow
                    stakeholder={stakeholder}
                    key={`${role}-${stakeholder.id}`}
                    role={role}
                    onClickRemove={removeStakeholder}
                    onClickSave={saveStakeholder}
                    onClickSend={sendInvite}
                    feedbackSubmitted={!!survey_submission_id}
                    isUpdating={isUpdating}
                    isActive={isActive}
                  />
                );
              })}
              {isActive && (
                <Button
                  variant='outlined'
                  startIcon={<Add />}
                  disabled={stakeholders[role].some(s => !s.added)}
                  onClick={() => addRow(role)}
                  text='Add Stakeholder'
                />
              )}
              <Divider />
            </Fragment>
          ))}
        </>
      )}
      {window.location.pathname.includes('/my-coachees') &&
        roles.map(({ header, role }) => (
          <Fragment key={`${role}-section`}>
            <Typography variant='h3' style={{ marginBottom: 24 }}>
              {header}
            </Typography>
            {!assessmentStakeholders?.filter(s => s.role === role).length
              ? 'No feedback requested'
              : `${
                  assessmentStakeholders?.filter(
                    s => s.role === role && s.survey_submission_id,
                  ).length
                }/${
                  assessmentStakeholders?.filter(s => s.role === role).length
                } Feedback Received`}
            <Divider />
          </Fragment>
        ))}
      {(window.location.pathname.includes('/profile') ||
        window.location.pathname.includes('/program/')) &&
        roles.map(({ header, role }) => (
          <Fragment key={`${role}-section`}>
            <Typography variant='h3' style={{ marginBottom: 24 }}>
              {header}
            </Typography>
            {!assessmentStakeholders?.filter(s => s.role === role).length &&
            !isEditable
              ? 'No feedback requested'
              : stakeholders[role].map(stakeholder => {
                  const { survey_submission_id } = stakeholder;
                  return (
                    <StakeholderRow
                      stakeholder={stakeholder}
                      key={`${role}-${stakeholder.id}`}
                      role={role}
                      onClickRemove={removeStakeholder}
                      onClickSave={saveStakeholder}
                      onClickSend={sendInvite}
                      feedbackSubmitted={!!survey_submission_id}
                      isUpdating={isUpdating}
                      isActive={isActive}
                      isEditable={isEditable}
                    />
                  );
                })}
            {isActive && isEditable && (
              <Button
                variant='outlined'
                startIcon={<Add />}
                disabled={stakeholders[role].some(s => !s.added)}
                onClick={() => addRow(role)}
                text='Add Stakeholder'
              />
            )}
            <Divider />
          </Fragment>
        ))}
    </>
  );
};

export default observer(StakeholderManager);
