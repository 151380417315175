import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { RatingScaleBox } from '@src/components/common/RatingScaleBox';
import { Card } from '@src/components/sc-design-system';

const cardStyle = { borderColor: 'grey.400' };

interface OverallRatingProps {
  data?: EvaluationReport;
}

const OverallRating: FC<OverallRatingProps> = ({ data }) => {
  return (
    <Card
      title='Overall rating'
      sx={{
        ...cardStyle,
        '&.MuiPaper-outlined': cardStyle,
        '.MuiCardHeader-root': cardStyle,
      }}
    >
      <Stack spacing={4}>
        <Box sx={{ backgroundColor: 'grey.50', padding: '2em' }}>
          <Typography variant='h4' component='h3' sx={{ marginBottom: '.5em' }}>
            Final rating
          </Typography>
          <Typography>
            The final employee performance rating is taken from the manager's
            rating.
          </Typography>
          <Stack direction='row' spacing={4} marginTop='24px'>
            {data?.performance_cycle?.rating_scale_labels.map(
              ({ text, value }) => (
                <RatingScaleBox
                  key={value}
                  text={text}
                  value={value}
                  variant={
                    value === data?.results?.manager?.rating
                      ? 'contained'
                      : 'disabled'
                  }
                />
              ),
            )}
          </Stack>
        </Box>
        <Box sx={{ backgroundColor: 'grey.50', padding: '2em' }}>
          <Typography variant='h4' component='h3' sx={{ marginBottom: '.5em' }}>
            Rating breakdown
          </Typography>
          <Typography>
            View and compare the self and manager ratings. The final rating is
            taken from the manager's rating.
          </Typography>
          <Typography variant='body1m' marginTop='24px'>
            Manager
          </Typography>
          <Stack direction='row' spacing={4} marginTop='1em'>
            {data?.performance_cycle?.rating_scale_labels.map(
              ({ text, value }) => (
                <RatingScaleBox
                  key={value}
                  text={text}
                  value={value}
                  variant={
                    value === data?.results?.manager?.rating
                      ? 'contained'
                      : 'disabled'
                  }
                />
              ),
            )}
          </Stack>
          <Typography variant='body1m' marginTop='24px'>
            Self
          </Typography>
          <Stack direction='row' spacing={4} marginTop='1em'>
            {data?.performance_cycle?.rating_scale_labels.map(
              ({ text, value }) => (
                <RatingScaleBox
                  key={value}
                  text={text}
                  value={value}
                  variant={
                    value === data?.results?.direct_report?.rating
                      ? 'contained'
                      : 'disabled'
                  }
                />
              ),
            )}
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};

export { OverallRating };
