import React, { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import MUIButton, { ButtonProps as MUIButtonProps } from '@mui/material/Button';
import { Icon } from './Icon';

export interface ButtonProps extends MUIButtonProps {
  text?: string;
  size?: 'small' | 'large';
  loading?: boolean;
  shape?: 'pill' | 'rounded-square';
  formatText?: boolean;
  anchorRef?: React.MutableRefObject<any>;
}

const textFormatter = text => {
  return text.toLowerCase().replace(
    // Captures and capitalizes first character of string, any word of 4+ characters
    /\b(sow)\b|(^[a-z](?=\w*))|(\b[a-z](?=[a-z]{3,}))/g,
    char => char.toUpperCase(),
  );
};

function ButtonIcon(icon) {
  // either use the icon name or return the react element
  return typeof icon === 'string' ? <Icon name={icon} size='small' /> : icon;
}

const Button: FC<ButtonProps> = ({
  text = '',
  loading,
  disabled,
  shape = 'pill',
  formatText = true,
  anchorRef,
  startIcon,
  endIcon,
  ...props
}) => {
  return (
    <MUIButton
      {...props}
      ref={anchorRef}
      startIcon={loading ? null : startIcon ? ButtonIcon(startIcon) : null}
      endIcon={loading ? null : endIcon ? ButtonIcon(endIcon) : null}
      disabled={disabled || loading}
      className={`shape-${shape}`}
    >
      {loading && (
        <CircularProgress size={18} sx={{ marginRight: 1 }} color='inherit' />
      )}
      {formatText ? textFormatter(text) : text}
    </MUIButton>
  );
};

export { Button };
