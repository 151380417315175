import React from 'react';
import { Grid, Stack, Typography, Switch } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Select, TextField } from '@src/components/sc-design-system';
import FileUpload from '@src/components/common/FileUpload';

const getNameFromUri = (name?: string) => {
  if (!name) return undefined;
  const trail = name.split('-').pop();
  const extension = trail.split('.').pop();
  return `${name.replace(`-${trail}`, '')}.${extension}`;
};

interface ResourceEditorFormProps {
  onSubmit: (data: ResourceEditorOnSubmitProps) => void;
  form: UseFormReturn<ResourceEditorFormDefaultValues, undefined>;
}

const ResourceEditorForm: React.FunctionComponent<
  ResourceEditorFormProps
> = props => {
  const { onSubmit, form } = props;
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, touchedFields },
  } = form;
  const uriLabel = watch('type') === 'link' ? 'URL' : 'Embed URL';

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} id='resource-editor-form'>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Controller
              name='name'
              control={control}
              rules={{
                required: 'Resource name is required',
              }}
              render={({ field }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <TextField
                    {...fieldProps}
                    innerRef={ref}
                    label='Name'
                    required
                    fullWidth
                    error={Boolean(touchedFields.name && errors?.name)}
                    errorMessage={
                      touchedFields.name &&
                      typeof errors?.name?.message === 'string' &&
                      errors?.name?.message
                    }
                    id={field.name}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name='type'
              control={control}
              render={({ field }) => {
                const { ref, onChange, ...fieldProps } = field;
                return (
                  <Select
                    {...fieldProps}
                    id={field.name}
                    innerRef={ref}
                    label='Type'
                    required
                    onChange={event => {
                      onChange(event.target.value);
                      setValue('uri', '');
                    }}
                    options={[
                      {
                        options: [
                          { value: 'embed', label: 'Embed' },
                          { value: 'link', label: 'Link' },
                          { value: 'document', label: 'Document' },
                        ],
                      },
                    ]}
                    fullWidth
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='description'
              control={control}
              rules={{
                required: 'Resource description is required',
              }}
              render={({ field }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <TextField
                    {...fieldProps}
                    id={field.name}
                    innerRef={ref}
                    label='Description / Summary'
                    placeholder='Resource description'
                    required
                    multiline
                    fullWidth
                    maxRows={4}
                    error={Boolean(
                      touchedFields.description && errors?.description,
                    )}
                    errorMessage={
                      touchedFields.description &&
                      typeof errors?.description?.message === 'string' &&
                      errors?.description?.message
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {['link', 'embed'].includes(watch('type')) ? (
              <Controller
                name='uri'
                control={control}
                rules={{
                  required: `Resource ${uriLabel.toLowerCase()} is required`,
                }}
                render={({ field }) => {
                  const { ref, ...fieldProps } = field;
                  return (
                    <TextField
                      {...fieldProps}
                      innerRef={ref}
                      label={`Resource ${uriLabel}`}
                      required
                      fullWidth
                      error={Boolean(touchedFields.uri && errors?.uri)}
                      errorMessage={
                        touchedFields.uri &&
                        typeof errors?.uri?.message === 'string' &&
                        errors?.uri?.message
                      }
                      id={field.name}
                    />
                  );
                }}
              />
            ) : (
              <Controller
                name='uri'
                control={control}
                rules={{
                  required: `Resource ${uriLabel.toLowerCase()} is required`,
                }}
                render={() => (
                  <FileUpload
                    currentFileName={getNameFromUri(watch('uri'))}
                    onSuccess={key =>
                      setValue('uri', key, {
                        shouldDirty: true,
                        shouldTouch: true,
                        shouldValidate: true,
                      })
                    }
                    onFileDelete={() => {
                      setValue('uri', '', {
                        shouldDirty: false,
                        shouldValidate: true,
                      });
                    }}
                  />
                )}
              />
            )}
          </Grid>
          {watch('type') !== 'document' && (
            <Grid item xs={12}>
              <Controller
                name='new_window'
                control={control}
                render={({ field }) => {
                  const { onChange, value, ...fieldProps } = field;
                  return (
                    <Stack direction='row' alignItems='center' spacing={2}>
                      <Switch
                        {...fieldProps}
                        inputProps={{ 'aria-label': fieldProps.name }}
                        checked={value}
                        onChange={event => onChange(event.target.checked)}
                      />
                      <Typography variant='body2'>
                        Open in a new window
                      </Typography>
                    </Stack>
                  );
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              name='default_available'
              control={control}
              render={({ field }) => {
                const { onChange, value, ...fieldProps } = field;
                return (
                  <Stack direction='row' alignItems='center' spacing={2}>
                    <Switch
                      {...fieldProps}
                      inputProps={{ 'aria-label': fieldProps.name }}
                      checked={value}
                      onChange={event => onChange(event.target.checked)}
                    />
                    <Typography variant='body2'>
                      Show for programs with no resources available
                    </Typography>
                  </Stack>
                );
              }}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ResourceEditorForm;
