import { Form, Space, Switch } from 'antd';
import React, { FC, useRef } from 'react';
import SurveyQuestion from '@src/models/SurveyQuestion';
import {
  InputField,
  TextareaField,
  DialogTitle,
  Button,
  Select,
} from '@src/components/sc-design-system';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { api } from '@src/lib/client';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';

const validateSurveyQuestion = (
  currQu,
  setHideOk: Function,
  validateJsObject: Function,
  jsObject,
) => {
  if (currQu.title.length && currQu.type && currQu.type.includes('-select')) {
    validateJsObject(jsObject, setHideOk);
  } else if (
    currQu.title.length &&
    currQu.type &&
    !currQu.type.includes('-select')
  ) {
    setHideOk(false);
  } else {
    setHideOk(true);
  }
};

const validateJsObject = (jsObject, setHideOk: Function) => {
  const validJS = Array.isArray(jsObject)
    ? jsObject.filter(j => {
        if (j.value.length && j.text.length) {
          return j;
        }
      })
    : null;
  if (validJS && jsObject?.length === validJS?.length) {
    setHideOk(false);
  } else {
    //should probably show some type of error message to indicate that error is occuring b/c empty/incomplete answer option
    setHideOk(true);
  }
};

const NewSurveyQuestion: FC<{
  setOpenModal: Function;
  setModalOpen: Function;
  currentSurveyQuestion: SurveyQuestion;
  setCurrentSurveyQuestion: Function;
  modalTitle: string;
  setModalTitle: Function;
  setHideOk: Function;
  hideOk: boolean;
  setTotal: Function;
  total: number;
}> = ({
  setOpenModal,
  setModalOpen,
  currentSurveyQuestion,
  setCurrentSurveyQuestion,
  modalTitle,
  setModalTitle,
  setHideOk,
  hideOk,
  setTotal,
  total,
}) => {
  const json = useRef(null);
  return (
    <Dialog
      open
      onClose={() => {
        setOpenModal(false);
        setModalOpen(false);
        setModalTitle('New survey question');
        setCurrentSurveyQuestion(new SurveyQuestion());
        setHideOk(true);
      }}
    >
      <DialogTitle
        title={modalTitle}
        buttonOnClick={() => {
          setOpenModal(false);
          setModalOpen(false);
          setModalTitle('New survey question');
          setCurrentSurveyQuestion(new SurveyQuestion());
          setHideOk(true);
        }}
      />
      <DialogContent>
        <Form
          initialValues={{
            type: currentSurveyQuestion.type,
          }}
          id='editor'
          layout='vertical'
          onFinish={async () => {
            // Would it be better to just grab the Form.Item values here, or is it better to set state in Form.Item onChange?
            setHideOk(true);
            try {
              if (json.current?.state.jsObject) {
                currentSurveyQuestion.options = json.current.state.jsObject;
              }
              await api.surveys.saveSurveyQuestion(currentSurveyQuestion.data);
              setTotal(total + 1);
              setHideOk(false);
              setOpenModal(false);
              setModalOpen(false);
              setModalTitle('New survey question');
              setCurrentSurveyQuestion(new SurveyQuestion());
            } catch {
              // no-op  - This is needed because JSONInput's state is null if nothing has changed, but trying to set state on its onChange event to control OK button visibility causes too many re-renders
            }
          }}
        >
          <TextareaField
            rules={[
              {
                required: true,
                message: 'Please provide a question title',
                type: 'string',
                whitespace: true,
              },
            ]}
            name='title'
            label='Question title'
            value={currentSurveyQuestion.title}
            onChange={val => {
              currentSurveyQuestion.title = val;
              validateSurveyQuestion(
                currentSurveyQuestion,
                setHideOk,
                validateJsObject,
                json.current?.state.jsObject,
              );
              setCurrentSurveyQuestion(
                new SurveyQuestion(currentSurveyQuestion),
              );
            }}
          />
          <Form.Item name='inQuestionBank' label='In Question Bank'>
            <Switch
              checked={currentSurveyQuestion.inQuestionBank}
              onChange={checked => {
                currentSurveyQuestion.inQuestionBank = checked;
                setCurrentSurveyQuestion(
                  new SurveyQuestion(currentSurveyQuestion),
                );
              }}
            />
          </Form.Item>
          <Select
            required
            name='type'
            label='Type'
            value={currentSurveyQuestion.type}
            options={[
              {
                options: [
                  {
                    label: 'Message',
                    value: 'message',
                  },
                  {
                    label: 'Multi-select',
                    value: 'multi-select',
                  },
                  {
                    label: 'Range',
                    value: 'range',
                  },
                  {
                    label: 'Single-select',
                    value: 'single-select',
                  },
                  {
                    label: 'Text',
                    value: 'text',
                  },
                  {
                    label: 'Text area',
                    value: 'textarea',
                  },
                ],
              },
            ]}
            onChange={e => {
              currentSurveyQuestion.type = e.target.value as string;
              validateSurveyQuestion(
                currentSurveyQuestion,
                setHideOk,
                validateJsObject,
                json.current?.state.jsObject,
              );
              setCurrentSurveyQuestion(
                new SurveyQuestion(currentSurveyQuestion),
              );
            }}
            fullWidth
            sx={{ legend: { display: 'none' } }} // @TODO remove this once antd is gone
            size='small'
          />
          <InputField
            name='name'
            label='Question name'
            value={currentSurveyQuestion.name}
            onChange={val => {
              currentSurveyQuestion.name = val;
              setCurrentSurveyQuestion(
                new SurveyQuestion(currentSurveyQuestion),
              );
            }}
          />
          <TextareaField
            name='helpText'
            label='Help text'
            value={currentSurveyQuestion.helpText}
            onChange={val => {
              currentSurveyQuestion.helpText = val;
              setCurrentSurveyQuestion(
                new SurveyQuestion(currentSurveyQuestion),
              );
            }}
          />
          <TextareaField
            name='privacyText'
            label='Privacy text'
            value={currentSurveyQuestion.privacyText}
            onChange={val => {
              currentSurveyQuestion.privacyText = val;
              setCurrentSurveyQuestion(
                new SurveyQuestion(currentSurveyQuestion),
              );
            }}
          />

          <Space>
            <Form.Item name='required' label='Required'>
              <Switch
                checked={currentSurveyQuestion.required}
                onChange={checked => {
                  currentSurveyQuestion.required = checked;
                  setCurrentSurveyQuestion(
                    new SurveyQuestion(currentSurveyQuestion),
                  );
                }}
              />
            </Form.Item>
            {(currentSurveyQuestion.type === 'multi-select' ||
              currentSurveyQuestion.type === 'single-select') && (
              <Form.Item label='Allow other'>
                <Switch
                  checked={currentSurveyQuestion.allow_other}
                  onChange={checked => {
                    currentSurveyQuestion.allow_other = checked;
                    if (!checked) {
                      currentSurveyQuestion.other_answer_required = false;
                    }
                    setCurrentSurveyQuestion(
                      new SurveyQuestion(currentSurveyQuestion),
                    );
                  }}
                />
              </Form.Item>
            )}

            {(currentSurveyQuestion.type === 'multi-select' ||
              currentSurveyQuestion.type === 'single-select') && (
              <Form.Item label='Allow none'>
                <Switch
                  checked={currentSurveyQuestion.allow_none}
                  onChange={checked => {
                    currentSurveyQuestion.allow_none = checked;
                    setCurrentSurveyQuestion(
                      new SurveyQuestion(currentSurveyQuestion),
                    );
                  }}
                />
              </Form.Item>
            )}
            {currentSurveyQuestion.type === 'text' && (
              <Form.Item name='isNumberInput' label='Number input'>
                <Switch
                  checked={currentSurveyQuestion.isNumberInput}
                  onChange={checked => {
                    currentSurveyQuestion.isNumberInput = checked;
                    setCurrentSurveyQuestion(
                      new SurveyQuestion(currentSurveyQuestion),
                    );
                  }}
                />
              </Form.Item>
            )}
            {(currentSurveyQuestion.type === 'multi-select' ||
              currentSurveyQuestion.type === 'single-select') &&
              currentSurveyQuestion.allow_other && (
                <Form.Item label='Other text required'>
                  <Switch
                    checked={currentSurveyQuestion.other_answer_required}
                    onChange={checked => {
                      currentSurveyQuestion.other_answer_required = checked;
                      setCurrentSurveyQuestion(
                        new SurveyQuestion(currentSurveyQuestion),
                      );
                    }}
                  />
                </Form.Item>
              )}
          </Space>
          {(currentSurveyQuestion.type === 'multi-select' ||
            currentSurveyQuestion.type === 'single-select') && (
            <>
              <Typography variant='body2' sx={{ margin: '1em 0' }}>
                Options
              </Typography>
              <JSONInput
                width='100%'
                ref={json}
                locale={locale}
                placeholder={
                  currentSurveyQuestion.options || [
                    {
                      value: '',
                      text: '',
                      index_position: 0,
                    },
                  ]
                }
                onChange={val => {
                  validateJsObject(val.jsObject, setHideOk);
                }}
              />
            </>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          text='Cancel'
          onClick={() => {
            setOpenModal(false);
            setModalOpen(false);
            setModalTitle('New survey question');
            setCurrentSurveyQuestion(new SurveyQuestion());
            setHideOk(true);
          }}
        />
        <Button
          form='editor'
          type='submit'
          disabled={hideOk}
          text='OK'
          formatText={false}
        />
      </DialogActions>
    </Dialog>
  );
};

export default NewSurveyQuestion;
