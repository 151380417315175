import React, { FC } from 'react';
import { useIntercom } from 'react-use-intercom';
import { Bar, Button, Spin } from '@src/components/sc-design-system';
import { observer } from 'mobx-react';
import { Typography } from '@mui/material';

type Layout = 'horizontal';
type Position = 'top' | 'bottom';

interface StrengthsAndWeaknessDataPoint {
  name: string;
  type: 'self' | 'stakeholder';
  value: number;
}

interface StrengthsAndWeaknesses {
  data: StrengthsAndWeaknessDataPoint[];
}

const ClientDashboardStrengthsAndWeaknesses: FC<{
  strengthsAndWeaknesses?: StrengthsAndWeaknesses;
  loading?: boolean;
}> = ({ strengthsAndWeaknesses, loading }) => {
  const layout: Layout = 'horizontal';
  const position: Position = 'bottom';
  const config = {
    appendPadding: 15,
    data: strengthsAndWeaknesses?.data || [],
    isGroup: true,
    xField: 'value',
    yField: 'name',
    seriesField: 'type',
    legend: {
      layout,
      position,
    },
    label: {
      formatter: ({ value }) => value && value.toFixed(1),
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
    color: ['#0EBFDA', '#7E287E'],
    barStyle: {
      radius: [4, 4, 0, 0],
    },
    xAxis: {
      grid: {
        line: null,
      },
      label: null,
    },
    yAxis: {
      line: null,
      tickLine: null,
    },
  };
  const intercom = useIntercom();

  if (loading) return <Spin sectionLoader />;

  // tooltip is manually set here because of a wacky ts issue
  return config?.data?.length ? (
    <Bar {...config} tooltip={false} />
  ) : (
    <>
      <Typography variant='body1'>
        Assign a 360 survey to one or more programs to generate insights on your
        members strengths and areas for development. Contact us to learn how you
        can add your own competencies and values to surveys.
      </Typography>
      <div style={{ textAlign: 'center', marginTop: '1em' }}>
        <Button
          onClick={() => {
            intercom.show();
          }}
          text='Contact Us'
          sx={{ width: 'fit-content' }}
        />
      </div>
    </>
  );
};

export default observer(ClientDashboardStrengthsAndWeaknesses);
