/* eslint no-template-curly-in-string: 0 */
const translations = {
  // Commom
  Administrator: 'Administrator',
  Coach: 'Coach',
  Coaches: 'Coaches',
  Coachee: 'Coachee',
  Coachees: 'Coachees',
  Member: 'Member',
  Members: 'Members',
  'Email Address': 'Email Address',
  Password: 'Password',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  Save: 'Save',
  Cancel: 'Cancel',
  Continue: 'Continue',
  Next: 'Next',
  Previous: 'Previous',

  'Please contact support': 'Please contact support',

  // Common form validation messages
  'Field required': 'This field is required',
  'Field invalid': 'This field is invalid',
  'Must be valid email address': 'Must be a valid email address',
  'Cannot be empty': 'This field cannot be empty',
  'Invalid date': 'Invalid date',
  'Must be minimum': 'Must be at least ${min} characters',
  'Must not exceed maximum': 'Must be less than ${max}',
  'Must be one of': 'Must be one of [${enum}]',

  // Login and Signup
  Login: 'Login',
  Signup: 'Create Account',
  'Forgot Password': 'Forgot Password',
  'Check email for authorization code':
    'Please check your email for an authorization code',
  'Confirm Password': 'Confirm Password',
  'Passwords must match': 'Passwords must match',
  'Resend Code': 'Resend Code',
  // Recovery
  'Current Password': 'Current Password',
  'New Password': 'New Password',
  'Change Password': 'Change Password',
  'No account found': 'No matching account found',
  'Password Recovery': 'Password Recovery',
  'Recovery Code': 'Recovery Code',
  'Enter the recovery code sent to your email':
    'Enter the recovery code sent to your email',
  'Please check your email for confirmation code':
    'Please check your email for confirmation code',

  // User Profile
  'Your Profile': 'Your Profile',
  'Profile Image': 'Profile Image',
  Biography: 'Biography',
  'Some information about yourself': 'Some information about yourself',

  // Address
  'Street Address 1': 'Street Address 1',
  'Street Address 2': 'Street Address 2',
  City: 'City',
  State: 'State',
  Zip: 'Zip Code',
};

export default translations;
