import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { Button } from '@src/components/sc-design-system';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import StakeholderForm from './StakeholderForm';
import { AssessmentStakeholder } from '@src/models/Program';

import './StakeholderRow.less';

interface StakeholderRowProps {
  role: 'manager' | 'report' | 'peer';
  stakeholder: AssessmentStakeholder;
  feedbackSubmitted?: boolean;
  onClickRemove?: (stakeholder: AssessmentStakeholder) => void;
  onClickSave: Function;
  onClickSend: (stakeholder: AssessmentStakeholder) => void;
  isUpdating: boolean;
  isActive: boolean;
  isEditable?: boolean;
}

const StakeholderRow: FC<StakeholderRowProps> = ({
  stakeholder,
  role,
  feedbackSubmitted = false,
  onClickRemove,
  onClickSave,
  onClickSend,
  isUpdating = false,
  isActive,
  isEditable = true,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <Stack alignItems='center' spacing={2} direction='row'>
      <StakeholderForm
        stakeholder={stakeholder}
        onSubmit={onClickSave}
        role={role}
        onEdit={setIsEditing}
        isUpdating={isUpdating}
        isActive={isActive}
        isEditable={isEditable}
      />
      {!!stakeholder.added && (
        <>
          {!feedbackSubmitted ? (
            <Button
              disabled={isUpdating || isEditing}
              onClick={() => onClickSend(stakeholder)}
              text={stakeholder.sent ? 'Send Reminder' : 'Send Invite'}
            />
          ) : (
            <Stack direction='row' alignItems='center' gap={1}>
              <CheckCircleOutlined />
              <span style={{ fontWeight: 'bold' }}>Feedback Received</span>
            </Stack>
          )}
          {!feedbackSubmitted && stakeholder.sent && (
            <div className='last-sent-at'>
              {`Last sent ${dayjs(
                Math.max(stakeholder.sent, stakeholder.sent_reminder || 0),
              ).format('MM/DD/YYYY')}`}
            </div>
          )}
        </>
      )}
      {isEditable && isActive && !feedbackSubmitted && !stakeholder.sent && (
        <IconButton onClick={() => onClickRemove(stakeholder)}>
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default observer(StakeholderRow);
