import React from 'react';
import { Grid } from '@mui/material';
import { DatePicker, TextField } from '@src/components/sc-design-system';
import { Controller, UseFormReturn } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';

interface PerformanceCycleFormProps {
  onSubmit: (data: PerformanceCycleOnSubmitProps) => void;
  form: UseFormReturn<PerformanceCycleFormDefaultValues, undefined>;
  performanceCycleNames: string[];
}

const PerformanceCycleForm: React.FunctionComponent<
  PerformanceCycleFormProps
> = props => {
  const { onSubmit, form, performanceCycleNames } = props;
  const {
    control,
    handleSubmit,
    getValues,
    resetField,
    formState: { errors, touchedFields, dirtyFields },
  } = form;
  return (
    <form onSubmit={handleSubmit(onSubmit)} id='performance-cycle-form'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name='name'
            control={control}
            rules={{
              required: 'Performance cycle name is required',
              validate: {
                name: name =>
                  !dirtyFields.name ||
                  !touchedFields.name ||
                  (touchedFields.name &&
                    dirtyFields.name &&
                    !performanceCycleNames.includes(name)) ||
                  'Performance cycle name must be unique',
              },
            }}
            render={({ field }) => {
              const { ref, ...fieldProps } = field;
              return (
                <TextField
                  {...fieldProps}
                  innerRef={ref}
                  label='Name'
                  required
                  fullWidth
                  error={Boolean(touchedFields.name && errors?.name)}
                  errorMessage={
                    touchedFields.name &&
                    typeof errors?.name?.message === 'string' &&
                    errors?.name?.message
                  }
                  id={field.name}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name='start'
            control={control}
            rules={{
              required: 'Start date is required',
              validate: {
                start: (v: Dayjs) => v.isValid() || 'Please enter a valid date',
              },
            }}
            render={({ field }) => {
              const { ref, onChange, ...fieldProps } = field;
              return (
                <DatePicker
                  {...fieldProps}
                  onChange={e => {
                    onChange(e);
                    // the following will ensure end is cleared out if a user sets a start/end and then change the start date
                    resetField('end', { defaultValue: '' });
                  }}
                  label='Start Date'
                  fullWidth
                  innerRef={ref}
                  error={Boolean(touchedFields.start && errors?.start)}
                  errorMessage={
                    touchedFields.start &&
                    typeof errors?.start?.message === 'string' &&
                    errors?.start?.message
                  }
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name='end'
            control={control}
            rules={{
              required: 'End date is required',
              validate: {
                end: (v: Dayjs) => {
                  if (!v.isValid()) return 'Please enter a valid date';
                  if (
                    getValues('start') &&
                    v.isBefore(dayjs(getValues('start')))
                  ) {
                    return 'End date must be after start date.';
                  }
                },
              },
            }}
            render={({ field }) => {
              const { ref, ...fieldProps } = field;
              return (
                <DatePicker
                  {...fieldProps}
                  label='End Date'
                  innerRef={ref}
                  minDate={
                    getValues('start') &&
                    dayjs(getValues('start')).add(1, 'day')
                  }
                  fullWidth
                  error={Boolean(touchedFields.end && errors?.end)}
                  errorMessage={
                    touchedFields.end &&
                    typeof errors?.end?.message === 'string' &&
                    errors?.end?.message
                  }
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export { PerformanceCycleForm };
