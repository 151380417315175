import {
  action,
  computed,
  makeObservable,
  makeAutoObservable,
  observable,
  toJS,
} from 'mobx';
import { program as ProgramSchema } from '@shared/schemas';
import Base from './_Base';
import { message } from 'antd';
import { api } from '@src/lib/client';
import { ProgramGetMembersParams } from '@src/lib/client/apis/programs';
import { AxiosResponse } from 'axios';
import SOW from './SOW';
import Client from './Client';
import Invitee from './Invitee';

export class SelfAssessment {
  survey_submission_id?: string;

  constructor(self_assessment) {
    makeAutoObservable(this, { data: computed });
    Object.keys(self_assessment).forEach(prop => {
      this[prop] = self_assessment[prop];
    });
  }

  get data() {
    return ['survey_submission_id'].reduce((acc, prop) => {
      acc[prop] = toJS(this[prop]);
      return acc;
    }, {});
  }
}

export class AssessmentStakeholder {
  id = '';
  email = '';
  added: number = Date.now();
  sent?: number;
  sent_reminder?: number;
  survey_submission_id?: string;
  name?: string;
  role?: 'manager' | 'report' | 'peer';

  constructor(assessment_stakeholder) {
    makeAutoObservable(this, { data: computed });
    Object.keys(assessment_stakeholder).forEach(prop => {
      this[prop] = assessment_stakeholder[prop];
    });
  }

  get data() {
    return [
      'id',
      'email',
      'added',
      'sent',
      'sent_reminder',
      'survey_submission_id',
      'name',
      'role',
    ].reduce((acc, prop) => {
      acc[prop] = toJS(this[prop]);
      return acc;
    }, {});
  }
}
export class ProgramMember extends Base {
  id = undefined;
  role = 'member';
  joinedAt = Date.now();
  removedAt = undefined;
  selected_coach = undefined;
  selected_coach_at = undefined;
  sessions_allocated = 0;
  self_assessment = undefined;
  assessment_stakeholders = [];
  profileImageUrl?: string;
  hasProfileImage = true;

  constructor(member) {
    super();
    makeObservable(this, {
      id: observable,
      role: observable,
      joinedAt: observable,
      removedAt: observable,
      selected_coach: observable,
      selected_coach_at: observable,
      sessions_allocated: observable,
      self_assessment: observable,
      assessment_stakeholders: observable,
      // Computeds
      data: computed,
    });
    Object.keys(member).forEach(prop => {
      if (prop === 'assessment_stakeholders') {
        this[prop] = member[prop].map(p => new AssessmentStakeholder(p));
      } else if (prop === 'self_assessment') {
        this[prop] = new SelfAssessment(member[prop]);
      } else {
        this[prop] = member[prop];
      }
    });
  }

  get data() {
    return JSON.parse(
      JSON.stringify({
        id: toJS(this.id),
        role: toJS(this.role),
        joinedAt: toJS(this.joinedAt),
        removedAt: toJS(this.removedAt),
        selected_coach: toJS(this.selected_coach),
        selected_coach_at: toJS(this.selected_coach_at),
        sessions_allocated: toJS(this.sessions_allocated),
        self_assessment: toJS(this.self_assessment),
        assessment_stakeholders: toJS(
          this.assessment_stakeholders.map(aS => aS.data),
        ),
      }),
    );
  }
}

export class ProgramCompletedSession extends Base {
  session_id = undefined;
  member_id = 'member';
  timestamp = Date.now();
  constructor(member) {
    super();
    makeObservable(this, {
      session_id: observable,
      member_id: observable,
      timestamp: observable,
      // Computeds
      data: computed,
    });
    Object.keys(member).forEach(prop => {
      this[prop] = member[prop];
    });
  }

  get data() {
    return JSON.parse(
      JSON.stringify({
        session_id: toJS(this.session_id),
        member_id: toJS(this.member_id),
        timestamp: toJS(this.timestamp),
      }),
    );
  }
}

export const programProperties = [
  'id',
  'sow_id',
  'client_id',
  'name',
  'description',
  'type',
  'sessions_reserved',
  'sessions_per_member',
  'session_rate',
  'min_interviews',
  'max_interviews',
  'start_date',
  'end_date',
  'is_active',
  'members',
  'mentors',
  'coaches',
  'invitees',
  'allowed_durations',
  'surveys',
];

export default class Program extends Base {
  id = '';
  sow_id = undefined;
  client_id = undefined;
  name = '';
  description = undefined;
  type = 'standard_coaching';
  sessions_reserved = 0;
  sessions_per_member = 0;
  session_rate = 0;
  min_interviews = 0;
  max_interviews = 0;
  start_date = Date.now();
  end_date = Date.now() + 1000 * 60 * 60 * 24 * 90;
  is_active = true;
  members: ProgramMember[] = [];
  mentors: string[] = [];
  coaches: string[] = [];
  invitees: Invitee[] = [];
  completed_sessions = [];
  allowed_durations = [60];
  sows?: SOW[] = [];
  clients?: Client[] = [];
  coach?: any;
  surveys: {
    intake?: { id: string | null; enabled: boolean };
    '360_self'?: { id: string | null; enabled: boolean };
    '360_stakeholder'?: { id: string | null; enabled: boolean };
    midpoint_engagement?: { id: string | null; enabled: boolean };
    final_engagement?: { id: string | null; enabled: boolean };
  } = {
    intake: {
      id: '3636f28e-f057-4697-8cb4-4aa957131202',
      enabled: true,
    },
    '360_self': {
      id: 'efa4a993-bd22-4c62-a99f-3419b726b4d5',
      enabled: true,
    },
    '360_stakeholder': {
      id: '8e810b79-73a6-4e43-af5d-08638b734d89',
      enabled: true,
    },
    midpoint_engagement: {
      id: '1f8d808-18df-11ed-861d-0242ac120002',
      enabled: true,
    },
    final_engagement: {
      id: '9z8p080-18df-11ed-861d-0242ac120002',
      enabled: true,
    },
  };

  constructor(program: ProgramSchema.ProgramType = {}) {
    super();
    makeObservable(this, {
      id: observable,
      sow_id: observable,
      client_id: observable,
      name: observable,
      description: observable,
      type: observable,
      sessions_reserved: observable,
      sessions_per_member: observable,
      session_rate: observable,
      min_interviews: observable,
      max_interviews: observable,
      start_date: observable,
      end_date: observable,
      is_active: observable,
      members: observable,
      mentors: observable,
      coaches: observable,
      invitees: observable,
      completed_sessions: observable,
      allowed_durations: observable,
      surveys: observable,
      // Actions
      mergeData: action,
      save: action,
      addMember: action,
      removeMember: action,
      getMembersList: action,
      updateSurveyConfig: action,
      updateType: action,
      // Computeds
      data: computed,
      isValid: computed,
    });
    this.mergeData(program);
  }

  mergeData = (program: ProgramSchema.ProgramType) => {
    Object.keys(program).forEach(prop => {
      if (prop === 'members') {
        this[prop] = program[prop].map(m => new ProgramMember(m));
      } else if (prop === 'invitees') {
        this[prop] = program[prop].map(i => new Invitee(i));
      } else if (prop === 'completed_sessions') {
        this[prop] = program[prop].map(p => new ProgramCompletedSession(p));
      } else {
        this[prop] = program[prop];
      }
    });
  };

  get data() {
    return programProperties.reduce((acc, prop) => {
      acc[prop] =
        prop === 'members'
          ? this.members.map(m => m.data)
          : prop === 'mentors'
          ? this.mentors || []
          : prop === 'coaches'
          ? this.coaches || []
          : prop === 'invitees'
          ? this.invitees.map(i => i.data)
          : prop === 'completed_sessions'
          ? this.completed_sessions.map(cS => cS.data)
          : toJS(this[prop]);
      return acc;
    }, {});
  }

  get isValid() {
    if (!this.start_date || !this.end_date || this.end_date < this.start_date) {
      return false;
    }
    const checkValues = ['client_id', 'sow_id', 'name'];
    return Boolean(
      checkValues.filter(prop => !this[prop] || this[prop].length === 0)
        .length === 0,
    );
  }

  updateType = type => {
    this.type = type;
    if (type === 'platform_only') {
      this.sessions_reserved = 0;
      this.sessions_per_member = 0;
    }
  };

  changeMentorAvailability = async (
    id: string,
    available: boolean,
  ): Promise<void> => {
    if (available && !this.mentors.includes(id)) {
      this.mentors.push(id);
    } else if (!available && this.mentors.includes(id)) {
      this.mentors = this.mentors.filter(m => m !== id);
    }
    await this.save();
  };

  changeCoachAvailability = async (
    id: string,
    available: boolean,
  ): Promise<void> => {
    if (available && !this.coaches.includes(id)) {
      this.coaches.push(id);
    } else if (!available && this.coaches.includes(id)) {
      this.coaches = this.coaches.filter(c => c !== id);
    }
    await this.save();
  };

  addMember = async (
    memberId: string,
    role: string = 'member',
    email: string,
  ): Promise<void> => {
    try {
      const res = await api.programs.addProgramMembers({
        programId: this.id,
        memberId,
        role,
        email,
      });
      this.members.push(
        new ProgramMember({
          id: memberId,
          role,
          sessions_allocated: res.data.sessionsAllocated,
        }),
      );
    } catch (e) {
      message.error('Could not add member to program');
    }
  };

  inviteMembers = async (invitees: Invitee[]): Promise<void> => {
    try {
      await api.programs.inviteProgramMembers({
        programId: this.id,
        invitees,
      });
      this.invitees = this.invitees.concat(invitees);
    } catch (e) {
      message.error('Could not invite members');
    }
  };

  revokeInvite = async (email: string): Promise<void> => {
    try {
      const res = await api.programs.revokeInvite({
        programId: this.id,
        email,
      });
      this.invitees = res.data.invitees;
    } catch (e) {
      message.error('Could not revoke invite');
    }
  };

  undoRevokeInvite = async (email: string): Promise<void> => {
    try {
      const res = await api.programs.undoRevokeInvite({
        programId: this.id,
        email,
      });
      this.invitees = res.data.invitees;
    } catch (e) {
      message.error('Could not undo revoke');
    }
  };

  removeMember = async (
    memberId: string,
    sessionsUsed: number,
  ): Promise<void> => {
    const member = this.members.find(m => m.id === memberId);
    member.removedAt = Date.now();
    member.sessions_allocated = sessionsUsed;
    await this.save();
  };

  getMembersList = async (
    params: ProgramGetMembersParams,
  ): Promise<AxiosResponse> =>
    await api.programs.getProgramMembers(this.id, params);

  updateMemberSessionAllocation = async (
    memberId: string,
    sessions: number,
  ): Promise<void> => {
    this.members = this.members.map((m: ProgramMember) => {
      if (m.id === memberId) m.sessions_allocated = sessions;
      return m;
    });
    await this.save();
  };

  changeMemberSelectedCoach = async (
    memberId: string,
    coachId?: string | undefined,
  ): Promise<void> => {
    const res = await api.programs.selectProgramCoach(
      memberId,
      coachId,
      this.id,
    );
    this.mergeData(res.data); // Update local
  };

  save = async () => {
    try {
      const res = await api.programs.saveProgram(
        Object.assign({}, this.data, {
          id: this && this.id && this.id.length > 0 ? this.id : undefined,
        }),
      );
      this.mergeData(res.data); // Update local
    } catch (e) {
      message.error('Could not save program record');
      throw e;
    }
  };

  updateSurveyConfig = async () => {
    try {
      const res = await api.programs.updateProgramSurveyConfiguration({
        programId: this.id,
        surveys: this.surveys,
        client_id: this.client_id,
      });
      this.mergeData(res.data);
    } catch (e) {
      message.error('Could not save survey configuration');
      throw e;
    }
  };

  toggleSurveyEnabled = async (type, surveyId, enabled) => {
    this.surveys = {
      ...this.surveys,
      [type]: {
        id: surveyId,
        enabled,
      },
    };

    await this.updateSurveyConfig();
  };
}
