import { useQuery } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import SOW from '@src/models/SOW';

const useSOWs = ({ enabled, params } = { enabled: true, params: {} }) => {
  const { data, ...rest } = useQuery({
    queryKey: ['sows', params],
    queryFn: async () => await api.sows.get(params),
    select: ({ data }) => {
      return {
        data: data.data.map(s => new SOW(s)), // TODO: remove mobx
        totalRecords: data.totalRecords,
      };
    },
    enabled,
  });

  return {
    sows: data ?? null,
    ...rest,
  };
};

export { useSOWs };
