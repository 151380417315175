import { makeAutoObservable } from 'mobx';
import Session from '@src/models/Session';
import { api } from '@src/lib/client';
import { ISession } from '../UserProgramCard/types';
import { ToastIF } from '@src/components/sc-design-system';

export class SessionDetailsViewModel {
  // Init of VM
  id: string;
  userId: string;
  userRole: string;
  session: Session = new Session();
  programType: string;
  type: string;

  notes;
  ratings;
  feedback;
  missed_reason;

  // Data
  notesText: string;
  notesShared: boolean = false;
  notesSavedAt: number;
  ratingValue: number = 0;
  ratingNotes: string;
  feedbackText: string;
  missedFeedback: string;
  otherParticipantName: string;
  otherParticipantRole: string;

  followUpObject?: any;

  // Infered
  ratingReadOnly: boolean = false;
  feedbackReadOnly: boolean = false;

  current_status: string;
  start_time: number;
  end_time: number;

  constructor(
    userId: string,
    userRole: string,

    session: ISession,
    programType?: string,
  ) {
    this.id = session.id;
    this.userId = userId;
    this.userRole = userRole || 'coachee';
    this.programType = programType;
    this.type = session.type;
    // this.session = session;

    const notesObj = session?.notes?.find(n => n.user_id === userId) || {};
    const ratingsObj = session?.ratings?.find(n => n.user_id === userId) || {};
    const feedbackObj =
      session?.feedback?.find(n => n.user_id === userId) || {};
    const missedReasonObj =
      session?.missed_reason?.find(n => n.user_id === userId) || {};

    this.notes = session.notes;
    this.ratings = session.ratings;
    this.feedback = session.feedback;

    this.notesText = notesObj.note || '';
    this.notesShared = notesObj.shared || false;
    this.notesSavedAt = notesObj.timestamp || 0;
    this.ratingValue = ratingsObj.rating || 0;
    this.ratingNotes = ratingsObj.notes || '';
    this.feedbackText = feedbackObj.feedback || '';
    this.missedFeedback = missedReasonObj.reason;

    this.ratingReadOnly = ratingsObj?.rating > 0;
    this.feedbackReadOnly = feedbackObj?.feedback?.length > 0;

    const otherParticipant = session?.members?.find(m => m.id !== userId);
    this.otherParticipantName = otherParticipant.first_name;
    this.otherParticipantRole = otherParticipant.role;

    this.current_status = session.current_status;
    this.start_time = session.start_time;
    this.end_time = session.end_time;

    makeAutoObservable(this);
  }

  get needsRating() {
    return (
      this.current_status === 'completed' &&
      Boolean(!this.ratingReadOnly) &&
      Date.now() - this.end_time <= 1000 * 60 * 60 * 24 * 14 // Only show the alert if within 14 days
    );
  }

  get otherParticipantFeedback() {
    const otherParticipantSharedNotes = this.notes?.find(
      n => n.user_id !== this.userId,
    );
    const otherParticipantSharedFeedback = this.feedback?.find(
      f => f.user_id !== this.userId,
    )?.feedback;
    return {
      notes:
        otherParticipantSharedNotes &&
        otherParticipantSharedNotes.shared &&
        otherParticipantSharedNotes.note.length > 0
          ? otherParticipantSharedNotes.note
          : false,
      feedback:
        otherParticipantSharedFeedback &&
        otherParticipantSharedFeedback.length > 0
          ? otherParticipantSharedFeedback
          : false,
    };
  }

  async saveFollowUp(
    type: 'ratings' | 'notes' | 'feedback' | 'missed_reason',
    toast: ToastIF,
  ): Promise<void> {
    const opts = (type => {
      switch (type) {
        case 'notes':
          return {
            note: this.notesText,
            shared: this.notesShared,
          };
        case 'ratings':
          return {
            rating: this.ratingValue,
            notes: this.ratingNotes,
          };
        case 'feedback':
          return {
            feedback: this.feedbackText,
          };
        case 'missed_reason':
          return {
            reason: this.missedFeedback,
          };
      }
    })(type);
    try {
      const obj = Object.assign({}, opts, { user_id: this.userId });
      await api.sessions.saveFollowUp(this.id, { [type]: obj });
      if (type === 'notes') {
        this.notesSavedAt = Date.now();
      }
      if (type === 'ratings') {
        this.ratingReadOnly = true;
      }
      if (type === 'feedback') {
        this.feedbackReadOnly = true;
      }

      const messages = {
        ratings: 'Session rating saved',
        notes: 'Session notes saved',
        feedback: 'Session feedback saved',
        missed_reason: 'Session missed reason saved',
      };
      toast.success(messages[type]);
    } catch (e) {
      toast.error('Could not save session information');
    }
  }
}
