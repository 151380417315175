import './PublicView.less';
import * as React from 'react';
import { observer } from 'mobx-react';
import CookieBot from 'react-cookiebot';
import { useStore } from '@src/models/Store';
import { usePageTitle } from '@src/navigation';
import { Survey } from '@src/components/common/Survey/Survey';
import { Spin } from '@src/components/sc-design-system';

interface PublicViewProps {
  component: any;
  title: string;
}

export let PublicView: React.FC<PublicViewProps> = ({
  component: Component,
  title,
}) => {
  const store = useStore();
  const [checkingSession, setCheckingSession] = React.useState(true);
  usePageTitle(title);

  // NOTE(zuko): during a refactor, I removed some routing logic from this
  // effect but kept the `store.checkSession` call since it has side effects.
  // Consider whether this can be removed entirely or otherwise improved.
  React.useEffect(() => {
    let cancelled = false;
    (async () => {
      await store.checkSession();
      if (!cancelled) {
        setCheckingSession(false);
      }
    })();
    return () => {
      cancelled = true;
    };
  }, [store, store.user]);

  return checkingSession ? (
    <Spin sectionLoader />
  ) : (
    <div className='public-container'>
      <Survey />
      <div className='public-view' role='main'>
        <Component store={store} />
        <CookieBot domainGroupId='1e448d5d-3e6f-4ba6-b969-4c1d5fd23a22' />
      </div>
    </div>
  );
};

PublicView = observer(PublicView);
