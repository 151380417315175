import React, { FC, useState, useEffect } from 'react';
import { Table, Input, Pagination } from 'antd';
import Program, { ProgramMember } from '@src/models/Program';
import { observer } from 'mobx-react';
import { Avatar, ConfirmDialog } from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import IconButton from '@mui/material/IconButton';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import { Divider } from '@mui/material';

const PAGE_LIMIT = 15;
interface UsersListIF {
  program: Program;
  lastLoad: number;
  loadList: boolean;
}

let currentGetReqTimeout: any;

const getList = async (
  params: object,
  program: Program,
  setProgramMembersList: Function,
  setProgramMemberListTotalRecords: Function,
  setLoading: Function,
): Promise<void> => {
  // Debounce to prevent keyword onchange from blasting the api
  if (currentGetReqTimeout) clearTimeout(currentGetReqTimeout);
  currentGetReqTimeout = setTimeout(async () => {
    const res = await program.getMembersList(params);
    setProgramMembersList(
      res.data && res.data.data
        ? res.data.data
            .sort((a, b) => (a.role > b.role ? 1 : -1))
            .map(m => new ProgramMember(m))
        : [],
    );

    setProgramMemberListTotalRecords(res.data.totalRecords);
    setLoading(false);
  }, 200);
};

const ProgramMembersList: FC<UsersListIF> = observer(
  ({ program, lastLoad, loadList }) => {
    const [openConfirmModal, setOpenConfirmModal] = React.useState<
      string | false
    >(false);
    const [keyword, setKeyword] = useState('');
    const [page, setPage] = useState(1);
    const [programMemberList, setProgramMemberList] = useState([]);
    const [programMemberListTotalRecords, setProgramMemberListTotalRecords] =
      useState(0);
    const [loading, setLoading] = useState(false);

    const removeMember = async (id: string) => {
      const programSessions = await api.sessions.getByProgram({
        programId: program.id,
      });
      const sessionsCompleted = programSessions.data.data.filter(
        ps =>
          ps.type === 'coaching' &&
          ps.current_status !== 'canceled' &&
          ps.members.find(sm => sm.id === id && sm.role === 'coachee'),
      );
      await program.removeMember(id, sessionsCompleted.length);
      setProgramMemberList(programMemberList.filter(m => m.id !== id));
      setProgramMemberListTotalRecords(programMemberListTotalRecords - 1);
    };

    useEffect(() => {
      setLoading(true);
      getList(
        { keyword, page },
        program,
        setProgramMemberList,
        setProgramMemberListTotalRecords,
        setLoading,
      );
    }, [program, keyword, page, lastLoad, loadList]);

    return (
      <>
        <Input
          size='middle'
          placeholder='Search...'
          defaultValue={keyword}
          allowClear={true}
          onChange={e => setKeyword(e.target.value)}
        />
        <Divider />
        <Table
          bordered
          loading={loading}
          pagination={false}
          dataSource={programMemberList.filter(m => !m.removedAt)}
          columns={[
            {
              width: 30,
              dataIndex: 'profile_image',
              key: 'avatar',
              align: 'center' as 'center',
              title: '',
              render: (text: string, programMember) => (
                <Avatar
                  srcId={programMember.id}
                  alt={`${programMember.first_name} ${programMember.last_name}`}
                />
              ),
            },
            {
              title: 'First Name',
              dataIndex: 'first_name',
              key: 'first_name',
            },
            {
              title: 'Last Name',
              dataIndex: 'last_name',
              key: 'last_name',
            },
            {
              title: 'Remove',
              key: 'remove',
              width: 30,
              align: 'center' as 'center',
              render: (text: string, programMember) => (
                <React.Fragment key={programMember.id}>
                  <ConfirmDialog
                    open={openConfirmModal === programMember.id}
                    setOpenModal={setOpenConfirmModal}
                    buttonProps={{
                      text: 'OK',
                      onClick: async () => removeMember(programMember.id),
                    }}
                    title='Remove Member'
                    body={`Are you sure you want to remove ${programMember.first_name} ${programMember.last_name}?`}
                  />
                  <IconButton
                    onClick={() => setOpenConfirmModal(programMember.id)}
                  >
                    <CancelOutlined color='error' />
                  </IconButton>
                </React.Fragment>
              ),
            },
          ]}
          locale={{ emptyText: 'No members found' }}
          rowKey={'id'}
        />
        <Pagination
          style={{ marginTop: '2em' }}
          pageSize={PAGE_LIMIT}
          current={+page}
          total={programMemberListTotalRecords}
          onChange={val => setPage(val)}
        />
      </>
    );
  },
);

export default ProgramMembersList;
