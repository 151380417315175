import React, { FC } from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Button, DialogTitle } from '@src/components/sc-design-system';
import GoalStatusIndicator from '@src/components/common/GoalCard/GoalStatusIndicator';

interface AlignedGoalsModalProps {
  open: boolean;
  cycle: GoalCycleWithGoals;
  allGoals: Goal[];
  handleClose(): void;
}

interface GoalUser {
  id: string;
  first_name: string;
  last_name: string;
}

const AllEmployeeGoalsModal: FC<AlignedGoalsModalProps> = ({
  open,
  cycle,
  allGoals,
  handleClose,
}) => {
  const allGoalsData = allGoals
    .map((g, i) => {
      g['key'] = i;
      return g;
    })
    .sort((a, b) => {
      const aUser = a.user_ids[0] as unknown as GoalUser;
      const bUser = b.user_ids[0] as unknown as GoalUser;
      return aUser.first_name > bUser.first_name
        ? 1
        : aUser.first_name === bUser.first_name
        ? 0
        : -1;
    });

  return (
    <Dialog maxWidth='lg' open={open} onClose={handleClose}>
      <DialogTitle
        title={
          <Typography variant='h3'>
            All Employee Goals for <strong>{cycle?.name}</strong>
          </Typography>
        }
        buttonOnClick={handleClose}
      />
      <DialogContent>
        <Typography variant='body2' sx={{ marginBottom: '2em' }}>
          The following goals have been added to the organizational goal cycle{' '}
          {cycle?.name} by your members.
        </Typography>
        <Table
          dataSource={allGoalsData}
          pagination={false}
          columns={[
            {
              title: 'Employee',
              dataIndex: 'user_ids',
              key: 'employee',
              render: users => `${users[0]?.first_name} ${users[0]?.last_name}`,
              width: '23%',
            },
            { title: 'Goal Name', dataIndex: 'title', key: 'goal-name' },
            {
              title: 'Status',
              dataIndex: 'current_status',
              key: 'status',
              render: (_, goal) => {
                const currentStatus =
                  goal.status_history?.[goal.status_history.length - 1];
                return <GoalStatusIndicator status={currentStatus?.status} />;
              },
            },
            {
              title: 'Target Date',
              dataIndex: 'target_date',
              key: 'target_date',
              render: date => dayjs(date).format('MM/DD/YYYY'),
              width: '15%',
            },
            ...(cycle?.goal_weighting_enabled
              ? [
                  {
                    title: 'Goal Weight',
                    key: 'goal_weight',
                    dataIndex: 'weight',
                    render: weight => (weight != null ? `${weight}%` : ''),
                  },
                ]
              : []),
            {
              title: 'Aligned with Company Goal',
              dataIndex: 'aligned_goal_id',
              key: 'id',
              render: alignedGoalId => {
                return (
                  cycle?.goals.filter(g => g.id === alignedGoalId)[0]?.title ||
                  '-'
                );
              },
            },
          ]}
          expandable={{
            expandedRowRender: record => (
              <div
                className='description'
                dangerouslySetInnerHTML={{ __html: record.description }}
              />
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button text='Close' onClick={handleClose} />
      </DialogActions>
    </Dialog>
  );
};

export default AllEmployeeGoalsModal;
