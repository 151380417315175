import React, { FC } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { api } from '@src/lib/client';
import { useFullScreen } from '@src/hooks/useFullScreen';
import { Button, DialogTitle } from '@src/components/sc-design-system';
import { SurveyWriteParams } from '@src/lib/client/apis/surveys';
import { CollectionSchema } from '../lib/schema';
import { SurveyCollectionType } from '@shared/schemas';

interface RetrySendModalProps {
  isModalOpen: boolean;
  onClose: Function;
  clientId?: string;
  clientName: string;
  surveyId: string;
  surveyName: string;
  collection: SurveyCollectionType;
  failedRecipients: any[];
}

const RetrySendModal: FC<RetrySendModalProps> = ({
  isModalOpen,
  onClose,
  clientId,
  clientName,
  surveyId,
  surveyName,
  collection,
  failedRecipients,
}) => {
  const queryClient = useQueryClient();
  const { fullScreen } = useFullScreen();

  const { mutateAsync: retrySend } = useMutation({
    mutationFn: async (data: {
      body: { recipients: CollectionSchema['recipients'] };
      params: SurveyWriteParams;
    }) => {
      const { body, params } = data;
      return api.surveys.remindRecipients(
        surveyId,
        clientId,
        collection.id,
        body,
        params,
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['survey', surveyId, 'collections'],
      });
      onClose();
    },
  });

  const handleSubmit = async () => {
    const data = {
      body: { recipients: failedRecipients },
      params: {
        surveyName,
        clientName,
      },
    };
    await retrySend(data);
  };

  return (
    <Dialog
      open={isModalOpen}
      maxWidth='sm'
      fullScreen={fullScreen}
      onClose={() => {
        onClose();
      }}
    >
      <DialogTitle title='An error occurred' />
      <DialogContent>
        <Stack spacing={2}>
          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            <InfoIcon fontSize='small' color='error' />
            <Typography color='error'>
              Failed to send survey to the following
            </Typography>
          </div>
          {failedRecipients.map(r => (
            <Stack key={r.id}>
              {/* TODO: update when Typography theme is integrated */}
              <Typography>
                <strong>
                  {r.first_name} {r.last_name}
                </strong>
              </Typography>
              <Typography>{r.email}</Typography>
            </Stack>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button text='Close' variant='outlined' onClick={() => onClose()} />
        <Button
          text='Try again'
          onClick={() => {
            handleSubmit();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export { RetrySendModal };
