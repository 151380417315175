import { useMediaQuery, useTheme } from '@mui/material';

const useFullScreen = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return { fullScreen };
};

export { useFullScreen };
