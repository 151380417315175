import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { Col, Input, Radio, Row, Space } from 'antd';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import {
  Button,
  useToast,
  DialogTitle,
} from '@src/components/sc-design-system';
import { CompetencySet } from '@src/models/CompetencySet';

const CompetencySetRatingModal: FC<{
  clientId: string;
  competencySet: CompetencySet;
  setRatingScaleModalOpen: Function;
}> = ({ clientId, competencySet, setRatingScaleModalOpen }) => {
  const toast = useToast();
  const createLabels = (max: number) => {
    return Array.from(Array(max), (_, i) => {
      const value = i + 1;
      const text =
        competencySet.range_labels?.find(rl => rl.value === value)?.text || '';
      return {
        value,
        text,
      };
    });
  };
  const [labels, setLabels] = useState(
    createLabels(competencySet.range_maximum || 10),
  );
  return (
    <Dialog open onClose={() => setRatingScaleModalOpen(false)}>
      <DialogTitle
        title={
          <Stack spacing={1}>
            <Typography variant='h3'>Create Rating Scale</Typography>
            <Typography variant='subtitle1'>
              {`Define the scale to be used in each survey where users will be asked
            to evaluate themselves or others on these ${
              competencySet.competency_label === 'value'
                ? 'values'
                : 'competencies'
            }. You can provide additional guidance for users
            when these are added to a survey.`}
            </Typography>
          </Stack>
        }
        buttonOnClick={() => setRatingScaleModalOpen(false)}
      />
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant='body1m'>Select Numeric Rating</Typography>
          <Radio.Group
            defaultValue={competencySet.range_maximum || 10}
            onChange={e => {
              competencySet.range_maximum = e.target.value;
              setLabels(createLabels(e.target.value));
            }}
          >
            <Radio value={4}>1-4 Scale</Radio>
            <Radio value={5}>1-5 Scale</Radio>
            <Radio value={10}>1-10 Scale</Radio>
          </Radio.Group>
        </Stack>
        <Divider />
        <Space direction='vertical'>
          <Typography variant='body1m'>Add Rating Labels</Typography>
          <Typography color='grey.700'>
            You can provide custom labels for each rating below or leave the
            labels blank to have a purely numeric scale.
          </Typography>
          {labels.map((_, index) => {
            const label = labels[index];
            const adjacentLabel = labels[index + 5];
            return (
              label.value <= 5 && (
                <Row key={index}>
                  {label.value <= 5 && (
                    <Col span={labels.length > 5 ? 12 : 24}>
                      <Input
                        addonBefore={label.value}
                        defaultValue={label.text}
                        onChange={e => {
                          label.text = e.target.value;
                        }}
                      />
                    </Col>
                  )}
                  {/* Column Break */}
                  {adjacentLabel?.value > 5 &&
                    adjacentLabel?.value <= 10 &&
                    labels.length > 5 && (
                      <Col span={12}>
                        <Input
                          addonBefore={adjacentLabel.value}
                          defaultValue={adjacentLabel.text}
                          onChange={e => {
                            adjacentLabel.text = e.target.value;
                          }}
                        />
                      </Col>
                    )}
                </Row>
              )
            );
          })}
        </Space>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          onClick={() => setRatingScaleModalOpen(false)}
          text='Cancel'
        />
        <Button
          text='Create'
          onClick={async () => {
            if (
              labels.some(l => l.text.length) &&
              !labels.every(l => l.text.length)
            ) {
              toast.error(
                'All rating labels must have values or all must be left blank',
              );
              return;
            }
            competencySet.range_labels = labels;
            await competencySet.save(clientId);
            setRatingScaleModalOpen(false);
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default observer(CompetencySetRatingModal);
