import React, { FC, FocusEvent, ReactElement } from 'react';
import { Form, Select, SelectProps } from 'antd';
import { Typography } from '@mui/material';

interface OptionIF {
  label: string | ReactElement;
  value: any;
}

interface OptionGroupIF {
  label: string;
  options: OptionIF[];
}

export interface SelectFieldPropsIF {
  name?: string | string[];
  required?: boolean;
  label?: string | ReactElement;
  placeholder?: string;
  multiple?: boolean;
  value: any | any[];
  rules?: Array<any>;
  validateStatus?: any;
  rows?: number;
  options: OptionIF[] | OptionGroupIF[];
  onChange: Function;
  onKeyPress?: Function;
  onFocus?: Function;
  onBlur?: Function;
  autoFocus?: boolean;
  search?: boolean;
  style?: any;
  disabled?: boolean;
  optionFilterProp?: string;
  loading?: boolean;
  tagRender?: any;
  mode?: any;
  defaultValue?: any;
  allowClear?: boolean;
  hasFeedback?: boolean;
  initialValue?: any | any[];
  onDropdownVisibleChange?: SelectProps['onDropdownVisibleChange'];
}

const SelectField: FC<SelectFieldPropsIF> = props => {
  const {
    name,
    label,
    placeholder,
    value,
    rules,
    validateStatus,
    multiple,
    options,
    onChange,
    onBlur,
    onFocus,
    search = true,
    style,
    disabled,
    optionFilterProp,
    loading = false,
    tagRender,
    mode,
    defaultValue,
    allowClear = false,
    hasFeedback = true,
    initialValue,
    onDropdownVisibleChange,
  } = props;
  return (
    <div style={{ width: '100%' }} className='form-select-field'>
      <Form.Item
        label={label ? label : undefined}
        name={name || label?.toString()}
        rules={rules || []}
        style={{ width: '100%' }}
        help={
          <Typography variant='body2' color='error'>
            {validateStatus}
          </Typography>
        }
        hasFeedback={hasFeedback}
        validateTrigger={['onChange', 'onBlur']}
        initialValue={initialValue}
      >
        <Select
          value={value}
          tagRender={tagRender}
          loading={loading}
          defaultValue={defaultValue}
          disabled={disabled}
          showSearch={search}
          style={Object.assign({}, { width: '100%' }, style || {})}
          mode={multiple ? 'multiple' : mode}
          onFocus={(e: FocusEvent) => onFocus && onFocus(e)}
          onBlur={(e: FocusEvent) => onBlur && onBlur(e)}
          placeholder={placeholder || label}
          // FIXME: options still works at runtime but our interface doesn't
          // align with what antd expects. Fix that.
          // @ts-expect-error
          options={options}
          onChange={(e: any) => {
            onChange(e);
          }}
          notFoundContent={
            <Typography variant='body2' sx={{ textAlign: 'center' }}>
              No results found
            </Typography>
          }
          optionFilterProp={optionFilterProp}
          allowClear={allowClear}
          onDropdownVisibleChange={onDropdownVisibleChange}
        />
      </Form.Item>
    </div>
  );
};

export { SelectField };
