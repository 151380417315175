import React, { FC } from 'react';
import startCase from 'lodash/startCase';
import DialogActions from '@mui/material/DialogActions';
import { Dialog, DialogContent } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFullScreen } from '@src/hooks/useFullScreen';
import { Button, DialogTitle } from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import Client from '@src/models/Client';
import {
  useSchemaResolver,
  addressSchema,
} from '@src/components/common/AddressFormGrid/schema';
import { AddressFormGrid } from '@src/components/common/AddressFormGrid';

interface AddressFormModalProps {
  isOpen: boolean;
  onClose: Function;
  currentAddress: Address;
  client: Client;
}

const AddressFormModal: FC<AddressFormModalProps> = ({
  isOpen,
  onClose,
  currentAddress,
  client,
}) => {
  const queryClient = useQueryClient();
  const { fullScreen } = useFullScreen();

  const form = useForm({
    defaultValues: {
      addresses: client.data.addresses,
    },
    resolver: useSchemaResolver(addressSchema),
  });
  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = form;

  const { mutate: updateAddresses, isPending } = useMutation({
    mutationFn: async (updates: Address[]) => {
      const addresses = updates.map(a => {
        return Object.entries(a).reduce((acc, [key, value]) => {
          if (value) {
            acc[key] = value;
          }
          return acc;
        }, {});
      });
      return api.clients.saveClient({
        id: client.id,
        addresses,
      });
    },
    onSuccess: ({ data: updatedClient }) => {
      queryClient.setQueryData(
        ['client', client.id],
        new Client(updatedClient),
      );
      onClose();
    },
  });

  const onSubmit = d => {
    updateAddresses(d.addresses.map(({ id, ...a }) => a));
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        if (isPending) return;
        reset();
        onClose();
      }}
      fullScreen={fullScreen}
    >
      <DialogTitle
        title={`${startCase(currentAddress?.type)} Address`}
        buttonOnClick={() => {
          reset();
          onClose();
        }}
        buttonIsDisabled={isPending}
      />
      <DialogContent>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} id='main'>
            <AddressFormGrid
              currentAddressType={currentAddress?.type}
              isSubmitting={isPending}
            />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          text='Close'
          variant='outlined'
          disabled={isPending}
          onClick={() => {
            reset();
            onClose();
          }}
        />
        <Button
          text='Save'
          disabled={isPending || !isValid || !isDirty}
          loading={isPending}
          type='submit'
          form='main'
        />
      </DialogActions>
    </Dialog>
  );
};

export { AddressFormModal };
