import React, { useState, FC } from 'react';
import { observer } from 'mobx-react';
import { Link, DialogActions } from '@mui/material';
import { Button, useToast } from '@src/components/sc-design-system';
import { StateAnswer } from '../Survey';
import { evaluateQuestion } from '../utils/conditions';
import { SurveyQuestionType } from '@shared/schemas';

import './SurveyActions.less';

interface QuestionGroupType {
  title: string;
  helpText?: string;
  questions: any[];
}

interface UISurveyQuestionType extends SurveyQuestionType {
  group?: QuestionGroupType;
}

const getPrevQuestion = (
  currentQuestion: UISurveyQuestionType,
  questions: UISurveyQuestionType[],
  answers: StateAnswer[],
) => {
  let curIndex = questions.findIndex(q => q === currentQuestion);
  if (curIndex === 0) {
    return false;
  }
  for (let i = curIndex - 1; i >= 0; i--) {
    if (evaluateQuestion(questions[i], answers)) {
      if (questions[i]?.group) {
        const showQuGroup = questions[i].group.questions.reduce(
          (acc = 0, q) => {
            const showQu = evaluateQuestion(q, answers);
            showQu ? acc++ : acc;
            return acc;
          },
          0,
        );

        if (showQuGroup !== 0) {
          return questions[i];
        }
      } else {
        return questions[i];
      }
    }
  }
};

const getNextQuestion = (
  currentQuestion: UISurveyQuestionType,
  questions: UISurveyQuestionType[],
  answers: StateAnswer[],
) => {
  let curIndex = questions.findIndex(q => q === currentQuestion);

  if (questions.length - 1 === curIndex) {
    return false;
  }
  for (let i = curIndex + 1; i < questions.length; i++) {
    if (evaluateQuestion(questions[i], answers)) {
      if (questions[i]?.group) {
        const showCurrentQuGroup = questions[i].group.questions.reduce(
          (acc = 0, q) => {
            const showQu = evaluateQuestion(q, answers);
            showQu ? acc++ : acc;
            return acc;
          },
          0,
        );

        if (showCurrentQuGroup !== 0) {
          return questions[i];
        }
      } else {
        return questions[i];
      }
    }
  }
};

const handleSubmit = async (
  questions: UISurveyQuestionType[],
  answers: StateAnswer[],
  toast,
  onSubmit?: Function,
  isDraft = false,
) => {
  // Deep copy the answers array so that if something fails after question_id
  // is removed from each object in the array, we can still reference the original array on subsequent attempts.
  // Not doing this causes answer to be undefined on subsequent attempts and wipes out all answers but the last
  const answersWithoutQuestionId = JSON.parse(JSON.stringify(answers));
  const final = questions.reduce((acc, question: UISurveyQuestionType) => {
    if (question.type === 'message') return acc;
    // Sanitization to ensure all conditions are met on all responses
    if (evaluateQuestion(question, answers)) {
      if (question.group) {
        question.group.questions.forEach(q => {
          const answer = answersWithoutQuestionId.find(
            a => a.question_id === q.id,
          );

          if (answer && evaluateQuestion(question, answers)) {
            delete answer.question_id;
            acc.push(Object.assign({}, { question: q }, answer));
          } else if (!answer && question.required === false) {
            acc.push(Object.assign({}, { question: q }, { answer: null }));
          }
        });
      } else {
        const answer = answersWithoutQuestionId.find(
          a => a.question_id === question.id,
        );

        if (answer) {
          delete answer.question_id;
          acc.push(Object.assign({}, { question }, answer));
        } else if (!answer && question.required === false) {
          acc.push(Object.assign({}, { question }, { answer: null }));
        }
      }
    }
    return acc;
  }, []);
  try {
    return onSubmit && onSubmit(final, isDraft);
  } catch (e) {
    toast.error('There was an error submitting the survey');
  }
};

const isValidAndCanProceed = (
  question: UISurveyQuestionType,
  answers: StateAnswer[],
) => {
  if (question?.type === 'message' || question?.required === false) return true;
  if (
    (question?.type === 'single-select' || question?.type === 'multi-select') &&
    answers.find(a => a.question_id === question.id)?.answer === 'other'
  ) {
    if (
      !question?.other_answer_required ||
      answers.find(a => a.question_id === question.id)?.other_value
    ) {
      return true;
    } else {
      return false;
    }
  }

  const validConditions = answer =>
    Boolean(Array.isArray(answer) ? answer.length > 0 : answer != null);

  if (question?.group) {
    return question.group.questions.reduce((acc, q, idx) => {
      const valid =
        evaluateQuestion(q, answers) && q.required !== false // If required is undefined, assume it IS required.
          ? validConditions(answers.find(a => a.question_id === q.id)?.answer)
          : true;

      return idx > 0 && acc === false ? false : valid;
    }, false);
  } else
    return validConditions(
      answers.find(a => a.question_id === question.id)?.answer,
    );
};

export const SurveyActions: FC<{
  currentQuestion: UISurveyQuestionType;
  setCurrentQuestion: Function;
  questions: UISurveyQuestionType[];
  answers: StateAnswer[];
  onSubmit?: Function;
  topOfSurveyRef;
  allowDraft: boolean;
}> = observer(
  ({
    currentQuestion,
    setCurrentQuestion,
    questions,
    answers,
    onSubmit,
    topOfSurveyRef,
    allowDraft,
  }) => {
    const toast = useToast();
    const previousQuestion = getPrevQuestion(
      currentQuestion,
      questions,
      answers,
    );
    const [isNextDisabled, setIsNextDisabled] = useState<boolean>(
      !!previousQuestion,
    );
    const nextQuestion = getNextQuestion(currentQuestion, questions, answers);
    const [submitting, setIsSubmitting] = useState<boolean>(false);
    const [isDraftSaving, setIsDraftSaving] = useState<boolean>(false);
    React.useEffect(
      function () {
        topOfSurveyRef.current.scrollIntoView({
          block: 'start',
          inline: 'nearest',
        });
      },
      [nextQuestion, topOfSurveyRef],
    );
    const showSaveForLaterButton = !!previousQuestion && allowDraft;

    React.useLayoutEffect(() => {
      setIsNextDisabled(!isValidAndCanProceed(currentQuestion, answers));
    }, [currentQuestion, answers]);

    return (
      <DialogActions
        sx={showSaveForLaterButton ? null : { justifyContent: 'center' }}
      >
        {showSaveForLaterButton && (
          <Link
            variant='a2'
            sx={{ marginRight: 'auto' }}
            onClick={async () => {
              if (submitting || isDraftSaving) return;
              setIsDraftSaving(true);
              await handleSubmit(questions, answers, toast, onSubmit, true);
              setIsDraftSaving(false);
            }}
          >
            Save for later
          </Link>
        )}
        {previousQuestion && (
          <Button
            onClick={e => {
              setCurrentQuestion(previousQuestion);
              e.currentTarget.blur();
            }}
            disabled={!previousQuestion}
            text='Back'
            variant='outlined'
          />
        )}
        <Button
          disabled={isNextDisabled || submitting}
          loading={submitting}
          onClick={async e => {
            if (nextQuestion) {
              setCurrentQuestion(nextQuestion);
              e.currentTarget.blur();
              return;
            }
            setIsSubmitting(true);
            await handleSubmit(questions, answers, toast, onSubmit);
            setIsSubmitting(false);
          }}
          text={!nextQuestion ? 'Submit' : previousQuestion ? 'Next' : 'Start'}
        />
      </DialogActions>
    );
  },
);
