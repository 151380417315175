import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';

obey.creator('uuid', () => uuidv4());
obey.creator('now', () => Date.now());
obey.creator('90d', () => Date.now() + 1000 * 60 * 60 * 24 * 90);

export const ProgramSchema = {
  id: { type: 'string', creator: 'uuid' },
  sow_id: { type: 'string', required: true },
  client_id: { type: 'string', required: true },
  name: { type: 'string', required: true },
  description: { type: 'string', empty: true },
  type: {
    type: 'string',
    allow: [
      'standard_coaching',
      'custom_coach_selection',
      'mentoring',
      'platform_only',
    ],
    default: 'standard_coaching',
  },
  sessions_reserved: { type: 'number', default: 0 },
  sessions_per_member: { type: 'number', default: 0 },
  session_rate: { type: 'number', default: 0 },
  min_interviews: { type: 'number', default: 0 },
  max_interviews: { type: 'number', default: 0 },
  start_date: { type: 'number', creator: 'now' },
  end_date: { type: 'number', creator: '90d' },
  is_active: { type: 'boolean', default: true },
  members: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        id: { type: 'string', required: true },
        role: {
          type: 'string',
          allow: ['manager', 'member'],
          default: 'member',
        },
        joinedAt: { type: 'number' },
        removedAt: { type: 'number' },
        selected_coach: { type: 'string', allowNull: true },
        selected_coach_at: { type: 'number' },
        sessions_allocated: { type: 'number', required: true, default: 0 },
        self_assessment: {
          type: 'object',
          keys: {
            survey_submission_id: { type: 'string' },
          },
        },
        assessment_stakeholders: {
          type: 'array',
          values: {
            type: 'object',
            keys: {
              id: { type: 'string', empty: true },
              email: { type: 'string' },
              added: { type: 'number' },
              sent: { type: 'number' },
              sent_reminder: { type: 'number' },
              survey_submission_id: { type: 'string' },
              role: { type: 'string' },
              name: { type: 'string', empty: true },
            },
          },
          empty: true,
          default: [],
        },
      },
    },
    empty: true,
    default: [],
  },
  mentors: {
    type: 'array',
    values: { type: 'string' },
    empty: true,
    default: [],
  },
  coaches: {
    type: 'array',
    values: { type: 'string' },
    empty: true,
    default: [],
  },
  completedSessions: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        session_id: { type: 'string' },
        member_id: { type: 'string' },
        timestamp: { type: 'number', creator: 'now' },
      },
    },
    empty: true,
    default: [],
  },
  allowed_durations: {
    type: 'array',
    values: { type: 'number', allow: [30, 60] },
    default: [60],
  },
  stakeholders: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        stakeholder_first_name: { type: 'string' },
        stakeholder_last_name: { type: 'string' },
        stakeholder_email: { type: 'string' },
      },
    },
    empty: true,
  },
  invitees: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        invitee_first_name: { type: 'string', empty: true },
        invitee_last_name: { type: 'string', empty: true },
        invitee_email: { type: 'string' },
        invitedAt: { type: 'number' },
        revokedAt: { type: 'number' },
        joinedAt: { type: 'number' },
      },
    },
    empty: true,
  },
  surveys: {
    type: 'object',
    keys: {
      intake: {
        type: 'object',
        keys: {
          id: { type: 'string' },
          enabled: { type: 'boolean' },
        },
      },
      midpoint_engagement: {
        type: 'object',
        keys: {
          id: { type: 'string' },
          enabled: { type: 'boolean' },
        },
      },
      final_engagement: {
        type: 'object',
        keys: {
          id: { type: 'string' },
          enabled: { type: 'boolean' },
        },
      },
      '360_self': {
        type: 'object',
        keys: {
          id: { type: 'string' },
          enabled: { type: 'boolean' },
        },
      },
      '360_stakeholder': {
        type: 'object',
        keys: {
          id: { type: 'string' },
          enabled: { type: 'boolean' },
        },
      },
    },
  },
};

// eslint-disable-next-line no-unused-vars
export type ProgramType = { [K in keyof typeof ProgramSchema]?: any };

export const ProgramModel = obey.model(ProgramSchema);
