import React, { FC, MouseEventHandler } from 'react';
import { Button } from '@src/components/sc-design-system';
import { handleKeyboardNav } from '@src/utils';
import Add from '@mui/icons-material/Add';

export interface IGrid {
  data: any[];
  renderer: Function;
  cols?: 1 | 2 | 3 | 4 | 5;
  isSelectable?: boolean;
  onSelectItem?: Function;
  selectedItem?: number | null;
  className?: string;
  addItemLabel?: string;
  onClickAddItem?: Function;
}

const Grid: FC<IGrid> = ({
  data,
  renderer,
  cols = 2,
  isSelectable,
  onSelectItem,
  selectedItem,
  addItemLabel,
  onClickAddItem,
  className = '',
}) => {
  return (
    <div className={`sc-grid col-${cols} ${className}`}>
      {data?.map((item, idx) => (
        <div
          role={isSelectable && onSelectItem ? 'button' : null}
          tabIndex={isSelectable && onSelectItem ? 0 : null}
          key={idx}
          className={`sc-grid-item ${
            isSelectable
              ? `selectable ${selectedItem === idx ? 'selected' : ''}`
              : ''
          }`}
          onClick={
            isSelectable && onSelectItem ? () => onSelectItem(item, idx) : null
          }
          onKeyDown={
            isSelectable && onSelectItem
              ? e => handleKeyboardNav(e.key, () => onSelectItem(item, idx))
              : null
          }
        >
          {renderer(item)}
          {isSelectable && (
            <div
              className={`grid-item-radio ${
                selectedItem === idx ? 'selected' : ''
              }`}
            >
              {selectedItem === idx && <div className='selected-indicator' />}
            </div>
          )}
        </div>
      ))}
      {addItemLabel && onClickAddItem && (
        <div className={`sc-grid-item`}>
          <GridAddItem onClick={() => onClickAddItem()} label={addItemLabel} />
        </div>
      )}
    </div>
  );
};

const GridAddItem: FC<{
  onClick: MouseEventHandler<HTMLButtonElement>;
  label: string;
}> = ({ onClick, label }) => (
  <div className='add-grid-item'>
    <Button onClick={onClick} variant='text' startIcon={<Add />} text={label} />
  </div>
);

export { Grid, GridAddItem };
