import React from 'react';
import { observer } from 'mobx-react';
import { EChart, Spin } from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import { Message } from './components';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';

function CompetencyFeedback(props: CompetencyFeedbackProps) {
  function Feedback({
    competencySetFeedback,
  }: {
    competencySetFeedback: StakeholderFeedbackPayload;
  }) {
    function generateDataSource(title, answers) {
      return {
        title,
        description: (
          <ul>
            {answers.map((a, index) => (
              <li key={index}>{a}</li>
            ))}
          </ul>
        ),
      };
    }

    const data = [];
    if (competencySetFeedback.selfAnswers.length > 0) {
      data.push(generateDataSource('Self', competencySetFeedback.selfAnswers));
    }
    if (competencySetFeedback.stakeholderAnswers.length > 0) {
      data.push(
        generateDataSource(
          'Stakeholder',
          competencySetFeedback.stakeholderAnswers,
        ),
      );
    }

    return (
      <List>
        {data.map((item, index) => (
          <ListItem key={index}>
            <ListItemText
              primaryTypographyProps={{ component: 'p' }}
              secondaryTypographyProps={{
                component: 'div',
                sx: { color: 'grey.700' },
              }}
              primary={item.title}
              secondary={item.description}
            />
          </ListItem>
        ))}
      </List>
    );
  }

  const feedbackForCompetencySets =
    props.stakeholderFeedback[props.competencySetId];

  if (!feedbackForCompetencySets) return <></>;
  return (
    <>
      {feedbackForCompetencySets.map((competencySetFeedback, index) => (
        <React.Fragment key={index}>
          {(competencySetFeedback.selfAnswers.length > 0 ||
            competencySetFeedback.stakeholderAnswers.length > 0) && (
            <>
              <Typography variant='body1'>
                <strong>{competencySetFeedback.title}</strong>
              </Typography>
              <div style={{ marginLeft: '25px' }}>
                <Feedback competencySetFeedback={competencySetFeedback} />
              </div>
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );
}

const ReportCompetencies = (props: ReportCompetenciesProps) => {
  const {
    clientId,
    programId,
    userId,
    isLoadingGet360ReportData,
    isLoadingAggregateCompetencyResults,
    setIsLoadingAggregateCompetencyResults,
    stakeholderFeedback,
  } = props;
  const [results, setResults] = React.useState([]);

  React.useEffect(() => {
    try {
      const getHeatmapOptions = async () => {
        const res = await api.surveys.getAggregateCompetencyResults(
          clientId,
          programId,
          userId,
        );
        setIsLoadingAggregateCompetencyResults(false);
        if (res.data.length && res.data.length > 0) {
          setResults(res.data);
        }
      };
      if (clientId && programId && userId) getHeatmapOptions();
    } catch (error) {
      setIsLoadingAggregateCompetencyResults('error');
    }
  }, [clientId, programId, userId, setIsLoadingAggregateCompetencyResults]);

  return (
    <>
      <Typography variant='h3'>Competencies</Typography>
      <Divider />
      {((typeof isLoadingGet360ReportData === 'boolean' &&
        isLoadingGet360ReportData === true) ||
        (typeof isLoadingAggregateCompetencyResults === 'boolean' &&
          isLoadingAggregateCompetencyResults === true)) && (
        <Spin sectionLoader />
      )}
      {(isLoadingGet360ReportData === 'error' ||
        isLoadingAggregateCompetencyResults === 'error') && (
        <Message type='error' />
      )}
      {!isLoadingGet360ReportData &&
        !isLoadingAggregateCompetencyResults &&
        results.length === 0 && (
          <Message type='aggregateCompetencyResultsEmpty' />
        )}
      {!isLoadingGet360ReportData &&
        !isLoadingAggregateCompetencyResults &&
        results.length > 0 &&
        results.map(
          (result, index) =>
            result.options && (
              <React.Fragment key={index}>
                {result.title && (
                  <Typography variant='h5'>{result.title}</Typography>
                )}
                <EChart
                  props={{
                    width: '100%',
                    options: result.options,
                    chartType: result.chartType,
                  }}
                />
                <CompetencyFeedback
                  key={`cf_${index}`}
                  competencySetId={result.competencySetId}
                  stakeholderFeedback={stakeholderFeedback}
                />
              </React.Fragment>
            ),
        )}
    </>
  );
};

export default observer(ReportCompetencies);
