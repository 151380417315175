import React, { useState, FC } from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { useIntercom } from 'react-use-intercom';
import { useNavigate } from 'react-router';
import type { NotificationPlacement } from 'antd/es/notification';
import { InlineWidget } from 'react-calendly';
import { Col, Row, notification } from 'antd';
import {
  Link,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
} from '@mui/material';
import {
  Button,
  Stamp,
  MenuButton,
  Spin,
  ConfirmDialog,
} from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import { CoachViewModal } from '@src/components/common/CoachViewModal';
import './UpNextCard.less';
import { useUser } from '@src/hooks/useUser';
import { useQueryClient } from '@tanstack/react-query';

const typeIcons = {
  default: {
    name: 'notification',
    color: 'orange',
  },
  no_program: {
    name: 'notification',
    color: 'orange',
  },
  no_goals: {
    name: 'goal',
    color: 'pink',
  },
  select_coach: {
    name: 'userSearch',
    color: 'green',
  },
  low_stakeholders: {
    name: 'group',
    color: 'pink',
  },
  self_assessment: {
    name: 'clipboard',
    color: 'orange',
  },
  open_sessions: {
    name: 'plus',
    color: 'green',
  },
  done: {
    name: 'notification',
    color: 'green',
  },
  session: {
    name: 'calendar',
    color: 'green',
  },
  survey: {
    name: 'edit',
    color: 'orange',
  },
  performance_cycle_evaluation: {
    name: 'clipboard',
    color: 'orange',
  },
};

const openNotification = (
  title,
  content?,
  placement?: NotificationPlacement,
  action = 'open',
) => {
  notification[action]({ message: title, description: content, placement });
};

const ActionComponent: FC<{ data; user; setLoading }> = ({
  data,
  user,
  setLoading,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [menuKey, setMenuKey] = useState(undefined);
  const [showCoachViewModal, setShowCoachViewModal] = useState(false);
  const [secondaryAction, setSecondaryAction] = useState('');
  const [showConfirmDismissModal, setShowConfirmDismissModal] = useState(false);
  const intercom = useIntercom();

  const role =
    window.location.pathname.includes('my-coachees') ||
    window.location.pathname.includes('my-mentees')
      ? 'coach'
      : 'coachee';

  const handleDismiss = async () => {
    setLoading(true);
    await api.notifications.dismissNotification(data.key);
    queryClient.invalidateQueries({
      queryKey: ['user', user.id, 'programs', role],
    });
    queryClient.invalidateQueries({
      queryKey: ['user', user.id, 'upNext', role],
    });
    setLoading(false);
  };

  const onClickSecondaryAction = async key => {
    const now = Date.now();
    const hour = 60 * 60 * 1000;
    const sessionBeginsInLessThan24Hours =
      data.meta.startTime - now <= hour * 24;
    const sessionBeginsInMoreThan1Hour = data.meta.startTime - now >= hour;
    const sessionBeginsInLessThan1Hour = data.meta.startTime - now <= hour;
    const sessionBeginsInMoreThan1HourButLessThan24 =
      sessionBeginsInLessThan24Hours && sessionBeginsInMoreThan1Hour;

    if (key === 'cancel' || key === 'reschedule') {
      if (
        window.location.pathname.includes('my-programs') &&
        sessionBeginsInLessThan24Hours
      ) {
        openNotification(
          `SkillCycle policy does not allow ${
            key === 'reschedule' ? 'rescheduling' : 'cancelation'
          } of sessions inside of 24 hours.`,
          <div>
            Please contact{' '}
            <Link variant='a1' onClick={() => intercom.show()}>
              {process.env.SUPPORT_TEAM_EMAIL}
            </Link>{' '}
            for additional help.
          </div>,
          'top',
          'warn',
        );
        return;
      }
      if (sessionBeginsInLessThan1Hour) {
        openNotification(
          `SkillCycle policy does not allow ${
            key === 'reschedule' ? 'rescheduling' : 'cancelation'
          } of sessions inside of 1 hour.`,
          <div>
            Please contact{' '}
            <Link variant='a1' onClick={() => intercom.show()}>
              {process.env.SUPPORT_TEAM_EMAIL}
            </Link>{' '}
            for additional help.
          </div>,
          'top',
          'warn',
        );
        return;
      } else if (sessionBeginsInMoreThan1HourButLessThan24) {
        setMenuKey(key);
        return;
      }
      setShowModal(true);
      setSecondaryAction(key);
    }
    if (key === 'dismiss') {
      if (data.meta.dismissal_confirmation_message) {
        setShowConfirmDismissModal(true);
      } else {
        handleDismiss();
      }
    }
  };
  return (
    <div style={{ width: '100%' }}>
      {data.type === 'select_coach' ? (
        <Button
          fullWidth
          onClick={() => navigate(data.meta.coachSearch)}
          text={data.primary_action}
        />
      ) : ['no_program', 'done'].includes(data.type) ? (
        <Button
          fullWidth
          onClick={() => intercom.show()}
          text={data.primary_action}
        />
      ) : ['low_stakeholders', 'self_assessment', 'no_goals'].includes(
          data.type,
        ) ? (
        <Button
          fullWidth
          onClick={() => {
            navigate(data.meta.url);
          }}
          text={data.primary_action}
        />
      ) : data.type === 'open_sessions' ? (
        <Button
          fullWidth
          onClick={() => setShowCoachViewModal(true)}
          text={data.primary_action}
        />
      ) : data.type === 'performance_cycle_evaluation' ? (
        <Button
          fullWidth
          onClick={() =>
            navigate(
              `/performance-and-growth?pc=${data.meta.performance_cycle_id}`,
            )
          }
          text={data.primary_action}
        />
      ) : (
        <MenuButton
          fullWidth
          primaryActionText={data.primary_action}
          onClickPrimaryAction={() => {
            if (data.type === 'survey') {
              navigate(data.meta.surveyLink);
            }
            if (data.type === 'session') {
              const canStartAt = data.meta.startTime - 1000 * 60 * 15;
              const diff = canStartAt - Date.now();
              if (diff >= 0) {
                const minutes = Math.ceil(diff / (1000 * 60));
                const noun = minutes === 1 ? 'minute' : 'minutes';
                openNotification(
                  `You can join your session ${
                    minutes > 15
                      ? '15 minutes before the scheduled start time'
                      : `in ${minutes} ${noun}`
                  }`,
                  '',
                  'top',
                  'info',
                );
              } else {
                navigate(`/sessions/${data.key}`);
              }
            }
          }}
          secondaryActions={data.secondary_actions}
          onClickSecondaryAction={onClickSecondaryAction}
        />
      )}
      {showCoachViewModal && (
        <CoachViewModal
          coach={Object.assign({}, data.meta.coach, {
            association: 'selected',
          })}
          isBooking={true}
          bookingProgram={Object.assign({}, data.meta.program, {
            coach: data.meta.coach,
          })}
          lastRerender={Date.now()}
          onClose={async () => {
            setLoading(true);
            setShowCoachViewModal(false);
            queryClient.invalidateQueries({
              queryKey: ['user', user.id, 'programs', 'coachee'],
            });
            queryClient.invalidateQueries({
              queryKey: ['user', user.id, 'upNext', role],
            });
            setLoading(false);
          }}
        />
      )}
      {showConfirmDismissModal && (
        <ConfirmDialog
          open={showConfirmDismissModal}
          setOpenModal={setShowConfirmDismissModal}
          title='Dismiss Notification?'
          body={data.meta.dismissal_confirmation_message}
          buttonProps={{
            text: 'Dismiss',
            onClick: async () => {
              await handleDismiss();
            },
          }}
        />
      )}
      <Dialog
        open={
          !window.location.pathname.includes('my-programs') && menuKey
            ? true
            : false
        }
        maxWidth='sm'
        onClose={() => setMenuKey(undefined)}
      >
        <DialogContent>
          <Typography>
            Remember, SkillCycle has a 24 hour cancellation policy. Please
            confirm you have communicated and confirmed this change with your{' '}
            {window.location.pathname.includes('my-coachees')
              ? 'coachee'
              : 'mentee'}
            .
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            text='Confirm'
            onClick={() => {
              setMenuKey(undefined);
              setShowModal(true);
              setSecondaryAction(menuKey);
            }}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        open={showModal}
        onClose={async () => {
          setLoading(true);
          setShowModal(false);
          queryClient.invalidateQueries({
            queryKey: ['user', user.id, 'programs', role],
          });
          queryClient.invalidateQueries({
            queryKey: ['user', user.id, 'upNext', role],
          });
          setLoading(false);
        }}
      >
        <DialogContent>
          {secondaryAction === 'cancel' && (
            <InlineWidget url={data.meta.cancelLink} />
          )}
          {secondaryAction === 'reschedule' && (
            <InlineWidget url={data.meta.rescheduleLink} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              setLoading(true);
              setShowModal(false);
              queryClient.invalidateQueries({
                queryKey: ['user', user.id, 'programs', role],
              });
              queryClient.invalidateQueries({
                queryKey: ['user', user.id, 'upNext', role],
              });
              setLoading(false);
            }}
            text='Close'
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

const UpNextCard: FC<{
  scRole: 'coach' | 'coachee';
}> = observer(({ scRole }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { user, useGetUpNext } = useUser();
  const { upNext, isLoading: isUpNextLoading } = useGetUpNext(scRole);

  return (
    <div className='up-next-card'>
      {isLoading || isUpNextLoading ? (
        <Spin sectionLoader />
      ) : upNext?.length ? (
        upNext.map((uN, idx) => (
          <span key={`${uN.key}-${idx}`}>
            <Row className='up-next-row' key={`${uN.key}-${idx}`}>
              <Col
                lg={{ span: 2 }}
                md={{ span: 2 }}
                xxl={{ span: 2 }}
                className='icon-col'
              >
                <div className='icon-col-container'>
                  <div className='inner-container'>
                    {uN.type === 'session' ? (
                      <div className='sc-icon'>
                        <div className='session-calendar-icon'>
                          <div className='sc-icon-month'>
                            {dayjs(uN.meta.startTime).format('MMM')}
                          </div>
                          <div className='sc-icon-day'>
                            {dayjs(uN.meta.startTime).format('D')}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='icon-stamp'>
                        <Stamp
                          name={(typeIcons[uN.type] || typeIcons.default)?.name}
                          color={
                            (typeIcons[uN.type] || typeIcons.default)?.color
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 16 }}
                xxl={{ span: 18 }}
                className='title-meta-col'
              >
                <div className='title-meta-col-div'>
                  <Typography variant='h3'>{uN.title}</Typography>
                  <Typography
                    variant='body1'
                    color='grey.700'
                    sx={{ paddingLeft: '0.1em', marginTop: '0.3em' }}
                  >
                    {uN.sub_title}
                  </Typography>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
                xxl={{ span: 4 }}
                className='action-col'
              >
                <ActionComponent
                  data={uN}
                  user={user}
                  setLoading={setIsLoading}
                />
              </Col>
            </Row>
            {idx < upNext?.length - 1 && <Divider />}
          </span>
        ))
      ) : (
        <Typography
          variant='body2'
          color='grey.700'
          sx={{ textAlign: 'center' }}
        >
          Nothing upcoming
        </Typography>
      )}
    </div>
  );
});

export { UpNextCard };
