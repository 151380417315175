import ReactGA from 'react-ga4';
import { autorun } from 'mobx';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import Store from '@src/models/Store';

export class Telemetry {
  private store: Store;
  private ga?: typeof ReactGA;

  constructor(store: Store) {
    this.store = store;
    if (process.env.NODE_ENV === 'production') {
      this.ga = ReactGA;
      this.ga.initialize('G-9PD7XCDVSV', { gaOptions: { titleCase: false } });
    }
  }

  recordEvent(category: string, action: string) {
    if (process.env.NODE_ENV !== 'production') return;

    this.ga.gtag('set', 'user_properties', this.userProperties);
    this.ga.event({ category, action });
  }

  recordPageView(title: string) {
    if (process.env.NODE_ENV !== 'production') return;

    this.ga.gtag('set', 'user_properties', this.userProperties);
    this.ga.ga('send', 'pageview', {
      page: window.location.pathname,
      title: title,
    });
  }

  get userProperties() {
    const user = this.store.user;
    const id = user.id || 'unauthenticated';
    const role = user.id
      ? user.is_admin
        ? 'admin'
        : user.is_coach
        ? 'coach'
        : 'coachee'
      : 'unauthenticated';

    return { id, role };
  }
}

// https://docs.datadoghq.com/real_user_monitoring/
export const initDataDogTelemetry = (store: Store) => {
  if (
    ['development', 'uat'].includes(process.env.NODE_ENV) ||
    process.env.DISABLE_RUM
  ) {
    return;
  }
  const config = {
    env: process.env.NODE_ENV,
    version: process.env.RELEASE_VERSION,
    clientToken: 'pub5b6abca0b98f29572f148fd55d253517',
    site: 'datadoghq.com',
    service: 'skillcycle-platform',
    sampleRate: 100,
  };

  // https://docs.datadoghq.com/real_user_monitoring/browser/
  datadogRum.init({
    ...config,
    applicationId: 'b93b8922-3816-48a0-8342-f61d0b08dc80',
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  // https://docs.datadoghq.com/logs/log_collection/javascript/
  datadogLogs.init({
    ...config,
    forwardConsoleLogs: 'all',
  });

  autorun(() => {
    const user = store.user;
    if (user) {
      datadogRum.setUser({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email || 'n/a',
      });
    } else {
      datadogRum.setUser({
        id: 'n/a',
        name: 'Unauthenticated',
        email: 'n/a',
      });
    }
  });

  datadogRum.startSessionReplayRecording();
};
