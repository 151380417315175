import React from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';
import GoalStatusIndicator from '@src/components/common/GoalCard/GoalStatusIndicator';
import { capitalizeAllWords } from '@shared/lib/src/utils';
import { TabPanelWrapper } from '@src/components/common/TabPanelWrapper';

interface GoalsPanelProps {
  goals: any;
  activeTab: number;
  tabIndex: number;
}

export function GoalsPanel(props: GoalsPanelProps) {
  const { activeTab, tabIndex, goals } = props;
  return (
    <TabPanelWrapper value={activeTab} index={tabIndex}>
      <Table
        rowKey='id'
        dataSource={goals}
        pagination={false}
        columns={[
          {
            title: 'Goal Name',
            dataIndex: 'title',
            key: 'title',
          },
          {
            title: 'Status',
            dataIndex: 'current_status',
            key: 'status',
            render: (_, goal) => {
              const currentStatus =
                goal.status_history?.[goal.status_history.length - 1];
              return <GoalStatusIndicator status={currentStatus?.status} />;
            },
          },
          {
            title: 'Goal Type',
            dataIndex: 'type',
            key: 'type',
            render: type => capitalizeAllWords(type, '_'),
          },
          {
            title: 'Target Date',
            dataIndex: 'target_date',
            key: 'target_date',
            render: date => dayjs(date).format('MM/DD/YYYY'),
            width: '25%',
          },
        ]}
        expandable={{
          expandedRowRender: record => (
            <div
              className='description'
              dangerouslySetInnerHTML={{ __html: record.description }}
            />
          ),
        }}
        locale={{
          emptyText: 'No goals have been shared with this program',
        }}
      />
    </TabPanelWrapper>
  );
}
