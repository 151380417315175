import React from 'react';
import { Drawer } from 'antd';
import UserProfile from '@src/components/forms/UserProfile/UserProfile';
import { useStore } from '@src/models/Store';

const UserProfileDrawer = ({ open, onClose }) => {
  const store = useStore();
  return (
    <Drawer
      onClose={onClose}
      open={open}
      size='large'
      width={920}
      bodyStyle={{ padding: 0 }}
    >
      {open && <UserProfile store={store} userId={store.user.id} />}
    </Drawer>
  );
};

export { UserProfileDrawer };
