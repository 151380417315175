import React from 'react';
import { Dialog, DialogActions, DialogContent, Link } from '@mui/material';
import {
  Button,
  DialogTitle,
  useToast,
} from '@src/components/sc-design-system';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import { useParams } from 'react-router';
import { RatingScaleForm } from '@src/components/views/MyBusiness/Performance/tabs/PerformanceCycles/PerformanceCycleLandingPage/modals/RatingScaleForm';

interface EditRatingScaleModalProps {
  setOpenModal: (b: boolean) => void;
  openModal: boolean;
  performanceCycle: PerformanceCycleI;
  selfRatingOptions: number[];
  refetchPerformanceCycle: Function;
}

const EditRatingScaleModal: React.FunctionComponent<
  EditRatingScaleModalProps
> = props => {
  const toast = useToast();
  const {
    setOpenModal,
    openModal,
    performanceCycle,
    selfRatingOptions,
    refetchPerformanceCycle,
  } = props;
  const { id: clientId } = useParams();
  const ratingScaleObj = {
    rating_scale: performanceCycle.rating_scale_labels.length,
    rating_scale_labels: performanceCycle.rating_scale_labels.reduce(
      (labels, { text }) => {
        if (text && text !== '') {
          labels.push(text);
        }
        return labels;
      },
      [],
    ),
  };
  const form = useForm({
    mode: 'all',
    defaultValues: ratingScaleObj as RatingScaleFormDefaultValues,
  });
  const { reset, watch } = form;
  // if custom rating label is provided form should disable until all ratings have a custom label
  const labelLength = watch('rating_scale_labels').filter(l => l).length;
  const disabled = Boolean(
    labelLength > 0 && labelLength < watch('rating_scale'),
  );

  const { mutate: editRatingScale, isPending } = useMutation({
    mutationFn: async (payload: Partial<PerformanceCycleI>) => {
      return api.clients.editPerformanceCycle(clientId, {
        id: performanceCycle.id,
        ...payload,
      });
    },
    onSuccess: () => {
      refetchPerformanceCycle();
      setOpenModal(false);
    },
    onError: () => {
      toast.error('Unable to edit rating scale');
    },
  });

  const onSubmit = (data: RatingScaleOnSubmitProps) => {
    const rating_scale_labels = [];
    for (let scale = 0; scale < data.rating_scale; scale++) {
      rating_scale_labels.push({
        value: scale + 1,
        text: data.rating_scale_labels[scale] || '',
      });
    }
    editRatingScale({ rating_scale_labels });
  };

  function handleClose() {
    setOpenModal(false);
    reset(ratingScaleObj);
  }

  return (
    <Dialog open={openModal} onClose={() => handleClose()}>
      <DialogTitle title={`Edit rating scale`} />
      <DialogContent dividers>
        <RatingScaleForm
          onSubmit={onSubmit}
          form={form}
          selfRatingOptions={selfRatingOptions}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Link
          variant='a2'
          onClick={() =>
            reset({
              rating_scale: selfRatingOptions[0],
              rating_scale_labels: [],
            })
          }
        >
          Clear all fields
        </Link>
        <div>
          <Button
            key='cancel'
            text='Cancel'
            variant='outlined'
            onClick={handleClose}
          />
          <Button
            sx={{ marginLeft: '.5em' }}
            type='submit'
            form='rating-scale-form'
            disabled={disabled}
            key='save'
            text='Save'
            loading={isPending}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};

export { EditRatingScaleModal };
