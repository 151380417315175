import React from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Table, Avatar } from '@src/components/sc-design-system';
import { GoalStatuses } from '@shared/schemas/src/goal';
import GoalStatusIndicator from '@src/components/common/GoalCard/GoalStatusIndicator';
import { Stack, Typography } from '@mui/material';

interface GoalStatusesTableProps {
  descOrderStatusHistory: GoalStatusProps[];
}

const GoalStatusesTable: React.FunctionComponent<GoalStatusesTableProps> =
  observer(function (props: GoalStatusesTableProps) {
    const columns = [
      {
        title: 'Contributor',
        dataIndex: 'contributor',
        render: (text: string, statusRecord) => (
          <Stack direction='row' spacing={1} alignItems='flex-end'>
            <Avatar
              srcId={statusRecord.creator_id}
              alt={`${statusRecord.creator_first_name} ${statusRecord.creator_last_name}`}
            />
            <Typography variant='body2'>
              {dayjs(statusRecord.createdAt).fromNow()}
            </Typography>
          </Stack>
        ),
        key: 'contributor',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status: GoalStatuses) => (
          <GoalStatusIndicator status={status} />
        ),
        key: 'status',
      },
    ];
    return (
      <Table
        rowKey='id'
        dataSource={props.descOrderStatusHistory}
        locale={{
          emptyText:
            'Update the status of your goal to see the most recent activity.',
        }}
        expandable={{
          rowExpandable: record => record.notes,
          expandedRowRender: record => (
            <div
              className='description'
              dangerouslySetInnerHTML={{ __html: record.notes }}
            />
          ),
        }}
        columns={columns}
        hidePagination
        style={{ padding: '0 32px' }}
      />
    );
  });

export default GoalStatusesTable;
