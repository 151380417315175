/**
 * Purple tones
 */
const PRIMARY = {
  [100]: '#F8EDF8',
  [200]: '#DEAFDE',
  [300]: '#C47EC4',
  [400]: '#932F93',
  [500]: '#7E287E',
  [600]: '#692669',
  [700]: '#532253',
  [800]: '#3F1D3F',
  [900]: '#341934',
};
/**
 * Pink tones
 */
const SECONDARY = {
  [100]: '#FEDCEC',
  [200]: '#FDAAD1',
  [300]: '#FC79B6',
  [400]: '#FA479B',
  [500]: '#ED1F80',
  [600]: '#C01B69',
  [700]: '#8B1D51',
  [800]: '#5B1A39',
  [900]: '#301220',
};
/**
 * Blue tones
 */
const TERTIARY = {
  [100]: '#B2F5FF',
  [200]: '#80EEFF',
  [300]: '#4EE6FD',
  [400]: '#1CDFFD',
  [500]: '#0EBFDA',
  [600]: '#148C9F',
  [700]: '#14606B',
  [800]: '#10373C',
  [900]: '#151204',
};
/**
 * Grey tones
 */
const NEUTRAL = {
  [50]: '#FAFAFA',
  [100]: '#F5F5F5',
  [200]: '#E8E8E8',
  [300]: '#D8D8D8',
  [400]: '#CCCCCC',
  [500]: '#949494',
  [600]: '#808080',
  [700]: '#585858',
  [800]: '#383838',
  [900]: '#000000',
};

/**
 * Accent colors: green, red, yellow, blue
 */
const GREEN = {
  [100]: '#DDF4F0 ',
  [200]: '#A3E0D6',
  [300]: '#74C3B6',
  [400]: '#46A495',
  [500]: '#077E6A',
  [600]: '#066555',
  [700]: '#044D41',
  [800]: '#03352D',
  [900]: '#021D18',
};
const RED = {
  [100]: '#FFDBDC',
  [200]: '#FFA8A9',
  [300]: '#FC797A',
  [400]: '#F64C4D',
  [500]: '#ED2224',
  [600]: '#C51618',
  [700]: '#931516',
  [800]: '#641212',
  [900]: '#370C0C',
};
const YELLOW = {
  [100]: '#FFF5D1',
  [200]: '#FFEA9E',
  [300]: '#FFDF6B',
  [400]: '#FFD438',
  [500]: '#FFC907',
  [600]: '#DFB10C',
  [700]: '#BC9815',
  [800]: '#866E18',
  [900]: '#6A591B',
};
const BLUE = {
  [100]: '#D5E3F0',
  [200]: '#8AB0D6',
  [300]: '#6496C9',
  [400]: '#417DB9',
  [500]: '#346393',
  [600]: '#2D5780',
  [700]: '#264A6D',
  [800]: '#203D5B',
  [900]: '#193048',
};

const ACCENTS = {
  GREEN,
  RED,
  YELLOW,
  BLUE,
  PURPLE: '#42049F',
};

/**
 * Main export
 */
const COLOR = {
  BLACK: '#1A1A2E',
  WHITE: '#FFFFFF',
  PRIMARY,
  SECONDARY,
  TERTIARY,
  NEUTRAL,
  ACCENTS,
};

export { COLOR };
