import React from 'react';
import Chip, { ChipOwnProps } from '@mui/material/Chip';

interface ActiveInactiveChipProps
  extends Omit<ChipOwnProps, 'color' | 'customColor'> {
  active: boolean;
}

const ActiveInactiveChip: React.FunctionComponent<
  ActiveInactiveChipProps
> = props => {
  const { active, ...additionalProps } = props;
  return (
    <Chip
      color={active ? 'success' : undefined}
      label={active ? 'Active' : 'Inactive'}
      {...additionalProps}
    />
  );
};

export default ActiveInactiveChip;
