import querystring from 'node:querystring';
import { HTTPClient, APIResponse } from '@src/lib/client';
import { ProgramType } from '@shared/schemas';

export interface ProgramGetParams {
  queryBy: 'client' | 'sow';
  queryId: string;
  page?: number;
  limit?: number;
}

export interface ProgramGetMembersParams {
  keyword?: string;
  page?: number;
  limit?: number;
}

interface AddMemberIF {
  programId: string;
  memberId: string;
  role?: string;
  email?: string;
}

interface Invitee {
  invitee_first_name?: string;
  invitee_last_name?: string;
  invitee_email: string;
}

interface InviteMemberIF {
  programId: string;
  invitees: Invitee[];
}

interface RevokeInviteIF {
  programId: string;
  email: string;
}

interface EngagementAssessmentStakeholder {
  id?: string;
  email: string;
  added?: number;
  sent?: number;
  sentReminder?: number;
  surveySubmissionId?: string;
  name?: string;
  role?: 'manager' | 'report' | 'peer';
}

export interface _360StakeholdersParams {
  stakeholders: EngagementAssessmentStakeholder[];
  programId: string;
  memberId: string;
}

interface Update360StakeholderParams {
  email: string;
  programId: string;
  memberId: string;
  submissionId: string;
}

interface UpdateProgramSurveyConfigurationParams {
  programId: string;
  surveys: ProgramType['surveys'];
  client_id: string;
}

export class ProgramsAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async getProgramById(id: string) {
    return this.http.request({
      url: `/programs/${id}`,
      method: 'get',
    });
  }

  async getProgramFields(id: string, queryParams: { return_fields: string[] }) {
    return this.http.request({
      url: `/programs/${id}?${querystring.encode(queryParams)}`,
      method: 'get',
    });
  }

  async get(params: ProgramGetParams) {
    return this.http.request({
      url: `/programs/list?${Object.keys(params)
        .map(key =>
          key && key === 'queryBy'
            ? params.queryBy + '=' + encodeURIComponent(params.queryId)
            : params[key]
            ? key + '=' + encodeURIComponent(params[key])
            : undefined,
        )
        .filter(n => n)
        .join('&')}`,
      method: 'get',
    });
  }

  async getProgramMembers(programId: string, params?: ProgramGetMembersParams) {
    return this.http.request({
      url: `/programs/members/${programId}?${
        params &&
        Object.keys(params)
          .map(key => key + '=' + encodeURIComponent(params[key]))
          .join('&')
      }`,
      method: 'get',
    });
  }

  async getProgramMentors(programId: string) {
    return this.http.request({
      url: `/programs/mentors/${programId}`,
      method: 'get',
    });
  }

  async getProgramCoaches(programId: string) {
    return this.http.request({
      url: `/programs/coaches/${programId}`,
      method: 'get',
    });
  }

  async saveProgram(data: ProgramType) {
    return this.http.request({
      url: `/programs/${data.id && data.id.length > 0 ? data.id : 'create'}`,
      method: data.id && data.id.length > 0 ? 'patch' : 'post',
      data,
    });
  }

  async addProgramMembers(data: AddMemberIF) {
    return this.http.request({
      url: '/programs/members/add',
      method: 'post',
      data,
    });
  }

  async inviteProgramMembers(data: InviteMemberIF) {
    return this.http.request({
      url: '/programs/members/invite',
      method: 'post',
      data,
    });
  }

  async revokeInvite(data: RevokeInviteIF) {
    return this.http.request({
      url: '/programs/revoke-invite',
      method: 'post',
      data,
    });
  }

  async undoRevokeInvite(data: RevokeInviteIF) {
    return this.http.request({
      url: '/programs/undo-revoke-invite',
      method: 'post',
      data,
    });
  }

  async quickSearch(keyword: string) {
    return this.http.request({
      url: `/programs/quick-search?keyword=${keyword || ''}`,
      method: 'get',
    });
  }

  async add360Stakeholder({
    programId,
    memberId,
    stakeholder,
  }): APIResponse<ProgramType> {
    return this.http.request({
      url: `programs/360-stakeholders/${programId}/${memberId}/new`,
      method: 'patch',
      data: {
        stakeholder,
      },
    });
  }

  async remove360Stakeholder({
    programId,
    memberId,
    stakeholder,
  }): APIResponse<ProgramType> {
    return this.http.request({
      url: `programs/360-stakeholders/${programId}/${memberId}/remove`,
      method: 'patch',
      data: {
        stakeholder,
      },
    });
  }

  async get360Stakeholders({ programId, memberId }): APIResponse<ProgramType> {
    return this.http.request({
      url: `programs/360-stakeholders/${programId}/${memberId}`,
      method: 'get',
    });
  }

  async add360Stakeholders(
    params: _360StakeholdersParams,
  ): APIResponse<ProgramType> {
    const response = await this.http.request({
      url: `programs/360-stakeholders/${params.programId}/${params.memberId}`,
      method: 'patch',
      data: {
        stakeholders: params.stakeholders,
      },
    });
    return response;
  }

  async send360Notifications(
    params: _360StakeholdersParams,
  ): APIResponse<ProgramType> {
    const response = await this.http.request({
      url: `programs/360-notifications/${params.programId}/${params.memberId}`,
      method: 'post',
      data: {
        stakeholders: params.stakeholders,
      },
    });
    return response;
  }

  async mark360StakeholderAssessmentComplete(
    params: Update360StakeholderParams,
  ) {
    const { email, programId, memberId, submissionId } = params;
    const response = await this.http.request({
      url: `programs/360-stakeholders/${programId}/${memberId}/complete`,
      method: 'patch',
      data: {
        email,
        submissionId,
      },
    });
    return response;
  }

  async mark360SelfAssessmentComplete(params: {
    surveySubmissionId: string;
    memberId: string;
    programId: string;
  }) {
    const { programId, memberId, surveySubmissionId } = params;
    const response = await this.http.request({
      url: `programs/360-self/${programId}/${memberId}/complete`,
      method: 'patch',
      data: {
        surveySubmissionId,
      },
    });
    return response;
  }

  async selectProgramCoach(
    user_id: string,
    coach_id: string,
    program_id: string,
  ) {
    return this.http.request({
      url: '/programs/select-program-coach',
      method: 'patch',
      data: {
        user_id,
        coach_id,
        program_id,
      },
    });
  }

  async updateProgramSurveyConfiguration({
    programId,
    surveys,
    client_id,
  }: UpdateProgramSurveyConfigurationParams): APIResponse<ProgramType> {
    return this.http.request({
      url: `programs/${programId}/surveys`,
      method: 'patch',
      data: { client_id, surveys },
    });
  }

  async getProgramSurveySubmissions(programId: string) {
    return this.http.request({
      url: `/programs/${programId}/survey-submissions`,
      method: 'get',
    });
  }

  async getPublishedProgramSurveys(queryParams: {
    client_id?: string;
    program_id?: string;
  }) {
    return this.http.request({
      url: `/programs/list-published-program-surveys?${Object.keys(queryParams)
        .filter(k => queryParams[k])
        .map(key => key + '=' + encodeURIComponent(queryParams[key]))
        .join('&')}`,
      method: 'get',
    });
  }
}
