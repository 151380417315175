import React, { FC } from 'react';
import { Select, Space } from 'antd';
import SurveyQuestion from '@src/models/SurveyQuestion';
import { observer } from 'mobx-react';
import '../index.less';

const SurveyQuestionOptions: FC<{
  surveyQuestion: SurveyQuestion;
  isSurveyEditable: boolean;
}> = ({ surveyQuestion, isSurveyEditable }) => {
  if (surveyQuestion.type !== 'range') {
    return null;
  }
  return (
    <Space direction='horizontal' align='baseline' className='se-range-options'>
      <Select
        className='se-range-selector'
        defaultValue={
          surveyQuestion.range_maximum ? surveyQuestion.range_maximum : 10
        }
        options={[
          { value: 4, label: '4' },
          { value: 5, label: '5' },
          { value: 6, label: '6' },
          { value: 7, label: '7' },
          { value: 8, label: '8' },
          { value: 9, label: '9' },
          { value: 10, label: '10' },
        ]}
        onChange={o => {
          surveyQuestion.range_maximum = o;
        }}
        disabled={!isSurveyEditable}
      />
      <span className='se-range-label'>Range Max</span>
    </Space>
  );
};

export default observer(SurveyQuestionOptions);
