import React, { useState, FC } from 'react';

import { Space, Input } from 'antd';

interface VCIProps {
  onChange: Function;
}

const VerificationCodeInput: FC<VCIProps> = ({ onChange }) => {
  const [codeArr, setCodeArr] = useState(['', '', '', '', '', '']);
  const setCodePosition = (idx: number, value?: string): void => {
    if (!/^(\s*|\d+)$/.test(value)) return;
    const arr = new Array(...codeArr);
    arr[idx] = value;
    setCodeArr(arr);
    onChange &&
      onChange({
        valid: Boolean(arr.join('').length === 6),
        value: arr.join(''),
      });
    if (+value >= 0 && +value <= 9 && idx < codeArr.length) {
      const next = document.querySelector<HTMLInputElement>(
        `input[name=vc-${idx + 1}]`,
      );
      next && next.focus && next.focus();
    }
  };
  return (
    <Space style={{ marginBottom: '1em' }}>
      {codeArr.map((value, idx) => (
        <Input
          onFocus={() =>
            document
              .querySelector<HTMLInputElement>(`input[name=vc-${idx}]`)
              .select()
          }
          style={{ textAlign: 'center' }}
          name={`vc-${idx}`}
          key={`vc-${idx + 1}`}
          maxLength={1}
          value={value || ''}
          onChange={e => {
            setCodePosition(idx, e.target.value);
          }}
        />
      ))}
    </Space>
  );
};

export interface VCOnChangeResIF {
  value: string;
  valid: boolean;
}

export default VerificationCodeInput;
