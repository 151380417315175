import React, { FC, useMemo } from 'react';
import dayjs from 'dayjs';
import ListIcon from '@mui/icons-material/List';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BarChartIcon from '@mui/icons-material/BarChart';
import TrackChanges from '@mui/icons-material/TrackChanges';
import { useStore } from '@src/models/Store';
import {
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
} from '@src/components/sc-design-system';
import { ClientSelector } from '@src/components/common/ClientSelector';
import {
  MyPerformance,
  Competencies,
  SurveysAndFeedback,
  PersonalGrowth,
} from './tabs';
import { useActiveClient, ActiveClientKey } from '@src/hooks/useActiveClient';
import { useNavigate } from 'react-router';
import { useUserQueries } from '@src/hooks/useUserQueries';

const PerformanceAndGrowth: FC<{}> = () => {
  const store = useStore();
  const { user } = store;
  const navigate = useNavigate();

  const {
    client,
    isLoading: isClientLoading,
    isFetching: isClientFetching,
  } = useActiveClient({
    queryKey: ActiveClientKey.profile_active_client_id,
  });

  const { useGetUserGoalData, useGetUserCompetencySets } = useUserQueries(
    user.id,
  );

  const { performanceData, personalGrowthData, isLoading } =
    useGetUserGoalData();
  const { competencySets, isLoading: isCSLoading } = useGetUserCompetencySets();

  const performanceCycles = useMemo(() => {
    const categorized = performanceData?.performance_cycles?.reduce(
      (acc, pc) => {
        const start = dayjs(pc.start);
        const isActive = dayjs().isBetween(start, dayjs(pc.end));
        if (isActive || dayjs().isBefore(start)) {
          acc.active.push(pc);
        } else {
          acc.inactive.push(pc);
        }
        return acc;
      },
      { active: [], inactive: [] },
    );

    // sort based on start date
    return {
      active: categorized?.active.sort((a, b) => b.start - a.start),
      inactive: categorized?.inactive.sort((a, b) => b.start - a.start),
    };
  }, [performanceData]);

  const activeUserCycles = useMemo(() => {
    return (
      personalGrowthData?.goal_cycles?.filter(
        c => !c.end || dayjs().isBefore(c.end),
      ) || []
    );
  }, [personalGrowthData]);

  const allActiveCycles = useMemo(() => {
    const performanceGoalCycles = performanceCycles?.active?.reduce(
      (arr, pc) => {
        const pcGoalCycles =
          pc.goal_cycles?.map(gc => {
            return {
              ...gc,
              performanceCycleId: pc.id,
            };
          }) || [];
        return [...arr, ...pcGoalCycles];
      },
      [],
    );
    return [...activeUserCycles, ...(performanceGoalCycles || [])];
  }, [activeUserCycles, performanceCycles]);

  return (
    <View>
      <ViewHeader
        title='Performance and Growth'
        titleIcon={<TrackChanges />}
        actions={[
          {
            render: user.clients?.length > 1 && (
              <ClientSelector
                user={user}
                key='client-selector'
                selectedClient={client}
                disabled={isClientLoading || isClientFetching}
                onSelect={() => {
                  navigate('.'); // clear query params
                }}
                localStorageKey={ActiveClientKey.profile_active_client_id}
              />
            ),
          },
        ]}
      />
      <ViewBody>
        {performanceData && (
          <ViewBodySection
            title='My Performance'
            icon={<BarChartIcon />}
            isLoading={isLoading || isClientLoading || isClientFetching}
          >
            <MyPerformance
              performanceCycles={performanceCycles}
              activeGoalCycles={allActiveCycles}
              clientId={client?.id}
              clientName={client?.name}
            />
          </ViewBodySection>
        )}
        <ViewBodySection
          title='Personal Growth'
          icon={<TrendingUpIcon />}
          isLoading={isLoading || isClientLoading || isClientFetching}
        >
          <PersonalGrowth
            user={user}
            goalCycles={personalGrowthData?.goal_cycles}
            allGoalCycles={allActiveCycles}
            clientId={client?.id}
          />
        </ViewBodySection>
        {Boolean(competencySets?.length) && (
          <ViewBodySection
            title='Competencies'
            icon={<ListIcon />}
            isLoading={isCSLoading || isClientLoading}
          >
            <Competencies sets={competencySets} clientName={client?.name} />
          </ViewBodySection>
        )}
        <ViewBodySection title='Surveys & Feedback' icon={<FeedOutlinedIcon />}>
          <SurveysAndFeedback user={user} />
        </ViewBodySection>
      </ViewBody>
    </View>
  );
};

export { PerformanceAndGrowth };
