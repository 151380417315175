import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Spin } from '@src/components/sc-design-system';
import { Message } from './components';
import { Typography } from '@mui/material';

const sx = { color: 'grey.700' };

function filterAnswers(results = []) {
  const questionTypes = ['single-select', 'multi-select', 'range', 'text'];
  return results.reduce((acc, { question, answer }) => {
    if (!question.meta_data && questionTypes.includes(question.type)) {
      let description;
      if (question.type === 'multi-select') {
        const translatedAnswers = answer.map(a => {
          if (question.allow_other && a === 'other') {
            return 'Other';
          }
          return question.options.find(option => option.value === a)?.text;
        });
        description = (
          <ul>
            {translatedAnswers.map((answer, idx) => (
              <li key={idx}>{answer}</li>
            ))}
          </ul>
        );
      } else {
        if (question.type === 'single-select') {
          description = question.options.find(
            option => option.value === answer,
          )?.text;
          if (!description && question.allow_other && answer === 'other') {
            description = 'Other';
          }
        }
        if (answer != null)
          description = (
            <Typography variant='body2' sx={sx}>
              {answer}
            </Typography>
          );
      }

      acc.push({
        title: question.title,
        description: description || (
          <Typography variant='body2' sx={sx}>
            No response provided
          </Typography>
        ),
      });
    }
    return acc;
  }, []);
}

function ReportSelfAssessment(props: ReportSelfAssessmentProps) {
  const { latestSelfSubmission, isLoadingGet360ReportData } = props;
  const [answers, setAnswers] = React.useState([]);

  React.useEffect(() => {
    const filteredAnswers = filterAnswers(latestSelfSubmission?.results);
    setAnswers(filteredAnswers);
  }, [latestSelfSubmission]);

  return (
    <>
      {isLoadingGet360ReportData === true && <Spin sectionLoader />}
      {!isLoadingGet360ReportData && answers.length > 0 && (
        <List>
          {answers.map((item, index) => (
            <ListItem key={index}>
              <ListItemText
                primaryTypographyProps={{ component: 'p', variant: 'body1m' }}
                secondaryTypographyProps={{
                  component: 'div',
                  sx,
                }}
                primary={item.title}
                secondary={item.description}
              />
            </ListItem>
          ))}
        </List>
      )}
      {!isLoadingGet360ReportData && answers.length === 0 && (
        <Message type='selfAssessmentEmpty' />
      )}
      {isLoadingGet360ReportData === 'error' && <Message type='error' />}
    </>
  );
}

export default ReportSelfAssessment;
