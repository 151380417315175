import React from 'react';
import { ReactComponent as LoadingImg } from '@shared/theme/src/assets/logomark-spin.svg';

const LoadingIcon = ({ size = 'small' }) => {
  const sizes = {
    small: {
      position: 'fixed',
      top: '45%',
      left: '50%',
      width: '60px',
      height: '60px',
    },
  };
  return <LoadingImg style={sizes[size]} />;
};

export default LoadingIcon;
