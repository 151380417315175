import i18n from 'i18next';
import translations from '@shared/translations/src/en/translations';
import { initReactI18next } from 'react-i18next';

export const resources = {
  en: {
    translation: translations,
  },
};

i18n.use(initReactI18next).init({
  lng: 'en',
  resources,
});
