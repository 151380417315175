import React, { FC, useState } from 'react';
import startCase from 'lodash/startCase';
import { Box, Stack, Typography } from '@mui/material';
import { Button, Card } from '@src/components/sc-design-system';
import { AddressFormModal } from './AddressFormModal';
import Client from '@src/models/Client';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

const formatAddress = ({
  street_address_1,
  street_address_2 = '',
  city,
  state,
  zip,
  country,
}) =>
  [street_address_1, street_address_2, city, state, zip, country]
    .filter(v => v)
    .join(', ');

const AddressBoxes: FC<{ client: Client }> = ({ client }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(null);
  const addressForAll = client?.addresses?.find(({ type }) => type === 'all');
  return (
    <Card title='Addresses' titleActions={null}>
      {client?.addresses.map((address, i) => {
        return (
          <Box
            key={address.type}
            sx={{
              backgroundColor: 'grey.50',
              width: '100%',
              height: '124px',
              display: 'flex',
              alignItems: 'center',
              padding: '2em',
              justifyContent: 'space-between',
              ...(i === 0
                ? {
                    borderBottomWidth: '1px',
                    borderBottomStyle: 'solid',
                    borderBottomColor: 'grey.300',
                  }
                : {}),
            }}
          >
            <Stack spacing={2}>
              <Typography variant='body1m'>
                {startCase(address.type === 'all' ? 'mailing' : address.type)}{' '}
                Address
              </Typography>
              <Typography variant='body1'>
                {address.isValid
                  ? formatAddress(addressForAll || address)
                  : 'Optional'}
              </Typography>
            </Stack>
            <Button
              startIcon={address.isValid ? <EditIcon /> : <AddIcon />}
              variant='outlined'
              text={address.isValid ? 'Edit address' : 'Add address'}
              onClick={() => {
                setCurrentAddress(address.data);
                setIsEditModalOpen(true);
              }}
            />
          </Box>
        );
      })}
      {!!currentAddress && (
        <AddressFormModal
          isOpen={isEditModalOpen}
          onClose={() => {
            setCurrentAddress(null);
            setIsEditModalOpen(false);
          }}
          currentAddress={currentAddress}
          client={client}
        />
      )}
    </Card>
  );
};

export { AddressBoxes };
