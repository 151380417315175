import React, { FC, useCallback, useMemo, useState } from 'react';
import { InputNumber } from 'antd';
import {
  Button,
  useToast,
  DialogTitle,
} from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Stack,
} from '@mui/material';

import './GoalWeightingModal.less';

interface GoalWeightingModalProps {
  userId: string;
  goalCycle?: GoalCycleWithGoals;
  onClose: Function;
  open: boolean;
  onGoalUpdate?: Function;
}

const GoalWeightingModal: FC<GoalWeightingModalProps> = ({
  userId,
  onClose,
  goalCycle,
  open,
  onGoalUpdate,
}) => {
  const toast = useToast();
  const userGoals = useMemo(() => {
    return goalCycle?.goals?.filter((g: GoalBase) =>
      g.user_ids?.includes(userId),
    );
  }, [goalCycle, userId]);
  const [formGoals, setFormGoals] = useState(
    userGoals?.map(({ id, weight, title }) => ({ id, weight, title })),
  );

  const calculateTotal = useCallback(() => {
    return formGoals?.reduce((acc, g) => {
      return (g.weight || 0) + acc;
    }, 0);
  }, [formGoals]);

  const [total, setTotal] = useState(calculateTotal());

  const [isLoading, setIsLoading] = useState(false);
  const [touched, setTouched] = useState(false);

  const handleClose = () => {
    setTouched(false);
    setFormGoals([]);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      maxWidth='sm'
      className='goal-weighting-modal'
    >
      <DialogTitle
        title={
          <Stack spacing={1}>
            <Typography variant='h3'>Manage Goal Weighting</Typography>
            <Typography variant='body1'>
              Goal weighting for all aligned goals must add up to 100%.
            </Typography>
          </Stack>
        }
        buttonOnClick={() => handleClose()}
      />
      <DialogContent>
        {formGoals?.map(g => (
          <Stack
            key={g.id}
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography>{g.title}</Typography>
            <Stack
              direction='row'
              alignItems='center'
              width={150}
              minWidth={150}
              spacing={1}
            >
              <InputNumber
                value={g.weight}
                placeholder='0'
                controls={false}
                onChange={val => {
                  g.weight = Math.round(val);
                  setTotal(calculateTotal());
                  if (!touched) setTouched(true);
                }}
                style={{
                  textAlign: 'right',
                  padding: '0 8px',
                }}
              />
              <span>%</span>
            </Stack>
          </Stack>
        ))}
        {touched && total !== 100 && (
          <Typography
            variant='body2'
            color='error.main'
            sx={{ margin: '1em 0' }}
          >
            Goal weighting for all aligned goals must add up to 100%.
          </Typography>
        )}
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ marginTop: '1em' }}
        >
          <Typography>Total</Typography>
          <Stack direction='row' alignItems='center' spacing={2}>
            <Typography>{total}</Typography>
            <Typography>%</Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          text='Cancel'
          onClick={() => handleClose()}
          sx={{ marginRight: '.75em' }}
        />
        <Button
          text='Set'
          onClick={async () => {
            setIsLoading(true);
            try {
              await api.goals_competencies.updateGoalWeights(
                userId,
                formGoals?.map(({ id, weight }) => ({ id, weight })),
              );
              formGoals?.forEach(goal => {
                const current = goalCycle?.goals.find(g => g.id === goal.id);
                current.weight = goal.weight;
              });
              toast.success('Updated goal weights');
              onGoalUpdate && (await onGoalUpdate());

              handleClose();
            } catch (err) {
              toast.error('Unable to update goal weights');
            } finally {
              setIsLoading(false);
            }
          }}
          loading={isLoading}
          disabled={total !== 100 || isLoading}
        />
      </DialogActions>
    </Dialog>
  );
};

export { GoalWeightingModal };
