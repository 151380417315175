// TODO: disabling these rules should be temporary.
// interacting with the coach cards via keyboard works as-is, and resolving
// these issues involves a larger refactor with the weird CoachViewModal comp
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './CoachSearch.css';
import * as React from 'react';
import { observer } from 'mobx-react';
import {
  autorun,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import { Empty, Input, Select } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  View,
  ViewHeader,
} from '@src/components/sc-design-system';
import SearchIcon from '@mui/icons-material/Search';
import Store, { useStore } from '@src/models/Store';
import CoachSearchWizard from '../../common/CoachSearchWizard/CoachSearchWizard';
import { api } from '@src/lib/client';
import { useWorld } from '@src/world';
import { Coach, CoachingTopic } from '@src/lib/client/apis/coaches';
import { useElementRect } from '@src/lib/dom';
import {
  CoachViewModal,
  CoachViewModalButton,
} from '@src/components/common/CoachViewModal';
import * as useVerbiage from '@shared/lib/src/use-verbiage';
import { handleKeyboardNav, useQueryParams } from '@src/utils';
import { useNavigate } from 'react-router-dom';
import { useSkills } from '@src/hooks/useSkills';
import {
  Typography,
  Divider,
  IconButton,
  CircularProgress,
} from '@mui/material';
import WandIcon from '@mui/icons-material/AutoFixHigh';
import StarOutline from '@mui/icons-material/StarOutline';
type ProgramType = 'standard_coaching' | 'custom_coach_selection' | 'mentoring';

export class CoachesViewModel {
  private store: Store;
  private timer: any;
  private requestId: number;
  state: 'loading' | 'error' | 'loaded';
  results: Coach[];
  selectedTopics: Set<string>;
  program_id: string;
  program_type: ProgramType;
  skills: Array<{
    id: string;
    title: string;
  }> = [];

  constructor(
    store: Store,
    skills: Array<{
      id: string;
      title: string;
    }> | null,
  ) {
    this.store = store;
    this.skills = skills;
    this.state = 'loaded';
    this.results = [];
    this.selectedTopics = new Set();
    this.timer = null;
    this.requestId = 0;
    if (this.store.user?.search_topics) {
      this.selectedTopics = new Set(this.store.user.search_topics);
    }
    makeAutoObservable<this, 'timer' | 'runningSearch'>(this, {
      timer: false,
      runningSearch: false,
      results: observable.ref,
    });
  }

  private async _search(
    requestId: number,
    programId: string,
    programType: ProgramType,
  ) {
    let response: any;
    let error: Error | undefined;
    try {
      const tags: string[] = [];
      const keywords: string[] = [];
      for (const topic of this.selectedTopics) {
        const isKnown = !!this.skills?.find(s => s.id === topic);
        if (isKnown) {
          tags.push(topic);
        } else {
          keywords.push(topic);
        }
      }
      response = await api.coaches.searchCoaches({
        program_id: programId,
        program_type: programType,
        keyword: keywords.join(','),
        tags: [...this.selectedTopics],
        randomize: true,
      });
    } catch (e) {
      error = e;
    }
    if (this.requestId !== requestId) {
      return;
    }
    runInAction(() => {
      if (error) {
        this.state = 'error';
      } else {
        this.results = response.data.data;
        this.state = 'loaded';
      }
    });
  }

  get loading() {
    return this.state === 'loading';
  }

  async search(programId, programType): Promise<void> {
    this.state = 'loading';
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.requestId++;
      this._search(this.requestId, programId, programType);
    }, 500);
  }

  topic(id: string): CoachingTopic | undefined {
    return this.skills?.find(skill => skill.id === id);
  }
}

let CoachSearchView = () => {
  const { telemetry } = useWorld();
  const navigate = useNavigate();
  const store = useStore();
  const query = useQueryParams();
  const { skills } = useSkills();
  const programId = query.get('program_id');
  const programType = query.get('program_type');
  const vm = React.useMemo(() => {
    const vm = new CoachesViewModel(store, skills);
    vm.search(programId, programType);
    return vm;
  }, [store, skills, programId, programType]);
  const v = useVerbiage.init(programType === 'mentoring' ? ['isMentor'] : []);

  React.useEffect(() => {
    if (!programId || !programType) {
      navigate('/my-programs');
    }
  }, [navigate, programId, programType]);

  return (
    <View>
      <ViewHeader
        title={`${v('coach', true)} Search`}
        titleIcon={<SearchIcon fontSize='large' />}
        actions={[
          {
            render: (vm.results.length >= 20 ||
              programType === 'standard_coaching') && (
              <CoachSearchWizard
                key='search_wizard'
                user={store.user}
                onCompleted={() => {
                  runInAction(() => {
                    vm.selectedTopics = new Set(store.user.search_topics);
                    vm.search(programId, programType);
                  });
                  telemetry.recordEvent(
                    'coach_search_wizard',
                    'completed_coach_search_wizard',
                  );
                }}
                programType={programType}
              >
                <Button
                  startIcon={<WandIcon />}
                  size='small'
                  text='Search Wizard'
                />
              </CoachSearchWizard>
            ),
          },
        ]}
      />
      <Card sx={{ marginTop: 0, borderRadius: 0 }}>
        {(vm.results.length >= 20 || programType === 'standard_coaching') && (
          <Search vm={vm} />
        )}
        <SearchResults vm={vm} />
      </Card>
    </View>
  );
};

let Search = ({ vm }: { vm: CoachesViewModel }) => {
  const query = useQueryParams();
  const { skills, isLoading: isLoadingSkills } = useSkills();
  const keywords = query.getAll('keywords');
  const programId = query.get('program_id');
  const programType = query.get('program_type');
  const v = useVerbiage.init(programType === 'mentoring' ? ['isMentor'] : []);
  const options = React.useMemo(() => {
    return skills?.map(skill => {
      return {
        key: skill.id,
        label: skill.title,
        value: skill.id,
      };
    });
  }, [skills]);

  React.useEffect(() => {
    if (!isLoadingSkills && Array.isArray && keywords.length > 0) {
      const matchedKeywords = options.reduce((acc, o) => {
        if (keywords.includes(o.label)) {
          acc.push(o.label);
        }
        return acc;
      }, []);
      const filteredKeywords = keywords.filter(
        k => !matchedKeywords.includes(k),
      );
      filteredKeywords.forEach(k => vm.selectedTopics.add(k));
    }
  }, [isLoadingSkills, keywords, options, vm.selectedTopics]);

  return (
    <section className='coach-search'>
      <Typography
        variant='body1'
        color='grey.700'
        style={{ marginBottom: '2em' }}
      >
        Find the perfect {v('coach')} by searching for skills that interest you.
      </Typography>
      <Input.Group className='coach-search-container' compact>
        <IconButton
          className='outlined'
          onClick={() => vm.search(programId, programType)}
          sx={{
            margin: 0,
            width: '4rem',
            height: '67px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '.5em',
          }}
        >
          {vm.loading ? (
            <CircularProgress size={30} color='inherit' />
          ) : (
            <SearchIcon fontSize='large' />
          )}
        </IconButton>
        <Select
          mode='tags'
          placeholder='Type a skill here or click to see options.'
          value={isLoadingSkills ? [] : [...vm.selectedTopics]}
          options={options}
          optionFilterProp='label'
          onDeselect={id => {
            // @ts-ignore
            query.delete('keywords', id);
            runInAction(() => {
              vm.selectedTopics.delete(id);
              vm.search(programId, programType);
            });
          }}
          onSelect={id => {
            runInAction(() => {
              vm.selectedTopics.add(id);
              vm.search(programId, programType);
            });
          }}
          style={{ width: '100%' }}
        />
      </Input.Group>
    </section>
  );
};
Search = observer(Search);

let SearchResults = ({ vm }: { vm: CoachesViewModel }) => {
  const ref = React.useRef(null!);
  const columnsRef = React.useRef<number>(0);
  const rectObservable = useElementRect(ref);
  const [showResults, setShowResults] = React.useState(false);
  useKeyboardNavigation(ref, columnsRef);

  // When columns change, we only need to set a CSS property; we don't
  // actually need to re-render. That's why the observable logic is
  // handled in an effect rather than the render function.
  React.useEffect(() => {
    return autorun(() => {
      const columns = computed((): number => {
        const rect = rectObservable.get();
        if (!rect) return 0;
        if (rect.width >= 1100) return 4;
        if (rect.width >= 800) return 3;
        if (rect.width >= 500) return 2;
        return 1;
      }).get();

      setShowResults(true);
      columnsRef.current = columns;
      ref.current.style.setProperty('--columns', '' + columns);
    });
  }, [rectObservable]);

  const empty = computed(() => {
    return vm.state !== 'loading' && vm.results.length === 0;
  }).get();

  if (empty) {
    return (
      <Empty
        className='coach-results-empty'
        description={
          <Typography variant='a1' color='grey.700'>
            No results found
          </Typography>
        }
      />
    );
  }

  return (
    <div className='coach-results' ref={ref}>
      {showResults &&
        vm.results.map(coach => {
          return <CoachCard key={coach.id} vm={vm} coach={coach} />;
        })}
    </div>
  );
};
SearchResults = observer(SearchResults);

let CoachCard = ({ vm, coach }: { vm: CoachesViewModel; coach: Coach }) => {
  const query = useQueryParams();
  const programType = query.get('program_type');
  const v = useVerbiage.init(programType === 'mentoring' ? ['isMentor'] : []);
  const ref = React.useRef(null!);
  const [showDetails, setShowDetails] = React.useState(false);
  const store = useStore();
  const fullName = `${coach.first_name} ${coach.last_name}`;
  const saved = !!store.user.savedAndInterviewCoachList.find(
    c => c.id === coach.id,
  );

  let location = '';
  if (coach.addresses?.length) {
    const addr = coach.addresses[0]!;
    const parts = [addr.city, addr.state, addr.country].filter(Boolean);
    location = parts.join(', ');
  }

  let classes = 'coach-card';
  if (showDetails) {
    classes += ' active';
  }

  const avatarImgSrc = process.env.ASSETS_ROOT
    ? `${process.env.ASSETS_ROOT}/${coach.id}/avatar.png`
    : coach.profile_image;

  return (
    <>
      <div
        ref={ref}
        role='button'
        className={classes}
        tabIndex={0}
        onClick={() => setShowDetails(true)}
        onKeyDown={e => handleKeyboardNav(e.key, () => setShowDetails(true))}
      >
        <div className='coach-card-image'>
          <img
            src={avatarImgSrc}
            alt={`${coach.first_name} ${coach.last_name}`}
          />
          {saved && (
            <span className='coach-card-sash'>
              <span className='coach-card-sash-content'>
                <StarOutline />
                Saved
              </span>
            </span>
          )}
        </div>
        <div className='coach-card-body'>
          <Typography variant='h3' component='h2' sx={{ margin: 0 }}>
            {fullName}
          </Typography>
          {location && <span className='coach-card-location'>{location}</span>}
          {coach.biography && (
            <div className='coach-card-bio'>
              {coach.biography?.split('\n').map((text, i) => {
                return (
                  <Typography variant='body2' color='grey.700' key={i}>
                    {text}
                  </Typography>
                );
              })}
            </div>
          )}
          <Divider textAlign='left' sx={{ marginBottom: '10px' }}>
            <Typography variant='h5' component='h3' color='grey.700'>
              Skills
            </Typography>
          </Divider>
          <ul className='coach-card-topics'>
            {coach.coaching_topics.map(id => {
              const topic = vm.topic(id);
              if (!topic) return null;

              const match = vm.selectedTopics.has(topic.id);
              let classes = 'topic-badge';
              if (match) {
                classes += ' topic-badge-match';
              }
              return (
                <li key={topic.id} className={classes}>
                  {match && <CheckCircleOutlined />}
                  {topic.title}
                </li>
              );
            })}
          </ul>
          <footer
            className='coach-card-footer'
            onClick={e => e.stopPropagation()}
          >
            <CoachViewModalButton
              coach={coach}
              buttonText={`View ${v('coach', true)}`}
              isBooking={false}
              tabDisabled
            />
          </footer>
        </div>
      </div>
      {showDetails && (
        <CoachViewModal
          coach={coach}
          isBooking={false}
          onClose={() => {
            setShowDetails(false);
            ref.current?.focus();
          }}
        />
      )}
    </>
  );
};
CoachCard = observer(CoachCard);

// TODO(zuko): refactor this to use a more reusable keyboarding abstraction.
const useKeyboardNavigation = (
  ref: React.MutableRefObject<HTMLElement>,
  columnsRef: React.MutableRefObject<number>,
) => {
  React.useEffect(() => {
    let node = ref.current;
    if (!node) return;

    let handleKeydown = (e: KeyboardEvent) => {
      let dx = e.key === 'ArrowLeft' ? -1 : e.key === 'ArrowRight' ? 1 : 0;
      let dy = e.key === 'ArrowUp' ? -1 : e.key === 'ArrowDown' ? 1 : 0;
      if (!dx && !dy) return;

      let curr = document.activeElement?.closest<HTMLLIElement>('.coach-card');
      if (!curr) return;

      let cards = Array.from(
        node.querySelectorAll<HTMLLIElement>('.coach-card'),
      );
      let idx = cards.indexOf(curr);
      if (idx === -1) return;

      if (dx) {
        idx += dx;
      } else if (dy === -1) {
        idx -= columnsRef.current;
      } else if (dy === 1) {
        idx += columnsRef.current;
      }
      // Wrap around if next index is out of bounds. This doesn't handle
      // preserving the current column. I think this is a good enough
      // improvement over what we had before, but if somebody wants to
      // tackle the vertical navigation wrapping please feel free :).
      if (idx < 0) {
        idx = cards.length - 1;
      } else if (idx >= cards.length) {
        idx = 0;
      }
      cards[idx]?.focus();
    };
    node.addEventListener('keydown', handleKeydown);
    return () => {
      node.removeEventListener('keydown', handleKeydown);
    };
  }, [ref, columnsRef]);
};

export default CoachSearchView;
