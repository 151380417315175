import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Button } from '@src/components/sc-design-system';
import { availableIcons } from '@src/components/sc-design-system/Icon';
import { StyledCard } from './StyledComponents';

interface EmptySectionProps {
  header: string;
  description: string;
  buttonText: string;
  buttonIcon?: keyof typeof availableIcons;
  buttonOnClick?: Function;
}

const EmptySection: FC<EmptySectionProps> = ({
  header,
  description,
  buttonText,
  buttonIcon,
  buttonOnClick,
}) => {
  return (
    <StyledCard>
      <Stack
        direction='column'
        justifyContent='center'
        textAlign='center'
        spacing={1}
      >
        <Typography variant='h4'>{header}</Typography>
        <Typography variant='body1' color='grey.700'>
          {description}
        </Typography>
        <div style={{ marginTop: '1.5em' }}>
          <Button
            text={buttonText}
            startIcon={buttonIcon}
            size='large'
            onClick={() => buttonOnClick()}
          />
        </div>
      </Stack>
    </StyledCard>
  );
};

export { EmptySection };
