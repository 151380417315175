import React, { FC, KeyboardEvent, FocusEvent } from 'react';
import { Form, Input } from 'antd';
import { observer } from 'mobx-react';
import { Typography } from '@mui/material';

export interface TextareaFieldPropsIF {
  name?: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  value: (string | number | readonly string[]) & string;
  rules?: Array<any>;
  rows?: number;
  onChange: Function;
  onKeyPress?: Function;
  onFocus?: Function;
  onBlur?: Function;
  autoFocus?: boolean;
  validateStatus?: any;
  validateTrigger?: any;
}

const TextareaField: FC<TextareaFieldPropsIF> = observer(props => {
  const {
    name,
    label,
    placeholder,
    value,
    rules,
    rows,
    onChange,
    onKeyPress,
    onBlur,
    onFocus,
    validateStatus,
    validateTrigger,
  } = props;
  const appliedRules = rules || [];
  const appliedValidateTrigger = validateTrigger || ['onChange', 'onBlur'];
  return (
    <Form.Item
      label={label ? label.toString() : undefined}
      name={name || label}
      rules={appliedRules}
      validateTrigger={appliedValidateTrigger}
      help={
        <Typography variant='body2' color='error'>
          {validateStatus}
        </Typography>
      }
      style={{ width: '100%' }}
      initialValue={value}
      className='sc-textarea'
    >
      <Input.TextArea
        style={{ width: '100%' }}
        onKeyPress={(e: KeyboardEvent) => onKeyPress && onKeyPress(e)}
        onFocus={(e: FocusEvent) => onFocus && onFocus(e)}
        onBlur={(e: FocusEvent) => onBlur && onBlur(e)}
        value={value || ''}
        placeholder={placeholder || label}
        onChange={(e: any) => {
          onChange(e.target.value);
        }}
        rows={rows || 3}
      />
    </Form.Item>
  );
});

export { TextareaField };
