import React from 'react';
import { Chip } from '@mui/material';

interface RatingChipProps {
  hasEvaluationPeriodEnded: boolean;
}

const RatingChip: React.FunctionComponent<RatingChipProps> = ({
  hasEvaluationPeriodEnded,
}) => {
  return (
    <Chip
      color={hasEvaluationPeriodEnded ? 'warning' : 'default'}
      label={hasEvaluationPeriodEnded ? 'Not submitted' : 'Not Started'}
      sx={{ width: 'fit-content' }}
    />
  );
};

export { RatingChip };
