import React from 'react';
import { observer } from 'mobx-react';

import CoachHome from './components/CoachHome/CoachHome';
import CoacheeHome from './components/CoacheeHome/CoacheeHome';
import { useLocation } from 'react-router';

const Home = () => {
  const location = useLocation();

  return (
    <div>
      {location.pathname.includes('/my-programs') ? (
        <CoacheeHome />
      ) : (
        <CoachHome />
      )}
    </div>
  );
};

export default observer(Home);
