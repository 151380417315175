import React, { FC, useEffect, useMemo, useState } from 'react';
import _partition from 'lodash/partition';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ListAltIcon from '@mui/icons-material/ListAlt';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import {
  UserProgramCard,
  UserPlatformProgramCard,
} from '@src/components/common/UserProgramCard';
import { UpNextCard } from '@src/components/common/UpNextCard/UpNextCard';
import SavedCoachesList from '@src/components/common/SavedCoachesList/SavedCoachesList';
import { useIntercom } from 'react-use-intercom';
import { api } from '@src/lib/client';
import { SessionDetailsModal } from '@src/components/common/SessionDetails/SessionDetailsModal';
import { SessionDetailsViewModel } from '@src/components/common/SessionDetails/SessionDetailsViewModel';
import {
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
  Card,
  Button,
  Spin,
} from '@src/components/sc-design-system';
import { Typography, Tabs, Tab, Stack, Divider } from '@mui/material';
import {
  TabPanelWrapper,
  generateTabProps,
} from '@src/components/common/TabPanelWrapper';
import { useQueryParams } from '@src/utils';
import { useUser } from '@src/hooks/useUser';
import { useUserQueries } from '@src/hooks/useUserQueries';
import dayjs from 'dayjs';
import User from '@src/models/User';
import { IProgram } from '@src/components/common/UserProgramCard/types';

const CoacheeHome: FC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { useGetUserPrograms } = useUserQueries(user.id);
  const { userPrograms, isLoading: isLoadingPrograms } =
    useGetUserPrograms('coachee');

  const [currentPrograms, pastPrograms] = useMemo(() => {
    if (isLoadingPrograms) return [];
    return _partition(
      userPrograms?.sort((a: any, b: any) => {
        if (a.is_active === b.is_active) {
          if (a?.start_date > b?.start_date) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return a.is_active ? -1 : 1;
        }
      }),
      p => dayjs().isBefore(p.end_date),
    );
  }, [isLoadingPrograms, userPrograms]);

  const tabs = useMemo(() => {
    if (isLoadingPrograms) return [];
    const tabItems = [{ label: 'Active', ...generateTabProps(0) }];
    if (pastPrograms?.length > 0) {
      tabItems.push({ label: 'Past', ...generateTabProps(1) });
    }
    return tabItems;
  }, [pastPrograms, isLoadingPrograms]);

  const query = useQueryParams();
  const session_id = query.get('session_id');
  const [loadingSavedCoaches, setLoadingSavedCoaches] = useState(false);
  const [session, setSession] = useState(undefined);
  const [programType, setProgramType] = useState(undefined);
  const [loadingSession, setLoadingSession] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const getSavedAndSelectedCoaches = async () => {
    setLoadingSavedCoaches(true);
    await user.getSavedAndSelectedCoaches();
    await user.getInterviews('coachee');
    setLoadingSavedCoaches(false);
  };

  const getSession = async () => {
    setLoadingSession(true);
    const res = await api.sessions.get({ sessionId: session_id });
    const sessionData = res.data.data[0];
    sessionData.members = sessionData.members.map(data => {
      if (sessionData.member_info[data.id])
        return { ...data, ...sessionData.member_info[data.id] };
      return data;
    });
    const p = userPrograms?.map(p => {
      if (p.id === res.data.data[0].program_id) {
        return p.type;
      }
    });

    setSession(
      new SessionDetailsViewModel(
        user.id,
        'coachee',
        sessionData,
        sessionData.program_info.type,
      ),
    );
    if (p?.length) {
      setProgramType(p[0]);
    }

    setLoadingSession(false);
  };

  useEffect(() => {
    getSavedAndSelectedCoaches();
    if (session_id) {
      (async function () {
        await getSession();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const intercom = useIntercom();

  return (
    user && (
      <View>
        <ViewHeader
          title='Learning and Development'
          titleIcon={<ListAltIcon fontSize='large' />}
        />
        <ViewBody>
          <ViewBodySection title='Overview'>
            <Card titleIcon={<ScheduleIcon fontSize='large' />} title='Up Next'>
              <UpNextCard scRole='coachee' />
            </Card>
            {session &&
            session.current_status === 'completed' &&
            !loadingSession ? (
              <SessionDetailsModal
                session={session}
                userRole='coachee'
                open={session && !loadingSession}
                onClose={() => {
                  setSession(undefined);
                  setProgramType(undefined);
                  navigate('/my-programs');
                }}
                programType={programType}
              />
            ) : null}
            <Card
              titleIcon={<ListAltIcon fontSize='large' />}
              title='My Programs'
            >
              {isLoadingPrograms && <Spin sectionLoader />}
              {userPrograms?.length === 0 && !isLoadingPrograms ? (
                <div style={{ textAlign: 'center' }}>
                  <Typography
                    variant='body1'
                    color='grey.700'
                    sx={{ marginBottom: '1em' }}
                  >
                    You have not been added to a program yet
                  </Typography>
                  <Button
                    onClick={() => {
                      intercom.show();
                    }}
                    text='Contact Support'
                  />
                </div>
              ) : (
                <>
                  {tabs?.length > 1 && (
                    <Tabs
                      value={activeTab}
                      onChange={(_, newTab) => {
                        setActiveTab(newTab);
                      }}
                      sx={{ marginBottom: '1em' }}
                    >
                      {tabs.map(tab => (
                        <Tab key={tab.label} label={tab.label} />
                      ))}
                    </Tabs>
                  )}
                  <TabPanelWrapper value={activeTab} index={0}>
                    {currentPrograms?.length === 0 ? (
                      <div style={{ textAlign: 'center' }}>
                        <Typography
                          variant='body1'
                          color='grey.700'
                          sx={{ marginBottom: '1em' }}
                        >
                          No active programs
                        </Typography>
                        <Button
                          onClick={() => {
                            intercom.show();
                          }}
                          text='Contact Support'
                        />
                      </div>
                    ) : (
                      <Stack spacing={4} divider={<Divider />}>
                        {Boolean(currentPrograms?.length) &&
                          currentPrograms.map(program => (
                            <ProgramCard
                              key={program.id + program.coachee.id}
                              program={program}
                              user={user}
                            />
                          ))}
                      </Stack>
                    )}
                  </TabPanelWrapper>
                  <TabPanelWrapper value={activeTab} index={1}>
                    <PastProgramsTab pastPrograms={pastPrograms} user={user} />
                  </TabPanelWrapper>
                </>
              )}
            </Card>
            <Card
              titleIcon={<StarOutlineIcon fontSize='large' />}
              title={`Saved ${
                userPrograms?.some(cp => cp.type === 'mentoring')
                  ? 'Coaches and Mentors'
                  : 'Coaches'
              }`}
            >
              {user.saved_and_selected_coaches?.length &&
              !loadingSavedCoaches ? (
                <SavedCoachesList />
              ) : loadingSavedCoaches ? (
                <Spin sectionLoader />
              ) : (
                <Typography
                  variant='body1'
                  color='grey.700'
                  sx={{ textAlign: 'center' }}
                >
                  You haven't saved or selected any{' '}
                  {userPrograms?.some(cp => cp.type === 'mentoring')
                    ? 'coaches or mentors'
                    : 'coaches'}
                </Typography>
              )}
            </Card>
          </ViewBodySection>
        </ViewBody>
      </View>
    )
  );
};

const ProgramCard: FC<{ program: IProgram; user: User }> = ({
  program,
  user,
}) => {
  return program.type === 'platform_only' ? (
    <UserPlatformProgramCard program={program} />
  ) : (
    <UserProgramCard user={user} program={program} scRole='coachee' />
  );
};

const PastProgramsTab: FC<{
  pastPrograms: IProgram[];
  user: User;
}> = ({ pastPrograms, user }) => {
  const [showAllPrograms, setShowAllPrograms] = useState(false);
  const programs = useMemo(() => {
    if (showAllPrograms) return pastPrograms;
    return pastPrograms.slice(0, 2);
  }, [pastPrograms, showAllPrograms]);

  return (
    <Stack spacing={4} divider={<Divider />}>
      {Boolean(programs?.length) &&
        programs.map(program => (
          <ProgramCard
            key={program.id + program.coachee.id}
            program={program}
            user={user}
          />
        ))}
      {pastPrograms?.length > 2 && (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Button
            onClick={() => setShowAllPrograms(!showAllPrograms)}
            text={showAllPrograms ? 'Show less' : 'Show more'}
            variant='outlined'
            formatText={false}
          />
        </div>
      )}
    </Stack>
  );
};

export default observer(CoacheeHome);
