import { SurveyQuestionType } from '@shared/schemas/src/survey';

interface QuestionGroupType {
  title: string;
  helpText?: string;
  questions: any[];
}

interface UISurveyQuestionType extends SurveyQuestionType {
  group?: QuestionGroupType;
}

interface Answer {
  question_id: string;
  answer: any;
}

type Expression =
  | { type: 'or'; condition: Expression[] }
  | { type: 'and'; condition: Expression[] }
  | { type: 'eq'; left: Expression; right: Expression }
  | { type: 'includes'; left: Expression; right: Expression }
  | { type: 'excludes'; left: Expression; right: Expression }
  | { type: 'lt'; left: Expression; right: Expression }
  | { type: 'lte'; left: Expression; right: Expression }
  | { type: 'gt'; left: Expression; right: Expression }
  | { type: 'gte'; left: Expression; right: Expression }
  | { type: 'literal'; value: any }
  | { type: 'answer'; value: string };

export const evaluateQuestion = (
  question: UISurveyQuestionType,
  answers: Answer[],
) => {
  const evaluate = (expression: Expression): boolean => {
    switch (expression.type) {
      case 'or':
        for (let exp of expression.condition) {
          if (evaluate(exp)) {
            return true;
          }
        }
        return false;
      case 'and':
        for (let exp of expression.condition) {
          if (!evaluate(exp)) {
            return false;
          }
        }
        return true;
      case 'eq':
        return evaluate(expression.left) === evaluate(expression.right);
      case 'includes': {
        const value = evaluate(expression.left);
        return (
          typeof value === 'string' || Array.isArray(value) ? value : []
        ).includes(evaluate(expression.right));
      }
      case 'excludes': {
        const value = evaluate(expression.left);
        return !(
          typeof value === 'string' || Array.isArray(value) ? value : []
        ).includes(evaluate(expression.right));
      }
      case 'lt':
        return evaluate(expression.left) < evaluate(expression.right);
      case 'lte':
        return evaluate(expression.left) <= evaluate(expression.right);
      case 'gt':
        return evaluate(expression.left) > evaluate(expression.right);
      case 'gte':
        return evaluate(expression.left) >= evaluate(expression.right);
      case 'answer':
        return answers.find(q => q.question_id === expression.value)?.answer;
      case 'literal':
        return expression.value;
      default:
        throw Error('Unknow expression type');
    }
  };

  if (!question.condition) return true;
  for (let expression of question.condition) {
    let result = evaluate(expression);
    if (!result) return false;
  }
  return true;
};
