import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Link, Stack, Typography, Divider } from '@mui/material';
import findIndex from 'lodash/findIndex';
import startCase from 'lodash/startCase';
import { Grid } from '@src/components/sc-design-system';
import dayjs from 'dayjs';
import ActiveInactiveChip from '../../ActiveInactiveChip';

interface GoalAlignmentProps {
  selectedGoalCycle: GoalCycleWithGoals & { performanceCycleId?: string };
  hasMultipleCycles: boolean;
  onClickChange: Function;
  onSelectGoal: Function;
  alignedGoalId?: string | null;
}

const GoalAlignment: FC<GoalAlignmentProps> = observer(
  ({
    selectedGoalCycle,
    hasMultipleCycles,
    onClickChange,
    onSelectGoal,
    alignedGoalId,
  }) => {
    const filteredGoals = selectedGoalCycle.goals?.filter(
      g => !g.user_ids?.length,
    );
    const isClientCycle = Boolean(selectedGoalCycle.performanceCycleId);

    return (
      <>
        <Stack
          direction='row'
          justifyContent='space-between'
          sx={{ marginBottom: '0.75em' }}
        >
          <Typography variant='h4' style={{ margin: 0 }}>
            Where does your goal live?
          </Typography>
          {hasMultipleCycles && (
            <Link
              component='button'
              variant='a2'
              onClick={() => onClickChange()}
            >
              Change
            </Link>
          )}
        </Stack>
        <Grid
          cols={1}
          data={[selectedGoalCycle]}
          renderer={cycle => (
            <div style={{ width: '100%' }}>
              <Stack direction='row' spacing={2}>
                <Typography
                  variant='h4'
                  sx={{ margin: 0 }}
                  color='primary.main'
                >
                  {cycle.name}
                </Typography>
                {isClientCycle && (
                  <ActiveInactiveChip
                    active={
                      !cycle.start || dayjs().isBetween(cycle.start, cycle.end)
                    }
                  />
                )}
              </Stack>
              {isClientCycle && Boolean(cycle.start && cycle.end) && (
                <Typography
                  variant='subtitle2'
                  sx={{ marginTop: '0 !important' }}
                >
                  {dayjs(cycle.start).format('MMM DD, YYYY')} -{' '}
                  {dayjs(cycle.end).format('MMM DD, YYYY')}
                </Typography>
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: cycle.description,
                }}
              />
            </div>
          )}
        />
        {isClientCycle && filteredGoals?.length > 0 && (
          <>
            <Typography variant='h4' style={{ margin: '.5em 0' }}>
              Does this align to an organization goal?
            </Typography>
            <Typography variant='body2' style={{ margin: '.5em 0' }}>
              If this goal supports an existing organizational goal from{' '}
              {selectedGoalCycle.name}, select it below.
            </Typography>
            <Grid
              data={filteredGoals}
              isSelectable
              onSelectItem={goal => {
                onSelectGoal(alignedGoalId === goal.id ? null : goal.id);
              }}
              selectedItem={
                alignedGoalId
                  ? findIndex(
                      filteredGoals,
                      (g: Goal) => g.id === alignedGoalId,
                    )
                  : null
              }
              renderer={goal => (
                <div>
                  <Typography
                    variant='h4'
                    sx={{ margin: 0 }}
                    color='primary.main'
                  >
                    {goal.title}
                  </Typography>
                  <Stack
                    direction='row'
                    spacing={2}
                    divider={<Divider orientation='vertical' flexItem />}
                    sx={{ marginBottom: '.25em' }}
                  >
                    <Typography variant='body2' color='grey.700'>
                      {startCase(goal.type)}
                    </Typography>
                    <Typography variant='body2' color='grey.700'>
                      By {dayjs(goal.target_date).format('M/D/YY')}
                    </Typography>
                  </Stack>
                  <div
                    className='description'
                    dangerouslySetInnerHTML={{ __html: goal.description }}
                  />
                </div>
              )}
            />
          </>
        )}
      </>
    );
  },
);

export { GoalAlignment };
