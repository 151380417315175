import React, { FC } from 'react';
import {
  Statistic as AntdStatistic,
  StatisticProps as AntdStatisticsProps,
} from 'antd';

export interface StatisticProps extends AntdStatisticsProps {
  size?: 'small' | 'medium' | 'large';
}

const Statistic: FC<StatisticProps> = ({ size = 'medium', ...props }) => {
  return (
    <AntdStatistic
      {...props}
      className={`size-${size}`}
      valueStyle={{ fontWeight: 400 }}
    />
  );
};

export { Statistic };
