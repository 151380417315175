import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import _groupBy from 'lodash/groupBy';
import _toPairs from 'lodash/toPairs';
import dayjs from 'dayjs';
import { useIntercom } from 'react-use-intercom';
import {
  IconButton,
  Typography,
  Link,
  Stack,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  Switch,
} from '@mui/material';
import RepeatIcon from '@mui/icons-material/Repeat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import LockOutlined from '@mui/icons-material/LockOutlined';
import OpenInNew from '@mui/icons-material/OpenInNew';
import {
  Card,
  Table,
  Button,
  useBreadcrumbs,
  ConfirmDialog,
  DialogTitle,
  Select,
} from '@src/components/sc-design-system';
import { Survey } from '@src/components/common/Survey/Survey';
import SurveyType from '@src/models/Survey';
import { useStore } from '@src/models/Store';
import { ProgramViewModel } from './ProgramViewModel';
import {
  alignProgramSurveysWithTypes,
  surveyTypes,
} from '@src/lib/surveyTypes';
import { useNavigate } from 'react-router-dom';

const ProgramSurveys: FC<{ vm: ProgramViewModel }> = observer(({ vm }) => {
  const intercom = useIntercom();
  const store = useStore();
  const [previewSurvey, setPreviewSurvey] = useState<SurveyType | null>(null);
  const [changeType, setChangeType] = useState<string | null>(null);
  const [loadingSwitchId, setLoadingSwitchId] = useState<string | null>(null);
  const rows = alignProgramSurveysWithTypes(vm.surveys, vm.program);
  const navigate = useNavigate();
  const { resetBreadcrumbs } = useBreadcrumbs();
  return (
    <Card title='Program Surveys'>
      <Typography sx={{ marginBottom: '2em' }}>
        Below are the surveys associated with your program. If you wish to build
        or edit a survey, you can do so in Surveys & Insights.
      </Typography>
      <Table
        dataSource={rows}
        hidePagination
        columns={[
          {
            title: 'Survey',
            width: '25%',
            render: (_, survey) => {
              return (
                <Stack gap={0.5}>
                  <Stack direction='row' alignItems='center' gap={0.5}>
                    <Typography
                      variant='body2'
                      sx={{ marginBottom: '0 !important' }}
                    >
                      {surveyTypes[survey?.type]?.label}
                    </Typography>
                    {surveyTypes[survey?.type]?.helpText && (
                      <Tooltip title={surveyTypes[survey?.type]?.helpText}>
                        <InfoOutlined sx={{ height: '.7em', width: '.7em' }} />
                      </Tooltip>
                    )}
                  </Stack>
                  <Link
                    component='button'
                    variant='a2'
                    sx={{ textAlign: 'start', fontSize: '12px' }}
                    onClick={() => setPreviewSurvey(survey)}
                  >
                    {survey?.title}
                    <OpenInNew
                      fontSize='small'
                      sx={{ height: '.7em', width: '.7em' }}
                    />
                  </Link>
                </Stack>
              );
            },
          },
          {
            title: 'Change',
            width: '15%',
            align: 'center',
            render: (_, survey) => {
              return survey?.type === 'intake' ? (
                <Tooltip
                  title={
                    <Stack spacing={1}>
                      <Typography variant='body2'>
                        The intake survey is not currently customizable. The
                        SkillCycle intake survey is the default for all
                        programs.
                      </Typography>
                      <div style={{ marginLeft: 'auto' }}>
                        <Button
                          text='Contact Support'
                          onClick={() => intercom.show()}
                          sx={{ width: 'fit-content' }}
                          size='small'
                        />
                      </div>
                    </Stack>
                  }
                >
                  <LockOutlined />
                </Tooltip>
              ) : survey?.responses > 0 && !store.user.is_admin ? (
                <Tooltip
                  title={
                    <Stack spacing={1}>
                      <Typography variant='body2'>
                        This survey is locked because responses have been
                        collected. If you require assistance, please reach out
                        to support@skillcycle.com.
                      </Typography>
                      <div style={{ marginLeft: 'auto' }}>
                        <Button
                          text='Contact Support'
                          onClick={() => intercom.show()}
                          sx={{ width: 'fit-content' }}
                          size='small'
                        />
                      </div>
                    </Stack>
                  }
                >
                  <LockOutlined />
                </Tooltip>
              ) : (
                <IconButton
                  onClick={() => {
                    setChangeType(survey.type);
                  }}
                >
                  <RepeatIcon />
                </IconButton>
              );
            },
          },
          {
            title: 'Enabled for Program',
            width: '15%',
            render: (_, survey) => {
              return (
                <Switch
                  disabled={
                    survey?.type === 'intake' || loadingSwitchId === survey?.id
                  }
                  checked={
                    survey?.type === 'intake' ||
                    vm.program.surveys?.[survey?.type]?.enabled
                  }
                  onChange={e => {
                    setLoadingSwitchId(survey?.id);
                    vm.program.toggleSurveyEnabled(
                      survey?.type,
                      survey?.id,
                      e.target.checked,
                    );
                    setLoadingSwitchId(null);
                  }}
                />
              );
            },
          },
          {
            title: 'Responses',
            width: '15%',
            dataIndex: 'responses',
            render: responses => {
              return `${responses} Response${responses === 1 ? '' : 's'}`;
            },
          },
          {
            title: 'Insights',
            width: '15%',
            align: 'center',
            render: (_, survey) => (
              <IconButton
                onClick={() => {
                  navigate(
                    `/insights/${vm.program.client_id}?sid=${survey.id}`,
                  );
                  resetBreadcrumbs([]);
                }}
              >
                <TrendingUpIcon />
              </IconButton>
            ),
          },
        ]}
      />
      {previewSurvey && (
        <Survey
          previewSurvey={previewSurvey}
          previewQuestions={previewSurvey?.questions}
          handleClose={() => {
            setPreviewSurvey(null);
          }}
        />
      )}
      {changeType && (
        <SurveySelectModal
          open={Boolean(changeType)}
          type={changeType}
          surveys={vm.surveys}
          handleClose={async (reload: boolean) => {
            setChangeType(null);
            if (reload) await vm.loadSurveyResponseCounts();
          }}
          program={vm.program}
        />
      )}
    </Card>
  );
});

const SurveySelectModal = observer(
  ({ type, open, handleClose, surveys, program }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [previewSurvey, setPreviewSurvey] = useState<SurveyType | null>(null);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [selectedSurveyId, setSelectedSurveyId] = useState(
      program.surveys?.[type]?.id,
    );
    const label = surveyTypes[type]?.label;
    const groups = _toPairs(_groupBy(surveys, s => s.survey_id));
    const options = groups.map(([_, versions]) => ({
      label: versions[0].title,
      options: versions
        .sort((a, b) => b.published_at - a.published_at)
        .map((s, idx) => {
          const optionLabel = isSelectOpen
            ? dayjs(s.published_at).format('dddd, MMM DD, YYYY, h:mm:ss a')
            : `${s.title} (${dayjs(s.published_at).format(
                'dddd, MMM DD, YYYY, h:mm:ss a',
              )})`;
          return {
            label: `${optionLabel}
              ${
                idx === 0 && versions.length > 1 && isSelectOpen
                  ? ' (LATEST)'
                  : ''
              }`,
            value: s.id,
            notes: s.notes || '',
          };
        }),
    }));
    options.sort((a, b) => {
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase();
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });

    return (
      <>
        <Dialog open={open} maxWidth='sm' onClose={() => handleClose()}>
          <DialogTitle
            title={`Change ${label}`}
            buttonOnClick={() => handleClose()}
          />
          <DialogContent sx={{ overflow: 'hidden' }}>
            <Stack spacing={2}>
              <Select
                label={label}
                options={options}
                name='survey'
                value={selectedSurveyId || program.surveys?.[type]?.id}
                onChange={e => {
                  setSelectedSurveyId(e.target.value);
                  setIsSelectOpen(false);
                }}
                onClose={() => {
                  setIsSelectOpen(false);
                }}
                sx={{ maxWidth: '85%' }}
                fullWidth
                MenuProps={{
                  sx: { maxHeight: '45vh' },
                }}
              />
              <Button
                startIcon={<VisibilityOutlined />}
                text='Preview Survey'
                variant='outlined'
                onClick={() =>
                  setPreviewSurvey(
                    surveys.find(({ id }) => id === selectedSurveyId),
                  )
                }
                sx={{ width: 'fit-content' }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              key='cancel'
              onClick={() => handleClose()}
              variant='outlined'
              text='Cancel'
            />
            <>
              <Button
                text='Update'
                loading={isSaving}
                disabled={isSaving}
                onClick={() => setOpenConfirm(true)}
              />
              {openConfirm && (
                <ConfirmDialog
                  open={openConfirm}
                  setOpenModal={setOpenConfirm}
                  title='Confirm'
                  body='This update will make this an active survey on the program. Once any responses are received, this selection cannot be changed.'
                  buttonProps={{
                    text: 'Update',
                    onClick: async () => {
                      setIsSaving(true);
                      program.surveys = {
                        ...program.surveys,
                        [type]: {
                          enabled: program.surveys[type]?.enabled || false,
                          id: selectedSurveyId,
                        },
                      };
                      await program.updateSurveyConfig();
                      setIsSaving(false);
                      handleClose(true);
                    },
                  }}
                />
              )}
            </>
          </DialogActions>
        </Dialog>
        {previewSurvey && (
          <Survey
            previewSurvey={previewSurvey}
            previewQuestions={previewSurvey?.questions}
            handleClose={() => {
              setPreviewSurvey(null);
            }}
          />
        )}
      </>
    );
  },
);

export { ProgramSurveys };
