import React, { useMemo } from 'react';
import { Card, Button, Spin } from '@src/components/sc-design-system';
import { Typography, Link } from '@mui/material';
import FeedOutlined from '@mui/icons-material/FeedOutlined';
import AddIcon from '@mui/icons-material/Add';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { FeedbackFormModal } from './FeedbackFormModal';
import { useParams } from 'react-router';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { SurveyType } from '@shared/schemas';
import { Survey } from '@src/components/common/Survey/Survey';
import { useStore } from '@src/models/Store';

const FeedbackForm: React.FunctionComponent<{
  performanceCycle: PerformanceCycleI;
  refetchPerformanceCycle: Function;
}> = props => {
  const { performanceCycle, refetchPerformanceCycle } = props;
  const feedbackFormSet = !!performanceCycle.ongoing_feedback_form_id;
  return feedbackFormSet ? (
    <ViewFeedbackForm
      performanceCycle={performanceCycle}
      refetchPerformanceCycle={refetchPerformanceCycle}
    />
  ) : (
    <AddFeedbackForm
      performanceCycle={performanceCycle}
      refetchPerformanceCycle={refetchPerformanceCycle}
    />
  );
};

interface AddFeedbackFormProps {
  performanceCycle: PerformanceCycleI;
  refetchPerformanceCycle: Function;
}

const AddFeedbackForm: React.FunctionComponent<
  AddFeedbackFormProps
> = props => {
  const { performanceCycle, refetchPerformanceCycle } = props;
  const [openModal, setOpenModal] = React.useState(false);
  return (
    <Card
      variant='elevation'
      title='Feedback form'
      titleIcon={<FeedOutlined />}
      titleStyle='h2styleh4'
      sx={{ margin: '2em 0', padding: '.5em' }}
    >
      <Typography variant='body1' color='grey.700' sx={{ margin: '.5em 0' }}>
        Set a form for members to gather feedback on from their direct managers,
        direct reports, and/or peers.
      </Typography>
      <FeedbackFormModal
        open={openModal}
        handleClose={setOpenModal}
        performanceCycle={performanceCycle}
        refetchPerformanceCycle={refetchPerformanceCycle}
      />
      <Button
        sx={{ marginTop: '1em' }}
        size='small'
        variant='outlined'
        startIcon={<AddIcon />}
        text='Set form'
        onClick={() => setOpenModal(true)}
      />
    </Card>
  );
};

interface ViewFeedbackFormProps {
  performanceCycle: PerformanceCycleI;
  refetchPerformanceCycle: Function;
}

const ViewFeedbackForm: React.FunctionComponent<ViewFeedbackFormProps> = ({
  performanceCycle,
  refetchPerformanceCycle,
}) => {
  const { user } = useStore();
  const { id: clientId } = useParams();
  const [openModal, setOpenModal] = React.useState(false);
  const [previewSurvey, setPreviewSurvey] = React.useState<SurveyType | null>(
    null,
  );
  const { useGetClientPublishedSurveys, useGetPerformanceCycleFeedbackStatus } =
    useClientQueries(clientId);
  const { surveys } = useGetClientPublishedSurveys();
  const survey = React.useMemo(() => {
    return surveys?.find(s => {
      return s.id === performanceCycle.ongoing_feedback_form_id;
    });
  }, [surveys, performanceCycle]);
  const { feedbackData, isLoading } = useGetPerformanceCycleFeedbackStatus(
    performanceCycle.id,
    performanceCycle.ongoing_feedback_form_id,
  );

  const isEditable = useMemo(() => {
    if (user?.is_admin) return true;
    if (isLoading) return false;
    return !feedbackData;
  }, [user, feedbackData, isLoading]);

  return (
    <Card
      variant='elevation'
      titleIcon={<FeedOutlined />}
      titleStyle='h2styleh4'
      title='Feedback form'
      titleActions={
        isEditable && (
          <Link
            key='edit-feedback-forms'
            variant='a1'
            onClick={() => setOpenModal(true)}
          >
            Edit
          </Link>
        )
      }
    >
      <Typography variant='body1sb' sx={{ marginBottom: '.5em' }}>
        Feedback
      </Typography>
      {survey ? (
        <Link
          variant='a1'
          onClick={() => setPreviewSurvey(survey)}
          component='button'
        >
          {survey?.title}
          <OpenInNew
            fontSize='small'
            sx={{
              marginLeft: '4px',
              marginBottom: '-3px',
              height: '.7em',
              width: '.7em',
            }}
          />
        </Link>
      ) : (
        <Spin />
      )}
      <FeedbackFormModal
        handleClose={setOpenModal}
        open={openModal}
        performanceCycle={performanceCycle}
        edit
        refetchPerformanceCycle={refetchPerformanceCycle}
      />

      {previewSurvey && (
        <Survey
          previewSurvey={previewSurvey}
          previewQuestions={previewSurvey?.questions}
          handleClose={() => {
            setPreviewSurvey(null);
          }}
        />
      )}
    </Card>
  );
};

export { FeedbackForm };
