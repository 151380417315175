import Jitsi from '@src/components/common/Jitsi/Jitsi';
import React from 'react';
import { useParams } from 'react-router-dom';
import './Meetings.less';

const Meeting = () => {
  const params = useParams();

  // Prevent the intercom launcher from covering up the meeting controls
  // on smaller screens.
  React.useEffect(() => {
    document.body.classList.add('hide-intercom');
    return () => {
      document.body.classList.remove('hide-intercom');
    };
  }, []);
  return <Jitsi meetingId={params.id} />;
};

export default Meeting;
