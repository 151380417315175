import React from 'react';
import dayjs from 'dayjs';
import { useMutation } from '@tanstack/react-query';
import { Drawer } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import Program from '@src/models/Program';
import GoalStatusIndicator from '@src/components/common/GoalCard/GoalStatusIndicator';
import GoalStatusUpdateFormHeader from '@src/components/common/GoalManagerDrawer/GoalStatusUpdateFormHeader';
import GoalStatusesTable from '@src/components/common/GoalManagerDrawer/GoalStatusesTable';
import GoalStatusUpdateForm from '@src/components/common/GoalManagerDrawer/GoalStatusUpdateForm';
import { GoalStatuses } from '@shared/schemas/src/goal';
import { useStore } from '@src/models/Store';
import { GoalDetailsTable } from './GoalDetailsTable';
import { Stack, Typography, Divider } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { api } from '@src/lib/client';
import { useToast, IconMenuButton } from '@src/components/sc-design-system';
interface GoalManagerDrawerProps {
  userId?: string;
  clientId?: string;
  open: false | 'edit' | 'add';
  onClose(): void;
  selectedGoalCycle: GoalCycleWithGoals & { performanceCycleId?: string };
  selectedGoal?: Goal | null;
  goalCycles?: GoalCycleWithGoals[];
  programs?: Program[];
  onSave(g: Goal): void;
  entityType: 'user' | 'client' | 'team_manager';
  onChangeCycle?: Function;
  alignedGoalId?: string | null;
  performanceCycleId?: string;
  onDeleteGoal: (g: Goal) => void;
  onClickGoalWeight?: (c: GoalCycleWithGoals) => void;
  canManageGoalWeights?: boolean;
}

interface TitleProps {
  title: string;
  status: GoalStatuses;
  handleDeleteGoal: () => void;
  entityType: 'user' | 'client' | 'team_manager';
  canDelete?: boolean;
}

function Title({
  title,
  status,
  handleDeleteGoal,
  entityType,
  canDelete,
}: TitleProps) {
  return (
    <Stack justifyContent='space-between' alignItems='center' direction='row'>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Typography variant='h3'>{title}</Typography>
        <GoalStatusIndicator status={status} />
      </Stack>
      {entityType === 'user' && canDelete && (
        <IconMenuButton
          items={[
            {
              key: 'delete',
              label: 'Delete',
              onClick: handleDeleteGoal,
            },
          ]}
        />
      )}
    </Stack>
  );
}

function generateInitialFormValues(props, selectedGoal, selectedGoalCycle) {
  return {
    id: selectedGoal?.id,
    title: selectedGoal?.title ?? '',
    type: selectedGoal?.type ?? '',
    description: selectedGoal?.description ?? '',
    target_date: selectedGoal?.target_date,
    program_ids: selectedGoal?.program_ids || props.programs?.map(p => p.id),
    goal_cycle_id: selectedGoalCycle?.id,
    user_ids:
      selectedGoal?.user_ids ||
      (props.entityType === 'user' ? [props.userId] : []),
    client_id:
      selectedGoal?.client_id ||
      (props.entityType === 'client' ||
      Boolean(selectedGoalCycle?.performanceCycleId)
        ? props.clientId
        : undefined),
    aligned_goal_id: selectedGoal?.aligned_goal_id || props.alignedGoalId,
    status_history: selectedGoal?.status_history || [],
  };
}

const GoalManagerDrawer: React.FunctionComponent<GoalManagerDrawerProps> =
  function (props) {
    const confirm = useConfirm();
    const toast = useToast();
    const store = useStore();
    const {
      onClose,
      selectedGoal,
      selectedGoalCycle,
      onClickGoalWeight,
      canManageGoalWeights = false,
      onDeleteGoal,
    } = props;
    const initialValues = generateInitialFormValues(
      props,
      selectedGoal,
      selectedGoalCycle,
    );

    const { mutateAsync: saveGoal } = useMutation({
      mutationFn: async (goal: Partial<Goal>) => {
        return api.goals_competencies.saveGoal(
          goal,
          props.performanceCycleId || selectedGoalCycle?.performanceCycleId,
        );
      },
      onSuccess: async ({ data: updatedGoal }) => {
        toast.success('Goal saved');
        if (props.onSave) await props.onSave(updatedGoal);
        setShowUpdateStatusForm(false);
      },
      onError() {
        toast.error('Could not save goal');
      },
    });

    const handleDeleteGoal = async () => {
      try {
        // TODO: abstract this
        await confirm({
          title: 'Delete this goal?',
          description: 'This action cannot be undone.',
          confirmationText: 'Delete',
          dialogProps: { maxWidth: 'xs' },
          cancellationButtonProps: {
            variant: 'outlined',
            className: 'shape-pill',
          },
          confirmationButtonProps: {
            className: 'shape-pill',
          },
        });
        onDeleteGoal(selectedGoal);
        onClose();
      } catch (e) {}
    };

    const [showUpdateStatusForm, setShowUpdateStatusForm] =
      React.useState(false);
    const [updatedGoalStatus, setUpdatedGoalStatus] =
      React.useState<GoalStatuses>(
        selectedGoal?.status_history?.[selectedGoal.status_history.length - 1]
          ?.status,
      );
    const [updatedGoalStatusNotes, setUpdatedGoalStatusNotes] =
      React.useState<string>();
    const descOrderStatusHistory = cloneDeep(
      selectedGoal?.status_history || [],
    ).reverse();
    const [formValues, setFormValues] = React.useState<{
      id?: string;
      title: string;
      type?: string;
      description?: string;
      target_date?: number;
      program_ids?: string[];
      goal_cycle_id?: string;
      user_ids?: string[];
      client_id?: string;
      aligned_goal_id?: string;
      status_history?: GoalStatusProps[];
    }>(initialValues);

    React.useEffect(
      function () {
        setFormValues(
          generateInitialFormValues(props, selectedGoal, selectedGoalCycle),
        );
      },
      [selectedGoal, props, selectedGoalCycle],
    );

    async function updateGoalStatus() {
      if (updatedGoalStatus) {
        const updatedFormValues = {
          ...formValues,
          status_history: [
            ...formValues?.status_history,
            {
              status: updatedGoalStatus,
              notes:
                updatedGoalStatusNotes !== ''
                  ? updatedGoalStatusNotes
                  : undefined,
              creator_id: store.user.id,
              creator_first_name: store.user.first_name,
              creator_last_name: store.user.last_name,
            },
          ],
        };
        await saveGoal(updatedFormValues as Goal);
      }
    }

    return (
      <Drawer
        title={
          <Title
            title={selectedGoal.title}
            status={
              selectedGoal?.status_history?.[
                selectedGoal.status_history.length - 1
              ]?.status
            }
            handleDeleteGoal={handleDeleteGoal}
            entityType={props.entityType}
            canDelete={
              !selectedGoalCycle?.goal_edit_deadline ||
              dayjs().isBefore(selectedGoalCycle?.goal_edit_deadline)
            }
          />
        }
        size='large'
        onClose={onClose}
        open={!!open}
        bodyStyle={{ padding: 0 }}
      >
        <GoalDetailsTable
          goal={selectedGoal}
          goalCycle={selectedGoalCycle}
          onClickGoalWeight={onClickGoalWeight}
          canManageGoalWeights={canManageGoalWeights}
        />
        <Divider />
        {props.entityType !== 'client' && (
          <React.Fragment>
            {!showUpdateStatusForm ? (
              <GoalStatusUpdateFormHeader
                selectedGoal={selectedGoal}
                setShowUpdateStatusForm={setShowUpdateStatusForm}
              />
            ) : (
              <GoalStatusUpdateForm
                setShowUpdateStatusForm={setShowUpdateStatusForm}
                setUpdatedGoalStatus={setUpdatedGoalStatus}
                updatedGoalStatus={updatedGoalStatus}
                setUpdatedGoalStatusNotes={setUpdatedGoalStatusNotes}
                updatedGoalStatusNotes={updatedGoalStatusNotes}
                updateGoalStatus={updateGoalStatus}
                goalCycle={selectedGoalCycle}
              />
            )}
            <GoalStatusesTable
              descOrderStatusHistory={descOrderStatusHistory}
            />
          </React.Fragment>
        )}
      </Drawer>
    );
  };

export { GoalManagerDrawer };
