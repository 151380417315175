import User from '@src/models/User';
import { Checkbox, Steps, Space } from 'antd';
import { observer } from 'mobx-react';
import React, { ReactChild, FC, useState } from 'react';
import Masonry from 'react-masonry-css';
import * as useVerbiage from '@shared/lib/src/use-verbiage';
import { useSkills } from '@src/hooks/useSkills';
import { DialogTitle, Button } from '@src/components/sc-design-system';
import {
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WandIcon from '@mui/icons-material/AutoFixHigh';

import './CoachSearchWizard.less';
import { handleKeyboardNav } from '@src/utils';
import { useFullScreen } from '@src/hooks/useFullScreen';

const topicGroups = {
  focusing_on_my_career_growth: [
    '1usbS0d9rrzLUOVgZgkA0',
    '_MZ2xS_o29rhfaYnesrKL',
    'ARjDxmJEl5y1xCg4m1c7j',
    '0BY5ESz-SNPO3qVl0CgQU',
  ],
  developing_my_leadership_skills: [
    'UDVcjI3lhV_sDMANF-wkr',
    '26X4DibAaslsy9WlXr0S_',
    'cwXSGEJmIGwWaCQlKXwyo',
    'Xzwcouk0b-0_75gum30_U',
    '5Pas_G0K92a6G1va6Sxm5',
    '5zLnhcWoC2_wl3FowkIi7',
    'e6DX8wZS5BwpZ3o2naR4v',
    'xL5Zn5PwOcr4a4VfBonEB',
  ],
  navigating_team_and_company_culture: [
    '4P8owtSKYVNaVTNJIe1GZ',
    'vEmbHK1hsxGgSEGVpbzSY',
    '3Ceww_m7TlHmX75hFT2ne',
    '0Ui7E3BXk5YJE9ss-JxL9',
  ],
  communicating_effectively: [
    'O54SrJwl8EaM0yrgoWp8w',
    'qSm2AyKMog2vSx0yqJwSt',
    '8T9lScClK0gv7EDHVZY7y',
  ],
  sharpening_my_professional_skills: [
    '7hN1d5LYD4bSOKYZLLCqe',
    '1VcWP1auwvZX2X7SZIRvp',
    'GsM3qc2PgjavcVAc7uwDt',
    'r4r2jNfPyeyyhhYTAJVp_',
    '0-D4-qfHMYpHtksmw7CjV',
    '915qJWniTyVvRFUMoaVlJ',
    'm_Ie2VgG00zzl0E4Yg8pX',
    'K5GMdqfMwAuT52JjxKk3j',
  ],
  working_on_myself: [
    '3yI1w6SVXRT2balHx70_A',
    'yR0uoW7Z7If4yFXDf6iXq',
    '1ocSmucnjFsS6sKvhuZry',
    'h9rz2AwG_vOrMYmuCssdt',
  ],
};

const CoachSearchWizard: FC<{
  user: User;
  children: ReactChild;
  onCompleted?: Function;
  programType: string;
}> = ({ user, children, onCompleted, programType }) => {
  const v = useVerbiage.init(programType === 'mentoring' ? ['isMentor'] : []);
  const { skills } = useSkills();
  const [isOpen, setIsOpen] = useState(user.search_topics?.length === 0);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<boolean | string>(
    false,
  );
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [isSavingToUser, setIsSavingToUser] = useState(false);
  const { fullScreen } = useFullScreen();

  const toggleModal = () => {
    setIsOpen(true);
    setSelectedCategory(false);
    setSelectedTopics([]);
    setCurrentStepIndex(0);
  };

  return (
    <>
      <Dialog
        className='coach-search-wizard'
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullScreen={fullScreen}
      >
        <DialogTitle
          title={
            <Stack spacing={1} direction='row'>
              <WandIcon />
              <Typography variant='h3'>
                {v('coach', true)} Search Wizard
              </Typography>
            </Stack>
          }
          buttonOnClick={() => setIsOpen(false)}
        />
        <DialogContent>
          <div className='steps-header'>
            <Steps
              current={currentStepIndex}
              items={[{}, {}]}
              responsive={false}
            />

            {currentStepIndex === 0 ? (
              <>
                <Typography
                  variant='h1'
                  sx={{ marginTop: '1.5em', marginBottom: '0.3em' }}
                >
                  {user.first_name}, Let&apos;s find your {v('coach')}.
                </Typography>
                <Typography variant='h3'>
                  In what area are you looking for support?
                </Typography>
              </>
            ) : (
              <Stack
                direction='row'
                alignItems='center'
                spacing={1}
                sx={{ margin: '1em 0 1em -4em' }}
              >
                <IconButton
                  onClick={() => {
                    setSelectedTopics([]);
                    setCurrentStepIndex(0);
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant='h2'>
                  Looking for {v('coaching')} in...
                </Typography>
              </Stack>
            )}
          </div>
          {currentStepIndex === 0 && (
            <div className='step-index-0'>
              <div className='topic-groups'>
                {Object.keys(topicGroups).map(name => (
                  <div
                    role='checkbox'
                    tabIndex={0}
                    aria-checked={selectedCategory === name}
                    key={name}
                    onClick={() => setSelectedCategory(name)}
                    onKeyDown={e =>
                      handleKeyboardNav(e.key, () => setSelectedCategory(name))
                    }
                    className={`topic-group ${
                      selectedCategory === name ? 'selected' : ''
                    }`}
                  >
                    {name.replace(/_/g, ' ')}
                  </div>
                ))}
              </div>
            </div>
          )}
          {currentStepIndex === 1 && (
            <div className='step-index-1'>
              {selectedCategory ? (
                <Grid
                  container
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Typography
                    variant='h3'
                    color='tertiary.main'
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {selectedCategory.toString()?.replace(/_/g, ' ')}
                  </Typography>
                  <Link
                    component='button'
                    variant='a1'
                    onClick={() => setSelectedCategory(false)}
                  >
                    View All Categories
                  </Link>
                </Grid>
              ) : (
                <Typography variant='h3' color='tertiary.main'>
                  Viewing all categories
                </Typography>
              )}
              <Typography variant='h4' className='topic-select-label'>
                Choose 1-5 skills from the list below.
              </Typography>
              <Space direction='vertical' className='topic-list'>
                {selectedCategory ? (
                  topicGroups[selectedCategory.toString()]?.map(id => (
                    <Checkbox
                      key={id}
                      checked={selectedTopics.includes(id)}
                      onChange={e => {
                        setSelectedTopics(
                          e.target.checked
                            ? [...selectedTopics, id]
                            : selectedTopics.filter(s => s !== id),
                        );
                      }}
                      disabled={
                        selectedTopics.length >= 5 &&
                        !selectedTopics.includes(id)
                      }
                    >
                      {skills.find(s => s.id === id)?.title}
                    </Checkbox>
                  ))
                ) : (
                  <Masonry
                    breakpointCols={{
                      default: 2,
                      700: 1,
                    }}
                    className='masonry-categories'
                    columnClassName='masonry-categories-column'
                  >
                    {Object.keys(topicGroups).map(topicGroup => (
                      <div key={topicGroup} className='topic-group'>
                        <strong>
                          {topicGroup.toString().replace(/_/g, ' ')}
                        </strong>
                        <Space direction='vertical' className='topic-list'>
                          {topicGroups[topicGroup].map(id => (
                            <Checkbox
                              key={id}
                              checked={selectedTopics.includes(id)}
                              onChange={e => {
                                setSelectedTopics(
                                  e.target.checked
                                    ? [...selectedTopics, id]
                                    : selectedTopics.filter(s => s !== id),
                                );
                              }}
                              disabled={
                                selectedTopics.length >= 5 &&
                                !selectedTopics.includes(id)
                              }
                            >
                              {skills.find(s => s.id === id)?.title}
                            </Checkbox>
                          ))}
                        </Space>
                      </div>
                    ))}
                  </Masonry>
                )}
              </Space>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              if (currentStepIndex === 0) {
                setSelectedTopics([]);
                setCurrentStepIndex(1);
              } else {
                if (selectedTopics.length > 0) {
                  setIsSavingToUser(true);
                  try {
                    user.search_topics = selectedTopics;
                    await user.save();
                  } catch (e) {}
                  setIsSavingToUser(false);
                  onCompleted && onCompleted();
                  setIsOpen(false);
                }
              }
            }}
            text={
              currentStepIndex === 0
                ? 'Next'
                : `Search for ${v('coaches', true)}`
            }
            disabled={
              (currentStepIndex === 0 && !selectedCategory) ||
              (currentStepIndex === 1 && selectedTopics.length === 0) ||
              isSavingToUser
            }
            loading={isSavingToUser}
          />
        </DialogActions>
      </Dialog>
      <span
        role='button'
        tabIndex={0}
        onClick={toggleModal}
        onKeyDown={e => handleKeyboardNav(e.key, toggleModal)}
      >
        {children}
      </span>
    </>
  );
};

export default observer(CoachSearchWizard);
