import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';

obey.creator('uuid', () => uuidv4());

export const ResourceSchema = {
  id: { type: 'string', required: true, creator: 'uuid' },
  owner: { type: 'string', default: 'gocoach' },
  status: { type: 'string', allow: ['active', 'inactive'], default: 'active' },
  uri: { type: 'string', required: true },
  name: { type: 'string', required: true },
  description: { type: 'string' },
  tags: {
    type: 'array',
    values: { type: 'string' },
    empty: true,
    default: [],
  },
  type: { type: 'string', allow: ['embed', 'link', 'document'] },
  new_window: { type: 'boolean', default: false },
  default_available: { type: 'boolean', default: false },
};

// eslint-disable-next-line no-unused-vars
export type ResourceType = { [K in keyof typeof ResourceSchema]?: any };

export const ResourceModel = obey.model(ResourceSchema);
