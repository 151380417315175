import React, { FC, useState } from 'react';
import { Form, Input } from 'antd';
import { Competency, CompetencySet } from '@src/models/CompetencySet';
import {
  Button,
  InputField,
  TextareaField,
  DialogTitle,
} from '@src/components/sc-design-system';
import { nanoid } from 'nanoid';
import Add from '@mui/icons-material/Add';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Stack,
  Divider,
} from '@mui/material';
import { useFullScreen } from '@src/hooks/useFullScreen';

const CompetencyManagerModal: FC<{
  clientId: string;
  open: boolean;
  selectedSet: CompetencySet;
  selectedCompetency?: Competency;
  onClose: Function;
  onSave: Function;
  type: string;
}> = ({
  clientId,
  open,
  selectedSet,
  selectedCompetency,
  onClose,
  onSave,
  type,
}) => {
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { fullScreen } = useFullScreen();

  const validate = () => {
    const values = form.getFieldsValue();

    setIsFormValid(Boolean(values.title && values.description));
  };

  const handleSubmit = async values => {
    setIsSaving(true);
    const competency = new Competency({
      ...values,
      indicators: values.indicators.filter(i => !!i.title),
      id: selectedCompetency?.id,
      competency_set_id: selectedSet?.id,
      client_id: selectedCompetency?.client_id || clientId,
    });
    await competency.save();
    await onSave();
    onClose();
    setIsSaving(false);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
      <DialogTitle
        title={
          <Stack spacing={1}>
            <Typography variant='h3'>
              {selectedCompetency?.id ? 'Edit' : 'Add'} {type} & Statements
            </Typography>
            <Typography variant='body1'>
              Add a {type.toLowerCase()} and one or more statements that can be
              used to objectively describe what members should demonstrate in
              their work.
            </Typography>
          </Stack>
        }
        buttonOnClick={handleClose}
      />
      <DialogContent>
        <Typography variant='h4' sx={{ marginBottom: '.5em' }}>
          {type}
        </Typography>
        <Form
          id='main'
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={{
            id: selectedCompetency?.id,
            title: selectedCompetency?.title ?? '',
            description: selectedCompetency?.description ?? '',
            indicators: selectedCompetency?.indicators || [
              { id: nanoid(), title: '' },
            ],
            competency_set_id:
              selectedCompetency?.competency_set_id || selectedSet?.id || '',
            client_id: selectedCompetency?.client_id || clientId,
          }}
          onFieldsChange={validate}
        >
          <InputField
            name='title'
            label='Name'
            rules={[{ required: true }]}
            value={form.getFieldValue('title')}
            onChange={title => form.setFieldsValue({ title })}
          />
          <TextareaField
            name='description'
            label='Definition'
            rules={[{ required: true }]}
            value={form.getFieldValue('description')}
            onChange={description => form.setFieldsValue({ description })}
          />
          <Divider />
          <Typography variant='h4' sx={{ marginTop: 0 }}>
            Statements
          </Typography>
          <Typography variant='body2' sx={{ margin: '1.3em 0' }}>
            Add one more more statements or indicators that describe the desired
            behavior in more detail. Each statement will appear on surveys when
            this {type.toLowerCase()} is evaluated.
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid #ccc',
              borderRadius: 6,
              padding: 12,
              paddingBottom: 0,
              maxHeight: 300,
              overflow: 'scroll',
            }}
          >
            <Form.List name='indicators'>
              {(fields, { add }) => (
                <>
                  {fields.map((field, idx) => (
                    <Form.Item
                      key={field.key}
                      name={[idx, 'title']}
                      style={{ margin: '.4em' }}
                    >
                      <Input placeholder='e.g. Expresses his/her views clearly and in an impartial manner,..' />
                    </Form.Item>
                  ))}
                  <div style={{ textAlign: 'center', padding: '.5em 0' }}>
                    <Button
                      variant='text'
                      startIcon={<Add />}
                      text='Add Statement'
                      onClick={() => add()}
                    />
                  </div>
                </>
              )}
            </Form.List>
          </div>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          type='submit'
          onClick={handleClose}
          text='Cancel'
        />
        <Button
          form='main'
          type='submit'
          disabled={!isFormValid || isSaving}
          loading={isSaving}
          text='Save'
        />
      </DialogActions>
    </Dialog>
  );
};

export { CompetencyManagerModal };
