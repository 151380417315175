import React, { FC, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import MonitorIcon from '@mui/icons-material/Monitor';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { Card, Statistic, Select } from '@src/components/sc-design-system';
import Stack from '@mui/material/Stack';
import Client from '@src/models/Client';
import ClientDashboardAreasOfInterest from './components/AreasOfInterest';
import ClientDashboardStrengthsAndWeaknesses from './components/StrengthsAndWeaknesses';
import { ClientDashboardProgramActivity } from '@src/components/common/ClientDashboardProgramActivity';
import { Grid, Typography } from '@mui/material';

const ProgramsDashboard: FC<{
  client: Client;
}> = observer(({ client }) => {
  const [program, setProgram] = useState('all');
  const { id } = useParams();
  const { useGetClientSOWs, useGetClientMetrics, useGetClientDashboardData } =
    useClientQueries(id);

  const { sows: clientSOWs } = useGetClientSOWs();
  const { data: clientMetrics } = useGetClientMetrics();
  const {
    areasOfInterest,
    strengths,
    weaknesses,
    isLoading: isDashboardLoading,
  } = useGetClientDashboardData();

  const {
    members,
    membersByProgram,
    allocatedSessions,
    allocatedSessionsByProgram,
    enrolledInPrograms,
    activePrograms,
    usersWithUpdatedGoals,
    paired,
    pairedByProgram,
    inCoaching,
    inCoachingByProgram,
    usedSessions,
    usedSessionsByProgram,
  } = clientMetrics || {};

  const programs = useMemo(
    () =>
      clientSOWs?.reduce((arr, s) => {
        s.programs.forEach(p => arr.push(p));
        return arr;
      }, []) || [],
    [clientSOWs],
  );

  return (
    // TODO: improve responsiveness
    <Grid container rowSpacing={2} sx={{ paddingTop: '2em' }}>
      <Grid item container spacing={2}>
        <Grid item container direction='column' spacing={2} sm={3}>
          <Grid item sx={{ paddingTop: '0 !important' }}>
            <Card>
              <Stack direction='row' alignItems='center' gap={1}>
                <GroupOutlinedIcon fontSize='large' />
                <Statistic title='Active Members' value={members} />
              </Stack>
            </Card>
          </Grid>
          <Grid item>
            <Card>
              <Stack direction='row' alignItems='center' gap={1}>
                <MonitorIcon fontSize='large' />
                <Statistic
                  title={`Enrolled in Coaching Programs`}
                  value={enrolledInPrograms}
                />
              </Stack>
            </Card>
          </Grid>
          <Grid item>
            <Card>
              <Stack direction='row' alignItems='center' gap={1}>
                <LayersOutlinedIcon fontSize='large' />
                <Statistic
                  title='Active Coaching Programs'
                  value={activePrograms}
                />
              </Stack>
            </Card>
          </Grid>
          <Grid item>
            <Card>
              <Stack direction='row' alignItems='center' gap={1}>
                <TrackChangesIcon fontSize='large' />
                <Statistic
                  title='Members with Updated Goals'
                  value={usersWithUpdatedGoals}
                />
              </Stack>
            </Card>
          </Grid>
        </Grid>
        <Grid item sm={9} sx={{ paddingTop: { sm: '0 !important' } }}>
          <Card title='Areas of Coaching Interest'>
            <Typography variant='body1'>
              Top 10 coaching interests compared to all other companies
            </Typography>
            <ClientDashboardAreasOfInterest
              areasOfInterest={areasOfInterest}
              clientName={client?.name}
              loading={isDashboardLoading}
            />
          </Card>
        </Grid>
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <Card
          title='Program Activity'
          titleActions={
            <Select
              options={[
                {
                  options: [{ label: 'All Programs', value: 'all' }].concat(
                    programs.map(p => {
                      return {
                        label: p.name,
                        value: p.id,
                      };
                    }),
                  ),
                },
              ]}
              defaultValue='all'
              value={program}
              onChange={e => {
                setProgram(e.target.value as string);
              }}
            />
          }
        >
          <Typography variant='body1'>
            Here is how your members are progressing through their coaching or
            mentoring programs. Visit the Programs tab for more insights and
            tools to manage activity.
          </Typography>
          <ClientDashboardProgramActivity
            clientMetrics={{
              members:
                program === 'all'
                  ? members ?? 0
                  : membersByProgram?.[program] ?? 0,
              allocatedSessions:
                program === 'all'
                  ? allocatedSessions ?? 0
                  : allocatedSessionsByProgram?.[program] ?? 0,
              paired:
                program === 'all'
                  ? paired ?? 0
                  : pairedByProgram?.[program] ?? 0,
              inCoaching:
                program === 'all'
                  ? inCoaching ?? 0
                  : inCoachingByProgram?.[program] ?? 0,
              usedSessions:
                program === 'all'
                  ? usedSessions ?? 0
                  : usedSessionsByProgram?.[program] ?? 0,
            }}
          />
        </Card>
      </Grid>
      <Grid item container direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <Grid item md={6}>
          <Card title='Top Strengths'>
            <ClientDashboardStrengthsAndWeaknesses
              strengthsAndWeaknesses={strengths}
              loading={isDashboardLoading}
            />
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card title='Top Areas for Development'>
            <ClientDashboardStrengthsAndWeaknesses
              strengthsAndWeaknesses={weaknesses}
              loading={isDashboardLoading}
            />
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
});

export { ProgramsDashboard };
