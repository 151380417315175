import React, { FC } from 'react';
import { Button, TextField } from '@src/components/sc-design-system';
import { useFieldArray, Controller, useFormContext } from 'react-hook-form';
import { List, ListItem, Stack } from '@mui/material';
import Add from '@mui/icons-material/Add';

const ClientDepartments: FC = () => {
  const { control } = useFormContext();
  const { fields, append } = useFieldArray({
    control,
    name: 'departments',
  });
  return (
    <Stack spacing={2} width='100%' alignItems='center' justifyContent='center'>
      <List sx={{ width: '100%' }}>
        {fields.map((item, i) => (
          <ListItem disableGutters key={item.id}>
            <Controller
              name={`departments.${i}.name`}
              control={control}
              render={({ field }) => (
                <TextField
                  label=''
                  placeholder='Enter department name...'
                  fullWidth
                  {...field}
                  id={field.name}
                  innerRef={field.ref}
                />
              )}
            />
          </ListItem>
        ))}
      </List>
      <Button
        sx={{ margin: 'auto', width: 'fit-content' }}
        onClick={() => append({ name: '' })}
        text='New Department'
        startIcon={<Add />}
      />
    </Stack>
  );
};

export { ClientDepartments };
