import { ComparisonType, SurveyCollectionType } from '@shared/schemas';
import { HTTPClient } from '@src/lib/client';
import { OnSubmit, Option, Question } from '@src/models/Survey';

export interface Survey {
  id?: string;
  title: string;
  hidden: string[];
  questions: Question[];
  on_submit: OnSubmit;
}

export interface SurveyQuestion {
  id?: string;
  name: string;
  title: string;
  helpText: string;
  privacyText: string;
  type: string;
  required: boolean;
  isNumberInput?: boolean;
  allow_other?: boolean;
  other_answer_required?: boolean;
  allow_none?: boolean;
  options?: Option[];
  logo?: string;
  range_maximum?: number;
  inQuestionBank?: boolean;
}

interface ListParamsIF {
  client_id?: string;
  keyword?: string;
  limit?: number;
  page?: number;
  questionBank?: boolean;
  includeProgramData?: boolean;
}

export interface SurveyWriteParams {
  action?: 'send' | 'save';
  clientName: string;
  surveyName: string;
}

export class SurveysAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async get(queryParams: ListParamsIF) {
    return this.http.request({
      url: `/surveys/list?${Object.keys(queryParams)
        .filter(k => queryParams[k])
        .map(key => key + '=' + encodeURIComponent(queryParams[key]))
        .join('&')}`,
      method: 'get',
    });
  }

  async getPublishedSurveys(queryParams: ListParamsIF & { all?: boolean }) {
    return this.http.request({
      url: `/surveys/list-published?${Object.keys(queryParams)
        .filter(k => queryParams[k])
        .map(key => key + '=' + encodeURIComponent(queryParams[key]))
        .join('&')}`,
      method: 'get',
    });
  }

  async getById(id: string) {
    return this.http.request({
      url: `/surveys/${id}`,
      method: 'get',
    });
  }

  async getPublishedSurvey(id: string) {
    return this.http.request({
      url: `/surveys/${id}/published`,
      method: 'get',
    });
  }

  async getSkillCycleQuestions(queryParams: ListParamsIF) {
    return this.http.request({
      url: `/surveys/questions/list?${Object.keys(queryParams)
        .filter(k => queryParams[k])
        .map(key => key + '=' + encodeURIComponent(queryParams[key]))
        .join('&')}`,
      method: 'get',
    });
  }

  async saveSurvey(data: Survey) {
    return this.http.request({
      url: `/surveys/${data.id && data.id.length > 0 ? data.id : 'create'}`,
      method: data.id && data.id.length > 0 ? 'patch' : 'post',
      data,
    });
  }

  async saveSurveyQuestion(data: SurveyQuestion) {
    return this.http.request({
      url: `/surveys/questions/${
        data.id && data.id.length > 0 ? data.id : 'create'
      }`,
      method: data.id && data.id.length > 0 ? 'patch' : 'post',
      data,
    });
  }

  async saveSurveySubmission(data: SurveySubmission) {
    return this.http.request({
      url: '/surveys/save-submission',
      method: 'post',
      data,
    });
  }

  async archiveSurvey(surveyId: string) {
    return this.http.request({
      url: `/surveys/${surveyId}/archive`,
      method: 'patch',
    });
  }

  async getSurveySubmission(
    userId: string,
    surveyId: string,
    params?: { email?: string; performance_cycle_id?: string },
  ) {
    return this.http.request({
      url: `/surveys/submission/${userId}/${surveyId}`,
      method: 'get',
      params,
    });
  }

  async publishSurvey(data: Survey) {
    return this.http.request({
      url: '/surveys/publish',
      method: 'post',
      data,
    });
  }

  // fetch aggregate results for one question
  async getAggregateQuestionResults(
    id: string,
    surveyID: string,
    questionId: string,
    compare?: ComparisonType,
  ) {
    const compareParam = compare ? `&compare=${compare}` : '';
    return this.http.request({
      url: `/surveys/${id}/question/results?sid=${surveyID}&qid=${questionId}${compareParam}`,
      method: 'get',
    });
  }
  // fetch aggregate results for many questions
  async getAggregateQuestionResultsByIds(
    id: string,
    surveyID: string,
    questionIds: string[],
    userId: string,
    programId: string,
  ) {
    return this.http.request({
      url: `/surveys/${id}/questions/results?sid=${surveyID}&pid=${programId}&puid=${userId}&compare=user&qids=${questionIds.join(
        '&qids=',
      )}`,
      method: 'get',
    });
  }

  async getAggregateCompetencyResults(
    id: string,
    programId: string,
    programUserId: string,
  ) {
    return this.http.request({
      url: `/surveys/${id}/competencies/results?pid=${programId}&puid=${programUserId}`,
      method: 'get',
    });
  }

  async get360ReportDataHandler(programId: string, programUserId: string) {
    return this.http.request({
      url: `/surveys/report-data?pid=${programId}&puid=${programUserId}`,
      method: 'get',
    });
  }

  async getProgramsUsingSurvey(id: string, clientId: string) {
    return this.http.request({
      url: `/surveys/${id}/${clientId}/programs`,
      method: 'get',
    });
  }

  async listSurveyCollections(id: string, clientId: string) {
    return this.http.request({
      url: `/surveys/${id}/${clientId}/collections`,
      method: 'get',
    });
  }

  async writeSurveyCollection(
    id: string,
    clientId: string,
    data: SurveyCollectionType,
    params: SurveyWriteParams,
  ) {
    return this.http.request({
      url: `/surveys/${id}/${clientId}/collections`,
      method: 'post',
      data,
      params,
    });
  }

  async remindRecipients(
    id: string,
    clientId: string,
    collectionId: string,
    data: any,
    params: SurveyWriteParams,
  ) {
    return this.http.request({
      url: `/surveys/${id}/${clientId}/collections/${collectionId}/`,
      method: 'patch',
      data,
      params,
    });
  }

  async markSurveyCompleteForCollectionRecipient(
    collectionId: string,
    userId: string, // TODO: will need to pass email for non-users
    data: string,
  ) {
    return this.http.request({
      url: `/surveys/collections/${collectionId}/${userId}`,
      method: 'patch',
      data,
    });
  }

  async getSurveySubmissionsForUser(userId: string) {
    return this.http.request({
      url: `/surveys/submissions`,
      method: 'get',
      params: { user_id: userId },
    });
  }
}
