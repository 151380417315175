import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ReportStakeholderAssessmentChart from './ReportStakeholderAssessmentChart';
import { Spin } from '@src/components/sc-design-system';
import { Message } from './components';
import { Typography, Divider } from '@mui/material';

function ReportStakeholderAssessment(props: ReportStakeholderAssessmentProps) {
  const {
    reportData,
    stakeholderQuestionIds,
    isLoadingGet360ReportData,
    numberOfResponses,
    isLoadingStakeholderAssessmentCharts,
    setIsLoadingStakeholderAssessmentCharts,
    userId,
    programId,
  } = props;

  return (
    <>
      <Typography variant='h3'>Stakeholder Assessment</Typography>
      <Divider />
      {isLoadingGet360ReportData === true ? (
        <Spin sectionLoader />
      ) : isLoadingGet360ReportData === 'error' ? (
        <Message type='error' />
      ) : !isLoadingGet360ReportData && numberOfResponses === 0 ? (
        <Message type='stakeholderEmpty' />
      ) : (
        <>
          <ReportStakeholderAssessmentChart
            isLoadingGet360ReportData={isLoadingGet360ReportData}
            stakeholderQuestionIds={stakeholderQuestionIds}
            clientId={reportData?.client_id}
            surveyId={reportData?.stakeholder_survey_id}
            isLoadingStakeholderAssessmentCharts={
              isLoadingStakeholderAssessmentCharts
            }
            setIsLoadingStakeholderAssessmentCharts={
              setIsLoadingStakeholderAssessmentCharts
            }
            userId={userId}
            programId={programId}
          />
          {reportData.stakeholder_text_answers.length > 0 && (
            <List>
              {reportData.stakeholder_text_answers.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primaryTypographyProps={{
                      component: 'p',
                      variant: 'body1m',
                    }}
                    primary={item.title}
                    secondaryTypographyProps={{
                      component: 'div',
                      color: 'grey.700',
                    }}
                    secondary={
                      item.answers.length > 0 ? (
                        <ul>
                          {item.answers.map(
                            (answer, idx) =>
                              answer && <li key={idx}>{answer}</li>,
                          )}
                        </ul>
                      ) : (
                        <Typography variant='body2' color='grey.700'>
                          No responses provided
                        </Typography>
                      )
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}
    </>
  );
}

export default ReportStakeholderAssessment;
