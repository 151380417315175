import { HTTPClient } from '@src/lib/client';

interface Address {
  city: string;
  country: string;
  state: string;
  street_address_1: string;
  street_address_2: string;
  zip: string;
}

interface Testimonial {
  company: string;
  name: string;
  review: string;
  title: string;
}

interface SocialMediaLink {
  type: string;
  value: string;
}

export interface CoachingTopic {
  id: string;
  title: string;
}

export interface Coach {
  id: string;
  addresses: Partial<Address>[];
  biography: string;
  coaching_topics: string[];
  first_name: string;
  last_name: string;
  points: {
    first_name: number;
    last_name: number;
    biography: number;
    coaching_topics: number;
    total: number;
  };
  profile_image: string;
  social_media_links: SocialMediaLink[];
  testimonials: Testimonial[];
}

export interface GetListParamsIF {
  keyword?: string;
  tags?: string[];
  type?: 'administrators' | 'coaches' | 'coachees';
  program_id?: string;
  program_type?: ProgramType;
  randomize?: boolean;
}

export class CoachesAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async searchCoaches(
    queryParams: GetListParamsIF,
  ): Promise<{ data: { data: Coach[] } }> {
    return this.http.request({
      url: `/coaches/list?${Object.keys(queryParams)
        .map(key => key + '=' + encodeURIComponent(queryParams[key]))
        .join('&')}`,
      method: 'get',
    });
  }

  async listCoaches(): Promise<{ data: { data: Coach[] } }> {
    return this.http.request({
      url: '/coaches/list/all',
      method: 'get',
    });
  }
}
