import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Button, ConfirmDialog } from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import Client from '@src/models/Client';
import Program from '@src/models/Program';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

interface RemoveMemberButtonProps {
  member: ClientMember;
  readOnly: boolean;
  client: Client;
  programs: Program[];
}

export default function RemoveMemberButton(props: RemoveMemberButtonProps) {
  const { member, readOnly, client, programs } = props;
  const queryClient = useQueryClient();

  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);

  const invalidateClient = () => {
    queryClient.invalidateQueries({
      queryKey: ['client', client.id],
      exact: true,
    });
  };

  const removeMember = async (id: string) => {
    for (const clientProgram of programs) {
      if (clientProgram.members.find(cpm => cpm.id === id)) {
        const program = new Program(clientProgram);
        const programSessions = await api.sessions.getByProgram({
          programId: program.id,
        });
        const sessionsUsed = programSessions.data.data.filter(
          ps =>
            ps.type === 'coaching' &&
            ps.current_status !== 'canceled' &&
            ps.members.find(sm => sm.id === id && sm.role === 'coachee'),
        );
        await program.removeMember(id, sessionsUsed.length);
      }
    }
    await client.removeMember(id);
    invalidateClient();
    queryClient.invalidateQueries({
      queryKey: ['client', client.id, 'members'],
    });
  };

  return (
    <>
      <ConfirmDialog
        open={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        buttonProps={{
          text: 'Yes',
          onClick: async () => removeMember(member.id),
        }}
        title='Remove Member'
        body={`Are you sure you want to remove ${member.first_name} ${member.last_name}?`}
      />
      <Button
        sx={{ margin: '2em 0' }}
        fullWidth
        disabled={readOnly}
        variant='outlined'
        color='error'
        text='Remove'
        startIcon={<DeleteOutlineOutlinedIcon />}
        onClick={() => setOpenConfirmModal(true)}
      />
    </>
  );
}
