import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Stack,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  Switch,
} from '@mui/material';
import { Card, TextField, Avatar } from '@src/components/sc-design-system';
import { ClientLogoInput } from '@src/components/common/ClientLogoInput';
import { useFullScreen } from '@src/hooks/useFullScreen';
import Client from '@src/models/Client';

interface ClientGeneralFieldsProps {
  client: Client; // TODO: replace with type def
}

const ClientGeneralFields: FC<ClientGeneralFieldsProps> = ({ client }) => {
  const {
    control,
    formState: { errors, touchedFields },
    watch,
  } = useFormContext();
  const { fullScreen } = useFullScreen();
  const newClientName = watch('name');

  return (
    <Stack spacing={4} paddingTop='.5em'>
      <Card variant='elevation' elevation={5}>
        <Typography variant='h4' sx={{ marginBottom: '2em' }}>
          Name and logo
        </Typography>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          spacing={4}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100px',
            }}
          >
            <Avatar
              sx={{ height: '60px', width: '60px' }}
              srcId={client?.id || newClientName || 'New Client'}
              alt={client?.name || newClientName || 'New Client'}
            >
              {/* TODO: this is a fallback until logos are fully migrated */}
              {client?.logo_image ? (
                <img
                  src={client?.logo_image}
                  alt={client?.name}
                  style={{
                    width: 'inherit',
                    height: 'inherit',
                  }}
                />
              ) : null}
            </Avatar>
            <ClientLogoInput client={client} />
          </div>
          <Controller
            name='name'
            control={control}
            rules={{ required: 'Client name is required' }}
            render={({ field }) => {
              const { ref, ...fieldProps } = field;
              return (
                <TextField
                  {...fieldProps}
                  fullWidth
                  label='Client name'
                  required
                  placeholder='Enter text...'
                  id='name'
                  innerRef={ref}
                  error={Boolean(touchedFields.name && errors?.name)}
                  errorMessage={
                    touchedFields.name &&
                    typeof errors?.name?.message === 'string' &&
                    errors?.name?.message
                  }
                />
              );
            }}
          />
        </Stack>
      </Card>
      <Card variant='elevation' elevation={5}>
        <Stack spacing={3}>
          <Typography variant='h4'>Basic details</Typography>
          <Controller
            name='email_domain'
            control={control}
            rules={{ required: 'Email domain is required' }}
            render={({ field }) => {
              const { ref, ...fieldProps } = field;
              return (
                <TextField
                  {...fieldProps}
                  fullWidth
                  label='Email domain'
                  required
                  placeholder='company.com'
                  id='email_domain'
                  innerRef={ref}
                  error={Boolean(
                    touchedFields.email_domain && errors?.email_domain,
                  )}
                  errorMessage={
                    touchedFields.email_domain &&
                    typeof errors?.email_domain?.message === 'string' &&
                    errors?.email_domain?.message
                  }
                />
              );
            }}
          />
          <Controller
            name='website'
            control={control}
            rules={{ required: 'Website is required' }}
            render={({ field }) => {
              const { ref, ...fieldProps } = field;
              return (
                <TextField
                  {...fieldProps}
                  fullWidth
                  label='Website'
                  required
                  placeholder='https://www.company.com'
                  id='website'
                  innerRef={ref}
                  error={Boolean(touchedFields.website && errors?.website)}
                  errorMessage={
                    touchedFields.website &&
                    typeof errors?.website?.message === 'string' &&
                    errors?.website?.message
                  }
                />
              );
            }}
          />
          <Stack direction={fullScreen ? 'column' : 'row'} spacing={3}>
            <Controller
              name='salesforce_id'
              control={control}
              rules={{ required: 'Salesforce ID is required' }}
              render={({ field }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <TextField
                    {...fieldProps}
                    fullWidth
                    label='Salesforce ID'
                    required
                    placeholder='Enter text...'
                    id='salesforce_id'
                    innerRef={ref}
                    error={Boolean(
                      touchedFields.salesforce_id && errors?.salesforce_id,
                    )}
                    errorMessage={
                      touchedFields.salesforce_id &&
                      typeof errors?.salesforce_id?.message === 'string' &&
                      errors?.salesforce_id?.message
                    }
                  />
                );
              }}
            />
            <Controller
              name='default_sow_id'
              control={control}
              render={({ field }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <Stack gap={1} width='100%'>
                    <InputLabel id='default-sow-select-label'>
                      Default SOW
                    </InputLabel>
                    <Select
                      {...fieldProps}
                      disabled={!client?.sows?.length}
                      label=''
                      id='default_sow_id'
                      ref={ref}
                      sx={{
                        '.Mui-disabled': {
                          backgroundColor: 'grey.400',
                          cursor: 'not-allowed',
                        },
                      }}
                    >
                      {client?.sows.map(s => (
                        <MenuItem key={s.id} value={s.id}>
                          {s.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                );
              }}
            />
          </Stack>
        </Stack>
      </Card>
      <Card variant='elevation' elevation={5}>
        <Stack spacing={1}>
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='h4'>HRIS sync</Typography>
            <Controller
              name='hris_enabled'
              control={control}
              render={({ field }) => (
                <Switch {...field} checked={field.value} />
              )}
            />
          </Stack>
          <Typography color='grey.700'>
            Turning this on will allow clients to connect their HRIS with
            SkillCycle.
          </Typography>
        </Stack>
      </Card>
      <Card variant='elevation' elevation={5}>
        <Stack spacing={1}>
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='h4'>Enable Aida</Typography>
            <Controller
              name='aida_enabled'
              control={control}
              render={({ field }) => (
                <Switch {...field} checked={field.value} />
              )}
            />
          </Stack>
          <Typography color='grey.700'>
            Enabling this will allow clients to accept use of SkillCycle's
            Adaptive Individual Development Advisor (Aida) for their employees.
          </Typography>
        </Stack>
      </Card>
    </Stack>
  );
};

export { ClientGeneralFields };
