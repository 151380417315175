import React, { FC, useEffect, useState } from 'react';
import {
  View,
  ViewBody,
  ViewBodySection,
  ViewHeader,
  Button,
  Card,
  SCLogo,
  Spin,
} from '@src/components/sc-design-system';
import { useQueryParams } from '@src/utils';
import { generateReportDownload } from '@src/utils/reports';
import { useNavigation } from '@src/navigation';
import { AxiosError } from 'axios';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Stack, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import { EmployeeDetails } from './components/EmployeeDetails';
import { Competencies } from './components/Competencies';
import { SurveySubmissionStack } from '@src/components/common/SurveySubmissionStack';

const cardStyle = { borderColor: 'grey.400' };

const PerformanceCycleFeedbackReport: FC = () => {
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);
  const query = useQueryParams();
  const clientId = query.get('clientId');
  const performanceCycleId = query.get('performanceCycleId');
  const feedbackFormId = query.get('feedbackFormId');
  const userId = query.get('userId');
  const userName = query.get('name');
  const navigation = useNavigation();

  const { data, isLoading, error } = useQuery({
    queryKey: [
      'client',
      clientId,
      'performance-cycle',
      performanceCycleId,
      'feedback',
      feedbackFormId,
      'report',
      userId,
    ],
    queryFn: async () =>
      api.clients.getEmployeeFeedbackReport(
        clientId,
        performanceCycleId,
        feedbackFormId,
        userId,
      ),
    select: ({ data }) => data,
  });

  useEffect(() => {
    if ((error as AxiosError)?.response?.status === 403) {
      navigation.replace('/');
    }
  }, [error, navigation]);

  function generateDownload() {
    // react is not disabling/setting the loading icon on the
    // download button so we need too wait 2 seconds to give that time
    setIsDownloadingPDF(true);
    setTimeout(function () {
      generateReportDownload(`SkillCycle Feedback Report for ${userName}.pdf`);
      setIsDownloadingPDF(false);
    }, 2000);
  }

  return (
    <View>
      <ViewHeader
        title={`Feedback for ${userName}`}
        actions={[
          {
            render: (
              <Tooltip
                title='This request may take some time. Thank you for your patience.'
                disableFocusListener
                key='download-tooltip'
              >
                <span>
                  <Button
                    disabled={isDownloadingPDF}
                    loading={isDownloadingPDF}
                    onClick={generateDownload}
                    text='Download report'
                    startIcon={<SaveAltIcon fontSize='small' />}
                  />
                </span>
              </Tooltip>
            ),
          },
        ]}
      />
      <ViewBody>
        <ViewBodySection title='Report'>
          <Card bodyStyle={{ padding: '0 !important' }}>
            <Stack spacing={4} id='report' sx={{ padding: '2em' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <SCLogo showText size={52} />
              </div>
              {isLoading ? (
                <Spin sectionLoader />
              ) : (
                <>
                  <EmployeeDetails
                    data={data}
                    userName={userName}
                    clientId={clientId}
                  />
                  <Competencies
                    clientId={clientId}
                    performanceCycleId={performanceCycleId}
                    feedbackFormId={feedbackFormId}
                    userId={userId}
                  />
                  <Card
                    title='Feedback form'
                    sx={{
                      '&.MuiPaper-outlined': cardStyle,
                      '.MuiCardHeader-root': cardStyle,
                      ...cardStyle,
                    }}
                  >
                    <SurveySubmissionStack
                      results={data?.results}
                      compiledAnswers
                    />
                  </Card>
                </>
              )}
            </Stack>
          </Card>
        </ViewBodySection>
      </ViewBody>
    </View>
  );
};

export { PerformanceCycleFeedbackReport };
