import React from 'react';
import dayjs from 'dayjs';
import { Typography, Stack, IconButton, Tooltip } from '@mui/material';
import { COLOR } from '@src/components/sc-design-system/tokens';
import { Spin } from '@src/components/sc-design-system';
import { generateAidaResponse } from '@src/utils/aida';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SyncIcon from '@mui/icons-material/Sync';

function ErrorState(props: { retryRequest: () => void }) {
  const { retryRequest } = props;
  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Stack direction='row' alignItems='center' spacing={1}>
        <ErrorOutlineIcon sx={{ color: 'error.600' }} />
        <Typography variant='body2' color='error.600'>
          There was an error loading the results. Please try again.
        </Typography>
      </Stack>
      <IconButton
        onClick={retryRequest}
        color='primary'
        size='small'
        children={<SyncIcon />}
      />
    </Stack>
  );
}

function AidaExperimentWarning() {
  return (
    <Typography
      variant='body2'
      color='grey.700'
      sx={{ justifySelf: 'center', textAlign: 'center' }}
    >
      Aida is new and improving and may make mistakes.
    </Typography>
  );
}

function AnimatedThinkingIcon() {
  const base = [
    COLOR.PRIMARY[500],
    COLOR.SECONDARY[500],
    COLOR.ACCENTS.RED[500],
    COLOR.ACCENTS.YELLOW[500],
    COLOR.ACCENTS.PURPLE,
    '#210DE6',
    '#75C380',
  ];
  return (
    <>
      <svg width='32' height='8' style={{ display: 'block' }}>
        <defs>
          <linearGradient
            id='thinking-gradient'
            x1='0%'
            y1='50%'
            x2='100%'
            y2='50%'
          >
            <stop offset='0%' stopColor='#7E287E'>
              <animate
                attributeName='stop-color'
                values={[...base, COLOR.PRIMARY[500]].join('; ')}
                dur='3s'
                repeatCount='indefinite'
              />
            </stop>
            <stop offset='100%' stopColor='#75C380'>
              <animate
                attributeName='stop-color'
                values={['#75C380', ...base].join('; ')}
                dur='3s'
                repeatCount='indefinite'
              />
            </stop>
          </linearGradient>
        </defs>
        <svg
          width='32'
          height='8'
          viewBox='0 0 32 8'
          fill='url(#thinking-gradient)'
          display='block'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='4' cy='4' r='4' />
          <circle cx='16' cy='4' r='4' />
          <circle cx='28' cy='4' r='4' />
        </svg>
      </svg>
    </>
  );
}

function Download(props: { selectedPrompt: Prompts }) {
  const [isDownloadingPDF, setIsDownloadingPDF] = React.useState(false);

  function generateDownload() {
    setIsDownloadingPDF(true);
    setTimeout(function () {
      const filename = `${props.selectedPrompt}-screenshot-${dayjs().format(
        'YYYY-MM-DD HH mm ss',
      )}`;
      generateAidaResponse(filename);
      setIsDownloadingPDF(false);
    }, 2000);
  }

  return (
    <Tooltip title='Save'>
      <IconButton
        onClick={() => generateDownload()}
        color='primary'
        size='small'
        disabled={isDownloadingPDF}
        children={!isDownloadingPDF ? <FileDownloadIcon /> : <Spin />}
      />
    </Tooltip>
  );
}

export { ErrorState, AidaExperimentWarning, AnimatedThinkingIcon, Download };
