import React from 'react';
import { Menu } from 'antd';
import { Tooltip } from '@mui/material';
import ChevronDownIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ComparisonType } from '@shared/schemas';

import type { MenuProps } from 'antd/es/menu';

import './Insights.less';

type MenuItem = Required<MenuProps>['items'][number];

interface QuestionsMenuProps {
  questions: Question[];
  competencies: Question[];
  selectedSurvey: Survey;
  setSelectedQuestion: (p: Question) => void;
  setComparisonType: (p: ComparisonType) => void;
  setFailedToLoadSurvey: (b: boolean) => void;
  setDisableCompareBy: (b: boolean) => void;
}

function generateItems(questions: Question[], competencies: Question[]) {
  const items: MenuItem[] = [];

  function generateItem(key, label, children) {
    function Label({ title }) {
      return (
        <Tooltip placement='top-start' title={title}>
          {title}
        </Tooltip>
      );
    }

    return {
      key,
      label,
      children: children.map(q => ({
        label: <Label title={q.title || q.group.title} />,
        key: q.id || q.group.id, // account for old style competencies
        children: q?.group?.questions.map(gQ => ({
          label: <Label title={gQ.title} />,
          key: gQ.id,
        })),
      })),
    };
  }

  if (questions?.length > 0) {
    items.push(generateItem('questions', 'Questions', questions));
  }
  if (competencies?.length > 0) {
    items.push(generateItem('competencies', 'Competencies', competencies));
  }

  return items;
}

export default function QuestionsMenu(props: QuestionsMenuProps) {
  const {
    questions,
    competencies,
    selectedSurvey,
    setSelectedQuestion,
    setComparisonType,
    setFailedToLoadSurvey,
    setDisableCompareBy,
  } = props;
  const [current, setCurrent] = React.useState();
  const [items, setItems] = React.useState<MenuItem[]>([]);
  const [defaultOpenKeys, setDefaultOpenKeys] = React.useState<string | null>();

  React.useEffect(
    function () {
      const i = generateItems(questions, competencies);
      if (i.length > 0) setDefaultOpenKeys(`${i[0]['key']}`);
      setItems(i);
    },
    [questions, competencies],
  );

  function selectQuestion(questionId: string, keyPath: string[]) {
    let selQuestion: Question;
    if (keyPath.length === 2) {
      selQuestion = selectedSurvey.questions.find(q => q.id === questionId);
    } else {
      // competency question
      const qG = selectedSurvey.questions.find(
        q =>
          (q.id && q.id === keyPath[1]) ||
          (q?.group?.id && q?.group?.id === keyPath[1]), // account for old style competencies
      );
      selQuestion = qG.group.questions.find(q => q.id === questionId);
    }
    setSelectedQuestion(selQuestion);
  }

  function onClick(e) {
    setFailedToLoadSurvey(false);
    setDisableCompareBy(true);
    setComparisonType(null);
    setCurrent(e.key);
    selectQuestion(e.key, e.keyPath);
  }

  return (
    defaultOpenKeys && (
      <Menu
        style={{ width: '270px', border: 0 }}
        onClick={onClick}
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[current]}
        items={items}
        mode='inline'
        expandIcon={({ isOpen }) =>
          isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />
        }
      />
    )
  );
}
