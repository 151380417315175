import React from 'react';
import { Alert, AlertTitle, Typography } from '@mui/material';

const LearnMoreAboutAidaAlert = () => {
  return (
    <Alert severity='info' sx={{ marginBottom: '0' }}>
      <AlertTitle>Learn more about Aida!</AlertTitle>
      <Typography variant='body2'>
        AIDA was created to revolutionize workplace learning and performance
        management by providing personalized, on-demand support that aligns
        individual growth with company goals. Inspired by the need for more
        effective, continuous development in the modern workplace, AIDA aims to
        bridge the gap between traditional performance reviews and the
        day-to-day support employees need to thrive.
      </Typography>
    </Alert>
  );
};

export { LearnMoreAboutAidaAlert };
