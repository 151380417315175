import React from 'react';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react';
import _groupBy from 'lodash/groupBy';
import { Card, SelectField } from '@src/components/sc-design-system';
import { useQueryParams } from '@src/utils';
import { api } from '@src/lib/client';
import LoadingIcon from '@src/components/common/LoadingIcon';
import { ComparisonType } from '@shared/schemas';
import QuestionsMenu from './QuestionsMenu';
import Message from './Message';
import QuestionView from './QuestionView';

import './Insights.less';
import { Typography } from '@mui/material';

function populateCategories(survey: Survey) {
  function isChartableQuestion(question) {
    const questionTypes = ['single-select', 'multi-select', 'range'];

    if (
      (question.type === 'text' && question.isNumberInput) ||
      questionTypes.includes(question.type)
    ) {
      return true;
    }
  }

  return (survey?.questions || []).reduce(
    (arr, q) => {
      if (q?.group?.type === 'competency') {
        q.group.questions = q.group.questions.filter(qGQ => {
          if (isChartableQuestion(qGQ)) return qGQ;
        });
        arr.competencies.push(q);
      } else {
        if (isChartableQuestion(q)) arr.questions.push(q);
      }

      return arr;
    },
    { questions: [], competencies: [] },
  );
}

function populateSurveyDropdown(publishedSurveys, setSurveyDropdown) {
  const groupedSurveys = _groupBy(publishedSurveys, s => s.surveyId);
  const opts = [];
  Object.keys(groupedSurveys).forEach(id => {
    opts.push({
      label: groupedSurveys[id][0].title,
      options: groupedSurveys[id].map((s, idx) => {
        const surveyTimestamp = dayjs(s.publishedAt).format(
          'MM/DD/YYYY h:mm:ss a',
        );
        const labelPostfix =
          idx === 0 && groupedSurveys[id].length > 1 ? ' (LATEST)' : '';
        return {
          label: `${surveyTimestamp}${labelPostfix}`,
          value: s.id,
        };
      }),
    });
  });
  setSurveyDropdown(opts);
}

const Insights: React.FunctionComponent = () => {
  const { client_id } = useParams();
  const navigate = useNavigate();
  const query = useQueryParams();
  const survey_id = query.get('sid');
  const [loadingSurveys, setLoadingSurveys] = React.useState(true);
  const [failedToLoadSurvey, setFailedToLoadSurvey] = React.useState(false);
  const [disableCompareBy, setDisableCompareBy] = React.useState(true);
  const [surveys, setSurveys] = React.useState<Survey[]>([]);
  const [surveyDropdown, setSurveyDropdown] = React.useState<any[]>();
  const [selectedSurveyId, setSelectedSurveyId] = React.useState<string>();
  // this is only needed when survey id comes from the sid qsp
  const [selectedSurveyName, setSelectedSurveyName] = React.useState<string>();
  const [selectedSurvey, setSelectedSurvey] = React.useState<Survey>();
  const [selectedQuestion, setSelectedQuestion] = React.useState<Question>();
  const [questions, setQuestions] = React.useState<Question[]>();
  const [competencies, setCompetencies] = React.useState<Question[]>();
  const [comparisonType, setComparisonType] = React.useState<ComparisonType>();
  const defaultSurveyDropdownValue =
    selectedSurveyId && selectedSurveyName
      ? {
          label: selectedSurveyName,
          value: selectedSurveyId,
        }
      : undefined;

  const handleSurveySelection = React.useCallback(
    function (survey: Survey) {
      setFailedToLoadSurvey(false);
      setDisableCompareBy(true);
      setSelectedSurveyId(survey_id);
      setSelectedSurveyName(survey.title);
      setSelectedSurvey(survey);
      setSelectedQuestion(null);
      setComparisonType(null);
      const categories = populateCategories(survey);
      setQuestions(categories.questions);
      setCompetencies(categories.competencies);
    },
    [survey_id],
  );

  React.useEffect(
    function () {
      (async function () {
        try {
          let publishedSurveys;
          let selectedSurveyBySid; // selected survey is in published list

          if (surveys.length > 0) {
            // surveys already in local state (ie user views more than one survey)
            selectedSurveyBySid = surveys.find(s => s.id === survey_id);
            publishedSurveys = surveys;
          } else {
            let publishedSurveysRes;
            let publishedProgramSurveysRes;
            // super cautious here...
            try {
              const res = await api.surveys.getPublishedSurveys({
                client_id,
              });
              if (Array.isArray(res.data) && res.status === 200)
                publishedSurveysRes = res.data;
            } catch (error) {}
            try {
              const res = await api.programs.getPublishedProgramSurveys({
                client_id,
              });
              if (Array.isArray(res.data) && res.status === 200)
                publishedProgramSurveysRes = res.data;
            } catch (error) {}

            // some times a survey can appear in the surveys collection and under a program survey
            // so we need to make sure we return unique survey ids
            const uniqueSurveyIds: string[] = [];
            publishedSurveys = [
              ...publishedSurveysRes,
              ...publishedProgramSurveysRes,
            ].reduce((surveys, s) => {
              if (!uniqueSurveyIds.includes(s.id)) {
                if (s.id === survey_id) selectedSurveyBySid = s;
                surveys.push({
                  id: s.id,
                  surveyId: s.survey_id,
                  title: s.title,
                  questions: s.questions,
                  publishedAt: s.published_at,
                });
                uniqueSurveyIds.push(s.id);
              }
              return surveys;
            }, []);
          }
          setSurveys(publishedSurveys);
          populateSurveyDropdown(publishedSurveys, setSurveyDropdown);
          // selected survey is not in published list (user clicked direct link from a survey list)
          if (!selectedSurveyBySid && survey_id) {
            const fetchedSurvey = await api.surveys.getPublishedSurvey(
              survey_id,
            );
            if (fetchedSurvey.status === 200 && fetchedSurvey.data)
              selectedSurveyBySid = fetchedSurvey.data;
          }
          if (selectedSurveyBySid) {
            handleSurveySelection(selectedSurveyBySid);
          } else {
            setFailedToLoadSurvey(true);
          }
          setLoadingSurveys(false);
        } catch (error) {
          setFailedToLoadSurvey(true);
        }
        setLoadingSurveys(false);
      })();
    },
    [client_id, survey_id, surveys, handleSurveySelection],
  );

  if (loadingSurveys) {
    return <LoadingIcon />;
  }

  return (
    <div style={{ display: 'flex' }}>
      <div className='i-side'>
        <Typography variant='h5' sx={{ marginBottom: '.5em' }}>
          Survey
        </Typography>
        {surveyDropdown && (
          <SelectField
            search={false}
            placeholder='Select a survey...'
            value={defaultSurveyDropdownValue}
            onChange={sid => {
              navigate(`/insights/${client_id}?sid=${sid}`);
            }}
            options={surveyDropdown}
          />
        )}
        {selectedSurvey && (
          <QuestionsMenu
            questions={questions}
            competencies={competencies}
            selectedSurvey={selectedSurvey}
            setSelectedQuestion={setSelectedQuestion}
            setComparisonType={setComparisonType}
            setFailedToLoadSurvey={setFailedToLoadSurvey}
            setDisableCompareBy={setDisableCompareBy}
          />
        )}
      </div>
      <div className='i-content'>
        {!selectedSurveyId && (
          <Card>
            <Message
              headline='Select a survey to view survey data and insights.'
              text='Once a survey is selected, you can view results by
                        question.'
            />
          </Card>
        )}
        {selectedSurvey && (
          <>
            {failedToLoadSurvey && (
              <Card>
                <Message
                  headline='Oops!'
                  text='Unable to load the survey. Please try again later.'
                />
              </Card>
            )}
            {!failedToLoadSurvey && !selectedQuestion && (
              <Card>
                <Message
                  headline={
                    questions.length
                      ? 'Select a question to view results.'
                      : `This survey is comprised of text-only questions. These do not display in
                        the insights dashboard. Survey questions will only display if they are single-select,
                        multi-select, range or number.`
                  }
                />
              </Card>
            )}
          </>
        )}
        {selectedQuestion && (
          <QuestionView
            selectedQuestion={selectedQuestion}
            selectedSurvey={selectedSurvey}
            client_id={client_id}
            comparisonType={comparisonType}
            setComparisonType={setComparisonType}
            disableCompareBy={disableCompareBy}
            setDisableCompareBy={setDisableCompareBy}
          />
        )}
      </div>
    </div>
  );
};

export default observer(Insights);
