import React, { FC, useMemo } from 'react';
import { Typography, Stack, Grid, Divider } from '@mui/material';
import { Card } from '@src/components/sc-design-system';
import dayjs from 'dayjs';
import { useLogoBlob } from '@src/hooks/useLogoBlob';

const cardStyle = { borderColor: 'grey.400' };

interface EmployeeDetailsProps {
  data?: FeedbackReport;
  userName: string;
  clientId: string;
}
const EmployeeDetails: FC<EmployeeDetailsProps> = ({
  data,
  userName,
  clientId,
}) => {
  const { logoBlob } = useLogoBlob(clientId);
  const lastUpdatedAt = useMemo(() => {
    const lastSubmission = data?.feedback_submissions?.sort((a, b) => {
      return b.submitted - a.submitted;
    })?.[0];

    return lastSubmission?.submitted;
  }, [data]);

  return (
    <Card
      title={userName}
      titleActions={
        <Stack direction='row' alignItems='center' spacing={1}>
          {Boolean(logoBlob) && (
            <img
              height={32}
              src={logoBlob as string}
              alt={`${data?.client_name} logo`}
            />
          )}
          <Typography sx={{ fontSize: '20px' }}>{data?.client_name}</Typography>
        </Stack>
      }
      sx={{
        '&.MuiPaper-outlined': cardStyle,
        '.MuiCardHeader-root': cardStyle,
        ...cardStyle,
      }}
    >
      <Grid container columns={12} spacing={1}>
        <Grid item xs={3}>
          <Typography variant='body1m'>Department</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>
            {data?.department ? data?.department : 'Not set'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant='body1m'>Manager</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>
            {data?.manager?.last_name
              ? `${data?.manager?.first_name} ${data?.manager?.last_name}`
              : 'Not set'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant='body1m'>Performance cycle</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{data?.performance_cycle?.name}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant='body1m'>Performance cycle period</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>
            {dayjs(data?.performance_cycle?.start).format('MM/DD/YYYY')}
            {' - '}
            {dayjs(data?.performance_cycle?.end).format('MM/DD/YYYY')}
          </Typography>
        </Grid>
      </Grid>
      {lastUpdatedAt > 0 && (
        <>
          <Divider sx={{ borderColor: 'grey.400' }} />
          <Grid container columns={12}>
            <Grid item xs={3}>
              <Typography variant='body1m'>Last updated</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>
                {dayjs(lastUpdatedAt).format('dddd, MMMM Do, YYYY')}
              </Typography>
            </Grid>
          </Grid>
          <Typography sx={{ marginTop: '1em' }}>
            <em>
              Note: The information below reflects the most recent response from
              any given stakeholder.
            </em>
          </Typography>
        </>
      )}
    </Card>
  );
};

export { EmployeeDetails };
