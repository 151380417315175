import React from 'react';
import dayjs from 'dayjs';
import { Stack, Divider, Link, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useClientQueries } from '@src/hooks/useClientQueries';
import {
  useLayoutToggle,
  LayoutType,
  MenuButton,
  useToast,
  Avatar,
  Spin,
} from '@src/components/sc-design-system';
import { GoalCycleCard } from '@src/components/common/GoalCycleCard';
import { GoalCycleGrid } from '@src/components/common/GoalCycleGrid';
import { GoalCycleList } from '@src/components/common/GoalCycleList';
import { CreateGoalCycleButton } from './CreateGoalCycleButton';
import { GoalCycleManager } from '@src/components/common/GoalCycleManager';
import { GoalManagerModal } from '@src/components/common/GoalManagerModal';
import { useMutation } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import { useConfirm } from 'material-ui-confirm';
import AllEmployeeGoalsModal from './AllEmployeeGoalsModal';
import { useParams } from 'react-router';
import { GetStartedCard } from './GetStartedCard';

const GoalCycles: React.FunctionComponent = () => {
  const { id: clientId, performanceCycleId } = useParams();

  const { useGetPerformanceCycleGoals, useGetClientNonAlignedGoals } =
    useClientQueries(clientId);
  const { goalCycles, isLoading, isFetching, refetch } =
    useGetPerformanceCycleGoals(performanceCycleId);
  const { nonAlignedGoals } = useGetClientNonAlignedGoals();

  const [isArchiveVisible, setIsArchiveVisible] = React.useState(false);

  const activeCycles = React.useMemo(() => {
    return goalCycles?.filter(c => !c.end || dayjs().isBefore(c.end));
  }, [goalCycles]);
  const archivedCycles = React.useMemo(() => {
    return goalCycles?.filter(c => dayjs().isAfter(c.end));
  }, [goalCycles]);

  if (isLoading) {
    return <Spin sectionLoader />;
  }

  if (!goalCycles && !isLoading && !isFetching) {
    return (
      <GetStartedCard
        performanceCycleId={performanceCycleId}
        clientId={clientId}
      />
    );
  }

  return (
    <Stack spacing={2}>
      <div style={{ marginLeft: 'auto' }}>
        <CreateGoalCycleButton
          clientId={clientId}
          performanceCycleId={performanceCycleId}
          text='Add goal cycle'
          variant='text'
        />
      </div>
      <Stack spacing={4}>
        {activeCycles?.map((cycle, idx) => (
          <ClientGoalCycleCard
            key={cycle.id}
            cycle={cycle}
            clientId={clientId}
            performanceCycleId={performanceCycleId}
            nonAlignedGoals={nonAlignedGoals}
            refetchGoals={refetch}
            defaultOpen={idx === 0}
          />
        ))}
        {Boolean(archivedCycles?.length) && (
          <Stack>
            <Divider />
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              sx={{ marginBottom: isArchiveVisible ? '2em' : 0 }}
            >
              <Typography variant='h4'>Archived Goals</Typography>
              <Link
                variant='a1blank'
                component='button'
                onClick={() => setIsArchiveVisible(!isArchiveVisible)}
              >
                {isArchiveVisible ? 'Hide' : 'Show'} Section
              </Link>
            </Stack>
            {isArchiveVisible &&
              archivedCycles?.map(cycle => (
                <ClientGoalCycleCard
                  key={cycle.id}
                  cycle={cycle}
                  clientId={clientId}
                  performanceCycleId={performanceCycleId}
                  nonAlignedGoals={nonAlignedGoals}
                  refetchGoals={refetch}
                />
              ))}
            <Divider />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

interface ClientGoalCycleCardProps {
  cycle: GoalCycleWithGoals;
  clientId: string;
  performanceCycleId: string;
  nonAlignedGoals?: Goal[];
  refetchGoals: Function;
  defaultOpen?: boolean;
}

const ClientGoalCycleCard: React.FunctionComponent<
  ClientGoalCycleCardProps
> = ({
  cycle,
  clientId,
  performanceCycleId,
  nonAlignedGoals,
  refetchGoals,
  defaultOpen,
}) => {
  const toast = useToast();
  const confirm = useConfirm();
  const [layoutType, setLayoutType] = useLayoutToggle(`goal-cycle-${cycle.id}`);
  const [showGoalCycleModal, setShowGoalCycleModal] = React.useState(false);
  const [allEmployeeGoalsModalOpen, setAllEmployeeGoalsModalOpen] =
    React.useState(false);
  const [showGoalModal, setShowGoalModal] = React.useState<
    false | 'edit' | 'add'
  >(false);
  const [selectedGoal, setSelectedGoal] = React.useState<Goal | null>(null);
  const [activeTab, setActiveTab] = React.useState(null);

  const sections = React.useMemo(() => {
    const filtered = cycle.goals.filter(g => !g.user_ids?.length);
    return [filtered];
  }, [cycle.goals]);

  const goalsInCycle = React.useMemo(() => {
    const aligned =
      cycle?.goals.filter(g => g.aligned_goal_id && g.user_ids.length) || [];
    const nonAligned =
      nonAlignedGoals?.filter(nag => nag.goal_cycle_id === cycle?.id) || [];
    return [...aligned, ...nonAligned];
  }, [cycle, nonAlignedGoals]);

  const employeeIds = React.useMemo(() => {
    const res = new Set<string>();
    goalsInCycle.forEach(g =>
      g.user_ids.forEach(u => {
        res.add(u.id);
      }),
    );
    return Array.from(res);
  }, [goalsInCycle]);

  const { mutate: deleteCycle } = useMutation({
    mutationFn: async () => {
      return api.clients.deleteGoalCycle(
        cycle.id,
        clientId,
        performanceCycleId,
      );
    },
    onSuccess() {
      refetchGoals();
      toast.success('Goal cycle deleted');
    },
    onError() {
      toast.error('Could not delete goal cycle');
    },
  });

  const { mutateAsync: deleteGoal } = useMutation({
    mutationFn: async (goalId: string) => {
      await api.goals_competencies.deleteGoal(goalId);
    },
    onSuccess: async () => {
      setShowGoalModal(false);
      await refetchGoals();
      toast.success('Goal deleted');
    },
    onError() {
      toast.error('Could not delete goal');
    },
  });

  const handleDeleteGoal = async (goal: Goal) => {
    await deleteGoal(goal.id);
  };

  const handleDeleteCycle = async () => {
    try {
      await confirm({
        title: 'Delete this goal cycle?',
        description: 'This action cannot be undone.',
        confirmationText: 'Delete',
        dialogProps: { maxWidth: 'xs' },
        cancellationButtonProps: {
          variant: 'outlined',
          className: 'shape-pill',
        },
        confirmationButtonProps: {
          className: 'shape-pill',
        },
      });
      deleteCycle();
    } catch (e) {}
  };

  const toggleGoalEditor = (type, goal = null) => {
    setSelectedGoal(goal || null);
    setShowGoalModal(type);
  };

  return (
    <>
      <GoalCycleCard
        cycle={cycle}
        view='client'
        defaultOpen={defaultOpen}
        showStatusChip
        titleActions={
          <div style={{ marginLeft: 'auto' }}>
            <MenuButton
              variant='outlined'
              disablePadding
              primaryActionText='Edit'
              secondaryActions={[
                {
                  key: 'delete',
                  label: 'Delete',
                  onClick: handleDeleteCycle,
                },
              ]}
              onClickPrimaryAction={() => {
                setShowGoalCycleModal(true);
              }}
            />
          </div>
        }
        onChangeLayout={setLayoutType}
        onChangeTab={setActiveTab}
        refetchGoals={refetchGoals}
        subheaderActions={
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            divider={<Divider orientation='vertical' sx={{ height: '21px' }} />}
          >
            {Boolean(goalsInCycle.length) && (
              <Link
                variant='a2blank'
                sx={{
                  marginTop: '.5em',
                  display: 'flex',
                  gap: '0.5em',
                  alignItems: 'center',
                  '> span': {
                    color: 'secondary.500',
                  },
                }}
                onClick={() => {
                  setAllEmployeeGoalsModalOpen(true);
                }}
              >
                <div className='avatar-stack'>
                  {employeeIds.slice(0, 3).map(uid => (
                    <Avatar key={uid} size='sm' srcId={uid} />
                  ))}
                </div>
                <span
                  style={{
                    marginLeft:
                      employeeIds.slice(0, 3).length === 3 ? '1em' : null,
                  }}
                >
                  View Employee Goals Entered
                </span>
              </Link>
            )}
            {cycle.goal_weighting_enabled && (
              <Stack direction='row' alignItems='center' gap={1}>
                Goal weighting enabled
                <CheckCircleOutlineIcon fontSize='small' color='success' />
              </Stack>
            )}
          </Stack>
        }
      >
        {layoutType === LayoutType.grid && (
          <GoalCycleGrid
            cycle={cycle}
            entityType='client'
            canEditGoals
            onAddOrEditGoal={toggleGoalEditor}
            onDeleteGoal={handleDeleteGoal}
            sections={sections}
            activeTab={activeTab}
          />
        )}
        {layoutType === LayoutType.list && (
          <GoalCycleList
            cycle={cycle}
            entityType='client'
            onAddOrEditGoal={toggleGoalEditor}
            onDeleteGoal={handleDeleteGoal}
            canEditGoals
            sections={sections}
            activeTab={activeTab}
          />
        )}
      </GoalCycleCard>
      {showGoalCycleModal && (
        <GoalCycleManager
          clientId={clientId}
          goalCycle={cycle}
          open={showGoalCycleModal}
          onClose={() => setShowGoalCycleModal(false)}
          performanceCycleId={performanceCycleId}
        />
      )}
      {allEmployeeGoalsModalOpen && (
        <AllEmployeeGoalsModal
          open={allEmployeeGoalsModalOpen}
          cycle={cycle}
          allGoals={goalsInCycle}
          handleClose={() => setAllEmployeeGoalsModalOpen(false)}
        />
      )}
      {Boolean(showGoalModal) && (
        <GoalManagerModal
          clientId={clientId}
          selectedGoalCycle={cycle}
          open={showGoalModal}
          onClose={() => {
            toggleGoalEditor(null);
          }}
          selectedGoal={selectedGoal}
          onSave={refetchGoals}
          entityType='client'
          performanceCycleId={performanceCycleId}
        />
      )}
    </>
  );
};

export { GoalCycles };
