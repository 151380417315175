import React, { FC } from 'react';
import dayjs from 'dayjs';
import { Typography, Stack } from '@mui/material';
import { IProgram } from '../types';
import ActiveInactiveChip from '../../ActiveInactiveChip';

const ProgramDetails: FC<{
  program: IProgram;
}> = ({ program }) => {
  const isActive = (start: number, end: number): boolean =>
    start < Date.now() && end > Date.now();
  return (
    <>
      <Typography
        variant='body1'
        color='grey.700'
        sx={{ marginBottom: '4px !important' }}
      >
        {program.client}
      </Typography>
      <Typography variant='h2' component='h3' sx={{ marginBottom: '1px' }}>
        {program.name}
      </Typography>
      <Stack direction='row' spacing={2}>
        <Typography
          variant='body1'
          color='grey.700'
          sx={{ marginBottom: '4px !important' }}
        >
          {dayjs(program.start_date).format('ll')}
          {' - '}
          {dayjs(program.end_date).format('ll')}
        </Typography>
        <ActiveInactiveChip
          active={
            program.is_active && isActive(program.start_date, program.end_date)
          }
        />
      </Stack>
    </>
  );
};

export { ProgramDetails };
