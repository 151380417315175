import React, { FC, useState } from 'react';
import { api } from '@src/lib/client';
import { observer } from 'mobx-react';
import { Form, Input } from 'antd';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import {
  Typography,
  Stack,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Button, DialogTitle } from '@src/components/sc-design-system';

const { TextArea } = Input;

const Contact: FC<{
  senderFirstName: string;
  senderLastName: string;
  senderAddress: string;
  recipientFirstName: string;
  recipientLastName: string;
  recipientAddress: string;
  onClose: Function;
}> = ({
  senderFirstName,
  senderLastName,
  senderAddress,
  recipientFirstName,
  recipientLastName,
  recipientAddress,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <Dialog open onClose={() => onClose()}>
      <DialogTitle
        title={
          <Stack direction='row' alignItems='center' spacing={1}>
            <MailOutlinedIcon />
            <Typography variant='h3'>
              Email {recipientFirstName} {recipientLastName}
            </Typography>
          </Stack>
        }
        buttonOnClick={() => onClose()}
      />
      <DialogContent>
        <Form
          id='main'
          layout='vertical'
          onFinish={async values => {
            setLoading(true);
            await api.mailer.contact({
              senderFirstName,
              senderLastName,
              senderAddress,
              recipientAddress,
              message: values.message,
            });
            setLoading(false);
            onClose();
          }}
        >
          <Form.Item
            label='Message:'
            name='message'
            rules={[
              {
                required: true,
                message: 'Message cannot be blank',
              },
            ]}
          >
            <TextArea rows={10} spellCheck={true}></TextArea>
          </Form.Item>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} text='Cancel' variant='outlined' />
        <Button type='submit' form='main' loading={loading} text='Send' />
      </DialogActions>
    </Dialog>
  );
};

export default observer(Contact);
