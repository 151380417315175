import React, { FC, useMemo, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
  Divider,
} from '@mui/material';
import { Button, DialogTitle } from '@src/components/sc-design-system';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { FeedbackInviteModal } from './FeedbackInviteModal';
import { useFullScreen } from '@src/hooks/useFullScreen';
import { useDebounce } from '@src/hooks/useDebounce';
import { InviteeSelect } from './InviteeSelect';
import { PerformanceCycleFeedbackInvitesTable } from '@src/components/common/PerformanceCycleFeedbackInvitesTable';

interface RequestFeedbackModalProps {
  open: boolean;
  onClose: () => void;
  clientId: string;
  userId: string;
  userFirstName: string;
  userLastName: string;
  performanceCycleId: string;
  feedbackFormId: string;
}

const RequestFeedbackModal: FC<RequestFeedbackModalProps> = ({
  open,
  onClose,
  clientId,
  userId,
  userFirstName,
  userLastName,
  performanceCycleId,
  feedbackFormId,
}) => {
  const { fullScreen } = useFullScreen();
  const { useGetClientMembers, useGetPerformanceCycleFeedbackStatusForMember } =
    useClientQueries(clientId);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [stakeholders, setStakeholders] = useState([]);
  const [keyword, setKeyword] = useState('');
  const debouncedKeyword = useDebounce(keyword, 200);
  const { members, isLoading, isFetching } = useGetClientMembers(
    {
      keyword: debouncedKeyword,
      return_fields: ['email', 'first_name', 'last_name'],
    },
    { enabled: !!debouncedKeyword },
  );

  const {
    refetch,
    feedbackData,
    isLoading: isFeedbackDataLoading,
  } = useGetPerformanceCycleFeedbackStatusForMember(
    performanceCycleId,
    feedbackFormId,
    userId,
  );

  const filteredMemberOptions = useMemo(() => {
    if (isLoading || isFeedbackDataLoading || isFetching || !members) {
      return [];
    }
    return members
      ?.filter(m => {
        return (
          m.id !== userId &&
          stakeholders?.every(i => m.id !== i.value) &&
          (feedbackData?.stakeholders
            ? feedbackData?.stakeholders?.every(i => m.id !== i.user_id)
            : true)
        );
      })
      .map(m => ({
        value: {
          user_id: m.id,
          email: m.email,
          first_name: m.first_name,
          last_name: m.last_name,
        },
        label: `${m.first_name} ${m.last_name}`,
      }));
  }, [
    members,
    isLoading,
    isFeedbackDataLoading,
    isFetching,
    userId,
    stakeholders,
    feedbackData,
  ]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullScreen={fullScreen}>
      <DialogTitle title='Request feedback' buttonOnClick={onClose} />
      <DialogContent>
        <Stack spacing={4}>
          <Stack>
            <Typography
              variant='h4'
              component='h3'
              sx={{ marginBottom: '.5em' }}
            >
              Invite stakeholders
            </Typography>
            <Typography
              variant='body2'
              color='grey.700'
              sx={{ marginBottom: '1.5em' }}
            >
              Request feedback from your colleagues now and in the future by
              adding them here. When added, they will be notified via an
              in-platform notification and email request. Consider adding your
              manager, direct reports, and peers, and at least 5 people. Data
              will only be shown if there are{' '}
              <strong>5 or more responses</strong>.
            </Typography>
            <Stack direction='row' alignItems='center' spacing={2}>
              <InviteeSelect
                value={stakeholders}
                loading={isFetching}
                limitTags={2}
                keyword={keyword}
                options={filteredMemberOptions}
                onSelect={(e, value) => {
                  setStakeholders(value);
                  setKeyword(null);
                }}
                onInputChange={setKeyword}
              />
              <>
                <Button
                  text='Invite'
                  onClick={() => setInviteModalOpen(true)}
                  disabled={!stakeholders?.length}
                />
                {inviteModalOpen && (
                  <FeedbackInviteModal
                    onClose={() => setInviteModalOpen(false)}
                    setStakeholders={setStakeholders}
                    stakeholders={stakeholders}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    filteredMemberOptions={filteredMemberOptions}
                    isFetching={isFetching}
                    userId={userId}
                    userFirstName={userFirstName}
                    userLastName={userLastName}
                    clientId={clientId}
                    performanceCycleId={performanceCycleId}
                    feedbackFormId={feedbackFormId}
                    onSubmit={refetch}
                  />
                )}
              </>
            </Stack>
          </Stack>
          <Divider />
          <PerformanceCycleFeedbackInvitesTable
            clientId={clientId}
            userId={userId}
            userFirstName={userFirstName}
            userLastName={userLastName}
            performanceCycleId={performanceCycleId}
            feedbackFormId={feedbackFormId}
            view='member'
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button text='Done' onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

export { RequestFeedbackModal };
