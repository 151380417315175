import React from 'react';
import { Drawer, IconButton, Typography, Box, Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Card } from '@src/components/sc-design-system';
import Client from '@src/models/Client';
import Program from '@src/models/Program';

import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CloseIcon from '@mui/icons-material/Close';
import RemoveMemberButton from './RemoveMemberButton';
import DetailsCard from './DetailsCard';
import RolesCard from './RolesCard';

interface MemberEditPanelProps {
  member: ClientMember;
  client: Client;
  allClientMembers: ClientMember[];
  mentoringEnabled: boolean;
  programs: Program[];
  currentUserId: string;
}

export default function MemberEditPanel(props: MemberEditPanelProps) {
  const { member, client, mentoringEnabled, allClientMembers, programs } =
    props;
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const [updatedProps, setUpdatedProps] = React.useState([]);
  const [updatedData, setUpdatedData] = React.useState<
    undefined | ClientMember[]
  >();

  const invalidateClient = () => {
    queryClient.invalidateQueries({
      queryKey: ['client', client.id],
      exact: true,
    });
  };
  // because the response shape from the mutation endpoints
  // is different from the query response shape,
  // this process of merging updates is more involved
  // and runs when the drawer is closing
  const mergeMemberUpdates = () => {
    const isMentorChanged = updatedProps.includes('is_mentor');
    const props = updatedProps.filter(item => item !== 'is_mentor');
    if (props.length === 0 && !isMentorChanged) return;

    // run query update if non is_mentor prop is passed and updatedData exists
    if (props.length > 0 && Array.isArray(updatedData)) {
      const [[queryKey, queryData]] = queryClient.getQueriesData({
        queryKey: ['client', client.id, 'members'],
        // we don't want to match the manager select query
        predicate: q => !!q?.queryHash?.match('"memberType"'),
      }) as any[];

      const updatedList = queryData.data.data
        .map(m => {
          const updatedUser = updatedData.find(({ id }) => id === m.id);
          if (!updatedUser) return m;
          return {
            ...m,
            ...props.reduce(
              (acc, p) => ({ ...acc, [p]: updatedUser?.[p] }),
              {},
            ),
          };
        })
        .sort((a, b) => (a.role > b.role ? 1 : -1));

      queryClient.setQueryData(queryKey, {
        ...queryData,
        data: {
          ...queryData.data,
          data: updatedList,
        },
      });
    }
    // run client invalidation if mentor status changed
    if (isMentorChanged) {
      invalidateClient();
    }
  };

  const handleClose = () => {
    mergeMemberUpdates();
    setOpen(false);
  };

  const readOnly = React.useMemo(
    function () {
      // If the employee exists in the data from Merge...
      return (
        client.merge_hris_data?.some(mhd => mhd.workEmail === member.email) ||
        // ...or is an invitee who hasn't yet joined
        member.status === 'pending_invite'
      );
    },
    [client.merge_hris_data, member.email, member.status],
  );

  return (
    <>
      <IconButton onClick={() => setOpen(true)} color='primary' size='small'>
        <CreateOutlinedIcon fontSize='inherit' />
      </IconButton>
      <Drawer
        anchor='right'
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 1300 }}
      >
        {/* we are using grid to break up the card header and body so that the scroll is correct */}
        <Grid
          container
          direction='column'
          wrap='nowrap'
          sx={{ overflow: 'hidden' }}
        >
          <Grid item sx={{ '.MuiCard-root': { borderRadius: 0 } }}>
            <Card
              title={`${member.first_name} ${member.last_name}`}
              titleActions={[
                <IconButton key='close-drawer' onClick={handleClose}>
                  <CloseIcon />
                </IconButton>,
              ]}
              bodyStyle={{ '&.MuiCardContent-root': { padding: 0 } }}
            />
          </Grid>
          <Grid item sx={{ overflowY: 'scroll' }}>
            <Box sx={{ padding: '2em' }}>
              <Card variant='elevation'>
                <Typography variant='h4' component='h3'>
                  Email
                </Typography>
                <Typography variant='body1' color='grey.700'>
                  {member.email}
                </Typography>
              </Card>
              <DetailsCard
                member={member}
                readOnly={readOnly}
                client={client}
                allClientMembers={allClientMembers}
                updatedProps={updatedProps}
                setUpdatedProps={setUpdatedProps}
                setUpdatedData={setUpdatedData}
              />
              <RolesCard
                member={member}
                client={client}
                mentoringEnabled={mentoringEnabled}
                updatedProps={updatedProps}
                setUpdatedProps={setUpdatedProps}
                setUpdatedData={setUpdatedData}
              />
              {member.id !== props.currentUserId && (
                <RemoveMemberButton
                  member={member}
                  readOnly={readOnly}
                  client={client}
                  programs={programs}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}
