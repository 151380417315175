import React from 'react';
import { Dialog, DialogActions, DialogContent, Link } from '@mui/material';
import {
  Button,
  DialogTitle,
  useToast,
} from '@src/components/sc-design-system';
import { useForm } from 'react-hook-form';
import { Dayjs } from 'dayjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import Client from '@src/models/Client';
import { useParams } from 'react-router';
import { PerformanceCycleForm } from '../PerformanceCycleLandingPage/modals/PerformanceCycleForm';

interface CreatePerformanceCycleModalProps {
  setOpenModal: (b: boolean) => void;
  openModal: boolean;
  performanceCycleNames: string[];
}

const CreatePerformanceCycleModal: React.FunctionComponent<
  CreatePerformanceCycleModalProps
> = props => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { setOpenModal, openModal, performanceCycleNames } = props;
  const { id: clientId } = useParams();

  const form = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      start: '',
      end: '',
    } as { name: string; start: '' | Dayjs; end: '' | Dayjs },
  });
  const {
    reset,
    formState: { isValid, isDirty },
  } = form;

  const { mutate: addPerformanceCycle, isPending } = useMutation({
    mutationFn: async (payload: PerformanceCycleIBase) => {
      return api.clients.addPerformanceCycle(clientId, payload);
    },
    onSuccess: ({ data: updatedClient }) => {
      queryClient.setQueryData(
        ['client', clientId],
        new Client(updatedClient.data),
      );
      handleClose();
    },
    onError: () => {
      toast.error('Unable to create performance cycle');
    },
  });

  const onSubmit = (data: PerformanceCycleOnSubmitProps) => {
    addPerformanceCycle({
      name: data.name,
      start: data.start.valueOf(),
      end: data.end.valueOf(),
    });
  };

  function handleClose() {
    setOpenModal(false);
    reset();
  }

  return (
    <Dialog open={openModal} onClose={() => handleClose()}>
      <DialogTitle title='Create performance cycle' />
      <DialogContent dividers>
        <PerformanceCycleForm
          onSubmit={onSubmit}
          form={form}
          performanceCycleNames={performanceCycleNames}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Link variant='a2' onClick={() => reset(undefined)}>
          Clear all fields
        </Link>
        <div>
          <Button
            key='cancel'
            variant='outlined'
            text='Cancel'
            onClick={handleClose}
          />
          <Button
            sx={{ marginLeft: '.5em' }}
            type='submit'
            form='performance-cycle-form'
            disabled={!isValid || !isDirty}
            key='create'
            text='Create'
            loading={isPending}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};

export { CreatePerformanceCycleModal };
