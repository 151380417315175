import React, { FC } from 'react';
import { SurveyQuestionType } from '@shared/schemas/src/survey';
import './SurveyQuestion.less';
import { InputNumber } from 'antd';
import { evaluateQuestion } from '../utils/conditions';
import ReactMarkdown from 'react-markdown';
import {
  Typography,
  List,
  ListItem,
  Stack,
  Grid,
  TextField,
} from '@mui/material';
import { Range, Selection, SelectionInput } from './SurveyQuestionButton';

interface QuestionGroupType {
  title: string;
  helpText?: string;
  questions: any[];
}

interface UISurveyQuestionType extends SurveyQuestionType {
  group?: QuestionGroupType;
}

export const TextQuestion: FC<{
  questionId: string;
  isTextArea: boolean;
  answer?: string;
  question: UISurveyQuestionType;
  setAnswer?: Function;
  ariaLabelledBy: string;
}> = ({
  questionId,
  isTextArea,
  question,
  answer,
  setAnswer,
  ariaLabelledBy,
}) => {
  const { isNumberInput } = question;

  return isNumberInput ? (
    <InputNumber
      key={questionId}
      className='survey-text-question number'
      value={answer}
      type='number'
      defaultValue='1'
      min='1'
      onChange={e => {
        setAnswer && setAnswer(questionId, e);
      }}
      aria-labelledby={ariaLabelledBy}
    />
  ) : (
    <TextField
      key={questionId}
      value={answer}
      fullWidth
      aria-labelledby={ariaLabelledBy}
      onChange={e => {
        setAnswer && setAnswer(questionId, e.target.value);
      }}
      multiline
      minRows={isTextArea ? 4 : 1}
      maxRows={20}
    />
  );
};

export const SingleSelectQuestion: FC<{
  questionId: string;
  question: UISurveyQuestionType;
  answer?: any;
  otherValue?: undefined | string;
  setAnswer?: Function;
}> = ({ questionId, question, answer = '', otherValue, setAnswer }) => {
  const { type, options, allow_other, allow_none } = question;
  const selectOptions = options;

  return (
    <List component={Stack} spacing={2} justifyContent='center'>
      {selectOptions.map(opt => {
        const selected = answer === opt.value;
        return (
          <ListItem key={opt.value} sx={{ scrollMargin: '1em' }}>
            <Selection
              key={questionId}
              text={opt.text}
              selected={selected}
              onClick={() => {
                const newValue = selected ? undefined : opt.value;
                setAnswer && setAnswer(questionId, newValue, otherValue);
              }}
            />
          </ListItem>
        );
      })}
      {type !== 'range' && (
        <>
          {allow_other && (
            <ListItem>
              <SelectionInput
                key={questionId}
                text='Other'
                selected={answer.includes('other')}
                onClick={() => {
                  const newValue = answer.includes('other')
                    ? undefined
                    : 'other';
                  setAnswer &&
                    setAnswer(
                      questionId,
                      newValue,
                      newValue?.includes('other') ? otherValue : undefined,
                    );
                }}
                onInputClick={e => {
                  e.stopPropagation();
                  if (!(answer || []).includes('other')) {
                    setAnswer(
                      questionId,
                      [...(answer || []), 'other'],
                      otherValue,
                    );
                  }
                }}
                onInputChange={e => {
                  const newValue = 'other';
                  setAnswer && setAnswer(questionId, newValue, e.target.value);
                }}
                inputValue={otherValue}
              />
            </ListItem>
          )}
          {allow_none && (
            <ListItem key='none'>
              <Selection
                key={questionId}
                text='None of the above'
                selected={answer.includes(null)}
                onClick={() => {
                  const newValue = answer.includes(null) ? undefined : [null];
                  setAnswer(questionId, newValue);
                }}
              />
            </ListItem>
          )}
        </>
      )}
    </List>
  );
};

export const MultiSelectQuestion: FC<{
  questionId: string;
  question: UISurveyQuestionType;
  answer?: any;
  otherValue?: undefined | string;
  setAnswer?: Function;
}> = ({ questionId, question, answer = '', otherValue, setAnswer }) => {
  const {
    type,
    options,
    allow_other,
    allow_none,
    range_maximum,
    range_labels,
  } = question;
  const selectOptions =
    type === 'range'
      ? new Array(range_maximum || 10).fill('').map((_, idx) => ({
          key: idx,
          value: range_labels?.[idx]?.value || idx + 1,
          text: range_labels?.[idx]?.text || idx + 1,
        }))
      : options;

  return (
    <List component={Stack} spacing={2} justifyContent='center'>
      {selectOptions.map(opt => {
        const selected = answer?.includes(opt.value);

        return (
          <ListItem key={opt.value}>
            <Selection
              key={questionId}
              type='multi'
              text={opt.text}
              selected={selected}
              onClick={() => {
                let newValue = opt.value;
                if (type === 'multi-select') {
                  newValue = (answer || []).includes(opt.value)
                    ? (answer || []).filter(v => v !== opt.value || v === null)
                    : [
                        ...((answer || []).filter(a => a !== null) || []),
                        newValue,
                      ];
                }
                setAnswer && setAnswer(questionId, newValue, otherValue);
              }}
            />
          </ListItem>
        );
      })}
      {type !== 'range' && (
        <>
          {allow_other && (
            <ListItem>
              <SelectionInput
                key={questionId}
                type='multi'
                text='Other'
                selected={answer.includes('other')}
                onClick={() => {
                  const newValue = (answer || []).includes('other')
                    ? (answer || []).filter(v => v !== 'other' || v === null)
                    : [
                        ...((answer || []).filter(a => a !== null) || []),
                        'other',
                      ];
                  setAnswer &&
                    setAnswer(
                      questionId,
                      newValue,
                      newValue.includes('other') ? otherValue : undefined,
                    );
                }}
                onInputClick={e => {
                  e.stopPropagation();
                  if (!(answer || []).includes('other')) {
                    setAnswer(
                      questionId,
                      [...(answer || []), 'other'],
                      otherValue,
                    );
                  }
                }}
                onInputChange={e => {
                  const newValue = (answer || []).includes('other')
                    ? answer
                    : [...(answer || []), 'other'];
                  setAnswer && setAnswer(questionId, newValue, e.target.value);
                }}
                inputValue={otherValue}
              />
            </ListItem>
          )}
          {allow_none && (
            <ListItem key='none'>
              <Selection
                key={questionId}
                type='multi'
                text='None of the above'
                selected={answer.includes(null)}
                onClick={() => {
                  const newValue = answer.includes(null) ? undefined : [null];
                  setAnswer(questionId, newValue);
                }}
              />
            </ListItem>
          )}
        </>
      )}
    </List>
  );
};

export const RangeQuestion: FC<{
  questionId: string;
  question: UISurveyQuestionType;
  answer?: any;
  otherValue?: undefined | string;
  setAnswer?: Function;
}> = ({ questionId, question, answer = '', otherValue, setAnswer }) => {
  const { range_maximum, range_labels } = question;
  const selectOptions = new Array(range_maximum || 10)
    .fill('')
    .map((_, idx) => ({
      key: idx,
      value: range_labels?.[idx]?.value || idx + 1,
      text: range_labels?.[idx]?.text || idx + 1,
    }));

  return (
    <List
      component={Grid}
      direction='row'
      spacing={1}
      justifyContent={range_labels ? 'flex-start' : 'center'}
      container
    >
      {selectOptions.map(opt => {
        const selected = answer === opt.value;
        return (
          <Grid item key={opt.value}>
            <ListItem sx={{ width: 'auto' }} disableGutters>
              <Range
                key={questionId}
                text={`${opt.text}`}
                selected={selected}
                onClick={() => {
                  const newValue = selected ? undefined : opt.value;
                  setAnswer && setAnswer(questionId, newValue, otherValue);
                }}
              />
            </ListItem>
          </Grid>
        );
      })}
    </List>
  );
};

const QuestionElement: FC<{
  question: UISurveyQuestionType;
  answers: any[];
  setAnswer: Function;
}> = ({ question, answers, setAnswer }) => {
  const currentAnswer = answers.find(a => a.question_id === question?.id);

  return (
    <>
      {['text', 'textarea'].includes(question?.type) && (
        <TextQuestion
          questionId={question.id}
          question={question}
          isTextArea={question?.type === 'textarea'}
          answer={currentAnswer?.answer}
          setAnswer={setAnswer}
          ariaLabelledBy={question.title ?? 'survey-text-question-label'}
        />
      )}
      {question?.type === 'range' && (
        <RangeQuestion
          questionId={question.id}
          question={question}
          answer={currentAnswer?.answer}
          otherValue={currentAnswer?.other_value}
          setAnswer={setAnswer}
        />
      )}
      {question?.type === 'multi-select' && (
        <MultiSelectQuestion
          questionId={question.id}
          question={question}
          answer={currentAnswer?.answer}
          otherValue={currentAnswer?.other_value}
          setAnswer={setAnswer}
        />
      )}
      {question?.type === 'single-select' && (
        <SingleSelectQuestion
          questionId={question.id}
          question={question}
          answer={currentAnswer?.answer}
          otherValue={currentAnswer?.other_value}
          setAnswer={setAnswer}
        />
      )}
    </>
  );
};

export const SurveyQuestion: FC<{
  question: UISurveyQuestionType;
  answers: any[];
  setAnswer: Function;
}> = ({ question, answers, setAnswer }) => {
  const title = question?.title || question?.group?.title;
  const helpText = question?.helpText || question?.group?.helpText;

  return (
    <div className='survey-question' style={{ maxWidth: '750px' }}>
      <ReactMarkdown
        aria-label='survey-question-label'
        components={{
          h3: ({ node, ...props }) => <Typography variant='h3' {...props} />,
        }}
        children={`### ${title}`}
      />
      {helpText && (
        <ReactMarkdown
          children={helpText}
          components={{
            p: ({ node, ...props }) => (
              <Typography
                variant='body2'
                color='grey.700'
                {...props}
                sx={{ marginBottom: '1.5em' }}
              />
            ),
          }}
        />
      )}
      {question?.group ? (
        question.group.questions?.map(
          q =>
            evaluateQuestion(q, answers) && (
              <Stack key={q.id} spacing={2} paddingBottom={2}>
                <ReactMarkdown
                  children={q.title}
                  components={{
                    p: ({ node, ...props }) => (
                      <Typography
                        variant='body1'
                        sx={{ textAlign: 'left' }}
                        {...props}
                        aria-label={q.title}
                      />
                    ),
                  }}
                />
                <QuestionElement
                  question={q}
                  answers={answers}
                  setAnswer={setAnswer}
                />
              </Stack>
            ),
        )
      ) : (
        <QuestionElement
          question={question}
          answers={answers}
          setAnswer={setAnswer}
        />
      )}
    </div>
  );
};
