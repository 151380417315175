import React, { FC, useEffect, useState } from 'react';
import { Input, Pagination, Table } from 'antd';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { api } from '@src/lib/client';
import SurveyQuestion from '@src/models/SurveyQuestion';
import NewSurveyQuestion from './NewSurveyQuestion';
import { useQueryParams } from '@src/utils';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Divider } from '@mui/material';

const PAGE_LIMIT = 25;

const setQueryParams = (
  navigate: Function,
  query: any,
  name: string,
  value: string | number,
) => {
  let currentKeyword = query.get('keyword') || '';
  let currentPage = query.get('page') || 1;

  // Handle prop change
  if (name === 'keyword') {
    currentPage = 1; // Need reset on search
    currentKeyword = value;
  }
  if (name === 'page') {
    currentPage = value;
  }

  // Build query
  const composedQuery = `?tab=1${
    currentKeyword && currentKeyword.length > 1
      ? `&keyword=${currentKeyword}`
      : ''
  }${currentPage && `&page=${currentPage}`}`;
  navigate({
    pathname: window.location.pathname,
    search: composedQuery,
  });
};

const columns = [
  {
    title: 'Question Bank',
    dataIndex: 'inQuestionBank',
    width: 100,
    render: (_, record) => {
      return (
        <CheckBoxOutlinedIcon
          color={record.inQuestionBank ? 'success' : 'disabled'}
        />
      );
    },
  },
  {
    title: 'Type',
    dataIndex: 'type',
    width: 100,
    render: (_, record) => {
      return (
        <label className={`question-type-label ${record.type}`}>
          {record.type === 'text' && record.isNumberInput
            ? 'Number'
            : record.type}
        </label>
      );
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (_, record) => {
      return (
        <span>
          {record.name ? (
            record.name
          ) : (
            <span style={{ color: '#ccc' }}>Not set</span>
          )}
        </span>
      );
    },
  },
  {
    title: 'Title',
    dataIndex: 'title',
    render: (_, record) => {
      return (
        <ReactMarkdown
          children={
            // Markdown bolding seems to make things less-bold?
            record.title
          }
        />
      );
    },
  },
  {
    title: <span style={{ whiteSpace: 'nowrap' }}>Help Text</span>,
    dataIndex: 'helpText',
    render: (_, record) => {
      return (
        <CheckBoxOutlinedIcon
          color={record.helpText ? 'success' : 'disabled'}
        />
      );
    },
    width: 90,
    align: 'center' as 'center',
  },
  {
    title: <span style={{ whiteSpace: 'nowrap' }}>Privacy Text</span>,
    dataIndex: 'privacyText',
    render: (_, record) => {
      return (
        <CheckBoxOutlinedIcon
          color={record.privacyText ? 'success' : 'disabled'}
        />
      );
    },
    width: 115,
    align: 'center' as 'center',
  },
  {
    title: 'Required',
    dataIndex: 'required',
    render: (_, record) => {
      return (
        <CheckBoxOutlinedIcon
          color={record.required ? 'success' : 'disabled'}
        />
      );
    },
    width: 95,
    align: 'center' as 'center',
  },
  {
    title: 'Options',
    dataIndex: 'options',
    render: (_, record) => {
      return record.options?.length ? <>{record.options.length}</> : '';
    },
    width: 90,
    align: 'center' as 'center',
  },
  {
    title: <span style={{ whiteSpace: 'nowrap' }}>Allow Other</span>,
    dataIndex: 'allow_other',
    render: (_, record) => {
      return (
        <span>
          {(record.type === 'multi-select' ||
            record.type === 'single-select') &&
          record.allow_other &&
          record.other_answer_required ? (
            <CheckBoxOutlinedIcon color='success' />
          ) : (record.type === 'multi-select' ||
              record.type === 'single-select') &&
            record.allow_other ? (
            <CheckBoxOutlinedIcon color='success' />
          ) : record.type === 'multi-select' ||
            record.type === 'single-select' ? (
            <CheckBoxOutlinedIcon color='disabled' />
          ) : (
            <CheckBoxOutlinedIcon color='disabled' />
          )}
        </span>
      );
    },
    width: 110,
    align: 'center' as 'center',
  },
  {
    title: <span style={{ whiteSpace: 'nowrap' }}>Allow None</span>,
    dataIndex: 'allow_none',
    render: (_, record) => {
      return (
        <CheckBoxOutlinedIcon
          color={
            (record.type === 'multi-select' ||
              record.type === 'single-select') &&
            record.allow_none
              ? 'success'
              : record.type === 'multi-select' ||
                record.type === 'single-select'
              ? 'disabled'
              : 'disabled'
          }
        />
      );
    },
    width: 120,
    align: 'center' as 'center',
  },
];

let currentGetReqTimeout: any;

const getSurveyQuestions = async (
  params: object,
  setLoading: Function,
  setSurveyQuestions: Function,
  setTotal: Function,
) => {
  setLoading(true);
  // Debounce to prevent keyword onchange from blasting the api
  if (currentGetReqTimeout) clearTimeout(currentGetReqTimeout);
  currentGetReqTimeout = setTimeout(async () => {
    const surveyQuestions = await api.surveys.getSkillCycleQuestions(
      Object.assign(params, { limit: PAGE_LIMIT }),
    );
    setSurveyQuestions(surveyQuestions.data.data);
    setTotal(surveyQuestions.data.totalRecords);
    setLoading(false);
  }, 200);
};

const SurveyQuestions: FC<{
  openModal: boolean;
  setOpenModal: Function;
}> = ({ openModal, setOpenModal }) => {
  const query = useQueryParams();
  const navigate = useNavigate();
  const setQuery = setQueryParams.bind(null, navigate, query);
  const keyword = query.get('keyword') || '';
  const page = query.get('page') || 1;
  const [total, setTotal] = useState(0);
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('New survey question');
  const [currentSurveyQuestion, setCurrentSurveyQuestion] =
    useState<SurveyQuestion>(new SurveyQuestion());
  const [loading, setLoading] = useState(false);
  const [hideOk, setHideOk] = useState(true);
  useEffect(() => {
    getSurveyQuestions(
      { keyword, page },
      setLoading,
      setSurveyQuestions,
      setTotal,
    );
  }, [keyword, page, total]);

  return (
    <>
      <Input
        size='middle'
        placeholder='Search...'
        defaultValue={keyword}
        allowClear={true}
        onChange={e => setQuery('keyword', e.target.value)}
      />
      <Divider />
      <Table
        style={{ whiteSpace: 'pre-wrap' }}
        columns={columns}
        dataSource={surveyQuestions}
        loading={loading}
        pagination={false}
        rowKey={record => record.id}
        onRow={record => {
          return {
            onDoubleClick: () => {
              setCurrentSurveyQuestion(
                new SurveyQuestion({
                  id: record.id,
                  name: record.name,
                  title: record.title,
                  required: record.required,
                  helpText: record.helpText,
                  privacyText: record.privacyText,
                  allow_other: record.allow_other,
                  other_answer_required: record.other_answer_required,
                  allow_none: record.allow_none,
                  isNumberInput: record.isNumberInput,
                  type: record.type,
                  options: record.options,
                  inQuestionBank: record.inQuestionBank,
                }),
              );
              setHideOk(false);
              setModalTitle(record.id);
              setModalOpen(true);
            },
          };
        }}
        scroll={{ y: 300 }}
      />
      <Pagination
        style={{ marginTop: '2em' }}
        pageSize={PAGE_LIMIT}
        current={+page}
        total={total}
        onChange={val => setQuery('page', val)}
      />
      {modalOpen || openModal ? (
        <NewSurveyQuestion
          modalTitle={modalTitle}
          setModalTitle={setModalTitle}
          setOpenModal={setOpenModal}
          setModalOpen={setModalOpen}
          currentSurveyQuestion={currentSurveyQuestion}
          setCurrentSurveyQuestion={setCurrentSurveyQuestion}
          setHideOk={setHideOk}
          hideOk={hideOk}
          setTotal={setTotal}
          total={total}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default observer(SurveyQuestions);
