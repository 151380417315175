import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';

obey.creator('uuid', () => uuidv4());

export const ImageSchema = {
  id: { type: 'string', required: true, creator: 'uuid' },
  entity_id: { type: 'string' },
  image: { type: 'string' },
};

// eslint-disable-next-line no-unused-vars
export type ImageType = { [K in keyof typeof ImageSchema]?: any };

export const ImageModel = obey.model(ImageSchema);
