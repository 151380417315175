import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { EditableTypography } from '@src/components/sc-design-system';

import './styles.less';

const QuestionListItem: FC<{
  item: any;
  onChange: Function;
  isSurveyEditable: boolean;
}> = observer(({ item, onChange, isSurveyEditable }) => {
  return (
    <EditableTypography
      key={item.id}
      variant='body1'
      width={'100%'}
      children={item.text}
      onChange={text => {
        onChange({
          ...item,
          text,
          value: text.replace(/\s/g, '').toLowerCase(),
        });
      }}
      disabled={!isSurveyEditable}
    />
  );
});

export { QuestionListItem };
