import React, { FC } from 'react';
import SurveyQuestion from '@src/models/SurveyQuestion';
import { observer } from 'mobx-react';
import { List, Space, Row } from 'antd';
import { QuestionTitle, QuestionText } from './components';

const SQRange: FC<{
  surveyQuestion: SurveyQuestion;
  isSurveyEditable: boolean;
}> = ({ surveyQuestion, isSurveyEditable }) => {
  const rangeMax = surveyQuestion.range_maximum || 10;
  const selectOptions = [];
  for (let r = 1; r <= rangeMax; r++) {
    selectOptions.push({
      value: r,
      text: r,
    });
  }

  return (
    <div className='sq-editor-range'>
      <label className={`question-type-label ${surveyQuestion.type}`}>
        {surveyQuestion.type}
      </label>
      <QuestionTitle
        surveyQuestion={surveyQuestion}
        readOnly={!isSurveyEditable}
      />
      <QuestionText
        surveyQuestion={surveyQuestion}
        propName='helpText'
        isSurveyEditable={isSurveyEditable}
      />

      <List className={`survey-select-options ${surveyQuestion.type}`}>
        <Row align='bottom'>
          {selectOptions.map((opt, i) => {
            return (
              <Space key={i} direction='vertical' align='center'>
                <List.Item className='selection-box '>{opt.value}</List.Item>
              </Space>
            );
          })}
        </Row>
      </List>
    </div>
  );
};

export default observer(SQRange);
