import React from 'react';
import { Typography } from '@mui/material';

interface MessageProps {
  headline?: string;
  text?: string;
}

export default function Message({ headline, text }: MessageProps) {
  return (
    <div style={{ textAlign: 'center' }}>
      {headline && (
        <Typography
          variant='h3'
          sx={{ marginBottom: '0.25em' }}
          color='primary'
        >
          {headline}
        </Typography>
      )}
      {text && <Typography variant='body1'>{text}</Typography>}
    </div>
  );
}
