import React, { useState } from 'react';
import { api } from '@src/lib/client';
import { Input } from 'antd';
import { UserEngagementRecord } from '@src/lib/client/apis/engagement';
import {
  useToast,
  DialogTitle,
  Button,
} from '@src/components/sc-design-system';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';

const { TextArea } = Input;

export const EngagementRecord360sModal = ({
  record,
  setSelected360sRecord,
  updateLocalRecord,
  setShowNotificationsModal,
  onClose,
}: {
  record: UserEngagementRecord;
  setSelected360sRecord: Function;
  updateLocalRecord: Function;
  setShowNotificationsModal: Function;
  onClose(): void;
}) => {
  const toast = useToast();
  const [assessmentStakeholders, setAssessmentStakeholders] = useState<string>(
    record.assessmentStakeholders?.reduce((acc, s) => {
      acc += `${s.email}\n`;
      return acc;
    }, '') || '',
  );
  const [saving, setSaving] = useState<boolean>(false);

  return (
    <Dialog onClose={onClose} open className='engagement-record-notes'>
      <DialogTitle
        title={`${record.name} ${
          record.programName ? '- ' + record.programName : ''
        }`}
        buttonOnClick={onClose}
      />
      <DialogContent>
        <Typography
          variant='body1'
          color='grey.500'
          sx={{ marginBottom: '.5em' }}
        >
          Please enter one email address per line:
        </Typography>
        <TextArea
          rows={5}
          value={assessmentStakeholders}
          onChange={e => {
            setAssessmentStakeholders(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' text='Close' onClick={onClose} />
        <Button
          text='Save'
          loading={saving}
          disabled={saving}
          onClick={async () => {
            setSaving(true);
            try {
              const assessmentStakeholdersData = assessmentStakeholders
                .split('\n')
                .filter(n => n)
                .map(s => {
                  const originalObj =
                    record.assessmentStakeholders?.find(aS => aS.email === s) ||
                    {};
                  return Object.assign({}, originalObj, {
                    email: s,
                    // @ts-expect-error
                    added: originalObj.added || Date.now(),
                  });
                });
              await api.programs.add360Stakeholders({
                programId: record.programId,
                memberId: record.userId,
                stakeholders: assessmentStakeholdersData,
              });
              updateLocalRecord(record.email, record.programId, {
                assessmentStakeholders: assessmentStakeholdersData,
              });
              setSelected360sRecord(
                Object.assign({}, record, {
                  assessmentStakeholders: assessmentStakeholdersData,
                }),
              );
              setShowNotificationsModal(true);
            } catch (e) {
              toast.error('Could not save 360 stakeholders');
            }
            setSaving(false);
          }}
        />
      </DialogActions>
    </Dialog>
  );
};
