import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import Dialog from '@mui/material/Dialog';
import { api } from '@src/lib/client';
import { SurveyWriteParams } from '@src/lib/client/apis/surveys';
import { useFullScreen } from '@src/hooks/useFullScreen';
import { SendStep } from './steps/Send';
import { AddRecipientsStep } from './steps/AddRecipients';
import {
  DEFAULT_EMAIL_CONTENT,
  DEFAULT_PLATFORM_NOTIFICATION,
  STATIC_EMAIL_CONTENT,
} from '../lib/constants';
import {
  CollectionSchema,
  baseSchema,
  getSchemaForCollection,
} from '../lib/schema';
import { SurveyCollectionType } from '@shared/schemas';

export enum SendSurveyStep {
  send = 'send',
  recipients = 'recipients',
}

interface SendSurveyModalProps {
  isModalOpen: boolean;
  onClose: Function;
  clientId?: string;
  clientName: string;
  surveyId: string;
  surveyName: string;
  collection: SurveyCollectionType;
  isDraftMode: boolean;
  onSendFailures: Function;
}

const SendSurveyModal: FC<SendSurveyModalProps> = ({
  isModalOpen,
  onClose,
  clientId,
  clientName,
  surveyId,
  surveyName,
  collection,
  isDraftMode,
  onSendFailures,
}) => {
  const queryClient = useQueryClient();
  const { fullScreen } = useFullScreen();
  const [activeStep, setActiveStep] = useState(SendSurveyStep.send);
  const [isSendButtonEnabled, setIsSendButtonEnabled] = useState(false);

  queryClient.prefetchQuery({
    queryKey: ['client', clientId, 'members', { limit: 200 }],
    queryFn: async () => api.clients.getMembers(clientId, {}),
  });

  const { mutate: writeSurveyCollection, isPending } = useMutation({
    mutationFn: async (data: {
      body: CollectionSchema;
      params: SurveyWriteParams;
    }) => {
      const { body, params } = data;
      return api.surveys.writeSurveyCollection(
        surveyId,
        clientId,
        body,
        params,
      );
    },
    onSuccess({ data: resData }, { body: reqBody }) {
      queryClient.invalidateQueries({
        queryKey: ['survey', surveyId, 'collections'],
      });
      const errors = resData.recipients.filter(r => r.send_error);
      if (errors.length) {
        // Res doesn't include recipient name, so find corresponding records in req
        onSendFailures(
          reqBody.recipients.filter(r => errors.some(e => e.id === r.id)),
        );
        handleClose(collection);
      } else {
        handleClose();
      }
    },
  });

  const onSubmit = (body, e) => {
    const triggeredBySend = e.nativeEvent?.submitter?.id === 'send-survey'; // we'll keep an eye on this because it seems brittle
    const action = triggeredBySend ? 'send' : 'save';

    writeSurveyCollection({
      body,
      params: {
        action,
        surveyName,
        clientName,
      },
    });
  };

  const validationSchema = useMemo(() => {
    return collection ? getSchemaForCollection(collection) : baseSchema;
  }, [collection]);

  const useSchemaResolver = schema =>
    useCallback(
      data => {
        try {
          const values = schema.validateSync(data, { abortEarly: false });
          // conditionally validate the form for send button
          if (data?.status === 'draft' && !data?.recipients?.length) {
            try {
              schema.validateSync(
                { ...data, status: 'sent' },
                { abortEarly: false },
              );
              setIsSendButtonEnabled(true);
            } catch (err) {
              setIsSendButtonEnabled(false);
            }
          } else {
            setIsSendButtonEnabled(true);
          }
          return { values, errors: {} };
        } catch (errors) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {},
            ),
          };
        }
      },
      [schema],
    );

  const formMethods = useForm<CollectionSchema>({
    defaultValues: {
      id: collection?.id ?? '',
      name: collection?.name ?? '',
      status: collection?.status ?? 'draft',
      client_id: clientId,
      survey_id: surveyId,
      platform_notification: {
        enabled: collection?.platform_notification?.enabled ?? true,
        message:
          collection?.platform_notification?.message ??
          DEFAULT_PLATFORM_NOTIFICATION,
      },
      email_notification: {
        enabled: collection?.email_notification?.enabled ?? true,
        message:
          collection?.email_notification?.message ??
          DEFAULT_EMAIL_CONTENT + STATIC_EMAIL_CONTENT,
      },
      recipients:
        collection?.recipients?.map(({ department, ...r }) => r) ?? [],
    },
    resolver: useSchemaResolver(validationSchema),
  });

  const handleClose = (c = null) => {
    formMethods.reset();
    onClose(c);
  };

  useEffect(() => {
    return () => {
      if (activeStep !== SendSurveyStep.send) {
        setActiveStep(SendSurveyStep.send);
      }
    };
  }, [activeStep, formMethods]);

  return (
    <Dialog
      open={isModalOpen}
      maxWidth='md'
      fullScreen={fullScreen}
      fullWidth
      onClose={() => {
        handleClose();
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          {activeStep === SendSurveyStep.send && (
            <SendStep
              handleClose={handleClose}
              onClickAddRecipients={() =>
                setActiveStep(SendSurveyStep.recipients)
              }
              isDraftMode={isDraftMode}
              isSaving={isPending}
              isSendReady={isSendButtonEnabled}
            />
          )}
          {activeStep === SendSurveyStep.recipients && (
            <AddRecipientsStep
              handleClose={handleClose}
              handleStepChange={() => {
                setActiveStep(SendSurveyStep.send);
              }}
              clientId={clientId}
              isDraftMode={isDraftMode}
            />
          )}
        </form>
      </FormProvider>
    </Dialog>
  );
};

export { SendSurveyModal };
