import React from 'react';
import { Box, Typography, Stack, useTheme, useMediaQuery } from '@mui/material';
import aidaLogo from '@shared/theme/src/assets/aida-logo.svg';

const AidaHeader = () => {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box
      sx={{
        height: '14em',
        backgroundImage: `linear-gradient(to right, 
        ${theme.palette.primary.main},
        ${theme.palette.secondary.main},
        ${theme.palette.error.main} 50%,
        ${theme.palette.warning.main} 68%,
        ${theme.palette.success[400]} 92%,
        ${theme.palette.tertiary.main}
      )`,
        display: 'flex',
        justifyContent: 'center',
        padding: { lg: '0 5em', xs: '0 2em' },
      }}
    >
      <Box
        sx={{
          backgroundColor: 'aida.main',
          width: '100%',
          maxWidth: 1400,
          maxHeight: '9em',
          borderRadius: '16px 16px 0px 16px',
          top: { sm: '50%', xs: '45%' },
          position: 'relative',
          padding: { xs: '1em', md: '2em' },
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Stack
          direction={{ lg: 'row', md: 'column' }}
          alignItems={{ lg: 'center', xs: 'flex-start' }}
          justifyContent='center'
          spacing={{ lg: 5, md: 2, xs: 1 }}
        >
          <Box
            component='img'
            alt='Aida by SkillCycle'
            src={aidaLogo}
            sx={{ height: { lg: '56px', sm: '46px', xs: '36px' } }}
          />
          {/* TODO: improve responsiveness with sidebar hook */}
          <Typography
            variant={lg ? 'h4' : md ? 'body1m' : 'body2m'}
            component='h3'
            color='white.main'
            sx={{ fontWeight: 400 }}
          >
            Hello! I'm Aida, here to support you on your SkillCycle journey.
            Together we'll explore your goals and feedback to help you grow and
            succeed. Ready to take the first step?
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export { AidaHeader };
