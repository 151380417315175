import React, { FC, useState } from 'react';
import { DialogTitle, Button } from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import {
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

const article = {
  terms: 'UVdoalMwNXVlVWhNT0V0VFRIYzlQUT09',
  policy: 'VUhKWFNURXlVVGh2YWxJdmRXYzlQUT09',
};

export const AcknowledgeAcceptance: FC<{
  userId: string;
  setShow: Function;
}> = ({ userId, setShow }) => {
  const [articleId, setArticleId] = useState(article.terms);
  const [accepted, setAccepted] = useState(false);

  const { data: html } = useQuery({
    queryKey: ['terms', articleId],
    queryFn: async () => {
      const content = await fetch(
        `https://app.termageddon.com/api/policy/${articleId}`,
      );
      return content.text();
    },
    enabled: !!articleId,
  });

  const { mutateAsync: acceptTerms, isPending } = useMutation({
    mutationFn: async () => {
      return api.users.acceptTerms(userId);
    },
    onSuccess: () => {
      setShow(false);
    },
  });

  return (
    <Dialog open maxWidth='lg' disableEscapeKeyDown onClose={() => {}}>
      <DialogTitle
        title="We've updated our Terms of Service & Privacy Policy. Please review
          and accept."
      />
      <DialogContent>
        <Accordion
          onChange={(_, expanded) => {
            if (expanded) setArticleId(article['terms']);
          }}
        >
          <AccordionSummary>
            <Typography variant='h4' color='primary'>
              Terms of Service
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {html && (
              <div
                style={{ maxHeight: '35vh', overflow: 'scroll' }}
                dangerouslySetInnerHTML={{ __html: html }}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          onChange={(_, expanded) => {
            if (expanded) setArticleId(article['policy']);
          }}
        >
          <AccordionSummary>
            <Typography variant='h4' color='primary'>
              Privacy Policy
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {html && (
              <div
                style={{ maxHeight: '35vh', overflow: 'scroll' }}
                dangerouslySetInnerHTML={{ __html: html }}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Divider />
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={false}
              checked={accepted}
              onChange={e => {
                setAccepted(e.target.checked);
              }}
            />
          }
          label='I acknowledge that I have read and accept the Terms of Service and Privacy Policy'
        />
      </DialogContent>
      <DialogActions>
        <Button
          text='Okay'
          onClick={async () => {
            await acceptTerms();
          }}
          disabled={!accepted || isPending}
          loading={isPending}
        />
      </DialogActions>
    </Dialog>
  );
};
