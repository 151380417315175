import React from 'react';
import { useDropzone } from 'react-dropzone';
import FileDropzone from './FileDropzone';

interface CsvDropProps {
  currentFileName?: string;
  setCsvFileContent: Function;
  setBaseTableData: Function;
  setCurrentTableData: Function;
  setFileName: Function;
}

const CsvDrop: React.FunctionComponent<CsvDropProps> = ({
  currentFileName,
  setCsvFileContent,
  setBaseTableData,
  setCurrentTableData,
  setFileName,
}) => {
  const onDrop = async (files: any[]) => {
    const file = files[0];
    const { name } = file;
    setFileName(name);
    const reader = new FileReader();
    reader.onload = () => {
      const csvFileContent = reader.result.toString();
      setCsvFileContent(csvFileContent);
      const tableData = csvFileContent?.split('\n').map(line => {
        const record = line?.split(',');
        return record.map(field => field.trim());
      });
      setBaseTableData(tableData);
      setCurrentTableData(tableData);
    };
    reader.readAsText(file);
  };
  const onFileDelete = () => {
    setCsvFileContent('');
    setBaseTableData([]);
    setCurrentTableData([]);
    setFileName('');
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { 'text/csv': ['.csv'] },
    onDrop,
  });
  return (
    <FileDropzone
      currentFileName={currentFileName}
      getRootProps={getRootProps}
      isDragActive={isDragActive}
      getInputProps={getInputProps}
      onFileDelete={onFileDelete}
    />
  );
};

export default CsvDrop;
