import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import {
  Button,
  DialogTitle,
  useToast,
} from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import ResourceEditorForm from '@src/components/forms/ResourceEditorForm/ResourceEditorForm';

interface ResourceEditorModalProps {
  resource?: Resource;
  refetchResources: Function;
  openModal: boolean;
  setOpenModal: (b: boolean) => void;
  setActiveResource: (r: Resource) => void;
}

const ResourceEditorModal: React.FunctionComponent<
  ResourceEditorModalProps
> = props => {
  const toast = useToast();
  const { resource, openModal, setOpenModal, setActiveResource } = props;

  const defaultValues = React.useCallback(
    resource => ({
      description: resource?.description || '',
      type: resource?.type || 'embed',
      name: resource?.name || '',
      uri: resource?.uri || '',
      new_window: resource?.new_window || false,
      default_available: resource?.default_available || false,
    }),
    [],
  );

  const form = useForm({
    mode: 'all',
    defaultValues: defaultValues(resource) as ResourceEditorFormDefaultValues,
  });
  const {
    reset,
    formState: { isValid, isDirty },
  } = form;

  React.useEffect(
    () => reset(defaultValues(resource)),
    [defaultValues, reset, resource],
  );

  const { mutate: setResource, isPending } = useMutation({
    mutationFn: async (payload: Partial<PerformanceCycleI>) => {
      await api.resources.save({ id: resource?.id, ...payload });
    },
    onSuccess: () => {
      props.refetchResources();
      toast.success(resource?.id ? 'Resource updated' : 'New resource saved');
      handleClose();
    },
    onError: () => {
      toast.error(
        resource?.id ? 'Unable to edit resource' : 'Unable to save resource',
      );
    },
  });

  const onSubmit = (data: ResourceEditorOnSubmitProps) => {
    setResource(data);
  };

  function handleClose() {
    setOpenModal(false);
    setActiveResource(null);
    reset();
  }

  return (
    <Dialog open={openModal} onClose={() => handleClose()}>
      <DialogTitle
        title='Resource Editor'
        buttonOnClick={() => setOpenModal(false)}
      />
      <DialogContent dividers>
        <ResourceEditorForm onSubmit={onSubmit} form={form} />
      </DialogContent>
      <DialogActions>
        <Button
          key='close'
          text='Close'
          onClick={handleClose}
          variant='outlined'
        />
        <Button
          sx={{ marginLeft: '.5em' }}
          type='submit'
          form='resource-editor-form'
          disabled={!isValid || !isDirty}
          key='save'
          text={resource?.id ? 'Save' : 'Create'}
          loading={isPending}
        />
      </DialogActions>
    </Dialog>
  );
};

export { ResourceEditorModal };
