import React from 'react';
import { Button, DialogTitle } from '@src/components/sc-design-system';
import { ButtonProps } from '@src/components/sc-design-system/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

interface ConfirmDialogProps {
  open: boolean;
  setOpenModal: (b: false) => void;
  title: string;
  body: string | JSX.Element;
  buttonProps: ButtonProps;
  showCancelButton?: boolean;
  cancelText?: string;
  onCancel?: Function;
}

function ConfirmDialog(props: ConfirmDialogProps) {
  const {
    open,
    setOpenModal,
    title,
    body,
    showCancelButton = true,
    cancelText = 'Cancel',
    onCancel,
  } = props;
  const { onClick, ...buttonProps } = props.buttonProps;

  function handleClose() {
    setOpenModal(false);
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm'>
      <DialogTitle title={title} buttonOnClick={handleClose} />
      <DialogContent>
        {typeof body === 'string' ? (
          <Typography variant='body1' sx={{ marginBottom: '1em' }}>
            {body}
          </Typography>
        ) : (
          body
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        {showCancelButton && (
          <Button
            variant='outlined'
            onClick={() => {
              onCancel && onCancel();
              handleClose();
            }}
            text={cancelText}
          />
        )}
        <Button
          onClick={event => {
            // if onClick exists vs type=submit then run it
            onClick && onClick(event);
            handleClose();
          }}
          {...buttonProps}
        />
      </DialogActions>
    </Dialog>
  );
}

export { ConfirmDialog };
