import React, { FC } from 'react';
import { Autocomplete, Box, Chip, Stack, Typography } from '@mui/material';
import { TextField, Avatar, Spin } from '@src/components/sc-design-system';

interface InviteeSelectProps {
  onSelect: (e: any, value: any) => void;
  value: Array<{
    label: string;
    value: {
      user_id: string;
      email: string;
      first_name: string;
      last_name: string;
    };
  }>;
  loading: boolean;
  disabled?: boolean;
  keyword: string;
  onInputChange: Function;
  options: Array<{
    label: string;
    value: {
      user_id: string;
      email: string;
      first_name: string;
      last_name: string;
    };
  }>;
  limitTags?: number;
}

const InviteeSelect: FC<InviteeSelectProps> = ({
  value,
  loading,
  disabled = false,
  onSelect,
  keyword,
  onInputChange,
  options,
  limitTags,
}) => {
  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      multiple
      filterSelectedOptions
      clearOnBlur
      disableClearable
      value={value}
      loading={loading}
      loadingText={<Spin />}
      onChange={onSelect}
      limitTags={limitTags}
      popupIcon={null}
      renderTags={(value, getTagProps) => {
        return value.map((item, index: number) => (
          <Chip
            key={item.value.id}
            variant='outlined'
            color='primary'
            size='medium'
            label={
              <Stack direction='row' gap={1} alignItems='center'>
                <Avatar srcId={item.value.user_id} size='xs' alt={item.label} />
                {item.label}
              </Stack>
            }
            {...getTagProps({ index })}
          />
        ));
      }}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            type: 'search',
          }}
          value={keyword}
          placeholder={value?.length ? '' : 'Start typing...'}
          label=''
          onChange={e => {
            onInputChange(e.target.value);
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box component='li' {...props}>
          <Avatar size='md' srcId={option.value.user_id} alt={option.label} />
          <Typography sx={{ marginLeft: '.5em' }}>{option.label}</Typography>
        </Box>
      )}
      options={options}
    />
  );
};

export { InviteeSelect };
