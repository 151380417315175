import { ProgramViewModel } from '@src/components/views/Program/ProgramViewModel';
import Program from '@src/models/Program';
import Survey from '@src/models/Survey';

export const surveyTypes = {
  intake: {
    label: 'Intake Survey',
    helpText:
      'This survey initiates, and is currently required, on member registration.',
  },
  '360_self': {
    label: 'Self-Assessment Survey',
    helpText:
      'This survey is available for the member at the outset of their program.',
  },
  '360_stakeholder': {
    label: '360 Stakeholder Assessment Survey',
    helpText:
      'This survey is available for the member to send to stakeholders at the outset of their program.',
  },
  midpoint_engagement: {
    label: 'Midpoint Engagement Survey',
    helpText:
      'This survey is triggered after the member successfully completes two sessions in their program.',
  },
  final_engagement: {
    label: 'Endpoint Final Engagement Survey',
    helpText:
      'This survey is triggered after the member finishes all sessions in their program.',
  },
};

export const alignProgramSurveysWithTypes = (
  surveys: Survey[],
  program: Program | ProgramViewModel,
) => {
  return Object.keys(surveyTypes).map(type => {
    const survey = surveys?.find(s => {
      // survey is saved on program
      return s.id === program?.surveys?.[type]?.id;
    });
    const enabled = program?.surveys?.[type]?.enabled;
    return Object.assign({}, survey, { type, enabled });
  });
};
