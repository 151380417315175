import React from 'react';
import { FormControl, Stack, InputLabel } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  DatePicker as DatePickerMUI,
  DatePickerProps as DatePickerPropsMUI,
} from '@mui/x-date-pickers/DatePicker';
import { RefCallBack, Noop } from 'react-hook-form';

export interface DatePickerProps
  // onChange is supplied by react-hook-form not the date picker
  extends Omit<DatePickerPropsMUI<any>, 'onChange'> {
  onChange: (...event: any[]) => void;
  onBlur?: Noop;
  innerRef?: RefCallBack;
  clearable?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  helperText?: string;
  error?: boolean;
  errorMessage?: string;
  label: string;
  size?: 'medium' | 'small'; // @TODO needed with mixed antd/mui forms - can be removed once they are all flipped over to mui
}

const DatePicker: React.FunctionComponent<DatePickerProps> = props => {
  const {
    innerRef,
    label,
    disabled,
    fullWidth,
    onChange,
    onBlur,
    required,
    clearable,
    error,
    errorMessage,
    helperText,
    size,
    ...fieldProps
  } = props;
  return (
    <FormControl
      component={Stack}
      spacing={1}
      disabled={disabled}
      fullWidth={fullWidth}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <InputLabel
          id={`${fieldProps.name}-input-label`}
          htmlFor={fieldProps.name}
          required={required}
          disabled={disabled}
        >
          {label}
        </InputLabel>
        <DatePickerMUI
          {...fieldProps}
          ref={innerRef}
          onChange={onChange}
          slotProps={{
            field: { clearable },
            textField: {
              onChange: e => onChange(e),
              onBlur,
              required,
              id: fieldProps.name,
              error,
              helperText: errorMessage || helperText,
              size,
            },
          }}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export { DatePicker };
