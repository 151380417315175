import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import { Card, Spin } from '@src/components/sc-design-system';
import { useClientQueries } from '@src/hooks/useClientQueries';
import Client from '@src/models/Client';
import { HRISExistingIntegration } from './HRISExistingIntegration';
import { HRISSetupIntegration } from './HRISSetupIntegration';

const HRISSyncCard: FC<{ client: Client }> = ({ client }) => {
  const [isRemoved, setIsRemoved] = useState(false);
  const { useGetClientMergeHrisIntegration } = useClientQueries(client.id);
  const { data, isFetching, isLoading } = useGetClientMergeHrisIntegration();

  return (
    <Card title='HRIS sync' titleActions={null}>
      <Box
        sx={{
          backgroundColor: 'grey.50',
          width: '100%',
          height: '135px',
          display: 'flex',
          alignItems: 'center',
          padding: '2em',
          justifyContent: 'space-between',
        }}
      >
        {(isLoading || isFetching) && !data ? (
          <Spin sectionLoader />
        ) : !data || isRemoved ? (
          <HRISSetupIntegration client={client} />
        ) : data ? (
          <HRISExistingIntegration
            client={client}
            setIsRemoved={setIsRemoved}
          />
        ) : (
          ''
        )}
      </Box>
    </Card>
  );
};

export { HRISSyncCard };
