import React from 'react';
import { Alert, Typography } from '@mui/material/';
import { Button } from '@src/components/sc-design-system';
import { AidaUserDisplayMessage } from '@src/components/views/Aida/AssistantButtons';

interface CompleteStepsAlertProps {
  selectedPrompt: Prompts;
  conversations: Conversations;
  handlePromptSelection: HandlePromptSelection;
  setUserDisplayMessage: (m: string) => void;
  setAssistantMessage: (m: AidaMessage) => void;
}

function CompleteStepsAlert(props: CompleteStepsAlertProps) {
  const {
    selectedPrompt,
    conversations,
    handlePromptSelection,
    setUserDisplayMessage,
    setAssistantMessage,
  } = props;

  function alertOnClick(prompt: 'summary' | 'focus_areas') {
    handlePromptSelection(prompt, true);
    setUserDisplayMessage(AidaUserDisplayMessage[prompt]);
    setAssistantMessage(null);
  }

  const promptAlerts = {
    focus_areas: {
      text: 'Get started with your development summary first, then Aida will be able to provide you with focus areas.',
      buttonText: 'Get started',
      buttonAction: () => alertOnClick('summary'),
    },
    resources: {
      text: 'Aida can make better resource recommendations once you’ve gone through the steps of building a development plan and focus areas.',
      buttonAction: () =>
        alertOnClick(!conversations?.summary ? 'summary' : 'focus_areas'),
    },
    coaches: {
      text: 'SkillCycle can make better coach recommendations once you’ve gone through the steps of building a development plan and focus areas.',
      buttonAction: () =>
        alertOnClick(!conversations?.summary ? 'summary' : 'focus_areas'),
    },
    ask: {
      text: 'Get started with your development summary first, then Aida will be happy to respond to your questions.',
      buttonText: 'Get started',
      buttonAction: () => alertOnClick('summary'),
    },
  };

  const promptAlert = promptAlerts[selectedPrompt];
  // this prompt doesn't need a complete step alert
  if (!promptAlert) return null;

  return (
    <Alert
      severity='info'
      action={
        <Button
          variant='text'
          size='small'
          shape='rounded-square'
          text={promptAlert?.buttonText || 'Complete steps'}
          formatText={false}
          onClick={promptAlert.buttonAction}
        />
      }
    >
      <Typography variant='body2'>{promptAlert.text}</Typography>
    </Alert>
  );
}

export { CompleteStepsAlert };
