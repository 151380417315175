import React, { FC, useMemo } from 'react';
import _startCase from 'lodash/startCase';
import { Chip, Typography } from '@mui/material';
import { Card, TableMUI as Table } from '@src/components/sc-design-system';

const cardStyle = { borderColor: 'grey.400' };

interface GoalAttainmentProps {
  data?: EvaluationReport;
  userId: string;
}

const GoalAttainment: FC<GoalAttainmentProps> = ({ data, userId }) => {
  let dataWithComments = [];
  data?.goals?.forEach(g => {
    dataWithComments.push(g);
    const employeeStatuses = g?.status_history?.filter(
      s => s.creator_id === userId,
    );

    const latestEmployeeStatus =
      employeeStatuses?.[employeeStatuses?.length - 1];
    const managerStatuses = g?.status_history?.filter(
      s => s.creator_id === data?.results?.manager?.id,
    );
    const latestManagerStatus = managerStatuses?.[managerStatuses?.length - 1];
    let comments = [];
    if (latestEmployeeStatus?.notes) {
      comments.push(
        `${latestEmployeeStatus?.creator_first_name}: ${latestEmployeeStatus?.notes}`,
      );
    }
    if (latestManagerStatus?.notes) {
      comments.push(
        `${latestManagerStatus?.creator_first_name}: ${latestManagerStatus?.notes}`,
      );
    }
    if (comments?.length) {
      dataWithComments.push({
        title: (
          <>
            <Typography variant='body2b'>Comments</Typography>
            <ul>
              {comments.map((comment, i) => (
                <li key={i} dangerouslySetInnerHTML={{ __html: comment }} />
              ))}
            </ul>
          </>
        ),
        type: 'comments',
      });
    }
    return;
  });
  const columns = useMemo(
    () => [
      {
        header: 'Goal',
        cell: ({ row }) => {
          return row.original.title;
        },
        size: 225,
      },
      {
        header: 'Type',
        cell: ({ row }) => {
          if (row.original.type === 'comments') {
            return '';
          }
          _startCase(row.original.type);
        },
        size: 225,
      },
      {
        header: 'Goal alignment',
        cell: ({ row }) => {
          if (row.original.type === 'comments') {
            return '';
          }
          row.original.aligned_goal_name || '—';
        },
        size: 225,
      },
      {
        header: 'Goal weight',
        cell: ({ row }) => {
          if (row.original.type === 'comments') {
            return '';
          }
          const goalCycle = data?.performance_cycle?.goal_cycles.find(
            ({ id }) => id === row.original.goal_cycle_id,
          );

          if (
            !goalCycle?.goal_weighting_enabled ||
            row.original.weight == null
          ) {
            return '—';
          }
          return (
            <Chip label={`${row.original.weight}% weight`} color='default' />
          );
        },
      },
      {
        header: 'Self evaluation',
        cell: ({ row }) => {
          if (row.original.type === 'comments') {
            return '';
          }
          const statuses = row.original.status_history?.filter(
            s => s.creator_id === userId,
          );
          const latestStatus = statuses?.[statuses.length - 1];
          if (!latestStatus) return '—';
          const { status } = latestStatus;
          return (
            <Chip
              label={_startCase(status)}
              color={
                ['partially_met', 'off_track'].includes(status)
                  ? 'warning'
                  : [
                      'on_track',
                      'met',
                      'frequently_exceeded_expectations',
                      'consistently_exceeded_expectations',
                    ].includes(status)
                  ? 'success'
                  : ['at_risk', 'not_met'].includes(status)
                  ? 'error'
                  : 'default'
              }
            />
          );
        },
      },
      {
        header: 'Manager evaluation',
        cell: ({ row }) => {
          if (row.original.type === 'comments') {
            return '';
          }
          const statuses = row.original.status_history?.filter(
            s => s.creator_id === data?.results?.manager?.id,
          );
          const latestStatus = statuses?.[statuses.length - 1];
          if (!latestStatus) return '—';
          const { status } = latestStatus;
          return (
            <Chip
              label={_startCase(status)}
              color={
                ['partially_met', 'off_track'].includes(status)
                  ? 'warning'
                  : [
                      'on_track',
                      'met',
                      'frequently_exceeded_expectations',
                      'consistently_exceeded_expectations',
                    ].includes(status)
                  ? 'success'
                  : ['at_risk', 'not_met'].includes(status)
                  ? 'error'
                  : 'default'
              }
            />
          );
        },
      },
    ],
    [data, userId],
  );

  return (
    <Card
      title='Goal attainment'
      sx={{
        ...cardStyle,
        '&.MuiPaper-outlined': cardStyle,
        '.MuiCardHeader-root': cardStyle,
      }}
    >
      <Table
        data={dataWithComments}
        emptyState='Goal have not been entered yet.'
        columns={columns}
      />
    </Card>
  );
};

export { GoalAttainment };
