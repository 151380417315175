import React, { FC, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '@src/models/Store';
import {
  usePageTitle,
  useMustBeAuthenticated,
  useNavigation,
} from '@src/navigation';
import { AppLoader } from '../AppLoader';
import { AppShell } from '../Shell/AppShell';
import { Survey } from '../../common/Survey/Survey';
import { AcknowledgeAcceptance } from '@src/components/forms/AcknowledgeAcceptance/AcknowledgeAcceptance';
import { useRedirect } from '@src/hooks/useRedirect';
import Home from '@src/components/views/Home/Home';
import UserProfile from '@src/components/forms/UserProfile/UserProfile';
import CoachSearch from '@src/components/views/Coaches/CoachSearch';
import Administration from '@src/components/views/Administration/Administration';
import AdminUsersList from '@src/components/views/Administration//UsersList/UsersList';
import AdminClientsList from '@src/components/views/Administration/ClientsList/ClientsList';
import SOWsList from '@src/components/views/Administration/SOWs/SOWsList';
import ProgramsList from '@src/components/views/Administration/Programs/ProgramsList';
import Session from '@src/components/views/Session/Session';
import CompetencySetLandingPage from '@src/components/views/MyBusiness/CompetencySetLandingPage';
import Program from '@src/components/views/Program/Program';
import ResourcesManager from '@src/components/views/ResourcesManager/ResourcesManager';
import SurveysManager from '@src/components/views/SurveysManager/SurveysManager';
import SurveyEditor from '@src/components/views/SurveysManager/SurveyEditor/SurveyEditor';
import CoacheeProgram from '@src/components/views/Coachees/Program/Program';
import { Aida } from '@src/components/views/Aida/Aida';
import { PerformanceAndGrowth } from '@src/components/views/PerformanceAndGrowth';
import DirectReportProfile from '@src/components/views/DirectReportProfile/DirectReportProfile';
import TeamDashboard from '@src/components/views/TeamManagement/TeamDashboard';
import Report from '@src/components/views/Report/Report';
import { ClientAdmin } from '@src/components/views/MyBusiness/Admin';
import { ClientPrograms } from '@src/components/views/MyBusiness/Programs';
import { Performance } from '@src/components/views/MyBusiness/Performance';
import { PerformanceCycleLandingPage } from '@src/components/views/MyBusiness/Performance/tabs/PerformanceCycles/PerformanceCycleLandingPage';
import { SurveysAndInsights } from '@src/components/views/MyBusiness/SurveysAndInsights';
import { PerformanceEvaluationReport } from '@src/components/views/PerformanceEvaluationReport';
import { PerformanceCycleFeedbackReport } from '@src/components/views/PerformanceCycleFeedbackReport';
import { useBreadcrumbs } from '@src/components/sc-design-system';
import { ActiveClientKey, useActiveClient } from '@src/hooks/useActiveClient';
import { useIntakeSurvey } from '@src/hooks/useIntakeSurvey';
import { useUser } from '@src/hooks/useUser';
import { useUserQueries } from '@src/hooks/useUserQueries';

const availableComponents = {
  Home,
  UserProfile,
  CoachSearch,
  Administration,
  AdminUsersList,
  AdminClientsList,
  SOWsList,
  ProgramsList,
  Session,
  ClientAdmin,
  CompetencySetLandingPage,
  Program,
  ResourcesManager,
  SurveysManager,
  SurveyEditor,
  CoacheeProgram,
  Aida,
  PerformanceAndGrowth,
  DirectReportProfile,
  TeamDashboard,
  Report,
  ClientPrograms,
  Performance,
  SurveysAndInsights,
  PerformanceCycleLandingPage,
  PerformanceEvaluationReport,
  PerformanceCycleFeedbackReport,
};

interface AuthenticatedViewProps {
  componentName: string;
  title: string;
  noLayout?: boolean;
  createBreadcrumb?: boolean;
}

export let AuthenticatedView: FC<AuthenticatedViewProps> = ({
  componentName,
  title,
  noLayout,
  createBreadcrumb,
}) => {
  const store = useStore();
  const navigation = useNavigation();
  const [showAcceptTerms, setShowAcceptTerms] = useState(false);
  const redirect = useRedirect();
  const { onSubmitIntake } = useIntakeSurvey();
  useSessionPoller();
  usePageTitle(title);
  useMustBeAuthenticated();
  const { breadcrumbs, resetBreadcrumbs } = useBreadcrumbs();
  const { isLoading, isFetching } = useActiveClient({
    queryKey: ActiveClientKey.profile_active_client_id,
  });
  const isActiveClientLoading = isLoading || isFetching;

  React.useEffect(() => {
    if (!store.user.accepted_terms) {
      setShowAcceptTerms(true);
    }
    //eslint-disable-next-line
  }, [store.user]);

  React.useEffect(() => {
    if (redirect && !isActiveClientLoading) {
      navigation.replace('/');
      resetBreadcrumbs([]);
    }
  }, [redirect, navigation, store, resetBreadcrumbs, isActiveClientLoading]);

  React.useEffect(() => {
    if (createBreadcrumb && !breadcrumbs.length) {
      resetBreadcrumbs([
        {
          path: navigation.location.pathname,
          label: title,
        },
      ]);
    }
  }, [createBreadcrumb, breadcrumbs, resetBreadcrumbs, title, navigation]);

  if (!store.session || store.loading) {
    return <AppLoader />;
  }

  const View = availableComponents[componentName];
  if (noLayout) {
    return <View store={store} />;
  }
  return showAcceptTerms ? (
    <AcknowledgeAcceptance
      userId={store.user.id}
      setShow={setShowAcceptTerms}
    />
  ) : (
    <AppShell>
      <View store={store} />
      <Survey user={store?.user} onSubmit={onSubmitIntake} />
    </AppShell>
  );
};
AuthenticatedView = observer(AuthenticatedView);

const useSessionPoller = () => {
  const timeout = useRef<any>(null); // should be NodeJS.Timeout but TS needs to be fixed first.
  const running = useRef(false);
  const location = useLocation();
  const { user } = useUser();
  const { useGetUserPrograms } = useUserQueries(user.id);
  const { refetch } = useGetUserPrograms('coachee');

  React.useEffect(() => {
    if (['development', 'uat'].includes(process.env.NODE_ENV)) return; // Too heavy to run locally

    if (['/home', '/coaches'].includes(location.pathname)) {
      const poll = async () => {
        try {
          refetch();
        } catch (e) {
          // ignore, do not break the poller.
        }
        if (running.current) {
          timeout.current = setTimeout(() => {
            if (running.current) {
              poll();
            }
          }, 1000 * 60); // 1 minute
        }
      };
      if (!running.current) {
        running.current = true;
        poll();
      }
    } else if (running.current) {
      clearTimeout(timeout.current);
      running.current = false;
      timeout.current = null;
    }
  }, [refetch, location]);
};
