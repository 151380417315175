import { message } from 'antd';
import { computed, makeObservable, observable, toJS } from 'mobx';
import { survey as SurveySchema } from '@shared/schemas';
import Base from './_Base';
import SurveyQuestion from './SurveyQuestion';
import { api } from '@src/lib/client';

export interface OnSubmit {
  submit_title: string;
  submit_message: string;
  action: string[];
}

export interface Group {
  id: string;
  title: string;
  type: string;
  helpText: string;
  condition?: any;
  questions: any;
}

export interface Question {
  id?: string;
  condition?: any;
  group?: Group;
}

export interface Option {
  id?: string;
  value?: any;
  text: string;
  index_position: number;
}

export const surveyProperties = [
  'id',
  'client_id',
  'title',
  'hidden',
  'questions',
  'on_submit',
  'type',
  'description',
];

export const DEFAULT_SURVEY_IDS = [
  '3636f28e-f057-4697-8cb4-4aa957131202', // intake
  'efa4a993-bd22-4c62-a99f-3419b726b4d5', // 360 self
  '8e810b79-73a6-4e43-af5d-08638b734d89', // 360 stakeholder
  '1f8d808-18df-11ed-861d-0242ac120002', // midpoint
  '9z8p080-18df-11ed-861d-0242ac120002', // final
];

export default class Survey extends Base {
  // Provides template for survey editor form
  id: string;
  client_id: string;
  title: string;
  hidden: string[] = [];
  questions: SurveyQuestion[] = [];
  on_submit: OnSubmit = {
    submit_title: 'Thank you!',
    submit_message: 'You have successfully submitted a response to the survey.',
    action: [],
  };
  notes?: string;
  type: string;
  responses?: number;
  survey_id?: string;
  last_published_at?: number;
  last_published_id?: string;
  updatedAt?: number;
  description?: string;

  constructor(survey: SurveySchema.SurveyType = {}) {
    super();
    makeObservable(this, {
      id: observable,
      client_id: observable,
      on_submit: observable,
      title: observable,
      hidden: observable,
      questions: observable,
      notes: observable,
      type: observable,
      responses: observable,
      survey_id: observable,
      description: observable,
      status: computed,
      isSCDefault: computed,
    });
    this.mergeData(survey);
  }

  mergeData = (survey: SurveySchema.SurveyType) => {
    Object.keys(survey).forEach(prop => {
      if (prop === 'questions') {
        this[prop] = survey[prop].map(q => {
          const id = q.id;
          const condition = q.condition;
          const group = q.group;
          if (group?.questions) {
            group.questions = group.questions.map(qq => new SurveyQuestion(qq));
          }
          return new SurveyQuestion(
            Object.assign({}, { id }, { condition }, { group }),
          );
        });
      } else {
        this[prop] = survey[prop];
      }
    });
  };

  get status() {
    // brand new survey
    if (!this.last_published_at && !this.id) return '';
    // survey has been saved but never published
    if (!this.last_published_at && this.id) return 'draft';
    // survey has been published
    if (this.last_published_at) {
      // survey has no changes since publish date
      if (!this.updatedAt || this.last_published_at > this.updatedAt) {
        return 'published';
      }
      // survey has been updated since last publish
      if (this.last_published_at < this.updatedAt) {
        return 'unpublished-changes';
      }
    }
    return '';
  }

  get isSCDefault() {
    return DEFAULT_SURVEY_IDS.includes(this.id);
  }

  get data() {
    return JSON.parse(
      JSON.stringify(
        surveyProperties.reduce((acc, prop) => {
          if (prop === 'questions') {
            acc[prop] = this[prop].map(q => {
              const id = q.id;
              const condition = q.condition;
              const group = q.group;
              if (group?.questions) {
                group.questions = group.questions.map(qq => {
                  return {
                    id: qq.id,
                    condition: qq.condition,
                    group: qq.group,
                  };
                });
              }
              return Object.assign({}, { id }, { condition }, { group });
            });
          } else {
            acc[prop] = toJS(this[prop]);
          }
          return acc;
        }, {}),
      ),
    );
  }

  get publishData() {
    return JSON.parse(
      JSON.stringify(
        [...surveyProperties, 'notes', 'survey_id'].reduce((acc, prop) => {
          if (prop === 'questions') {
            acc[prop] = this[prop].map(q => {
              if (q.group) {
                return Object.assign(
                  {},
                  {
                    group: q.group,
                    condition: q.condition,
                    title: q.title,
                    helpText: q.helpText,
                    type: q.type,
                  },
                );
              }
              return q;
            });
          } else if (prop === 'survey_id') {
            acc[prop] = this.id;
          } else if (prop === 'id') {
            // do nothing
          } else {
            acc[prop] = toJS(this[prop]);
          }
          return acc;
        }, {}),
      ),
    );
  }

  save = async () => {
    try {
      const res = await api.surveys.saveSurvey(this.data);
      this.mergeData(res.data); // Update local
    } catch (e) {
      message.error('Could not save Survey record');
      throw e;
    }
  };

  publish = async () => {
    try {
      const res = await api.surveys.publishSurvey(this.publishData);
      this.last_published_at = res.data.published_at;
    } catch (e) {
      message.error('Could not publish Survey record');
      throw e;
    }
  };
}
