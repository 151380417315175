import React, { FC, useMemo, useState } from 'react';
import { Stack, Typography, Link, Divider } from '@mui/material';
import { Card, useBreadcrumbs } from '@src/components/sc-design-system';
import { RequestFeedbackModal } from '@src/components/common/RequestFeedbackModal';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { useNavigate } from 'react-router';

interface CollectFeedbackProps {
  clientId: string;
  userId: string;
  userFirstName: string;
  userLastName: string;
  performanceCycleId: string;
  feedbackFormId: string;
}

const CollectFeedback: FC<CollectFeedbackProps> = ({
  clientId,
  userId,
  userFirstName,
  userLastName,
  performanceCycleId,
  feedbackFormId,
}) => {
  const navigate = useNavigate();
  const { appendBreadcrumb } = useBreadcrumbs();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { useGetPerformanceCycleFeedbackStatusForMember } =
    useClientQueries(clientId);
  const { feedbackData } = useGetPerformanceCycleFeedbackStatusForMember(
    performanceCycleId,
    feedbackFormId,
    userId,
  );

  const isReportReady = useMemo(() => {
    if (!feedbackData) return false;
    if (feedbackData?.stakeholders?.length < 5) return false;
    const completedTotal = feedbackData?.stakeholders.reduce((num, s) => {
      if (s.feedback_submission_id) {
        return num + 1;
      }
      return num;
    }, 0);
    return completedTotal >= 5;
  }, [feedbackData]);

  return (
    <Stack sx={{ marginBottom: '1.5em' }}>
      <Typography variant='h3' sx={{ marginBottom: '1em' }}>
        Collect feedback
      </Typography>
      <Card elevation={5} variant='elevation' sx={{ margin: 0 }}>
        <Stack
          direction={{ md: 'row', sm: 'column' }}
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={{ margin: { sm: '0 2em' } }}
            />
          }
          spacing={{ sm: 4 }}
        >
          <Stack width={{ md: '50%' }}>
            <Typography variant='h4' sx={{ marginBottom: '0.5em' }}>
              Request feedback
            </Typography>
            <Typography sx={{ marginBottom: '1.5em' }}>
              Ask for feedback from stakeholders and manage them all in one
              place.
            </Typography>
            <>
              <Link
                variant='a1'
                sx={{ width: 'fit-content' }}
                onClick={() => setIsModalOpen(true)}
              >
                {feedbackData?.stakeholders?.length
                  ? 'Manage participants'
                  : 'Request feedback'}
              </Link>
              {isModalOpen && (
                <RequestFeedbackModal
                  open={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  clientId={clientId}
                  userId={userId}
                  userFirstName={userFirstName}
                  userLastName={userLastName}
                  performanceCycleId={performanceCycleId}
                  feedbackFormId={feedbackFormId}
                />
              )}
            </>
          </Stack>
          {isReportReady && (
            <Stack width={{ md: '50%' }}>
              <Typography variant='h4' sx={{ marginBottom: '.5em' }}>
                View report on feedback
              </Typography>
              <Typography sx={{ marginBottom: '1.5em' }}>
                View an aggregate report of feedback from your stakeholders,
              </Typography>
              <Link
                variant='a1'
                onClick={() => {
                  const path = `/feedback-report?clientId=${clientId}&performanceCycleId=${performanceCycleId}&feedbackFormId=${feedbackFormId}&userId=${userId}&name=${encodeURIComponent(
                    `${userFirstName} ${userLastName}`,
                  )}`;
                  appendBreadcrumb({
                    path,
                    label: `Report for ${userFirstName} ${userLastName}`,
                  });
                  navigate(path, {
                    state: { employeeName: `${userFirstName} ${userLastName}` },
                  });
                }}
              >
                View report
              </Link>
            </Stack>
          )}
        </Stack>
      </Card>
    </Stack>
  );
};

export { CollectFeedback };
