import React from 'react';
import { FormHelperText, Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import {
  Card,
  useToast,
  TextField,
  Select,
} from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import Client from '@src/models/Client';
interface DetailsCardProps {
  member: ClientMember;
  readOnly: boolean;
  client: Client;
  allClientMembers: ClientMember[];
  updatedProps: string[];
  setUpdatedProps: (sA: string[]) => void;
  setUpdatedData: (cM: ClientMember[]) => void;
}

const updateTitle = async ({ clientId, memberId, title }) =>
  api.clients.changeMemberTitle(clientId, memberId, title || '');
const updateDepartment = async ({ clientId, memberId, departmentId }) =>
  api.clients.changeDepartment(clientId, memberId, departmentId);
const updateManager = async ({ clientId, memberId, managerId }) =>
  api.clients.changeManager(clientId, memberId, managerId);

export default function DetailsCard(props: DetailsCardProps) {
  const {
    member,
    readOnly,
    client,
    allClientMembers,
    updatedProps,
    setUpdatedProps,
    setUpdatedData,
  } = props;
  const toast = useToast();
  const [title, setTitle] = React.useState(member.title || '');
  const hasDept = client.departments.some(
    ({ id }) => id === member.department?.id,
  );
  const hasManager = allClientMembers?.some(({ id }) => id === member.manager);
  const [department, setDepartment] = React.useState(
    hasDept ? member.department?.id : '',
  );
  const [manager, setManager] = React.useState(
    hasManager ? member.manager : '',
  );

  const { mutate: updateMemberTitle, isPending: isMemberTitleChangeLoading } =
    useMutation({
      mutationFn: updateTitle,
      onSuccess: ({ data }) => {
        setUpdatedProps(['title', ...updatedProps]);
        setUpdatedData(data);
        toast.success('Title successfully updated');
      },
      onError() {
        toast.error('Could not change title of member');
      },
    });
  const {
    mutate: updateMemberDepartment,
    isPending: isMemberDepartmentChangeLoading,
  } = useMutation({
    mutationFn: updateDepartment,
    onSuccess: ({ data }) => {
      setUpdatedProps(['department', ...updatedProps]);
      setUpdatedData(data);
      toast.success('Department successfully updated');
    },
    onError() {
      toast.error('Could not change department of member');
    },
  });
  const {
    mutate: updateMemberManager,
    isPending: isMemberManagerChangeLoading,
  } = useMutation({
    mutationFn: updateManager,
    onSuccess: ({ data }) => {
      setUpdatedProps(['manager', ...updatedProps]);
      setUpdatedData(data);
      toast.success('Manager successfully updated');
    },
    onError() {
      toast.error('Could not change manager of member');
    },
  });
  const departments = client.departments
    ?.map(d => {
      return { label: d.name, value: d.id };
    })
    .sort((a, b) => (a.label < b.label ? -1 : 1));

  return (
    <Card
      variant='elevation'
      title='Details'
      titleStyle='h3styleh4'
      sx={{ marginTop: '2em' }}
    >
      <Stack spacing={2}>
        <TextField
          placeholder={readOnly ? '' : 'Enter text...'}
          id={`title-${member.id}`}
          key={`title-${member.id}`}
          label='Title'
          fullWidth
          value={title}
          onChange={event => setTitle(event.target.value)}
          disabled={isMemberTitleChangeLoading || readOnly}
          onBlur={() => {
            const originalTitle = member.title || '';
            // only make update call if title changes
            if (title !== originalTitle) {
              updateMemberTitle({
                clientId: client.id,
                memberId: member.id,
                title,
              });
            }
          }}
        />
        <Select
          fullWidth
          label='Department'
          value={department}
          onChange={event => {
            updateMemberDepartment({
              clientId: client.id,
              memberId: member.id,
              departmentId: event.target?.value || 'none',
            });
            const dpt = event.target?.value || 'none';
            setDepartment(dpt as string);
          }}
          options={[
            {
              options: departments,
            },
          ]}
          disabled={
            departments.length === 0 ||
            isMemberDepartmentChangeLoading ||
            readOnly
          }
        />
        {departments.length === 0 && (
          <FormHelperText disabled>
            Departments are not yet defined. This can be managed in the
            Departments tab
          </FormHelperText>
        )}
        <Select
          fullWidth
          label='Manager'
          value={manager}
          onChange={event => {
            updateMemberManager({
              clientId: client.id,
              memberId: member.id,
              managerId: event.target?.value || 'none',
            });
            const mgr = event.target?.value || 'none';
            setManager(mgr as string);
          }}
          options={[
            {
              options:
                allClientMembers
                  ?.filter(cm => cm.id !== member.id)
                  .map(cm => {
                    return {
                      label: `${cm.first_name} ${cm.last_name} `,
                      value: cm.id,
                    };
                  })
                  .sort((a, b) => (a.label < b.label ? -1 : 1)) ?? [],
            },
          ]}
          disabled={isMemberManagerChangeLoading || readOnly}
        />
      </Stack>
    </Card>
  );
}
