import { computed, makeObservable, observable, toJS } from 'mobx';

export default class Invitee {
  invitee_first_name? = undefined;
  invitee_last_name? = undefined;
  invitee_email = undefined;
  invitedAt? = undefined;
  joinedAt? = undefined;
  revokedAt? = undefined;

  constructor(invitee) {
    makeObservable(this, {
      invitee_first_name: observable,
      invitee_last_name: observable,
      invitee_email: observable,
      invitedAt: observable,
      joinedAt: observable,
      revokedAt: observable,
      // Computeds
      data: computed,
    });
    Object.keys(invitee).forEach(prop => {
      this[prop] = invitee[prop];
    });
  }

  get data() {
    return JSON.parse(
      JSON.stringify({
        invitee_first_name: toJS(this.invitee_first_name) || '',
        invitee_last_name: toJS(this.invitee_last_name) || '',
        invitee_email: toJS(this.invitee_email),
        invitedAt: toJS(this.invitedAt),
        joinedAt: toJS(this.joinedAt),
        revokedAt: toJS(this.revokedAt),
      }),
    );
  }
}
