import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Table, Pagination, Space } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import {
  Card,
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
  useBreadcrumbs,
} from '@src/components/sc-design-system';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { handleKeyboardNav, useQueryParams } from '@src/utils';
import { ActiveClientKey } from '@src/hooks/useActiveClient';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { useSOWs } from '@src/hooks/useSOWs';
import { useUser } from '@src/hooks/useUser';
import { SOWEditorButton } from './components/SOWEditorButton';

const PAGE_LIMIT = 25;

const setQueryParams = (
  navigate: Function,
  query: any,
  name: string,
  value: string | number,
) => {
  let currentPage = query.get('page') || 1;
  if (name === 'page') {
    currentPage = value;
  }

  // Build query
  const composedQuery = `?${currentPage && `page=${currentPage}`}`;
  navigate({
    pathname: window.location.pathname,
    search: composedQuery,
  });
};

const SOWsList: FC = observer(() => {
  const { user } = useUser();
  const query = useQueryParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { appendBreadcrumb, resetBreadcrumbs } = useBreadcrumbs();
  const pathParameters = useParams();
  const setQuery = setQueryParams.bind(null, navigate, query);
  const page = query.get('page') || 1;
  const clientId = (pathParameters && pathParameters.id) || undefined;
  const { sows, isLoading, isFetching } = useSOWs({
    enabled: true,
    params: { client: clientId, page },
  });

  let columns = [
    {
      title: 'Name',
      render: (text, sow) => (
        <div style={{ whiteSpace: 'nowrap' }}>{sow.name}</div>
      ),
      width: 200,
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      showSorterTooltip: false,
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      align: 'right',
      width: 30,
      sorter: (a: any, b: any) => a.rate - b.rate,
      showSorterTooltip: false,
      render: text => <span> $ {text}</span>,
    },
    {
      title: 'Start',
      align: 'center' as 'center',
      responsive: ['lg' as Breakpoint],
      render: (text: string, sow) => (
        <span>{dayjs(sow.start_date).format('l')}</span>
      ),
      width: 30,
      sorter: (a: any, b: any) => a.start_date - b.start_date,
      showSorterTooltip: false,
    },
    {
      title: 'End',
      align: 'center' as 'center',
      responsive: ['lg' as Breakpoint],
      render: (text: string, sow) => (
        <span>{dayjs(sow.end_date).format('l')}</span>
      ),
      width: 30,
      sorter: (a: any, b: any) => a.end_date - b.end_date,
      showSorterTooltip: false,
    },
    {
      width: 30,
      title: 'Programs',
      key: 'programs',
      align: 'center' as 'center',
      render: (text: string, sow) => {
        const goToPrograms = () => {
          const path = `/administration/programs/${sow.client_id}?sow_id=${sow.id}`;
          navigate(path);
          appendBreadcrumb({
            path,
            label: `${sow.name} Programs`,
          });
        };
        return (
          // TODO: this should be changed to a button, but needs design input
          <Space className='gc-table-actions text-actions'>
            <div
              role='button'
              tabIndex={0}
              onClick={goToPrograms}
              onKeyDown={e => handleKeyboardNav(e.key, goToPrograms)}
              className='text modal-open-click-target'
            >
              {sow.programs ? sow.programs.length : 0}
            </div>
          </Space>
        );
      },
    },
    {
      width: 30,
      title: 'Actions',
      key: 'actions',
      responsive: ['md' as Breakpoint],
      align: 'center' as 'center',
      render: (text: string, sow) => (
        <SOWEditorButton sow={sow} buttonType='icon' disabled={isFetching} />
      ),
    },
    {
      title: 'View',
      width: 1,
      key: 'view',
      align: 'center' as 'center',
      render: (text: string, sow) => (
        <IconButton
          onClick={() => {
            const activeClientId = localStorage.getItem(
              ActiveClientKey.sc_admin_active_client_id,
            );
            if (activeClientId !== sow.client_id) {
              localStorage.setItem(
                ActiveClientKey.sc_admin_active_client_id,
                sow.client_id,
              );
              queryClient.invalidateQueries();
            }
            navigate(`/my-business/${sow.client_id}`);
            resetBreadcrumbs([]);
          }}
        >
          <ChevronRightIcon fontSize='small' />
        </IconButton>
      ),
    },
  ];

  if (!clientId) {
    columns.splice(1, 0, {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      sorter: (a, b) => (a.clients[0].name > b.clients[0].name ? -1 : 1),
      render: (text, sow) =>
        clientId ? (
          <div>
            {sow.clients && sow.clients[0] ? sow.clients[0].name : 'Missing'}
          </div>
        ) : (
          <Tooltip
            title={`Filter by ${sow?.clients[0]?.name}`}
            enterDelay={500}
          >
            <Link
              variant='a2dash'
              onClick={() => navigate(`/administration/sows/${sow.client_id}`)}
            >
              {sow.clients && sow.clients[0] ? sow.clients[0].name : 'Missing'}
            </Link>
          </Tooltip>
        ),
    } as any);
  }
  return (
    <View>
      <ViewHeader
        title='SOWs / Plans'
        titleIcon={<ContentPasteIcon fontSize='large' />}
        actions={[
          {
            render: user.is_admin && clientId ? <SOWEditorButton /> : null,
          },
        ]}
      />
      <ViewBody>
        <ViewBodySection title='SOWs List'>
          <Card>
            <Table
              bordered
              loading={isLoading}
              pagination={false}
              dataSource={sows?.data}
              // @ts-expect-error
              columns={columns}
              locale={{ emptyText: 'No SOWs found' }}
              rowKey={'id'}
            />
            <Pagination
              style={{ marginTop: '2em' }}
              pageSize={PAGE_LIMIT}
              current={+page}
              total={sows?.totalRecords}
              onChange={val => setQuery('page', val)}
            />
          </Card>
        </ViewBodySection>
      </ViewBody>
    </View>
  );
});

export default SOWsList;
