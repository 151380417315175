import React, { useState, FC } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import Store from '@src/models/Store';
import {
  Card,
  Button,
  InputField,
  SCLogo,
  useToast,
} from '@src/components/sc-design-system';
import VerificationCodeInput, {
  VCOnChangeResIF,
} from '@src/components/forms/VerificationCodeInput/VerificationCodeInput';
import { useNavigate } from 'react-router-dom';
import PasswordReset from '@src/components/forms/PasswordReset/PasswordReset';
import { Link, Typography, Divider } from '@mui/material';

interface PRProps {
  store: Store;
}

const PasswordRecovery: FC<PRProps> = ({ store }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [authCode, setAuthCode] = useState('');
  const [password, setPassword] = useState('');
  const [authCodeValid, setAuthCodeValid] = useState(false);
  const [awaitingCode, setAwaitingCode] = useState(
    store.user.hasTempPasswordRecovery,
  );
  const [email, setEmail] = useState('');

  const restartPasswordRecoveryFlow = () => {
    setAwaitingCode(false);
  };

  const processSendRecoveryCode = async () => {
    try {
      await store.user.forgotPassword(email);
      setAwaitingCode(true);
      toast.info(t('Check email for authorization code'));
    } catch (e) {
      toast.error(t('No account found'));
    }
  };

  const processResetPassword = async () => {
    try {
      await store.user.forgotPasswordSubmit(authCode, password);
      toast.success(t('Change Password'));
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (e) {
      toast.error(t('Please contact support'));
    }
  };

  return (
    <Card>
      <div style={{ paddingLeft: '1em' }}>
        <SCLogo showText size={50} />
      </div>
      <Divider />
      {!authCode && !awaitingCode ? (
        <Typography variant='body1' color='grey.700'>
          Please enter the email you registered with to recover your password.
        </Typography>
      ) : (
        <Typography variant='body1' color='grey.700'>
          Please enter your verification code to continue.
        </Typography>
      )}
      <Divider />
      {!authCode && !awaitingCode && (
        <>
          <Form layout='vertical'>
            <InputField
              label={t('Email Address')}
              type='email'
              rules={[{ required: true, type: 'email' }]}
              value={email}
              onChange={setEmail}
            />
          </Form>
          <Button
            onClick={processSendRecoveryCode}
            disabled={email.length === 0}
            size='large'
            text={t('Continue')}
          />
        </>
      )}
      {awaitingCode && (
        <>
          <form className='ant-form ant-form-vertical'>
            <Typography variant='body2'>
              {t('Enter the recovery code sent to your email')}
            </Typography>
            <div className='ant-form-item-label'>
              <label
                className='ant-form-item-required'
                style={{ marginBottom: '.5em' }}
              >
                {t('Recovery Code')}
              </label>
              <div className='ant-form-item-control'>
                <VerificationCodeInput
                  onChange={(res: VCOnChangeResIF) => {
                    setAuthCode(res.value);
                    setAuthCodeValid(res.valid);
                  }}
                />
              </div>
            </div>
          </form>
          <Link variant='a1' onClick={restartPasswordRecoveryFlow}>
            {t('Resend Code')}
          </Link>
          <Divider />
          <PasswordReset
            isRecovery
            onChange={res => {
              setPassword(res.valid ? res.newPassword : '');
            }}
          />
          <Button
            disabled={!authCodeValid || !password.length}
            onClick={processResetPassword}
            text={t('Continue')}
          />
        </>
      )}
      <Divider />
      <Link variant='a1' href='/' onClick={() => localStorage.clear()}>
        {t('Cancel')}
      </Link>
    </Card>
  );
};

export default PasswordRecovery;
