import React, { FC, useEffect } from 'react';
import {
  DatePicker,
  Select,
  TextField,
} from '@src/components/sc-design-system';
import Program from '@src/models/Program';
import {
  Grid,
  Typography,
  Switch,
  Stack,
  Divider,
  InputAdornment,
  FormControlLabel,
} from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import dayjs from 'dayjs';

interface AttributesTabProps {
  availablePrograms: Program[];
  form: UseFormReturn<SOWFormDefaultValues, undefined>;
  isNewSOW: boolean;
}

const AttributesTab: FC<AttributesTabProps> = ({
  isNewSOW,
  availablePrograms,
  form,
}) => {
  const {
    control,
    formState: { errors, touchedFields },
    trigger,
  } = form;

  const sowProgramsSessionsReserved = availablePrograms.reduce((acc, p) => {
    return acc + p.sessions_reserved;
  }, 0);
  const latestProgramEndDate = availablePrograms.reduce((acc, p) => {
    return p.end_date > acc ? p.end_date : acc;
  }, 0);

  function handleCurrencyInput(val) {
    let value = val.replace(/\D/g, ''); // Remove all non-numeric characters

    if (value.length === 0) {
      value = 0;
    }

    value = parseInt(value, 10);

    const dollars = Math.floor(value / 100);
    const cents = value % 100;

    // Format the input display value as a number with decimal places
    const formattedCents = cents < 10 ? '0' + (cents || '0') : cents;
    const formattedValue = `${dollars}.${formattedCents}`;

    return formattedValue[formattedValue.length - 1] === '0'
      ? formattedValue
      : parseFloat(formattedValue);
  }

  useEffect(() => {
    trigger();
  }, [trigger]);

  const options: { label: string; value: keyof SOWOptions }[] = [
    {
      label: 'Client can set up programs with custom coaches',
      value: 'custom_coach_selection',
    },
    { label: 'Client can set up mentoring programs', value: 'mentoring' },
  ];

  const getErrorMessage = (field: keyof SOWFormDefaultValues) => {
    return (
      (isNewSOW ? touchedFields[field] : true) &&
      typeof errors?.[field]?.message === 'string' &&
      errors?.[field]?.message
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid container item spacing={2}>
          <Grid item xs={8}>
            <Controller
              name='name'
              control={control}
              rules={{ required: 'SOW name is required' }}
              render={({ field }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <TextField
                    {...fieldProps}
                    label='SOW Name'
                    required
                    placeholder='SOW Name'
                    id='name'
                    innerRef={ref}
                    error={Boolean(getErrorMessage('name'))}
                    errorMessage={getErrorMessage('name')}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name='is_active'
              control={control}
              rules={{ required: 'Status is required' }}
              render={({ field }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <Select
                    {...fieldProps}
                    name='is_active'
                    label='Status'
                    options={[
                      {
                        options: [
                          { label: 'Active', value: 'true' },
                          { label: 'Inactive', value: 'false' },
                        ],
                      },
                    ]}
                    fullWidth
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <Controller
              name='start_date'
              control={control}
              rules={{
                required: 'Start Date is required',
                validate: {
                  start: (v: Dayjs, context) => {
                    if (!v.isValid()) {
                      return 'Please enter a valid date';
                    }
                    if (
                      context.end_date?.isValid() &&
                      v.isAfter(context.end_date)
                    ) {
                      return 'Start date must be before end date';
                    }
                    return true;
                  },
                },
              }}
              render={({ field }) => {
                return (
                  <DatePicker
                    {...field}
                    label='Start Date'
                    fullWidth
                    innerRef={field.ref}
                    error={Boolean(getErrorMessage('start_date'))}
                    errorMessage={getErrorMessage('start_date')}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name='end_date'
              control={control}
              rules={{
                required: 'End date is required',
                validate: {
                  start: (v: Dayjs, context) => {
                    if (!v.isValid()) {
                      return 'Please enter a valid date';
                    }
                    if (
                      context.start_date?.isValid() &&
                      v.isBefore(context.start_date)
                    ) {
                      return 'End date must be after start date';
                    }
                    return true;
                  },
                  latestProgramEndDate: v => {
                    if (
                      dayjs(latestProgramEndDate).startOf('date') >
                      dayjs(v).startOf('date')
                    ) {
                      return `End date cannot be earlier than ${dayjs(
                        latestProgramEndDate,
                      ).format('MM/DD/YYYY')}`;
                    }
                    return true;
                  },
                },
              }}
              render={({ field }) => {
                return (
                  <DatePicker
                    {...field}
                    label='End date'
                    fullWidth
                    innerRef={field.ref}
                    error={Boolean(getErrorMessage('end_date'))}
                    errorMessage={getErrorMessage('end_date')}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <Controller
              name='default_program_id'
              control={control}
              disabled={!availablePrograms || availablePrograms.length === 0}
              render={({ field }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <Select
                    {...fieldProps}
                    label='Default Program'
                    options={[
                      {
                        options:
                          !availablePrograms || availablePrograms.length === 0
                            ? []
                            : availablePrograms.map((p: Program) => ({
                                value: p.id,
                                label: p.name,
                              })),
                      },
                    ]}
                    fullWidth
                    disabled={
                      !availablePrograms || availablePrograms.length === 0
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name='rate'
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    label='Rate per 60m'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                    value={field.value}
                    id='rate'
                    onChange={e => {
                      const parsed = handleCurrencyInput(e.target.value);
                      field.onChange(parsed);
                    }}
                    placeholder=''
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name='coach_rate'
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    label='Coach rate'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                    id='coach_rate'
                    onChange={e => {
                      const parsed = handleCurrencyInput(e.target.value);
                      field.onChange(parsed);
                    }}
                    placeholder=''
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={4}>
            <Controller
              name='sessions_reserved'
              control={control}
              rules={{
                required: 'Sessions reserved is required',
                validate: {
                  minimum: v =>
                    v >= sowProgramsSessionsReserved ||
                    `Minimum number of SOW sessions must be at least ${sowProgramsSessionsReserved}`,
                },
              }}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    label='Sessions reserved'
                    id='sessions_reserved'
                    onChange={e => {
                      const parsed = parseInt(e.target.value);
                      if (!isNaN(parsed)) {
                        field.onChange(parsed);
                      } else if (e.target.value === '') {
                        field.onChange(0);
                      }
                    }}
                    placeholder='Sessions reserved'
                    error={Boolean(getErrorMessage('sessions_reserved'))}
                    errorMessage={getErrorMessage('sessions_reserved')}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name='seats_reserved'
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    label='Seats Reserved'
                    id='seats_reserved'
                    onChange={e => {
                      const parsed = parseInt(e.target.value);
                      if (!isNaN(parsed)) {
                        field.onChange(parsed);
                      } else if (e.target.value === '') {
                        field.onChange(0);
                      }
                    }}
                    placeholder='Seats Reserved'
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name='allowed_durations'
              control={control}
              rules={{
                required: 'Allowed durations is required',
                validate: {
                  minLength: v =>
                    v.length > 0 || 'Allowed durations is required',
                },
              }}
              render={({ field }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <Select
                    {...fieldProps}
                    label='Allowed Durations'
                    multiple
                    renderValue={(value: number[]) =>
                      value?.map(v => `${v}m`)?.join(', ')
                    }
                    options={[
                      {
                        options: [
                          { label: '30m', value: 30 },
                          { label: '60m', value: 60 },
                        ],
                      },
                    ]}
                    fullWidth
                    error={Boolean(getErrorMessage('allowed_durations'))}
                    errorMessage={getErrorMessage('allowed_durations')}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Controller
            name='salesforce_opp_id'
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  label='Salesforce Opp ID'
                  id='salesforce_opp_id'
                  placeholder='Salesforce Opp ID'
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <Stack>
        <Typography component='h3' variant='h4' sx={{ marginBottom: '1em' }}>
          Options
        </Typography>
        {options.map(opt => (
          <FormControlLabel
            key={`sow-option-${opt.value}`}
            control={
              <Controller
                name={`options.${opt.value}`}
                control={control}
                render={({ field }) => {
                  return <Switch {...field} checked={field.value} />;
                }}
              />
            }
            label={opt.label}
            sx={{ margin: 0, width: 'fit-content' }}
          />
        ))}
      </Stack>
      <Divider />
      <Controller
        name='description'
        control={control}
        render={({ field }) => {
          return (
            <TextField
              {...field}
              innerRef={field.ref}
              multiline
              rows={4}
              id='description'
              label='Description'
              placeholder='Scope of work, Special Requirements, Goals, etc...'
            />
          );
        }}
      />
    </>
  );
};

export { AttributesTab };
