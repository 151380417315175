import React, { FC, useMemo } from 'react';
import { Alert, Typography } from '@mui/material';
import { useUser } from '@src/hooks/useUser';
import { ActiveClientKey, useActiveClient } from '@src/hooks/useActiveClient';
import { Button } from '@src/components/sc-design-system';

interface PromptUpdateAlertProps {
  conversations: Conversations;
  onClickUpdate: Function;
}

const PromptUpdateAlert: FC<PromptUpdateAlertProps> = ({
  conversations,
  onClickUpdate,
}) => {
  const { client } = useActiveClient({
    queryKey: ActiveClientKey.profile_active_client_id,
  });
  const { useGetUserAidaTasks } = useUser();
  const { mostRecentUpdate, isLoading } = useGetUserAidaTasks(client?.id);

  const latestSummaryConversation = conversations?.summary?.[0];

  const updateAvailable = useMemo(() => {
    if (isLoading || !latestSummaryConversation) return false;
    return mostRecentUpdate > latestSummaryConversation?.timestamp;
  }, [mostRecentUpdate, latestSummaryConversation, isLoading]);

  if (!updateAvailable) {
    return null;
  }

  return (
    <Alert
      severity='info'
      action={
        <Button
          variant='text'
          text='Update results'
          size='small'
          shape='rounded-square'
          onClick={() => onClickUpdate()}
        />
      }
    >
      <Typography variant='body2'>
        We have new information that can provide new guidance for you. Would you
        like to refresh and see?
      </Typography>
    </Alert>
  );
};

export { PromptUpdateAlert };
