import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';

obey.creator('uuid', () => uuidv4());

export const ClientCoachingInterestsSchema = {
  id: { type: 'string', required: true, creator: 'uuid' },
  clients: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        client_id: { type: 'string', allowNull: true },
        total_members: { type: 'number' },
        skills: {
          type: 'array',
          values: {
            type: 'object',
            keys: {
              title: { type: 'string' },
              count: { type: 'number' },
            },
          },
        },
      },
    },
  },
};

// eslint-disable-next-line no-unused-vars
export type ClientCoachingInterestsType = {
  [K in keyof typeof ClientCoachingInterestsSchema]?: any;
};

export const ClientCoachingInterestsModel = obey.model(
  ClientCoachingInterestsSchema,
);
