import { message } from 'antd';
import { action, makeAutoObservable, toJS } from 'mobx';
import { survey as SurveyQuestionSchema } from '@shared/schemas';
import { api } from '@src/lib/client';
import { nanoid } from 'nanoid';

export interface Option {
  id: string;
  value: any;
  text: string;
  index_position: number;
}

export const questionTypes = [
  'message',
  'text',
  'single-select',
  'multi-select',
  'range',
  'competency',
];

export interface RangeLabel {
  value: number;
  text: string;
}

export const surveyQuestionProperties = [
  'id',
  'client_id',
  'name',
  'title',
  'helpText',
  'privacyText',
  'type',
  'isNumberInput',
  'options',
  'allow_other',
  'other_answer_required',
  'allow_none',
  'required',
  'range_maximum',
  'range_labels',
  'inQuestionBank',
  'group',
  'meta_data',
];

export default class SurveyQuestion {
  // Provides template for question editor form
  id: string;
  client_id: string;
  name: string;
  title: string;
  helpText: string;
  privacyText: string;
  type: string;
  required: boolean;
  isNumberInput: boolean;
  options: Option[];
  allow_other: boolean;
  other_answer_required: boolean;
  allow_none: boolean;
  range_maximum: number;
  range_labels: RangeLabel[];
  inQuestionBank: boolean;
  group: any;
  condition: any[];
  meta_data?: {
    competency_set_id: string;
    competency_id: string;
    statement_id: string;
  };

  constructor(surveyQuestion: SurveyQuestionSchema.SurveyQuestionType = {}) {
    makeAutoObservable(this, {
      addOption: action,
      deleteOption: action,
    });
    this.mergeData(surveyQuestion);
  }

  mergeData = (surveyQuestion: SurveyQuestionSchema.SurveyQuestionType) => {
    surveyQuestionProperties.forEach(prop => {
      this[prop] = surveyQuestion[prop];
    });
  };

  get data() {
    return JSON.parse(
      JSON.stringify(
        surveyQuestionProperties.reduce((acc, prop) => {
          acc[prop] = toJS(this[prop]);
          return acc;
        }, {}),
      ),
    );
  }

  addOption = () => {
    this.options.push({
      id: nanoid(),
      index_position: this.options.length,
      value: '',
      text: '',
    });
  };

  deleteOption = id => {
    this.options = this.options.filter(o => o.id !== id);
  };

  save = async (inQuestionBank = false) => {
    try {
      const data = { ...this.data };
      if (inQuestionBank) {
        delete data.id;
        data.inQuestionBank = false;
      }
      const res = await api.surveys.saveSurveyQuestion(data);
      this.mergeData(res.data); // Update local
    } catch (e) {
      message.error('Could not save survey question');
      throw e;
    }
  };
}
