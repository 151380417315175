import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card, EChart } from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import { Typography } from '@mui/material';

const cardStyle = { borderColor: 'grey.400' };

interface CompetenciesProps {
  clientId: string;
  performanceCycleId: string;
  userId: string;
}

const Competencies: FC<CompetenciesProps> = ({
  clientId,
  performanceCycleId,
  userId,
}) => {
  const { data, isLoading } = useQuery({
    queryKey: [
      'client',
      clientId,
      'performance-cycle',
      performanceCycleId,
      'evaluation-competencies',
      userId,
    ],
    queryFn: async () => {
      return api.clients.getAggregateEmployeeCompetencyResults(
        clientId,
        performanceCycleId,
        userId,
      );
    },
    select: ({ data }) => data,
  });

  return (
    <Card
      title='Competencies and values'
      sx={{
        ...cardStyle,
        '&.MuiPaper-outlined': cardStyle,
        '.MuiCardHeader-root': cardStyle,
      }}
    >
      {!isLoading && data?.length > 0 ? (
        data?.map(
          (result, index) =>
            result.options && (
              <React.Fragment key={index}>
                {result.title && (
                  <Typography variant='h5'>{result.title}</Typography>
                )}
                <EChart
                  props={{
                    width: '100%',
                    options: result.options,
                    chartType: result.chartType,
                  }}
                />
              </React.Fragment>
            ),
        )
      ) : (
        <Typography variant='body1m' color='grey.700'>
          Evaluation of competencies and values have not been completed yet.
        </Typography>
      )}
    </Card>
  );
};

export { Competencies };
