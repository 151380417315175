import React, { FC } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { DialogTitle, Button } from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import FileUpload from './FileUpload';
import { FileError } from 'react-dropzone';

interface FileUploadModalProps {
  open: boolean;
  onClose: Function;
  onSuccess: Function;
  onFileDelete?: Function;
  accept?: any;
  maxSize?: any;
  customValidator?: {
    validatorFunction: <T extends File>(file: T) => FileError | FileError[];
    errorCode: string;
    errorMessage: string;
  };
}

const FileUploadModal: FC<FileUploadModalProps> = ({
  open,
  onClose,
  onSuccess,
  onFileDelete,
  accept,
  maxSize,
  customValidator,
}) => {
  const handleClose = () => onClose();

  const { mutate: deleteFile } = useMutation({
    mutationFn: async (key: string) => {
      return api.files.deleteFile(key);
    },
  });

  const handleDelete = (key: string) => {
    deleteFile(key);
    onFileDelete && onFileDelete(key);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm'>
      <DialogTitle title='Upload files' buttonOnClick={handleClose} />
      <DialogContent>
        <FileUpload
          onSuccess={onSuccess}
          onFileDelete={handleDelete}
          accept={accept}
          maxSize={maxSize}
          customValidator={customValidator}
        />
      </DialogContent>
      <DialogActions>
        <Button text='Close' onClick={handleClose} />
      </DialogActions>
    </Dialog>
  );
};

export { FileUploadModal };
