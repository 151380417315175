import React from 'react';
import {
  createTheme,
  ThemeOptions,
  PaletteOptions,
} from '@mui/material/styles';
import { COLOR } from '@src/components/sc-design-system/tokens';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { IconButton } from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';

const body1 = {
  fontSize: '16px',
  lineHeight: '150%',
  fontWeight: 400,
  letterSpacing: '0.04em',
};
const body2 = {
  fontSize: '14px',
  lineHeight: '150%',
  fontWeight: 400,
  letterSpacing: '0.04em',
};
const body2sb = {
  ...body2,
  fontWeight: 600,
};
const body2m = {
  ...body2,
  fontWeight: 500,
};
const body3 = {
  fontSize: '12px',
  lineHeight: '150%',
  fontWeight: 400,
  letterSpacing: '0.04em',
};
const body3m = {
  ...body3,
  fontWeight: 500,
};
const a1 = {
  fontSize: '16px',
  lineHeight: '150%',
  fontWeight: 500,
  letterSpacing: '0.04em',
  '&:hover': {
    // once antd is removed, we can use Link color prop without issue, filter will allow us to darken the selected color on hover
    // filter: 'brightness(85%)',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};
const a2 = {
  fontSize: '14px',
  lineHeight: '150%',
  fontWeight: 500,
  letterSpacing: '0.04em',
  '&:hover': {
    // filter: 'brightness(85%)',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};

const palette: PaletteOptions = {
  primary: {
    main: COLOR.PRIMARY[500],
    light: COLOR.PRIMARY[300],
    dark: COLOR.PRIMARY[700],
    contrastText: COLOR.WHITE,
    ...COLOR.PRIMARY,
  },
  secondary: {
    main: COLOR.SECONDARY[500],
    light: COLOR.SECONDARY[300],
    dark: COLOR.SECONDARY[700],
    contrastText: COLOR.NEUTRAL[900],
    ...COLOR.SECONDARY,
  },
  tertiary: {
    main: COLOR.TERTIARY[500],
    light: COLOR.TERTIARY[300],
    dark: COLOR.TERTIARY[700],
    contrastText: COLOR.NEUTRAL[900],
    ...COLOR.TERTIARY,
  },
  grey: {
    ...COLOR.NEUTRAL,
    A100: COLOR.NEUTRAL[100],
    A200: COLOR.NEUTRAL[200],
    A400: COLOR.NEUTRAL[400],
    A700: COLOR.NEUTRAL[700],
  },
  error: {
    main: COLOR.ACCENTS.RED[500],
    light: COLOR.ACCENTS.RED[300],
    dark: COLOR.ACCENTS.RED[700],
    contrastText: COLOR.NEUTRAL[900],
    ...COLOR.ACCENTS.RED,
  },
  warning: {
    main: COLOR.ACCENTS.YELLOW[500],
    light: COLOR.ACCENTS.YELLOW[300],
    dark: COLOR.ACCENTS.YELLOW[700],
    contrastText: COLOR.NEUTRAL[900],
    ...COLOR.ACCENTS.YELLOW,
  },
  success: {
    main: COLOR.ACCENTS.GREEN[500],
    light: COLOR.ACCENTS.GREEN[300],
    dark: COLOR.ACCENTS.GREEN[700],
    contrastText: COLOR.WHITE,
    ...COLOR.ACCENTS.GREEN,
  },
  info: {
    main: COLOR.ACCENTS.BLUE[500],
    light: COLOR.ACCENTS.BLUE[300],
    dark: COLOR.ACCENTS.BLUE[700],
    contrastText: COLOR.WHITE,
    ...COLOR.ACCENTS.BLUE,
  },
  background: { paper: COLOR.WHITE, default: COLOR.WHITE },
  text: {
    primary: COLOR.BLACK,
  },
  black: {
    main: COLOR.BLACK,
  },
  white: {
    main: COLOR.WHITE,
  },
  aida: {
    main: COLOR.ACCENTS.PURPLE,
  },
};

const themeOptions: ThemeOptions = {
  palette,
  typography: {
    fontFamily: 'Urbanist, Noto Sans',
    fontSize: 16,
    fontWeightRegular: 300,
    fontWeightLight: 200,
    fontWeightMedium: 400,
    h1: {
      fontSize: '32px',
      lineHeight: '130%',
      fontWeight: 300,
      letterSpacing: '0.02em',
    },
    h2: {
      fontSize: '24px',
      lineHeight: '140%',
      fontWeight: 500,
      letterSpacing: '0.02em',
    },
    h3: {
      fontSize: '21px',
      lineHeight: '140%',
      fontWeight: 500,
      letterSpacing: '0.02em',
    },
    h4: {
      fontSize: '18px',
      lineHeight: '150%',
      fontWeight: 500,
      letterSpacing: '0.03em',
    },
    h5: {
      fontSize: '16px',
      lineHeight: '150%',
      fontWeight: 600,
      letterSpacing: '0.02em',
    },
    body1,
    body1m: {
      ...body1,
      fontWeight: 500,
    },
    body1sb: {
      ...body1,
      fontWeight: 600,
    },
    body1b: {
      ...body1,
      fontWeight: 700,
    },
    body2,
    body2m,
    body2sb,
    body2b: {
      ...body2,
      fontWeight: 700,
    },
    body3,
    body3m,
    body3sb: {
      ...body3,
      fontWeight: 600,
    },
    body3b: {
      ...body3,
      fontWeight: 700,
    },
    subtitle1: body1,
    subtitle1m: {
      ...body1,
      fontWeight: 500,
    },
    subtitle2: body2,
    subtitle2m: {
      ...body2,
      fontWeight: 500,
    },
    caption: {
      fontSize: '12px',
      lineHeight: '150%',
      fontWeight: 500,
      letterSpacing: '0.08em',
    },
    a1,
    a1dash: {
      ...a1,
      fontWeight: 400,
      textDecoration: `underline dashed ${COLOR.NEUTRAL[500]} !important`,
      color: `${COLOR.BLACK} !important`,
      textUnderlineOffset: '4px',
      '&:hover': {
        ...a1['&:hover'],
        textDecoration: `underline dashed ${COLOR.BLACK} !important`,
        color: `${COLOR.BLACK} !important`,
      },
    },
    a1blank: {
      ...a1,
      fontWeight: 400,
      textDecoration: `unset !important`,
      color: `${COLOR.BLACK} !important`,
      '&:hover': {
        ...a1['&:hover'],
        textDecoration: `underline solid ${COLOR.BLACK} !important`,
        color: `${COLOR.BLACK} !important`,
      },
    },
    a2,
    a2dash: {
      ...a2,
      fontWeight: 400,
      textDecoration: `underline dashed ${COLOR.NEUTRAL[500]} !important`,
      color: `${COLOR.BLACK} !important`,
      textUnderlineOffset: '4px',
      '&:hover': {
        ...a2['&:hover'],
        textDecoration: `underline dashed ${COLOR.BLACK} !important`,
        color: COLOR.BLACK + '!important',
      },
    },
    a2blank: {
      ...a2,
      fontWeight: 400,
      textDecoration: `unset !important`,
      color: `${COLOR.BLACK} !important`,
      '&:hover': {
        ...a2['&:hover'],
        textDecoration: `underline solid ${COLOR.BLACK} !important`,
        color: `${COLOR.BLACK} !important`,
      },
    },
    overline: {
      fontSize: '14px',
      lineHeight: '150%',
      fontWeight: 700,
      letterSpacing: '0.07em',
      textDecoration: 'uppercase',
    },
    a1white: {
      ...a1,
      color: `${COLOR.WHITE} !important`,
      textDecoration: `underline solid ${COLOR.WHITE} !important`,
      '&:hover': {
        ...a1['&:hover'],
        textDecoration: `underline solid ${COLOR.NEUTRAL[300]} !important`,
        color: `${COLOR.NEUTRAL[300]} !important`,
      },
    },
    a2white: {
      ...a2,
      color: `${COLOR.WHITE} !important`,
      textDecoration: `underline solid ${COLOR.WHITE} !important`,
      '&:hover': {
        ...a2['&:hover'],
        textDecoration: `underline solid ${COLOR.NEUTRAL[300]} !important`,
        color: `${COLOR.NEUTRAL[300]} !important`,
      },
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1m: 'p',
          body1sb: 'p',
          body1b: 'p',
          body2m: 'p',
          body2sb: 'p',
          body2b: 'p',
          body3: 'p',
          body3m: 'p',
          body3sb: 'p',
          body3b: 'p',
          a1: 'a',
          a1dash: 'a',
          a1blank: 'a',
          a2: 'a',
          a2dash: 'a',
          a2blank: 'a',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        variant: 'contained',
        size: 'large',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          lineHeight: 'normal',
          fontSize: 14,
          fontWeight: 500,
          '&.shape-pill': {
            borderRadius: '28px',
          },
          '&.shape-rounded-square': {
            borderRadius: '8px',
          },
          '&.Mui-disabled': {
            backgroundColor: COLOR.NEUTRAL[400] + ' !important',
            color: COLOR.NEUTRAL[800],
          },
          '&.MuiButton-containedSizeCompact': {
            '&.MuiButton-firstButton': {
              padding: '0 5px',
            },
            '&.MuiButtonGroup-grouped': {
              minWidth: 'fit-content',
            },
          },
        },
        sizeSmall: {
          padding: '7px 20px',
          minHeight: '32px',
        },
        sizeLarge: {
          padding: '11px 20px',
          minHeight: '44px',
        },
        contained: ({ ownerState }) => {
          if (ownerState.color === 'error') {
            return {
              boxShadow: 'none',
              color: palette.white.main,
              backgroundColor: palette[ownerState.color][600],
              '&:focus': {
                backgroundColor: palette[ownerState.color][700],
                boxShadow: 'none',
              },
              '&:hover': {
                backgroundColor: palette[ownerState.color][700],
                boxShadow: 'none',
              },
              '&:active': {
                backgroundColor: palette[ownerState.color][800],
                boxShadow: 'none',
              },
            };
          }
          if (ownerState.color === 'grey') {
            return {
              boxShadow: 'none',
              color: palette.grey[700],
              '& .MuiCheckbox-root svg': { fill: palette.grey[500] },
              backgroundColor: palette.grey[50],
              '&.MuiButtonGroup-firstButton': { borderRight: 'unset' },
              '&:focus': {
                backgroundColor: palette.grey[200],
                boxShadow: 'none',
              },
              '&:hover': {
                backgroundColor: palette.grey[200],
                boxShadow: 'none',
              },
              '&:active': {
                backgroundColor: palette.grey[300],
                boxShadow: 'none',
              },
              '&:disabled': {
                '&.MuiButtonGroup-firstButton': { borderRight: 'unset' },
                color: palette.grey[700],
                boxShadow: 'none',
              },
            };
          }
          return {
            boxShadow: 'none',
            color: palette[ownerState.color].contrastText,
            backgroundColor: palette[ownerState.color][500],
            '&:focus': {
              backgroundColor: palette[ownerState.color][600],
              boxShadow: 'none',
            },
            '&:hover': {
              backgroundColor: palette[ownerState.color][600],
              boxShadow: 'none',
            },
            '&:active': {
              backgroundColor: palette[ownerState.color][700],
              boxShadow: 'none',
            },
          };
        },
        outlined: ({ ownerState }) => {
          const color =
            ownerState.color === 'error'
              ? palette[ownerState.color][600]
              : palette[ownerState.color][500];
          return {
            color,
            borderColor: color,
            backgroundColor: COLOR.WHITE,
            '&.Mui-disabled': {
              borderColor: COLOR.NEUTRAL[300],
            },
            '&:focus': {
              color,
              borderColor: color,
              backgroundColor: palette[ownerState.color][100],
            },
            '&:hover': {
              color,
              borderColor: color,
              backgroundColor: palette[ownerState.color][100],
            },
            '&:active': {
              color:
                ownerState.color === 'error'
                  ? palette.white.main
                  : palette[ownerState.color].contrastText,
              borderColor: color,
              backgroundColor: palette[ownerState.color][500],
            },
          };
        },
        text: ({ ownerState }) => ({
          '&.Mui-disabled': {
            backgroundColor: 'unset',
            color: COLOR.NEUTRAL[700],
          },
          color: palette[ownerState.color][500],
          '&:focus': {
            color: palette[ownerState.color][500],
            backgroundColor: palette[ownerState.color][100],
          },
          '&:hover': {
            color: palette[ownerState.color][500],
            backgroundColor: palette[ownerState.color][100],
          },
          '&:active': {
            color: palette[ownerState.color].contrastText,
            backgroundColor: palette[ownerState.color][600],
          },
        }),
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
        color: 'grey',
      },
      variants: [
        {
          props: { color: 'grey' },
          style: {
            color: COLOR.BLACK,
            '&:hover': {
              backgroundColor: COLOR.NEUTRAL[200],
            },
            '&:focus-visible': {
              backgroundColor: COLOR.NEUTRAL[400],
            },
            '&:active': {
              backgroundColor: COLOR.NEUTRAL[400],
            },
            '&.Mui-disabled': {
              color: COLOR.NEUTRAL[500],
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'grey' },
          style: {
            border: `1px solid ${COLOR.NEUTRAL[500]}`,
            '&:hover': {
              borderColor: COLOR.NEUTRAL[200],
              border: `1px solid ${COLOR.NEUTRAL[500]}`,
            },
            '&:focus-visible': {
              borderColor: COLOR.NEUTRAL[400],
              border: `1px solid ${COLOR.NEUTRAL[500]}`,
            },
            '&:active': {
              borderColor: COLOR.NEUTRAL[400],
              border: `1px solid ${COLOR.NEUTRAL[500]}`,
            },
            '&.Mui-disabled': {
              color: COLOR.NEUTRAL[500],
              border: `1px solid ${COLOR.NEUTRAL[200]}`,
            },
          },
        },
        {
          props: { variant: 'over-grey', color: 'primary' },
          style: {
            color: COLOR.BLACK,
            '&:hover': {
              backgroundColor: COLOR.PRIMARY[200],
            },
            '&:focus-visible': {
              backgroundColor: COLOR.PRIMARY[400],
            },
            '&:active': {
              backgroundColor: COLOR.PRIMARY[400],
            },
            '&.Mui-disabled': {
              color: COLOR.NEUTRAL[400],
            },
          },
        },
        {
          props: { variant: 'over-primary', color: 'grey' },
          style: {
            color: COLOR.WHITE,
            '&:hover': {
              color: COLOR.BLACK,
              backgroundColor: COLOR.NEUTRAL[200],
            },
            '&:focus-visible': {
              color: COLOR.BLACK,
              backgroundColor: COLOR.NEUTRAL[400],
            },
            '&:active': {
              color: COLOR.BLACK,
              backgroundColor: COLOR.NEUTRAL[400],
            },
            '&.Mui-disabled': {
              color: COLOR.NEUTRAL[400],
            },
          },
        },
        {
          props: { color: 'primary' },
          style: {
            color: COLOR.PRIMARY[500],
            '&:hover': {
              backgroundColor: COLOR.PRIMARY[100],
            },
            '&:focus-visible': {
              color: COLOR.WHITE,
              backgroundColor: COLOR.PRIMARY[500],
            },
            '&:active': {
              color: COLOR.WHITE,
              backgroundColor: COLOR.PRIMARY[500],
            },
            '&.Mui-disabled': {
              color: COLOR.NEUTRAL[500],
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            color: COLOR.PRIMARY[500],
            border: `1px solid ${COLOR.PRIMARY[500]}`,
            '&:hover': {
              backgroundColor: COLOR.PRIMARY[100],
              border: `1px solid ${COLOR.PRIMARY[500]}`,
            },
            '&:focus-visible': {
              color: COLOR.WHITE,
              backgroundColor: COLOR.PRIMARY[500],
            },
            '&:active': {
              color: COLOR.WHITE,
              backgroundColor: COLOR.PRIMARY[500],
            },
            '&.Mui-disabled': {
              border: `1px solid ${COLOR.NEUTRAL[200]}`,
              color: COLOR.NEUTRAL[500],
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            color: COLOR.ACCENTS.RED[500],
            '&:hover': {
              backgroundColor: COLOR.ACCENTS.RED[100],
            },
            '&:focus-visible': {
              color: COLOR.WHITE,
              backgroundColor: COLOR.ACCENTS.RED[500],
            },
            '&:active': {
              color: COLOR.WHITE,
              backgroundColor: COLOR.ACCENTS.RED[500],
            },
            '&.Mui-disabled': {
              color: COLOR.NEUTRAL[500],
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            color: COLOR.ACCENTS.RED[500],
            border: `1px solid ${COLOR.ACCENTS.RED[500]}`,
            '&:hover': {
              backgroundColor: COLOR.ACCENTS.RED[100],
              border: `1px solid ${COLOR.ACCENTS.RED[500]}`,
            },
            '&:focus-visible': {
              color: COLOR.WHITE,
              backgroundColor: COLOR.ACCENTS.RED[500],
            },
            '&:active': {
              color: COLOR.WHITE,
              backgroundColor: COLOR.ACCENTS.RED[500],
            },
            '&.Mui-disabled': {
              border: `1px solid ${COLOR.NEUTRAL[200]}`,
              color: COLOR.NEUTRAL[500],
            },
          },
        },
      ],
    },
    MuiPagination: {
      defaultProps: {
        variant: 'outlined',
        shape: 'rounded',
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          border: `1px solid ${COLOR.NEUTRAL[200]}`,
          borderRadius: '6px',
          borderCollapse: 'separate',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          'tr:first-of-type': {
            'th:first-of-type': { borderTopLeftRadius: '6px' },
            'th:last-of-type': { borderTopRightRadius: '6px' },
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          'tr:last-of-type': {
            'td:first-of-type': { borderBottomLeftRadius: '6px' },
            'td:last-of-type': { borderBottomRightRadius: '6px' },
            td: { borderBottom: 0 },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: palette.white.main,
        },
        head: {
          backgroundColor: palette.grey[50],
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          ...body2,
          padding: '20px 16px',
        },
        head: {
          ...body2m,
          color: palette.black.main,
          padding: '20px 16px',
        },
      },
    },
    MuiChip: {
      defaultProps: { size: 'small' },
      styleOverrides: {
        root: ({ ownerState }) => {
          const props =
            ownerState.color === 'default' && ownerState.variant === 'filled'
              ? { backgroundColor: COLOR.NEUTRAL[500], color: COLOR.BLACK }
              : ownerState.color === 'default' &&
                ownerState.variant === 'outlined'
              ? {
                  borderColor: palette.grey[700],
                  color: palette.grey[700],
                  backgroundColor: palette.grey[100],
                }
              : {};

          return {
            borderColor: palette.grey[200],
            svg: { fill: props.color },
            ...props,
          };
        },
        sizeSmall: {
          '&.shape-rounded-square': { borderRadius: '4px' },
        },
        sizeMedium: {
          '&.shape-rounded-square': { borderRadius: '8px' },
        },
        icon: { fontSize: '13px', marginLeft: '8px', marginRight: '-2px' },
        label: { ...body3 },
        outlined: ({ ownerState }) => {
          const color =
            ownerState.color === 'default' ? 'grey' : ownerState.color;
          const borderColor =
            ownerState.color === 'primary'
              ? 500
              : ownerState.color === 'warning'
              ? 800
              : 600;

          return {
            borderColor: palette[color][borderColor],
            backgroundColor: palette[color][100],
            '.MuiChip-label': { color: palette[color][borderColor] },
            svg: { fill: palette[color][borderColor] },
          };
        },
      },
      variants: [
        {
          props: { 'custom-color': 'black' },
          style: {
            backgroundColor: COLOR.BLACK,
            color: COLOR.WHITE,
            svg: { fill: COLOR.WHITE },
          },
        },
        {
          props: { 'custom-color': 'black', variant: 'outlined' },
          style: {
            borderColor: COLOR.BLACK,
            color: COLOR.BLACK,
            backgroundColor: COLOR.WHITE,
            svg: { fill: COLOR.BLACK },
          },
        },
      ],
    },
    MuiDivider: {
      defaultProps: {
        variant: 'middle',
      },
      styleOverrides: {
        root: {
          margin: '2em 0',
          borderColor: COLOR.NEUTRAL[200],
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: COLOR.WHITE,
          color: COLOR.BLACK,
          fontSize: 14,
          border: `1px solid ${COLOR.NEUTRAL[200]}`,
          padding: 12,
        },
        arrow: {
          '&:before': {
            border: `1px solid ${COLOR.NEUTRAL[200]}`,
          },
          color: COLOR.WHITE,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow:
            '0 1px 3px 0 rgba(26,26,46,0.1), 0 0 0px rgba(26,26,46,0.1)',
          border: `1px solid ${COLOR.NEUTRAL[200]}`,
          borderRadius: '6px',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          borderRadius: '0.6em',
          '&.MuiPaper-outlined': {
            border: `1px solid ${COLOR.NEUTRAL[200]}`,
          },
          '&.MuiPaper-elevation': {
            boxShadow:
              '0 1px 3px 0 rgba(26,26,46,0.1), 0 0 0px rgba(26,26,46,0.1)',
            border: `1px solid ${COLOR.NEUTRAL[200]}`,
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '2em',
          '&:last-child': {
            paddingBottom: '2em',
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '2em',
          borderBottom: `1px solid ${COLOR.NEUTRAL[200]}`,
        },
      },
    },
    MuiCardActionArea: {
      defaultProps: { disableRipple: true },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'large',
        variant: 'contained',
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&.shape-pill': {
            borderRadius: '28px',
          },
          '&.shape-rounded-square': {
            borderRadius: '8px',
          },
          '&.full-width': {
            width: '100%',
          },
          boxShadow: 'none',
        },
        firstButton: ({ ownerState }) => ({
          borderTopLeftRadius: ownerState.className.includes('shape-pill')
            ? '28px'
            : '8px',
          borderBottomLeftRadius: ownerState.className.includes('shape-pill')
            ? '28px'
            : '8px',
          padding: ownerState.size === 'large' ? '11px 16px' : '7px 16px',
          ...(ownerState.className.includes('compact') && {
            paddingLeft: '12px',
            paddingRight: '12px',
          }),
        }),
        lastButton: ({ ownerState }) => ({
          padding: 0,
          maxWidth: '44px',
          borderTopRightRadius: ownerState.className.includes('shape-pill')
            ? '28px'
            : '8px',
          borderBottomRightRadius: ownerState.className.includes('shape-pill')
            ? '28px'
            : '8px',
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: COLOR.NEUTRAL[500],
          '&.Mui-disabled svg': {
            fill: COLOR.NEUTRAL[400],
          },
          '&.Mui-disabled.Mui-checked svg, &.Mui-disabled.MuiCheckbox-indeterminate svg':
            { fill: COLOR.NEUTRAL[500] },
        },
      },
    },
    MuiAvatar: {
      defaultProps: {
        variant: 'circular',
      },
      styleOverrides: {
        root: {
          background: 'transparent',
        },
      },
      variants: [
        {
          props: { variant: 'interactive' },
          style: {
            border: `1.5px solid ${COLOR.WHITE}`,
            outline: `0.5px solid ${COLOR.NEUTRAL[400]}`,
            '&:focus': {
              border: `1.5px solid ${COLOR.PRIMARY[100]}`,
            },
            '&:hover': {
              border: `1.5px solid ${COLOR.PRIMARY[100]}`,
            },
            '&:active': {
              border: `1.5px solid ${COLOR.PRIMARY[500]}`,
            },
          },
        },
      ],
    },
    MuiDialog: {
      defaultProps: {
        maxWidth: 'md',
        fullWidth: true,
      },
    },
    MuiDialogTitle: { styleOverrides: { root: { padding: '1em 1.25em' } } },
    MuiDialogContent: {
      defaultProps: { dividers: true },
      styleOverrides: {
        root: {
          padding: '1.5em 2em',
          borderTopColor: palette.grey[200],
          borderBottomColor: palette.grey[200],
        },
      },
    },
    MuiDialogActions: { styleOverrides: { root: { padding: '1.25em 2em' } } },
    MuiInputLabel: {
      defaultProps: { shrink: true, disableAnimation: true },
      styleOverrides: {
        asterisk: { color: palette.error[500] },
        root: {
          ...body2,
          color: palette.black.main,
          transform: 'none',
          position: 'relative',
          '&.Mui-disabled': { color: palette.grey[700] },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...body3,
          transform: 'none',
          position: 'relative',
          marginLeft: 0,
          color: palette.grey[700],
          '&.Mui-error': { color: palette.error[500] },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          border: `1px solid ${COLOR.NEUTRAL[200]}`,
          '&.MuiMenu-paper': { padding: 0 },
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          width: '100%',
          height: '44px',
          padding: '0px 12px',
          borderRadius: 6,
          color: palette.black.main,
          '&:hover': {
            backgroundColor: COLOR.NEUTRAL[100],
          },
          '&:focus-visible': {
            backgroundColor: COLOR.PRIMARY[100],
          },
          '&:active': {
            backgroundColor: COLOR.PRIMARY[100],
          },
          '&.Mui-disabled': {
            color: COLOR.NEUTRAL[500],
          },
          '&.Mui-selected': {
            backgroundColor: COLOR.PRIMARY[100],
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: palette.error[500] },
        root: {
          ...body2,
          color: palette.black.main,
          transform: 'none',
          position: 'relative',
          '&.Mui-disabled': { color: palette.grey[700] },
          '&.Mui-focused': { color: palette.black.main },
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          success: <CheckCircleOutlineIcon />,
          info: <InfoOutlinedIcon />,
          error: <ErrorOutlineOutlinedIcon />,
          warning: <WarningAmberOutlinedIcon />,
        },
        slotProps: {
          closeButton: {
            size: 'small',
            color: 'grey',
            sx: { svg: { fontSize: '20px' } },
          },
        },
        slots: { closeButton: IconButton },
      },
      styleOverrides: {
        root: ({ ownerState }) => {
          // If color prop is provided, use that
          const color = ownerState.color || ownerState.severity;
          return {
            padding: '1em',
            marginBottom: '2em',
            borderRadius: '6px',
            backgroundColor: palette[color][100],
            border: `1px solid ${palette[color][200]}`,
            color: palette[color][900],
            '.MuiAlert-icon': {
              color: palette[color][900],
              svg: { fontSize: '20px' },
            },
            a: {
              color: palette[color][900],
              textDecoration: 'underline',
              textDecorationColor: palette[color][700],
              ':hover': {
                fontWeight: 600,
                textDecoration: 'underline',
                textDecorationColor: palette[color][900],
              },
            },
          };
        },
        action: ({ ownerState }) => {
          // If color prop is provided, use that
          const color = ownerState.color || ownerState.severity;
          return {
            button: {
              '&.MuiButton-root': {
                color: palette[color][900],
                ':hover': {
                  backgroundColor: palette[color][200],
                },
                ':active': {
                  backgroundColor: palette[color][400],
                },
                ':focus': {
                  backgroundColor: palette[color][200],
                },
              },
              '&.MuiIconButton-root': {
                color: palette[color][900],
                ':hover': {
                  backgroundColor: palette[color][200],
                },
                ':active': {
                  backgroundColor: palette[color][400],
                },
                ':focus': {
                  backgroundColor: palette[color][200],
                },
              },
            },
          };
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          ...body2sb,
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        textColor: 'secondary',
        indicatorColor: 'secondary',
        variant: 'scrollable',
        scrollButtons: 'auto',
      },
      styleOverrides: {
        root: {
          marginBottom: '1em',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
        iconPosition: 'start',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          minHeight: '44px',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow:
            '0 1px 3px 0 rgba(26,26,46,0.1), 0 0 0px rgba(26,26,46,0.1)',
          border: `1px solid ${COLOR.NEUTRAL[200]}`,
          borderRadius: '6px',
          '&:before': { display: 'none' },
          '&:last-of-type': {
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
          },
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <ChevronRight />,
      },
      styleOverrides: {
        expandIconWrapper: {
          '&.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          width: '36px',
          height: '20px',
          padding: '2px',
        },
        thumb: {
          width: '12px !important',
          height: '12px !important',
          backgroundColor: palette.white.main,
        },
        switchBase: {
          '&.Mui-checked + .MuiSwitch-track': {
            opacity: '1 !important',
          },
        },
        track: {
          borderRadius: '24px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          marginLeft: '.5em',
        },
        labelPlacementStart: {
          '.MuiFormControlLabel-label': {
            marginLeft: 0,
            marginRight: '.5em',
          },
        },
        labelPlacementTop: {
          '.MuiFormControlLabel-label': {
            marginLeft: 0,
          },
        },
        labelPlacementBottom: {
          '.MuiFormControlLabel-label': {
            marginLeft: 0,
          },
        },
      },
    },
  },
};

export const theme = createTheme(themeOptions);
