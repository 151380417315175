import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@src/components/sc-design-system';
import GoalStatusIndicator from '@src/components/common/GoalCard/GoalStatusIndicator';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { Stack, Typography } from '@mui/material';

interface GoalStatusUpdateFormHeaderProps {
  selectedGoal: Goal;
  setShowUpdateStatusForm: (b: boolean) => void;
}

const GoalStatusUpdateFormHeader: React.FunctionComponent<GoalStatusUpdateFormHeaderProps> =
  observer(function (props: GoalStatusUpdateFormHeaderProps) {
    const { selectedGoal, setShowUpdateStatusForm } = props;
    const currentStatus = useMemo(() => {
      return selectedGoal.status_history?.[
        selectedGoal.status_history.length - 1
      ];
    }, [selectedGoal.status_history]);
    return (
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='middle'
        style={{ marginBottom: '20px', padding: '0 32px' }}
      >
        <Stack direction='row' spacing={2} alignItems='center'>
          <Typography variant='h5' sx={{ marginBottom: 0 }}>
            Current Goal Status
          </Typography>
          <GoalStatusIndicator status={currentStatus?.status} />
        </Stack>
        <Button
          variant='text'
          startIcon={<EditOutlined />}
          text='Update Status'
          size='small'
          onClick={() => setShowUpdateStatusForm(true)}
        />
      </Stack>
    );
  });

export default GoalStatusUpdateFormHeader;
