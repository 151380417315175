import React, { FC } from 'react';
import { observer } from 'mobx-react';
import SurveyQuestion from '@src/models/SurveyQuestion';
import { EditableTypography } from '@src/components/sc-design-system';

const QuestionTitle: FC<{
  surveyQuestion: SurveyQuestion;
  readOnly?: boolean;
}> = observer(({ surveyQuestion, readOnly = false }) => (
  <EditableTypography
    key={surveyQuestion.id}
    variant='h4'
    width={'100%'}
    children={surveyQuestion.title}
    onChange={title => (surveyQuestion.title = title)}
    disabled={readOnly}
    multiline
  />
));

export { QuestionTitle };
