import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import Program from '@src/models/Program';
import { useBreadcrumbs } from '@src/components/sc-design-system';
import { EmptySection } from '../EmptySection';
import ActiveInactiveChip from '@src/components/common/ActiveInactiveChip';

interface ExistingSurveyUseProps {
  programs: Array<Program & { survey_responses: number }> | null;
}

const ExistingSurveyUse: FC<ExistingSurveyUseProps> = ({ programs }) => {
  const navigate = useNavigate();
  const { client_id } = useParams();
  const { resetBreadcrumbs } = useBreadcrumbs();

  if (!programs?.length) {
    return (
      <EmptySection
        header='Existing survey use'
        description='If this survey is already in use in a program, you can review that
        information here.'
        buttonText={'Go to programs'}
        buttonOnClick={() => {
          const path = `/my-business/${client_id}?tab=1`;
          navigate(path);
          resetBreadcrumbs([
            {
              path,
              label: 'Programs',
            },
          ]);
        }}
      />
    );
  }

  return (
    <Stack gap='1em'>
      <Typography variant='h4'>Existing survey use</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant='head' width='25%'>
              Program Name
            </TableCell>
            <TableCell variant='head' width='10%'>
              Status
            </TableCell>
            <TableCell variant='head' width='22%'>
              Number of responses
            </TableCell>
            <TableCell variant='head' width='16%'>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {programs?.map(p => (
            <TableRow key={p.id}>
              <TableCell>
                <Typography variant='body1'>{p.name}</Typography>
              </TableCell>
              <TableCell>
                <ActiveInactiveChip
                  active={
                    p.is_active &&
                    p.start_date < Date.now() &&
                    p.end_date > Date.now()
                  }
                />
              </TableCell>
              <TableCell>
                <Typography variant='body1'>{p.survey_responses}</Typography>
              </TableCell>
              <TableCell>
                <Link
                  variant='a1'
                  component='button'
                  onClick={() => {
                    const path = `/program/${p.id}`;
                    navigate(path);
                    resetBreadcrumbs([]);
                  }}
                >
                  Manage program
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
};

export { ExistingSurveyUse };
