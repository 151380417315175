import React, { FC, useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import MenuIcon from '@mui/icons-material/Menu';

import './ListGridToggle.less';

enum LayoutType {
  grid = 'grid',
  list = 'list',
}

/**
 * This hook can be used wherever ListGridToggle is used, as a convenience
 * for setting the layout view in local storage.
 *
 * @param key Unique string to prepend to local storage key
 * @returns ['list' | 'grid', ('list' | 'grid') => void ]
 */
const useLayoutToggle = (key?: string) => {
  const [layoutType, setLayoutType] = useState(
    localStorage.getItem(`${key}-layout`) || LayoutType.grid,
  );

  return [
    layoutType as LayoutType,
    (type: LayoutType) => {
      localStorage.setItem(`${key}-layout`, type);
      setLayoutType(type);
    },
  ] as const;
};

interface ListGridToggleProps {
  layoutType?: LayoutType;
  onChange: (type: LayoutType) => void;
}

const ListGridToggle: FC<ListGridToggleProps> = ({ layoutType, onChange }) => (
  <Stack direction='row' alignItems='center' sx={{ marginLeft: 'auto' }}>
    <IconButton onClick={() => onChange(LayoutType.grid)}>
      <GridViewIcon
        fontSize='small'
        color={layoutType === LayoutType.grid ? 'secondary' : 'action'}
      />
    </IconButton>
    <IconButton onClick={() => onChange(LayoutType.list)}>
      <MenuIcon
        fontSize='small'
        color={layoutType === LayoutType.list ? 'secondary' : 'action'}
      />
    </IconButton>
  </Stack>
);

export { ListGridToggle, LayoutType, useLayoutToggle };
