import React from 'react';
import {
  Dialog,
  DialogContent,
  Divider,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { useParams } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  DialogTitle,
  useToast,
} from '@src/components/sc-design-system';
import { useClientQueries } from '@src/hooks/useClientQueries';
import CurrentInvitations from './CurrentInvitations';
import IndividualInvite from './IndividualInvite';
import InviteFromCSV from './InviteFromCSV';
import { api } from '@src/lib/client';
import { isDuplicateEmail } from './utils';
import InviteEmployees from './InviteEmployees';

import Add from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InviteReminder from './InviteReminder';

interface InviteMenuProps {
  toInvite: Set<string>;
  setOpenInviteModal: (b: boolean) => void;
  setOpenInviteEmployeesModal: (b: boolean) => void;
}

const InviteMenu = function (props: InviteMenuProps) {
  const { toInvite, setOpenInviteModal, setOpenInviteEmployeesModal } = props;
  const [openInviteMenu, setOpenInviteMenu] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const menuItems = [
    {
      key: 'invite_employees',
      label: 'Invite employees',
      onClick: () => setOpenInviteEmployeesModal(true),
      disabled: toInvite.size === 0,
    },
    {
      key: 'invite_non_employees',
      label: 'Invite non-employees',
      onClick: () => setOpenInviteModal(true),
    },
  ];

  return (
    <React.Fragment>
      <Button
        anchorRef={anchorRef}
        variant='contained'
        size='small'
        startIcon={<Add />}
        endIcon={<ArrowDropDownIcon />}
        text='Invite'
        className='rounded-square'
        onClick={() => setOpenInviteMenu(true)}
      />
      <Menu
        open={openInviteMenu}
        onClose={() => setOpenInviteMenu(!openInviteMenu)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '.MuiMenu-paper': {
            padding: '2px',
            marginTop: '.5em',
          },
        }}
      >
        {menuItems.map(item => (
          <MenuItem
            disableRipple
            key={item.key}
            onClick={() => {
              item.onClick();
              setOpenInviteMenu(false);
            }}
            disabled={item.disabled}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

interface InviteModalProps {
  enableAdvancedInvites: boolean;
  hrisEnabled?: boolean;
  toInvite?: Set<string>;
  setToInvite?: (s: Set<string>) => void;
  toRemind?: Set<string>;
  setToRemind?: (s: Set<string>) => void;
  refetchClient?: Function; // used to refresh the zero state when members are invited
}

const InviteModal = (props: InviteModalProps) => {
  const {
    enableAdvancedInvites,
    hrisEnabled,
    toInvite,
    setToInvite,
    toRemind,
    setToRemind,
    refetchClient,
  } = props;
  const toast = useToast();
  const queryClient = useQueryClient();
  const [openInviteModal, setOpenInviteModal] = React.useState(false);
  const [membersInvited, setMembersInvited] = React.useState(false);
  const [openInviteEmployeesModal, setOpenInviteEmployeesModal] =
    React.useState(false);
  const [csvFileContent, setCsvFileContent] = React.useState('');
  const { id: clientId } = useParams();
  const { useGetClientFields, useGetClientMembers } =
    useClientQueries(clientId);
  const { members: allClientMembers } = useGetClientMembers();
  const {
    data: invitees,
    refetch,
    isLoading,
  } = useGetClientFields(['invitees', 'name']);

  // invitees who haven't been revoked or who haven't registered
  const activeInvitees = React.useMemo(() => {
    return (invitees || []).filter(i => !i.joinedAt && !i.revokedAt);
  }, [invitees]);

  const { mutate: revokeInvite } = useMutation({
    mutationFn: async (email: string) =>
      api.clients.revokeInvite({ clientId, email }),
    onSuccess: () => {
      setMembersInvited(true);
      refetch();
    },
    onError: () => {
      toast.error('Unable to revoke invite');
    },
  });

  const { mutate: undoRevokeInvite } = useMutation({
    mutationFn: async (email: string) =>
      api.clients.undoRevokeInvite({ clientId, email }),
    onSuccess: () => {
      setMembersInvited(true);
      refetch();
    },
    onError: () => {
      toast.error('Unable to undo revoked invite');
    },
  });

  const inviteClientMembers = async ({ invitees }) =>
    api.clients.inviteClientMembers({
      clientId,
      invitees,
    });

  const handleClose = () => {
    setOpenInviteModal(false);
    setCsvFileContent('');
    setToInvite && setToInvite(new Set());
    setToRemind && setToRemind(new Set());
    if (membersInvited) {
      queryClient.invalidateQueries({
        queryKey: ['client', clientId, 'members'],
      });
      // zero state refresh
      refetchClient && refetchClient();
    }
  };

  return (
    <>
      {enableAdvancedInvites ? (
        <Stack direction='row' spacing={2}>
          {activeInvitees.length > 0 && (
            <InviteReminder
              clientId={clientId}
              toRemind={toRemind}
              setToRemind={setToRemind}
            />
          )}
          {hrisEnabled ? (
            <>
              <InviteEmployees
                clientId={clientId}
                openInviteEmployeesModal={openInviteEmployeesModal}
                setOpenInviteEmployeesModal={setOpenInviteEmployeesModal}
                toInvite={toInvite}
                setToInvite={setToInvite}
                inviteMembers={inviteClientMembers}
              />
              <InviteMenu
                toInvite={toInvite}
                setOpenInviteEmployeesModal={setOpenInviteEmployeesModal}
                setOpenInviteModal={setOpenInviteModal}
              />
            </>
          ) : (
            <Button
              variant='contained'
              startIcon={<Add />}
              text='Invite Members'
              size='small'
              onClick={() => setOpenInviteModal(true)}
            />
          )}
        </Stack>
      ) : (
        <Button
          variant='contained'
          startIcon={<Add />}
          text='Invite Members'
          onClick={() => setOpenInviteModal(true)}
        />
      )}
      <Dialog maxWidth='lg' open={openInviteModal} onClose={handleClose}>
        <DialogTitle buttonOnClick={handleClose} title='Invite Members' />
        <DialogContent>
          <Stack divider={<Divider />}>
            <InviteFromCSV
              csvFileContent={csvFileContent}
              setCsvFileContent={setCsvFileContent}
              isDuplicateEmail={isDuplicateEmail(allClientMembers, invitees)}
              refetch={refetch}
              inviteMembers={inviteClientMembers}
              setMembersInvited={setMembersInvited}
            />
            {!csvFileContent && (
              <IndividualInvite
                refetch={refetch}
                inviteMembers={inviteClientMembers}
                isDuplicateEmail={isDuplicateEmail(allClientMembers, invitees)}
                setMembersInvited={setMembersInvited}
              />
            )}
            {!csvFileContent && (
              <CurrentInvitations
                invitees={invitees}
                isLoading={isLoading}
                revokeInvite={revokeInvite}
                undoRevokeInvite={undoRevokeInvite}
              />
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InviteModal;
