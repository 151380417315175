import React, { FC } from 'react';
import { Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { PercentageRing } from '@src/components/sc-design-system';

interface ClientMetrics {
  // Client and program level
  members: number;
  membersByProgram?: any;
  allocatedSessions: number;
  allocatedSessionsByProgram?: any;
  paired: number;
  pairedByProgram?: any;
  inCoaching: number;
  inCoachingByProgram?: any;
  usedSessions: number;
  usedSessionsByProgram?: any;
  // Client level only
  activePrograms?: number;
  enrolledInPrograms?: number;
  usersWithUpdatedGoals?: number;
}

const ClientDashboardProgramActivity: FC<{
  clientMetrics: ClientMetrics;
}> = observer(({ clientMetrics }) => {
  const { members, allocatedSessions, paired, inCoaching, usedSessions } =
    clientMetrics;
  const color = ['#7E287E', '#E8E8E8'];
  return (
    <Row justify={'space-evenly'}>
      <PercentageRing
        titleText='Paired with a coach or mentor'
        percent={paired / members}
        color={color}
      />
      <PercentageRing
        titleText={'Have started coaching'}
        percent={inCoaching / members}
        color={color}
      />
      <PercentageRing
        titleText='Sessions scheduled or completed'
        percent={usedSessions / allocatedSessions}
        color={color}
      />
    </Row>
  );
});

export { ClientDashboardProgramActivity };
