import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import Surveys from '../../../../SurveysManager/Surveys/Surveys';
import { Button, Card } from '@src/components/sc-design-system';
import { NewSurveyModal } from '@src/components/views/SurveysManager/SurveyEditor/NewSurveyModal';
import Add from '@mui/icons-material/Add';
import { useQueryParams } from '@src/utils';

const ClientSurveys: FC<{ clientId: string; tabIndex?: number }> = observer(
  ({ clientId, tabIndex = 1 }) => {
    const query = useQueryParams();
    const creatingNewSurvey = Boolean(query.get('new') || false);
    const [openNewSurveyModal, setOpenNewSurveyModal] =
      useState(creatingNewSurvey);

    return (
      <Card
        title='Surveys'
        titleActions={[
          <Button
            key='Add Survey'
            startIcon={<Add />}
            variant='text'
            size='small'
            onClick={() => setOpenNewSurveyModal(true)}
            text='Add Survey'
          />,
        ]}
      >
        {openNewSurveyModal && (
          <NewSurveyModal
            showModal={openNewSurveyModal}
            setShowModal={setOpenNewSurveyModal}
          />
        )}
        <Surveys
          clientId={clientId}
          openNewSurvey={() => setOpenNewSurveyModal(true)}
          tabIndex={tabIndex}
        />
      </Card>
    );
  },
);

export { ClientSurveys };
