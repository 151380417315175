// https://github.com/aws-amplify/amplify-js/issues/122#issuecomment-536067828
import { Auth } from '@aws-amplify/auth';
import { Logger } from './lib/logger';

let _session: UserSession;

/**
 * @deprecated
 * Provides a global UserSession instance for modules that expect it to be
 * global. Remove when those usages are refactored.
 */
export const deprecatedGlobalUserSession = (): UserSession => {
  if (!_session) {
    throw new Error(`global user session not initialized.`);
  }
  return _session;
};

export const createUserSession = (): UserSession => {
  const config = {
    region: process.env.AWS_APP_REGION,
    userPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.AWS_COGNITO_USER_POOL_APP_ID,
  };
  Auth.configure(config);
  const session = new UserSession(Auth);
  _session = session;
  return session;
};

export class UserSession {
  private auth: typeof Auth;
  private logger: Logger;
  constructor(auth: typeof Auth) {
    this.auth = auth;
    this.logger = new Logger('session');
  }

  async signIn(email: string, password: string) {
    try {
      const user = await this.auth.signIn({ username: email, password });
      // For accounts manually added to Cognito we need this step
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await this.auth.completeNewPassword(user, password);
      }
      return user;
    } catch (e) {
      throw new Error('Could not login with email and password provided');
    }
  }

  async signOut() {
    await this.auth.signOut();
  }

  async signUp(info: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) {
    try {
      return await this.auth.signUp({
        username: info.email,
        password: info.password,
        attributes: {
          email: info.email.trim().toLowerCase(),
          given_name: info.firstName.trim(),
          family_name: info.lastName.trim(),
        },
      });
    } catch (e) {
      let message = e.message;
      if (message === 'User already exists') {
        message = 'The email address provided already exists';
      }
      throw new Error(message);
    }
  }

  async changePassword(oldPassword: string, newPassword: string) {
    const user = await this.auth.currentAuthenticatedUser();
    await this.auth.changePassword(user, oldPassword, newPassword);
  }

  async confirmSignUp(email: string, code: string) {
    await this.auth.confirmSignUp(email, code);
  }

  async resendCode(email: string) {
    try {
      await this.auth.resendSignUp(email);
    } catch (e) {
      throw new Error('Could not resend code');
    }
  }

  async forgotPassword(email: string) {
    await this.auth.forgotPassword(email);
  }

  async forgotPasswordSubmit(email: string, code: string, password: string) {
    await this.auth.forgotPasswordSubmit(email, code, password);
  }

  async getSessionInfo() {
    try {
      const currentSession = await Auth.currentSession();
      const { attributes } = await Auth.currentAuthenticatedUser();
      const accessToken = currentSession.getAccessToken();
      const jwt = accessToken.getJwtToken();
      return { attributes, currentSession, accessToken, jwt };
    } catch (e) {
      return false;
    }
  }
}

export const consumeInviteTokens = () => {
  try {
    const search = window.location.search.substring(1);
    const queryParams = JSON.parse(
      '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function (key, value) {
        return key === '' ? value : decodeURIComponent(value);
      },
    );
    if (queryParams) {
      const joinTokenParamNames = [
        'client-invite-token',
        'program-invite-token',
        'coach',
        'first-name',
        'last-name',
        'email',
        'client-name',
        'program-name',
      ];
      joinTokenParamNames.forEach(p => {
        if (queryParams[p]) window.localStorage.setItem(p, queryParams[p]);
      });
    }
  } catch (e) {
    /* no-op, should be non-blocking */
  }
};
