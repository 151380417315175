import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';

obey.modifier('now', () => Date.now());
obey.creator('uuid', () => uuidv4());

export const PerformanceCycleResultsSchema = {
  id: { type: 'string', required: true, creator: 'uuid' },
  performance_cycle_id: { type: 'string', required: true },
  results: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        // If a manager's results come in first, we won't have the direct report's submission id or rating, so set allowNull
        direct_report: {
          type: 'object',
          keys: {
            id: { type: 'string', required: true },
            evaluation_submission_id: { type: 'string', allowNull: true },
            rating: { type: 'number', allowNull: true },
          },
        },
        // If a direct report's self results come in first, we won't have the manager fields, so set allowNull
        manager: {
          type: 'object',
          keys: {
            id: { type: 'string', allowNull: true },
            evaluation_submission_id: { type: 'string', allowNull: true },
            rating: { type: 'number', allowNull: true },
          },
        },
        updatedAt: { type: 'number', modifier: 'now' },
      },
    },
    default: [],
    empty: true,
  },
};

// eslint-disable-next-line no-unused-vars
export type PerformanceCyclesResultsType = {
  [K in keyof typeof PerformanceCycleResultsSchema]?: any;
};

export const PerformanceCycleResultsModel = obey.model(
  PerformanceCycleResultsSchema,
);
