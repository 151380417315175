import { useQuery } from '@tanstack/react-query';
import { api } from '@src/lib/client';

const useSkills = ({ enabled } = { enabled: true }) => {
  const { data, ...rest } = useQuery({
    queryKey: ['skills'],
    queryFn: async () => await api.skills.get(),
    select: ({ data }) => data.sort((a, b) => (a.title > b.title ? 1 : -1)),
    enabled,
  });

  return {
    skills: data ?? null,
    ...rest,
  };
};

export { useSkills };
