import React, { FC, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react';
import { Stack, Typography, Divider } from '@mui/material';
import { Card } from '@src/components/sc-design-system';
import LoadingIcon from '@src/components/common/LoadingIcon';
import GoalActivityCards from './GoalActivityCards';
import ProgramActivityCard from './ProgramActivityCard';
import DirectReportOverviewCard from './DirectReportOverviewCard';
import { ActiveClientKey } from '@src/hooks/useActiveClient';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { useUserQueries } from '@src/hooks/useUserQueries';
import { PerformanceCycleSelector } from '@src/components/common/PerformanceCycleSelector';
import EvaluationFor from './EvaluationFor';
import SelfEvaluation from './SelfEvaluation';
import { FinalPerformanceReview } from './FinalPerformanceReview';
import { OngoingFeedback } from './OngoingFeedback';

const Overview: FC = () => {
  const [selectedPerformanceCycle, setSelectedPerformanceCycle] =
    useState<PerformanceCycleI>(null);
  const params = useParams();

  const queryClient = useQueryClient();
  const invalidateUserGoals = () => {
    queryClient.invalidateQueries({ queryKey: ['user', params.id, 'goals'] });
  };

  const scAdminClientId = localStorage.getItem(
    ActiveClientKey.sc_admin_active_client_id,
  );
  const profileClientId = localStorage.getItem(
    ActiveClientKey.profile_active_client_id,
  );
  // Get Client Data
  const currentUserClient = scAdminClientId || profileClientId;
  const { useGetClient, useGetPerformanceCycleResultsForDirectReport } =
    useClientQueries(currentUserClient);
  const { data: client, isLoading: isClientDataLoading } = useGetClient();
  const {
    results: performanceCycleResults,
    isLoading: isPerformanceCycleDataLoading,
  } = useGetPerformanceCycleResultsForDirectReport(
    selectedPerformanceCycle?.id,
    params.id,
  );

  // Get User Data
  const {
    useGetUser,
    useGetUserGoalData,
    useGetUserPrograms,
    useGetUserGoalDataForAdmin,
    useGetUserProgramsForAdmin,
  } = useUserQueries(params.id);
  const { user: profileUser, isLoading: isUserDataLoading } = useGetUser();
  const {
    performanceData: adminUserPerformanceData,
    isLoading: isAdminUserPerformanceDataLoading,
  } = useGetUserGoalDataForAdmin({ enabled: !!scAdminClientId });
  const {
    performanceData: userPerformanceData,
    isLoading: isUserPerformanceDataLoading,
  } = useGetUserGoalData({ enabled: !scAdminClientId });
  const {
    userPrograms: adminUserProgramsData,
    isLoading: isAdminUserProgramsDataLoading,
  } = useGetUserProgramsForAdmin('coachee', { enabled: !!scAdminClientId });
  const {
    userPrograms: userProgramsData,
    isLoading: isUserProgramsDataLoading,
  } = useGetUserPrograms('coachee', { enabled: !scAdminClientId });

  const performanceData = useMemo(() => {
    if (isAdminUserPerformanceDataLoading || isUserPerformanceDataLoading) {
      return null;
    }
    return adminUserPerformanceData || userPerformanceData;
  }, [
    isAdminUserPerformanceDataLoading,
    adminUserPerformanceData,
    isUserPerformanceDataLoading,
    userPerformanceData,
  ]);

  const userPrograms = useMemo(() => {
    if (isAdminUserProgramsDataLoading || isUserProgramsDataLoading) {
      return null;
    }
    return adminUserProgramsData || userProgramsData;
  }, [
    isAdminUserProgramsDataLoading,
    adminUserProgramsData,
    isUserProgramsDataLoading,
    userProgramsData,
  ]);

  useEffect(() => {
    if (isAdminUserPerformanceDataLoading || isUserPerformanceDataLoading) {
      return;
    }
    if (!selectedPerformanceCycle) {
      setSelectedPerformanceCycle(
        performanceData?.performance_cycles?.find(pc => pc.goal_cycles), // TODO: Which performance cycle should display initially?
      );
    } else {
      setSelectedPerformanceCycle(
        performanceData?.performance_cycles?.find(
          pc => pc.id === selectedPerformanceCycle.id,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    performanceData,
    isAdminUserPerformanceDataLoading,
    isUserPerformanceDataLoading,
  ]);

  const title = useMemo(() => {
    return client?.members?.find(m => m.id === profileUser?.id)?.title || '';
  }, [client, profileUser]);

  const department = useMemo(() => {
    return (
      client?.departments?.find(
        d =>
          d.id ===
          client?.members?.find(m => m.id === profileUser?.id)?.department?.data
            ?.id,
      )?.name || ''
    );
  }, [client, profileUser]);

  const alignedGoalsNum = useMemo(() => {
    return performanceData?.performance_cycles?.reduce((sum, pc) => {
      pc.goal_cycles?.forEach(gc => {
        let aligned = gc.goals?.filter(g =>
          g.user_ids?.includes(profileUser?.id),
        );
        sum += aligned.length;
      });
      return sum;
    }, 0);
  }, [performanceData, profileUser]);

  const isLoading =
    isUserDataLoading ||
    isAdminUserPerformanceDataLoading ||
    isUserPerformanceDataLoading ||
    isAdminUserProgramsDataLoading ||
    isUserProgramsDataLoading ||
    isClientDataLoading ||
    isPerformanceCycleDataLoading;

  const canEvaluateDirectReport =
    selectedPerformanceCycle?.manager_evaluation_start &&
    selectedPerformanceCycle?.manager_evaluation_end &&
    dayjs().isSameOrAfter(
      dayjs(selectedPerformanceCycle.manager_evaluation_start),
    );
  const hasSelfEvaluated =
    selectedPerformanceCycle?.self_evaluation_start &&
    selectedPerformanceCycle?.self_evaluation_end &&
    dayjs().isSameOrAfter(
      dayjs(selectedPerformanceCycle.self_evaluation_start),
    ) &&
    (performanceCycleResults?.results?.direct_report?.rating != null ||
      !!performanceCycleResults?.results?.direct_report
        ?.evaluation_submission_id);

  const hasEvaluatedDirectReport =
    canEvaluateDirectReport &&
    (performanceCycleResults?.results?.manager?.rating != null ||
      !!performanceCycleResults?.results?.manager?.evaluation_submission_id);

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <>
      <DirectReportOverviewCard
        department={department}
        alignedGoalsNum={alignedGoalsNum}
        title={title}
        programs={userPrograms}
      />
      <Card
        title={selectedPerformanceCycle?.name || 'Performance cycle'} // TODO: Placeholder for empty state; check with Amanda/Erin
        titleActions={
          <PerformanceCycleSelector
            onChange={setSelectedPerformanceCycle}
            performanceCycles={performanceData?.performance_cycles}
            selectedPerformanceCycle={selectedPerformanceCycle}
          />
        }
        subheader={
          <Typography variant='body1' color='grey.700'>
            Performance cycle activity
          </Typography>
        }
      >
        <Stack divider={<Divider />}>
          {hasEvaluatedDirectReport && hasSelfEvaluated && (
            <FinalPerformanceReview
              profileUser={profileUser}
              clientId={client?.id}
              performanceCycleId={selectedPerformanceCycle?.id}
            />
          )}
          {(canEvaluateDirectReport || hasSelfEvaluated) && (
            <>
              {canEvaluateDirectReport && (
                <EvaluationFor
                  profileUser={profileUser}
                  selectedPerformanceCycle={selectedPerformanceCycle}
                  performanceCycleResults={
                    performanceCycleResults?.results || {}
                  }
                  clientId={client?.id}
                />
              )}
              {hasSelfEvaluated && (
                <SelfEvaluation
                  profileUser={profileUser}
                  selectedPerformanceCycle={selectedPerformanceCycle}
                  performanceCycleResults={
                    performanceCycleResults?.results || {}
                  }
                />
              )}
            </>
          )}
          {Boolean(selectedPerformanceCycle?.ongoing_feedback_form_id) && (
            <OngoingFeedback
              profileUser={profileUser}
              selectedPerformanceCycle={selectedPerformanceCycle}
              clientId={client?.id}
            />
          )}
          <Stack spacing={1} gap={1}>
            <Typography variant='h3'>Goal activity</Typography>
            <GoalActivityCards
              goalCycles={selectedPerformanceCycle?.goal_cycles?.map(gc => {
                return {
                  ...gc,
                  performanceCycleId: selectedPerformanceCycle?.id,
                };
              })}
              user={profileUser}
              clientId={client?.id}
              onGoalStatusUpdate={invalidateUserGoals}
            />
          </Stack>
        </Stack>
      </Card>
      <ProgramActivityCard user={profileUser} programs={userPrograms} />
    </>
  );
};

export default observer(Overview);
