import React, { FC, useState, useEffect, useMemo } from 'react';
import _intersectionBy from 'lodash/intersectionBy';
import _uniq from 'lodash/uniq';
import _pullAllBy from 'lodash/pullAllBy';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import usePrevious from '@src/hooks/usePrevious';

interface RecipientsTableProps {
  recipientsList: any[];
  selectedRecipients?: any[];
  selectableRecipients?: any[];
  handleSelect?: Function;
  showCheckboxColumn?: boolean;
  updatePage?: number;
}
const PAGE_SIZE = 25;

const RecipientsTable: FC<RecipientsTableProps> = ({
  recipientsList,
  selectedRecipients,
  selectableRecipients,
  handleSelect,
  showCheckboxColumn = false,
  updatePage,
}) => {
  const prevUpdate = usePrevious(updatePage);
  const [page, setPage] = useState(0);

  useEffect(() => {
    // if a filter is set in a parent component, reset the page
    if (prevUpdate && prevUpdate !== updatePage) {
      setPage(0);
    }
  }, [updatePage, prevUpdate]);

  // Track which recipients have been selected within given department filter
  const selectedFilteredRecipients = useMemo(() => {
    return _intersectionBy(selectedRecipients, selectableRecipients, 'id');
  }, [selectedRecipients, selectableRecipients]);

  return (
    <>
      <Table>
        <TableHead sx={{ position: 'sticky' }}>
          <TableRow>
            {showCheckboxColumn && (
              <TableCell variant='head'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    disableRipple
                    checked={
                      selectableRecipients?.length &&
                      selectableRecipients?.length ===
                        selectedFilteredRecipients?.length
                    }
                    indeterminate={
                      selectedFilteredRecipients?.length > 0 &&
                      selectedFilteredRecipients?.length <
                        selectableRecipients?.length
                    }
                    onChange={e => {
                      if (e.target.checked) {
                        handleSelect(
                          _uniq([
                            ...selectedRecipients,
                            ...selectableRecipients,
                          ]),
                        );
                      } else {
                        const updated = _pullAllBy(
                          selectedRecipients,
                          selectedFilteredRecipients,
                          'id',
                        );
                        handleSelect([...updated]);
                      }
                    }}
                    disabled={!selectableRecipients?.length}
                  />
                  <Tooltip title='Select all recipients that have not responded'>
                    <InfoIcon fontSize='small' />
                  </Tooltip>
                </div>
              </TableCell>
            )}
            <TableCell variant='head' width='25%'>
              Recipient
            </TableCell>
            <TableCell variant='head' align='center' width='22%'>
              Response Status
            </TableCell>
            <TableCell variant='head' align='center' width='20%'>
              Registered
            </TableCell>
            <TableCell variant='head' width='20%'>
              Last Sent
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recipientsList?.length === 0 && (
            <TableRow>
              <TableCell colSpan={showCheckboxColumn ? 5 : 4} align='center'>
                <Typography variant='body1'>
                  No matches with current filters.
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {recipientsList?.length !== 0 &&
            (PAGE_SIZE > 0
              ? recipientsList.slice(
                  page * PAGE_SIZE,
                  page * PAGE_SIZE + PAGE_SIZE,
                )
              : recipientsList
            ).map(r => {
              const isSelected = selectedRecipients?.some(s => s.id === r.id);
              const alreadySubmitted = Boolean(r.submission_id);
              return (
                <TableRow key={r.id}>
                  {showCheckboxColumn && (
                    <TableCell>
                      <Checkbox
                        disableRipple
                        checked={Boolean(isSelected && !alreadySubmitted)}
                        onChange={e => {
                          if (e.target.checked) {
                            handleSelect([...selectedRecipients, r]);
                          } else {
                            const filtered = selectedRecipients.filter(
                              ({ id }) => id !== r.id,
                            );
                            handleSelect(filtered);
                          }
                        }}
                        disabled={alreadySubmitted || r.send_error}
                      />
                    </TableCell>
                  )}
                  <TableCell>
                    <Stack spacing={1}>
                      <Typography variant='body2'>
                        {r.first_name} {r.last_name}
                      </Typography>
                      <Typography variant='body2'>{r.email}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align='center'>
                    {r.submission_id ? (
                      <CheckCircleOutlineIcon color='success' />
                    ) : (
                      <CancelOutlinedIcon color='error' />
                    )}
                  </TableCell>
                  <TableCell align='center'>
                    {/* TODO: unregistered emails */}
                    <CheckCircleOutlineIcon color='success' />
                  </TableCell>
                  <TableCell>
                    {r.send_error ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 8,
                        }}
                      >
                        <InfoIcon fontSize='small' color='error' />
                        <Typography variant='body2' color='error'>
                          Previous attempt failed to send
                        </Typography>
                      </div>
                    ) : (
                      <Typography variant='body2'>
                        {dayjs(r.last_sent).format('M/D/YYYY') ?? 'n/a'}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {recipientsList.length > PAGE_SIZE && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <StyledTablePagination
            page={+page}
            count={recipientsList.length}
            rowsPerPage={PAGE_SIZE}
            onPageChange={(_, value) => {
              setPage(value);
            }}
          />
        </Box>
      )}
    </>
  );
};

export { RecipientsTable };

const StyledTablePagination = styled(TablePagination)({
  '.MuiTablePagination-selectLabel': {
    display: 'none',
  },
  '.MuiTablePagination-input': {
    display: 'none',
  },
});
