import React, { FC } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { Button, DialogTitle } from '@src/components/sc-design-system';
import { PerformanceCycleFeedbackInvitesTable } from '@src/components/common/PerformanceCycleFeedbackInvitesTable';
import { useFullScreen } from '@src/hooks/useFullScreen';

interface FeedbackStatusModalProps {
  open: boolean;
  onClose: () => void;
  clientId: string;
  userId: string;
  userFirstName: string;
  userLastName: string;
  performanceCycleId: string;
  feedbackFormId: string;
  view?: 'client' | 'member';
}

const FeedbackStatusModal: FC<FeedbackStatusModalProps> = ({
  open,
  onClose,
  clientId,
  userId,
  userFirstName,
  userLastName,
  performanceCycleId,
  feedbackFormId,
  view,
}) => {
  const { fullScreen } = useFullScreen();
  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullScreen={fullScreen}>
      <DialogTitle
        title={
          <Stack gap={1}>
            <Typography variant='h3'>Feedback status</Typography>
            <Typography variant='subtitle2' color='grey.700'>
              For {userFirstName} {userLastName}
            </Typography>
          </Stack>
        }
        buttonOnClick={onClose}
      />
      <DialogContent>
        <PerformanceCycleFeedbackInvitesTable
          clientId={clientId}
          performanceCycleId={performanceCycleId}
          feedbackFormId={feedbackFormId}
          userId={userId}
          userFirstName={userFirstName}
          userLastName={userLastName}
          view={view}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} text='Close' />
      </DialogActions>
    </Dialog>
  );
};

export { FeedbackStatusModal };
