import React, { FC } from 'react';
import {
  Pie as AntdPie,
  PieConfig,
  Column as AntdColumn,
  ColumnConfig,
  G2,
} from '@ant-design/charts';

import {
  Bar as AntdBar,
  BarConfig as AntdBarProps,
  Bullet as AntdBullet,
  BulletConfig as AntdBulletProps,
  Gauge as AntdGauge,
  GaugeConfig as AntdGaugeProps,
  RingProgress as AntdRingProgress,
  RingProgressConfig as AntdRingProgressProps,
} from '@ant-design/plots';

const { registerTheme } = G2;
registerTheme('skillcycle', {
  colors10: [
    '#0EBFDA',
    '#ED1F80',
    '#FFC907',
    '#ED2224',
    '#80287E',
    '#79E6F6',
    '#5DC85D',
    '#C75B8F',
    '#5BBEC7',
    '#FF7171',
  ],
  colors20: [
    '#0EBFDA',
    '#ED1F80',
    '#FFC907',
    '#ED2224',
    '#80287E',
    '#79E6F6',
    '#5DC85D',
    '#C75B8F',
    '#5BBEC7',
    '#FF7171',
    '#0EBFDA',
    '#ED1F80',
    '#FFC907',
    '#ED2224',
    '#80287E',
    '#79E6F6',
    '#5DC85D',
    '#C75B8F',
    '#5BBEC7',
    '#FF7171',
  ],
});

export interface PieProps extends PieConfig {}

const Pie: FC<PieProps> = ({ ...props }) => {
  return <AntdPie {...props} theme='skillcycle' />;
};

export interface ColumnProps extends ColumnConfig {}

const Column: FC<ColumnProps> = ({ ...props }) => {
  return <AntdColumn {...props} theme='skillcycle' />;
};

export interface GaugeProps extends AntdGaugeProps {
  titleText: string;
  numerator: number;
}

const PercentageGauge: FC<GaugeProps> = ({
  titleText,
  numerator = 0,
  ...props
}) => {
  const config = {
    ...props,
    axis: {
      label: {
        formatter: v => `${v * 100}%`,
      },
      subTickLine: {
        count: 3,
      },
    },
    statistic: {
      content: {
        formatter: () => numerator.toFixed(0),
      },
      title: {
        formatter: () => titleText,
      },
    },
    theme: 'skillcycle',
    autoFit: true,
    width: props.width || 300,
    height: props.height || 300,
    padding: 0,
  };
  return (
    <AntdGauge className='ant-percentage-gauge' {...config} renderer='svg' />
  );
};

export interface RingProgressProps extends AntdRingProgressProps {
  titleText: string;
  percent: number;
}

const PercentageRing: FC<RingProgressProps> = ({
  titleText,
  percent,
  ...props
}) => {
  const config = {
    ...props,
    percent,
    statistic: {
      title: {
        formatter: () =>
          Number.isNaN(percent) ? '0%' : `${(percent * 100).toFixed(0)}%`,
      },
      content: {
        formatter: () => titleText,
      },
    },
    theme: 'skillcycle',
    autoFit: true,
    width: props.width || 300,
    height: props.height || 300,
    padding: 0,
  };
  return (
    <AntdRingProgress
      className='ant-percentage-ring'
      {...config}
      renderer='svg'
    />
  );
};

export interface BarProps extends AntdBarProps {}

const Bar: FC<BarProps> = ({ ...props }) => {
  const config = {
    ...props,
    theme: 'skillcycle',
  };
  return <AntdBar className='ant-bar' {...config} renderer='svg' />;
};

export interface BulletProps extends AntdBulletProps {}

const Bullet: FC<BulletProps> = ({ ...props }) => {
  const config = {
    ...props,
    theme: 'skillcycle',
  };
  return <AntdBullet className='ant-bullet' {...config} renderer='svg' />;
};

export { Bar, Bullet, Pie, Column, PercentageGauge, PercentageRing };
