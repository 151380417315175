import React, { FC, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { Button, DialogTitle } from '@src/components/sc-design-system';
import SurveyQuestion from '@src/models/SurveyQuestion';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import { api } from '@src/lib/client';
import { Tree } from 'antd';

const AddCompetenciesModal: FC<{
  clientId: string;
  surveyQuestions: any[];
  setSurveyQuestions: Function;
  setCompetenciesModalOpen: Function;
}> = observer(
  ({
    clientId,
    surveyQuestions,
    setSurveyQuestions,
    setCompetenciesModalOpen,
  }) => {
    const [competencySets, setCompetencySets] = useState([]);
    const [competencies, setCompetencies] = useState([]);
    const [selectedCompetencies, setSelectedCompetencies] = useState(new Set());
    useEffect(() => {
      const getCompetencySets = async () => {
        const competency_sets = (
          await api.goals_competencies.getSets('client', clientId)
        ).data[0]?.competency_sets;
        if (!competency_sets) return;
        setCompetencySets(competency_sets);
        const _competencies = [];
        competency_sets.map(cs =>
          cs.competencies.map(c => _competencies.push(c)),
        );
        setCompetencies(_competencies);
      };
      getCompetencySets();
    }, [clientId]);
    return (
      <Dialog
        maxWidth='sm'
        open={true}
        onClose={() => setCompetenciesModalOpen(false)}
      >
        <DialogTitle
          title='Add Competencies and Values'
          buttonOnClick={() => setCompetenciesModalOpen(false)}
        />
        <DialogContent>
          <Tree
            checkable
            expandedKeys={competencySets.map(cs => cs.id)}
            selectable={false}
            onCheck={(_, e) => {
              if (e.checked) {
                // This is a set, add all competencies
                if (e.node.children) {
                  e.node.children.map(c => {
                    selectedCompetencies.add(c.key);
                  });
                  // This is a competency, add it
                } else {
                  selectedCompetencies.add(e.node.key);
                }
              } else {
                // This is a set, remove all competencies
                if (e.node.children) {
                  e.node.children.map(c => {
                    selectedCompetencies.delete(c.key);
                  });
                  // This is a competency, remove it
                } else {
                  selectedCompetencies.delete(e.node.key);
                }
              }
              setSelectedCompetencies(selectedCompetencies);
            }}
            treeData={competencySets.map(cs => {
              return {
                title: cs.name,
                key: cs.id,
                children: cs.competencies.map(c => {
                  return {
                    title: c.title,
                    key: c.id,
                  };
                }),
              };
            })}
          />
        </DialogContent>
        <DialogActions>
          <Button
            text='Cancel'
            variant='outlined'
            onClick={() => setCompetenciesModalOpen(false)}
          />
          <Button
            text='OK'
            formatText={false}
            onClick={() => {
              const groups = [];
              competencies
                .filter(c => selectedCompetencies.has(c.id))
                .map(c => {
                  const questions = [];
                  const competencySet = competencySets.find(
                    cs => cs.id === c.competency_set_id,
                  );
                  c.indicators.map(i => {
                    questions.push(
                      new SurveyQuestion({
                        id: nanoid(),
                        client_id: clientId,
                        type: 'range',
                        range_maximum: competencySet.range_maximum || 10,
                        range_labels: competencySet.range_labels,
                        title: i.title,
                        required: false,
                        meta_data: {
                          competency_set_id: competencySet.id,
                          competency_id: c.id,
                          statement_id: i.id,
                        },
                      }),
                    );
                  });
                  questions.push(
                    new SurveyQuestion({
                      id: nanoid(),
                      client_id: clientId,
                      type: 'textarea',
                      title: 'Please share any additional comments below.',
                      required: false,
                      meta_data: {
                        competency_set_id: competencySet.id,
                        competency_id: c.id,
                      },
                    }),
                  );
                  const group = {
                    id: nanoid(),
                    type: 'competency',
                    meta_data: {
                      competency_set_id: competencySet.id,
                      competency_id: c.id,
                    },
                    title: c.title,
                    helpText: competencySet.range_labels?.some(
                      rl => rl.text.length,
                    )
                      ? 'For each statement, please select a rating from the options below'
                      : `For each statement, please select a value where 1 is strongly disagree and ${
                          competencySet.range_maximum || 10
                        } is strongly agree`,
                    questions,
                  };
                  groups.push({ ...group, group });
                });
              const groupQuestions = groups.map(g => {
                const groupQ = new SurveyQuestion(g);
                return groupQ;
              });
              setSurveyQuestions([...surveyQuestions, ...groupQuestions]);
              setCompetenciesModalOpen(false);
            }}
          />
        </DialogActions>
      </Dialog>
    );
  },
);

export { AddCompetenciesModal };
