import { HTTPClient, APIResponse } from '@src/lib/client';
import { Administration } from '@shared/schemas';
import { EngagementAdministration } from '@shared/lib/src/engagement';

export interface AdministrationKey {
  user_email: string;
  program_id: string;
}

export interface RecordNoteParams extends AdministrationKey {
  content: string;
}

export interface SetPriorityParams extends AdministrationKey {
  priority: boolean;
}

export interface EngagementAssessmentStakeholder {
  email: string;
  added?: number;
  sent?: number;
  sentReminder?: number;
  surveySubmissionId?: string;
}

export interface UserEngagementRecord {
  userId: string;
  name: string;
  email: string;
  coachName: string;
  lastActivity: Date;
  clientId: string;
  clientName: string;
  programId: string;
  programName: string;
  status: string;
  attentionNeeded: string;
  missedInterviews: number;
  missedSessions: number;
  midpointEngagementSurvey: any;
  endpointEngagementSurvey: any;
  lastCompletedSession: number;
  selectedCoachAt: number;
  lastCompletedInterview: number;
  joinedAt: number;
  invitedAt: number;
  administration: EngagementAdministration;
  assessmentStakeholders: EngagementAssessmentStakeholder[];
}

export class EngagementAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async getData(clientId: string): APIResponse<UserEngagementRecord[]> {
    const response = await this.http.request({
      url: `/admin/engagement/${clientId}`,
      method: 'get',
    });
    response.data = response.data.map(json => {
      const lastActivity =
        json.lastCompletedSession ||
        json.selectedCoachAt ||
        json.lastCompletedInterview ||
        json.joinedAt ||
        json.invitedAt;

      json.lastActivity = lastActivity ? new Date(lastActivity) : null;
      return json;
    });
    return response;
  }

  async recordNote(params: RecordNoteParams): APIResponse<Administration> {
    const response = await this.http.request({
      url: '/admin/notes',
      method: 'put',
      data: params,
    });
    return response;
  }

  async setPriority(params: SetPriorityParams): APIResponse<Administration> {
    const response = await this.http.request({
      url: '/admin/priority',
      method: 'put',
      data: params,
    });
    return response;
  }
}
