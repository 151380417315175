import React, { FC, useEffect, useRef } from 'react';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  VisualMapComponent,
  ToolboxComponent,
  TooltipComponent,
} from 'echarts/components';
import { BarChart, HeatmapChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import echartsTheme from './EChartsTheme';

import './ECharts.less';

echarts.registerTheme('skillcycle', echartsTheme);
echarts.use([
  GridComponent,
  BarChart,
  HeatmapChart,
  CanvasRenderer,
  VisualMapComponent,
  ToolboxComponent,
  TooltipComponent,
]);

export interface EChartProps {
  className?: string;
  height?: string;
  loading?: boolean;
  Title?: React.ReactNode;
  options: any;
  width: string;
  chartType: string;
}

function truncateLabel(label) {
  const maxLength = 50;
  const i = label.indexOf(' ', maxLength);
  return i === -1 ? label : label.substring(0, i) + '...';
}

export const EChart: FC<{ props: EChartProps }> = ({ props }) => {
  const { className, height, loading, Title, options, width, chartType } =
    props;
  const chartDom = useRef(null);

  useEffect(() => {
    let chart: echarts.ECharts | undefined;
    if (chartDom.current !== null) {
      chart = echarts.init(chartDom.current, 'skillcycle', {
        renderer: 'canvas',
      });
    }

    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener('resize', resizeChart);

    return () => {
      chart?.dispose();
      window.removeEventListener('resize', resizeChart);
    };
  }, []);

  useEffect(() => {
    const renderData = Object.assign({}, options);
    delete renderData.title;
    if (chartDom.current !== null) {
      const chart = echarts.getInstanceByDom(chartDom.current);
      // the api will not return functions so the formatting has to happen on the web app
      if (chartType === 'histogram') {
        renderData.xAxis.axisLabel = {
          ...renderData.xAxis.axisLabel,
          formatter: truncateLabel,
        };
        renderData.tooltip = {
          ...renderData.tooltip,
          formatter: function ({ marker, data, name }) {
            const stats = `${data.value}%<br/>${data.count} resp.`;
            if (!isNaN(name)) {
              // label is numeric
              return `<div style="display:flex;justify-content:space-between;"><span>${marker} ${name}</span><span style="text-align:right;line-height: 1.25">${stats}</span></div>`;
            }

            return `${marker} ${name}<div style="text-align:right;margin-top: 20px; line-height:1.25">${stats}</div>`;
          },
        };
        renderData.series[0].label = {
          ...renderData.series[0].label,
          formatter: function ({ data }) {
            const { value, count } = data;
            if (value === '0') {
              return '0%';
            }
            return `{percentage|${value}%} \n\n {resp|${count} resp.}`;
          },
          align: 'center',
          lineHeight: 10,
          rich: {
            percentage: {
              fontSize: 16,
            },
            resp: {
              fontSize: 13,
            },
          },
        };
      }
      if (chartType === 'bar') {
        renderData.yAxis.axisLabel = {
          ...renderData.yAxis.axisLabel,
          formatter: truncateLabel,
        };
        renderData.series[0].label = {
          ...renderData.series[0].label,
          formatter: function ({ data }) {
            if (data === '0') {
              return '0%';
            }
            return `${data}%`;
          },
        };
        renderData.tooltip = {
          ...renderData.tooltip,
          valueFormatter: value => value + '%',
        };
      }
      if (chartType === 'heatmap') {
        renderData.series[0].label = {
          ...renderData.series[0].label,
          formatter: function ({ data }) {
            // -99 will be returned if there is no responses for a role type
            if (data[2] === -99) {
              return '-';
            }
            return data[2];
          },
        };
      }
      chart && chart.setOption(renderData);
    }
  }, [options, chartType]);

  useEffect(() => {
    if (chartDom.current !== null) {
      const chart = echarts.getInstanceByDom(chartDom.current);
      if (chart) {
        loading === true ? chart.showLoading() : chart.hideLoading();
      }
    }
  }, [loading]);

  // if height is not passed, calculate the height based on the number of items in the yaxis so the height grows dynamically
  let chartHeight = height;
  if (!chartHeight) {
    chartHeight = options.yAxis.data.length * 115 + 25 + 'px';
  }

  return (
    <div className='sc-chart'>
      {Title || null}
      <div
        className={`sc-chart-content ${className || ''}`}
        style={{ width, height: chartHeight }}
        ref={chartDom}
      />
    </div>
  );
};
