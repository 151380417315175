import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';

obey.modifier('now', () => Date.now());
obey.creator('uuid', () => uuidv4());

export const ClientSchema = {
  id: { type: 'string', required: true, creator: 'uuid' },
  name: { type: 'string', required: true },
  salesforce_id: { type: 'string' },
  email_domain: { type: 'string' },
  website: { type: 'string' },
  logo_image: { type: 'string' },
  addresses: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        type: { type: 'string', allow: ['billing', 'mailing', 'all'] },
        street_address_1: { type: 'string' },
        street_address_2: { type: 'string', empty: true },
        city: { type: 'string' },
        state: { type: 'string' },
        zip: { type: 'string' },
        country: { type: 'string' },
      },
    },
    empty: true,
    default: [],
  },
  members: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        id: { type: 'string', required: true },
        role: {
          type: 'string',
          allow: ['manager', 'member'],
          default: 'member',
        },
        joinedAt: { type: 'number' },
        removedAt: { type: 'number' },
        is_primary_contact: { type: 'boolean', default: false },
        department: {
          type: 'object',
          keys: {
            id: { type: 'string' },
            is_department_manager: { type: 'boolean', default: false },
          },
        },
        manager: { type: 'string' },
        direct_reports: {
          type: 'array',
          values: { type: 'string' },
          empty: true,
        },
        title: { type: 'string', empty: true },
      },
    },
    empty: true,
    default: [],
  },
  mentors: {
    type: 'array',
    values: { type: 'string' },
    empty: true,
    default: [],
  },
  invitees: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        invitee_first_name: { type: 'string', empty: true },
        invitee_last_name: { type: 'string', empty: true },
        invitee_email: { type: 'string' },
        invitedAt: { type: 'number' },
        revokedAt: { type: 'number' },
        joinedAt: { type: 'number' },
        lastRemindedAt: { type: 'number' },
      },
    },
    empty: true,
  },
  default_sow_id: { type: 'any', default: false },
  is_verified: { type: 'boolean', default: true },
  is_active: { type: 'boolean', default: true },
  performance_cycles: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        id: { type: 'string', creator: 'uuid' },
        name: { type: 'string', required: true },
        start: { type: 'number', required: true },
        end: { type: 'number', required: true },
        self_evaluation_start: { type: 'number' },
        self_evaluation_end: { type: 'number' },
        manager_evaluation_start: { type: 'number' },
        manager_evaluation_end: { type: 'number' },
        self_evaluation_form_id: { type: 'string' },
        manager_evaluation_form_id: { type: 'string' },
        ongoing_feedback_form_id: { type: 'string' },
        release_evaluations: { type: 'boolean' },
        rating_scale_labels: {
          type: 'array',
          values: {
            type: 'object',
            keys: {
              value: { type: 'number' },
              text: { type: 'string', empty: true },
            },
          },
        },
        goal_cycles: {
          type: 'array',
          values: {
            type: 'object',
            keys: {
              id: { type: 'string', creator: 'uuid' },
              name: { type: 'string', required: true },
              start: { type: 'number', creator: 'now' },
              end: { type: 'number' },
              description: { type: 'string', required: true },
              goal_weighting_enabled: { type: 'boolean', default: false },
              goal_submission_deadline: { type: 'number' },
              goal_edit_deadline: { type: 'number' },
              goal_evaluation_start: { type: 'number' },
              goal_evaluation_end: { type: 'number' },
            },
          },
          default: [],
          empty: true,
        },
      },
    },
    default: [],
    empty: true,
  },
  competency_sets: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        id: { type: 'string', creator: 'uuid' },
        description: { type: 'string' },
        name: { type: 'string', required: true },
        competency_label: {
          type: 'string',
          allow: ['competency', 'value'],
          default: 'competency',
        },
        range_maximum: { type: 'number', default: 10 },
        range_labels: {
          type: 'array',
          values: {
            type: 'object',
            keys: {
              value: { type: 'number' },
              text: { type: 'string', empty: true, allowNull: true },
            },
          },
        },
      },
    },
    default: [],
    empty: true,
  },
  departments: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        id: { type: 'string', creator: 'uuid' },
        name: { type: 'string', required: true, empty: true },
      },
    },
    default: [],
    empty: true,
  },
  hris_enabled: { type: 'boolean', default: false },
  aida_enabled: { type: 'boolean', default: false },
  merge_account_token: { type: 'string' },
  merge_last_modified: { type: 'number' },
  merge_hris_data: {
    type: 'array',
    values: { type: 'object' },
    default: [],
    empty: true,
  },
  aida_terms_accepted: {
    type: 'object',
    keys: {
      accepted_on: { type: 'number', required: true },
      admin_uuid: { type: 'string', required: true },
    },
    allowNull: true,
  },
};

// eslint-disable-next-line no-unused-vars
export type ClientType = { [K in keyof typeof ClientSchema]?: any };
export interface ClientMemberType {
  id: string;
  role: 'manager' | 'member';
}

export const ClientModel = obey.model(ClientSchema);
