import React from 'react';
import { Typography } from '@mui/material';
import { Card } from '@src/components/sc-design-system';

interface EmptyStateProps {
  title?: string;
  body?: string;
  EmptyStateActionArea?: React.ReactNode;
}

const EmptyState: React.FunctionComponent<EmptyStateProps> = props => {
  const { title, body, EmptyStateActionArea = null } = props;

  return (
    <Card sx={{ textAlign: 'center' }}>
      {title && (
        <Typography variant='h4' component='h2' sx={{ marginBottom: '.25em' }}>
          {title}
        </Typography>
      )}
      {body && (
        <Typography
          variant='body2'
          color='grey.700'
          sx={{ marginBottom: '1.5em' }}
        >
          {body}
        </Typography>
      )}
      {EmptyStateActionArea}
    </Card>
  );
};

export { EmptyState };
