import { HTTPClient } from '@src/lib/client';
import { message } from 'antd';
import axios from 'axios';

export class FilesAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async getUploadUrl(fileName: string) {
    return this.http.request({
      url: `/files/signed-upload-url?file-name=${fileName}`,
      method: 'get',
    });
  }

  async getDownloadUrl(key: string) {
    return this.http.request({
      url: `/files/signed-download-url?key=${key}`,
      method: 'get',
    });
  }

  async uploadFile(signedUrl: string, file: any, contentType: string) {
    try {
      return await axios.put(signedUrl, file, {
        headers: {
          'Content-Type': contentType,
        },
      });
    } catch (e) {
      message.error('Could not upload file');
    }
  }

  async deleteFile(fileKey: any) {
    return this.http.request({
      url: `/files/delete`,
      method: 'delete',
      data: { key: fileKey },
    });
  }
}
