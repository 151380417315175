import React from 'react';
import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import InputLabel from '@mui/material/InputLabel';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

// TODO: the usage of these exports should eventually be replaced with themed MUI components

export const StyledLabel = styled(InputLabel)({
  fontSize: '16px',
  fontWeight: 500,
  marginBottom: '.5em',
  letterSpacing: '.03em',
  color: '#000000',
  lineHeight: '22px',
});

export const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '4px',
  boxShadow: 'none',
  padding: '1.5em',
  fontSize: 16,
  backgroundColor: theme.palette.grey[50],
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'white',
    color: 'black',
    fontSize: 14,
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: 12,
    '& .MuiTooltip-arrow': {
      '&:before': {
        border: `1px solid ${theme.palette.grey[200]}`,
      },
      color: 'white',
    },
  },
}));
