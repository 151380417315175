import { HTTPClient } from '@src/lib/client';

export class LoggerAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async send(data) {
    return this.http.request({
      url: `/logger/creat`,
      method: 'post',
      data,
    });
  }
}
