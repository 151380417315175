import React from 'react';
import { Button } from '@src/components/sc-design-system';
import { CreatePerformanceCycleModal } from '@src/components/views/MyBusiness/Performance/tabs/PerformanceCycles/modals/CreatePerformanceCycleModal';
import AddIcon from '@mui/icons-material/Add';

const AddPerformanceCycleButton: React.FunctionComponent<{
  performanceCycleNames: string[];
}> = ({ performanceCycleNames }) => {
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <>
      <CreatePerformanceCycleModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        performanceCycleNames={performanceCycleNames}
      />
      <Button
        sx={{ width: 'fit-content' }}
        text='Add performance cycle'
        variant='outlined'
        startIcon={<AddIcon />}
        size='small'
        onClick={() => setOpenModal(true)}
      />
    </>
  );
};

export { AddPerformanceCycleButton };
