import React, { useState, FC, useEffect, useMemo } from 'react';
import User from '@src/models/User';
import {
  Button,
  useToast,
  DialogTitle,
  Select,
} from '@src/components/sc-design-system';
import { observer } from 'mobx-react';
import * as useVerbiage from '@shared/lib/src/use-verbiage';
import { useNavigate } from 'react-router';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import PersonCheck from '@mui/icons-material/HowToRegOutlined';
import { useUser } from '@src/hooks/useUser';
import { useUserQueries } from '@src/hooks/useUserQueries';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

const SelectCoach: FC<{
  user: User;
  coach: any;
  programType?: string;
  programId?: string;
}> = ({ coach, programType, programId }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { user } = useUser();
  const { useGetUserPrograms } = useUserQueries(user.id);
  const { userPrograms, isLoading: isLoadingPrograms } =
    useGetUserPrograms('coachee');
  const [selectedProgram, setSelectedProgram] = useState<string>();
  // this will either default to what comes into the component ie via querystring on parent page
  // or will be set based on what is selected from the drop down
  const [selectedProgramType, setSelectedProgramType] = useState(programType);
  const [saving, setIsSaving] = useState(false);

  const availablePrograms = useMemo(() => {
    if (isLoadingPrograms) return [];
    return (
      userPrograms?.filter(p => {
        return (
          !p.coach &&
          p.type !== 'platform_only' &&
          p.is_active &&
          dayjs().isAfter(p.start_date) &&
          dayjs().isBefore(p.end_date)
        );
      }) ?? []
    );
  }, [isLoadingPrograms, userPrograms]);

  useEffect(() => {
    setSelectedProgram(programId || availablePrograms[0]?.id);
  }, [open, coach, user.id, availablePrograms, programId]);

  useEffect(() => {
    if (availablePrograms.length > 0) {
      const program = availablePrograms.find(p => p.id === selectedProgram);
      setSelectedProgramType(program?.type);
    }
  }, [selectedProgram, availablePrograms]);

  const v = useVerbiage.init(
    selectedProgramType === 'mentoring' ? ['isMentor'] : [],
  );
  return availablePrograms.length > 0 && coach ? (
    <>
      <Dialog maxWidth='sm' open={open} onClose={() => setOpen(false)}>
        <DialogTitle
          title={`Select ${v('coach', true)}`}
          buttonOnClick={() => setOpen(false)}
          buttonIsDisabled={saving}
        />
        <DialogContent>
          {availablePrograms.length === 1 ? (
            <Typography variant='body2' sx={{ marginBottom: '1.35em' }}>
              Would you like to select {coach.first_name} as your {v('coach')}{' '}
              for {availablePrograms[0].name}?
            </Typography>
          ) : (
            <>
              <Typography variant='body2' sx={{ marginBottom: '1.35em' }}>
                Please select which program you would like to select{' '}
                {coach.first_name} for:
              </Typography>
              <Select
                fullWidth
                value={selectedProgram}
                onChange={e => setSelectedProgram(e.target.value as string)}
                options={[
                  {
                    options: availablePrograms.map(p => ({
                      label: p.name,
                      value: p.id,
                    })),
                  },
                ]}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            text={`Select ${coach.first_name}`}
            onClick={async () => {
              setIsSaving(true);
              try {
                await user.selectProgramCoach(coach.id, selectedProgram);
                setIsSaving(false);
                setOpen(false);
                navigate('/my-programs');
                queryClient.invalidateQueries({
                  queryKey: ['user', user.id, 'programs', 'coachee'],
                });
              } catch (e) {
                setIsSaving(false);
                toast.error(
                  `Could not select ${v('coach')}, please contact support`,
                );
              }
            }}
            loading={saving}
            disabled={saving}
          />
        </DialogActions>
      </Dialog>
      <Button
        size='small'
        onClick={() => setOpen(true)}
        startIcon={<PersonCheck />}
        text='Select'
      />
    </>
  ) : null;
};

export default observer(SelectCoach);
