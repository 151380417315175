import React from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'antd';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { DirectReport } from '@src/components/views/TeamManagement/types';
import { ActiveClientKey, useActiveClient } from '@src/hooks/useActiveClient';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { useNavigation } from '@src/navigation';
import {
  Button,
  Table,
  useBreadcrumbs,
} from '@src/components/sc-design-system';

interface DirectReportTeamProps {
  userId: string;
  firstName: string;
  lastName: string;
}

interface DirectReportTeamHeaderProps {
  userId: string;
  firstName: string;
  lastName: string;
  setShowDirectReportTeamTable: (s: undefined | string) => void;
  showDirectReportTeamTable: undefined | string;
}

function DirectReportTeamHeader({
  userId,
  firstName,
  lastName,
  setShowDirectReportTeamTable,
  showDirectReportTeamTable,
}: DirectReportTeamHeaderProps) {
  const navigation = useNavigation();
  const { appendBreadcrumb } = useBreadcrumbs();
  return (
    <Row align='middle' justify='space-between' style={{ marginBottom: 0 }}>
      <Col>
        {showDirectReportTeamTable === userId ? (
          <Button
            onClick={() => setShowDirectReportTeamTable(undefined)}
            text={`Hide ${firstName}’s Team`}
            variant='text'
            startIcon={<RemoveCircleOutline />}
            size='small'
            sx={{ marginBottom: '.5em' }}
          />
        ) : (
          <Button
            onClick={() => setShowDirectReportTeamTable(userId)}
            text={`Show ${firstName}’s Team`}
            variant='text'
            startIcon={<AddCircleOutline />}
            size='small'
            sx={{ marginBottom: '.5em' }}
          />
        )}
      </Col>
      <Col>
        <Button
          onClick={() => {
            const path = `/team/${userId}`;
            navigation.push(path);
            appendBreadcrumb({
              path,
              label: `${firstName} ${lastName}'s Team`,
            });
          }}
          text={`View ${firstName}’s Team Dashboard`}
          variant='text'
          size='small'
        />
      </Col>
    </Row>
  );
}

interface DirectReportTeamTableProps {
  directReports: DirectReport[];
  isLoadingDirectReports: boolean;
}

function DirectReportTeamTable({
  directReports,
  isLoadingDirectReports,
}: DirectReportTeamTableProps) {
  const navigation = useNavigation();
  const { appendBreadcrumb } = useBreadcrumbs();
  const columns = [
    {
      title: 'Name',
      render: (text: string, dR: DirectReport) =>
        `${dR.first_name} ${dR.last_name}`,
      key: 'name',
    },
    {
      title: 'Role',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Team',
      dataIndex: 'department',
      key: 'team',
    },
    {
      title: 'Aligned Goals',
      dataIndex: 'aligned_goals_count',
      key: 'alignedGoals',
    },
    {
      title: 'Enrolled Programs',
      dataIndex: 'active_program_count',
      key: 'enrolledPrograms',
    },
    {
      title: 'Actions',
      render: (text: string, dR) => (
        <Stack direction='row' spacing={1}>
          <Tooltip title='View Profile'>
            <IconButton
              onClick={() => {
                const path = `/profile/${dR.id}`;
                navigation.push(path);
                appendBreadcrumb({
                  path,
                  label: `${dR.first_name} ${dR.last_name}`,
                });
              }}
            >
              <PersonOutlineIcon />
            </IconButton>
          </Tooltip>
          {dR.has_direct_reports && (
            <Tooltip title='View Team Dashboard'>
              <IconButton
                onClick={() => {
                  const path = `/team/${dR.id}`;
                  navigation.push(path);
                  appendBreadcrumb({
                    path,
                    label: `${dR.first_name} ${dR.last_name}'s Team`,
                  });
                }}
              >
                <PeopleOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      ),

      key: 'actions',
    },
  ];

  return (
    <Table
      rowKey='id'
      loading={isLoadingDirectReports}
      dataSource={directReports}
      columns={columns}
      hidePagination
      style={{ padding: '0 32px' }}
    />
  );
}

const DirectReportTeam: React.FunctionComponent<DirectReportTeamProps> =
  observer(({ userId, firstName, lastName }) => {
    const navigation = useNavigation();
    const [showDirectReportTeamTable, setShowDirectReportTeamTable] =
      React.useState<string | undefined>();

    const { client } = useActiveClient({
      queryKey: ActiveClientKey.profile_active_client_id,
    });

    const { useGetClientDirectReports } = useClientQueries(client?.id);
    const {
      data,
      isLoading: isLoadingDirectReports,
      error: fetchError,
    } = useGetClientDirectReports(userId);

    React.useEffect(
      function () {
        if ((fetchError as any)?.response?.status === 401) {
          navigation.replace('/performance-and-growth');
        }
      },
      [fetchError, navigation],
    );

    return (
      <>
        <DirectReportTeamHeader
          userId={userId}
          firstName={firstName}
          lastName={lastName}
          showDirectReportTeamTable={showDirectReportTeamTable}
          setShowDirectReportTeamTable={setShowDirectReportTeamTable}
        />
        {showDirectReportTeamTable === userId && (
          <DirectReportTeamTable
            directReports={data?.direct_reports}
            isLoadingDirectReports={isLoadingDirectReports}
          />
        )}
      </>
    );
  });

export default DirectReportTeam;
