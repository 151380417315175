import React, { FC } from 'react';
import { observer } from 'mobx-react';
import Program from '@src/models/Program';
import SOW from '@src/models/SOW';
import {
  DatePicker,
  Select,
  TextField,
} from '@src/components/sc-design-system';
import dayjs from 'dayjs';
import { Grid } from '@mui/material';

const ProgramEditorForm: FC<{
  currentProgram: Program;
  setIsValid: Function;
  sow: SOW;
  isAdmin: boolean;
}> = observer(({ currentProgram, setIsValid, sow, isAdmin }) => {
  return (
    <Grid container columns={1} direction='column' rowSpacing={2}>
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id='name'
            label='Program Name'
            placeholder='Program Name'
            value={currentProgram.name}
            onChange={e => {
              currentProgram.name = e.target.value;
              setIsValid(currentProgram.isValid);
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <Select
            name='is_active'
            label='Status'
            value={currentProgram.is_active}
            disabled={!isAdmin}
            onChange={e => {
              currentProgram.is_active = e.target.value === 'true';
            }}
            options={[
              {
                options: [
                  { label: 'Active', value: 'true' },
                  { label: 'Inactive', value: 'false' },
                ],
              },
            ]}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            name='type'
            label='Type'
            value={currentProgram.type}
            fullWidth
            onChange={e => {
              currentProgram.updateType(e.target.value);
              setIsValid(currentProgram.isValid);
            }}
            options={[
              {
                options: [
                  { label: 'Standard Coaching', value: 'standard_coaching' },
                  {
                    label: 'Custom Coach Selection',
                    value: 'custom_coach_selection',
                  },
                  { label: 'Mentoring', value: 'mentoring' },
                  { label: 'Platform-only', value: 'platform_only' },
                ].filter(
                  o =>
                    o.value === 'standard_coaching' ||
                    o.value === 'platform_only' ||
                    (o.value === 'custom_coach_selection' &&
                      sow.options?.custom_coach_selection) ||
                    (o.value === 'mentoring' && sow.options?.mentoring),
                ),
              },
            ]}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            name='start_date'
            label='Start Date'
            fullWidth
            disabled={!isAdmin}
            required
            onChange={v => {
              if (v?.isValid()) {
                currentProgram.start_date = v ? +v : null;
                setIsValid(currentProgram.isValid);
              }
            }}
            value={
              currentProgram.start_date
                ? dayjs(currentProgram.start_date)
                : null
            }
            minDate={dayjs(sow.start_date)}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label='End Date'
            name='end_date'
            fullWidth
            disabled={!isAdmin}
            required
            onChange={v => {
              if (v?.isValid()) {
                currentProgram.end_date = v
                  ? +v
                  : Date.now() + 1000 * 60 * 60 * 24 * 90;
                setIsValid(currentProgram.isValid);
              }
            }}
            value={
              currentProgram.end_date
                ? dayjs(currentProgram.end_date)
                : Date.now() + 1000 * 60 * 60 * 24 * 90
            }
            minDate={dayjs(sow.start_date).add(1, 'day')}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        {isAdmin && (
          <Grid item xs={3}>
            <TextField
              id='coach_rate'
              label='Coach rate'
              onChange={undefined}
              disabled
              value={sow?.coach_rate || 0}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={3}>
          {/* TODO: implement number input https://skillcycle.monday.com/boards/3345864855/pulses/5695570698 */}
          <TextField
            id='sessions_reserved'
            label='Sessions Reserved'
            placeholder='Sessions Reserved'
            type='number'
            value={currentProgram.sessions_reserved}
            disabled={!isAdmin || currentProgram.type === 'platform_only'}
            onChange={e => {
              const val = +e.target.value;
              if (val < 0) {
                currentProgram.sessions_reserved = 0;
              } else {
                currentProgram.sessions_reserved = +e.target.value;
              }
              setIsValid(currentProgram.isValid);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          {/* TODO: implement number input https://skillcycle.monday.com/boards/3345864855/pulses/5695570698 */}
          <TextField
            id='sessions_per_member'
            label='Sessions/Member'
            placeholder='Sessions Per Member'
            type='number'
            value={currentProgram.sessions_per_member}
            disabled={currentProgram.type === 'platform_only'}
            onChange={e => {
              const val = +e.target.value;
              if (val > +currentProgram.sessions_reserved) {
                currentProgram.sessions_per_member =
                  +currentProgram.sessions_reserved;
              } else if (val < 0) {
                currentProgram.sessions_per_member = 0;
              } else {
                currentProgram.sessions_per_member = val;
              }
              setIsValid(currentProgram.isValid);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            name='allowed_durations'
            label='Allowed Durations'
            value={currentProgram.allowed_durations}
            onChange={e => {
              currentProgram.allowed_durations = e.target.value
                ? [e.target.value as number]
                : [];
            }}
            disabled={!isAdmin || (sow?.allowed_durations?.length ?? 0) <= 1}
            options={[
              {
                options:
                  sow?.allowed_durations
                    ?.slice()
                    .sort()
                    .map(ad => {
                      return {
                        label: `${ad}m`,
                        value: ad,
                      };
                    }) || [],
              },
            ]}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          id='description'
          label='Program Description'
          multiline
          rows={5}
          fullWidth
          value={currentProgram.description}
          onChange={e => {
            currentProgram.description = e.target.value;
          }}
        />
      </Grid>
    </Grid>
  );
});

export default ProgramEditorForm;
