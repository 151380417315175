import React, { useState } from 'react';
import { api } from '@src/lib/client';
import dayjs from 'dayjs';
import { Input, Timeline } from 'antd';
import {
  Button,
  useToast,
  DialogTitle,
} from '@src/components/sc-design-system';
import { Dialog, DialogContent, DialogActions, Divider } from '@mui/material';
import { UserEngagementRecord } from '@src/lib/client/apis/engagement';
import User from '@src/models/User';

const { TextArea } = Input;

import './EngagementRecordNotesModal.less';

export const EngagementRecordNotesModal = ({
  record,
  user,
  updateLocalRecord,
  onClose,
}: {
  record: UserEngagementRecord;
  user: User;
  updateLocalRecord: Function;
  onClose(): void;
}) => {
  const toast = useToast();
  const [notes, setNotes] = useState(record.administration?.notes || []);
  const [currentNote, setCurrentNote] = useState('');
  const [savingNote, setSavingNote] = useState(false);
  return (
    <Dialog onClose={onClose} open className='engagement-record-notes'>
      <DialogTitle
        title={`${record.name} ${
          record.programName ? '- ' + record.programName : ''
        }`}
        buttonOnClick={onClose}
      />
      <DialogContent>
        <Timeline mode='left'>
          {notes.length ? (
            notes?.map(n => {
              return (
                <Timeline.Item key={n.createdAt}>
                  <div className='timestamp'>
                    {dayjs(n.createdAt).format('lll')}
                  </div>
                  <div className='content'>{n.content}</div>
                  <span className='author'>
                    {n.createdBy.first_name} {n.createdBy.last_name}
                  </span>
                </Timeline.Item>
              );
            })
          ) : (
            <div className='no-notes'>No Notes</div>
          )}
        </Timeline>
        <Divider />
        <div className='notes-form'>
          <TextArea
            value={currentNote}
            onChange={e => {
              setCurrentNote(e.target.value);
            }}
          />
          <Button
            sx={{ marginTop: '1em' }}
            disabled={currentNote.length === 0 || savingNote}
            loading={savingNote}
            onClick={async () => {
              setSavingNote(true);
              try {
                await api.engagement.recordNote({
                  user_email: record.email,
                  program_id: record.programId,
                  content: currentNote,
                });

                const updatedNotes = [
                  {
                    content: currentNote,
                    createdAt: Date.now(),
                    createdBy: user,
                  },
                  ...(notes || []),
                ];

                updateLocalRecord(record.email, record.programId, {
                  administration: Object.assign({}, record.administration, {
                    notes: updatedNotes,
                  }),
                });
                setNotes(updatedNotes);
                setCurrentNote('');
              } catch (e) {
                toast.error('Unable to save note');
              }
              setSavingNote(false);
            }}
            text='Save Note'
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button text='Close' onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};
