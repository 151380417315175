import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, useToast } from '@src/components/sc-design-system';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { api } from '@src/lib/client';

import Send from '@mui/icons-material/SendOutlined';

interface InviteReminderProps {
  clientId: string;
  toRemind: Set<string>;
  setToRemind: (s: Set<string>) => void;
}

export default function InviteReminder(props: InviteReminderProps) {
  const { clientId, toRemind, setToRemind } = props;
  const toast = useToast();
  const { useGetClientMembers } = useClientQueries(clientId);
  const { members } = useGetClientMembers({
    include_invitees: true,
    include_hris: true,
  });

  const { mutate: sendInviteReminders, isPending } = useMutation({
    mutationFn: async () => {
      const emails = Array.from(toRemind);
      const reminders = emails.reduce((acc, email) => {
        const member = members.find(m => m.email === email);
        if (member) {
          acc.push({
            invitee_first_name: member?.first_name,
            invitee_last_name: member?.last_name,
            invitee_email: email,
          });
        }
        return acc;
      }, []);
      await api.clients.sendInviteeReminders(clientId, reminders);
    },
    onSuccess: () => {
      setToRemind(new Set());
      toast.success(
        <>
          You successfully reminded{' '}
          <b>
            {toRemind.size} {toRemind.size === 1 ? 'member' : 'members'}.
          </b>
        </>,
      );
    },
    onError() {
      toast.error('Unable to remind selected invitees');
    },
  });

  return (
    <Button
      variant='contained'
      size='small'
      startIcon={<Send />}
      text='Send Reminders'
      onClick={() => sendInviteReminders()}
      loading={isPending}
      disabled={toRemind.size === 0}
    />
  );
}
