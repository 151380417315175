import React, { useState, FC, useEffect } from 'react';
import { Form, Space, Row, Col } from 'antd';
import {
  Card,
  Button,
  InputField,
  SCLogo,
  useToast,
} from '@src/components/sc-design-system';
import { useTranslation } from 'react-i18next';
import { Divider, Link } from '@mui/material';

import Store from '@src/models/Store';

import '@src/lib/i18n.config';
import { observer } from 'mobx-react';
import { restoreIntendedRoute, useNavigation } from '@src/navigation';
import { ShellFooter } from '@src/components/app/Shell/AppShell';

interface LFProps {
  store: Store;
}

export let LoginForm: FC<LFProps> = ({ store }) => {
  const { t } = useTranslation([]);
  const navigation = useNavigation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isProcessingLogin, setIsProcessingLogin] = useState(false);
  const toast = useToast();

  const processLogin = async () => {
    setIsProcessingLogin(true);
    try {
      await store.user.signIn(email, password);
      await store.checkSession();
      setIsProcessingLogin(false);
      restoreIntendedRoute(navigation);
    } catch (e) {
      setIsProcessingLogin(false);
      toast.error(e.message);
    }
  };

  // TODO: rewrite the signup flow to not require this effect.
  useEffect(() => {
    if (store.user.session) {
      navigation.replace('/');
    } else if (store.session && location.pathname !== '/login') {
      restoreIntendedRoute(navigation);
    } else if (store.user.hasTempSignup) {
      navigation.push('/signup');
    } else if (store.user.hasTempPasswordRecovery) {
      navigation.push('/password-recovery');
    }
  }, [store, navigation, store.loading]);

  return (
    <Space direction='vertical'>
      <Card>
        <Row justify='center'>
          <Col>
            <SCLogo showText size={50} />
          </Col>
        </Row>
        <Divider />
        <Form layout='vertical'>
          <InputField
            label={t('Email Address')}
            rules={[{ required: true }]}
            value={email}
            onChange={setEmail}
            type='email'
          />
          <InputField
            label={t('Password')}
            rules={[{ required: true }]}
            type='password'
            value={password}
            onChange={setPassword}
            onKeyPress={(e: KeyboardEvent) => {
              if (e.key === 'Enter') processLogin();
            }}
          />
          <Row justify='center'>
            <Col>
              <Button
                disabled={isProcessingLogin}
                loading={isProcessingLogin}
                onClick={processLogin}
                text={t('Login')}
              />
            </Col>
          </Row>
          <Divider />
          <Row justify='center'>
            <Col>
              <Link variant='a2' href='/signup'>
                {t('Signup')}
              </Link>
            </Col>
            <Col>
              <Link variant='a2' href='/password-recovery'>
                {t('Forgot Password')}
              </Link>
            </Col>
          </Row>
        </Form>
      </Card>
      <ShellFooter />
    </Space>
  );
};

LoginForm = observer(LoginForm);
