import React, { FC, useState, useEffect } from 'react';
import { api } from '@src/lib/client';
import {
  AssistantButtons,
  AidaUserDisplayMessage,
} from '@src/components/views/Aida/AssistantButtons';
import { AidaPrompt } from '@src/components/views/Aida/AidaPrompt';
import { ActiveClientKey, useActiveClient } from '@src/hooks/useActiveClient';
import { useUser } from '@src/hooks/useUser';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Box, Divider } from '@mui/material';
import { PromptUpdateAlert } from './PromptUpdateAlert';
import { AidaHeader } from './AidaHeader';

const Aida: FC<{}> = () => {
  const queryClient = useQueryClient();
  const [existingAidaRequest, setExistingAidaRequest] =
    React.useState<AbortController>();
  const [aidaError, setAidaError] = React.useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState<Prompts>(null);
  const [userDisplayMessage, setUserDisplayMessage] = useState(null);
  const [assistantMessage, setAssistantMessage] = useState<AidaMessage>(null);
  const { client: userClient } = useActiveClient({
    queryKey: ActiveClientKey.profile_active_client_id,
  });
  const { user, useGetUserAidaConversations } = useUser();
  const {
    conversations,
    isLoading: isLoadingConversations,
    refetch: fetchConversations,
  } = useGetUserAidaConversations(userClient?.id);
  const activeClientMember = React.useMemo(
    function () {
      return user.clients.find(c => c.id === userClient?.id);
    },
    [user, userClient],
  );

  const { mutate: messageAida, isPending: isAssistantMessageLoading } =
    useMutation({
      mutationFn: async (prompt: Prompts) => {
        setAidaError(false);
        const axiosController = new AbortController();
        setExistingAidaRequest(axiosController);
        return api.integrations.messageAida(
          {
            userId: user?.id,
            firstName: user?.first_name,
            lastName: user?.last_name,
            prompt,
            clientId: userClient?.id,
            title: activeClientMember?.title,
            department: activeClientMember?.department,
            organization: activeClientMember?.name,
          },
          axiosController,
        );
      },
      onSuccess: async ({ data }) => {
        queryClient.invalidateQueries({
          queryKey: ['user', user?.id, 'conversations', userClient?.id],
        });
        setAssistantMessage(data);
        if (selectedPrompt === 'coaches') {
          // refresh the user mobx store so search_topics is current
          await user.getProfile();
        }
      },
      onError: err => err.message !== 'canceled' && setAidaError(true),
    });

  useEffect(() => {
    if (!selectedPrompt) {
      // Default to existing summary output if it exists
      if (conversations?.summary?.length) {
        const activeConversation = conversations.summary[0];
        setSelectedPrompt('summary');
        setUserDisplayMessage(AidaUserDisplayMessage.summary);
        setAssistantMessage({
          id: activeConversation?.id,
          summary: activeConversation?.output_text,
          data: activeConversation?.output_data,
          timestamp: activeConversation?.timestamp,
          additionalData: activeConversation?.additional_data,
        });
      }
    }
  }, [selectedPrompt, conversations]);

  const handlePromptSelection = async (
    prompt: Prompts,
    isUpdate = false,
    promptDisabled?: boolean,
  ) => {
    setAidaError(false);
    setSelectedPrompt(prompt);
    if (existingAidaRequest && existingAidaRequest?.abort) {
      existingAidaRequest.abort();
    }
    // input data requirements not met - user doesn't have enough data to proceed
    if (promptDisabled) return true;
    const isMessageAidaEnabled =
      isUpdate || (!isLoadingConversations && !conversations?.[prompt]?.length);
    if (isMessageAidaEnabled && prompt !== 'ask') {
      messageAida(prompt);
    } else if (isMessageAidaEnabled && prompt === 'ask') {
      // no-op
    } else {
      const promptConversations = conversations?.[prompt];
      const activeConversation = promptConversations?.[0];

      setAssistantMessage({
        id: activeConversation?.id,
        summary:
          activeConversation?.output_text ||
          activeConversation?.output_data?.summary,
        data:
          activeConversation?.output_data?.data ||
          activeConversation?.output_data,
        timestamp: activeConversation?.timestamp,
        additionalData: activeConversation?.additional_data,
      });
    }
  };

  return (
    <Box
      sx={{ height: '100%', paddingTop: '4em', backgroundColor: 'white.main' }}
    >
      <AidaHeader />
      <Box
        sx={{
          padding: { lg: '4em 5em 2em', xs: '4em 2em 2em' },
          backgroundColor: 'white.main',
          width: '100%',
          margin: '0 auto',
        }}
      >
        <AssistantButtons
          selectedPrompt={selectedPrompt}
          setAssistantMessage={setAssistantMessage}
          setUserDisplayMessage={setUserDisplayMessage}
          handlePromptSelection={handlePromptSelection}
          conversations={conversations}
        />
        <Divider
          sx={{
            marginLeft: { xs: '-2em', lg: '-5em' },
            marginRight: { xs: '-2em', lg: '-5em' },
          }}
        />
        {selectedPrompt && (
          <Box
            sx={{
              backgroundColor: 'white.main',
              width: '100%',
              maxWidth: 1400,
              margin: '0 auto',
              justifySelf: 'center',
            }}
          >
            {!isAssistantMessageLoading && (
              <PromptUpdateAlert
                conversations={conversations}
                onClickUpdate={() => {
                  handlePromptSelection('summary', true);
                  setUserDisplayMessage(AidaUserDisplayMessage.summary);
                  setAssistantMessage(null);
                }}
              />
            )}
            <AidaPrompt
              handlePromptSelection={handlePromptSelection}
              selectedPrompt={selectedPrompt}
              userDisplayMessage={userDisplayMessage}
              setUserDisplayMessage={setUserDisplayMessage}
              assistantMessage={assistantMessage}
              setAssistantMessage={setAssistantMessage}
              isAssistantMessageLoading={isAssistantMessageLoading}
              conversations={conversations}
              aidaError={aidaError}
              fetchConversations={fetchConversations}
              setAidaError={setAidaError}
              setExistingAidaRequest={setExistingAidaRequest}
              activeClientMember={activeClientMember}
              clientId={userClient?.id}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export { Aida };
