import React, { FC, useState } from 'react';
import { Button } from '@src/components/sc-design-system';
import { IconButton } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { SOWEditorModal } from './SOWEditorModal';

interface SOWEditorButtonProps {
  buttonType?: 'icon' | 'standard';
  disabled?: boolean;
  sow?: any;
}

const SOWEditorButton: FC<SOWEditorButtonProps> = ({
  buttonType = 'standard',
  disabled = false,
  sow,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      {buttonType === 'icon' ? (
        <IconButton onClick={() => setModalOpen(true)} disabled={disabled}>
          <EditOutlinedIcon fontSize='small' />
        </IconButton>
      ) : (
        <Button
          text='New SOW'
          onClick={() => setModalOpen(true)}
          size='small'
        />
      )}
      {modalOpen && (
        <SOWEditorModal onClose={() => setModalOpen(false)} sow={sow} />
      )}
    </>
  );
};

export { SOWEditorButton };
