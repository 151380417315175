import React, { useState, FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  Divider,
} from '@mui/material';
import { Avatar, DialogTitle, Button } from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import { useUserQueries } from '@src/hooks/useUserQueries';
import { handleKeyboardNav } from '@src/utils';
import { useFullScreen } from '@src/hooks/useFullScreen';
import CoacheeBioHeader from '@src/components/common/CoacheeBio/CoacheeBioHeader';
import CoacheeSearchTopics from '@src/components/common/CoacheeBio/CoacheeSearchTopics';
import CoacheeTabs from '@src/components/common/CoacheeBio/CoacheeTabs';

const getResults = async (
  id: string,
  setResults: Function,
  setLoadingResults: Function,
) => {
  setLoadingResults(true);
  const answersRes = await api.surveys.getSurveySubmission(
    id,
    '3636f28e-f057-4697-8cb4-4aa957131202',
  );
  if (answersRes?.data?.results) {
    setResults(answersRes.data.results);
  }

  setLoadingResults(false);
};

interface CoacheeBioProps {
  children: React.ReactNode;
  coachee: any;
  style?: any;
  hideSurveyResponses?: boolean;
  hideAida?: boolean;
}

const CoacheeBio: FC<CoacheeBioProps> = props => {
  const {
    children,
    coachee,
    style = {},
    hideSurveyResponses = false,
    hideAida = false,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [loadingResults, setLoadingResults] = useState(false);
  const [goals, setGoals] = useState([]);

  const programId = coachee.program_info?.id;
  const { fullScreen } = useFullScreen();
  const { useGetUserGoalDataForCoach } = useUserQueries(coachee.id);
  const {
    performanceData,
    personalGrowthData,
    isLoading: isUserGoalsLoading,
  } = useGetUserGoalDataForCoach(programId);

  useEffect(() => {
    if (isUserGoalsLoading) {
      return;
    }
    const personalGoals = personalGrowthData?.goal_cycles?.reduce((arr, gc) => {
      gc?.goals?.forEach(g => {
        if (g?.program_ids?.includes(programId)) {
          arr.push(g);
        }
      });
      return arr;
    }, []);
    const clientGoals = performanceData?.performance_cycles?.reduce(
      (arr, pc) => {
        pc?.goal_cycles?.forEach(gc => {
          gc?.goals?.forEach(g => {
            if (
              g?.user_ids?.includes(coachee.id) &&
              g?.program_ids?.includes(programId)
            )
              arr.push(g);
          });
        });
        return arr;
      },
      [],
    );
    setGoals([...(clientGoals || []), ...(personalGoals || [])]);
  }, [
    performanceData,
    personalGrowthData,
    isUserGoalsLoading,
    coachee,
    programId,
  ]);

  useEffect(() => {
    !hideSurveyResponses &&
      isOpen &&
      getResults(coachee.id, setResults, setLoadingResults);
  }, [isOpen, coachee.id, hideSurveyResponses]);

  return (
    <>
      <Dialog
        maxWidth='lg'
        fullScreen={fullScreen}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <DialogTitle
          buttonOnClick={() => {
            setIsOpen(false);
          }}
          title={
            <Stack direction='row' alignItems='center' spacing={2}>
              <Avatar
                srcId={coachee.id}
                alt={`${coachee.first_name} ${coachee.last_name}`}
              />
              <Typography variant='h3' sx={{ margin: 0 }}>
                {coachee.first_name} {coachee.last_name}
              </Typography>
            </Stack>
          }
        />
        <DialogContent>
          <Stack divider={<Divider orientation='horizontal' />}>
            <>
              <CoacheeBioHeader coachee={coachee} />
              <CoacheeSearchTopics coachee={coachee} isOpen={isOpen} />
            </>
            <CoacheeTabs
              results={results}
              goals={goals}
              isLoading={loadingResults || isUserGoalsLoading}
              hideSurveyResponses={hideSurveyResponses}
              programId={programId}
              isAidaActive={
                coachee.is_aida_active &&
                !hideAida &&
                coachee?.program_info?.is_active &&
                coachee?.program_info?.start_date <= Date.now() &&
                coachee?.program_info?.end_date > Date.now()
              }
              clientId={coachee.client_id}
              userId={coachee.id}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            text='Close'
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </DialogActions>
      </Dialog>
      <span
        role='button'
        tabIndex={0}
        className='coachee-bio-trigger'
        style={style}
        onClick={() => {
          setIsOpen(true);
        }}
        onKeyDown={e => handleKeyboardNav(e.key, () => setIsOpen(true))}
      >
        {children}
      </span>
    </>
  );
};

export default observer(CoacheeBio);
