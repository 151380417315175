import React, { FC, useCallback, useMemo, useState } from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';
import { Link, Stack, Typography } from '@mui/material';
import Add from '@mui/icons-material/Add';
import {
  Button,
  Avatar,
  IconMenuButton,
} from '@src/components/sc-design-system';
import { AlignedGoalsModal } from '@src/components/common/AlignedGoalsModal';
import { capitalizeAllWords } from '@shared/lib/src/utils';
import User from '@src/models/User';
import { useConfirm } from 'material-ui-confirm';
import GoalWeightIndicator from '../GoalCard/GoalWeightIndicator';
import GoalStatusIndicator from '../GoalCard/GoalStatusIndicator';
import { GoalTooltip } from '../GoalTooltip';

const getTableColumns = ({
  isEditable,
  entityType,
  cycle,
  isBeforeDeadline,
  onAddOrEditGoal,
  onGoalStatusUpdate,
  handleDeleteGoal,
  setSelectedGoalId,
  selectedGoalId,
  onClickGoalWeight,
  canManageGoalWeights,
  isClientGoal,
}) => {
  return [
    {
      title: 'Goal',
      dataIndex: 'title',
      key: 'title',
      render: (title, goal: Goal) => {
        const alignedGoals = cycle.goals?.filter(g => {
          return g.id === goal.aligned_goal_id || g.aligned_goal_id === goal.id;
        });
        const isUserGoal = Boolean(goal.user_ids?.length);

        return (
          <Stack gap={1}>
            <Typography variant='body2m'>{title}</Typography>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignItems={{ sm: 'center' }}
              spacing={1}
            >
              {entityType === 'user' && Boolean(alignedGoals.length) && (
                <GoalTooltip
                  type='aligned'
                  tooltipData={{ isUserGoal, alignedGoals }}
                />
              )}
              {isUserGoal && Boolean(goal.program_ids?.length) && (
                <GoalTooltip
                  type='shared-with-programs'
                  tooltipData={{ programCount: goal.program_ids.length }}
                />
              )}
              {entityType === 'client' && Boolean(alignedGoals.length) && (
                <Link
                  component='button'
                  variant='a2blank'
                  onClick={() => setSelectedGoalId(goal.id)}
                  sx={{
                    marginTop: '.5em',
                    display: 'flex',
                    gap: '0.5em',
                    alignItems: 'center',
                    '> span': {
                      color: 'secondary.500',
                    },
                  }}
                >
                  <div className='avatar-stack'>
                    {alignedGoals.slice(0, 3).map(g => (
                      <Avatar
                        key={`aligned-${g.id}`}
                        size='sm'
                        srcId={(g.user_ids[0] as unknown as User)?.id}
                      />
                    ))}
                  </div>
                  <span
                    style={{
                      color: '#ED1F80',
                      fontWeight: '500',
                      marginLeft:
                        alignedGoals.slice(0, 3).length === 3 ? '1em' : null,
                    }}
                  >{`${alignedGoals.length} Goal${
                    alignedGoals.length > 1 ? 's' : ''
                  } Aligned`}</span>
                </Link>
              )}
              {selectedGoalId === goal.id && (
                <AlignedGoalsModal
                  open={selectedGoalId ? true : false}
                  goal={goal}
                  alignedGoals={cycle.goals?.filter(g => {
                    return (
                      g.id === goal.aligned_goal_id ||
                      g.aligned_goal_id === goal.id
                    );
                  })}
                  handleClose={() => setSelectedGoalId(false)}
                />
              )}
            </Stack>
          </Stack>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '20%',
      render: type => capitalizeAllWords(type, '_'),
    },
    {
      title: entityType === 'user' ? 'Achieve by' : 'Achievement Date',
      dataIndex: 'target_date',
      key: 'target_date',
      render: target_date => {
        return `${dayjs(target_date).format('ll')}`;
      },
      width: '12%',
    },
    ...(entityType === 'user'
      ? [
          {
            title: 'Status',
            key: 'status',
            width: '12%',
            render: goal => {
              const currentStatus =
                goal.status_history?.[goal.status_history.length - 1];
              return <GoalStatusIndicator status={currentStatus?.status} />;
            },
          },
        ]
      : []),
    ...(entityType === 'user' &&
    canManageGoalWeights &&
    cycle.goal_weighting_enabled
      ? [
          {
            title: 'Goal weight',
            key: 'weight',
            width: '10%',
            render: goal => {
              return (
                <GoalWeightIndicator
                  weight={goal?.weight}
                  onClick={onClickGoalWeight}
                  isEditable={isEditable && isBeforeDeadline}
                />
              );
            },
          },
        ]
      : []),
    {
      title: 'Actions',
      key: 'actions',
      render: goal => {
        if (!isEditable) {
          const hasAddButton =
            entityType === 'user' &&
            isClientGoal &&
            !goal.user_ids?.length &&
            isBeforeDeadline;

          if (hasAddButton) {
            return (
              <Button
                onClick={() => onAddOrEditGoal('add', {}, cycle, goal.id)}
                startIcon={<Add />}
                variant='outlined'
                text='Add goal'
                size='small'
              />
            );
          }
          return null;
        }

        return (
          <IconMenuButton
            items={[
              ...(isBeforeDeadline
                ? [
                    {
                      key: 'edit',
                      label: 'Edit',
                      onClick: () => {
                        onAddOrEditGoal('edit', goal, cycle);
                      },
                    },
                  ]
                : []),
              ...(onGoalStatusUpdate
                ? [
                    {
                      key: 'goal_status',
                      label: 'Update Goal Status',
                      onClick: () =>
                        onGoalStatusUpdate(cycle, goal.id ? goal : {}),
                    },
                  ]
                : []),
              {
                key: 'delete',
                label: 'Delete',
                onClick: () => handleDeleteGoal(goal),
                disabled: !isBeforeDeadline,
              },
            ]}
          />
        );
      },
      width: '15%',
    },
  ];
};

interface GoalCycleListProps {
  cycle: GoalCycleWithGoals;
  entityType?: 'user' | 'client';
  canEditGoals?: boolean;
  canEditCycle?: boolean;
  onDeleteGoal?: (g: Goal) => void;
  onAddOrEditGoal?: (
    type: string,
    g: Goal | null,
    c?: GoalCycle,
    a?: string,
  ) => void;
  onGoalStatusUpdate?: (t: GoalCycle, g?: Goal, a?: string) => void;
  sections?: Goal[][];
  tabDefinitions?: Array<{
    label: string;
    emptyText?: string;
    isEditable?: boolean;
  }>;
  activeTab?: string;
  canManageGoalWeights?: boolean;
  onClickGoalWeight?: Function;
  performanceCycleId?: string;
}

const GoalCycleList: FC<GoalCycleListProps> = ({
  cycle,
  entityType,
  canEditGoals,
  sections = [],
  tabDefinitions,
  activeTab,
  onClickGoalWeight,
  canManageGoalWeights,
  performanceCycleId,
  onDeleteGoal,
  onAddOrEditGoal,
  onGoalStatusUpdate,
}) => {
  const isBeforeDeadline = dayjs().isSameOrBefore(cycle.goal_edit_deadline);
  const isClientGoal = entityType === 'client' || Boolean(performanceCycleId);

  const tabItems = useMemo(() => {
    if (sections.length <= 1) return [];
    return sections.map((goals, i) => {
      const isSectionEditable = tabDefinitions[i].isEditable;
      return {
        key: `${cycle.id}-section-${tabDefinitions[i].label}`,
        label: tabDefinitions[i].label,
        children:
          goals.length || isSectionEditable ? (
            <GoalCycleListLayout
              data={goals}
              cycle={cycle}
              entityType={entityType}
              onAddOrEditGoal={onAddOrEditGoal}
              onGoalStatusUpdate={onGoalStatusUpdate}
              isBeforeDeadline={isBeforeDeadline}
              onDeleteGoal={onDeleteGoal}
              isEditable={isSectionEditable}
              canAddGoals={isSectionEditable && isBeforeDeadline}
              onClickGoalWeight={onClickGoalWeight}
              canManageGoalWeights={isSectionEditable && canManageGoalWeights}
              isClientGoal={isClientGoal}
            />
          ) : (
            <div className='empty-section'>{tabDefinitions[i].emptyText}</div>
          ),
      };
    });
  }, [
    sections,
    tabDefinitions,
    cycle,
    isBeforeDeadline,
    onDeleteGoal,
    onAddOrEditGoal,
    onGoalStatusUpdate,
    entityType,
    onClickGoalWeight,
    canManageGoalWeights,
    isClientGoal,
  ]);

  if (tabItems.length > 1) {
    return (
      <>
        {tabItems.map(tab => (
          <div
            key={`${tab.key}-panel`}
            role='tabpanel'
            hidden={(activeTab || tabItems[0].label) !== tab.label}
          >
            {(activeTab || tabItems[0].label) === tab.label && tab.children}
          </div>
        ))}
      </>
    );
  }

  return (
    <GoalCycleListLayout
      data={sections[0]}
      onDeleteGoal={onDeleteGoal}
      cycle={cycle}
      entityType={entityType}
      onAddOrEditGoal={onAddOrEditGoal}
      isBeforeDeadline={isBeforeDeadline}
      onGoalStatusUpdate={onGoalStatusUpdate}
      isEditable={canEditGoals}
      canAddGoals={canEditGoals && isBeforeDeadline}
      onClickGoalWeight={onClickGoalWeight}
      canManageGoalWeights={canManageGoalWeights}
      isClientGoal={isClientGoal}
    />
  );
};

const GoalCycleListLayout = ({
  data,
  onDeleteGoal,
  cycle,
  entityType,
  onAddOrEditGoal,
  isBeforeDeadline,
  onGoalStatusUpdate,
  isEditable,
  canAddGoals,
  onClickGoalWeight,
  canManageGoalWeights,
  isClientGoal,
}) => {
  const [selectedGoalId, setSelectedGoalId] = useState<boolean | string>(false); // needs to accept a string (goal.id) so the correct aligned goal modal opens
  const goals = useMemo(() => {
    return data.map(goal => {
      return {
        ...goal,
        key: goal.id,
      };
    });
  }, [data]);
  const confirm = useConfirm();

  const handleDeleteGoal = useCallback(
    async (goal: Goal) => {
      try {
        await confirm({
          title: 'Delete this goal?',
          description: 'This action cannot be undone.',
          confirmationText: 'Delete',
          dialogProps: { maxWidth: 'xs' },
          cancellationButtonProps: {
            variant: 'outlined',
            className: 'shape-pill',
          },
          confirmationButtonProps: {
            className: 'shape-pill',
          },
        });
        onDeleteGoal(goal);
      } catch (e) {}
    },
    [confirm, onDeleteGoal],
  );

  const columns = useMemo(() => {
    return getTableColumns({
      isEditable,
      entityType,
      cycle,
      isBeforeDeadline,
      onAddOrEditGoal,
      onGoalStatusUpdate,
      handleDeleteGoal,
      setSelectedGoalId,
      selectedGoalId,
      onClickGoalWeight,
      canManageGoalWeights,
      isClientGoal,
    });
  }, [
    entityType,
    cycle,
    isBeforeDeadline,
    isEditable,
    selectedGoalId,
    onGoalStatusUpdate,
    onAddOrEditGoal,
    handleDeleteGoal,
    onClickGoalWeight,
    canManageGoalWeights,
    isClientGoal,
  ]);

  return (
    <div className='goal-cycle-list' style={{ overflow: 'scroll' }}>
      <Table
        dataSource={goals as unknown as Goal[]}
        pagination={false}
        columns={columns}
        expandable={{
          expandedRowRender: goal => (
            <div
              className='description'
              dangerouslySetInnerHTML={{ __html: goal.description }}
            />
          ),
        }}
        footer={() => {
          if (!canAddGoals) {
            return null;
          }
          return (
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={() => onAddOrEditGoal('add', null, cycle)}
                variant='outlined'
                text='Add Goal'
                startIcon={<Add />}
                size='small'
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export { GoalCycleList };
