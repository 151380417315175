import React from 'react';
import { Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { Button, DialogTitle } from '@src/components/sc-design-system';
import User from '@src/models/User';
import { RatingScaleBox } from '@src/components/common/RatingScaleBox';

interface ManagerRatingModalProps {
  setViewModal: (b: boolean) => void;
  viewModal: boolean;
  performanceCycle: PerformanceCycleI;
  profileUser: User;
  rating: number;
  ratingType: 'self' | 'manager' | 'report'; // self = user looking at their own vs report = manager looking at a user's
}

const ViewRatingModal: React.FunctionComponent<
  ManagerRatingModalProps
> = props => {
  const {
    setViewModal,
    viewModal,
    performanceCycle,
    profileUser,
    rating,
    ratingType,
  } = props;

  function handleClose() {
    setViewModal(false);
  }

  return (
    <Dialog open={viewModal} onClose={() => handleClose()}>
      <DialogTitle
        title={
          ratingType === 'manager'
            ? `Overall rating for ${profileUser.first_name}`
            : ratingType === 'report'
            ? `${profileUser.first_name}’s self-evaluation`
            : 'Your submitted rating'
        }
      />
      <DialogContent dividers>
        <Stack direction='row' justifyContent='center' spacing={2}>
          {performanceCycle.rating_scale_labels.map(({ text, value }) => (
            <RatingScaleBox
              key={value}
              text={text}
              value={value}
              variant={value === rating ? 'contained' : 'disabled'}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ marginLeft: '.5em' }}
          key='close'
          text='Close'
          onClick={() => setViewModal(false)}
        />
      </DialogActions>
    </Dialog>
  );
};

export { ViewRatingModal };
