import React from 'react';
import { Stack, Typography } from '@mui/material';

import { AidaFooter } from './AidaFooter';
import { ChatBubble } from './ChatBubble';
import {
  ErrorState,
  AidaExperimentWarning,
  AnimatedThinkingIcon,
} from './helper';
import { CompleteStepsAlert } from './CompleteStepsAlert';

interface GuidedAidaProps {
  selectedPrompt: Prompts;
  handlePromptSelection: HandlePromptSelection;
  setUserDisplayMessage: (m: string) => void;
  userDisplayMessage: string;
  assistantMessage: AidaMessage;
  setAssistantMessage: (m: AidaMessage) => void;
  isAssistantMessageLoading: boolean;
  aidaError: boolean;
  setAidaError: (b: boolean) => void;
  conversations: Conversations;
  fetchConversations: Function;
  followUpContent: React.ReactNode;
  initialResponse: React.ReactNode;
  footerContent: React.ReactNode;
  shareWith: ShareWith[];
  shareCount: number;
}

export function GuidedAida(props: GuidedAidaProps) {
  const {
    selectedPrompt,
    handlePromptSelection,
    setUserDisplayMessage,
    userDisplayMessage,
    assistantMessage,
    setAssistantMessage,
    isAssistantMessageLoading,
    aidaError,
    setAidaError,
    conversations,
    fetchConversations,
    followUpContent,
    initialResponse,
    footerContent,
    shareWith,
    shareCount,
  } = props;

  const completeStepsAlerts = {
    focus_areas: !conversations?.summary,
    resources: !conversations?.summary || !conversations?.focus_areas,
    // coaches will be handled in its own follow up component
  };
  const showCompleteStepsAlert = completeStepsAlerts[selectedPrompt];
  return (
    <>
      {showCompleteStepsAlert !== undefined && showCompleteStepsAlert ? (
        <CompleteStepsAlert
          selectedPrompt={selectedPrompt}
          conversations={conversations}
          handlePromptSelection={handlePromptSelection}
          setUserDisplayMessage={setUserDisplayMessage}
          setAssistantMessage={setAssistantMessage}
        />
      ) : (
        <>
          <Stack
            spacing={3}
            display='grid'
            marginTop='2em'
            id='aida-download-area'
          >
            {userDisplayMessage && (
              <ChatBubble
                sender='user'
                children={
                  <Typography variant='body1'>{userDisplayMessage}</Typography>
                }
              />
            )}
            {assistantMessage && !isAssistantMessageLoading && (
              <>
                {!!initialResponse && (
                  <ChatBubble
                    sender='aida'
                    children={initialResponse}
                    timestamp={assistantMessage?.timestamp}
                  />
                )}
                {followUpContent}
              </>
            )}
            {!assistantMessage && isAssistantMessageLoading && (
              <ChatBubble
                sender='aida'
                children={<AnimatedThinkingIcon />}
                isLoading={true}
              />
            )}
          </Stack>
          {aidaError && (
            <ChatBubble
              sender='aida'
              children={
                <ErrorState
                  retryRequest={() => {
                    setAssistantMessage(null);
                    handlePromptSelection(selectedPrompt, true);
                  }}
                />
              }
            />
          )}
          {assistantMessage && !isAssistantMessageLoading && !!initialResponse && (
            <>
              <AidaFooter
                assistantMessage={assistantMessage}
                handlePromptSelection={handlePromptSelection}
                selectedPrompt={selectedPrompt}
                setAssistantMessage={setAssistantMessage}
                conversations={conversations}
                fetchConversations={fetchConversations}
                setAidaError={setAidaError}
                shareWith={shareWith}
                shareCount={shareCount}
              />
              <AidaExperimentWarning />
              {footerContent}
            </>
          )}
        </>
      )}
    </>
  );
}
