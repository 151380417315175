import React from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { EvaluationPeriod } from '@src/components/views/MyBusiness/Performance/tabs/PerformanceCycles/PerformanceCycleLandingPage/tabs/PerformanceEvaluation/EvaluationPeriod';
import { RatingScale } from '@src/components/views/MyBusiness/Performance/tabs/PerformanceCycles/PerformanceCycleLandingPage/tabs/PerformanceEvaluation/RatingScale';
import LoadingIcon from '@src/components/common/LoadingIcon';
import { Alert, AlertTitle, Link, Stack, Typography } from '@mui/material';
import { FeedbackForms } from './FeedbackForms';
import { useStore } from '@src/models/Store';

const PerformanceEvaluation: React.FunctionComponent = () => {
  const { user } = useStore();
  const intercom = useIntercom();
  const { id: clientId, performanceCycleId } = useParams();
  const { useGetPerformanceCycleById } = useClientQueries(clientId);
  const { performanceCycle, isLoading, refetch } =
    useGetPerformanceCycleById(performanceCycleId);
  const hasEvaluationPeriodStarted =
    dayjs().isAfter(dayjs(performanceCycle.manager_evaluation_start)) ||
    dayjs().isAfter(dayjs(performanceCycle.self_evaluation_start));
  const hasNotStartedOrIsAdmin =
    (!user.is_admin && !hasEvaluationPeriodStarted) || user.is_admin;
  const ratingLabelSet =
    Array.isArray(performanceCycle?.rating_scale_labels) &&
    performanceCycle.rating_scale_labels.length > 0;
  const managerEvaluationActive = dayjs().isBetween(
    performanceCycle.manager_evaluation_start,
    performanceCycle.manager_evaluation_end,
  );
  const managerEvaluationSet =
    ratingLabelSet &&
    performanceCycle?.manager_evaluation_form_id &&
    performanceCycle?.manager_evaluation_form_id.length > 0;
  const selfEvaluationActive = dayjs().isBetween(
    performanceCycle.self_evaluation_start,
    performanceCycle.self_evaluation_end,
  );
  const selfEvaluationSet =
    ratingLabelSet &&
    performanceCycle?.self_evaluation_form_id &&
    performanceCycle?.self_evaluation_form_id.length > 0;
  const showChangingWarning =
    (managerEvaluationActive && !managerEvaluationSet) ||
    (selfEvaluationActive && !selfEvaluationSet);
  return (
    <>
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <Stack spacing={5}>
          {showChangingWarning && (
            <Alert severity='warning'>
              <AlertTitle>The evaluation period has started</AlertTitle>
              <Typography variant='body2'>
                Nothing can be changed at this time. Please reach out to our{' '}
                <Link variant='body2' onClick={() => intercom.show()}>
                  support team
                </Link>{' '}
                if help is needed.
              </Typography>
            </Alert>
          )}
          <EvaluationPeriod
            performanceCycle={performanceCycle}
            refetchPerformanceCycle={refetch}
            hasNotStartedOrIsAdmin={hasNotStartedOrIsAdmin}
          />
          <FeedbackForms
            performanceCycle={performanceCycle}
            refetchPerformanceCycle={refetch}
            hasNotStartedOrIsAdmin={hasNotStartedOrIsAdmin}
          />
          <RatingScale
            performanceCycle={performanceCycle}
            refetchPerformanceCycle={refetch}
            hasNotStartedOrIsAdmin={hasNotStartedOrIsAdmin}
          />
        </Stack>
      )}
    </>
  );
};

export { PerformanceEvaluation };
