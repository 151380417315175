import React, { FC } from 'react';
import { observer } from 'mobx-react';
import TuneIcon from '@mui/icons-material/Tune';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { Overview } from './Dashboards/Overview/Overview';
import { CoacheeEngagement } from './Dashboards/CoacheeEngagement/CoacheeEngagement';
import { SessionsCalendar } from './Dashboards/SessionsCalendar/SessionsCalendar';
import {
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
} from '@src/components/sc-design-system';

const Administration: FC = () => {
  return (
    <View>
      <ViewHeader
        title='Administration'
        titleIcon={<TuneIcon fontSize='large' />}
      />
      <ViewBody>
        <ViewBodySection title='Overview' icon={<VisibilityOutlinedIcon />}>
          <Overview />
        </ViewBodySection>
        <ViewBodySection title='Engagement' icon={<GroupOutlinedIcon />}>
          <CoacheeEngagement />
        </ViewBodySection>
        <ViewBodySection
          title='Sessions'
          icon={<CalendarTodayOutlinedIcon fontSize='small' />}
        >
          <SessionsCalendar />
        </ViewBodySection>
      </ViewBody>
    </View>
  );
};

export default observer(Administration);
