import React, { FC, useEffect, useLayoutEffect } from 'react';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Spin } from './Spin';
import { Button, ButtonProps } from './Button';
import { useQueryParams } from '@src/utils';
import { Avatar } from './Avatar';
import LoadingIcon from '@src/components/common/LoadingIcon';
import { useNavigation } from '@src/navigation';
import { useBreadcrumbs } from './Breadcrumbs';
import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';

// Using this VM to store the state of the view
// so we can use it across all components
class ViewVM {
  sections: any[] = [];
  activeSection: number = 0;

  tabs: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  buildSections = children => {
    this.sections = children.flat().map(c => ({
      title: c.props.title,
      icon: c.props.icon,
      onChangeTo: c.props.onChangeTo,
      fullScreen: c.props.fullScreen || false,
    }));
  };
}

// Create instantiation of VM
let CurrentViewVM = new ViewVM();

const View: FC<{
  children: JSX.Element | JSX.Element[];
  className?: string;
  isLoading?: boolean;
  isError?: boolean;
  refreshFn?: Function;
}> = observer(({ children, className, isLoading, isError, refreshFn }) => {
  useEffect(() => {
    CurrentViewVM.activeSection = 0;
    try {
      CurrentViewVM.tabs = new Array(
        // @ts-expect-error
        React.Children?.toArray(children)[1].props.children.length,
      );
    } catch (e) {
      console.warn('Could not determine number of view children nodes');
      // Arbitrarily init array to avoid out-of-bounds error
      CurrentViewVM.tabs = new Array(10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  if (isLoading) {
    return <ViewLoadingState />;
  }
  if (isError && refreshFn) {
    return <ViewErrorState retry={refreshFn} />;
  }
  return <div className={`view ${className || ''}`}>{children}</div>;
});

interface IViewHeaderAction {
  title?: string;
  icon?: ButtonProps['startIcon'];
  onClick?: Function;
  render?: any;
  variant?: ButtonProps['variant'];
  disabled?: boolean;
}

const ViewHeader: FC<{
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  titleAvatarId?: string;
  titleIcon?: JSX.Element;
  actions?: IViewHeaderAction[];
  showSingleTab?: boolean;
}> = observer(
  ({ title, subtitle, titleAvatarId, titleIcon, actions, showSingleTab }) => {
    const hasTabs = CurrentViewVM?.sections?.length > 1 || showSingleTab;
    const navigation = useNavigation();
    const query = useQueryParams();
    const { breadcrumbs, updateBreadcrumb } = useBreadcrumbs();
    return CurrentViewVM.sections ? (
      <Box
        sx={{
          backgroundColor: 'white.main',
          borderBottomColor: 'grey.200',
          borderBottomWidth: '2px',
          borderBottomStyle: 'solid',
        }}
        className='view-header'
      >
        <Box className='vh-inner-container'>
          <div
            className={`vh-title-container ${
              hasTabs ? 'navigation-tabs' : 'no-navigation-tabs'
            }`}
          >
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              margin='1em 0'
            >
              <Stack direction='row' alignItems='center' spacing={2}>
                {(titleAvatarId || titleIcon) &&
                  (titleAvatarId ? (
                    <Avatar
                      srcId={titleAvatarId}
                      size='xxxl'
                      alt={(title as string) ?? ''}
                    />
                  ) : titleIcon ? (
                    titleIcon
                  ) : null)}
                <Stack direction='row' alignItems='center' spacing={1}>
                  <Typography variant='h1'>{title}</Typography>
                  {subtitle && <>{subtitle}</>}
                </Stack>
              </Stack>
              <Stack
                direction={{ md: 'row', sm: 'column' }}
                alignItems='center'
                spacing={2}
              >
                {actions &&
                  actions.map((action, idx) =>
                    action.onClick ? (
                      <Button
                        key={`vh-actions-${idx}`}
                        onClick={() => action.onClick()}
                        size='small'
                        startIcon={action.icon}
                        text={action.title}
                        variant={action.variant}
                        disabled={action.disabled}
                      />
                    ) : (
                      action.render || null
                    ),
                  )}
              </Stack>
            </Stack>
          </div>
          {hasTabs && (
            <Tabs
              value={CurrentViewVM.activeSection}
              onChange={(_, idx) => {
                if (CurrentViewVM.sections.length === 1) return;
                CurrentViewVM.activeSection = idx;
                query.set('tab', CurrentViewVM.activeSection.toString());
                navigation.replace(
                  `${navigation.location.pathname}?${query}`,
                  navigation.location.state || {},
                );
                const lastCrumb = {
                  ...breadcrumbs[breadcrumbs.length - 1],
                };
                updateBreadcrumb(
                  {
                    ...lastCrumb,
                    search: `?tab=${CurrentViewVM.activeSection}`,
                  },
                  breadcrumbs.length - 1,
                );
                const section = CurrentViewVM.sections[idx];
                if (section.onChangeTo) {
                  section.onChangeTo();
                }
              }}
              sx={{ marginBottom: 0 }}
            >
              {CurrentViewVM.sections.map((section, idx) => {
                return (
                  <Tab
                    key={`view-header-section-${section.title}-${idx}`}
                    label={section.title}
                    icon={section.icon}
                    tabIndex={0}
                  />
                );
              })}
            </Tabs>
          )}
        </Box>
      </Box>
    ) : null;
  },
);

const ViewBody: FC<{
  children: JSX.Element | JSX.Element[];
}> = observer(({ children }) => {
  const childrenArr = React.Children.toArray(children).flat();
  const query = useQueryParams();
  const activeSection = Number(query.get('tab'));
  useLayoutEffect(() => {
    CurrentViewVM.buildSections(childrenArr);
    if (CurrentViewVM.sections) {
      CurrentViewVM.activeSection =
        activeSection >= 0 && activeSection <= CurrentViewVM.sections.length
          ? activeSection
          : 0;
    }
  }, [childrenArr, activeSection]);
  return (
    <div className='view-body'>
      {childrenArr.map((child: JSX.Element, idx) => (
        <div
          key={`view-body-child-${idx}`}
          className={`vb-visibility-container ${
            CurrentViewVM.activeSection === idx ? 'active' : 'hidden'
          }`}
        >
          {CurrentViewVM.activeSection === idx && React.cloneElement(child)}
        </div>
      ))}
    </div>
  );
});

const ViewBodySection: FC<{
  children: string | JSX.Element | JSX.Element[];
  title: string;
  icon?: JSX.Element;
  onChangeTo?: Function;
  cardTitle?: string;
  fullScreen?: boolean;
  isLoading?: boolean;
}> = observer(props => {
  return (
    <div
      className={`view-body-section ${props.fullScreen ? 'full-screen' : ''}`}
    >
      {props.isLoading ? <Spin sectionLoader /> : props.children}
    </div>
  );
});

const ViewLoadingState = () => (
  <div className='view-splash'>
    <div className='splash-content'>
      <LoadingIcon />
    </div>
  </div>
);

const ViewErrorState: FC<{ retry: Function }> = ({ retry }) => (
  <div className='view-splash'>
    <Stack
      className='splash-content'
      justifyContent='center'
      alignItems='center'
      spacing={2}
    >
      <Typography variant='h2'>We had trouble loading this page.</Typography>
      <Button
        onClick={() => retry()}
        text='Retry'
        sx={{ width: 'fit-content' }}
      />
    </Stack>
  </div>
);

export { View, ViewHeader, ViewBody, ViewBodySection };
