import React from 'react';
import dayjs from 'dayjs';
import { Stack, Divider, Typography } from '@mui/material';

function CoacheeBioHeader({ coachee }: { coachee: any }) {
  const showProgramInfo =
    coachee.sessions_allocated ||
    coachee.program_info?.coach_rate ||
    coachee.program_info?.start_date;

  return (
    coachee?.client_name &&
    coachee?.program_name && (
      <>
        <Typography variant='h4' component='h3'>
          {coachee.client_name} - {coachee.program_name}
        </Typography>
        {showProgramInfo && (
          <Stack
            direction='row'
            spacing={2}
            alignItems='center'
            sx={{ marginTop: '.5em' }}
            divider={<Divider orientation='vertical' flexItem />}
          >
            {coachee.sessions_allocated != null && (
              <Typography variant='body1'>
                {coachee.sessions_allocated} Sessions
              </Typography>
            )}
            {coachee.program_info?.coach_rate && (
              <Typography variant='body1'>
                ${coachee.program_info?.coach_rate}/session
              </Typography>
            )}
            {coachee.program_info?.start_date &&
              coachee.program_info?.end_date && (
                <Typography variant='body1'>
                  {dayjs(coachee.program_info.start_date).format('MMM D, YYYY')}
                  {' - '}
                  {dayjs(coachee.program_info.end_date).format('MMM D, YYYY')}
                </Typography>
              )}
          </Stack>
        )}
      </>
    )
  );
}

export default CoacheeBioHeader;
