import React from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import {
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
  Card,
} from '@src/components/sc-design-system';
import { useParams } from 'react-router';
import { useStore } from '@src/models/Store';
import DirectReportCard from '@src/components/views/TeamManagement/TeamDashboard/DirectReportCard';
import { ActiveClientKey, useActiveClient } from '@src/hooks/useActiveClient';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { ClientSelector } from '@src/components/common/ClientSelector';

const TeamDashboard: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const params = useParams();
  const store = useStore();
  const isMyTeam = window.location.pathname.includes('my-team');
  const defaultTitle = isMyTeam ? 'My Team' : 'Team Dashboard';

  const {
    client,
    isLoading: isClientLoading,
    isFetching: isClientFetching,
  } = useActiveClient({
    queryKey: ActiveClientKey.profile_active_client_id,
  });

  const { useGetClientDirectReports } = useClientQueries(client?.id);
  const {
    data,
    isLoading: isLoadingDirectReports,
    isError: fetchFailed,
    error: fetchError,
  } = useGetClientDirectReports(params?.id || store.user.id);

  const headerTitle = data
    ? params?.id
      ? `${data.manager_first_name} ${data.manager_last_name}'s Team`
      : defaultTitle
    : defaultTitle;

  React.useEffect(
    function () {
      if (fetchFailed && (fetchError as any)?.response?.status === 401) {
        navigate('/performance-and-growth');
      }
    },
    [fetchFailed, fetchError, navigate],
  );

  const isLoading =
    isLoadingDirectReports || isClientLoading || isClientFetching;

  return (
    <View isLoading={isLoading}>
      <ViewHeader
        title={headerTitle}
        titleIcon={<GroupsOutlinedIcon fontSize='large' />}
        actions={[
          {
            render: isMyTeam && store.user.clients?.length > 1 && (
              <ClientSelector
                user={store.user}
                key='client-selector'
                disabled={isLoading}
                selectedClient={client}
                localStorageKey={ActiveClientKey.profile_active_client_id}
              />
            ),
          },
        ]}
      />
      <ViewBody>
        <ViewBodySection title='Overview'>
          <Typography variant='h3' sx={{ marginBottom: '1.5em' }}>
            Direct Reports
          </Typography>
          {data?.direct_reports?.map((d, index) => (
            <DirectReportCard
              directReport={d}
              key={`card_${index}`}
              cardKey={`card_${index}`}
            />
          ))}
          {fetchFailed && (
            <Card bodyStyle={{ textAlign: 'center' }}>
              <Typography
                variant='h3'
                color='primary'
                sx={{ marginBottom: '1em' }}
              >
                Oops! Something went wrong.
              </Typography>
              <Typography variant='body1'>
                The page didn’t load correctly. Please try again later.
              </Typography>
            </Card>
          )}
        </ViewBodySection>
      </ViewBody>
    </View>
  );
};

export default observer(TeamDashboard);
