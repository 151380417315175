import { api } from '@src/lib/client';
import { message } from 'antd';
import { computed, makeObservable, observable, toJS } from 'mobx';

const resourceProps = [
  'id',
  'owner',
  'status',
  'uri',
  'name',
  'description',
  'tags',
  'type',
  'new_window',
  'default_available',
];

export default class Resource {
  id: string;
  owner: string;
  status: string = 'active';
  uri: string;
  name: string;
  description: string;
  tags: string[];
  type: string = 'embed';
  new_window: boolean = false;
  default_available: boolean = false;

  constructor(resource = { type: 'embed' }) {
    makeObservable(this, {
      id: observable,
      owner: observable,
      status: observable,
      uri: observable,
      name: observable,
      description: observable,
      tags: observable,
      type: observable,
      new_window: observable,
      default_available: observable,
      // computeds
      data: computed,
    });
    this.mergeData(resource);
  }

  mergeData = data => {
    resourceProps.forEach(prop => {
      this[prop] = data[prop];
    });
  };

  get data() {
    return JSON.parse(
      JSON.stringify(
        resourceProps.reduce((acc: object, prop: string) => {
          acc[prop] = toJS(this[prop]);
          return acc;
        }, {}),
      ),
    );
  }

  trackView = async (
    type: string,
    user_id: string,
    program_id: string,
    client_id: string,
    duration: number,
  ) => {
    try {
      // Don't track if resource preview is being used outside of program (admin view)
      if (!user_id || !program_id || !client_id) {
        return;
      }
      await api.resources.trackView({
        resource_id: this.id,
        type,
        user_id,
        program_id,
        client_id,
        duration,
      });
    } catch (e) {
      /* no-op */
      message.warn('Could not track resource view', e.message);
    }
  };
}
