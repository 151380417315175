import React, { FC } from 'react';
import { useParams } from 'react-router';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import TrophyIcon from '@mui/icons-material/EmojiEventsOutlined';
import {
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
} from '@src/components/sc-design-system';
import { ClientCompetencies } from './tabs/ClientCompetencies';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { PerformanceCycles } from './tabs/PerformanceCycles/PerformanceCyclesLandingPage';

const Performance: FC = () => {
  const { id } = useParams();
  const { useGetClient } = useClientQueries(id);
  const { data: client, isLoading: isClientLoading } = useGetClient();
  const isLoading = isClientLoading;

  return (
    <View isLoading={isLoading}>
      <ViewHeader
        title='Performance'
        titleIcon={<InsertChartOutlinedIcon fontSize='large' />}
      />
      <ViewBody>
        <ViewBodySection
          title='Performance Cycles'
          icon={<InsertChartOutlinedIcon />}
        >
          <PerformanceCycles clientId={id} />
        </ViewBodySection>
        <ViewBodySection
          title='Competencies & Value Sets'
          icon={<TrophyIcon />}
        >
          <ClientCompetencies client={client} />
        </ViewBodySection>
      </ViewBody>
    </View>
  );
};

export { Performance };
