import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router';
import {
  Card,
  TableMUI,
  useBreadcrumbs,
  useToast,
} from '@src/components/sc-design-system';
import { useClientQueries } from '@src/hooks/useClientQueries';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import { AppLoader } from '@src/components/app/AppLoader';
import {
  InputAdornment,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Search from '@mui/icons-material/Search';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { createColumnHelper } from '@tanstack/react-table';
import { theme } from '@src/theme';
import { api } from '@src/lib/client';
import { CSVLink } from 'react-csv';

const StatusDashboard: FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { appendBreadcrumb } = useBreadcrumbs();
  const { id: clientId, performanceCycleId } = useParams();
  const {
    useGetClient,
    useGetPerformanceCycleResults,
    useGetClientMembers,
    useGetPerformanceCycleById,
  } = useClientQueries(clientId);
  const { data: client, isLoading: isClientLoading } = useGetClient();
  const { members, isLoading: isMembersLoading } = useGetClientMembers();
  const { results, isLoading: isResultsLoading } =
    useGetPerformanceCycleResults(performanceCycleId);
  const { performanceCycle, isLoading: isPCLoading } =
    useGetPerformanceCycleById(performanceCycleId);
  const hasEvaluationPeriodStarted =
    dayjs().isAfter(dayjs(performanceCycle.manager_evaluation_start)) ||
    dayjs().isAfter(dayjs(performanceCycle.self_evaluation_start));

  const [searchKey, setSearchKey] = useState('');
  const [departmentFilter, setDepartmentFilter] = useState('all');
  const [csvData, setCsvData] = useState([]);
  const [downloadReady, setDownloadReady] = useState(false);
  //stackoverflow.com/questions/65611889/how-to-add-ref-to-csvlink-in-react-typescript/68066447#68066447
  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >();

  useEffect(() => {
    if (csvLink && csvLink.current && downloadReady) {
      csvLink.current.link.click(); // https://github.com/react-csv/react-csv/issues/72
      setDownloadReady(false);
    }
  }, [downloadReady]);

  const getPerformanceCycleCsvReport = async () => {
    try {
      const { data } = await api.clients.getPerformanceCycleCsvReport(
        clientId,
        performanceCycleId,
      );
      setCsvData(data);
      setDownloadReady(true);
    } catch {
      toast.error('Unable to generate CSV');
    }
  };

  const isLoading =
    isClientLoading || isMembersLoading || isResultsLoading || isPCLoading;

  const statusDashboardData = useMemo(() => {
    if (isLoading) return [];
    return members?.reduce((arr, m) => {
      let managerName = '';
      if (m.manager) {
        const manager = members.find(member => member.id === m.manager);
        managerName = manager
          ? `${manager.first_name} ${manager.last_name}`
          : null;
      }
      const memberResults = results?.results?.find(
        r => r.direct_report.id === m.id,
      );
      arr.push({
        id: m.id,
        name: `${m.first_name} ${m.last_name}`,
        department:
          client?.departments.find(d => d.id === m.department?.id)?.name ?? ' ', // Need white space for filtering by no department *shrug*
        manager: managerName,
        selfEvaluationSubmitted: Boolean(
          memberResults?.direct_report?.evaluation_submission_id,
        ),
        managerEvaluationSubmitted: Boolean(
          memberResults?.manager?.evaluation_submission_id,
        ),
        selfRatingSubmitted: Boolean(memberResults?.direct_report?.rating),
        managerRatingSubmitted: Boolean(memberResults?.manager?.rating),
      });
      return arr;
    }, []);
  }, [members, client, results, isLoading]);

  const columnHelper = createColumnHelper<typeof statusDashboardData>();

  const columns = [
    columnHelper.group({
      id: 'main-group',
      header: '',
      columns: [
        {
          accessorKey: 'name',
          header: 'Employee',
          minSize: 200,
          cell: ({ row }) => {
            const employeeHasResults = results?.results?.some(
              ({ direct_report }) => direct_report?.id === row.original.id,
            );
            return (
              <Stack spacing={1}>
                <Typography variant='body2'>{row.original.name}</Typography>
                {hasEvaluationPeriodStarted && employeeHasResults && (
                  <Link
                    variant='a2'
                    onClick={() => {
                      const path = `/evaluation-report?clientId=${clientId}&performanceCycleId=${performanceCycleId}&userId=${
                        row.original.id
                      }&name=${encodeURIComponent(row.original.name)}`;
                      appendBreadcrumb({
                        path,
                        label: `Report for ${row.original.name}`,
                      });
                      navigate(path, {
                        state: { employeeName: row.original.name },
                      });
                    }}
                  >
                    View report
                  </Link>
                )}
              </Stack>
            );
          },
        },
        {
          accessorKey: 'manager',
          header: 'Manager',
          minSize: 200,
        },
        {
          accessorKey: 'department',
          header: 'Department',
          minSize: 150,
        },
      ],
    }),
    columnHelper.group({
      id: 'self-eval-group',
      header: () => <div style={{ textAlign: 'center' }}>Self-evaluation</div>,
      columns: [
        {
          accessorKey: 'selfEvaluationSubmitted',
          header: 'Feedback form',
          cell: ({ row }) => {
            return row.original.selfEvaluationSubmitted ? (
              <CheckCircleOutline color='success' />
            ) : (
              <CancelOutlined color='error' />
            );
          },
        },
        {
          accessorKey: 'selfRatingSubmitted',
          header: 'Overall rating',
          cell: ({ row }) => {
            return row.original.selfRatingSubmitted ? (
              <CheckCircleOutline color='success' />
            ) : (
              <CancelOutlined color='error' />
            );
          },
        },
      ],
    }),
    columnHelper.group({
      id: 'manager-eval-group',
      header: () => (
        <div style={{ textAlign: 'center' }}>Manager evaluation</div>
      ),
      columns: [
        {
          accessorKey: 'managerEvaluationSubmitted',
          header: 'Feedback form',
          cell: ({ row }) => {
            return row.original.managerEvaluationSubmitted ? (
              <CheckCircleOutline color='success' />
            ) : (
              <CancelOutlined color='error' />
            );
          },
        },
        {
          accessorKey: 'managerRatingSubmitted',
          header: 'Overall rating',
          cell: ({ row }) => {
            return row.original.managerRatingSubmitted ? (
              <CheckCircleOutline color='success' />
            ) : (
              <CancelOutlined color='error' />
            );
          },
        },
      ],
    }),
  ];

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <Card
      title='Status Dashboard'
      titleActions={
        results?.results?.length && (
          <>
            <Typography variant='a1' onClick={getPerformanceCycleCsvReport}>
              <Stack direction='row' gap={1}>
                {/* https://fonts.google.com/icons?selected=Material+Symbols+Outlined:download:FILL@0;wght@400;GRAD@0;opsz@24 */}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  height='24'
                  viewBox='0 -960 960 960'
                  width='24'
                  fill={theme.palette.primary.main}
                >
                  <path d='M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z' />
                </svg>
                Generate CSV
              </Stack>
            </Typography>
            <CSVLink
              data={csvData}
              filename={`${performanceCycleId}-${Date.now()}.csv`}
              ref={csvLink}
              target='_blank'
            />
          </>
        )
      }
    >
      <Stack spacing={4}>
        <Stack spacing={0} sx={{ width: '100%' }} direction='row'>
          <StyledSelect
            value={departmentFilter}
            onChange={e => setDepartmentFilter(e.target.value as string)}
            IconComponent={ExpandMore}
          >
            <MenuItem key='all' value='all'>
              All departments
            </MenuItem>
            {client?.departments
              ?.sort((a, b) => (a.name > b.name ? 1 : -1))
              ?.map(d => (
                <MenuItem key={d.id} value={d.id}>
                  {d.name}
                </MenuItem>
              ))}
            <MenuItem key='none' value='none'>
              No department
            </MenuItem>
          </StyledSelect>
          <StyledTextField
            label=''
            value={searchKey ?? ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
            fullWidth
            placeholder='Search members'
            onChange={e => {
              setSearchKey(String(e.target.value));
            }}
          />
        </Stack>
        <TableMUI
          data={statusDashboardData}
          columns={columns}
          isFilterable
          initialRowsPerPage={25}
          tableState={{
            globalFilter: searchKey,
            columnFilters:
              departmentFilter === 'all'
                ? []
                : departmentFilter === 'none'
                ? [{ id: 'department', value: ' ' }] // TODO: maybe fix/change this? Can't use empty string or null for some reason
                : [
                    {
                      id: 'department',
                      value:
                        client.departments.find(d => d.id === departmentFilter)
                          ?.name ?? '',
                    },
                  ],
          }}
        />
      </Stack>
    </Card>
  );
};

export { StatusDashboard };

// TODO: make search bar reusable
const StyledSelect = styled(Select)({
  '&.MuiInputBase-root': {
    borderRadius: '4px 0px 0px 4px',
    width: '35%',
  },
});

const StyledTextField = styled(TextField)({
  '.MuiOutlinedInput-notchedOutline': {
    borderRadius: '0px 4px 4px 0px',
  },
});
