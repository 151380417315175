import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Typography, Stack, Card } from '@mui/material';
import { useParams } from 'react-router';
import { LearnMoreAboutAidaAlert } from '@src/components/common/LearnMoreAboutAidaAlert';
import { useUserQueries } from '@src/hooks/useUserQueries';
import { Spin } from '@src/components/sc-design-system';
import { AidaExpandingCard } from '../../Aida/AidaExpandingCard';

function NotShared() {
  return (
    <Card
      variant='elevation'
      sx={{
        padding: '2.5em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant='h4'>Data is unavailable</Typography>
      <Typography variant='body1' sx={{ marginTop: '1em' }}>
        Currently there is no data to display.
      </Typography>
    </Card>
  );
}

const Aida: React.FunctionComponent<{ clientId: string }> = props => {
  const { clientId } = props;
  const params = useParams();
  const { useGetUserAidaConversations } = useUserQueries(params.id);
  const { conversations, isLoading: isLoadingConversations } =
    useGetUserAidaConversations(clientId, 'manager');
  return (
    <>
      <LearnMoreAboutAidaAlert />
      {isLoadingConversations ? (
        <Spin sectionLoader />
      ) : !isLoadingConversations &&
        !conversations?.summary &&
        !conversations?.focus_areas ? (
        <NotShared />
      ) : (
        <Stack spacing={3.5}>
          {Array.isArray(conversations?.summary) &&
            conversations.summary.length > 0 &&
            conversations.summary[0] && (
              <AidaExpandingCard
                truncate
                boxTheme='white'
                children={
                  <>
                    <Typography variant='h4'>Developmental summary</Typography>
                    <ReactMarkdown
                      children={conversations.summary[0].output_text}
                    />
                  </>
                }
                timestamp={conversations.summary[0].timestamp}
              />
            )}
          {Array.isArray(conversations?.focus_areas) &&
            conversations.focus_areas.length > 0 &&
            conversations.focus_areas[0] && (
              <AidaExpandingCard
                truncate
                boxTheme='white'
                children={
                  <>
                    <Typography variant='h4'>Focus areas</Typography>
                    <ReactMarkdown
                      children={conversations.focus_areas[0].output_text}
                    />
                  </>
                }
                timestamp={conversations.focus_areas[0].timestamp}
              />
            )}
        </Stack>
      )}
    </>
  );
};

export default Aida;
