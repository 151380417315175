import * as React from 'react';
import { makeAutoObservable, observable } from 'mobx';
import { api } from '../../../lib/client';
import Program from '../../../models/Program';
import Session from '../../../models/Session';
import User from '../../../models/User';
import Survey, { DEFAULT_SURVEY_IDS } from '@src/models/Survey';
import Invitee from '@src/models/Invitee';

export class ProgramViewModel {
  program: Program;
  sessions: Session[];
  members: ProgramMemberViewModel[];
  mentors: {
    id: string;
    first_name: string;
    last_name: string;
  }[];
  coaches: {
    id: string;
    first_name: string;
    last_name: string;
  }[];
  surveys: Survey[];
  clientSurveys: Survey[];
  defaultSurveys: Survey[];
  invitees: Invitee[] = [];

  constructor(program: Program) {
    this.program = program;
    this.members = [];
    this.mentors = [];
    this.coaches = [];
    this.sessions = [];
    this.surveys = [];
    this.clientSurveys = [];
    this.defaultSurveys = [];
    this.invitees = program.invitees || [];
    makeAutoObservable(this, {
      program: observable.ref,
      sessions: observable.ref,
      members: observable.ref,
      mentors: observable.ref,
      coaches: observable.ref,
      surveys: observable.ref,
      invitees: observable.ref,
    });
  }

  /**
   * Returns the maximum number of sessions that can be allocated.
   */
  get availableSessionCount(): number {
    // 1. Start with the total reserved session count for the program.
    let remaining = this.program.sessions_reserved;

    // 2. Subtract the number of sessions that have been allocated to members.
    for (const member of this.members) {
      remaining -= member.sessions_allocated;
    }

    // 3. Subtract the number of sessions that have already been used
    //    but aren't allocated to a member, e.g., the member used a session
    //    and then was removed from the program.
    for (const session of this.coachingSessions) {
      if (!session.isUsed) continue;

      const isCoacheeInProgram = session.members.every(user => {
        if (user.role !== 'coachee') {
          return true;
        }
        return this.program.members.find(u => {
          return u.id === user.id;
        });
      });
      if (!isCoacheeInProgram) {
        remaining--;
      }
    }

    // NOTE: this should never happen. Probably want some logging/alerting.
    if (remaining < 0) {
      return 0;
    }
    return remaining;
  }

  get coachingSessions() {
    return this.sessions.filter(session => {
      return session.type === 'coaching' && !session.isCancelled;
    });
  }

  get stats() {
    const stats = {
      totalMembers: this.members.length,
      membersWithCoach: 0,
      membersWithScheduledInterviews: 0,
      completedCoachingSessions: 0,
      scheduledCoachingSessions: 0,
      unstartedCoachingSessions: 0,
    };

    for (const user of this.members) {
      if (user.selected_coach) {
        stats.membersWithCoach++;
      }
      if (user.interviewSessions.length) {
        stats.membersWithScheduledInterviews++;
      }
    }
    for (const session of this.sessions) {
      if (session.isCancelled) {
        continue;
      }
      if (session.type === 'coaching') {
        if (session.isCompleted) {
          stats.completedCoachingSessions++;
        } else if (session.isScheduled) {
          stats.scheduledCoachingSessions++;
        }
      }
    }
    const usedSessions =
      stats.completedCoachingSessions + stats.scheduledCoachingSessions;

    stats.unstartedCoachingSessions =
      this.program.sessions_reserved - usedSessions;

    return stats;
  }

  async loadMembers() {
    const response = await api.programs.getProgramMembers(this.program.id, {
      limit: 500,
    });
    this.members = response.data.data.map(json => {
      return new ProgramMemberViewModel(new User(json), this);
    });
  }

  async loadMentors() {
    const response = await api.clients.getMentors(this.program.client_id);
    this.mentors = response.data;
  }

  async loadCoaches() {
    const response = await api.coaches.listCoaches();
    this.coaches = response.data.data;
  }

  async loadSessions() {
    const response = await api.sessions.getByProgram({
      programId: this.program.id,
    });
    this.sessions = response.data.data.map(json => {
      return new Session(json);
    });
  }

  async loadSurveys() {
    const clientSurveys = await api.surveys.getPublishedSurveys({
      client_id: this.program.client_id,
      limit: 100,
    });
    this.clientSurveys = clientSurveys.data;
    const defaultSurveys = await api.surveys.getPublishedSurveys({});
    this.defaultSurveys = defaultSurveys.data?.filter(({ survey_id }) =>
      DEFAULT_SURVEY_IDS.includes(survey_id),
    );
    this.surveys = [...this.clientSurveys, ...this.defaultSurveys];
  }

  async loadSurveyResponseCounts() {
    const res = await api.programs.getProgramSurveySubmissions(this.program.id);
    this.surveys = this.surveys.map(s => ({
      ...s,
      responses: Number(res.data?.responses?.[s.id]) || 0,
    })) as Survey[];
  }
}

export class ProgramMemberViewModel {
  private vm: ProgramViewModel;
  user: User;

  constructor(user: User, vm: ProgramViewModel) {
    this.user = user;
    this.vm = vm;
    makeAutoObservable<this, 'vm'>(this, { user: false, vm: false });
  }

  get id() {
    return this.user.id;
  }

  get removedAt(): number {
    const member = this.vm.program.members.find(user => {
      return user.id === this.id;
    });
    return member?.removedAt || null;
  }

  get selected_coach() {
    return this.user.selected_coach;
  }

  get sessions() {
    return this.vm.sessions.filter(session => {
      return session.members.some(user => {
        return user.id === this.id && user.role === 'coachee';
      });
    });
  }

  get interviewSessions(): Session[] {
    return this.sessions.filter(session => {
      return session.type === 'interview' && !session.isCancelled;
    });
  }

  get coachingSessions(): Session[] {
    return this.sessions.filter(session => {
      return (
        !session.isCancelled &&
        session.type === 'coaching' &&
        session.members.find(user => {
          return user.id === this.user.id;
        })
      );
    });
  }

  /**
   * The number of sessions allocated to this user. The base user record
   * doesn't have `sessions_allocated`, only the partial record in the
   * program.members list does.
   */
  get sessions_allocated(): number {
    const member = this.vm.program.members.find(user => {
      return user.id === this.id;
    });
    return member?.sessions_allocated || 0;
  }

  /**
   * Returns the minimum and maximum number of sessions that can be allocated
   * to this user. A user cannot allocate fewer sessions than they've already
   * used, and they cannot allocate more than are still available in the program.
   */
  get allowedSessionAllocations(): { min: number; max: number } {
    let min = 0;
    for (const session of this.coachingSessions) {
      if (session.isUsed) {
        min++;
      }
    }
    let max = this.vm.availableSessionCount;

    // The number of available sessions remaining in the program is reduced by
    // the number of sessions that have been allocated to this user. Add that
    // amount back, otherwise users can't be re-allocated their current amount.
    max += this.sessions_allocated;
    return { min, max };
  }

  get stats(): {
    completedInterviews: number;
    scheduledInterviews: number;
  } {
    const stats = {
      completedInterviews: 0,
      scheduledInterviews: 0,
    };
    for (const session of this.sessions) {
      if (session.isCancelled) {
        continue;
      }
      if (session.type === 'interview') {
        if (session.current_status === 'scheduled') {
          stats.scheduledInterviews++;
        } else if (session.current_status === 'completed') {
          stats.completedInterviews++;
        }
      }
    }
    return stats;
  }
}

export const useProgramViewModel = (
  programId: string,
): [LoadingState<ProgramViewModel>, () => void] => {
  const [retrySentinel, setRetrySentinel] = React.useState<any>(null);
  const [result, setResult] = React.useState<LoadingState<ProgramViewModel>>({
    state: 'loading',
  });
  React.useEffect(() => {
    let cancelled = false;

    // Return the previous state if it is already "loading". This is needed
    // to prevent a duplicate render every time the programId changes;
    // returning a new { state: "loading" } object will cause a re-render
    // since React only compares object references, not values.
    setResult(prev => {
      if (prev.state === 'loading') {
        return prev;
      } else {
        return { state: 'loading' };
      }
    });
    (async () => {
      try {
        const vm = await loadProgramViewModel(programId);
        if (!cancelled) {
          setResult({ state: 'loaded', value: vm });
        }
      } catch (e) {
        if (!cancelled) {
          setResult({ state: 'error', error: e });
        }
      }
    })();
    return () => {
      cancelled = true;
    };
  }, [programId, retrySentinel]);
  return [
    result,
    () => {
      setRetrySentinel({});
    },
  ];
};

// NOTE: All requests in here should really should be made in parallel, but
// in order to avoid working with duplicate Session instances (one list
// for the program summary, then duplicates in each program member) it's
// *significantly* easier to just ignore the sessions that come back with
// the user object and compute them from the complete session list.
const loadProgramViewModel = async (programId: string) => {
  const response = await api.programs.getProgramById(programId);
  const program = new Program(response.data);
  const vm = new ProgramViewModel(program);

  await vm.loadSessions();
  await vm.loadMembers();
  if (program.type === 'mentoring') {
    await vm.loadMentors();
  }
  if (program.type === 'custom_coach_selection') {
    await vm.loadCoaches();
  }
  await vm.loadSurveys();
  await vm.loadSurveyResponseCounts();
  return vm;
};

type LoadingState<T> =
  | { state: 'loading'; value?: T }
  | { state: 'loaded'; value: T }
  | { state: 'error'; error: Error; value?: T };
