import React, { FC } from 'react';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';
import styled from '@emotion/styled';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Button } from '@src/components/sc-design-system';
import { EmptySection } from '../EmptySection';

interface SurveyActivityProps {
  onClickCollect: Function;
  onClickResponseRate: Function;
  isEmpty?: boolean;
  collections: any[];
}

const SurveyActivity: FC<SurveyActivityProps> = ({
  isEmpty,
  onClickCollect,
  onClickResponseRate,
  collections,
}) => {
  if (isEmpty) {
    return (
      <EmptySection
        header='Survey activity'
        description='Ready to collect responses to your survey? Choose recipients and send
  the survey now.'
        buttonIcon='add'
        buttonText='Collect Survey Responses'
        buttonOnClick={onClickCollect}
      />
    );
  }

  return (
    <Stack gap='1em'>
      <Stack justifyContent='space-between' alignItems='center' direction='row'>
        <Typography variant='h4'>Survey activity</Typography>
        <Button
          variant='text'
          startIcon='add'
          text='Collect responses'
          onClick={() => onClickCollect()}
        />
      </Stack>
      <Typography variant='body1'>
        Review your survey collections below. You can remind outstanding
        recipients, add new recipients, or create a new collection.
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant='head' width='25%'>
              Collection Name
            </TableCell>
            <TableCell variant='head' width='10%'>
              Status
            </TableCell>
            <TableCell variant='head' width='22%'>
              Last Updated
            </TableCell>
            <TableCell variant='head' width='20%'>
              Response Rate
            </TableCell>
            <TableCell variant='head' width='16%'>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collections?.map(c => {
            const recipientsWithError = c.recipients.filter(r => r.send_error);
            return (
              <TableRow key={c.id} sx={{ verticalAlign: 'initial' }}>
                <TableCell>
                  <Typography variant='body1'>{c.name}</Typography>
                </TableCell>
                <TableCell>
                  <StyledChip
                    label={startCase(c.status)}
                    color={c.status === 'sent' ? 'success' : 'primary'}
                  />
                </TableCell>
                <TableCell>
                  <Typography variant='body1'>
                    {dayjs(c.updatedAt).format('M/D/YYYY') ?? 'n/a'}
                  </Typography>
                </TableCell>
                <TableCell>
                  {c.status === 'draft' ? (
                    <Typography variant='body1'>n/a</Typography>
                  ) : (
                    <Link
                      variant='a1dash'
                      onClick={() => onClickResponseRate(c)}
                    >
                      {c.responseRate}%
                    </Link>
                  )}
                </TableCell>
                <TableCell>
                  <Stack spacing={1}>
                    <div>
                      <Tooltip
                        title={
                          c.status === 'draft'
                            ? 'Edit and send'
                            : 'Send to new recipients'
                        }
                      >
                        <span>
                          <IconButton onClick={() => onClickCollect(c)}>
                            {c.status === 'draft' ? (
                              <SendOutlinedIcon fontSize='small' />
                            ) : (
                              <PersonAddAltIcon fontSize='small' />
                            )}
                          </IconButton>
                        </span>
                      </Tooltip>
                    </div>
                    {Boolean(recipientsWithError?.length) && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 8,
                          paddingLeft: 6,
                        }}
                      >
                        <InfoIcon color='error' fontSize='small' />
                        <Typography variant='body2' color='error'>
                          Failed to send to {recipientsWithError.length}{' '}
                          recipient
                          {recipientsWithError.length === 1 ? '' : 's'}
                        </Typography>
                      </div>
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Stack>
  );
};

export { SurveyActivity };

// TODO: remove when Chip theme is merged
const StyledChip = styled(Chip)({
  '&.MuiChip-root': {
    height: 'auto',
    padding: '2px 0px',
  },
});
