import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import { Button, DialogTitle } from '@src/components/sc-design-system';

interface TermsAndConditionsProps {
  open: boolean;
  setOpen: (b: boolean) => void;
}

function TermsAndConditions({ open, setOpen }: TermsAndConditionsProps) {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle
        title='Terms & Conditions'
        buttonOnClick={() => setOpen(false)}
      />
      <DialogContent>
        <Typography variant='h1' sx={{ marginBottom: '.4em' }}>
          SkillCycle Aida Terms and Conditions
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '1.15em' }}>
          Welcome to SkillCycle's Aida (<strong>A</strong>daptive{' '}
          <strong>I</strong>ndividual <strong>D</strong>evelopment{' '}
          <strong>A</strong>dvisor)! We’re excited to offer you this innovative
          AI tool designed to help employees with intelligent guidance in their
          development journey. Before you start using Aida, please take a moment
          to read and accept the following terms and conditions.
        </Typography>
        <Typography variant='h2' sx={{ marginBottom: '1em' }}>
          About Aida
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '1.15em' }}>
          Aida is a cutting-edge AI tool that uses generative AI technology to
          provide personalized development advice. While we strive for accuracy,
          Aida is still a new technology and may occasionally make mistakes. By
          enabling Aida, you acknowledge and accept that errors may occur and
          that SkillCycle is not liable for any inaccuracies or mistakes made by
          Aida.
        </Typography>
        <Typography variant='h2' sx={{ marginBottom: '1em' }}>
          Data Usage and Privacy
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '1.15em' }}>
          SkillCycle is committed to protecting your data. Here’s how we handle
          the information you provide:
        </Typography>
        <ul>
          <li>
            <strong>Data Collection:</strong> We only use the information and
            data you submit to the SkillCycle platform, such as survey
            responses, goals, commentary on goals, and documents uploaded to the
            system.
          </li>
          <li>
            <strong>Confidentiality:</strong> Aida will not access or reference
            confidential conversations or notes with coaches, managers, or
            mentors.
          </li>
          <li>
            <strong>Third-Party Services:</strong> Aida utilizes services
            provided by Anthropic. By opting into Aida, you understand that your
            information will be transmitted to Anthropic to generate responses.
            Rest assured, SkillCycle will not use your information to train any
            Large Language Models (LLMs).
          </li>
        </ul>
        <Typography variant='h2' sx={{ marginBottom: '1em' }}>
          Acceptance of Terms
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '1.15em' }}>
          By enabling Aida, you agree to these terms and conditions and
          acknowledge that:
        </Typography>
        <ul>
          <li>
            Aida may make mistakes and SkillCycle is not responsible for any
            errors.
          </li>
          <li>
            Your information will be used as described above and may be
            transmitted to Anthropic.
          </li>
          <li>SkillCycle will not use your data to train LLMs. </li>
        </ul>
        <Typography variant='body1' sx={{ marginBottom: '1.15em' }}>
          Thank you for choosing SkillCycle and Aida to support your
          development. We’re here to help you grow and succeed!
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '1.15em' }}>
          If you have any questions or need further assistance, please don’t
          hesitate to contact our support team.
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '1.15em' }}>
          Please review these terms carefully. By enabling Aida, you acknowledge
          that you have read, understood, and agree to these terms and
          conditions.
        </Typography>
        <Typography
          variant='body1'
          sx={{ marginBottom: '1.15em', fontStyle: 'italics' }}
        >
          The information contained in this web site is subject to change
          without notice.
          <br /> &copy; 2024 SkillCycle. All rights reserved.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button text='Back' onClick={() => setOpen(false)} />
      </DialogActions>
    </Dialog>
  );
}

interface AboutAidaProps {
  open: boolean;
  setOpen: (b: boolean) => void;
}

function AboutAida({ open, setOpen }: AboutAidaProps) {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm'>
      <DialogTitle title='About Aida' buttonOnClick={() => setOpen(false)} />
      <DialogContent>
        <Typography variant='h1' sx={{ marginBottom: '.4em' }}>
          About AIDA
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '1em' }}>
          Welcome to SkillCycle's Aida (<strong>A</strong>daptive{' '}
          <strong>I</strong>ndividual <strong>D</strong>evelopment{' '}
          <strong>A</strong>dvisor)! AIDA is an innovative AI tool designed to
          help employees with intelligent guidance in their development journey.
          AIDA uses generative AI technology to provide personalized development
          advice. Our goal is to help synthesize development data via inputs and
          feedback in a clear, helpful and practical way to help employees
          understand how to think about their development, contextualized to
          their role, and organization. The hope is this can help provide a
          "north star" that can adjust and adapt over time to continually
          provide an updated guide for development. Based on inputs and usage,
          AIDA can provide a summary guide, specific focus areas, recommend
          resources and even support you in your search for a coach. We are
          excited to share this cutting-edge tool with you!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button text='Close' onClick={() => setOpen(false)} />
      </DialogActions>
    </Dialog>
  );
}

export { TermsAndConditions, AboutAida };
