import React from 'react';
import dayjs from 'dayjs';
import { Typography, Box } from '@mui/material';

interface ChatBubbleProps {
  children: React.ReactNode;
  sender: 'aida' | 'user';
  timestamp?: number;
  isLoading?: boolean;
}

function ChatBubble(props: ChatBubbleProps) {
  const { children, sender, timestamp, isLoading } = props;
  const backgroundColor = sender === 'aida' ? 'grey.100' : 'primary.100';
  const width = isLoading ? 'fit-content' : sender === 'aida' ? '90%' : '50%';
  const justifySelf = sender === 'aida' ? 'start' : 'end';
  const borderRadius =
    sender === 'aida' ? '16px 16px 16px 0px' : '16px 16px 0px 16px';

  return (
    <Box
      sx={{
        backgroundColor,
        padding: '1.25em 1.5em',
        borderRadius,
        width,
        justifySelf,
      }}
    >
      {children}
      {timestamp && (
        <Typography
          variant='body3m'
          color='grey.700'
          sx={{ marginTop: '1em', textAlign: 'right', fontStyle: 'italic' }}
        >
          {dayjs(timestamp).fromNow()}
        </Typography>
      )}
    </Box>
  );
}

export { ChatBubble };
