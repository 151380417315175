// Based on https://github.com/ryohey/use-toast-mui
import React from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

export interface ToastMessage {
  message: string;
  severity: AlertColor;
  key: number;
  open: boolean;
}

const ToastContext = React.createContext<{
  addMessage: (message: ToastMessage) => void;
}>(null as never);

export const ToastProvider: React.FC<
  { children: React.ReactNode } & ToastStyle
> = ({ children, ...props }) => {
  const [messages, setMessages] = React.useState<ToastMessage[]>([]);

  const removeMessage = (key: number) =>
    setMessages(arr => arr.filter(m => m.key !== key));

  return (
    <ToastContext.Provider
      value={{
        addMessage(message) {
          setMessages(arr => [...arr, message]);
        },
      }}
    >
      {children}
      {messages.map(m => (
        <Toast
          key={m.key}
          message={m}
          onExited={() => removeMessage(m.key)}
          {...props}
        />
      ))}
    </ToastContext.Provider>
  );
};

type ToastStyle = Omit<
  SnackbarProps,
  'TransitionProps' | 'onClose' | 'open' | 'children' | 'message'
>;

type ToastProps = {
  message: ToastMessage;
  onExited: () => void;
} & ToastStyle;

// https://mui.com/material-ui/react-snackbar/#consecutive-snackbars
const Toast: React.FC<ToastProps> = ({
  message,
  onExited,
  autoHideDuration,
  ...props
}) => {
  const [open, setOpen] = React.useState(message.open);

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      sx={{
        width: '50%',
        backgroundColor: 'transparent !important',
      }}
      key={message.key}
      open={open}
      onClose={handleClose}
      TransitionComponent={Slide}
      TransitionProps={{ onExited }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={autoHideDuration ?? 5000}
      {...props}
    >
      <Alert severity={message.severity} sx={{ margin: 0, width: '100%' }}>
        {message.message}
      </Alert>
    </Snackbar>
  );
};

export interface ToastIF {
  show: (message: string, options: { severity: AlertColor }) => void;
  info: (message: string | React.ReactNode) => void;
  success: (message: string | React.ReactNode) => void;
  warn: (message: string | React.ReactNode) => void;
  error: (message: string | React.ReactNode) => void;
}

// Main export
export const useToast = (): ToastIF => {
  const { addMessage } = React.useContext(ToastContext);

  const show = (message: string, options: { severity: AlertColor }) => {
    addMessage({ message, ...options, open: true, key: new Date().getTime() });
  };

  return {
    show,
    info(message: string) {
      show(message, { severity: 'info' });
    },
    success(message: string) {
      show(message, { severity: 'success' });
    },
    warn(message: string) {
      show(message, { severity: 'warning' });
    },
    error(message: string) {
      show(message, { severity: 'error' });
    },
  };
};
