import React, { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button } from '@src/components/sc-design-system';
import VideocamOutlined from '@mui/icons-material/VideocamOutlined';
import Session from '@src/models/Session';

interface StartSessionButtonPropsIF {
  session: Session;
}

const StartSessionButton: FC<StartSessionButtonPropsIF> = observer(
  ({ session }) => {
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
      const setButtonState = () => {
        // check if the button should be disbaled
        // disable button by default.
        // enable button 15 mins before start_time thru 10 mins after end_time
        const fifteenMinutesBefore = session.start_time - 1000 * 60 * 15;
        const tenMinutesAfter = session.end_time + 1000 * 60 * 10;
        const disabled =
          Date.now() < fifteenMinutesBefore || Date.now() > tenMinutesAfter;
        setIsDisabled(disabled);
      };

      const poll = () => {
        setButtonState();
        const timeout = setTimeout(poll, 10000);
        return () => clearTimeout(timeout);
      };
      return poll();
    }, [session]);

    return (
      <div className='session-start-button-container'>
        <Link to={`/sessions/${session.id}`}>
          <Button
            data-id={session.id}
            disabled={isDisabled}
            startIcon={<VideocamOutlined />}
            text={`${
              session.current_status === 'started' ? 'Join' : 'Start'
            } Session`}
          />
        </Link>
      </div>
    );
  },
);

export default StartSessionButton;
