import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { Grid, Typography } from '@mui/material';
import { Button, TextField, useToast } from '@src/components/sc-design-system';

interface IndividualInviteProps {
  isDuplicateEmail: (e: string) => boolean;
  refetch: Function;
  inviteMembers: (p: { invitees: Partial<Invitee>[] }) => Promise<unknown>;
  setMembersInvited?: (b: boolean) => void;
}

interface IndividualInviteFormDefaultValues {
  first_name: string;
  last_name: '' | string;
  email: '' | string;
}

interface IndividualInviteOnSubmitProps {
  first_name: string;
  last_name: string;
  email: string;
}

const IndividualInvite = (props: IndividualInviteProps) => {
  const { isDuplicateEmail, refetch } = props;
  const toast = useToast();

  const form = useForm({
    mode: 'all',
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
    } as IndividualInviteFormDefaultValues,
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty, errors, touchedFields },
  } = form;

  const { mutate: inviteMember, isPending } = useMutation({
    mutationFn: props.inviteMembers,
    onSuccess: () => {
      refetch();
      props.setMembersInvited && props.setMembersInvited(true);
      reset();
      toast.success('Member successfully invited');
    },
    onError: () => toast.error('Unable to invite member'),
  });

  const onSubmit = async (values: IndividualInviteOnSubmitProps) => {
    await inviteMember({
      invitees: [
        {
          invitee_first_name: values.first_name,
          invitee_last_name: values.last_name,
          invitee_email: values.email.toLowerCase(),
        },
      ],
    });
  };

  return (
    <>
      <Typography variant='h4' component='h3' sx={{ marginBottom: '1em' }}>
        Invite Individual Member
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} id='individual-member-invite'>
        <Grid container spacing={1} direction='row'>
          <Grid item xs={6}>
            <Controller
              name='first_name'
              control={control}
              rules={{ required: 'First name is required' }}
              render={({ field }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <TextField
                    {...fieldProps}
                    innerRef={ref}
                    fullWidth
                    label='First name'
                    placeholder='Enter text'
                    required
                    error={Boolean(
                      touchedFields.first_name && errors?.first_name,
                    )}
                    errorMessage={
                      touchedFields.first_name &&
                      typeof errors?.first_name?.message === 'string' &&
                      errors?.first_name?.message
                    }
                    id={field.name}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name='last_name'
              control={control}
              rules={{ required: 'Last name is required' }}
              render={({ field }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <TextField
                    {...fieldProps}
                    innerRef={ref}
                    fullWidth
                    label='Last name'
                    placeholder='Enter text'
                    required
                    error={Boolean(
                      touchedFields.last_name && errors?.last_name,
                    )}
                    errorMessage={
                      touchedFields.last_name &&
                      typeof errors?.last_name?.message === 'string' &&
                      errors?.last_name?.message
                    }
                    id={field.name}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          direction='row'
          alignItems='center'
          sx={{ marginTop: '1em' }}
        >
          <Grid item xs={10}>
            <Controller
              name='email'
              control={control}
              rules={{
                required: 'Email address is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i,
                  message: 'Invalid email address',
                },
                validate: {
                  email: email =>
                    !isDuplicateEmail(email) ||
                    'This email has already been registered or invited',
                },
              }}
              render={({ field }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <TextField
                    {...fieldProps}
                    innerRef={ref}
                    fullWidth
                    label='Email Address'
                    placeholder='Enter text'
                    required
                    error={Boolean(touchedFields.email && errors?.email)}
                    errorMessage={
                      touchedFields.email &&
                      typeof errors?.email?.message === 'string' &&
                      errors?.email?.message
                    }
                    id={field.name}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ textAlign: 'center' }}>
            <Button
              type='submit'
              form='individual-member-invite'
              disabled={!isValid || !isDirty}
              text='Send Invite'
              size='small'
              loading={isPending}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default IndividualInvite;
