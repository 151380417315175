import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useMutation } from '@tanstack/react-query';
import {
  Typography,
  Checkbox,
  FormControlLabel,
  Stack,
  InputLabel,
} from '@mui/material';
import {
  Select,
  TableMUI,
  Button,
  useToast,
} from '@src/components/sc-design-system';
import CsvDrop from '@src/components/common/CsvDrop';
import Invitee from '@src/models/Invitee';

interface InviteFromCSVProps {
  csvFileContent: string;
  setCsvFileContent: (s: string) => void;
  isDuplicateEmail: (e: string) => boolean;
  refetch: Function;
  inviteMembers: (p: { invitees: Partial<Invitee>[] }) => Promise<unknown>;
  setMembersInvited?: (b: boolean) => void;
}

const InviteFromCSV = (props: InviteFromCSVProps) => {
  const { csvFileContent, setCsvFileContent, isDuplicateEmail, refetch } =
    props;
  const toast = useToast();
  const [fileName, setFileName] = React.useState('');
  const [baseTableData, setBaseTableData] = React.useState([]);
  const [currentTableData, setCurrentTableData] = React.useState([]);
  const [columnMap, setColumnMap] = React.useState(['', '', '', '']);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [csvHeader, setCSVHeader] = React.useState<string[] | false>(false);

  const isValid = obj => {
    return (
      Object.values(obj).includes('email') &&
      Object.values(obj).includes('first_name') &&
      Object.values(obj).includes('last_name')
    );
  };

  const { mutate: inviteMembers, isPending } = useMutation({
    mutationFn: async () => {
      const invitees = currentTableData
        // Skip if email is null or invalid
        .filter(r => {
          const email = r[columnMap.indexOf('email')];
          if (!email) return false;
          let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
          if (!regex.test(email.replace(/\s/g, ''))) {
            return false;
          }
          return email;
        })
        .filter(r => !isDuplicateEmail(r[columnMap.indexOf('email')]))
        .map(r => {
          const invitee = new Invitee({
            invitee_first_name: r[columnMap.indexOf('first_name')],
            invitee_last_name: r[columnMap.indexOf('last_name')],
            invitee_email: r[columnMap.indexOf('email')].toLowerCase(),
          });
          return invitee;
        });
      await props.inviteMembers({ invitees });
    },
    onSuccess: () => {
      setCsvFileContent('');
      setBaseTableData([]);
      setCurrentTableData([]);
      setColumnMap([]);
      setFileName('');
      refetch();
      props.setMembersInvited && props.setMembersInvited(true);
      toast.success('Members successfully invited');
    },
    onError() {
      toast.error('Could not invite members');
    },
  });

  const columnHelper = createColumnHelper();
  const columns = React.useMemo(() => {
    if (csvFileContent) {
      return Object.keys(currentTableData[0]).map(col => {
        return columnHelper.accessor(col, {
          header: () => (
            <>
              {csvHeader && (
                <InputLabel sx={{ margin: '.5em' }}>
                  {csvHeader[col]}
                </InputLabel>
              )}
              <Select
                fullWidth
                value={columnMap[col]}
                placeholder='Map column'
                onChange={event => {
                  const val = event.target.value;
                  if (val === 'ignore') {
                    delete columnMap[col];
                  } else {
                    columnMap[col] = val;
                  }
                  setColumnMap([...columnMap]);
                  setIsDisabled(!isValid(columnMap));
                }}
                options={[
                  {
                    options: [
                      { label: 'first_name', value: 'first_name' },
                      { label: 'last_name', value: 'last_name' },
                      { label: 'email', value: 'email' },
                      { label: 'ignore', value: 'ignore' },
                    ],
                  },
                ]}
              />
            </>
          ),
          cell: info => {
            // lower case email address in import table
            const value = info.getValue() as string;
            return <span>{col === '2' ? value?.toLowerCase() : value}</span>;
          },
        });
      });
    }
  }, [currentTableData, csvFileContent, columnMap, columnHelper, csvHeader]);

  return (
    <>
      <Typography variant='h4' component='h3' sx={{ marginBottom: '1em' }}>
        Invite from CSV
      </Typography>
      <CsvDrop
        currentFileName={fileName}
        setCsvFileContent={setCsvFileContent}
        setBaseTableData={setBaseTableData}
        setCurrentTableData={setCurrentTableData}
        setFileName={setFileName}
      />
      {csvFileContent && (
        <Stack spacing={2}>
          {currentTableData.length >= 1 && (
            <div style={{ marginTop: '1em' }}>
              <FormControlLabel
                sx={{ width: 'fit-content' }}
                control={
                  <Checkbox
                    onChange={e => {
                      if (e.target.checked) {
                        setCurrentTableData(baseTableData.slice(1));
                        setCSVHeader(baseTableData.slice(0, -1)[0]);
                      } else {
                        setCurrentTableData(baseTableData);
                        setCSVHeader(false);
                      }
                    }}
                  />
                }
                label='First row contains column headers'
              />
            </div>
          )}
          <TableMUI columns={columns} data={currentTableData} />
          <Button
            sx={{ width: 'fit-content' }}
            disabled={isDisabled || isPending}
            loading={isPending}
            onClick={async () => inviteMembers()}
            text='Send Invites'
          />
        </Stack>
      )}
    </>
  );
};

export default InviteFromCSV;
