import * as React from 'react';
import { Link, useMatch } from 'react-router-dom';
import { Tooltip, Typography } from '@mui/material';
import { useStore } from '@src/models/Store';
import User from '@src/models/User';
import { ActiveClientKey, useActiveClient } from '@src/hooks/useActiveClient';
import { SCLogo, useBreadcrumbs } from '@src/components/sc-design-system';
import './ShellSidebar.less';
import { handleKeyboardNav } from '@src/utils';
import aidaLogo from '@shared/theme/src/assets/aida-logo-white.svg';
import WebIcon from '@mui/icons-material/Web';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import BusinessIcon from '@mui/icons-material/Business';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const SidebarCollapsedContext = React.createContext<boolean>(true);

export let ShellSidebar = ({
  collapsed,
  toggleSidebar,
}: {
  collapsed: boolean;
  toggleSidebar: Function;
}) => {
  const { user } = useStore();
  const {
    client: scAdminClient,
    activeClientMeta: scAdminClientMeta,
    isLoading: isSCAdminClientLoading,
  } = useActiveClient({
    enabled: user.is_admin,
    queryKey: ActiveClientKey.sc_admin_active_client_id,
  });
  const {
    client: userClient,
    activeClientMeta: userClientMeta,
    isLoading: isUserClientLoading,
  } = useActiveClient({
    queryKey: ActiveClientKey.profile_active_client_id,
  });
  const { resetBreadcrumbs } = useBreadcrumbs();
  const isLoading = user.is_admin
    ? isSCAdminClientLoading
    : isUserClientLoading;

  const navItems = React.useMemo(() => {
    return getLinkConfigsForUserRole(
      user,
      userClient,
      userClientMeta,
      scAdminClient,
      scAdminClientMeta,
      isLoading,
    );
  }, [
    user,
    userClient,
    userClientMeta,
    scAdminClient,
    scAdminClientMeta,
    isLoading,
  ]);

  let classes = 'shell-sidebar';
  if (collapsed) {
    classes += ' shell-sidebar-collapsed';
  }

  return (
    <SidebarCollapsedContext.Provider value={collapsed}>
      <div className={classes}>
        <div className={`logo-container ${collapsed ? 'collapsed' : ''}`}>
          <SCLogo
            showText={!collapsed}
            size={collapsed ? 'default' : 'large'}
            className='logo'
          />
          {!collapsed && <Divider />}
        </div>
        <div
          className='shell-sidebar-collapse-controller'
          onClick={() => toggleSidebar()}
          onKeyDown={e => handleKeyboardNav(e.key, toggleSidebar)}
          role='button'
          tabIndex={0}
          aria-label={collapsed ? 'Expand' : 'Collapse'}
        >
          <KeyboardArrowLeftIcon />
        </div>
        <nav className='side-nav-body' role='navigation' aria-label='Side Nav'>
          {navItems.map((item, i) => {
            if (!item) return null;
            const { type, CollapsedIcon, text, props } = item;
            if (type === 'section_header') {
              return (
                <div
                  className='side-nav-section-header'
                  key={`section-header-${text}`}
                >
                  {CollapsedIcon}
                  <span
                    className={`side-nav-section-header-text ${
                      collapsed ? 'collapsed' : ''
                    }`}
                    aria-label={text}
                  >
                    {text}
                  </span>
                </div>
              );
            }
            if (type === 'divider') {
              return <Divider key={`section-divider-${i}`} />;
            }
            if (type === 'nav_link') {
              return (
                <NavItem
                  {...props}
                  key={`${props.to}-link`}
                  onClick={() => {
                    resetBreadcrumbs([{ path: props.to, label: props.title }]);
                  }}
                />
              );
            }
          })}
        </nav>
      </div>
    </SidebarCollapsedContext.Provider>
  );
};

interface NavItemConfig {
  type: string;
  CollapsedIcon?: JSX.Element;
  text?: string;
  props?: {
    to: string;
    title: string;
    NavIcon: JSX.Element;
  };
}

const getLinkConfigsForUserRole = (
  user: User,
  userClient: any,
  userClientMeta: any,
  scAdminClient: any,
  scAdminClientMeta: any,
  isLoading: boolean,
): Array<NavItemConfig | null> => {
  return [
    ...getClientAdminNavItems(
      user,
      scAdminClient || userClient,
      scAdminClient ? scAdminClientMeta : userClientMeta,
      isLoading,
    ),
    ...getCoachNavItems(user),
    ...getMySkillCycleNavItems(user, userClient, userClientMeta),
    ...getSCAdminNavItems(user),
  ];
};

const getClientAdminNavItems = (
  user: User,
  client: any,
  clientMeta: any,
  isLoading: boolean,
): Array<NavItemConfig | null> => {
  if (
    isLoading ||
    !client ||
    (!clientMeta?.userIsClientAdmin && !user.is_admin)
  ) {
    return [];
  }

  return [
    {
      type: 'section_header',
      CollapsedIcon: <BusinessIcon />,
      text: client?.name,
    },
    {
      type: 'nav_link',
      props: {
        to: `/my-business/${client?.id}`,
        title: 'Programs',
        NavIcon: <WebIcon />,
      },
    },
    {
      type: 'nav_link',
      props: {
        to: `/my-business/${client?.id}/performance`,
        title: 'Performance',
        NavIcon: <InsertChartOutlinedIcon />,
      },
    },
    {
      type: 'nav_link',
      props: {
        to: `/insights/${client?.id}`,
        title: 'Surveys & Insights',
        NavIcon: <FeedOutlinedIcon />,
      },
    },
    {
      type: 'nav_link',
      props: {
        to: `/my-business/${client?.id}/admin`,
        title: 'Admin',
        NavIcon: <TuneIcon />,
      },
    },
    { type: 'divider' },
  ];
};

const getCoachNavItems = (user: User): Array<NavItemConfig | null> => {
  if (!user.is_coach) return [];

  return [
    {
      type: 'section_header',
      CollapsedIcon: <WorkOutlineIcon />,
      text: 'My Clients',
    },
    {
      type: 'nav_link',
      props: {
        to: `/my-coachees`,
        title: 'My Coachees',
        NavIcon: <GroupOutlinedIcon />,
      },
    },
    { type: 'divider' },
  ];
};

const getMySkillCycleNavItems = (
  user: User,
  client: any,
  clientMeta: any,
): Array<NavItemConfig | null> => {
  return [
    {
      type: 'section_header',
      CollapsedIcon: <PersonOutlinedIcon />,
      text: 'My SkillCycle',
    },
    client?.aida_enabled && client?.aida_terms_accepted
      ? {
          type: 'nav_link',
          props: {
            to: `/aida`,
            title: 'Aida',
            NavIcon: <img alt='Aida' src={aidaLogo} />,
          },
        }
      : null,
    {
      type: 'nav_link',
      props: {
        to: `/performance-and-growth`,
        title: 'Performance and Growth',
        NavIcon: <TrackChangesIcon />,
      },
    },
    user.isMentor && !user.is_coach
      ? {
          type: 'nav_link',
          props: {
            to: `/my-mentees`,
            title: 'My Mentees',
            NavIcon: <GroupOutlinedIcon />,
          },
        }
      : null,
    {
      type: 'nav_link',
      props: {
        to: `/my-programs`,
        title: 'Learning and Development',
        NavIcon: <ListAltIcon />,
      },
    },
    clientMeta?.hasDirectReports
      ? {
          type: 'nav_link',
          props: {
            to: `/my-team`,
            title: 'My Team',
            NavIcon: <GroupsOutlinedIcon />,
          },
        }
      : null,
  ];
};

const getSCAdminNavItems = (user: User): Array<NavItemConfig | null> => {
  if (!user.is_admin) return [];
  return [
    { type: 'divider' },
    {
      type: 'section_header',
      CollapsedIcon: <SettingsOutlinedIcon />,
      text: 'SkillCycle Admin',
    },
    {
      type: 'nav_link',
      props: {
        to: '/administration',
        title: 'Dashboard',
        NavIcon: <SpaceDashboardOutlinedIcon />,
      },
    },
    {
      type: 'nav_link',
      props: {
        to: '/administration/users',
        title: 'Users',
        NavIcon: <PersonAddAltIcon />,
      },
    },
    {
      type: 'nav_link',
      props: {
        to: '/administration/clients',
        title: 'Clients',
        NavIcon: <WorkOutlineIcon />,
      },
    },
    {
      type: 'nav_link',
      props: {
        to: '/administration/sows',
        title: 'SOWs',
        NavIcon: <ContentPasteIcon />,
      },
    },
    {
      type: 'nav_link',
      props: {
        to: '/administration/programs',
        title: 'Programs',
        NavIcon: <WebIcon />,
      },
    },
    {
      type: 'nav_link',
      props: {
        to: '/resources',
        title: 'Resources',
        NavIcon: <MenuBookIcon />,
      },
    },
    {
      type: 'nav_link',
      props: {
        to: '/surveys',
        title: 'Surveys',
        NavIcon: <FeedOutlinedIcon />,
      },
    },
  ];
};

let NavItem = ({
  to,
  title,
  NavIcon,
  style,
  onClick,
}: {
  to: string;
  title: string;
  NavIcon: JSX.Element;
  style?: React.CSSProperties;
  onClick: Function;
}) => {
  let collapsed = React.useContext(SidebarCollapsedContext);
  let match = useMatch({ path: to, end: true });
  let classes = `shell-nav-item item-${title.toLowerCase().replace(' ', '')}`;
  if (match) {
    classes += ' active';
  }
  return (
    // Only set `title` when collapsed. Antd won't render a tooltip that
    // doesn't that have a `title`, and we only need the tooltip if the
    // sidebar is collapsed where only an icon is visible.
    <Tooltip placement='right' title={collapsed && title}>
      <Link
        to={to}
        className={classes}
        style={style}
        aria-label={title}
        onClick={() => onClick()}
      >
        {NavIcon}
        <Typography
          variant='body2'
          className={`shell-nav-item-text ${collapsed ? 'collapsed' : ''}`}
        >
          {title}
        </Typography>
      </Link>
    </Tooltip>
  );
};

let Divider = () => {
  return <div className='shell-nav-divider' />;
};
