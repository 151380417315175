import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import { useStore } from '@src/models/Store';
import { useActiveClient, ActiveClientKey } from './useActiveClient';

const useProgramQueries = (programId: string, clientId?: string) => {
  const store = useStore();
  const { client: activeClient } = useActiveClient({
    queryKey: ActiveClientKey.profile_active_client_id,
  });
  const isSCAdminOrClientMember = useMemo(() => {
    return (
      store.user.is_admin ||
      (activeClient?.id === clientId &&
        store.user?.clients?.some(({ id }) => id === clientId))
    );
  }, [store, activeClient, clientId]);

  const useGetProgramFields = return_fields =>
    useQuery({
      queryKey: ['program', programId, return_fields],
      queryFn: async () =>
        api.programs.getProgramFields(programId, { return_fields }),
      select: ({ data }) => data.invitees || [],
      enabled: !!programId,
    });

  const useGetProgramMembers = (
    queryParams: {
      keyword?: string;
      type?: 'administrators' | 'coaches' | 'coachees';
      memberType?: ClientMemberTypes;
      page?: number;
      return_fields?: string[];
      include_invitees?: boolean;
      include_hris?: boolean;
    } = {},
    { enabled } = { enabled: true },
  ) => {
    const { data, ...rest } = useQuery({
      queryKey: ['program', programId, 'members', queryParams],
      queryFn: async () =>
        api.programs.getProgramMembers(programId, queryParams),
      select: res => {
        const { data, totalRecords } = res.data;
        return {
          data,
          totalRecords,
        };
      },
      enabled: isSCAdminOrClientMember && enabled,
    });

    return {
      members: data?.data ?? null,
      totalRecords: data?.totalRecords ?? null,
      ...rest,
    };
  };

  return {
    useGetProgramFields,
    useGetProgramMembers,
  };
};

export { useProgramQueries };
