import React, { FC } from 'react';
import { ConfirmDialog } from '@src/components/sc-design-system';
import { useMergeLink } from '@mergeapi/react-merge-link';
import { api } from '@src/lib/client';
import Client from '@src/models/Client';
import { useQueryClient } from '@tanstack/react-query';

const MergeLink: FC<{
  client: Client;
  linkToken: string;
  openConfirmModal: boolean;
  setOpenConfirmModal: (ocm: boolean) => void;
}> = ({ client, linkToken, openConfirmModal, setOpenConfirmModal }) => {
  const queryClient = useQueryClient();
  const onSuccess = React.useCallback(
    async public_token => {
      await api.integrations.getMergeAccountToken({
        mergePublicToken: public_token,
        clientId: client.id,
      });
    },
    [client.id],
  );

  const onExit = React.useCallback(async () => {
    queryClient.invalidateQueries({
      queryKey: ['client', client.id, 'mergeHrisIntegration'],
    });
  }, [client.id, queryClient]);

  const { open, isReady } = useMergeLink({
    linkToken,
    onSuccess,
    onExit,
  });

  return (
    <ConfirmDialog
      open={openConfirmModal}
      setOpenModal={setOpenConfirmModal}
      title='Connect your HRIS'
      body='Completing this action will override any existing data for members and departments'
      buttonProps={{
        disabled: !isReady, // TODO: NOT ALWAYS WORKING
        text: 'Connect',
        onClick: open,
      }}
    />
  );
};

export { MergeLink };
