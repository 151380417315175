import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import {
  Button,
  DialogTitle,
  Select,
  DateTimePicker,
} from '@src/components/sc-design-system';
import Program from '@src/models/Program';
import Session, { SessionStatusOverride } from '@src/models/Session';
import User from '@src/models/User';
import { SessionMember } from '@src/models/Session';
import dayjs from 'dayjs';

export interface CreateSessionModalPropsIF {
  member: User;
  user: User;
  program: Program;
  sessionsTrackerInfo: any;
  setSessionTrackerInfo: Function;
  setCreateSessionModalOpen: Function;
}

const CreateSessionModal: FC<CreateSessionModalPropsIF> = observer(
  ({
    member,
    user,
    program,
    sessionsTrackerInfo,
    setSessionTrackerInfo,
    setCreateSessionModalOpen,
  }) => {
    const coach = member.selected_coach;
    const now = Date.now();
    const defaultCurrentStatus = 'completed';
    const type = 'coaching';

    const [session, setSession] = useState<Session>(
      new Session({
        calendly_event: {},
        client_id: program.client_id,
        members: [
          new SessionMember({ id: member.id, role: 'coachee' }),
          new SessionMember({ id: coach.id, role: 'coach' }),
        ],
        current_status: defaultCurrentStatus,
        program_id: program.id,
        sow_id: program.sow_id,
        type,
      }),
    );

    return (
      <Dialog
        maxWidth='md'
        open
        onClose={() => setCreateSessionModalOpen(false)}
      >
        <DialogTitle
          title={`${program.name} - ${member.first_name} ${member.last_name} with ${coach?.first_name} ${coach?.last_name}`}
          buttonOnClick={() => setCreateSessionModalOpen(false)}
        />
        <DialogContent>
          <form id='create-session'>
            <Stack direction='row' spacing={2} marginBottom='1em'>
              <DateTimePicker
                name='start_time'
                label='Session Start'
                required
                onChange={date => {
                  session.calendly_event.start_time = date.valueOf();
                  setSession(session);
                }}
                disableFuture
                fullWidth
              />
              <DateTimePicker
                name='end_time'
                label='Session End'
                required
                onChange={date => {
                  session.calendly_event.end_time = date.valueOf();
                  setSession(session);
                }}
                maxDate={
                  session?.calendly_event?.start_time
                    ? dayjs(session?.calendly_event?.start_time)
                    : null
                }
                disableFuture
                fullWidth
              />
            </Stack>
            <Select
              name='session_status'
              label='Session status'
              required
              options={[
                {
                  options: [
                    {
                      label: 'Completed',
                      value: 'completed',
                    },
                    {
                      label: 'Missed',
                      value: 'scheduled',
                    },
                  ],
                },
              ]}
              value={session?.current_status}
              onChange={e => {
                const value = e.target.value as string;
                session.current_status = value;
                if (value === 'scheduled') {
                  session.status_overridden = new SessionStatusOverride({
                    original_status: 'scheduled',
                    notes: 'Session created manually',
                    user_id: user.id,
                    timestamp: now,
                  });
                }
                setSession(session);
              }}
              fullWidth
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            text='Cancel'
            onClick={() => setCreateSessionModalOpen(false)}
            variant='outlined'
          />
          <Button
            sx={{ marginLeft: '1em' }}
            key='save'
            text='Create Session'
            form='create-session'
            type='submit'
            disabled={
              !session?.calendly_event.start_time ||
              !session?.calendly_event.end_time ||
              !session?.current_status
            }
            onClick={() => {
              session.save(false, member.id);
              // Need this property for SessionTracker
              session.member_info = {
                [member.id]: {
                  first_name: member.first_name,
                  last_name: member.last_name,
                },
                [coach.id]: {
                  first_name: coach.first_name,
                  last_name: coach.last_name,
                },
              };
              sessionsTrackerInfo.list.push(session);
              sessionsTrackerInfo.list.sort((a, b) =>
                a.calendly_event.start_time > b.calendly_event.start_time
                  ? 1
                  : -1,
              );
              setSessionTrackerInfo({ ...sessionsTrackerInfo });
              setSession(null);
              setCreateSessionModalOpen(false);
            }}
          />
        </DialogActions>
      </Dialog>
    );
  },
);

export default CreateSessionModal;
