import React from 'react';
import { IconButton, Stack } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useQueryClient } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import {
  Button,
  Card,
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
  useToast,
  ConfirmDialog,
  TableMUI,
  TextField,
} from '@src/components/sc-design-system';
import { ResourceEditorModal } from './ResourceEditorModal';
import { useResources } from '@src/hooks/useResources';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import TheatersOutlinedIcon from '@mui/icons-material/TheatersOutlined';
import LinkIcon from '@mui/icons-material/Link';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const iconNames = {
  embed: <TheatersOutlinedIcon fontSize='medium' />,
  link: <LinkIcon fontSize='medium' />,
  document: <ArticleOutlinedIcon fontSize='medium' />,
};

const ResourcesManager: React.FunctionComponent = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [keyword, setKeyword] = React.useState('');
  const [openModal, setOpenModal] = React.useState(false);
  const [activeResource, setActiveResource] = React.useState<Resource>();
  const [openConfirmModal, setOpenConfirmModal] = React.useState<
    string | false
  >(false);
  const {
    resources,
    refetch: refetchResources,
    isLoading,
    isFetching,
  } = useResources();

  function openEditorModal(r) {
    setActiveResource(r);
    setOpenModal(true);
  }

  const columnHelper = createColumnHelper<Resource>();
  const columns = React.useMemo(() => {
    const deleteResource = async (id: string) => {
      try {
        await api.resources.remove(id);
        queryClient.invalidateQueries({
          queryKey: ['resources'],
        });
      } catch (e) {
        toast.error('Could not delete resource');
      }
    };
    return [
      {
        id: 'type_icon',
        cell: info => iconNames[info.row.original.type],
        header: '',
        size: 30,
        meta: { fitContent: true },
      },
      columnHelper.accessor('name', {
        cell: info => info.getValue(),
        header: 'Name',
      }),
      columnHelper.accessor('type', {
        cell: info => (
          <span style={{ textTransform: 'capitalize' }}>{info.getValue()}</span>
        ),
        header: 'Type',
      }),
      columnHelper.accessor('id', {
        cell: info => {
          const resource = info.row.original;
          return (
            <Stack direction='row' alignItems='center' spacing={1}>
              <IconButton
                size='small'
                onClick={() => openEditorModal(resource)}
                color='primary'
              >
                <EditOutlinedIcon fontSize='small' />
              </IconButton>
              <ConfirmDialog
                open={openConfirmModal === resource.id}
                setOpenModal={setOpenConfirmModal}
                buttonProps={{
                  text: 'Delete',
                  onClick: async () => deleteResource(resource.id),
                }}
                title='Delete resource'
                body={`Are you sure you want to delete ${resource.type} ${resource.name}?`}
              />
              <IconButton onClick={() => setOpenConfirmModal(resource.id)}>
                <DeleteOutlineOutlinedIcon fontSize='small' color='error' />
              </IconButton>
            </Stack>
          );
        },
        header: 'Actions',
        size: 70,
        meta: { fitContent: true },
      }),
    ];
  }, [columnHelper, openConfirmModal, toast, queryClient]);

  return (
    <View>
      <ViewHeader
        title='Resources'
        titleIcon={<MenuBookIcon fontSize='large' />}
        actions={[
          {
            render: (
              <Button
                key='new_resource'
                text='New Resource'
                size='small'
                onClick={() => openEditorModal(null)}
              />
            ),
          },
        ]}
      />
      <ViewBody>
        <ViewBodySection title='Resources List'>
          <Card>
            <Stack spacing={3}>
              <TextField
                id='keyword'
                fullWidth
                value={keyword}
                onChange={e => setKeyword(e.target.value)}
                placeholder='Search resources...'
              />
              <ResourceEditorModal
                resource={activeResource}
                refetchResources={refetchResources}
                setOpenModal={setOpenModal}
                openModal={openModal}
                setActiveResource={setActiveResource}
              />
              <TableMUI
                data={resources?.filter(r =>
                  r.name
                    .toLocaleLowerCase()
                    .includes(keyword.toLocaleLowerCase()),
                )}
                columns={columns}
                initialRowsPerPage={25}
                emptyState='No resources found.'
                dataIsLoading={isLoading || isFetching}
              />
            </Stack>
          </Card>
        </ViewBodySection>
      </ViewBody>
    </View>
  );
};

export default ResourcesManager;
