import React from 'react';
import { TabPanelWrapper } from '@src/components/common/TabPanelWrapper';
import { SurveySubmissionStack } from '@src/components/common/SurveySubmissionStack';
import { Spin } from '@src/components/sc-design-system';
import { Typography } from '@mui/material';

interface ResponsesPanelProps {
  results: any[];
  isLoading: boolean;
  activeTab: number;
  tabIndex: number;
}

export function ResponsesPanel(props: ResponsesPanelProps) {
  const { activeTab, tabIndex, isLoading, results } = props;
  return (
    <TabPanelWrapper value={activeTab} index={tabIndex}>
      {isLoading ? (
        <Spin sectionLoader />
      ) : results && Object.keys(results).length === 0 ? (
        <Typography
          variant='body2'
          color='grey.700'
          sx={{ textAlign: 'center', marginTop: '.5em' }}
        >
          No survey answers have been submitted
        </Typography>
      ) : (
        <SurveySubmissionStack results={results} />
      )}
    </TabPanelWrapper>
  );
}
