import React from 'react';
import { useLocation } from 'react-router-dom';

function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Used in onKeyDown handlers as a predicate for keyboard navigation
 * @param key - The key that was pressed
 * @param cd - The function to be fired if predicate is met
 */
function handleKeyboardNav(key: string, cb: Function): void {
  if (key === 'Enter' || key === ' ') {
    cb();
  }
}

export { useQueryParams, handleKeyboardNav };
