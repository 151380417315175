import React, { FC } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { Link } from '@mui/material';
import uploadImageUri from '@src/lib/uploadImageUri';
import { api } from '@src/lib/client';
import { ClientType } from '@shared/schemas';

interface ClientLogoInputProps {
  client: ClientType;
}

const ClientLogoInput: FC<ClientLogoInputProps> = ({ client }) => {
  const queryClient = useQueryClient();
  const { mutate: uploadImage } = useMutation({
    mutationFn: async ({ image }: { image: string }) => {
      return api.images.uploadImage({
        resourceId: client.id,
        image,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['avatar', client.id],
      });
    },
  });

  return (
    <>
      <label htmlFor='upload-button'>
        <Link variant='a2blank'>
          {!client?.logo_image ? 'Upload' : 'Change'} Logo
        </Link>
      </label>
      <input
        onChange={async e => {
          const file = e.target.files[0];
          if (file.size <= 1000000) {
            const imgUri = await uploadImageUri(file);
            await uploadImage({ image: imgUri });
          }
        }}
        style={{
          visibility: 'hidden',
          position: 'absolute',
          left: '-999999px',
        }}
        type='file'
        id='upload-button'
        accept='image/jpeg, image/png'
      />
    </>
  );
};

export { ClientLogoInput };
