import React, { useState, useMemo } from 'react';
import { Tabs, Tab } from '@mui/material';
import { generateTabProps } from '@src/components/common/TabPanelWrapper';
import { AidaPanel, GoalsPanel, ResponsesPanel } from './tabs';

interface CoacheeTabsProps {
  results: any[];
  goals: any;
  isLoading: boolean;
  hideSurveyResponses: boolean;
  programId: string;
  isAidaActive: boolean;
  clientId: string;
  userId: string;
}

function CoacheeTabs(props: CoacheeTabsProps) {
  const {
    results,
    goals,
    isLoading,
    hideSurveyResponses,
    programId,
    isAidaActive,
    clientId,
    userId,
  } = props;
  const [activeTab, setActiveTab] = useState(0);
  const tabItems = useMemo(() => {
    const items = [];
    if (isAidaActive) {
      items.push({ label: 'Aida' });
    }
    if (!hideSurveyResponses) {
      items.push({ label: 'Survey Responses' });
    }
    if (programId) {
      items.push({ label: 'Goals' });
    }
    return items.map((item, idx) => ({ ...item, ...generateTabProps(idx) }));
  }, [hideSurveyResponses, programId, isAidaActive]);

  const tabPanels = useMemo(() => {
    return tabItems.map((tab, idx) => {
      if (tab.label === 'Aida') {
        return function aidaPanel() {
          return (
            <AidaPanel
              activeTab={activeTab}
              tabIndex={idx}
              clientId={clientId}
              userId={userId}
            />
          );
        };
      }
      if (tab.label === 'Survey Responses') {
        return function responsesPanel() {
          return (
            <ResponsesPanel
              activeTab={activeTab}
              tabIndex={idx}
              isLoading={isLoading}
              results={results}
            />
          );
        };
      }
      return function goalsPanel() {
        return (
          <GoalsPanel activeTab={activeTab} tabIndex={idx} goals={goals} />
        );
      };
    });
  }, [isLoading, results, tabItems, goals, activeTab, clientId, userId]);

  return (
    <>
      <Tabs onChange={(_, v) => setActiveTab(v)} value={activeTab}>
        {tabItems.map(tab => (
          <Tab key={tab.label} label={tab.label} />
        ))}
      </Tabs>
      {tabPanels.map((TabPanel, i) => (
        <TabPanel key={`panel-${i}`} />
      ))}
    </>
  );
}

export default CoacheeTabs;
