import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';

obey.creator('uuid', () => uuidv4());
obey.creator('now', () => Date.now());

export const MeetingSchema = {
  id: { type: 'string', required: true, creator: 'uuid' },
  created_at: { type: 'number', creator: 'now' },
  whereby_room_url: { type: 'string' },
  end_date: { type: 'number' },
};

// eslint-disable-next-line no-unused-vars
export type MeetingType = { [K in keyof typeof MeetingSchema]?: any };

export const MeetingModel = obey.model(MeetingSchema);
