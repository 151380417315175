import React, { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import {
  Card,
  Spin,
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
} from '@src/components/sc-design-system';
import { Link, Typography, Alert, Stack, Divider } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { UserProgramCard } from '@src/components/common/UserProgramCard/UserProgramCard';
import { UpNextCard } from '@src/components/common/UpNextCard/UpNextCard';
import { InterviewCard } from '@src/components/common/InterviewCard/InterviewCard';
import * as useVerbiage from '@shared/lib/src/use-verbiage';
import { SessionDetailsViewModel } from '@src/components/common/SessionDetails/SessionDetailsViewModel';
import { api } from '@src/lib/client';
import { SessionDetailsModal } from '@src/components/common/SessionDetails/SessionDetailsModal';
import { useQueryParams } from '@src/utils';
import { UserProfileDrawer } from '@src/components/common/UserProfileDrawer';
import PersonOutlined from '@mui/icons-material/PersonOutlined';
import PersonCheckOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import PeopleOutlined from '@mui/icons-material/PeopleOutlined';
import { useUser } from '@src/hooks/useUser';
import { useUserQueries } from '@src/hooks/useUserQueries';

const CoachHome: FC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { useGetUserPrograms } = useUserQueries(user.id);
  const { userPrograms, isLoading: loadingPrograms } =
    useGetUserPrograms('coach');

  const query = useQueryParams();
  const v = useVerbiage.init(
    user.isMentor && !user.is_coach ? ['isMentor'] : [],
  );
  const session_id = query.get('session_id');

  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [loadingInterviews, setLoadingInterviews] = useState(false);
  const [session, setSession] = useState(undefined);
  const [programType, setProgramType] = useState(undefined);
  const [loadingSession, setLoadingSession] = useState(false);

  const activeCoachees = useMemo(() => {
    if (loadingPrograms) return [];
    return userPrograms?.filter(
      p => p.user_status === 'in-progress' || p.user_status === 'completed',
    );
  }, [userPrograms, loadingPrograms]);

  const newCoachees = useMemo(() => {
    if (loadingPrograms) return [];
    return userPrograms?.filter(p => p.user_status === 'started');
  }, [userPrograms, loadingPrograms]);

  const inactiveCoachees = useMemo(() => {
    if (loadingPrograms) return [];
    return userPrograms?.filter(p => p.user_status === 'inactive');
  }, [userPrograms, loadingPrograms]);

  const getCoachingInterviews = async () => {
    setLoadingInterviews(true);
    await user.getInterviews('coach');
    setLoadingInterviews(false);
  };

  const getSession = async () => {
    setLoadingSession(true);
    const res = await api.sessions.get({ sessionId: session_id });
    const sessionData = res.data.data[0];
    sessionData.members = sessionData.members.map(data => {
      if (sessionData.member_info[data.id])
        return { ...data, ...sessionData.member_info[data.id] };
      return data;
    });
    setSession(
      new SessionDetailsViewModel(
        user.id,
        'coach',
        sessionData,
        sessionData.program_info.type,
      ),
    );
    setProgramType(sessionData.program_info.type);
    setLoadingSession(false);
  };

  useEffect(() => {
    getCoachingInterviews();
    if (session_id) {
      (async function () {
        await getSession();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    user && (
      <View>
        <ViewHeader
          title={`My ${v('coachees', true)}`}
          titleIcon={<PeopleOutlined fontSize='large' />}
        />
        <ViewBody>
          <ViewBodySection title='Overview'>
            {user.coaching_topics?.length < 2 ||
            user.biography?.length < 10 ||
            user.calendly_username?.length < 5 ? (
              <>
                <Alert severity='error' sx={{ marginBottom: 0 }}>
                  <Stack direction='row' alignItems='center' gap={1}>
                    <Typography variant='body2'>
                      <strong>
                        Your profile needs to be completed before you will
                        appear in {v('coach')} searches.{' '}
                      </strong>
                    </Typography>
                    <Link
                      component='button'
                      variant='a2'
                      onClick={() => setIsProfileOpen(true)}
                    >
                      Edit Profile &raquo;
                    </Link>
                  </Stack>
                </Alert>
                <UserProfileDrawer
                  open={isProfileOpen}
                  onClose={() => setIsProfileOpen(false)}
                />
                <Divider />
              </>
            ) : null}
            <Card titleIcon={<ScheduleIcon fontSize='large' />} title='Up Next'>
              <UpNextCard scRole='coach' />
            </Card>
            {session &&
            session.current_status === 'completed' &&
            !loadingSession ? (
              <SessionDetailsModal
                session={session}
                userRole='coach'
                open={session && !loadingSession}
                onClose={() => {
                  setSession(undefined);
                  navigate(
                    `/my-${
                      user.isMentor && !user.is_coach ? 'mentees' : 'coachees'
                    }`,
                  );
                }}
                programType={programType}
              />
            ) : null}
            <Card
              titleIcon={<PeopleOutlined fontSize='large' />}
              title={`Active ${v('coachees', true)}`}
            >
              {!loadingPrograms && activeCoachees?.length ? (
                activeCoachees.map(program => (
                  <UserProgramCard
                    key={`active-coachee-${program.id}${program.coachee.id}`}
                    user={user}
                    program={program}
                    scRole='coach'
                  />
                ))
              ) : !loadingPrograms && activeCoachees?.length === 0 ? (
                <Typography
                  variant='body1'
                  color='grey.700'
                  sx={{ textAlign: 'center' }}
                >
                  You have no active {v('coachees')}
                </Typography>
              ) : (
                <Spin sectionLoader />
              )}
            </Card>
            {!loadingPrograms && newCoachees?.length > 0 && (
              <Card
                titleIcon={<StarOutlineIcon fontSize='large' />}
                title={`New ${v('coachees', true)}`}
              >
                {!loadingPrograms && newCoachees?.length ? (
                  newCoachees.map(program => (
                    <UserProgramCard
                      key={`new-coachee-${program.id}${program.coachee.id}`}
                      user={user}
                      program={program}
                      scRole='coach'
                    />
                  ))
                ) : (
                  <Spin sectionLoader />
                )}
              </Card>
            )}
            {user.isCoachOrMentor && (
              <Card
                titleIcon={<PersonOutlined fontSize='large' />}
                title='Recent &amp; Upcoming Interviews'
                className='interviews-card'
              >
                {!loadingInterviews && user.coaching_interviews?.length ? (
                  user.coaching_interviews.map(interview => {
                    return (
                      <InterviewCard
                        key={interview.id}
                        interview={interview}
                        scRole='coach'
                      />
                    );
                  })
                ) : user.coaching_interviews.length === 0 &&
                  !loadingInterviews ? (
                  <Typography
                    variant='body1'
                    color='grey.700'
                    sx={{ textAlign: 'center' }}
                  >
                    No scheduled interviews
                  </Typography>
                ) : (
                  <Spin sectionLoader />
                )}
              </Card>
            )}
            <Card
              titleIcon={<PersonCheckOutlinedIcon fontSize='large' />}
              title='Completed &amp; Inactive'
            >
              {!loadingPrograms && inactiveCoachees?.length ? (
                inactiveCoachees.map(program => (
                  <UserProgramCard
                    key={`inactive-coachee-${program.id}${program.coachee.id}`}
                    user={user}
                    program={program}
                    scRole='coach'
                  />
                ))
              ) : !loadingPrograms && inactiveCoachees?.length === 0 ? (
                <Typography
                  variant='body1'
                  color='grey.700'
                  sx={{ textAlign: 'center' }}
                >
                  You have no completed or inactive {v('coachees')}
                </Typography>
              ) : (
                <Spin sectionLoader />
              )}
            </Card>
          </ViewBodySection>
        </ViewBody>
      </View>
    )
  );
};

export default observer(CoachHome);
