import React, { FC, useEffect, useMemo, useState, forwardRef } from 'react';
import startCase from 'lodash/startCase';
import { useQuery } from '@tanstack/react-query';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FormatAlignLeftOutlinedIcon from '@mui/icons-material/FormatAlignLeftOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CommitOutlinedIcon from '@mui/icons-material/CommitOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import TagIcon from '@mui/icons-material/Tag';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import LoadingIcon from '@src/components/common/LoadingIcon';
import {
  Button,
  Card,
  View,
  ViewBody,
  ViewBodySection,
  ViewHeader,
  TextField,
  DialogTitle,
  useToast,
  ConfirmDialog,
} from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import { useFullScreen } from '@src/hooks/useFullScreen';
import { useStore } from '@src/models/Store';
import SurveyType from '@src/models/Survey';
import SurveyQuestion from '@src/models/SurveyQuestion';
import SurveyEditorQuestionForm from '../SurveyEditorQuestionForm';
import { Survey } from '../../../common/Survey/Survey';
import { handleKeyboardNav, useQueryParams } from '@src/utils';
import { useNavigation } from '@src/navigation';
import { Row, Col, Space, Popover } from 'antd';
import { observer } from 'mobx-react-lite';
import SortableList, { SortableItem } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';
import { nanoid } from 'nanoid';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import {
  AddCompetenciesModal,
  QuestionText,
} from '../SurveyEditorQuestionForm/components';
import { SettingsModal } from '@src/components/views/SurveysManager/SurveyEditor/SettingsModal';

import './SurveyEditor.less';
import { SurveyQuestionBankModal } from '../SurveyQuestionBank/SurveyQuestionBankModal';
import { CollectResponses } from './CollectResponses';
import { Chip } from '@mui/material';

const NewQuestionMenu: FC<{
  clientId: string;
  addQuestion: Function;
  surveyQuestions: SurveyQuestion[];
  setSurveyQuestions: Function;
  setMenuOpen: Function;
}> = ({
  clientId,
  addQuestion,
  surveyQuestions,
  setSurveyQuestions,
  setMenuOpen,
}) => {
  const [competenciesModalOpen, setCompetenciesModalOpen] =
    useState<boolean>(false);
  const [questionBankModalOpen, setQuestionBankModalOpen] = useState(false);

  const handleItemSelect = type => {
    const questionTemplates = {
      message: () => ({
        type: 'message',
        privacyText: 'Click to edit',
        helpText: '',
      }),
      'single-select': () => ({
        type: 'single-select',
        options: [
          {
            id: nanoid(),
            text: 'Click to edit',
            value: 'default-option',
          },
        ],
      }),
      'multi-select': () => ({
        type: 'multi-select',
        options: [
          {
            id: nanoid(),
            text: 'Click to edit',
            value: 'default-option',
          },
        ],
      }),
      range: () => ({ type: 'range' }),
      text: () => ({ type: 'text' }),
      number: () => ({ type: 'text', isNumberInput: true }),
    };

    addQuestion(questionTemplates[type]());
  };

  const toggleSCQuestionBank = () => {
    setQuestionBankModalOpen(true);
    setMenuOpen(false);
  };

  const toggleCompetencies = () => {
    setCompetenciesModalOpen(true);
    setMenuOpen(false);
  };

  return (
    <div className='se-new-question-menu'>
      <Row>
        <Col flex={'50%'}>
          <Typography variant='h5' sx={{ marginBottom: '0.5em' }}>
            Create New Question
          </Typography>
          <Typography variant='body2' color='grey.700'>
            Create a custom question from scratch
          </Typography>
          <ul className='create-new-from-type' role='tablist'>
            <li
              role='tab'
              tabIndex={0}
              onClick={() => handleItemSelect('message')}
              onKeyDown={e =>
                handleKeyboardNav(e.key, () => handleItemSelect('message'))
              }
            >
              <FormatAlignLeftOutlinedIcon className='create-message' /> Message
            </li>
            <li
              role='tab'
              tabIndex={0}
              onClick={() => handleItemSelect('single-select')}
              onKeyDown={e =>
                handleKeyboardNav(e.key, () =>
                  handleItemSelect('single-select'),
                )
              }
            >
              <RadioButtonCheckedOutlinedIcon className='create-single-select' />
              Single Select
            </li>
            <li
              role='tab'
              tabIndex={0}
              onClick={() => handleItemSelect('multi-select')}
              onKeyDown={e =>
                handleKeyboardNav(e.key, () => handleItemSelect('multi-select'))
              }
            >
              <CheckBoxOutlinedIcon className='create-multi-select' />
              Multiple Select
            </li>
            <li
              role='tab'
              tabIndex={0}
              onClick={() => handleItemSelect('range')}
              onKeyDown={e =>
                handleKeyboardNav(e.key, () => handleItemSelect('range'))
              }
            >
              <CommitOutlinedIcon className='create-range' />
              Range
            </li>
            <li
              role='tab'
              tabIndex={0}
              onClick={() => handleItemSelect('text')}
              onKeyDown={e =>
                handleKeyboardNav(e.key, () => handleItemSelect('text'))
              }
            >
              <EditNoteIcon className='create-text' />
              Text
            </li>
            <li
              role='tab'
              tabIndex={0}
              onClick={() => handleItemSelect('number')}
              onKeyDown={e =>
                handleKeyboardNav(e.key, () => handleItemSelect('number'))
              }
            >
              <TagIcon className='create-number' />
              Number
            </li>
          </ul>
        </Col>
        <Col flex={'50%'}>
          <Typography variant='h5' sx={{ marginBottom: '0.5em' }}>
            Use Existing Question
          </Typography>
          <Typography variant='body2' color='grey.700'>
            {/* TODO: add organizational question banks */}
            Select from SkillCycle's question library
          </Typography>
          <ul role='tablist'>
            <li
              role='tab'
              tabIndex={0}
              onClick={toggleSCQuestionBank}
              onKeyDown={e => handleKeyboardNav(e.key, toggleSCQuestionBank)}
            >
              <HelpOutlineIcon sx={{ fontSize: 20, marginRight: 0.5 }} />
              SkillCycle Questions
            </li>
            {/* TODO: add organizational question banks */}
            {/* <li>
              <HelpOutlineIcon sx={{ fontSize: 20, marginRight: 0.5}} />
              Organization Question
            </li> */}
          </ul>
          <Typography variant='h5' sx={{ marginBottom: '0.5em' }}>
            Competencies and Values
          </Typography>
          <Typography variant='body2' color='grey.700'>
            Evaluate your learners on your competencies and values
          </Typography>
          <ul role='tablist'>
            <li
              role='tab'
              tabIndex={0}
              onClick={toggleCompetencies}
              onKeyDown={e => handleKeyboardNav(e.key, toggleCompetencies)}
            >
              <HelpOutlineIcon sx={{ fontSize: 20, marginRight: 0.5 }} />
              Competencies and Values
            </li>
          </ul>
        </Col>
      </Row>
      {competenciesModalOpen && (
        <AddCompetenciesModal
          clientId={clientId}
          surveyQuestions={surveyQuestions}
          setSurveyQuestions={setSurveyQuestions}
          setCompetenciesModalOpen={setCompetenciesModalOpen}
        />
      )}
      {questionBankModalOpen && (
        <SurveyQuestionBankModal
          clientId={clientId}
          handleClose={() => setQuestionBankModalOpen(false)}
          surveyQuestions={surveyQuestions}
          setSurveyQuestions={setSurveyQuestions}
        />
      )}
    </div>
  );
};

const QuestionListItemWithRef: FC<any> = forwardRef(function questionListItem(
  {
    question,
    selectedQuestionId,
    lastSortedItem,
    setSelectedQuestionId,
    setSelectedSurveyQuestion,
    canDelete,
    removeQuestion,
    numberDisplay,
    idx,
    isSurveyEditable,
  },
  ref,
) {
  const handleSelectQuestion = () => {
    setSelectedQuestionId(question.id);
    setSelectedSurveyQuestion(question);
  };
  return (
    <li
      tabIndex={0}
      role='tab'
      ref={ref as React.LegacyRef<HTMLLIElement>}
      key={question.id}
      className={`${selectedQuestionId === question.id ? 'selected' : ''} ${
        idx != null && lastSortedItem === idx ? 'last-sorted' : ''
      }`}
      onClick={handleSelectQuestion}
      onKeyDown={e => handleKeyboardNav(e.key, handleSelectQuestion)}
      id={question.id}
    >
      <Space>
        <div className='question-number'>{numberDisplay}</div>
        <div>
          <Row className='question-name'>
            <QuestionText
              surveyQuestion={question}
              propName='name'
              isSurveyEditable={isSurveyEditable}
            />
          </Row>

          <div className='question-title'>{question.title}</div>
          <div
            className={`question-type-label ${
              question.type === 'text' && question.isNumberInput
                ? 'number'
                : question.type
            }`}
          >
            {question.type === 'text' && question.isNumberInput
              ? 'Number'
              : question.type}
          </div>
        </div>
        {canDelete && (
          <StyledIconButton
            className='delete-question'
            onClick={e => {
              e.stopPropagation();
              removeQuestion(question, idx);
            }}
          >
            <DeleteOutlinedIcon color='error' fontSize='small' />
          </StyledIconButton>
        )}
      </Space>
    </li>
  );
});

const QuestionListItem = observer(QuestionListItemWithRef, {
  forwardRef: true,
});

const QuestionsList: FC<{
  clientId: string;
  surveyQuestions: SurveyQuestion[];
  setSelectedSurveyQuestion: Function;
  setSurveyQuestions: Function;
  isSurveyEditable: boolean;
}> = observer(
  ({
    clientId,
    surveyQuestions,
    setSelectedSurveyQuestion,
    setSurveyQuestions,
    isSurveyEditable,
  }) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [selectedQuestionId, setSelectedQuestionId] = useState<string>();
    const [lastSortedItem, setLastSortedItem] = useState<number>();
    useEffect(() => {
      const firstQuestion = surveyQuestions[0];
      if (!selectedQuestionId) {
        setSelectedQuestionId(firstQuestion?.id);
        setSelectedSurveyQuestion(firstQuestion);
      }

      const q = surveyQuestions.find(sq => sq.id === selectedQuestionId);
      if (q?.title === 'New Question') {
        document.getElementById(selectedQuestionId)?.scrollIntoView({
          behavior: 'smooth',
          block: q?.title === 'New Question' ? 'start' : 'end',
          inline: 'nearest',
        });
      }
    }, [
      selectedQuestionId,
      surveyQuestions,
      setSelectedQuestionId,
      setSelectedSurveyQuestion,
    ]);

    const staticQuestion = surveyQuestions[0];
    const sortableQuestions = useMemo(() => {
      return surveyQuestions.slice(1);
    }, [surveyQuestions]);

    const addQuestion = q => {
      const newQ = new SurveyQuestion({
        id: nanoid(),
        client_id: clientId,
        title: 'New Question',
        helpText: 'Click to edit',
        ...q,
      });
      setSurveyQuestions([...surveyQuestions, newQ]);
      setSelectedQuestionId(newQ.id);
      setSelectedSurveyQuestion(newQ);
      setMenuOpen(false);
    };

    const removeQuestion = (q, idx) => {
      const filtered = surveyQuestions.filter(sq => sq.id !== q.id);
      setSurveyQuestions(filtered);
      if (q.id === selectedQuestionId) {
        // If selected question is deleted, select next or previous question
        // depending on array position
        const newSelected = filtered[idx] || filtered[idx - 1];
        setSelectedQuestionId(newSelected?.id);
        setSelectedSurveyQuestion(newSelected);
      }
    };

    return (
      <div className='se-list-container'>
        <div className='se-list-header'>
          <Row>
            <Col flex={1}>Survey Content</Col>
            {isSurveyEditable && (
              <Col flex={0}>
                <Popover
                  overlayClassName='se-new-question'
                  content={
                    <NewQuestionMenu
                      clientId={clientId}
                      addQuestion={addQuestion}
                      surveyQuestions={surveyQuestions}
                      setSurveyQuestions={setSurveyQuestions}
                      setMenuOpen={setMenuOpen}
                    />
                  }
                  trigger='click'
                  placement='rightTop'
                  open={menuOpen}
                  onOpenChange={() => setMenuOpen(!menuOpen)}
                >
                  <IconButton>
                    <AddCircleOutlineIcon fontSize='small' />
                  </IconButton>
                </Popover>
              </Col>
            )}
          </Row>
        </div>
        <div className='se-list'>
          <ul>
            <QuestionListItem
              question={staticQuestion}
              numberDisplay={1}
              selectedQuestionId={selectedQuestionId}
              lastSortedItem={lastSortedItem}
              setSelectedQuestionId={setSelectedQuestionId}
              setSelectedSurveyQuestion={setSelectedSurveyQuestion}
              removeQuestion={removeQuestion}
              canDelete={false}
              isSurveyEditable={isSurveyEditable}
            />
            <SortableList
              onSortEnd={(oldIndex: number, newIndex: number) => {
                if (oldIndex !== newIndex) {
                  setSurveyQuestions([
                    staticQuestion,
                    ...arrayMoveImmutable(
                      sortableQuestions,
                      oldIndex,
                      newIndex,
                    ),
                  ]);
                  setLastSortedItem(newIndex);
                  setTimeout(() => {
                    setLastSortedItem(-1);
                  }, 1000);
                }
              }}
              className='list'
              draggedItemClassName='se-list-dragging'
              lockAxis='y'
              allowDrag={isSurveyEditable}
            >
              {sortableQuestions.map((q, idx) => (
                <SortableItem key={idx}>
                  <QuestionListItem
                    question={q}
                    numberDisplay={idx + 2}
                    selectedQuestionId={selectedQuestionId}
                    lastSortedItem={lastSortedItem}
                    setSelectedQuestionId={setSelectedQuestionId}
                    setSelectedSurveyQuestion={setSelectedSurveyQuestion}
                    removeQuestion={removeQuestion}
                    canDelete={isSurveyEditable}
                    idx={idx}
                    isSurveyEditable={isSurveyEditable}
                  />
                </SortableItem>
              ))}
            </SortableList>
          </ul>
        </div>
      </div>
    );
  },
);

const QuestionEditor: FC<{
  selectedSurveyQuestion: SurveyQuestion;
  isSurveyEditable: boolean;
}> = observer(({ selectedSurveyQuestion, isSurveyEditable }) => {
  return selectedSurveyQuestion ? (
    <div className='se-question'>
      <div className='se-question-container'>
        <SurveyEditorQuestionForm
          surveyQuestion={selectedSurveyQuestion}
          isSurveyEditable={isSurveyEditable}
        />
      </div>
    </div>
  ) : null;
});

const ConfirmPublishModal: FC<{
  survey: SurveyType;
  questions: SurveyQuestion[];
  onClose: Function;
  saveSurvey: Function;
  setStatus: Function;
  onSave: Function;
}> = observer(
  ({ survey, questions, onClose, saveSurvey, setStatus, onSave }) => {
    const [publishingSurvey, setPublishingSurvey] = useState<boolean>(false);
    const navigation = useNavigation();
    const { fullScreen } = useFullScreen();
    return (
      <Dialog open onClose={() => onClose()} fullScreen={fullScreen} fullWidth>
        <DialogTitle
          title='Are you sure you want to publish this survey?'
          buttonOnClick={() => onClose()}
        />
        <DialogContent>
          <Stack spacing={2}>
            <Typography>
              By publishing this survey, it will be available to enable for your
              members.
            </Typography>
            <TextField
              multiline
              rows={4}
              label='Add optional notes on this version:'
              value={survey.notes}
              onChange={e => {
                survey.notes = e.target.value;
              }}
              id='notes'
              fullWidth
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            text='Cancel'
            variant='outlined'
            size='small'
            disabled={publishingSurvey}
            onClick={() => onClose()}
          />
          <Button
            text='Save & Publish'
            size='small'
            loading={publishingSurvey}
            disabled={publishingSurvey}
            onClick={async () => {
              setPublishingSurvey(true);
              try {
                await saveSurvey();
                survey.questions = questions;
                await survey.publish();
                setStatus(survey.status);
                await onSave(survey.id);
                setPublishingSurvey(false);
              } finally {
                setPublishingSurvey(false);
                navigation.replace(
                  `${navigation.location.pathname.replace(
                    'insights',
                    'my-business',
                  )}?id=${survey.id}`,
                );
              }
              onClose();
            }}
          />
        </DialogActions>
      </Dialog>
    );
  },
);

const SurveyEditor: FC<{}> = () => {
  const toast = useToast();
  const query = useQueryParams();
  const navigation = useNavigation();
  const { user } = useStore();
  const isDuplicate = query.get('action') === 'duplicate';
  const isDuplicateForClient = query.get('action') === 'duplicate-for-client';
  const surveyTitle = query.get('title');
  const surveyType = query.get('type');
  const surveyId = query.get('id');
  const { client_id } = useParams();
  const [loadingSurvey, setLoadingSurvey] = useState<boolean>(true);
  const [savingSurvey, setSavingSurvey] = useState<boolean>(false);
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [surveyStatus, setSurveyStatus] = useState('');
  const [confirmPublishOpen, setConfirmPublishOpen] = useState<boolean>(false);
  const [currentSurvey, setCurrentSurvey] = useState<SurveyType>();
  const [surveyQuestions, setSurveyQuestions] = useState<SurveyQuestion[]>([]);
  const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);
  const [openSaveModal, setOpenSaveModal] = React.useState(false);

  const isValid = useMemo(() => {
    return (
      surveyQuestions?.length > 0 &&
      surveyQuestions?.some(({ type }) => type !== 'message')
    );
  }, [surveyQuestions]);

  const { data: programs, isLoading: isProgramsLoading } = useQuery({
    queryKey: ['survey', surveyId, 'programs'],
    queryFn: async () =>
      api.surveys.getProgramsUsingSurvey(surveyId, client_id),
    select: ({ data }) => data,
    enabled: surveyId !== 'create' && !isDuplicate && !isDuplicateForClient,
  });

  const { data: collections, isLoading: isCollectionsLoading } = useQuery({
    queryKey: ['survey', surveyId, 'collections'],
    queryFn: async () => api.surveys.listSurveyCollections(surveyId, client_id),
    select: ({ data }) => data,
    enabled: surveyId !== 'create' && !isDuplicate && !isDuplicateForClient,
  });

  const isSurveyEditable = useMemo(() => {
    if (user.is_admin || isDuplicate || isDuplicateForClient) return true;
    return (
      !isProgramsLoading &&
      !isCollectionsLoading &&
      (programs?.length ? programs?.every(p => !p.survey_responses) : true) &&
      (collections?.length
        ? collections?.every(c => c.status === 'draft')
        : true)
    );
  }, [
    user,
    programs,
    collections,
    isProgramsLoading,
    isCollectionsLoading,
    isDuplicate,
    isDuplicateForClient,
  ]);

  const [selectedSurveyQuestion, setSelectedSurveyQuestion] =
    useState<SurveyQuestion>();

  const fetchSurvey = async (id: string): Promise<void> => {
    const res = await api.surveys.getById(id);
    const data = { ...res.data };
    if (isDuplicate || isDuplicateForClient) {
      data.title = surveyTitle;
      if (surveyType) {
        data.type = surveyType;
      }
      data.description = navigation?.location?.state?.description ?? '';
      delete data.id;
      delete data.last_published_at;
      delete data.last_published_id;
      if (Array.isArray(data.questions)) {
        // Loop backward through the array
        for (let i = data.questions.length - 1; i >= 0; i--) {
          const q = data.questions[i];
          // If the question is a group of questions and not a competency, or if it's conditional,
          // remove it since the editor does not yet support those features.
          if ((q.group && q.type != 'competency') || q.condition) {
            data.questions.splice(i, 1);
          }
          q.id = nanoid(); // New question id ensures we don't affect the original copy of the question
          if (isDuplicateForClient) {
            q.client_id = client_id; // Ensure a client_id is added to each question to disassociate it from SkillCycle defaults
          }
        }
      }
    }
    const survey = new SurveyType(data);
    if (isDuplicateForClient) {
      survey.client_id = client_id; // Ensure a client_id is added to the survey to disassociate it from SkillCycle defaults
    }
    setCurrentSurvey(survey);
    setSurveyQuestions(
      res.data.questions.map(q => {
        if (q.group) {
          q.helpText = q.group.helpText;
          q.id = q.group.id;
          q.title = q.group.title;
          q.type = q.group.type;
          q.questions = q.group.questions;
        }
        return new SurveyQuestion(q);
      }),
    );
    setSurveyStatus(survey.status);
    setLoadingSurvey(false);
  };

  const smartPush = (array: string[], items: string[]) => {
    for (const item of items) {
      if (!array.includes(item)) {
        array.push(item);
      }
    }
    return;
  };

  useEffect(() => {
    function createNewSurveyInMemory() {
      setCurrentSurvey(
        new SurveyType({
          title: query.get('title'),
          type: query.get('type'),
          client_id: client_id,
          description: navigation?.location?.state?.description ?? '',
        }),
      );
      setSurveyQuestions([
        new SurveyQuestion({
          title: 'Welcome! Get started creating your survey!',
          helpText:
            'Click the text to edit. Click the + button to begin adding questions to your survey.',
          privacyText:
            'Use this space to let members know who will have access to responses. For example: Your responses may be seen by SkillCycle and members of your organization.',
          type: 'message',
          client_id: client_id,
        }),
      ]);
      setLoadingSurvey(false);
    }

    if (query.get('id')) {
      if (query.get('id') === 'create') {
        createNewSurveyInMemory();
      } else {
        fetchSurvey(query.get('id'));
      }
    } else {
      // missing id querystring parameter, so redirect back to the survey list
      navigation.replace('/surveys');
    }
    // FIXME: invalid dependency array
    // eslint-disable-next-line
  }, [query, navigation]);

  const saveSurvey = async () => {
    if (!isValid) {
      toast.error(
        'Please add additional question type in order to create the survey.',
      );
      return;
    }
    try {
      currentSurvey.questions = await Promise.all(
        surveyQuestions.map(async sq => {
          const res = {} as SurveyQuestion;
          if (sq.group) {
            // First save the individual survey questions
            sq.group.questions.map(async (q: SurveyQuestion) => {
              await q.save();
            });

            // Now shape the survey data
            res.group = {
              id: sq.id,
              title: sq.title,
              helpText: sq.helpText,
              type: sq.type,
              questions: sq.group.questions.map(q => {
                return {
                  id: q.id,
                };
              }),
            };
            if (sq.meta_data) {
              res.meta_data = sq.meta_data;
            }
          } else {
            // First save the individual survey questions
            await sq.save(sq.inQuestionBank);

            // Now shape the survey data
            res.id = sq.id;
          }
          return res;
        }),
      );

      // Finally save the survey.
      // Since a survey can be used in any way (for any program survey type or sent manually),
      // add all possible hiddens and on_submit actions.
      smartPush(currentSurvey.hidden, [
        'user_id',
        'user_first_name',
        'user_last_name',
        'program_id',
        'notification_id',
        'collection_id',
        'email',
      ]);
      smartPush(currentSurvey.on_submit.action, [
        'mark360SelfAssessmentComplete',
        'complete360StakeholderAssessment',
        'completeNotification',
        'markSurveyCompleteForCollectionRecipient',
        'markFeedbackCompleteForStakeholder',
      ]);
      await currentSurvey.save();
      setSurveyStatus(currentSurvey.status);
    } finally {
      setSavingSurvey(false);
    }
  };
  return (
    <>
      {settingsModalOpen && (
        <SettingsModal
          {...{ settingsModalOpen, setSettingsModalOpen, currentSurvey }}
        />
      )}
      <View className='survey-editor'>
        <ViewHeader
          title={currentSurvey?.title || 'Survey Editor'}
          subtitle={
            surveyStatus && (
              <Chip
                color={
                  surveyStatus === 'published'
                    ? 'success'
                    : surveyStatus === 'unpublished-changes'
                    ? 'warning'
                    : undefined
                }
                label={startCase(surveyStatus)}
                sx={{ width: 'fit-content' }}
              />
            )
          }
          titleIcon={<FeedOutlinedIcon fontSize='large' />}
          actions={[
            {
              render: (
                <IconButton
                  key='preview-button'
                  onClick={() => setPreviewOpen(true)}
                >
                  <VisibilityOutlinedIcon />
                </IconButton>
              ),
            },
            {
              // only show the settings icon if the survey has been saved
              // title can't be edited until the survey has been saved once
              render: currentSurvey?.id && (
                <IconButton
                  key='settings-button'
                  onClick={() => setSettingsModalOpen(true)}
                  disabled={!isSurveyEditable}
                >
                  <SettingsOutlinedIcon />
                </IconButton>
              ),
            },
            {
              render:
                client_id || currentSurvey?.client_id ? (
                  <>
                    <ConfirmDialog
                      open={openSaveModal}
                      setOpenModal={setOpenSaveModal}
                      buttonProps={{
                        text: 'Save',
                        onClick: async () => {
                          setSavingSurvey(true);
                          await saveSurvey();
                          setSavingSurvey(false);
                        },
                      }}
                      title='Save Survey'
                      body='This survey will be saved in your Surveys tab and can continue to be edited. If you would like to use this survey with your members, you will need to publish the survey first.'
                    />
                    <Button
                      key='save-confirm-popup-button'
                      loading={savingSurvey}
                      variant='outlined'
                      size='small'
                      text='Save'
                      onClick={() => setOpenSaveModal(true)}
                      disabled={savingSurvey || !isSurveyEditable}
                    />
                  </>
                ) : (
                  <Button
                    key='save-button'
                    loading={savingSurvey}
                    variant='outlined'
                    size='small'
                    text='Save'
                    disabled={savingSurvey || !isSurveyEditable}
                    onClick={async () => {
                      setSavingSurvey(true);
                      await saveSurvey();
                      setSavingSurvey(false);
                    }}
                  />
                ),
            },
            {
              render: (
                <Button
                  key='save-and-publish-button'
                  text='Save & Publish'
                  size='small'
                  disabled={savingSurvey || !isSurveyEditable}
                  onClick={() => {
                    if (!isValid) {
                      toast.error(
                        'Please add additional question type in order to publish the survey.',
                      );
                      return;
                    }
                    setConfirmPublishOpen(true);
                  }}
                />
              ),
            },
          ]}
        />
        <ViewBody>
          <ViewBodySection
            title='Questions'
            icon={<FormatListNumberedIcon />}
            fullScreen
          >
            {!loadingSurvey && currentSurvey ? (
              <div className='survey-editor-region'>
                <div className='questions-list-col'>
                  <QuestionsList
                    clientId={client_id || currentSurvey.client_id}
                    surveyQuestions={surveyQuestions}
                    setSurveyQuestions={setSurveyQuestions}
                    setSelectedSurveyQuestion={setSelectedSurveyQuestion}
                    isSurveyEditable={isSurveyEditable}
                  />
                </div>
                <div className='question-editor-col'>
                  <QuestionEditor
                    selectedSurveyQuestion={selectedSurveyQuestion}
                    isSurveyEditable={isSurveyEditable}
                  />
                </div>
              </div>
            ) : loadingSurvey ? (
              <LoadingIcon />
            ) : (
              <Card>
                <Typography variant='h3' sx={{ marginBottom: '1em' }}>
                  Error Loading Survey
                </Typography>
                <Typography variant='body1'>
                  There was an error loading the survey, please contact support.
                </Typography>
              </Card>
            )}
          </ViewBodySection>
          {/* Only show the Collect Responses tab if this is an existing survey (i.e. it has an id that is not 'create' since that breaks the Collect Responses workflow) and if it has been published */}
          {query.get('id') !== 'create' &&
            currentSurvey &&
            currentSurvey.status !== 'draft' &&
            !isDuplicate &&
            !isDuplicateForClient && (
              <ViewBodySection
                title='Collect Responses'
                icon={<LibraryBooksOutlinedIcon />}
              >
                <CollectResponses surveyName={currentSurvey?.title} />
              </ViewBodySection>
            )}
        </ViewBody>
        {previewOpen && (
          <Survey
            previewSurvey={currentSurvey}
            previewQuestions={surveyQuestions}
            handleClose={() => setPreviewOpen(false)}
          />
        )}
        {confirmPublishOpen && (
          <ConfirmPublishModal
            survey={currentSurvey}
            questions={surveyQuestions}
            onClose={() => setConfirmPublishOpen(false)}
            saveSurvey={saveSurvey}
            setStatus={setSurveyStatus}
            onSave={fetchSurvey}
          />
        )}
      </View>
    </>
  );
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&.delete-question': {
    display: 'none',
    '.MuiSvgIcon-root': {
      color: theme.palette.error.light,
    },
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      '.MuiSvgIcon-root': {
        color: theme.palette.error.main,
      },
    },
    '&:active': {
      backgroundColor: theme.palette.background.default,
      '.MuiSvgIcon-root': {
        color: theme.palette.error.dark,
      },
    },
  },
}));

export default observer(SurveyEditor);
