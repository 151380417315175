import { HTTPClient, APIResponse } from '@src/lib/client';
import type { Notification } from '@shared/adapters';
export type { Notification };

export class NotificationsAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async getUserNotifications(userId: string): APIResponse<Notification[]> {
    return this.http.request({
      url: `/notifications/user/${userId}`,
      method: 'get',
    });
  }

  async dismissNotification(notificationId: string): APIResponse<Notification> {
    return this.http.request({
      url: `/notifications/${notificationId}`,
      method: 'patch',
      data: { dismissed_at: Date.now() },
    });
  }

  async setNotificationCompleted(
    notificationId: string,
  ): APIResponse<Notification> {
    return this.http.request({
      url: `/notifications/${notificationId}`,
      method: 'patch',
      data: { completed_at: Date.now() },
    });
  }
}
