import React from 'react';
import {
  Select as SelectMUI,
  SelectProps as SelectPropsMUI,
  MenuItem,
  InputLabel,
  FormControl,
  Stack,
  Typography,
  FormHelperText,
} from '@mui/material';

interface SelectProps extends SelectPropsMUI {
  options: {
    label?: string;
    options: {
      label: string | JSX.Element;
      value: string | number;
      disabled?: boolean;
    }[];
  }[];
  innerRef?: any;
  errorMessage?: string;
}

function Select(props: SelectProps) {
  const { options = [], label, innerRef, errorMessage, ...fieldProps } = props;
  if (innerRef) fieldProps.ref = innerRef;
  const menuItems = [];
  options.map(({ options, label }, idx) => {
    if (label) {
      menuItems.push(
        <li
          key={`optgroup-${idx}`}
          style={{
            height: '44px',
            paddingLeft: '1em',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body2m' color='grey.700'>
            {label}
          </Typography>
        </li>,
      );
    }
    options.map((opt, oIdx) => {
      menuItems.push(
        <MenuItem
          key={`optgroup-${idx}-option-${oIdx}`}
          disabled={opt.disabled}
          value={opt.value}
          sx={{
            paddingLeft: '2em',
            borderRadius: 0,
            '&:hover, &:focus-visible': {
              backgroundColor: 'grey.100',
            },
            '&.Mui-selected': {
              backgroundColor: 'primary.100',
            },
          }}
        >
          <Typography variant='body2'>{opt.label}</Typography>
        </MenuItem>,
      );
    });
  });

  return (
    <FormControl
      component={Stack}
      spacing={1}
      disabled={fieldProps.disabled}
      fullWidth={fieldProps.fullWidth}
      sx={{ minWidth: 'fit-content' }}
    >
      {label && (
        <InputLabel
          id='input-label'
          required={fieldProps.required}
          disabled={fieldProps.disabled}
        >
          {label}
        </InputLabel>
      )}
      <SelectMUI
        {...fieldProps}
        MenuProps={{
          PaperProps: {
            sx: {
              padding: 0,
              border: `1px solid`,
              borderColor: 'grey.200',
              boxShadow:
                '0px 1px 3px 0px rgba(26, 26, 46, 0.10), 0px 4px 12px 0px rgba(26, 26, 46, 0.08)',
            },
          },
        }}
      >
        {menuItems.map(item => item)}
      </SelectMUI>
      {errorMessage && fieldProps?.error && (
        <FormHelperText error color='error'>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export { Select };
