import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { SessionStatusOverride } from '@src/models/Session';
import {
  useToast,
  DialogTitle,
  Button,
  Select,
} from '@src/components/sc-design-system';
import dayjs from 'dayjs';
import {
  Link,
  Stack,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useQueryClient } from '@tanstack/react-query';
import { useAdminQueries } from '@src/hooks/useAdmin';

const SessionAlerts: FC<{
  userId: string;
}> = observer(({ userId }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { useSessionAlerts } = useAdminQueries();
  const { sessionAlerts, isLoading } = useSessionAlerts();
  const numAlerts = sessionAlerts?.length;
  const [modalVisible, setModalVisible] = useState(false);
  const [modifiedId, setModifiedId] = useState<string | null>(null);
  const [savingStatus, setSavingStatus] = useState<string | null>(null);
  return (
    <>
      <Dialog
        open={modalVisible}
        maxWidth='lg'
        onClose={() => setModalVisible(false)}
      >
        <DialogTitle
          title='Incomplete or Missed Sessions'
          buttonOnClick={() => setModalVisible(false)}
        />
        <DialogContent>
          <Table
            bordered
            pagination={false}
            columns={[
              {
                title: 'Scheduled',
                render: (text, sA) => (
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {dayjs(sA.calendly_event.start_time).format(
                      'M/DD/YY [at] h:mma',
                    )}
                  </span>
                ),
              },
              {
                title: 'Members',
                render: (text, sA: any) => (
                  <>
                    {sA.user_data.map(uD => (
                      <div
                        style={{ fontWeight: uD.is_coach ? '600' : '400' }}
                        key={uD.id}
                      >
                        {uD.first_name} {uD.last_name}
                      </div>
                    ))}
                  </>
                ),
              },
              {
                title: 'Type',
                render: (text, sA: any) => (
                  <>
                    <span>
                      {sA.type.charAt(0).toUpperCase() + sA.type.slice(1)}
                    </span>
                  </>
                ),
              },
              {
                title: 'Client',
                render: (text, sA: any) => (
                  <Link
                    variant='a2'
                    href={`/my-business/${sA.client_data[0]?.id}`}
                  >
                    {sA.client_data[0]?.name}
                  </Link>
                ),
              },
              {
                title: 'Program',
                render: (text, sA: any) => (
                  <Link variant='a2' href={`/program/${sA.programs[0]?.id}`}>
                    {sA.programs[0]?.name}
                  </Link>
                ),
              },
              {
                title: 'Modify',
                width: '20%',
                render: (text, sA) => (
                  <Select
                    placeholder='Select Status'
                    disabled={modifiedId === sA.id}
                    options={[
                      {
                        options: [
                          { label: 'Completed', value: 'completed' },
                          { label: 'Canceled', value: 'canceled' },
                          // To set missed we just set it back to scheduled
                          // which will add the override preventing the alert
                          // in the future
                          { label: 'Missed', value: 'scheduled' },
                        ],
                      },
                    ]}
                    value={
                      savingStatus ||
                      (sA.current_status === 'scheduled' || !sA.current_status
                        ? null
                        : sA.current_status)
                    }
                    onChange={async e => {
                      const oS = sA.current_status;
                      setModifiedId(sA.id);
                      sA.status_overridden = new SessionStatusOverride({
                        original_status: oS,
                        timestamp: Date.now(),
                        user_id: userId,
                      });
                      sA.current_status = e.target.value as string;
                      setSavingStatus(sA.current_status);
                      try {
                        await sA.save(true);
                        queryClient.invalidateQueries({
                          queryKey: ['admin', 'sessionAlerts'],
                        });
                      } catch (e) {
                        toast.error('Could not update session status');
                      }
                      setModifiedId(null);
                      setSavingStatus(null);
                    }}
                    fullWidth
                  />
                ),
              },
            ]}
            dataSource={sessionAlerts?.filter(sA => sA.members?.length > 0)}
          />
        </DialogContent>
        <DialogActions>
          <Button text='Close' onClick={() => setModalVisible(false)} />
        </DialogActions>
      </Dialog>
      {isLoading ? (
        <Stack direction='row' alignItems='center' gap={1}>
          <InfoOutlinedIcon />
          <span>Checking sessions...</span>
        </Stack>
      ) : numAlerts === 0 ? (
        <Stack direction='row' alignItems='center' gap={1}>
          <InfoOutlinedIcon />
          <span>No sessions require attention</span>
        </Stack>
      ) : (
        <Stack
          sx={{ cursor: 'pointer', width: '100%' }}
          onClick={() => setModalVisible(true)}
          direction='row'
          alignItems='center'
          gap={1}
        >
          <InfoOutlinedIcon color='error' />{' '}
          <span style={{ fontWeight: 600 }}>
            There {numAlerts === 1 ? 'is' : 'are'} {numAlerts} session
            {numAlerts === 1 ? '' : 's'} requiring attention
          </span>
        </Stack>
      )}
    </>
  );
});

export default SessionAlerts;
