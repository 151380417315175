import { useQuery } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import Program from '@src/models/Program';
import { ProgramGetParams } from '@src/lib/client/apis/programs';

const usePrograms = ({ enabled, params } = { enabled: true, params: {} }) => {
  const { data, ...rest } = useQuery({
    queryKey: ['programs', params],
    queryFn: async () => await api.programs.get(params as ProgramGetParams),
    select: ({ data }) => {
      return {
        data: data.data.map(p => new Program(p)),
        totalRecords: data.totalRecords,
      };
    },
    enabled,
  });

  return {
    programs: data ?? null,
    ...rest,
  };
};

export { usePrograms };
