import React, { FC } from 'react';
import { Stack, Divider } from '@mui/material';
import { Card } from '@src/components/sc-design-system';
import User from '@src/models/User';
import { ActiveClientKey, useActiveClient } from '@src/hooks/useActiveClient';
import { FeedbackRequests } from './FeedbackRequests';
import { Surveys } from './Surveys';

const SurveysAndFeedback: FC<{ user: User }> = ({ user }) => {
  const { client, isLoading: isLoadingActiveClient } = useActiveClient({
    queryKey: ActiveClientKey.profile_active_client_id,
  });

  const showFeedbackRequests = Boolean(!isLoadingActiveClient && client);

  return (
    <Card title='Surveys & feedback'>
      <Stack divider={<Divider />} spacing={4}>
        {showFeedbackRequests && <FeedbackRequests user={user} />}
        <Surveys user={user} />
      </Stack>
    </Card>
  );
};

export { SurveysAndFeedback };
