import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { TabPanelWrapper } from '@src/components/common/TabPanelWrapper';
import { AidaExpandingCard } from '@src/components/views/Aida/AidaExpandingCard';
import { useUserQueries } from '@src/hooks/useUserQueries';
import { Spin } from '@src/components/sc-design-system';
import { useUser } from '@src/hooks/useUser';
import ReactMarkdown from 'react-markdown';
import { LearnMoreAboutAidaAlert } from '../../LearnMoreAboutAidaAlert';

interface AidaPanelProps {
  activeTab: number;
  tabIndex: number;
  clientId: string;
  userId: string;
}

export function AidaPanel(props: AidaPanelProps) {
  const { activeTab, tabIndex, clientId, userId } = props;
  const { user } = useUser();
  const role = user.isMentor && !user.is_coach ? 'mentor' : 'coach';
  const { useGetUserAidaConversations } = useUserQueries(userId);
  const { conversations, isLoading: isLoadingConversations } =
    useGetUserAidaConversations(clientId, role);
  return (
    <TabPanelWrapper value={activeTab} index={tabIndex}>
      <Box sx={{ marginTop: '1em' }}>
        <LearnMoreAboutAidaAlert />
        {isLoadingConversations ? (
          <Spin sectionLoader />
        ) : !isLoadingConversations &&
          !conversations?.summary &&
          !conversations?.focus_areas ? (
          <div style={{ marginTop: '2em' }}>
            <NotShared />
          </div>
        ) : (
          <Stack spacing={3.5} sx={{ marginTop: '2em' }}>
            {Array.isArray(conversations?.summary) &&
              conversations.summary.length > 0 &&
              conversations.summary[0] && (
                <AidaExpandingCard
                  truncate
                  children={
                    <>
                      <Typography variant='h4'>
                        Developmental summary
                      </Typography>
                      <ReactMarkdown
                        children={conversations.summary[0].output_text}
                      />
                    </>
                  }
                  timestamp={conversations.summary[0].timestamp}
                />
              )}
            {Array.isArray(conversations?.focus_areas) &&
              conversations.focus_areas.length > 0 &&
              conversations.focus_areas[0] && (
                <AidaExpandingCard
                  truncate
                  children={
                    <>
                      <Typography variant='h4'>Focus areas</Typography>
                      <ReactMarkdown
                        children={conversations.focus_areas[0].output_text}
                      />
                    </>
                  }
                  timestamp={conversations.focus_areas[0].timestamp}
                />
              )}
          </Stack>
        )}
      </Box>
    </TabPanelWrapper>
  );
}

function NotShared() {
  return (
    <AidaExpandingCard
      children={
        <>
          <Typography variant='h4'>Data is unavailable</Typography>
          <Typography variant='body1' sx={{ marginTop: '.8em' }}>
            Currently, there is no data to display.
          </Typography>
        </>
      }
    />
  );
}
