import React, { FC, useMemo, useState } from 'react';
import User from '@src/models/User';
import { Space, Table, Input } from 'antd';
import {
  Button,
  useToast,
  DialogTitle,
  Select,
} from '@src/components/sc-design-system';
import dayjs from 'dayjs';
import Program from '@src/models/Program';
import { api } from '@src/lib/client';
import {
  Link,
  Grid,
  Stack,
  useTheme,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
} from '@mui/material';
import { useAdminQueries } from '@src/hooks/useAdmin';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { handleKeyboardNav } from '@src/utils';
import GroupOutlined from '@mui/icons-material/GroupOutlined';

interface ClientProgramMemberships {
  id: string;
  name: string;
  role: string;
  programs: Program[];
}

let clientDebounce;

const debounceClientSearch = async (
  keyword: string,
  limit: number,
  setClientSearchOptions: Function,
  setClientSearchLoading: Function,
) => {
  if (clientDebounce) clearTimeout(clientDebounce);
  clientDebounce = setTimeout(async () => {
    setClientSearchLoading(true);
    const res = await api.clients.quickSearch(keyword, limit);
    setClientSearchOptions(res.data);
    setClientSearchLoading(false);
  }, 250);
};

const ClientSearchAndAssign: FC<{
  user: User;
  reloadAlerts: Function;
  limit: number;
}> = ({ user, reloadAlerts, limit }) => {
  const toast = useToast();
  const [clientSearchOptions, setClientSearchOptions] = useState([]);
  const [clientSearchLoading, setClientSearchLoading] = useState(false);
  const [modalOpen, toggleModalOpen] = useState(false);
  const [savingSelection, setSavingSelection] = useState<boolean | number>(
    false,
  );

  return (
    <>
      <Dialog open={modalOpen} onClose={() => toggleModalOpen(false)}>
        <DialogTitle
          buttonOnClick={() => toggleModalOpen(false)}
          title={`Add ${user.first_name} ${user.last_name} to Client`}
        />
        <DialogContent>
          <Input
            onChange={e => {
              debounceClientSearch(
                e.target.value,
                limit,
                setClientSearchOptions,
                setClientSearchLoading,
              );
            }}
            placeholder='Search for clients'
          />
          <Divider />
          <Table
            loading={clientSearchLoading}
            pagination={false}
            rowKey='id'
            columns={[
              { title: 'Client', dataIndex: 'name' },
              {
                width: '30%',
                align: 'center' as 'center',
                title: 'Add',
                render: (_, c) => (
                  <Select
                    disabled={savingSelection === c.id}
                    fullWidth
                    placeholder='Add User'
                    options={[
                      {
                        options: [
                          {
                            label: 'As Administrator',
                            value: 'manager',
                          },
                          {
                            label: 'As Member',
                            value: 'member',
                          },
                        ],
                      },
                    ]}
                    onChange={async e => {
                      setSavingSelection(c.id);
                      try {
                        await api.clients.addClientMember({
                          clientId: c.id,
                          memberId: user.id,
                          role: e.target.value as string,
                          email: user.email,
                        });
                        await reloadAlerts();
                        toggleModalOpen(false);
                      } catch (e) {
                        toast.error('Could not add user to client');
                      }
                      setSavingSelection(false);
                    }}
                  />
                ),
              },
            ]}
            dataSource={clientSearchOptions}
          />
        </DialogContent>
        <DialogActions>
          <Button text='Cancel' onClick={() => toggleModalOpen(false)} />
        </DialogActions>
      </Dialog>
      <Grid
        container
        sx={{ cursor: 'pointer' }}
        justifyContent='center'
        alignItems='center'
        onClick={() => toggleModalOpen(true)}
        role='button'
      >
        <InfoOutlined color='error' sx={{ marginRight: '10px' }} />
        <span>No Client</span>
      </Grid>
    </>
  );
};

const memberClientsAndPrograms = (user: User): ClientProgramMemberships[] =>
  user.clients.length > 0
    ? user.clients.reduce((acc, client) => {
        const clientRole = client.members.find(cM => cM.id === user.id).role;
        const clientPrograms =
          user.programs && user.programs.length > 0
            ? user.programs.filter(p => p.client_id === client.id)
            : [];
        acc.push({
          id: client.id,
          name: client.name,
          role: clientRole,
          programs: clientPrograms,
        });
        return acc;
      }, [])
    : [];

const UserAlerts: FC<{ clientSearchResultsLimit?: number }> = ({
  clientSearchResultsLimit = 10,
}) => {
  const { useUserAlerts } = useAdminQueries();
  const { userAlerts, isLoading, refetch } = useUserAlerts();
  const theme = useTheme();
  const [modalOpen, toggleModalOpen] = useState(false);
  const unassignedUsersCount = useMemo(() => {
    return userAlerts?.filter(u => {
      return u.programs.length === 0;
    }).length;
  }, [userAlerts]);

  return (
    <>
      <Dialog
        maxWidth='lg'
        onClose={() => toggleModalOpen(false)}
        open={modalOpen}
      >
        <DialogTitle
          title='Recent Users'
          buttonOnClick={() => toggleModalOpen(false)}
        />
        <DialogContent>
          <Table
            bordered
            pagination={false}
            columns={[
              {
                title: 'Signed Up',
                sorter: (a, b) =>
                  dayjs(a.updatedAt).unix() - dayjs(b.updatedAt).unix(),
                render: (_, u) => (
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {dayjs(u.createdAt).format('M/DD/YY [at] h:mma')}
                  </span>
                ),
              },
              {
                title: 'Name',
                sorter: (a, b) => (a.first_name > b.first_name ? -1 : 1),
                render: (_, u) => (
                  <span>
                    {u.first_name} {u.last_name}
                  </span>
                ),
              },
              {
                title: 'Email',
                sorter: (a, b) => (a.email > b.email ? -1 : 1),
                render: (_, u) => <span>{u.email}</span>,
              },
              {
                title: 'Client',
                key: 'client',
                sorter: (a, b) => {
                  const aMemberClientsAndPrograms = memberClientsAndPrograms(a);
                  const bMemberClientsAndPrograms = memberClientsAndPrograms(b);
                  if (bMemberClientsAndPrograms[0] !== undefined) {
                    return aMemberClientsAndPrograms[0]?.name >
                      bMemberClientsAndPrograms[0].name
                      ? -1
                      : 1;
                  }
                  return aMemberClientsAndPrograms[0]?.name > 'No Client'
                    ? -1
                    : 1;
                },
                render: (_, u: User) => {
                  const memberships = memberClientsAndPrograms(u);
                  return memberships.length > 0 ? (
                    <Link
                      variant='a2'
                      href={`/my-business/${memberships[0]?.id}`}
                    >
                      <div>{memberships[0].name}</div>
                    </Link>
                  ) : (
                    <Space size={1}>
                      <ClientSearchAndAssign
                        user={u}
                        reloadAlerts={refetch}
                        limit={clientSearchResultsLimit}
                      />
                    </Space>
                  );
                },
              },
              {
                title: 'Program',
                key: 'program',
                sorter: (a, b) => {
                  const aMemberClientsAndPrograms = memberClientsAndPrograms(a);
                  const bMemberClientsAndPrograms = memberClientsAndPrograms(b);
                  if (
                    bMemberClientsAndPrograms[0] &&
                    bMemberClientsAndPrograms[0]?.programs.length
                  ) {
                    return aMemberClientsAndPrograms[0].programs[0]?.name >
                      bMemberClientsAndPrograms[0].programs[0]?.name
                      ? -1
                      : 1;
                  }
                  return aMemberClientsAndPrograms[0]?.programs[0]?.name >
                    'No Program'
                    ? -1
                    : 1;
                },
                render: (_, u: User) => {
                  const memberships = memberClientsAndPrograms(u);
                  return memberships.length > 0 &&
                    memberships[0].programs.length > 0 ? (
                    <Link
                      variant='a2'
                      href={`/program/${memberships[0]?.programs[0]?.id}`}
                    >
                      <div>{memberships[0]?.programs[0]?.name}</div>
                    </Link>
                  ) : (
                    <Stack direction='row' alignItems='center' gap='.5em'>
                      <InfoOutlined color='error' />
                      <Typography variant='body2'>No Program</Typography>
                    </Stack>
                  );
                },
              },
            ]}
            rowKey={u => u.id}
            dataSource={userAlerts}
          />
        </DialogContent>
        <DialogActions>
          <Button text='Close' onClick={() => toggleModalOpen(false)} />
        </DialogActions>
      </Dialog>
      <span
        role='button'
        tabIndex={0}
        style={{ cursor: userAlerts?.length > 0 ? 'pointer' : 'default' }}
        onClick={() => toggleModalOpen(true)}
        onKeyDown={e => handleKeyboardNav(e.key, () => toggleModalOpen(true))}
      >
        {isLoading && userAlerts?.length === 0 ? (
          <Space>
            <GroupOutlined fontSize='large' />
            <div>Checking recent users...</div>
          </Space>
        ) : (
          <Stack
            justifyContent='space-between'
            direction='row'
            alignItems='center'
          >
            <Stack direction='row' alignItems='center' gap={1}>
              <GroupOutlined
                fontSize='large'
                color={unassignedUsersCount === 0 ? 'success' : 'error'}
              />
              <span>
                {userAlerts?.length === 0 ? 'No' : userAlerts?.length} recent{' '}
                {userAlerts?.length === 1 ? 'signup' : 'signups'}
              </span>
            </Stack>
            {unassignedUsersCount > 0 && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '1.6em',
                  backgroundColor: theme.palette.error.main,
                  color: '#fff',
                  borderRadius: '.4em',
                  padding: '0 1em',
                }}
              >
                <div style={{ fontSize: '.8em', whiteSpace: 'nowrap' }}>
                  {unassignedUsersCount} Unassigned
                </div>
              </div>
            )}
          </Stack>
        )}
      </span>
    </>
  );
};

export default UserAlerts;
