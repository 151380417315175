import React from 'react';
import dayjs from 'dayjs';
import { Box, Stack, Typography } from '@mui/material/';
import Collapse from '@mui/material/Collapse';
import { Button } from '@src/components/sc-design-system';

import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

interface AidaExpandingCardProps {
  children: React.ReactNode;
  timestamp?: number;
  truncate?: boolean;
  boxTheme?: 'white' | 'grey';
}

function AidaExpandingCard(props: AidaExpandingCardProps) {
  const { children, timestamp, truncate = false, boxTheme = 'grey' } = props;
  const [showMore, setShowMore] = React.useState(false);

  return (
    <Box
      sx={{
        backgroundColor: boxTheme === 'grey' ? 'grey.100' : 'white.main',
        border: boxTheme === 'grey' ? '1px solid' : '',
        borderColor: boxTheme === 'grey' ? 'grey.200' : '',
        padding: '1.25em 1.5em',
        borderRadius: '16px',
      }}
    >
      {truncate ? (
        <Collapse in={showMore} collapsedSize={225}>
          {children}
        </Collapse>
      ) : (
        children
      )}
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ margin: '.5em' }}
      >
        {/* the div tags ensures that the ui will render correctly regardless of what is visible for the user*/}
        <div></div>
        <div>
          {truncate && (
            <Button
              onClick={() => setShowMore(!showMore)}
              size='small'
              endIcon={!showMore ? <ExpandMore /> : <ExpandLess />}
              text={!showMore ? 'Show more' : 'Show less'}
              variant='text'
              sx={{ alignSelf: 'center' }}
            />
          )}
        </div>
        <div>
          {timestamp && (
            <Typography
              variant='body3m'
              color='grey.700'
              sx={{
                alignSelf: 'end',
                display: 'contents',
                fontStyle: 'italic',
              }}
            >
              {dayjs(timestamp).fromNow()}
            </Typography>
          )}
        </div>
      </Stack>
    </Box>
  );
}

export { AidaExpandingCard };
