import React from 'react';
import { Dialog, DialogActions, DialogContent, Link } from '@mui/material';
import {
  Button,
  DialogTitle,
  useToast,
} from '@src/components/sc-design-system';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import Client from '@src/models/Client';
import { PerformanceCycleForm } from '../PerformanceCycleLandingPage/modals/PerformanceCycleForm';

interface EditPerformanceCycleModalProps {
  clientId: string;
  performanceCycle: PerformanceCycleI;
  setOpenEditModal: (b: boolean) => void;
  openEditModal: boolean;
  performanceCycleNames: string[];
}

const EditPerformanceCycleModal: React.FunctionComponent<
  EditPerformanceCycleModalProps
> = props => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const {
    clientId,
    performanceCycle,
    setOpenEditModal,
    openEditModal,
    performanceCycleNames,
  } = props;

  const performanceCycleObj = {
    name: performanceCycle.name,
    start: dayjs(performanceCycle.start),
    end: dayjs(performanceCycle.end),
  };

  const form = useForm({
    mode: 'all',
    defaultValues: performanceCycleObj as PerformanceCycleFormDefaultValues,
  });
  const {
    reset,
    formState: { isValid, isDirty },
  } = form;

  const { mutate: editPerformanceCycle, isPending } = useMutation({
    mutationFn: async (payload: PerformanceCycleIBase) => {
      return api.clients.editPerformanceCycle(clientId, {
        id: performanceCycle.id,
        ...payload,
      });
    },
    onSuccess: ({ data: updatedClient }) => {
      queryClient.setQueryData(
        ['client', clientId],
        new Client(updatedClient.data),
      );
      setOpenEditModal(false);
    },
    onError: () => {
      toast.error('Unable to edit performance cycle');
    },
  });

  const onSubmit = (data: PerformanceCycleOnSubmitProps) => {
    editPerformanceCycle({
      name: data.name,
      start: data.start.valueOf(),
      end: data.end.valueOf(),
    });
  };

  function handleClose() {
    setOpenEditModal(false);
    reset(performanceCycleObj);
  }

  return (
    <Dialog open={openEditModal} onClose={handleClose}>
      <DialogTitle title='Edit performance cycle' />
      <DialogContent dividers>
        <PerformanceCycleForm
          onSubmit={onSubmit}
          form={form}
          performanceCycleNames={performanceCycleNames}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Link
          variant='a2'
          onClick={() => reset({ name: '', start: '', end: '' })}
        >
          Clear all fields
        </Link>
        <div>
          <Button
            key='cancel'
            text='Cancel'
            variant='outlined'
            onClick={handleClose}
          />
          <Button
            sx={{ marginLeft: '.5em' }}
            type='submit'
            form='performance-cycle-form'
            disabled={!isValid || !isDirty}
            key='save'
            text='Save'
            loading={isPending}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};

export { EditPerformanceCycleModal };
