import React from 'react';
import {
  Divider,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  TextField,
} from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';

interface RatingScaleFormProps {
  onSubmit: (data: RatingScaleOnSubmitProps) => void;
  form: UseFormReturn<RatingScaleFormDefaultValues, undefined>;
  selfRatingOptions: number[];
}

const RatingScaleForm: React.FunctionComponent<
  RatingScaleFormProps
> = props => {
  const { onSubmit, form, selfRatingOptions } = props;
  const { control, handleSubmit, watch, setValue } = form;
  return (
    <form onSubmit={handleSubmit(onSubmit)} id='rating-scale-form'>
      <Typography variant='body1m' sx={{ marginBottom: '1em' }}>
        Select scale
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name='rating_scale'
            control={control}
            rules={{
              required: 'A rating scale is required.',
            }}
            render={({ field }) => {
              const { ref, ...fieldProps } = field;
              return (
                <>
                  <FormLabel>
                    Choose a rating point scale to evaluate employees at the end
                    your performance cycle.
                  </FormLabel>
                  <RadioGroup
                    ref={ref}
                    aria-labelledby='rating-scale'
                    name={fieldProps.name}
                    {...fieldProps}
                    row
                  >
                    {selfRatingOptions.map(number => (
                      <FormControlLabel
                        key={`${fieldProps.name}_${number}`}
                        control={<Radio />}
                        label={`1-${number} Scale`}
                        value={number}
                      />
                    ))}
                  </RadioGroup>
                </>
              );
            }}
          />
          <Divider />
          <Typography variant='body1m' sx={{ marginBottom: '1em' }}>
            Add rating labels
          </Typography>
          <Controller
            name='rating_scale_labels'
            control={control}
            render={() => {
              const scale = Array.from(
                { length: watch('rating_scale') },
                (_, i) => i + 1,
              );
              const labels = watch('rating_scale_labels').filter(i => i);

              return (
                <>
                  <FormLabel>
                    You can provide custom labels for each rating below or leave
                    them all blank to have a purely numeric scale.
                  </FormLabel>
                  {labels.length > 0 && labels.length < watch('rating_scale') && (
                    <FormHelperText error color='error'>
                      A rating label must be provided for all ratings.
                    </FormHelperText>
                  )}
                  <List sx={{ margin: '1.5em 0' }}>
                    {scale.map(i => {
                      const placeholderText =
                        i === 1
                          ? 'e.g. Needs improvement'
                          : i === +watch('rating_scale')
                          ? 'e.g. Exceed expectations'
                          : '';
                      let value;
                      try {
                        value = watch('rating_scale_labels')[i - 1];
                      } catch (e) {}
                      return (
                        <ListItem key={i}>
                          <Stack direction='row' alignItems='center'>
                            <Typography variant='body1' sx={{ width: '25px' }}>
                              {i}
                            </Typography>
                            <TextField
                              id={`rating_scale_label_${i}`}
                              style={{ width: '265px' }}
                              placeholder={placeholderText}
                              onChange={e => {
                                const labels = watch('rating_scale_labels');
                                labels[i - 1] =
                                  e.target.value === ''
                                    ? undefined
                                    : e.target.value;
                                setValue('rating_scale_labels', labels);
                              }}
                              error={labels.length > 0 && !value}
                              value={value || ''}
                            />
                          </Stack>
                        </ListItem>
                      );
                    })}
                  </List>
                </>
              );
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export { RatingScaleForm };
