import React from 'react';
import { Col } from 'antd';
import { Avatar } from '@src/components/sc-design-system';

import CoacheeBio from '../CoacheeBio';

import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

import './InterviewCard.less';

export const InterviewCard = ({ interview, scRole }) => {
  const participantRole = scRole === 'coach' ? 'coachee' : 'coach';
  const participant = interview[participantRole];

  return (
    <div className='interview-card'>
      <Stack key={interview.id} direction='row' justifyContent='space-between'>
        <Col className='header-info'>
          <CoacheeBio
            coachee={Object.assign({}, participant, {
              client_name: interview.client,
              client_id: interview.client_id,
              is_aida_active: interview.is_aida_active,
              program_name: interview.program,
              program_info: { id: interview.program_id },
            })}
            hideSurveyResponses={interview.program_type === 'mentoring'}
            hideAida={true}
          >
            <>
              <div
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 8,
                  cursor: 'pointer',
                }}
              >
                <Avatar
                  srcId={participant.id}
                  alt={`${participant.first_name} ${participant.last_name}`}
                  variant='interactive'
                  sx={{ marginRight: '0.25em' }}
                />
                <Typography variant='h3' sx={{ cursor: 'pointer' }}>
                  {participant.first_name} {participant.last_name}
                </Typography>
              </div>
              <Typography variant='body2' color='grey.700'>
                {interview.client}: {interview.program}
              </Typography>
            </>
          </CoacheeBio>
        </Col>
        <Stack spacing={1} textAlign='right'>
          <Stack direction='row' gap={1} alignItems='center'>
            <CalendarTodayOutlined
              sx={{ width: '.8em', height: '.8em' }}
              color='action'
            />
            <Typography variant='body2'>
              {dayjs(interview.start_time).format('MMM D, YYYY')}
            </Typography>
          </Stack>
          <Stack direction='row' gap={1} alignItems='center'>
            <ScheduleIcon
              sx={{ width: '.8em', height: '.8em' }}
              color='action'
            />
            <Typography variant='body2' sx={{ marginLeft: '1em' }}>
              {dayjs(interview.start_time).format('h:mm a')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};
