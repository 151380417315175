import { addressSchemaResolver } from '@src/components/common/AddressFormGrid/schema';
import { useCallback } from 'react';
import * as Yup from 'yup';
import _isEmpty from 'lodash/isEmpty';

const clientSchema = Yup.object({
  name: Yup.string().required('Client name is required'),
  email_domain: Yup.string()
    .matches(/^[a-z0-9]+[.][a-z]+/, {
      message: 'Email domain must be a valid domain name',
    })
    .required('Email domain is required'),
  website: Yup.string()
    .matches(
      /^((https?):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
      { message: 'Website must be a valid URL' },
    )
    .required('Website is required'),
  salesforce_id: Yup.string().required('Salesforce ID is required'),
  addresses: Yup.array(),
  departments: Yup.array().of(
    Yup.object({
      id: Yup.string(),
      name: Yup.string().required(),
    }),
  ),
  hris_enabled: Yup.boolean(),
  aida_enabled: Yup.boolean(),
});

const useSchemaResolver = schema =>
  useCallback(
    data => {
      const addressValidation = addressSchemaResolver(data);
      try {
        schema.validateSync(data, { abortEarly: false });
        if (!_isEmpty(addressValidation.errors)) {
          throw new Error('Address validation failed');
        }
        return { values: data, errors: {} };
      } catch (errors) {
        const formattedErrors = (errors?.inner || []).reduce(
          (obj, currentError) => {
            return {
              ...obj,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            };
          },
          {},
        );
        return {
          values: {},
          errors: {
            ...formattedErrors,
            ...(addressValidation.errors || {}),
          },
        };
      }
    },
    [schema],
  );

export { useSchemaResolver, clientSchema };
