import React from 'react';
import {
  Typography,
  Dialog,
  DialogContent,
  Alert,
  DialogActions,
} from '@mui/material';
import {
  Button,
  DialogTitle,
  TableMUI,
} from '@src/components/sc-design-system';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { createColumnHelper } from '@tanstack/react-table';

interface ProgramSurvey {
  key: string;
  title: string;
  type: string;
  enabled: boolean;
}

interface StartProgramModalProps {
  startProgramModalOpen: boolean;
  setStartProgramModalOpen: (b: boolean) => void;
  programSurveys: ProgramSurvey[];
  buttonOnClick: () => void;
}

const StartProgramModal = (props: StartProgramModalProps) => {
  const {
    startProgramModalOpen,
    setStartProgramModalOpen,
    programSurveys,
    buttonOnClick,
  } = props;
  const columnHelper = createColumnHelper<ProgramSurvey>();
  const columns = [
    columnHelper.accessor('title', {
      header: () => <span>Survey</span>,
    }),
    columnHelper.accessor('type', {
      header: () => <span>Type</span>,
    }),
    columnHelper.accessor('enabled', {
      header: () => <span>Enabled</span>,
      cell: info =>
        info.getValue() ? (
          <CheckIcon color='success' />
        ) : (
          <CloseIcon color='error' />
        ),
    }),
  ];
  return (
    <Dialog
      maxWidth='lg'
      open={startProgramModalOpen}
      onClose={() => setStartProgramModalOpen(false)}
    >
      <DialogTitle
        buttonOnClick={() => setStartProgramModalOpen(false)}
        title='Begin Inviting Users?'
      />
      <DialogContent>
        <Alert severity='warning' icon={false}>
          <Typography variant='body2'>
            Once you invite users, they can begin responding to surveys. Please
            confirm you have the correct surveys in place before inviting your
            members to join.
          </Typography>
        </Alert>
        <TableMUI data={programSurveys} columns={columns} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setStartProgramModalOpen(false)}
          text='Cancel'
          variant='outlined'
        />
        <Button onClick={buttonOnClick} text='Yes, Start Program' />
      </DialogActions>
    </Dialog>
  );
};

export default StartProgramModal;
