import { makeObservable, runInAction } from 'mobx';
import { PlatformAPI } from './client/apis/platform-api';
import { Logger } from './logger';

export class Platform {
  private api: PlatformAPI;
  private logger: Logger;
  features: Map<string, boolean>;
  initializing: boolean;

  constructor(api: PlatformAPI) {
    this.api = api;
    this.logger = new Logger('platform');
    this.features = new Map();
    this.initializing = false;
    makeObservable(this, {
      initializing: true,
    });
  }

  setGlobalFlags() {
    let platform = navigator.platform;
    let os: string;
    if (platform.includes('Mac')) {
      os = 'mac';
    } else if (platform.includes('Win')) {
      os = 'windows';
      document.documentElement.classList.add('custom-scrollbars');
    } else {
      os = 'linux';
    }
    document.documentElement.setAttribute('data-os', os);
  }

  /**
   * Loads configuration for the current environment.
   */
  async loadConfiguration(environment: string) {
    this.logger.debug('load configuration start');
    this.logger.debug(`environment: ${environment}`);
    runInAction(() => {
      this.initializing = true;
    });
    try {
      const config = await this.api.getConfiguration(environment);
      for (const [key, value] of Object.entries(config.features)) {
        this.features.set(key, !!value);
      }
      this.logger.debug('load configuration success');
    } catch (e) {
      this.logger.error('load configuration error: ' + e.message);
    }
    runInAction(() => {
      this.initializing = false;
    });
  }

  /**
   * Returns true if the feature flag is enabled. In local development, all
   * features are enabled by default.
   */
  enabled(flag: string): boolean {
    if (['development', 'uat'].includes(process.env.NODE_ENV)) {
      return true;
    }
    return !!this.features.get(flag);
  }
}
