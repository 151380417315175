import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';

obey.modifier('now', () => Date.now());
obey.creator('uuid', () => uuidv4());

export const PerformanceCycleFeedbackSchema = {
  id: { type: 'string', required: true, creator: 'uuid' },
  performance_cycle_id: { type: 'string', required: true },
  feedback_form_id: { type: 'string', required: true },
  members: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        member_id: { type: 'string', required: true },
        stakeholders: {
          type: 'array',
          empty: true,
          values: {
            type: 'object',
            keys: {
              user_id: { type: 'string', required: true },
              relationship: {
                type: 'string',
                allow: ['manager', 'direct_report', 'peer'],
                required: true,
              },
              requested_at: { type: 'number', creator: 'now' },
              send_error: { type: 'boolean' },
              feedback_submission_id: { type: 'string', allowNull: true },
              feedback_pending: { type: 'boolean' },
            },
          },
        },
      },
    },
    default: [],
    empty: true,
  },
  updatedAt: { type: 'number', modifier: 'now' },
};

// eslint-disable-next-line no-unused-vars
export type PerformanceCycleFeedbackType = {
  [K in keyof typeof PerformanceCycleFeedbackSchema]?: any;
};

export const PerformanceCycleFeedbackModel = obey.model(
  PerformanceCycleFeedbackSchema,
);
