import querystring from 'node:querystring';
import { HTTPClient } from '@src/lib/client';
import { ClientType } from '@shared/schemas';

export interface GetMembersParamsIF {
  keyword?: string;
  type?: 'administrators' | 'coaches' | 'coachees';
  limit?: number;
  page?: number;
  return_fields?: string[];
  include_invitees?: boolean;
  include_hris?: boolean;
}

interface AddMemberIF {
  clientId: string;
  memberId: string;
  role?: string;
  email?: string;
}

interface Invitee {
  invitee_first_name?: string;
  invitee_last_name?: string;
  invitee_email: string;
}

interface InviteMemberIF {
  clientId: string;
  invitees: Invitee[];
}

interface RevokeInviteIF {
  clientId: string;
  email: string;
}

export class ClientsAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async getClient(id: string) {
    return this.http.request({
      url: `/clients/${id}`,
      method: 'get',
    });
  }

  async getClientFields(id: string, queryParams: { return_fields: string[] }) {
    return this.http.request({
      url: `/clients/${id}?${querystring.encode(queryParams)}`,
      method: 'get',
    });
  }

  async getMembers(clientId: string, queryParams: GetMembersParamsIF) {
    return this.http.request({
      url: `/clients/members/${clientId}?${Object.keys(queryParams)
        .map(key => key + '=' + encodeURIComponent(queryParams[key]))
        .join('&')}`,
      method: 'get',
    });
  }

  async getMentors(clientId: string) {
    return this.http.request({
      url: `/clients/mentors/${clientId}`,
      method: 'get',
    });
  }

  async getIsClientMentor(clientId: string, memberId: string) {
    return this.http.request({
      url: `/clients/mentors/${clientId}/${memberId}`,
      method: 'get',
    });
  }

  async saveClient(data: ClientType) {
    return this.http.request({
      url: `/clients/${data.id && data.id.length > 0 ? data.id : 'create'}`,
      method: data.id && data.id.length > 0 ? 'patch' : 'post',
      data,
    });
  }

  async getList(queryParams: GetClientsListParamsIF) {
    return this.http.request({
      url: `/clients/list?${Object.keys(queryParams)
        .map(key => key + '=' + encodeURIComponent(queryParams[key]))
        .join('&')}`,
      method: 'get',
    });
  }

  async getCounts() {
    return this.http.request({
      url: '/clients/counts',
      method: 'get',
    });
  }

  async addClientMember(data: AddMemberIF) {
    return this.http.request({
      url: '/clients/members/add',
      method: 'post',
      data,
    });
  }

  async inviteClientMembers(data: InviteMemberIF) {
    return this.http.request({
      url: '/clients/members/invite',
      method: 'post',
      data,
    });
  }

  async sendInviteeReminders(clientId: string, reminders: Invitee[]) {
    return this.http.request({
      url: '/clients/members/send-invitee-reminders',
      method: 'post',
      data: { clientId, reminders },
    });
  }

  async revokeInvite(data: RevokeInviteIF) {
    return this.http.request({
      url: '/clients/revoke-invite',
      method: 'post',
      data,
    });
  }

  async undoRevokeInvite(data: RevokeInviteIF) {
    return this.http.request({
      url: '/clients/undo-revoke-invite',
      method: 'post',
      data,
    });
  }

  async quickSearch(keyword: string, limit: number) {
    return this.http.request({
      url: `/clients/quick-search?keyword=${keyword || ''}&limit=${limit}`,
      method: 'get',
    });
  }

  async changeMentorStatus(
    clientId: string,
    memberId: string,
    isMentor: boolean,
  ) {
    return this.http.request({
      url: `/clients/mentors/${clientId}?memberId=${memberId}&isMentor=${isMentor}`,
      method: 'patch',
    });
  }

  async changeAdminStatus(
    clientId: string,
    memberId: string,
    isAdmin: boolean,
  ) {
    return this.http.request({
      url: `/clients/admins/${clientId}?memberId=${memberId}&isAdmin=${isAdmin}`,
      method: 'patch',
    });
  }

  async changeDepartment(
    clientId: string,
    memberId: string,
    departmentId: string,
  ) {
    return this.http.request({
      url: `/clients/departments/${clientId}?memberId=${memberId}&departmentId=${departmentId}`,
      method: 'patch',
    });
  }

  async changeManager(clientId: string, memberId: string, managerId: string) {
    return this.http.request({
      url: `/clients/managers/${clientId}?memberId=${memberId}&managerId=${managerId}`,
      method: 'patch',
    });
  }

  async getClientDirectReportsByManagerId(managerId: string, clientId: string) {
    return this.http.request({
      url: `/clients/managers/${managerId}/direct-reports?cid=${clientId}`,
      method: 'get',
    });
  }

  async changeMemberTitle(clientId: string, memberId: string, title: string) {
    return this.http.request({
      url: `/clients/members/${clientId}?memberId=${memberId}`,
      method: 'patch',
      data: { title },
    });
  }

  async getPerformanceCycleById(clientId: string, performanceCycleId: string) {
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles/${performanceCycleId}/performance-cycle`,
      method: 'get',
    });
  }

  async addPerformanceCycle(clientId: string, data: PerformanceCycleIBase) {
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles`,
      method: 'patch',
      data,
    });
  }

  async editPerformanceCycle(
    clientId: string,
    data: Partial<PerformanceCycleI>,
  ) {
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles/${data.id}`,
      method: 'patch',
      data,
    });
  }

  async writeGoalCycle(data: GoalCycle, clientId, performanceCycleId) {
    const action = data.id ? 'edit-goal-cycle' : 'add-goal-cycle';
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles/${performanceCycleId}/${action}`,
      method: 'patch',
      data,
    });
  }

  async deleteGoalCycle(goal_cycle_id, clientId, performanceCycleId) {
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles/${performanceCycleId}/delete-goal-cycle`,
      method: 'patch',
      params: { goal_cycle_id },
    });
  }

  async getClientGoalsByPerformanceCycleId(
    clientId: string,
    performanceCycleId: string,
  ) {
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles/${performanceCycleId}/goal-cycles`,
      method: 'get',
    });
  }

  async getPerformanceCycleResults(
    clientId: string,
    performanceCycleId: string,
  ) {
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles/${performanceCycleId}/results`,
      method: 'get',
    });
  }

  async getPerformanceCycleResultsForDirectReport(
    clientId: string,
    performanceCycleId: string,
    directReportId: string,
  ) {
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles/${performanceCycleId}/results/${directReportId}`,
      method: 'get',
    });
  }

  async updatePerformanceCycleResults(
    clientId: string,
    performanceCycleId: string,
    directReportId: string,
    data: Omit<PerformanceCycleResultsRolesBase, 'id'>,
  ) {
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles/${performanceCycleId}/results/${directReportId}`,
      method: 'patch',
      data,
    });
  }

  async getEmployeePerformanceEvaluationReport(
    clientId: string,
    performanceCycleId: string,
    userId: string,
  ) {
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles/${performanceCycleId}/report/${userId}`,
      method: 'get',
    });
  }

  async getAggregateEmployeeCompetencyResults(
    clientId: string,
    performanceCycleId: string,
    userId: string,
  ) {
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles/${performanceCycleId}/competency-results/${userId}`,
      method: 'get',
    });
  }

  async getPerformanceCycleCsvReport(
    clientId: string,
    performanceCycleId: string,
  ) {
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles/${performanceCycleId}/report/`,
      method: 'get',
    });
  }

  async updatePerformanceCycleFeedback(
    clientId: string,
    performanceCycleId: string,
    feedbackFormId: string,
    data: any,
  ) {
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles/${performanceCycleId}/feedback/${feedbackFormId}`,
      method: 'patch',
      data,
    });
  }

  async getPerformanceCycleFeedbackStatusForMember(
    clientId: string,
    performanceCycleId: string,
    feedbackFormId: string,
    memberId: string,
  ) {
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles/${performanceCycleId}/feedback/${feedbackFormId}/${memberId}`,
      method: 'get',
    });
  }

  async getPerformanceCycleFeedbackStatus(
    clientId: string,
    performanceCycleId: string,
    feedbackFormId: string,
  ) {
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles/${performanceCycleId}/feedback/${feedbackFormId}`,
      method: 'get',
    });
  }

  async markFeedbackCompleteForStakeholder(
    clientId: string,
    performanceCycleId: string,
    feedbackFormId: string,
    memberId: string,
    data: any,
  ) {
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles/${performanceCycleId}/feedback/${feedbackFormId}/${memberId}/submit-feedback`,
      method: 'patch',
      data,
    });
  }

  async getEmployeeFeedbackReport(
    clientId: string,
    performanceCycleId: string,
    feedbackFormId: string,
    userId: string,
  ) {
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles/${performanceCycleId}/feedback/${feedbackFormId}/report/${userId}`,
      method: 'get',
    });
  }

  async getAggregateEmployeeCompetencyFeedback(
    clientId: string,
    performanceCycleId: string,
    feedbackFormId: string,
    userId: string,
  ) {
    return this.http.request({
      url: `/clients/${clientId}/performance-cycles/${performanceCycleId}/feedback/${feedbackFormId}/report/${userId}/competencies`,
      method: 'get',
    });
  }
}
