import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import {
  Button,
  TextField,
  useBreadcrumbs,
  DialogTitle,
  Spin,
} from '@src/components/sc-design-system';
import { ActiveClientKey } from '@src/hooks/useActiveClient';
import { useClients } from '@src/hooks/useClients';
import { useFullScreen } from '@src/hooks/useFullScreen';
import SurveyType from '@src/models/Survey';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

interface DuplicateSurveyModalProps {
  survey: SurveyType;
  handleClose: Function;
  forClient?: boolean;
}

const DuplicateSurveyModal = ({
  survey,
  forClient = false,
  handleClose,
}: DuplicateSurveyModalProps) => {
  const { fullScreen } = useFullScreen();
  const { pathname } = useLocation();
  const { appendBreadcrumb } = useBreadcrumbs();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  // Arbitrarily high number for the limit parameter
  const { clients: clientsRes, isLoading: isClientsLoading } = useClients(
    { limit: 1000, return_fields: ['id', 'name'] },
    { enabled: forClient },
  );

  const clients = clientsRes?.map(c => ({
    id: c.id,
    name: c.name,
  }));

  const {
    control,
    handleSubmit,
    formState: { isValid, errors, touchedFields, isDirty },
  } = useForm({
    mode: 'all',
    defaultValues: {
      title: '',
      description: '',
      client: '',
    },
  });

  const onSubmit = (data: any) => {
    let suffix =
      pathname.includes('insights') || pathname.includes('my-business')
        ? './surveys'
        : '.';
    if (forClient) {
      localStorage.setItem(
        ActiveClientKey.sc_admin_active_client_id,
        data.client,
      );
      queryClient.invalidateQueries();
      suffix = `/insights/${data.client}/surveys`;
    }
    const path = `${suffix}/editor?id=${survey?.id}&action=${
      forClient ? 'duplicate-for-client' : 'duplicate'
    }&title=${data.title}`;
    navigate(path, { state: { description: data.description } });
    appendBreadcrumb({
      path,
      label: data.title,
      search: location.search,
    });
    handleClose();
  };

  return (
    <Dialog
      onClose={() => handleClose()}
      open
      maxWidth='sm'
      fullScreen={fullScreen}
    >
      <DialogTitle
        title={`Duplicate survey${forClient ? ' for client' : ''}`}
        buttonOnClick={() => handleClose()}
      />
      <DialogContent
        sx={{
          padding: '2em',
          paddingTop: '2em !important',
          gap: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {survey && (
          <>
            <Alert
              severity='info'
              sx={{
                backgroundColor: 'info.100',
                textAlign: 'center',
                'span.survey-title': {
                  fontWeight: 'bold',
                },
              }}
              icon={false}
            >
              <Typography variant='body1'>
                This action will create a duplicate version of{' '}
                <span className='survey-title'>{survey?.title}</span>
                {forClient ? ' for the selected client' : ''}. If you wish to
                continue, please create a unique name for the survey and
                continue.
              </Typography>
            </Alert>
            <form onSubmit={handleSubmit(onSubmit)} id='main'>
              <Stack gap='2em'>
                {forClient && (
                  <FormControl required>
                    <InputLabel
                      id='client-select-label'
                      sx={{ marginBottom: '0.5em' }}
                    >
                      Client
                    </InputLabel>
                    <Controller
                      name='client'
                      control={control}
                      rules={{
                        required: 'Client is required',
                        // TODO: Validate that name does not exist in this client's surveys (but what if they haven't selected a client yet?)
                      }}
                      render={({ field }) => {
                        const { ref, ...fieldProps } = field;
                        return (
                          <Select
                            {...fieldProps}
                            ref={ref}
                            labelId='client-select-label'
                            id={field.name}
                            error={Boolean(
                              touchedFields.client && errors?.client,
                            )}
                          >
                            {isClientsLoading ? (
                              <Spin />
                            ) : (
                              clients?.map(c => {
                                return (
                                  <MenuItem key={c.id} value={c.id}>
                                    {c.name}
                                  </MenuItem>
                                );
                              })
                            )}
                          </Select>
                        );
                      }}
                    />
                    {Boolean(touchedFields.client && errors.client) && (
                      <FormHelperText error color='error'>
                        Client is required
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
                <Controller
                  name='title'
                  control={control}
                  rules={{
                    required: 'Survey name is required',
                    validate: value => {
                      return (
                        survey?.title?.toLowerCase() !== value?.toLowerCase() ||
                        'Please enter a unique survey name'
                      );
                    },
                  }}
                  render={({ field }) => {
                    const { ref, ...fieldProps } = field;
                    return (
                      <TextField
                        {...fieldProps}
                        innerRef={ref}
                        label='Survey name'
                        required
                        fullWidth
                        error={Boolean(touchedFields.title && errors?.title)}
                        errorMessage={
                          touchedFields.title && errors?.title?.message
                        }
                        id={field.name}
                      />
                    );
                  }}
                />
                <Controller
                  name='description'
                  control={control}
                  render={({ field }) => {
                    const { ref, ...fieldProps } = field;
                    return (
                      <TextField
                        {...fieldProps}
                        innerRef={ref}
                        id={field.name}
                        label='What do you intend to use the survey for?'
                        multiline
                        rows={6}
                        fullWidth
                        helperText='Optionally enter a short description that is viewable only by you and other client admins within your organization.'
                        helperTextPosition='top'
                      />
                    );
                  }}
                />
              </Stack>
            </form>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => handleClose()} text='Back' />
        <Button
          type='submit'
          form='main'
          text={`Duplicate survey${forClient ? ' for client' : ''}`}
          disabled={!isValid || !isDirty}
        />
      </DialogActions>
    </Dialog>
  );
};

export { DuplicateSurveyModal };
