import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import {
  Alert,
  AlertTitle,
  Chip,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useStore } from '@src/models/Store';
import PersonOutlined from '@mui/icons-material/PersonOutlined';
import { useUserQueries } from '@src/hooks/useUserQueries';
import { useNavigation } from '@src/navigation';
import { useQueryParams } from '@src/utils';
import { SurveySubmissionModal } from '@src/components/common/SurveySubmissionModal';
import { GreyHeaderBox } from '@src/components/sc-design-system';
import { useClientQueries } from '@src/hooks/useClientQueries';
import User from '@src/models/User';
import LinearScale from '@mui/icons-material/LinearScale';
import { SelfRatingModal } from './SelfRatingModal';
import { ViewRatingModal } from '@src/components/common/PerformanceCycle/ViewRatingModal';
import { EvaluationChip } from '@src/components/common/PerformanceCycle/EvaluationChip';
import { RatingChip } from '@src/components/common/PerformanceCycle/RatingChip';

interface EvaluationPeriodProps {
  performanceCycle: PerformanceCycleI;
  clientId: string;
  user: User;
}

interface SelfEvaluationProps extends EvaluationPeriodProps {
  hasEvaluationPeriodEnded: boolean;
}

function SelfEvaluation(props: SelfEvaluationProps) {
  const { performanceCycle, clientId, user, hasEvaluationPeriodEnded } = props;
  const [submissionModalOpen, setSubmissionModalOpen] = useState(false);
  const query = useQueryParams();
  const navigation = useNavigation();
  const { useGetUserSurveySubmission } = useUserQueries(user.id);
  const { surveySubmission } = useGetUserSurveySubmission({
    surveyId: performanceCycle?.self_evaluation_form_id,
    performance_cycle_id: performanceCycle?.id,
  });

  return (
    <Stack width={{ md: '50%' }} spacing={2} padding='2em'>
      <Stack direction='row' spacing={1}>
        <PersonOutlined />
        <Typography>
          {!hasEvaluationPeriodEnded && !surveySubmission
            ? 'Complete your self-evaluation'
            : 'Self-evaluation'}
        </Typography>
      </Stack>
      <EvaluationChip
        hasEvaluationPeriodEnded={hasEvaluationPeriodEnded}
        surveySubmission={surveySubmission}
      />
      <Typography color='grey'>
        Highlight your achievements and areas of improvement for this
        performance cycle.
      </Typography>
      {(!surveySubmission || surveySubmission.is_draft) &&
        !hasEvaluationPeriodEnded && (
          <Link
            onClick={() => {
              query.set('survey_id', performanceCycle?.self_evaluation_form_id);
              query.set('user_id', user.id);
              query.set('user_first_name', user.first_name);
              query.set('user_last_name', user.last_name);
              query.set('performance_cycle_id', performanceCycle?.id);
              query.set('client_id', clientId);
              navigation.replace(`${navigation.location.pathname}?${query}`);
            }}
            sx={{ width: 'fit-content' }}
          >
            {surveySubmission?.is_draft ? 'Edit' : 'Start'}
          </Link>
        )}
      {surveySubmission && !surveySubmission?.is_draft && (
        <>
          {submissionModalOpen && (
            <SurveySubmissionModal
              surveySubmission={surveySubmission}
              onClose={() => setSubmissionModalOpen(false)}
              ratingType='self'
              firstName={user.first_name}
            />
          )}
          <Link
            onClick={() => {
              setSubmissionModalOpen(true);
            }}
            sx={{ width: 'fit-content' }}
          >
            View
          </Link>
        </>
      )}
    </Stack>
  );
}

interface SelfRatingProps {
  user: User;
  performanceCycle: PerformanceCycleI;
  performanceCycleResults: PerformanceCycleResultObj;
  isPerformanceCycleDataLoading: boolean;
  clientId: string;
  hasEvaluationPeriodEnded: boolean;
}

function SelfRating(props: SelfRatingProps) {
  const {
    user,
    performanceCycle,
    performanceCycleResults,
    clientId,
    hasEvaluationPeriodEnded,
  } = props;
  const [openModal, setOpenModal] = React.useState(false);
  const [viewModal, setViewModal] = React.useState(false);

  return (
    <Stack spacing={2} width={{ md: '50%' }} padding='2em'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <LinearScale />
        <Typography>Overall rating</Typography>
      </Stack>
      {!performanceCycleResults?.direct_report?.rating ? (
        <>
          <RatingChip hasEvaluationPeriodEnded={hasEvaluationPeriodEnded} />
          <Typography color='grey'>
            Provide a rating to reflect how you feel about your performance
            during this cycle.
          </Typography>
          {!hasEvaluationPeriodEnded && (
            <>
              <SelfRatingModal
                profileUser={user}
                performanceCycle={performanceCycle}
                openModal={openModal}
                setOpenModal={setOpenModal}
                clientId={clientId}
              />
              <Link
                sx={{ width: 'fit-content' }}
                onClick={() => setOpenModal(true)}
              >
                Give rating
              </Link>
            </>
          )}
        </>
      ) : (
        <>
          <Chip
            color='success'
            label='Submitted'
            sx={{ width: 'fit-content' }}
          />
          <Typography color='grey'>
            Provide a rating to reflect how you feel about your performance
            during this cycle.
          </Typography>
          <ViewRatingModal
            viewModal={viewModal}
            setViewModal={setViewModal}
            profileUser={user}
            performanceCycle={performanceCycle}
            rating={performanceCycleResults?.direct_report.rating}
            ratingType='self'
          />
          <Link
            sx={{ width: 'fit-content' }}
            onClick={() => setViewModal(true)}
          >
            View
          </Link>
        </>
      )}
    </Stack>
  );
}

const EvaluationPeriod: FC<EvaluationPeriodProps> = props => {
  const { performanceCycle, clientId } = props;
  const { user } = useStore();
  const { useGetPerformanceCycleResultsForDirectReport } =
    useClientQueries(clientId);
  const {
    results: performanceCycleResults,
    isLoading: isPerformanceCycleDataLoading,
  } = useGetPerformanceCycleResultsForDirectReport(
    performanceCycle?.id,
    user.id,
  );
  const hasEvaluationPeriodEnded = dayjs().isAfter(
    dayjs(performanceCycle.self_evaluation_end),
  );
  const canSelfEvaluate =
    (Array.isArray(performanceCycle?.rating_scale_labels) &&
      performanceCycle.rating_scale_labels.length > 0) ||
    performanceCycle?.self_evaluation_form_id;

  return (
    <Stack spacing={2}>
      <Typography variant='h3'>Complete your evaluation</Typography>
      {hasEvaluationPeriodEnded && (
        <Alert severity='info' sx={{ marginBottom: '1em !important' }}>
          <AlertTitle>The evaluation period has ended</AlertTitle>
          <Typography variant='body2'>All submissions are final.</Typography>
        </Alert>
      )}
      <GreyHeaderBox
        header={
          <Stack
            direction='row'
            divider={<Divider orientation='vertical' flexItem />}
            spacing={1}
          >
            <Stack direction='row' gap={1} alignItems='center'>
              <Typography variant='body1sb'>Evaluation open</Typography>
              <Typography variant='body1'>
                {dayjs(performanceCycle?.self_evaluation_start).format(
                  'MMM D, YYYY',
                )}
              </Typography>
            </Stack>
            <Stack direction='row' gap={1} alignItems='center'>
              <Typography variant='body1sb'>Evaluation due</Typography>
              <Typography variant='body1'>
                {dayjs(performanceCycle?.self_evaluation_end).format(
                  'MMM D, YYYY',
                )}
              </Typography>
            </Stack>
          </Stack>
        }
      >
        {canSelfEvaluate ? (
          <Stack
            direction={{ md: 'row', sm: 'column' }}
            divider={<Divider orientation='vertical' flexItem />}
          >
            {performanceCycle?.self_evaluation_form_id && (
              <SelfEvaluation
                {...props}
                user={user}
                hasEvaluationPeriodEnded={hasEvaluationPeriodEnded}
              />
            )}
            {Array.isArray(performanceCycle?.rating_scale_labels) &&
              performanceCycle.rating_scale_labels.length > 0 && (
                <SelfRating
                  {...props}
                  user={user}
                  performanceCycleResults={
                    performanceCycleResults?.results || {}
                  }
                  isPerformanceCycleDataLoading={isPerformanceCycleDataLoading}
                  hasEvaluationPeriodEnded={hasEvaluationPeriodEnded}
                />
              )}
          </Stack>
        ) : (
          <div style={{ textAlign: 'center', padding: '2em' }}>
            <Typography variant='body1'>
              Your organization has not yet set up the performance evaluation
            </Typography>
          </div>
        )}
      </GreyHeaderBox>
    </Stack>
  );
};

export { EvaluationPeriod };
