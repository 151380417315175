import React, { useState, useEffect } from 'react';
import { Alert, AlertTitle, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { Logger, Severity } from '@src/lib/logger';

const logger = new Logger('jitsi');

interface JitsiSDK {
  JitsiMeetExternalAPI: any;
}
interface JitsiMeetExternalAPI {
  dispose(): void;
  addListener: any;
  removeListener: any;
}
const loadJitsiSDK: () => Promise<JitsiSDK> = (() => {
  let promise: Promise<JitsiSDK> | null;
  return () => {
    if (!promise) {
      promise = new Promise((resolve, reject) => {
        logger.debug('Load Jitsi SDK: started');
        const script = document.createElement('script');
        script.src = 'https://8x8.vc/external_api.js';
        script.async = true;
        script.onload = () => {
          logger.debug('Load Jitsi SDK: succeeded');
          resolve({
            JitsiMeetExternalAPI: (window as any).JitsiMeetExternalAPI,
          });
        };
        script.onerror = error => {
          logger.error('Load Jitsi SDK: failed', error);
          script.remove();
          promise = null;
          reject();
        };
        document.body.appendChild(script);
      });
    }
    return promise;
  };
})();

interface MeetingOptions {
  meetingId: string;
  meetingName?: string;
  userName?: string;
  userId?: string;
  jwt?: string;
  onJoin?(): void;
  onLeave?(): void;
}

const createJitsiMeeting = async (
  parentNode: HTMLElement,
  options: MeetingOptions,
): Promise<JitsiMeetExternalAPI> => {
  function jitsiLog(
    level: Severity,
    message: string,
    payload?: { [k: string]: any },
  ) {
    logger[level](message, {
      payload: JSON.stringify(payload),
      userId: options?.userId,
      sessionId: options?.meetingId,
      userAgent: window.navigator.userAgent,
    });
  }
  jitsiLog('debug', 'New meeting created');

  // Just track joins at mount/render since we don't care
  // as much about the actual session loading as we do the
  // attempt to join for confirming the user was present.
  options.onJoin?.();

  const jitsi = await loadJitsiSDK();
  const formattedMeetingId = options.meetingId
    .replace(/[0-9]/g, '')
    .toUpperCase();

  let roomName = `vpaas-magic-cookie-b424ff5af882445da6066e23911c96c6/`;
  roomName += options.meetingName || 'Meeting';
  roomName += '-';
  roomName += formattedMeetingId.substring(formattedMeetingId.length - 5);

  const meeting = new jitsi.JitsiMeetExternalAPI('8x8.vc', {
    jwt: options.jwt,
    parentNode,
    roomName,
    width: '100%',
    height: '100%',
    configOverwrite: {
      toolbarConfig: {
        alwaysVisible: true,
      },
    },
    interfaceConfigOverwrite: {
      SHOW_CHROME_EXTENSION_BANNER: false,
      TOOLBAR_BUTTONS: [
        'microphone',
        'camera',
        'closedcaptions',
        'desktop',
        'fullscreen',
        'fodeviceselection',
        'hangup',
        'profile',
        'chat',
        // 'recording',
        // 'livestreaming',
        // 'etherpad',
        'sharedvideo',
        'settings',
        'raisehand',
        'videoquality',
        'filmstrip',
        // 'invite',
        'feedback',
        'stats',
        'shortcuts',
        'tileview',
        'videobackgroundblur',
        // 'download',
        // 'help',
        // 'mute-everyone',
        // 'security',
      ],
      SETTINGS_SECTIONS: [
        'devices',
        'language',
        // 'moderator',
        'profile',
        // 'calendar',
      ],
    },
    userInfo: {
      displayName: options.userName,
    },
  });

  let hasReceivedLeftEvent = false;
  meeting.addListener('videoConferenceLeft', () => {
    if (!hasReceivedLeftEvent) {
      hasReceivedLeftEvent = true;
      jitsiLog('debug', 'Local user has left the meeting');
      options.onLeave();
    }
  });

  const eventsToLog: { event: string; level: Severity }[] = [
    { event: 'videoConferenceJoined', level: 'info' },
    { event: 'cameraError', level: 'error' },
    { event: 'audioAvailabilityChanged', level: 'info' },
    { event: 'browserSupport', level: 'info' },
    { event: 'errorOccurred', level: 'error' },
    { event: 'micError', level: 'error' },
    { event: 'deviceListChanged', level: 'info' },
    { event: 'participantJoined', level: 'info' },
    { event: 'participantKickedOut', level: 'info' },
    { event: 'participantLeft', level: 'info' },
    { event: 'videoAvailabilityChanged', level: 'info' },
    { event: 'suspendDetected', level: 'info' },
    { event: 'peerConnectionFailure', level: 'error' },
  ];

  for (const { event, level } of eventsToLog) {
    meeting.on(event, payload => {
      jitsiLog(level, `${event} event triggered`, payload);
    });
  }

  return meeting;
};

/**
 * Assumes all options are stable except for meetingId. If meetingId changes
 * then a new meeting will be created.
 */
const useJitsiMeeting = (
  ref: React.MutableRefObject<HTMLElement>,
  options: MeetingOptions,
): { error: Error | null } => {
  const [error, setError] = useState(null);

  useEffect(() => {
    let canceled = false;
    let meeting: JitsiMeetExternalAPI | null = null;
    setError(null);
    createJitsiMeeting(ref.current, options).then(
      result => {
        meeting = result;
        if (canceled && meeting) {
          meeting.dispose();
          meeting = null;
        }
      },
      err => {
        if (!canceled) {
          setError(err);
        }
      },
    );
    return () => {
      canceled = true;
      meeting?.dispose();
    };
    // meeting options are expected to be stable once a meeting starts.
    // eslint-disable-next-line
  }, [options.meetingId]);

  return { error };
};

const Jitsi: React.FC<MeetingOptions> = options => {
  const navigate = useNavigate();
  const ref = React.useRef<HTMLDivElement>(null!);
  const meeting = useJitsiMeeting(ref, {
    ...options,
    onLeave() {
      if (options.onLeave) {
        options.onLeave();
      } else {
        navigate('/');
      }
    },
  });

  if (meeting.error) {
    return (
      <Alert severity='error'>
        <AlertTitle>Cannot start meeting</AlertTitle>
        <Typography variant='body2'>
          Could not load session, please contact support
        </Typography>
      </Alert>
    );
  }
  return (
    <div className='page-session'>
      <div
        style={{ width: '100vw', height: '100vh' }}
        id='jitsi-meeting'
        ref={ref}
      />
    </div>
  );
};

export default Jitsi;
