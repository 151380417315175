import { HTTPClient } from '@src/lib/client';
import { SessionType } from '@shared/schemas';

export interface SessionGetParams {
  sessionId?: string;
  programId?: string;
  sowId?: string;
  memberId?: string;
  page?: number;
  role?: string;
}

export interface SessionGetResponse {
  id: string;
  type: string;
  program_info: { type: String };
  members: { id: string; role: string; signed_jwt: string }[];
}

export class SessionsAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async getByProgram(params: SessionGetParams) {
    return this.http.request({
      url: `/sessions/program/${params.programId}`,
      method: 'get',
    });
  }

  async getByUser(params: SessionGetParams) {
    return this.http.request({
      url: `/sessions/user/${params.memberId}?role=${params.role}`,
      method: 'get',
    });
  }

  async get(params: SessionGetParams) {
    return this.http.request({
      url: `/sessions/list?${Object.keys(params)
        .map(key =>
          params[key] ? key + '=' + encodeURIComponent(params[key]) : undefined,
        )
        .filter(n => n)
        .join('&')}`,
      method: 'get',
    });
  }

  async saveSession(data: SessionType, coachee?: string) {
    return this.http.request({
      url: `/sessions/${data.id || 'create'}${
        coachee ? `?coachee=${coachee}` : ''
      }`,
      method: data.id ? 'patch' : 'post',
      data,
    });
  }

  async stats() {
    return this.http.request({
      url: `/sessions/stats`,
      method: 'get',
    });
  }

  async alerts() {
    return this.http.request({
      url: `/sessions/alerts`,
      method: 'get',
    });
  }

  async checkSessionsAvailableForProgram(programId: string) {
    return this.http.request({
      url: `/sessions/program-sessions-available/${programId}`,
      method: 'get',
    });
  }

  async saveFollowUp(sessionId: string, data: any) {
    return this.http.request({
      url: `/sessions/follow-up/${sessionId}`,
      method: 'patch',
      data,
    });
  }

  async recordJoin(sessionId: string, user_id: string) {
    return this.http.request({
      url: `/sessions/${sessionId}/user-joined`,
      method: 'patch',
      data: {
        user_id,
      },
    });
  }

  async recordLeave(sessionId: string, user_id: string) {
    return this.http.request({
      url: `/sessions/${sessionId}/user-left`,
      method: 'patch',
      data: {
        user_id,
      },
    });
  }
}
