import { api } from '@src/lib/client';
import { useStore } from '@src/models/Store';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import * as useVerbiage from '@shared/lib/src/use-verbiage';

const useUser = () => {
  const { user } = useStore();

  const useGetUpNext = (role: 'coach' | 'coachee') => {
    const { data, ...rest } = useQuery({
      queryKey: ['user', user.id, 'upNext', role],
      queryFn: async () => api.users.getUpNext(user.id, role),
      select: ({ data }) => {
        const upNext = [];
        const sessions = data.sessions.map(s => {
          const sessionType =
            s.type === 'coaching'
              ? 'Session'
              : s.type === 'interview'
              ? 'Interview'
              : s.type;
          return {
            key: s.sessionId,
            type: 'session',
            title: `Upcoming ${sessionType} with ${s.otherMemberName}`,
            sub_title: `${sessionType} starts ${dayjs(s.startTime).format(
              'MMMM D [at] h:mma',
            )}`,
            primary_action: `Join ${sessionType}`,
            secondary_actions: [
              {
                label: 'Reschedule',
                key: 'reschedule',
              },
              {
                label: 'Cancel',
                key: 'cancel',
              },
            ],
            meta: {
              rescheduleLink: s.rescheduleLink,
              cancelLink: s.cancelLink,
              startTime: s.startTime,
            },
          };
        });
        upNext.push(...sessions);

        if (role === 'coachee') {
          if (data.noProgram) {
            upNext.push({
              key: 'no_program',
              type: 'no_program',
              title: 'Welcome to SkillCycle!',
              sub_title:
                'Contact our support team to find the right coaching program.',
              primary_action: 'Contact Support',
              secondary_actions: [],
            });
          }
          if (data.noPersonalGoals) {
            upNext.push({
              key: 'no_goals',
              type: 'no_goals',
              title: 'Get started with your goals',
              sub_title: 'Set goals to guide your development and growth.',
              primary_action: 'Create Goals',
              secondary_actions: [],
              meta: {
                url: '/performance-and-growth',
              },
            });
          }

          const programsNoCoach = data.programsNoCoach.map(pnc => {
            const v = useVerbiage.init(
              pnc.programType === 'mentoring' ? ['isMentor'] : [],
            );

            return {
              key: pnc.programId,
              type: 'select_coach',
              title: "Welcome! Let's get you started.",
              sub_title: `Find the right ${v('coach')} for ${pnc.programName}`,
              primary_action: `Find A ${v('coach', true)}`,
              secondary_actions: [],
              meta: {
                coachSearch: pnc.coachSearch,
              },
            };
          });
          upNext.push(...programsNoCoach);

          const selfAssessments = data.selfAssessments.map(sa => {
            return {
              key: sa.self_assessment_id,
              type: 'self_assessment',
              title: `You have an assessment to complete!`,
              sub_title: `Take a moment to complete your self-assessment for ${sa.program_name}`,
              primary_action: 'Complete assessment',
              secondary_actions: [],
              meta: {
                url: `/my-programs?${new URLSearchParams({
                  survey_id: sa.self_assessment_id,
                  program_id: sa.program_id,
                  user_id: user.id,
                }).toString()}`,
              },
            };
          });
          upNext.push(...selfAssessments);

          const programsLowStakeholders = data.programsLowStakeholders.map(
            pls => {
              return {
                key: pls.programId,
                type: 'low_stakeholders',
                title: `Collect feedback for ${pls.programName}!`,
                sub_title:
                  'Please submit the names and emails of the people who will be providing 360 feedback for your coach',
                primary_action: 'Add 360 participants',
                secondary_actions: [],
                meta: {
                  url: `/my-programs/${pls.programId}`,
                },
              };
            },
          );
          upNext.push(...programsLowStakeholders);

          const programsWithSessions = data.programsWithSessions.map(pws => {
            return {
              key: pws.program?.id,
              type: 'open_sessions',
              title: "Let's book your next session!",
              sub_title: `Book a session for ${pws.program?.name}`,
              primary_action: 'Book Session',
              secondary_actions: [],
              meta: {
                program: pws.program,
                coach: pws.coach,
              },
            };
          });
          upNext.push(...programsWithSessions);

          const notifications = data.notifications.map(n => {
            let title, sub_title;
            let primaryAction = 'Complete Survey';
            switch (n.type) {
              case 'engagement survey':
                title = 'You have a survey to complete!';
                sub_title = `Please complete your ${n.subtype} ${n.type}${
                  n.programName ? ` for ${n.programName}` : ''
                }`;
                break;
              case 'standalone-survey':
                title = n.meta.notification_message;
                sub_title = `Please complete the survey ${n.subtype}`;
                break;
              case 'feedback-survey':
                title = n.meta.notification_message;
                sub_title = n.meta.notification_description;
                primaryAction = 'Give Feedback';
                break;
            }
            return {
              key: n.notificationId,
              type: 'survey',
              title,
              sub_title,
              primary_action: primaryAction,
              secondary_actions: n.dismissable
                ? [{ label: 'Dismiss Notification', key: 'dismiss' }]
                : [],
              meta: {
                surveyLink: n.url,
                ...n.meta,
              },
            };
          });
          upNext.push(...notifications); // This may need to be moved out of the top-level if/once we have coach surveys

          const performanceCycleEvaluation =
            data.performanceCycleEvaluation.map(pc => {
              return {
                key: pc.performance_cycle_id,
                type: 'performance_cycle_evaluation',
                title: `It's time for your self-evaluation!`,
                sub_title: `Reflect and assess your performance`,
                primary_action: 'Complete self-evaluation',
                secondary_actions: [],
                meta: {
                  performance_cycle_id: pc.performance_cycle_id,
                },
              };
            });
          upNext.push(...performanceCycleEvaluation);

          // If upNext is still empty...
          if (!upNext.length) {
            upNext.push({
              key: 'done',
              type: 'done',
              title:
                'Interested in how SkillCycle can continue to support your growth and development?',
              sub_title: 'Contact our support team for more information',
              primary_action: 'Contact Support',
              secondary_actions: [],
            });
          }
        }

        return upNext;
      },
    });

    return { upNext: data, ...rest };
  };

  const useGetUserAidaTasks = (clientId: string) => {
    const { data, ...rest } = useQuery({
      queryKey: ['user', user.id, 'summary-inputs', clientId],
      queryFn: async () =>
        api.users.getUserAidaSummaryInputs(user.id, clientId),
      select: ({ data }) => data,
    });

    return {
      tasks: data?.input_data,
      mostRecentUpdate: data?.last_updated,
      ...rest,
    };
  };

  const useGetUserAidaConversations = (clientId: string) => {
    const { data, ...rest } = useQuery({
      queryKey: ['user', user.id, 'conversations', clientId],
      queryFn: async () =>
        api.users.getUserAidaConversations(user.id, { clientId }),
      select: ({ data }) => data,
    });

    return { conversations: data, ...rest };
  };

  return {
    user,
    useGetUpNext,
    useGetUserAidaTasks,
    useGetUserAidaConversations,
  };
};

export { useUser };
