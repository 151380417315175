import React, { FC, useEffect, useState } from 'react';
import { Alert, AlertTitle, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { CalendlyEventListener, InlineWidget } from 'react-calendly';
import Store from '@src/models/Store';
import Session, { CalendlyEvent } from '@src/models/Session';
import { api } from '@src/lib/client';
import { nanoid } from 'nanoid';
import * as useVerbiage from '@shared/lib/src/use-verbiage';
import { useToast } from '@src/components/sc-design-system';

interface CalendlySchedulerPropsIF {
  session: Session;
  calendlyUrl: string;
  store: Store;
  onBookingComplete: Function;
  programType: string;
  programName: string;
}

const CalendlyScheduler: FC<CalendlySchedulerPropsIF> = observer(
  ({
    session,
    calendlyUrl,
    store,
    onBookingComplete,
    programType,
    programName,
  }) => {
    const toast = useToast();
    const [error, setError] = useState(false);
    const [sessionShortId, setSessionShortId] = useState<string>();
    // Get the event data from Calendly then save to the session
    const handleCalendlyEventScheduled = async (data: any) => {
      // exit and show error if no event data
      if (!data?.payload?.event) {
        setError(true);
        return false;
      }

      // get the last segment of the uri string, which contains the uuid
      const calendlyEventUUID = data.payload.event.uri.substring(
        data.payload.event.uri.lastIndexOf('/') + 1,
      );

      // get the event details from the calendly api to update the session
      const eventDetails = await api.integrations.getCalendlyEventDetails(
        calendlyEventUUID,
        data.payload.invitee?.uri,
      );

      const newSession = new Session();

      newSession.mergeData(session);

      newSession.session_activity = [
        {
          timestamp: Date.now(),
          type: 'scheduled',
          user_id: store.user.id,
        },
      ];

      newSession.short_id = sessionShortId;
      newSession.current_status = 'scheduled';

      newSession.calendly_event = new CalendlyEvent({
        uuid: calendlyEventUUID,
        start_time: eventDetails.data.start_time,
        end_time: eventDetails.data.end_time,
        status: eventDetails.data.status,
        cancel_url: eventDetails.data.cancel_url,
        reschedule_url: eventDetails.data.reschedule_url,
      });

      try {
        await newSession.save(true); // If this fails (e.g. client-side network error), we'll end up with a Calendly event but no session in the database. Therefore, utilize the new Calendly event webhook to make sure the session has been written to the DB.
        onBookingComplete();
      } catch (e) {
        console.error(e);
        toast.error(`Unable to create ${session.type}, please contact support`);
      }
    };

    const v = useVerbiage.init(programType === 'mentoring' ? ['isMentor'] : []);

    useEffect(() => {
      setSessionShortId(nanoid());
    }, [session]);

    return (
      <>
        {error && (
          <Alert severity='error' onClose={() => setError(false)}>
            <AlertTitle>Something went wrong</AlertTitle>
            <Typography variant='body2'>
              We were unable to get Calendly, please refresh or try again later
            </Typography>
          </Alert>
        )}
        {/** @ts-expect-error */}
        <CalendlyEventListener
          onEventScheduled={payload => {
            handleCalendlyEventScheduled(payload.data);
          }}
        >
          <InlineWidget
            url={`${calendlyUrl}?embed_domain`}
            prefill={{
              name: `${store.user.first_name} ${store.user.last_name}`,
              email: store.user.email,
              location: `${window.location.protocol}//${window.location.hostname}/sessions/${sessionShortId}`,
              customAnswers: {
                a1: '',
                a2:
                  session.type === 'interview'
                    ? 'Interview'
                    : v('coaching', true),
                a3: programName,
              },
            }}
          />
        </CalendlyEventListener>
      </>
    );
  },
);

export default CalendlyScheduler;
