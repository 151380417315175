import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import startCase from 'lodash/startCase';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import * as useVerbiage from '@shared/lib/src/use-verbiage';
import { Avatar, Card } from '@src/components/sc-design-system';
import User from '@src/models/User';
import { StakeholderManagerModal } from '@src/components/common/StakeholderManagerModal';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { useStore } from '@src/models/Store';
import ActiveInactiveChip from '@src/components/common/ActiveInactiveChip';
import {
  Completed,
  Missed,
  Placeholder,
  Scheduled,
  Started,
} from '@src/components/common/SessionStatusButton';

export interface ISession {
  id: string;
  type: string;
  program_id: string;
  current_status: string;
  session_activity: any[];
  start_time: number;
  end_time: number;
  cancel_url: string;
  reschedule_url: string;
  notes: any[];
  members: any[];
  ratings: any[];
  noted: any[];
  feedback: any[];
  missed_reason: any[];
}

export const SessionItemView: FC<{
  session: ISession;
}> = ({ session }) => {
  const SessionButton = {
    completed: Completed,
    missed: Missed,
    scheduled: Scheduled,
    started: Started,
  }[session.current_status];
  return SessionButton ? <SessionButton /> : null;
};

const Program360sRow = ({ program, user }) => {
  const store = useStore();
  const [showStakeholderManagerModal, setShowStakeholderManagerModal] =
    useState(false);
  if (
    !program.self_assessment_enabled &&
    !program.stakeholder_assessment_enabled
  ) {
    return null;
  }
  return (
    <>
      <StakeholderManagerModal
        open={showStakeholderManagerModal}
        programId={program.id}
        memberId={user.id}
        isActive={program.isActive}
        isEditable={store.user.is_admin}
        onCancel={() => setShowStakeholderManagerModal(false)}
      />
      <Grid container>
        <Grid item xs={1}>
          <Typography>360s</Typography>
        </Grid>
        {program?.self_assessment_enabled && (
          <Grid item xs={5}>
            <Stack
              direction='row'
              spacing={2}
              divider={<Divider orientation='vertical' flexItem />}
            >
              {program.self_360_assessment_id ? (
                <Stack direction='row' spacing={1}>
                  <Typography>Self-Assessment Not Submitted</Typography>
                  <ErrorOutlineIcon color='warning' fontSize='small' />
                </Stack>
              ) : (
                <Stack direction='row' spacing={1}>
                  <Typography>Self-Assessment Submitted</Typography>
                  <CheckCircleOutlined color='success' fontSize='small' />
                </Stack>
              )}
              {program?.stakeholder_assessment_enabled && (
                <Typography
                  variant='a1'
                  onClick={() => setShowStakeholderManagerModal(true)}
                >
                  {!program.stakeholders?.length
                    ? 'Feedback Not Requested'
                    : `Feedback Requested (${
                        program.stakeholders?.filter(
                          s => s.survey_submission_id,
                        ).length
                      }/${program.stakeholders?.length})`}
                </Typography>
              )}
            </Stack>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const ProgramActivityCard: FC<{ programs: any[]; user: User }> = ({
  programs,
  user,
}) => {
  const [showAllPrograms, setShowAllPrograms] = useState(false);

  return (
    <Card title='Program Activity' className='program-activity-card'>
      <Stack spacing={4} divider={<Divider />}>
        {programs?.length ? (
          programs
            ?.slice()
            .sort((a, b) => {
              if (
                dayjs().isBetween(a.start_date, a.end_date) ===
                dayjs().isBetween(b.start_date, b.end_date)
              ) {
                return b.end_date < a.end_date ? -1 : 1;
              } else {
                return dayjs().isBetween(a.start_date, a.end_date) ? -1 : 1;
              }
            })
            .map((p, i) => {
              if ((!showAllPrograms && i < 2) || showAllPrograms) {
                const v = useVerbiage.init(
                  p?.type === 'mentoring' ? ['isMentor'] : [],
                );
                return (
                  <div key={i}>
                    <Typography variant='h3' sx={{ marginBottom: '0.5em' }}>
                      {p.name}
                    </Typography>
                    <Stack direction='row' spacing={1}>
                      <Typography variant='subtitle2'>
                        {dayjs(p.start_date).format('ll')}
                        {' - '} {dayjs(p.end_date).format('ll')}
                      </Typography>
                      <ActiveInactiveChip
                        active={
                          p.is_active &&
                          p.start_date < Date.now() &&
                          p.end_date > Date.now()
                        }
                      />
                    </Stack>
                    <Grid container marginTop='1em' marginBottom='1em'>
                      <Grid item xs={1}>
                        <Typography>{startCase(v('coach'))}</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        {p?.coach ? (
                          <Stack direction='row' spacing={1}>
                            <Avatar
                              srcId={p?.coach?.id}
                              alt={`${p?.coach?.first_name} ${p?.coach?.last_name}`}
                              size='md'
                            />
                            <Typography>
                              {p?.coach?.first_name} {p?.coach?.last_name}
                            </Typography>
                          </Stack>
                        ) : (
                          <Typography>Not Selected</Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Program360sRow program={p} user={user} />
                    <Stack
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      gap={1.5}
                      width='100%'
                      paddingTop='1em'
                      overflow='auto'
                    >
                      {p.sessions.length ? (
                        p?.sessions.map((session, i) => {
                          if (!session) {
                            return <Placeholder key={i} text={`${i + 1}`} />;
                          }
                          return <SessionItemView key={i} session={session} />;
                        })
                      ) : (
                        <Typography variant='body1' marginTop='1.5em'>
                          No sessions allocated for this program
                        </Typography>
                      )}
                    </Stack>
                  </div>
                );
              }
            })
        ) : (
          <Stack direction='row' justifyContent='center'>
            This member does not have any session activity for any given
            program.
          </Stack>
        )}
        {programs?.length && 2 < programs?.length ? (
          <Stack direction='row' justifyContent='center' alignContent='center'>
            <Typography
              variant='a2'
              onClick={() => {
                setShowAllPrograms(!showAllPrograms);
              }}
            >
              {showAllPrograms ? 'View Less' : 'View More'}
            </Typography>
          </Stack>
        ) : null}
      </Stack>
    </Card>
  );
};

export default observer(ProgramActivityCard);
