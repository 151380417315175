import React, { FC, useEffect, useState } from 'react';
import { Dialog, DialogContent, Divider, Stack } from '@mui/material';
import { useParams } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import {
  Button,
  DialogTitle,
  useToast,
} from '@src/components/sc-design-system';
import Program from '@src/models/Program';
import {
  alignProgramSurveysWithTypes,
  surveyTypes,
} from '@src/lib/surveyTypes';
import Survey from '@src/models/Survey';
import CurrentInvitations from './CurrentInvitations';
import IndividualInvite from './IndividualInvite';
import InviteFromCSV from './InviteFromCSV';
import StartProgramModal from './StartProgramModal';
import { api } from '@src/lib/client';
import { isDuplicateEmail } from './utils';
import { useProgramQueries } from '@src/hooks/useProgramQueries';

const InviteModalTrigger: FC<{
  program: Program;
  surveys?: Survey[];
}> = ({ program, surveys }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [startProgramModalOpen, setStartProgramModalOpen] = useState(false);
  const [shouldConfirmStartProgram, setShouldConfirmStartProgram] =
    useState(false);
  const [programSurveys, setProgramSurveys] = useState([]);
  useEffect(() => {
    // Determine if we should show the modal to confirm that the user wants to start the program
    // so they're aware of the surveys configured when users join
    try {
      setShouldConfirmStartProgram(
        program?.invitees?.length === 0 && program?.members?.length === 0,
      );
      setProgramSurveys(
        alignProgramSurveysWithTypes(surveys, program).map(s => ({
          key: s.id,
          title: s.title,
          type: surveyTypes[s.type].label,
          enabled: s.enabled,
        })),
      );
    } catch (e) {
      /* ignore, shouldn't block */
    }
  }, [program, surveys, startProgramModalOpen]);

  const handleButton = () => {
    if (shouldConfirmStartProgram) {
      setStartProgramModalOpen(true);
      return;
    }
    setModalOpen(true);
  };

  return (
    <>
      <StartProgramModal
        startProgramModalOpen={startProgramModalOpen}
        setStartProgramModalOpen={setStartProgramModalOpen}
        programSurveys={programSurveys}
        buttonOnClick={() => {
          setStartProgramModalOpen(false);
          setModalOpen(true);
        }}
      />
      <InviteModal
        handleButton={handleButton}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        clientId={program?.client_id}
      />
    </>
  );
};

interface InviteModalProps {
  handleButton: () => void;
  modalOpen: boolean;
  setModalOpen: (b: boolean) => void;
  clientId: string;
}

let InviteModal = (props: InviteModalProps) => {
  const { handleButton, modalOpen, setModalOpen, clientId } = props;
  const toast = useToast();
  const [csvFileContent, setCsvFileContent] = useState('');
  const { id: programId } = useParams();
  const { useGetProgramFields, useGetProgramMembers } = useProgramQueries(
    programId,
    clientId,
  );
  const {
    data: invitees,
    refetch,
    isLoading,
  } = useGetProgramFields(['name', 'invitees']);
  const { members: allProgramMembers } = useGetProgramMembers();

  const { mutate: revokeInvite } = useMutation({
    mutationFn: async (email: string) =>
      api.programs.revokeInvite({ programId, email }),
    onSuccess: () => refetch(),
    onError: () => {
      toast.error('Unable to revoke invite');
    },
  });

  const { mutate: undoRevokeInvite } = useMutation({
    mutationFn: async (email: string) =>
      api.programs.undoRevokeInvite({ programId, email }),
    onSuccess: () => refetch(),
    onError: () => {
      toast.error('Unable to undo revoked invite');
    },
  });

  const inviteProgramMembers = async ({ invitees }) =>
    api.programs.inviteProgramMembers({
      programId,
      invitees,
    });

  const handleClose = () => {
    setModalOpen(false);
    setCsvFileContent('');
  };

  return (
    <>
      <Button
        variant='contained'
        text='Program Invites'
        onClick={handleButton}
        size='small'
      />
      <Dialog maxWidth='lg' open={modalOpen} onClose={handleClose}>
        <DialogTitle buttonOnClick={handleClose} title='Invite Members' />
        <DialogContent>
          <Stack divider={<Divider />}>
            <InviteFromCSV
              csvFileContent={csvFileContent}
              setCsvFileContent={setCsvFileContent}
              isDuplicateEmail={isDuplicateEmail(allProgramMembers, invitees)}
              refetch={refetch}
              inviteMembers={inviteProgramMembers}
            />
            {!csvFileContent && (
              <IndividualInvite
                refetch={refetch}
                inviteMembers={inviteProgramMembers}
                isDuplicateEmail={isDuplicateEmail(allProgramMembers, invitees)}
              />
            )}
            {!csvFileContent && (
              <CurrentInvitations
                invitees={invitees}
                isLoading={isLoading}
                revokeInvite={revokeInvite}
                undoRevokeInvite={undoRevokeInvite}
              />
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InviteModalTrigger;
