import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Row, Space } from 'antd';
import dayjs from 'dayjs';
import _startCase from 'lodash/startCase';

import './GoalDetailsTable.less';
import GoalWeightIndicator from '../GoalCard/GoalWeightIndicator';
import { Typography } from '@mui/material';

interface GoalDetailsTableProps {
  goal: Goal;
  goalCycle: GoalCycleWithGoals;
  onClickGoalWeight?: (c: GoalCycleWithGoals) => void;
  canManageGoalWeights?: boolean;
}

const GoalDetailsTable: FC<GoalDetailsTableProps> = observer(
  ({ goal, goalCycle, onClickGoalWeight, canManageGoalWeights }) => {
    return (
      <div className='goal-details-table'>
        <Row>
          <Space style={{ width: '50%' }} size={16}>
            <Typography variant='h5'>Goal Type</Typography>
            <span>{_startCase(goal.type)}</span>
          </Space>
          <Space style={{ width: '50%' }} size={16}>
            <Typography variant='h5'>Target Date</Typography>
            <span>{dayjs(goal.target_date).format('MM/DD/YYYY')}</span>
          </Space>
        </Row>
        {goalCycle?.goal_weighting_enabled && (
          <Row>
            <Space style={{ width: '50%' }} size={16}>
              <Typography variant='h5'>Goal Weight</Typography>
              {canManageGoalWeights ? (
                <GoalWeightIndicator
                  weight={goal?.weight}
                  onClick={() => onClickGoalWeight(goalCycle)}
                  isEditable={dayjs().isSameOrBefore(
                    goalCycle.goal_edit_deadline,
                  )}
                />
              ) : (
                <span>{goal?.weight}% Weight</span>
              )}
            </Space>
          </Row>
        )}
        <Row>
          <Space align='baseline' size={28}>
            <Typography variant='h5'>Description</Typography>
            <div
              className='description'
              dangerouslySetInnerHTML={{ __html: goal.description }}
            />
          </Space>
        </Row>
        <Row>
          <Space size={16}>
            <Typography variant='h5'>Goal Location</Typography>
            <span>{goalCycle?.name ?? ''}</span>
          </Space>
        </Row>
        <Row>
          <Space size={16}>
            <Typography variant='h5'>Goal Alignment</Typography>
            <span>
              {goalCycle?.goals?.find(({ id }) => id === goal.aligned_goal_id)
                ?.title || ''}
            </span>
          </Space>
        </Row>
        <Row>
          <Space size={16}>
            <Typography variant='h5'>Sharing</Typography>
            <span>
              Shared with {goal.program_ids?.length || 0} program
              {goal.program_ids?.length === 1 ? '' : 's'}
            </span>
          </Space>
        </Row>
      </div>
    );
  },
);

export { GoalDetailsTable };
