import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import {
  Button,
  TextField,
  useToast,
  DialogTitle,
} from '@src/components/sc-design-system';
import SurveyType from '@src/models/Survey';
import { useFullScreen } from '@src/hooks/useFullScreen';
import { api } from '@src/lib/client';

interface SettingsModalProps {
  settingsModalOpen;
  setSettingsModalOpen;
  currentSurvey: SurveyType;
}

const SettingsModal = ({
  settingsModalOpen,
  setSettingsModalOpen,
  currentSurvey,
}: SettingsModalProps) => {
  const { fullScreen } = useFullScreen();
  const toast = useToast();

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty, touchedFields },
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      title: currentSurvey?.title,
      description: currentSurvey?.description,
    },
  });

  const handleClose = () => {
    reset();
    setSettingsModalOpen(false);
  };

  const { mutate: updateSurvey, isPending } = useMutation({
    mutationFn: async data => {
      await api.surveys.saveSurvey(data as unknown as SurveyType);
    },
    onSuccess: (_, updates) => {
      const { title, description } = updates as unknown as Partial<SurveyType>;
      currentSurvey.title = title;
      currentSurvey.description = description;
      toast.success('Survey title successfully updated.');
      handleClose();
    },
    onError: () => {
      toast.error('Unable to update survey, please try again later.');
    },
  });

  const onSubmit = async data => {
    updateSurvey({ id: currentSurvey.id, ...data });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={settingsModalOpen}
      maxWidth='sm'
      fullScreen={fullScreen}
    >
      <DialogTitle
        title='Edit Survey'
        buttonOnClick={() => setSettingsModalOpen(false)}
      />
      <DialogContent sx={{ padding: '2em', paddingTop: '2em !important' }}>
        <form onSubmit={handleSubmit(onSubmit)} id='main'>
          <Stack gap='2em'>
            <Controller
              name='title'
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <TextField
                    {...fieldProps}
                    label='Survey name'
                    innerRef={ref}
                    required
                    fullWidth
                    error={touchedFields.title && !getValues('title')}
                    errorMessage={
                      touchedFields.title &&
                      !getValues('title') &&
                      'Survey name is required'
                    }
                    id={field.name}
                  />
                );
              }}
            />
            <Controller
              name='description'
              control={control}
              render={({ field }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <TextField
                    {...fieldProps}
                    innerRef={ref}
                    id={field.name}
                    label='What do you intend to use the survey for?'
                    multiline
                    rows={6}
                    fullWidth
                    helperText='Optionally enter a short description that is viewable only by you and other client admins within your organization.'
                    helperTextPosition='top'
                  />
                );
              }}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: '1em' }}>
        <Button
          onClick={handleClose}
          text='Cancel'
          variant='outlined'
          disabled={isPending}
        />
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || !isDirty || isPending}
          loading={isPending}
          text='Save'
          type='submit'
          form='main'
        />
      </DialogActions>
    </Dialog>
  );
};

export { SettingsModal };
