import React from 'react';
import {
  Box,
  CircularProgress,
  CircularProgressProps as CircularProgressMUIProps,
} from '@mui/material';

export interface SpinProps extends CircularProgressMUIProps {
  sectionLoader?: boolean;
}

const Spin: React.FunctionComponent<SpinProps> = ({
  sectionLoader,
  size = 18,
}) => {
  let sx = {};
  if (sectionLoader) {
    sx = {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: '1em',
    };
  }
  return (
    <Box sx={sx}>
      <CircularProgress size={size} />
    </Box>
  );
};

export { Spin };
