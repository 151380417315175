import React from 'react';
import { Row } from 'antd';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { api } from '@src/lib/client';
import {
  Button,
  Card,
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
  SCLogo,
} from '@src/components/sc-design-system';
import ReportCompetencies from '@src/components/views/Report/ReportCompetencies';
import ReportHeader from '@src/components/views/Report/ReportHeader';
import ReportSelfAssessment from '@src/components/views/Report/ReportSelfAssessment';
import ReportStakeholderAssessment from '@src/components/views/Report/ReportStakeholderAssessment';
import { generateReportDownload } from '@src/utils/reports';
import { Tooltip } from '@mui/material';

import './Report.less';

function generateReportFilename(data: ReportData) {
  const dateTimestamp = dayjs().format('YYYY-MM-DD HH mm ss');
  const prefixData = [
    data.first_name && data.last_name && `${data.first_name} ${data.last_name}`,
    data.client_name,
    data.self_survey_name,
    data.stakeholder_survey_name,
  ].filter(d => d);

  if (prefixData.length === 0) {
    return `SkillCycle 360 Report ${dateTimestamp}.pdf`;
  }
  const prefix = prefixData.join(' - ');
  // strip anything a hard drive may not like and truncate if needed
  const fileName = prefix.replace(/[^a-z0-9- ]/gi, '').slice(0, 220);

  return `${fileName} ${dateTimestamp}.pdf`;
}

function Report() {
  const { user_id, program_id } = useParams();
  // component state for loaders - can be set to true, false or 'error'
  const [isLoadingGet360ReportData, setIsLoadingGet360ReportData] =
    React.useState<boolean | 'error'>(true);
  const [
    isLoadingAggregateCompetencyResults,
    setIsLoadingAggregateCompetencyResults,
  ] = React.useState<boolean | 'error'>(true);
  const [
    isLoadingStakeholderAssessmentCharts,
    setIsLoadingStakeholderAssessmentCharts,
  ] = React.useState<boolean | 'error'>(true);
  const [isDownloadingPDF, setIsDownloadingPDF] = React.useState(false);

  const [reportData, setReportData] = React.useState<ReportData | undefined>();
  const [numberOfResponses, setNumberOfResponses] = React.useState(0);
  const [latestSubmissionDate, setLatestSubmissionDate] = React.useState();
  const [latestSelfSubmission, setLatestSelfSubmission] = React.useState();
  const [stakeholderQuestionIds, setStakeholderQuestionIds] = React.useState<
    string[]
  >([]);
  const [stakeholderFeedback, setStakeholderFeedback] =
    React.useState<StakeholderFeedback>({});

  function traverseResponses(submissions = [], competencyFeedback, answerKey) {
    submissions.forEach(function (submission) {
      const questions = Array.isArray(submission?.results)
        ? submission?.results
        : [];
      questions.forEach(function ({ question, answer }) {
        if (
          question.meta_data &&
          question.type === 'textarea' &&
          !question.isNumberInput &&
          answer
        ) {
          competencyFeedback[question.meta_data.competency_id][answerKey].push(
            answer,
          );
        }
      });
    });
  }

  const parseStakeholderSubmissions = React.useCallback(function (
    res,
    latestSelfSubmission,
    setStakeholderQuestionIds,
    setStakeholderFeedback,
  ) {
    const questionTypes = ['single-select', 'multi-select', 'range'];
    const surveyQuestions = Array.isArray(res?.stakeholder_questions)
      ? res?.stakeholder_questions
      : [];
    const questionIds = [];
    // use the following variables to store the correct order of the competencies and their titles
    const competencySetOrder = {};
    const competencyFeedback = {};

    surveyQuestions.forEach(question => {
      if (
        (!question.meta_data && questionTypes.includes(question.type)) ||
        (question.type === 'text' && question.isNumberInput)
      ) {
        questionIds.push(question.id);
      }

      if (
        question.type === 'competency' ||
        question?.group?.type === 'competency'
      ) {
        const competencyTitle = question.title || question?.group?.title;
        const questions = Array.isArray(question?.group?.questions)
          ? question?.group?.questions
          : [];
        questions.forEach(function (question) {
          // only proceed if meta_data present
          if (
            question.meta_data?.competency_set_id &&
            question.meta_data?.competency_id
          ) {
            const competency_set_id = question.meta_data.competency_set_id;
            const competency_id = question.meta_data.competency_id;

            if (!competencySetOrder[competency_set_id]) {
              competencySetOrder[competency_set_id] = [];
            }
            if (!competencySetOrder[competency_set_id].includes(competency_id))
              competencySetOrder[competency_set_id].push(competency_id);
            if (!competencyFeedback[competency_id])
              competencyFeedback[competency_id] = {
                title: competencyTitle,
                stakeholderAnswers: [],
                selfAnswers: [],
              };
          }
        });
      }
    });
    setStakeholderQuestionIds(questionIds);

    traverseResponses(
      res.stakeholder_submissions,
      competencyFeedback,
      'stakeholderAnswers',
    );
    traverseResponses(
      [latestSelfSubmission],
      competencyFeedback,
      'selfAnswers',
    );

    const feedback = Object.keys(competencySetOrder).reduce((acc, cs_id) => {
      acc[cs_id] = competencySetOrder[cs_id].map(c_id => {
        return competencyFeedback[c_id];
      });
      return acc;
    }, {});
    setStakeholderFeedback(feedback);
  },
  []);

  React.useEffect(() => {
    (async function () {
      try {
        const res = await api.surveys.get360ReportDataHandler(
          program_id,
          user_id,
        );
        if (res.status !== 204) {
          setReportData(res.data);

          // grab the latest self and stakeholder submission to return the latest date
          let latestSelfSubmission;
          const latestSelfSubmissionDate = (
            res.data?.self_submissions || []
          ).reduce((latest, submission) => {
            const submissionIsAfterLatest = dayjs(submission.submitted).isAfter(
              latest,
            );
            if (submissionIsAfterLatest) {
              latest = submission.submitted;
              latestSelfSubmission = submission;
              setLatestSelfSubmission(latestSelfSubmission);
            }
            return latest;
          }, 0);
          const latestStakeholderSubmissionDate = (
            res.data?.stakeholder_submissions || []
          ).reduce((latest, submission) => {
            const submissionIsAfterLatest = dayjs(submission.submitted).isAfter(
              latest,
            );
            if (submissionIsAfterLatest) latest = submission.submitted;
            return latest;
          }, 0);
          const latestDate = dayjs(latestSelfSubmissionDate).isAfter(
            latestStakeholderSubmissionDate,
          )
            ? latestSelfSubmissionDate
            : latestStakeholderSubmissionDate;
          setLatestSubmissionDate(latestDate === 0 ? undefined : latestDate);
          setNumberOfResponses(res.data.stakeholder_submissions.length);

          // Get Stakeholder non-competency QuestionIds for the stakeholder assessment section
          try {
            parseStakeholderSubmissions(
              res.data,
              latestSelfSubmission,
              setStakeholderQuestionIds,
              setStakeholderFeedback,
            );
          } catch (e) {
            // console.log('error', e);
          }
        }
        setIsLoadingGet360ReportData(false);
      } catch (error) {
        // will be used to show error message
        setIsLoadingGet360ReportData('error');
      }
    })();
  }, [program_id, user_id, parseStakeholderSubmissions]);

  function generateDownload() {
    // react is not disabling/setting the loading icon on the
    // download button so we need too wait 2 seconds to give that time
    setIsDownloadingPDF(true);
    setTimeout(function () {
      const filename = generateReportFilename(reportData);
      generateReportDownload(filename);
      setIsDownloadingPDF(false);
    }, 2000);
  }

  return (
    <View>
      <ViewHeader
        title='360 Report'
        titleIcon={<SummarizeOutlinedIcon fontSize='large' />}
        actions={[
          {
            render: (
              <Tooltip title='This request may take some time. Thank you for your patience.'>
                <Button
                  size='small'
                  disabled={
                    isLoadingGet360ReportData === true ||
                    isLoadingAggregateCompetencyResults === true ||
                    isLoadingStakeholderAssessmentCharts === true
                  }
                  loading={isDownloadingPDF}
                  onClick={generateDownload}
                  text='Download Report'
                />
              </Tooltip>
            ),
          },
        ]}
      />
      <ViewBody>
        <ViewBodySection title='Report'>
          <Card className='report-card'>
            <div id='report' style={{ padding: '50px' }}>
              <Row justify='end' style={{ marginBottom: '15px' }}>
                <SCLogo showText size={52} />
              </Row>
              <div className='report-section'>
                <ReportHeader
                  reportData={reportData}
                  numberOfResponses={numberOfResponses}
                  latestSubmissionDate={latestSubmissionDate}
                  isLoadingGet360ReportData={isLoadingGet360ReportData}
                />
              </div>
              <div className='report-section'>
                <ReportCompetencies
                  userId={user_id}
                  programId={program_id}
                  clientId={reportData?.client_id}
                  isLoadingGet360ReportData={isLoadingGet360ReportData}
                  isLoadingAggregateCompetencyResults={
                    isLoadingAggregateCompetencyResults
                  }
                  setIsLoadingAggregateCompetencyResults={
                    setIsLoadingAggregateCompetencyResults
                  }
                  stakeholderFeedback={stakeholderFeedback}
                />
              </div>
              <div className='report-section'>
                <ReportSelfAssessment
                  latestSelfSubmission={latestSelfSubmission}
                  isLoadingGet360ReportData={isLoadingGet360ReportData}
                />
              </div>
              <div className='report-section'>
                <ReportStakeholderAssessment
                  numberOfResponses={numberOfResponses}
                  reportData={reportData}
                  stakeholderQuestionIds={stakeholderQuestionIds}
                  isLoadingGet360ReportData={isLoadingGet360ReportData}
                  isLoadingStakeholderAssessmentCharts={
                    isLoadingStakeholderAssessmentCharts
                  }
                  setIsLoadingStakeholderAssessmentCharts={
                    setIsLoadingStakeholderAssessmentCharts
                  }
                  userId={user_id}
                  programId={program_id}
                />
              </div>
            </div>
          </Card>
        </ViewBodySection>
      </ViewBody>
    </View>
  );
}

export default Report;
