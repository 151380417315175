import React, { useState, FC, useMemo } from 'react';
import SOW from '@src/models/SOW';
import dayjs from 'dayjs';
import { Dialog, DialogActions, DialogContent, Tabs, Tab } from '@mui/material';
import {
  useToast,
  DialogTitle,
  Button,
} from '@src/components/sc-design-system';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  TabPanelWrapper,
  generateTabProps,
} from '@src/components/common/TabPanelWrapper';
import { AttributesTab, ResourcesTab, NotesTab } from './tabs';
import { useForm } from 'react-hook-form';
import { api } from '@src/lib/client';
import { useParams } from 'react-router';

interface SOWEditorModalProps {
  sow: SOW;
  onClose: Function;
}

const SOWEditorModal: FC<SOWEditorModalProps> = ({ sow, onClose }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState(0);
  const { id: clientId } = useParams();

  const { mutateAsync: saveSOW, isPending } = useMutation({
    mutationFn: async (data: SOWEditorValues) => {
      return api.sows.saveSOW(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sows'] });
      onClose();
      toast.success('SOW record saved successfully');
    },
  });

  const defaultValues = useMemo(
    () => ({
      id: sow?.id,
      name: sow?.name ?? '',
      client_id: sow?.client_id ?? clientId,
      type: sow?.type ?? '',
      is_active: `${sow?.is_active ?? true}`,
      start_date: sow?.start_date ? dayjs(sow.start_date) : null,
      end_date: sow?.end_date ? dayjs(sow.end_date) : null,
      default_program_id: sow?.default_program_id ?? false,
      allowed_durations: sow?.allowed_durations?.length
        ? sow?.allowed_durations
        : [60],
      seats_reserved: sow?.seats_reserved,
      sessions_reserved: sow?.sessions_reserved,
      rate: sow?.rate,
      coach_rate: sow?.coach_rate,
      salesforce_opp_id: sow?.salesforce_opp_id,
      options: {
        custom_coach_selection: sow?.options?.custom_coach_selection ?? false,
        mentoring: sow?.options?.mentoring ?? false,
      },
      notes: sow?.notes ?? [],
      description: sow?.description ?? '',
      resources: sow?.resources?.map(r => ({ id: r, value: r })) ?? [],
    }),
    [sow, clientId],
  );

  const form = useForm<SOWFormDefaultValues>({
    defaultValues,
    mode: 'all',
  });

  const notesVal = form.watch('notes');

  const tabs = useMemo(() => {
    return [
      { label: 'Attributes', ...generateTabProps(0) },
      { label: 'Resources', ...generateTabProps(1) },
      {
        label: `Notes${
          notesVal && notesVal.length ? ` (${notesVal.length})` : ''
        }`,
        ...generateTabProps(2),
      },
    ];
  }, [notesVal]);

  const onSubmit = async (data: SOWFormDefaultValues) => {
    await saveSOW({
      ...data,
      is_active: data.is_active === 'true',
      start_date: data.start_date?.valueOf(),
      end_date: data.end_date?.valueOf(),
      rate: parseFloat(data.rate as string),
      coach_rate: parseFloat(data.coach_rate as string),
      resources: data.resources.map(r => r.value),
    } as SOWEditorValues);
  };

  const handleClose = () => {
    form.reset(defaultValues);
    setActiveTab(0);
    onClose();
  };

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle title='SOW Editor' buttonOnClick={handleClose} />
      <DialogContent>
        <Tabs
          value={activeTab}
          onChange={(_, v) => {
            setActiveTab(v);
          }}
          sx={{ marginBottom: '1em' }}
        >
          {tabs.map(tab => (
            <Tab key={tab.label} label={tab.label} />
          ))}
        </Tabs>
        <form id='sow-editor-form' onSubmit={form.handleSubmit(onSubmit)}>
          <TabPanelWrapper value={activeTab} index={0}>
            <AttributesTab
              isNewSOW={!sow}
              availablePrograms={sow?.programs || []}
              form={form}
            />
          </TabPanelWrapper>
          <TabPanelWrapper value={activeTab} index={1}>
            <ResourcesTab form={form} />
          </TabPanelWrapper>
          <TabPanelWrapper value={activeTab} index={2}>
            <NotesTab form={form} />
          </TabPanelWrapper>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' text='Close' onClick={handleClose} />
        <Button
          text='Save'
          loading={isPending}
          disabled={
            Boolean(Object.keys(form.formState?.errors || {}).length) ||
            !form.formState.isDirty ||
            isPending
          }
          form='sow-editor-form'
          type='submit'
        />
      </DialogActions>
    </Dialog>
  );
};

export { SOWEditorModal };
