import React from 'react';
import dayjs from 'dayjs';
import { Stack, Typography } from '@mui/material';
import { CreatePerformanceCycleEmptyState } from '@src/components/views/MyBusiness/Performance/tabs/PerformanceCycles/CreatePerformanceCycleEmptyState';
import { AddPerformanceCycleButton } from '@src/components/views/MyBusiness/Performance/tabs/PerformanceCycles/AddPerformanceCycleButton';
import { PerformanceCycleCard } from '@src/components/views/MyBusiness/Performance/tabs/PerformanceCycles/PerformanceCycleCard';
import { useClientQueries } from '@src/hooks/useClientQueries';
import LoadingIcon from '@src/components/common/LoadingIcon';
import { Card } from '@src/components/sc-design-system';

interface PerformanceCyclesProps {
  clientId: string;
}

function PerformanceCycleError() {
  return (
    <Card bodyStyle={{ textAlign: 'center' }}>
      <Typography variant='h3' sx={{ marginBottom: '0.25em' }} color='primary'>
        Oops!
      </Typography>
      <Typography variant='body1'>
        Unable to load performance cycles. Please try again later.
      </Typography>
    </Card>
  );
}

const PerformanceCycles: React.FunctionComponent<
  PerformanceCyclesProps
> = props => {
  const { clientId } = props;
  const { useGetClient } = useClientQueries(clientId);
  const { data: client, isLoading, isFetching, isError } = useGetClient();
  const isLoadingClientData = isLoading || isFetching;

  const data = React.useMemo(
    function () {
      if (!isLoadingClientData) {
        const performanceCycleNames = [];
        const performance_cycles = (
          Array.isArray(client?.performance_cycles)
            ? client.performance_cycles
            : []
        ) as PerformanceCycleI[];
        const categorized = performance_cycles.reduce(
          (acc, pc) => {
            performanceCycleNames.push(pc.name);
            const start = dayjs(pc.start);
            const isActive = dayjs().isBetween(start, dayjs(pc.end));
            if (isActive || dayjs().isBefore(start)) {
              acc.active.push(pc);
            } else {
              acc.inactive.push(pc);
            }
            return acc;
          },
          { active: [], inactive: [] },
        );
        // sort based on start date
        return {
          active: categorized.active.sort((a, b) => b.start - a.start),
          inactive: categorized.inactive.sort((a, b) => b.start - a.start),
          performanceCycleNames,
        };
      }
    },
    [client, isLoadingClientData],
  );

  return (
    <>
      {isError ? (
        <PerformanceCycleError />
      ) : isLoadingClientData ? (
        <LoadingIcon />
      ) : (
        <>
          {!client?.performance_cycles ||
          (Array.isArray(client?.performance_cycles) &&
            client.performance_cycles.length === 0) ? (
            <CreatePerformanceCycleEmptyState
              performanceCycleNames={data.performanceCycleNames}
            />
          ) : (
            <>
              <Stack direction='row' justifyContent='end'>
                <AddPerformanceCycleButton
                  performanceCycleNames={data.performanceCycleNames}
                />
              </Stack>
              {data.active.map(pc => (
                <PerformanceCycleCard
                  key={pc.id}
                  active={true}
                  clientId={clientId}
                  performanceCycle={pc}
                  performanceCycleNames={data.performanceCycleNames}
                />
              ))}
              {data.inactive.map(pc => (
                <PerformanceCycleCard
                  key={pc.id}
                  active={false}
                  clientId={clientId}
                  performanceCycle={pc}
                  performanceCycleNames={data.performanceCycleNames}
                />
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export { PerformanceCycles };
