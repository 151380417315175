import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';

obey.creator('uuid', () => uuidv4());

export const AidaConversationSchema = {
  id: { type: 'string', required: true, creator: 'uuid' },
  user_id: { type: 'string', required: true },
  client_id: { type: 'string', required: true },
  prompt: {
    type: 'string',
    required: true,
    allow: ['summary', 'focus_areas', 'resources', 'coaches'],
  },
  model: { type: 'string', required: true },
  input_text: { type: 'string', required: true },
  input_data: { type: 'object' },
  instructions: { type: 'string' },
  output_text: { type: 'string', allowNull: true },
  output_data: { type: 'object', allowNull: true },
  ai_platform: { type: 'string', allow: ['bedrock', 'openai'], required: true },
  chat_completion_id: { type: 'string', allowNull: true, required: true },
  timestamp: { type: 'number', required: true },
  rating: {
    type: 'array',
    empty: true,
    values: {
      type: 'object',
      keys: {
        rated_on: { type: 'string', required: true },
        rated_by: { type: 'string' },
        thumbs_up: { type: 'boolean', required: true },
        feedback: { type: 'string' },
      },
    },
  },
  additional_data: { type: 'any' },
  dismissed_at: { type: 'number' },
};

// eslint-disable-next-line no-unused-vars
export type AidaConversationType = {
  [K in keyof typeof AidaConversationSchema]?: any;
};

export const AidaConversationModel = obey.model(AidaConversationSchema);
