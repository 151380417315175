import { api } from '@src/lib/client';

export interface LogPayload {
  severity: string;
  data: any;
}

export type Severity = 'debug' | 'error' | 'info' | 'warn';

export const log = (payload: LogPayload) => {
  if (!payload.severity || !payload.data) {
    console.warn('Log payload format incorrect');
    return;
  }
  // Run synchronously, this should be non-blocking
  api.logger.send(payload);
};

export class Logger {
  namespace: string;
  constructor(namespace: string) {
    this.namespace = namespace;
  }

  private _log(severity: Severity, message: string, ...args: unknown[]) {
    console[severity](`[${this.namespace}] ${message}`, ...args);
  }

  info(message: string, ...args: unknown[]) {
    this._log('info', message, ...args);
  }

  debug(message: string, ...args: unknown[]) {
    this._log('debug', message, ...args);
  }

  error(message: string, ...args: unknown[]) {
    this._log('error', message, ...args);
  }

  warn(message: string, ...args: unknown[]) {
    this._log('warn', message, ...args);
  }
}
