import { useToast } from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import Session from '@src/models/Session';
import User from '@src/models/User';
import { useQuery } from '@tanstack/react-query';

const useAdminQueries = () => {
  const toast = useToast();

  const useUserAlerts = ({ enabled } = { enabled: true }) => {
    const { data, ...rest } = useQuery({
      queryKey: ['admin', 'userAlerts'],
      queryFn: async () => await api.users.adminAlerts(),
      select: ({ data }) => data.map(u => new User(u)),
      enabled,
    });
    return {
      userAlerts: data ?? null,
      ...rest,
    };
  };

  const useSessionAlerts = ({ enabled } = { enabled: true }) => {
    const { data, ...rest } = useQuery({
      queryKey: ['admin', 'sessionAlerts'],
      queryFn: async () => api.sessions.alerts(),
      select: ({ data }) => data.map(s => new Session(s)),
      enabled,
    });

    return {
      sessionAlerts: data ?? null,
      ...rest,
    };
  };

  const useAnalytics = ({ enabled } = { enabled: true }) => {
    const { data, ...rest } = useQuery({
      queryKey: ['admin', 'analytics'],
      queryFn: async () => api.analytics.getAdminAnalytics(true),
      select: ({ data }) => data,
      enabled,
    });

    return {
      analytics: data ?? null,
      ...rest,
    };
  };

  const useSessionStats = ({ enabled } = { enabled: true }) => {
    const { data, isError, ...rest } = useQuery({
      queryKey: ['admin', 'sessionStats'],
      queryFn: async () => api.sessions.stats(),
      select: ({ data }) => {
        const coaching = data.coaching.map(c => ({
          name: 'Coaching',
          date: c.date,
          count: c.count,
        }));
        const interviews = data.interviews.map(i => ({
          name: 'Interviews',
          date: i.date,
          count: i.count,
        }));
        return [...coaching, ...interviews];
      },
      enabled,
    });

    if (isError) {
      toast.error("Could not get stats for last 7 days's sessions");
    }

    return {
      sessionStats: data ?? null,
      ...rest,
    };
  };

  return {
    useUserAlerts,
    useSessionAlerts,
    useAnalytics,
    useSessionStats,
  };
};

export { useAdminQueries };
