import React, { FC } from 'react';
import { observer } from 'mobx-react';
import SurveyQuestion from '@src/models/SurveyQuestion';
import { EditableTypography } from '@src/components/sc-design-system';

const QuestionText: FC<{
  surveyQuestion: SurveyQuestion;
  propName?: 'helpText' | 'privacyText' | 'name';
  isSurveyEditable: boolean;
}> = observer(({ surveyQuestion, propName = 'helpText', isSurveyEditable }) => (
  <EditableTypography
    key={surveyQuestion.id}
    variant='body1'
    width={'100%'}
    children={surveyQuestion[propName]}
    onChange={text => (surveyQuestion[propName] = text)}
    disabled={!isSurveyEditable}
    multiline
  />
));

export { QuestionText };
