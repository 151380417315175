import React, { FC, useEffect, useState, useMemo } from 'react';
import findIndex from 'lodash/findIndex';
import { Space } from 'antd';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import {
  Grid,
  Button,
  Spin,
  DialogTitle,
} from '@src/components/sc-design-system';
import { FuzzySearchBar } from '@src/components/common/FuzzySearchBar';
import SurveyQuestion, { questionTypes } from '@src/models/SurveyQuestion';
import { api } from '@src/lib/client';

interface SurveyQuestionBankModalProps {
  clientId?: string;
  handleClose: Function;
  setSurveyQuestions: Function;
  surveyQuestions: SurveyQuestion[];
}

const SurveyQuestionBankModal: FC<SurveyQuestionBankModalProps> = ({
  clientId,
  handleClose,
  setSurveyQuestions,
  surveyQuestions,
}) => {
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState<SurveyQuestion>();
  const [loadingBank, setLoadingBank] = useState(false);
  const [filteredQuestions, setFilteredQuestions] = useState(questions);

  const renderData = useMemo(() => {
    return filteredQuestions || questions;
  }, [filteredQuestions, questions]);

  useEffect(() => {
    const fetchSCQuestionBank = async (): Promise<void> => {
      setLoadingBank(true);
      const res = await api.surveys.getSkillCycleQuestions({
        questionBank: true,
      });
      setQuestions(
        res.data.data.map(q => {
          if (clientId) {
            q.client_id = clientId;
          }
          if (q.group) {
            q.helpText = q.group.helpText;
            q.id = q.group.id;
            q.title = q.group.title;
            q.type = q.group.type;
            q.questions = q.group.questions;
          }
          return new SurveyQuestion(q);
        }),
      );
      setLoadingBank(false);
    };
    fetchSCQuestionBank();
  }, [clientId]);

  return (
    <Dialog open onClose={() => handleClose()} title='Add a Question'>
      <DialogTitle buttonOnClick={() => handleClose()} title='Add a Question' />
      <DialogContent>
        {loadingBank ? (
          <Spin sectionLoader />
        ) : (
          <div>
            <FuzzySearchBar
              data={questions}
              paramKey='type'
              params={questionTypes}
              emptyParam='All Categories'
              searchKeys={['title']}
              onFilter={setFilteredQuestions}
              placeholder='Search SkillCycle Question Bank'
            />
            {renderData?.length > 0 ? (
              <Grid
                cols={2}
                data={renderData}
                isSelectable
                onSelectItem={q => {
                  setSelectedQuestion(
                    selectedQuestion?.id === q.id ? undefined : q,
                  );
                }}
                selectedItem={
                  selectedQuestion?.id
                    ? findIndex(
                        filteredQuestions,
                        q => q.id === selectedQuestion?.id,
                      )
                    : null
                }
                renderer={q => (
                  <Space
                    direction='vertical'
                    style={{ justifyContent: 'space-between' }}
                  >
                    <div className='question-title'>{q.title}</div>
                    <label className={`question-type-label ${q.type}`}>
                      {q.type === 'text' && q.isNumberInput ? 'Number' : q.type}
                    </label>
                  </Space>
                )}
              />
            ) : (
              'No matching items'
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button text='Close' variant='outlined' onClick={() => handleClose()} />
        <Button
          key='submit'
          text='Add Question'
          disabled={!selectedQuestion}
          onClick={() => {
            setSurveyQuestions([...surveyQuestions, selectedQuestion]);
            handleClose();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export { SurveyQuestionBankModal };
