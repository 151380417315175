import { capitalizeAllWords } from './utils';

const dictionary = {
  isMentor: {
    coach: 'mentor',
    coachee: 'mentee',
    coaches: 'mentors',
    coachees: 'mentees',
    "coach's": "mentor's",
    coaching: 'mentoring',
    '1:1 coaching': 'mentoring',
  },
  isCustomCoachSelection: {
    '1:1 coaching': 'custom coach selection',
  },
  usesValuesNotCompetencies: {
    competency: 'value',
  },
  plural: {
    session: 'sessions',
  },
};

export type Translations = keyof typeof dictionary;

type DictionaryFunction = (text: string, capitalize?: boolean) => string;

export const init = (translations: Translations[] = []): DictionaryFunction => {
  // Create applied dictionary by adding any dictionary groups from args array
  const appliedDictionary = translations.reduce((acc, translation) => {
    // Assign translation group(s)
    return Object.assign(acc, dictionary[translation] || {});
  }, {});

  // Return function that checks applied dictionary or falls back to text passed
  return (text: string, capitalize?: boolean): string => {
    let words = appliedDictionary[text] || text;

    if (capitalize) {
      words = capitalizeAllWords(words);
    }
    return words;
  };
};
