import React, { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import Check from '@mui/icons-material/Check';

interface TaskLabelProps {
  isFirstItem?: boolean;
  isLastItem?: boolean;
  isComplete?: boolean;
  allItemsComplete?: boolean;
}

const TaskLabel: FC<TaskLabelProps> = ({
  isFirstItem,
  isLastItem,
  isComplete,
  allItemsComplete,
}) => {
  const theme = useTheme();
  return (
    <Box height='100%'>
      <Box
        sx={{
          color: isComplete ? 'black.main' : 'grey.500',
          width: '32px',
          height: '32px',
          top: 'calc(50% - 16px)',
          position: 'relative',
          zIndex: 2,
          backgroundColor: isComplete ? 'tertiary.500' : 'white.main',
          borderRadius: '50%',
          border: isComplete ? null : `1px solid ${theme.palette.grey[500]}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Check sx={{ height: '20px', width: '20px' }} />
      </Box>
      <Box
        style={{
          marginLeft: 10,
          top: isLastItem ? null : isFirstItem ? 'calc(50% - 32px)' : '-25%',
          width: '12px',
          backgroundColor: allItemsComplete
            ? theme.palette.tertiary[100]
            : theme.palette.grey[100],
          position: 'relative',
          zIndex: 1,
        }}
        sx={{
          bottom: {
            lg: isLastItem ? 'calc(50% + 32px)' : 'none',
            xs: isLastItem ? '25%' : 'none',
          },
          height: {
            lg: isFirstItem || isLastItem ? '100%' : '125%',
            xs: isFirstItem || isLastItem ? '70%' : '125%',
          },
        }}
      />
    </Box>
  );
};

export { TaskLabel };
