import * as React from 'react';
import { Platform } from '@src/lib/platform';
import {
  createAPIClient,
  setGlobalAPIClient,
  HTTPClient,
} from '@src/lib/client';
import { createNavigation, INavigation } from './navigation';
import { Telemetry } from '@src/lib/telemetry';
import { createUserSession, UserSession } from '@src/session';
import Store from '@src/models/Store';

/**
 * World manages all services for the application, and for all intents and
 * purposes represents an instance of the application.
 */
export interface World {
  platform: Platform;
  navigation: INavigation;
  telemetry: Telemetry;
  session: UserSession;
}

export const createWorld = (store: Store): World => {
  const session = createUserSession();
  const http = new HTTPClient(session);
  const api = createAPIClient(http);
  setGlobalAPIClient(api);

  const navigation = createNavigation();
  const platform = new Platform(api.platform);
  const telemetry = new Telemetry(store);

  return { platform, navigation, telemetry, session };
};

export const WorldContext = React.createContext<World>(null!);
export const useWorld = (): World => {
  return React.useContext(WorldContext);
};
