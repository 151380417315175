import React from 'react';
import ReactMarkdown from 'react-markdown';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';
import CheckBoxChecked from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';

export const Selection: React.FunctionComponent<{
  type?: 'single' | 'multi';
  text: string;
  selected: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = ({ type = 'single', text, selected, onClick }) => {
  return (
    <Button
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === 'Tab') {
          e.target.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }
      }}
      sx={{
        justifyContent: 'flex-start',
        width: '100%',
        padding: '16px 20px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'primary.500',
        borderRadius: '8px',
        minHeight: '56px',
      }}
      startIcon={
        selected ? (
          type === 'single' ? (
            <Box
              sx={{
                position: 'relative',
                height: '24px',
                width: '24px',
                top: '1px',
                marginRight: '-1px',
              }}
            >
              <RadioButtonUnchecked
                sx={{
                  width: '22px',
                  height: '22px',
                  position: 'inherit',
                }}
              />
              <CircleIcon
                sx={{
                  width: '14px',
                  height: '14px',
                  position: 'absolute',
                  left: 5,
                  top: 4,
                }}
              />
            </Box>
          ) : (
            <CheckBoxChecked />
          )
        ) : type === 'single' ? (
          <RadioButtonUnchecked />
        ) : (
          <CheckBoxOutlineBlank />
        )
      }
      variant={selected ? 'contained' : 'outlined'}
    >
      <ReactMarkdown
        children={text}
        components={{
          p: ({ node, ...props }) => (
            <Typography variant='body2' {...props} sx={{ margin: 0 }} />
          ),
        }}
      />
    </Button>
  );
};

export const SelectionInput: React.FunctionComponent<{
  type?: 'single' | 'multi';
  text: string;
  inputValue?: string;
  selected: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onInputClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({
  type = 'single',
  text,
  inputValue,
  selected,
  onClick,
  onInputClick,
  onInputChange,
}) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        justifyContent: 'flex-start',
        width: '100%',
        borderRadius: '8px',
        padding: '11px 20px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'primary.500',
        minHeight: '56px',
      }}
      onKeyDown={e => {
        if (e.key === 'Tab') {
          e.target.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }
      }}
      startIcon={
        selected ? (
          type === 'single' ? (
            <Box
              sx={{
                position: 'relative',
                height: '24px',
                width: '24px',
                top: '1px',
                marginRight: '-1px',
              }}
            >
              <RadioButtonUnchecked
                sx={{
                  width: '22px',
                  height: '22px',
                  position: 'inherit',
                }}
              />
              <CircleIcon
                sx={{
                  width: '14px',
                  height: '14px',
                  position: 'absolute',
                  left: 5,
                  top: 4,
                }}
              />
            </Box>
          ) : (
            <CheckBoxChecked />
          )
        ) : type === 'single' ? (
          <RadioButtonUnchecked />
        ) : (
          <CheckBoxOutlineBlank />
        )
      }
      variant={selected ? 'contained' : 'outlined'}
    >
      {text}
      <Input
        sx={{
          paddingLeft: '4px',
          width: '100%',
          height: '100%',
          marginLeft: 1,
          '&.MuiInputBase-root': {
            color: selected ? 'white !important' : '#7E287E !important', // TODO: 'primary' doesn't work
            '.MuiInput-input': {
              '&:focus': {
                backgroundColor: 'white !important',
                color: 'black !important',
              },
            },
          },
        }}
        onClick={onInputClick}
        value={inputValue}
        onChange={onInputChange}
      />
    </Button>
  );
};

export const Range: React.FunctionComponent<{
  text: string;
  selected: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = ({ text, selected, onClick }) => {
  return (
    <Button
      key={text}
      onClick={onClick}
      sx={{
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'primary.500',
        padding: '20px 20px',
        borderRadius: '8px',
        minHeight: '56px',
      }}
      onKeyDown={e => {
        if (e.key === 'Tab') {
          e.target.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }
      }}
      variant={selected ? 'contained' : 'outlined'}
    >
      {text}
    </Button>
  );
};
