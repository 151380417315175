import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography } from '@mui/material';
import {
  useToast,
  ConfirmDialog,
  Spin,
} from '@src/components/sc-design-system';
import { useClientQueries } from '@src/hooks/useClientQueries';

interface InviteEmployeesProps {
  clientId: string;
  openInviteEmployeesModal: boolean;
  setOpenInviteEmployeesModal: (b: boolean) => void;
  toInvite: Set<string>;
  setToInvite: (s: Set<string>) => void;
  inviteMembers: (p: { invitees: Partial<Invitee>[] }) => Promise<unknown>;
}

export default function InviteEmployees(props: InviteEmployeesProps) {
  const {
    clientId,
    openInviteEmployeesModal,
    setOpenInviteEmployeesModal,
    toInvite,
    setToInvite,
    inviteMembers,
  } = props;
  const toast = useToast();
  const queryClient = useQueryClient();
  const { useGetClientMembers } = useClientQueries(clientId);
  const { members, isLoading: membersLoading } = useGetClientMembers({
    include_invitees: true,
    include_hris: true,
  });
  const invalidateClient = () => {
    queryClient.invalidateQueries({
      queryKey: [
        'client',
        clientId,
        'members',
        { include_invitees: true, include_hris: true },
      ],
    });
  };

  const { mutate: inviteEmployees, isPending } = useMutation({
    mutationFn: async () => {
      const emails = Array.from(toInvite);
      const invitees = emails.reduce((acc, email) => {
        const member = members.find(m => m.email === email);
        if (member) {
          acc.push({
            invitee_first_name: member?.first_name,
            invitee_last_name: member?.last_name,
            invitee_email: email,
          });
        }
        return acc;
      }, []);
      await inviteMembers({ invitees });
    },
    onSuccess: () => {
      setToInvite(new Set());
      invalidateClient();
      toast.success('Members successfully invited');
    },
    onError() {
      toast.error('Could not invite members');
    },
  });

  return (
    <ConfirmDialog
      open={openInviteEmployeesModal}
      setOpenModal={setOpenInviteEmployeesModal}
      buttonProps={{
        text: 'Send',
        disabled: isPending,
        onClick: async () => inviteEmployees(),
      }}
      showCancelButton={false}
      title='Send invite'
      body={
        <>
          {membersLoading ? (
            <Spin />
          ) : (
            <Typography variant='body1' sx={{ marginBottom: '1em' }}>
              You are inviting{' '}
              <strong>
                {toInvite.size} {toInvite.size > 1 ? 'employees' : 'employee'}
              </strong>{' '}
              to register an account with SkillCycle. Invites will only be sent
              to employees who have not been invited.
            </Typography>
          )}
        </>
      }
    />
  );
}
