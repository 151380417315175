import React, { FC } from 'react';
import { Stack } from '@mui/material';
import Client from '@src/models/Client';
import { GeneralInfoCard } from './components/GeneralInfoCard';
import { HRISSyncCard } from './components/HRISSyncCard';
import { AidaCard } from './components/AidaCard';

const ClientSettings: FC<{ client: Client }> = ({ client }) => {
  return (
    <Stack spacing={4}>
      <GeneralInfoCard client={client} />
      {client.hris_enabled && <HRISSyncCard client={client} />}
      {client.aida_enabled && <AidaCard client={client} />}
    </Stack>
  );
};

export { ClientSettings };
