import React from 'react';
import { v4 as uuid } from 'uuid';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import {
  Button,
  DialogTitle,
  useToast,
} from '@src/components/sc-design-system';
import { AidaExpandingCard } from './AidaExpandingCard';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import { useUser } from '@src/hooks/useUser';

interface ConversationShareModalProps {
  setOpenModal: (b: boolean) => void;
  openModal: boolean;
  assistantMessage: AidaMessage;
  selectedPrompt: Prompts;
  shareWith: ShareWith[];
}

const ConversationShareModal: React.FunctionComponent<
  ConversationShareModalProps
> = props => {
  const toast = useToast();
  const { user } = useUser();
  const {
    setOpenModal,
    openModal,
    assistantMessage,
    selectedPrompt,
    shareWith,
  } = props;
  const permissions = React.useMemo(
    function () {
      const shared = user?.aida_share?.[selectedPrompt] || [];
      return shared.reduce(
        (acc, s) => {
          acc[s.role].push(s.user_id);
          return acc;
        },
        { coach: [], mentor: [], manager: [] },
      );
    },
    [user.aida_share, selectedPrompt],
  );

  const titlePostfix =
    selectedPrompt === 'summary' ? 'development summary' : 'focus areas';

  function defaultValues(p) {
    return {
      coach: new Set(p.coach),
      mentor: new Set(p.mentor),
      manager: new Set(p.manager),
    };
  }

  const form = useForm({
    defaultValues: defaultValues(permissions),
  });
  const { control, handleSubmit, reset } = form;

  React.useEffect(
    () => reset(defaultValues(permissions)),
    [permissions, reset],
  );

  const { mutate: shareFeedback, isPending } = useMutation({
    mutationFn: async (payload: ShareObject[]) => {
      return api.users.update({
        id: user?.id,
        aida_share: {
          ...user?.aida_share,
          [selectedPrompt]: payload,
        },
      });
    },
    onSuccess: async () => {
      await user.getProfile();
      handleClose();
    },
    onError: () => toast.error('Unable to share results'),
  });

  const onSubmit = (data: ShareFormValues) => {
    function shareObj(arr, role) {
      return arr.map(user_id => ({
        user_id,
        role,
      }));
    }
    const values = [
      ...shareObj(Array.from(data?.coach), 'coach'),
      ...shareObj(Array.from(data?.mentor), 'mentor'),
      ...shareObj(Array.from(data?.manager), 'manager'),
    ];
    shareFeedback(values);
  };

  function handleClose() {
    reset();
    setOpenModal(false);
  }

  return (
    <Dialog open={openModal} onClose={() => handleClose()}>
      <DialogTitle title={`Share your ${titlePostfix}`} />
      <DialogContent dividers>
        <Typography variant='body1' sx={{ margin: '.25em 0 1em' }}>
          A preview of what will be shared:
        </Typography>
        <AidaExpandingCard
          truncate
          children={<ReactMarkdown children={assistantMessage?.summary} />}
          timestamp={assistantMessage?.timestamp}
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          id='share-form'
          style={{ marginTop: '2em' }}
        >
          {shareWith.map(s => (
            <FormControlLabel
              key={`${s.role}-${s.id}-${uuid()}`}
              control={
                <Controller
                  name={s.role}
                  control={control}
                  render={({ field }) => {
                    const { onChange, value, ...fieldProps } = field;
                    return (
                      <Switch
                        {...fieldProps}
                        inputProps={{ 'aria-label': fieldProps.name }}
                        checked={value.has(s.id)}
                        onChange={event => {
                          if (event.target.checked) {
                            value.add(s.id);
                          } else {
                            value.delete(s.id);
                          }
                          onChange(value);
                        }}
                      />
                    );
                  }}
                />
              }
              label={
                <Typography variant='body2m' sx={{ marginLeft: '1em' }}>
                  Share with {s.role}:{' '}
                  <Typography variant='body2sb' component='span'>
                    {s.first_name} {s.last_name}
                  </Typography>
                </Typography>
              }
              labelPlacement='end'
              sx={{ marginBottom: '0.8em', display: 'flex' }}
            />
          ))}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          key='cancel'
          text='Cancel'
          variant='outlined'
          onClick={handleClose}
        />
        <Button
          sx={{ marginLeft: '.5em' }}
          type='submit'
          form='share-form'
          loading={isPending}
          key='save'
          text='Save'
        />
      </DialogActions>
    </Dialog>
  );
};

export { ConversationShareModal };
