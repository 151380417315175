import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';
import { Button, useToast } from '@src/components/sc-design-system';
import User from '@src/models/User';
import { api } from '@src/lib/client';
import { AppLoader } from '@src/components/app/AppLoader';
import { SurveyType, SurveyQuestionType } from '@shared/schemas/src/survey';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SurveyActions } from './components/SurveyActions';
import { SurveyQuestion } from './components/SurveyQuestion';
import { process as processAction } from './utils/submit_actions';
import { useFullScreen } from '@src/hooks/useFullScreen';
import { useQueryParams } from '@src/utils';
import './Survey.less';
import { useNavigation } from '@src/navigation';
import { useMutation } from '@tanstack/react-query';
import { useUserQueries } from '@src/hooks/useUserQueries';

const INTAKE_SURVEY_ID = '3636f28e-f057-4697-8cb4-4aa957131202';

interface QuestionGroupType {
  title: string;
  helpText?: string;
  privacytext?: string;
  questions: any[];
}

interface UISurveyQuestionType extends SurveyQuestionType {
  group?: QuestionGroupType;
}

export interface StateAnswer {
  question_id: string;
  answer: any;
  other_value?: boolean;
}

const setAnswer = (
  question_id: string,
  answer: any,
  other_value: undefined | string,
  answers: StateAnswer[],
  setAnswers: Function,
): void => {
  setAnswers([
    ...answers.filter(a => a.question_id !== question_id),
    { question_id, answer, other_value },
  ]);
};

export const Survey: FC<{
  user?: User;
  previewSurvey?: SurveyType;
  previewQuestions?: SurveyQuestionType[];
  handleClose?: Function;
  onSubmit?: Function;
}> = ({ user, previewSurvey, previewQuestions, handleClose, onSubmit }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { fullScreen } = useFullScreen();
  const topOfSurveyRef = React.useRef(null);
  const navigation = useNavigation();
  const isPreview = Boolean(previewSurvey && previewQuestions);
  const query = useQueryParams();
  const survey_id = query.get('survey_id');
  const user_id = query.get('user_id') || user?.id;
  const user_first_name = query.get('user_first_name') || user?.first_name;
  const user_last_name = query.get('user_last_name') || user?.last_name;
  const email = query.get('email');
  const performanceCycleId = query.get('performance_cycle_id');
  const clientId = query.get('client_id');
  const programId = query.get('program_id');
  const notificationId = query.get('notification_id');
  const collectionId = query.get('collection_id');
  const type = query.get('type');
  const persistNotification = query.get('persist_notification') === 'true';
  const [currentQuestion, setCurrentQuestion] = useState<
    undefined | UISurveyQuestionType
  >();
  const [answers, setAnswers] = useState([]);
  const [hiddens, setHiddens] = useState({});
  const [transitionQuestion, setTransitionQuestion] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const publicView = !user?.id;
  const allowDraft = useMemo(() => {
    return (
      !publicView &&
      // Don't allow save of intake survey since if it's not completed, users cannot do anything else.
      survey_id !== INTAKE_SURVEY_ID &&
      (navigation?.location?.state?.state?.allowDraft != null
        ? navigation?.location?.state?.state?.allowDraft
        : true)
    );
  }, [navigation, publicView, survey_id]);
  const { useGetUserSurveySubmission } = useUserQueries(
    email ? user_id : user?.id,
  );
  const { surveySubmission, isLoading: isSurveySubmissionLoading } =
    useGetUserSurveySubmission(
      {
        surveyId: survey_id,
        email,
        performance_cycle_id: performanceCycleId,
        program_id: programId,
        collection_id: collectionId,
        notification_id: notificationId,
      },
      Boolean(!isPreview && user?.id),
    );

  const closeModal = async () => {
    setIsSubmitted(false);
    setAnswers([]);
    if (isPreview) {
      handleClose();
      return;
    }

    Object.keys(hiddens).forEach(h => query.delete(h));
    query.delete('performance_cycle_id');
    query.delete('client_id');
    query.delete('persist_notification');

    if (!publicView) {
      queryClient.invalidateQueries({
        queryKey: ['user', user?.id],
      });
    }

    navigation.replace(
      `${navigation.location.pathname}${
        [...new Set(query.keys())].length ? '?' + query : ''
      }`,
    );
  };

  const { mutateAsync: saveSurveySubmission } = useMutation({
    mutationFn: async (data: SurveySubmission) => {
      return api.surveys.saveSurveySubmission({
        ...data,
        id: surveySubmission?.id,
      });
    },
    onSuccess: async ({ data }) => {
      if (data.is_draft) {
        toast.success('Survey saved');
        closeModal();
      } else {
        if (performanceCycleId && clientId && type !== 'feedback') {
          await api.clients.updatePerformanceCycleResults(
            clientId,
            performanceCycleId,
            user_id,
            { evaluation_submission_id: data.submission_id },
          );
        }
        if (survey.on_submit.action?.length) {
          await processAction(survey.on_submit.action, data, {
            persistNotification,
          });
        }
        if (onSubmit) {
          onSubmit();
        }
        setIsSubmitted(true);
      }
      if (!publicView) {
        queryClient.invalidateQueries({
          queryKey: [
            'user',
            email ? user_id : user?.id,
            'submission',
            survey_id,
          ],
        });
        queryClient.invalidateQueries({
          queryKey: ['user', user?.id, 'summary-inputs', clientId],
        });
      }
    },
  });
  const {
    data: loadedSurvey,
    isLoading: isSurveyLoading,
    isError: surveyLoadError,
  } = useQuery({
    queryKey: ['survey', survey_id],
    queryFn: async () => api.surveys.getPublishedSurvey(survey_id),
    select: ({ data }) => data,
    enabled: Boolean(!isPreview && user_id),
  });

  const survey = isPreview ? previewSurvey : loadedSurvey;
  const questions = isPreview ? previewQuestions : survey?.questions;

  const isLoading = isSurveyLoading || isSurveySubmissionLoading;
  const isError = surveyLoadError || Boolean(!isPreview && !user_id);

  useEffect(() => {
    if (isLoading || !survey) return;
    setCurrentQuestion(questions[0]);
    if (!isPreview) {
      setHiddens(
        [
          ...(survey?.hidden || []),
          'survey_id',
          'user_id',
          'user_first_name',
          'user_last_name',
          'performance_cycle_id',
          'submitter_id',
          'type',
        ].reduce((acc, name: string) => {
          try {
            if (name === 'user_first_name') {
              acc[name] = user_first_name;
            } else if (name === 'user_last_name') {
              acc[name] = user_last_name;
            } else {
              acc[name] = query.get(name);
            }
          } catch (e) {
            /* no-op */
          }
          return acc;
        }, {}),
      );
    }
  }, [
    survey,
    query,
    user_first_name,
    user_last_name,
    isPreview,
    questions,
    surveySubmission,
    isLoading,
  ]);

  useEffect(() => {
    if (
      surveySubmission &&
      (surveySubmission.is_draft ||
        surveySubmission.survey_id === INTAKE_SURVEY_ID) &&
      !isPreview
    ) {
      setAnswers(
        surveySubmission.results.map(r => ({
          question_id: r.question.id,
          answer: r.answer,
          other_value: r.other_value,
        })),
      );
    }
  }, [surveySubmission, questions, isPreview]);

  const idx =
    survey && currentQuestion && currentQuestion.id
      ? questions.findIndex(q => q.id === currentQuestion.id)
      : survey && currentQuestion
      ? questions.findIndex(
          q =>
            q.group &&
            q.group.title === currentQuestion?.group?.title &&
            q.group.questions.reduce((acc, q, i) => {
              if (q.id !== currentQuestion?.group?.questions[i].id) {
                acc = false;
              }
              return acc;
            }),
          true,
        )
      : 0;

  const percentComplete =
    survey && currentQuestion ? (idx / (questions.length - 1)) * 100 : 0;

  if (!isPreview && !survey_id) {
    return null;
  }

  const canSkip = ['development', 'uat'].includes(process.env.NODE_ENV);

  return (
    <Dialog
      open
      maxWidth='md'
      fullScreen={publicView || fullScreen}
      onClose={() => {
        if (isPreview || canSkip) {
          closeModal();
        }
      }}
      sx={{
        '.MuiDialog-paper': {
          minHeight: '50vh',
        },
      }}
    >
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant='h3'>
            {survey ? survey.title : 'Loading Survey'}
          </Typography>
          {(isPreview || canSkip) && (
            <IconButton onClick={() => closeModal()}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <Typography variant='body2m' color='grey.700'>
          Survey for {user_first_name} {user_last_name}
        </Typography>
        {survey && (
          <LinearProgress
            sx={{
              margin: '.5em 0 0',
              borderRadius: 5,
              height: 10,
              backgroundColor: 'grey.200',
            }}
            variant='determinate'
            value={percentComplete}
          />
        )}
      </DialogTitle>
      <DialogContent
        className='survey-modal'
        sx={{ overflowY: 'unset', scrollMarginTop: '300px' }}
      >
        {isError ? (
          <Stack alignItems='center' sx={{ width: '100%' }} spacing={3}>
            <Typography variant='h3'>
              There was a problem loading your survey
            </Typography>
            <Button
              onClick={() => {
                closeModal();
              }}
              text='Close'
            />
          </Stack>
        ) : isLoading ? (
          <AppLoader />
        ) : isSubmitted ? (
          <Stack alignItems='center' sx={{ width: '100%' }} spacing={3}>
            <Typography variant='h3'>
              {survey?.on_submit.submit_title}
            </Typography>
            <Typography variant='body1'>
              {survey?.on_submit.submit_message}
            </Typography>
            <Button
              onClick={() => {
                closeModal();
              }}
              text='Close'
            />
          </Stack>
        ) : (
          <Stack
            ref={topOfSurveyRef}
            spacing={2}
            alignItems='center'
            width='100%'
            className={`survey-questions ${
              transitionQuestion ? 'transition-question' : ''
            }`}
            sx={{ scrollMarginTop: '300px' }}
          >
            <SurveyQuestion
              question={currentQuestion}
              answers={answers}
              setAnswer={(question_id, answer, other_value) => {
                setAnswer(
                  question_id,
                  answer,
                  other_value,
                  answers,
                  setAnswers,
                );
              }}
            />
            {currentQuestion?.privacyText && (
              <ReactMarkdown
                children={currentQuestion.privacyText}
                components={{
                  p: ({ node, ...props }) => (
                    <Typography
                      variant='body2'
                      color='primary.500'
                      {...props}
                    />
                  ),
                }}
              />
            )}
          </Stack>
        )}
      </DialogContent>
      {!isLoading && !isSubmitted && !!currentQuestion && (
        <SurveyActions
          topOfSurveyRef={topOfSurveyRef}
          currentQuestion={currentQuestion}
          setCurrentQuestion={question => {
            setTransitionQuestion(true);
            setTimeout(() => {
              setCurrentQuestion(question);
              setTransitionQuestion(false);
            }, 400);
          }}
          questions={questions}
          answers={answers}
          onSubmit={async (results, isDraft) => {
            if (isPreview) {
              handleClose();
              return;
            }
            await saveSurveySubmission({
              user_id,
              survey_id,
              hiddens,
              results,
              is_draft: isDraft,
            });
          }}
          allowDraft={allowDraft}
        />
      )}
    </Dialog>
  );
};
