import React from 'react';
import { Box, Stack, Divider } from '@mui/material';

const GreyHeaderBox: React.FunctionComponent<{
  header: React.ReactElement;
  children: React.ReactNode;
}> = ({ header, children }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'grey.100',
        border: `1px solid`,
        borderColor: 'grey.200',
        borderRadius: '0.6em',
      }}
    >
      <Stack
        direction='row'
        divider={<Divider orientation='vertical' flexItem />}
        spacing={2}
        padding={3}
      >
        {header}
      </Stack>
      <Box
        sx={{
          backgroundColor: 'white.main',
          boxShadow:
            '0 1px 3px 0 rgba(26,26,46,0.1), 0 0 0px rgba(26,26,46,0.1)',
          border: `1px solid`,
          borderColor: 'grey.200',
          borderRadius: '6px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export { GreyHeaderBox };
