import { observer } from 'mobx-react';
import React from 'react';
import {
  Button,
  useToast,
  ConfirmDialog,
} from '@src/components/sc-design-system';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import StarOutline from '@mui/icons-material/StarOutline';
import User from '@src/models/User';
import * as useVerbiage from '@shared/lib/src/use-verbiage';

const SaveOrUnsaveCoach: React.FunctionComponent<{
  user: User;
  coach: any;
  onSaved?: Function;
  programType: string;
}> = observer(({ user, coach, onSaved, programType }) => {
  const toast = useToast();
  const [isSaved, setIsSaved] = React.useState(
    user.saved_coaches.includes(coach.id),
  );
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [savingCoachSelect, setSavingCoachSelect] = React.useState(false);
  const canSaveAndUnsave = !user.savedAndInterviewCoachList.find(
    c => c.id === coach.id && c.interview.status,
  );
  const v = useVerbiage.init(programType === 'mentoring' ? ['isMentor'] : []);
  return (
    canSaveAndUnsave && (
      <>
        <Button
          startIcon={isSaved ? <RemoveCircleOutline /> : <StarOutline />}
          variant='text'
          loading={savingCoachSelect}
          text={`${isSaved ? 'Unsave' : 'Save'} ${v('coach', true)}`}
          onClick={() => setOpenConfirmModal(true)}
        />
        <ConfirmDialog
          open={openConfirmModal}
          setOpenModal={setOpenConfirmModal}
          buttonProps={{
            text: isSaved ? 'Unsave' : 'Save',
            onClick: async () => {
              try {
                setSavingCoachSelect(true);
                if (!isSaved) {
                  user.saved_coaches.push(coach.id);
                } else {
                  user.saved_coaches = user.saved_coaches.filter(
                    c => c !== coach.id,
                  );
                }
                await user.save(false);

                setIsSaved(!isSaved);
                if (!isSaved && onSaved) onSaved();
              } catch (e) {
                toast.error(
                  `Could not ${isSaved ? 'un' : ''}save this ${v('coach')}`,
                );
              }
              setSavingCoachSelect(false);
            },
          }}
          title={`${isSaved ? 'Remove' : 'Add'} ${`${coach.first_name} ${
            isSaved ? 'from' : 'to'
          } saved ${v('coaches')}`}`}
          body={`Are you sure you want to ${isSaved ? 'remove' : 'add'} ${`${
            coach.first_name
          } ${isSaved ? 'from' : 'to'} your list of saved ${v('coaches')}?`}`}
        />
      </>
    )
  );
});

export default SaveOrUnsaveCoach;
