import { api } from '@src/lib/client';

/* Assumptions:

 - URL QSPs for a manual collection survey will contain:
   - collection_id
   - notification_id

 - URL QSPs for a program self assessment survey will contain:
   - program_id
   - user_id

   * They must NOT contain 'email'
   * They must NOT contain 'notification_id'

 - URL QSPs for a program 360 stakeholder assessment survey will contain:
   - program_id
   - user_id
   - email

 - URL QSPs for a program midpoint/final engagement survey will contain:
   - program_id
   - user_id
   - notification_id

 - URL QSPs for an intake survey will contain:
   - user_id

*/

const Action = {
  completeNotification: async (submission, meta) => {
    const { notification_id } = submission.hiddens;
    // Only triggers for manual collection surveys, midpoint/final engagement surveys
    if (notification_id && !meta.persistNotification) {
      await api.notifications.setNotificationCompleted(notification_id);
    }
  },
  complete360StakeholderAssessment: async submission => {
    const {
      submission_id,
      hiddens: { email, program_id, user_id },
    } = submission;
    if (
      email && // Only triggers for 360 stakeholder assessment surveys
      program_id &&
      user_id &&
      submission_id
    ) {
      await api.programs.mark360StakeholderAssessmentComplete({
        email,
        programId: program_id,
        memberId: user_id,
        submissionId: submission_id,
      });
    }
  },
  mark360SelfAssessmentComplete: async submission => {
    const { hiddens, user_id, submission_id } = submission;
    if (
      hiddens.program_id &&
      !hiddens.email && // Required or else this will trigger for 360 stakeholder assessment surveys
      !hiddens.notification_id && // Required or else this will trigger for midpoint/final engagement surveys
      user_id &&
      submission_id
    ) {
      await api.programs.mark360SelfAssessmentComplete({
        programId: hiddens.program_id,
        memberId: user_id,
        surveySubmissionId: submission_id,
      });
    }
  },
  markSurveyCompleteForCollectionRecipient: async submission => {
    // TODO: Surveys taken by non-users will need `email` in the hidden array, and we'll need to ensure that email is unique among a given survey_collection's recipients.
    const { hiddens, user_id, submission_id } = submission;
    if (
      hiddens.collection_id && // Only triggers for manual collection surveys
      user_id &&
      submission_id
    ) {
      await api.surveys.markSurveyCompleteForCollectionRecipient(
        hiddens.collection_id,
        user_id,
        submission_id,
      );
    }
  },
  markFeedbackCompleteForStakeholder: async submission => {
    const { hiddens, user_id, survey_id, submission_id } = submission;
    const { client_id, performance_cycle_id, submitter_id, type } = hiddens;
    if (
      type === 'feedback' && // Only triggers for feedback forms
      user_id &&
      submitter_id &&
      submission_id
    ) {
      await api.clients.markFeedbackCompleteForStakeholder(
        client_id,
        performance_cycle_id,
        survey_id,
        user_id,
        { user_id: submitter_id, feedback_submission_id: submission_id },
      );
    }
  },
};

export const process = async (action: string[], submission, meta = {}) => {
  action.forEach(a => {
    Action[a](submission, meta);
  });
};
