import { action, computed, makeObservable, observable, toJS } from 'mobx';
import Address, { addressProps } from './Address';
import Base from './_Base';
import { api } from '@src/lib/client';
import { client as ClientSchema } from '@shared/schemas';
import { message } from 'antd';
import { AxiosResponse } from 'axios';
import uploadImageUri from '@src/lib/uploadImageUri';
import { GetMembersParamsIF } from '@src/lib/client/apis/clients';
import { CompetencySet } from './CompetencySet';
import Invitee from './Invitee';

export class ClientMemberDepartment {
  id: string;
  is_department_manager: boolean = false;

  constructor(clientMemberDepartment = {}) {
    makeObservable(this, {
      id: observable,
      is_department_manager: observable,
    });

    Object.keys(clientMemberDepartment).forEach(prop => {
      this[prop] = clientMemberDepartment[prop];
    });
  }

  get data() {
    return JSON.parse(
      JSON.stringify({
        id: this.id,
        is_department_manager: this.is_department_manager,
      }),
    );
  }
}
export class ClientMember {
  id: string;
  client_id: string;
  role: string;
  joinedAt: number = Date.now();
  removedAt: number = undefined;
  department: ClientMemberDepartment;
  title?: string;
  manager: string;
  is_primary_contact: boolean;
  profileImageUrl?: string;
  hasProfileImage = true;
  direct_reports = [];

  constructor(member) {
    makeObservable(this, {
      id: observable,
      client_id: observable,
      role: observable,
      joinedAt: observable,
      removedAt: observable,
      department: observable,
      manager: observable,
      is_primary_contact: observable,
      direct_reports: observable,
    });
    this.mergeData(member);
  }

  mergeData = (clientMember: ClientMember) => {
    Object.keys(clientMember).forEach(prop => {
      if (prop === 'department') {
        this[prop] = new ClientMemberDepartment(clientMember[prop]);
      } else {
        this[prop] = clientMember[prop];
      }
    });
  };

  get data() {
    return JSON.parse(
      JSON.stringify({
        id: this.id,
        client_id: this.client_id,
        role: this.role,
        department: this.department,
        title: this.title,
        manager: this.manager,
        joinedAt: this.joinedAt,
        removedAt: this.removedAt,
        is_primary_contact: this.is_primary_contact,
        direct_reports: this.direct_reports,
      }),
    );
  }
}

const clientProps = [
  'id',
  'name',
  'email_domain',
  'website',
  'salesforce_id',
  'logo_image',
  'addresses',
  'members',
  'mentors',
  'invitees',
  'default_sow_id',
  'competency_sets',
  'performance_cycles',
  'departments',
  'merge_account_token',
  'merge_last_modified',
  'merge_hris_data',
  'hris_enabled',
  'aida_enabled',
  'aida_terms_accepted',
];

export default class Client extends Base {
  id: string;
  name: string;
  email_domain?: string;
  website?: string;
  salesforce_id?: string;
  logo_image?: string;
  addresses?: Address[];
  members?: ClientMember[];
  mentors?: string[] = [];
  invitees: Invitee[] = [];
  sows?: any[];
  programs?: any[];
  default_sow_id?: string | boolean = false;
  competency_sets: CompetencySet[] = [];
  performance_cycles: PerformanceCycleI[] | PerformanceCycleIBase[];
  departments: ClientDepartmentI[] = [];
  merge_account_token?: string;
  merge_last_modified?: number;
  merge_hris_data?: any[];
  hris_enabled?: boolean;
  aida_enabled?: boolean;
  aida_terms_accepted?: {
    accepted_on: number;
    admin_uuid: string;
  };

  constructor(client: ClientSchema.ClientType = {}) {
    super();
    makeObservable(this, {
      id: observable,
      name: observable,
      email_domain: observable,
      website: observable,
      salesforce_id: observable,
      logo_image: observable,
      addresses: observable,
      members: observable,
      mentors: observable,
      invitees: observable,
      default_sow_id: observable,
      departments: observable,
      merge_account_token: observable,
      merge_last_modified: observable,
      merge_hris_data: observable,
      hris_enabled: observable,
      aida_enabled: observable,
      aida_terms_accepted: observable,
      // helper observables
      sows: observable,
      // computeds
      data: computed,
      isValid: computed,
      addressesValid: computed,
      // actions
      mergeData: action,
      save: action,
      getMembersList: action,
      removeMember: action,
    });
    this.mergeData(client);
    if (!client.addresses || !client.addresses[0]) {
      this.addresses = [new Address({ type: 'mailing' })];
    }
    if (!client.addresses || !client.addresses[1]) {
      this.addresses = [this.addresses[0], new Address({ type: 'billing' })];
    }
  }

  mergeData = (client: ClientSchema.ClientType) => {
    Object.keys(client).forEach(prop => {
      if (prop === 'addresses') {
        this[prop] = client[prop].map(d => d && new Address(d));
      } else if (prop === 'members') {
        this[prop] = client[prop].map(d => d && new ClientMember(d));
      } else if (prop === 'invitees') {
        this[prop] = client[prop].map(i => new Invitee(i));
      } else if (prop === 'competency_sets') {
        this[prop] = client[prop].map(c => new CompetencySet(c));
      } else {
        this[prop] = client[prop];
      }
    });
  };

  uploadImage = async (file: Blob): Promise<void> => {
    this.logo_image = await uploadImageUri(file);
  };

  get data() {
    return JSON.parse(
      JSON.stringify(
        clientProps.reduce((acc: object, prop: string) => {
          if (prop === 'addresses') {
            const addresses = this.addresses
              ? this.addresses.map(p => p.data)
              : [];
            if (addresses[0].type === 'all') {
              acc[prop] = [addresses[0]];
            } else {
              acc[prop] = addresses;
            }
          } else if (prop === 'members') {
            acc[prop] = this.members ? this.members.map(p => p.data) : [];
          } else if (prop === 'invitees') {
            acc[prop] = this.invitees ? this.invitees.map(i => i.data) : [];
          } else if (prop === 'competency_sets') {
            acc[prop] = this.competency_sets
              ? this.competency_sets.map(cs => cs.data)
              : [];
          } else if (prop === 'mentors') {
            acc[prop] = this.mentors || [];
          } else {
            acc[prop] = toJS(
              this[prop] && (this[prop].length > 0 || Boolean(this[prop]))
                ? this[prop]
                : undefined,
            );
          }
          return acc;
        }, {}),
      ),
    );
  }

  get isValid() {
    return (
      this.name?.length > 0 &&
      this.name !== 'New Client' &&
      this.email_domain?.length > 0 &&
      this.website?.length > 0 &&
      this.salesforce_id?.length > 0 &&
      this.addressesValid
    );
  }

  get addressesValid() {
    return this.addresses.every(a => {
      const props = Object.entries(a.data);
      // if only `type` is set, it's valid
      if (props.length === 1) return true;
      // only validate editable fields
      const filteredProps = addressProps.filter(prop => prop !== 'type');
      // if anything else is entered, everything should be entered,
      // but if everything is empty, it's valid
      const condition = Boolean(a.street_address_1);
      return (
        filteredProps.every(prop => {
          if (prop === 'street_address_2') {
            return true;
          }
          return Boolean(a[prop]) === condition;
        }) && (condition ? a.isValid : true)
      );
    });
  }

  addMember = async (
    userId: string,
    role: string = 'member',
    email: string,
  ): Promise<void> => {
    try {
      await api.clients.addClientMember({
        clientId: this.id,
        memberId: userId,
        role,
        email,
      });
      this.members.push(new ClientMember({ id: userId, role }));
    } catch (e) {
      message.error('Could not add member to client');
    }
  };

  removeMember = async (memberId: string): Promise<void> => {
    const member = this.members.find(m => m.id === memberId);
    member.removedAt = Date.now();
    // Remove this person as the manager for their direct reports
    const directReportIds = member.direct_reports;
    directReportIds.forEach(drid => {
      const directReport = this.members.find(m => m.id === drid);
      directReport.manager = undefined;
    });
    // Remove this person's direct reports
    member.direct_reports = undefined;
    await this.save();
  };

  getMembersList = async (
    queryParams: GetMembersParamsIF,
  ): Promise<AxiosResponse> =>
    await api.clients.getMembers(this.id, queryParams);

  getMentorsList = async (): Promise<AxiosResponse> =>
    await api.clients.getMentors(this.id);

  inviteMembers = async (invitees: Invitee[]): Promise<void> => {
    try {
      await api.clients.inviteClientMembers({
        clientId: this.id,
        invitees,
      });
      this.invitees = this.invitees.concat(invitees);
    } catch (e) {
      message.error('Could not invite members');
    }
  };

  revokeInvite = async (email: string): Promise<void> => {
    try {
      const res = await api.clients.revokeInvite({
        clientId: this.id,
        email,
      });
      this.invitees = res.data.invitees;
    } catch (e) {
      message.error('Could not revoke invite');
    }
  };

  undoRevokeInvite = async (email: string): Promise<void> => {
    try {
      const res = await api.clients.undoRevokeInvite({
        clientId: this.id,
        email,
      });
      this.invitees = res.data.invitees;
    } catch (e) {
      message.error('Could not undo revoke');
    }
  };

  save = async () => {
    try {
      const res = await api.clients.saveClient(this.data);
      this.mergeData(res.data); // Update local
    } catch (e) {
      message.error('Could not save client record');
      throw e;
    }
  };
}
