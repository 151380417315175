import React from 'react';
import { useToast } from '@src/components/sc-design-system';
import { useDropzone, FileError } from 'react-dropzone';
import { api } from '@src/lib/client';
import FileDropzone from './FileDropzone';

const FileUpload: React.FunctionComponent<{
  onSuccess: Function;
  onError?: Function;
  currentFileName?: string;
  onFileDelete?: (e: any) => void;
  accept?: any;
  maxSize?: number;
  customValidator?: {
    validatorFunction: <T extends File>(file: T) => FileError | FileError[];
    errorCode: string;
    errorMessage: string;
  };
}> = ({
  onSuccess,
  onError,
  currentFileName,
  onFileDelete,
  accept,
  maxSize,
  customValidator,
}) => {
  const toast = useToast();
  const [isUploading, setIsUploading] = React.useState(false);
  const [fileName, setFileName] = React.useState(currentFileName);
  const [fileKey, setFileKey] = React.useState<null | string>(null);
  React.useEffect(() => setFileName(currentFileName), [currentFileName]);
  const onDrop = async (files: any[]) => {
    const file = files[0];
    const { name } = file;
    setFileName(name);
    setIsUploading(true);
    try {
      const uploadUrlRes = await api.files.getUploadUrl(name);
      const uploadFileRes = await api.files.uploadFile(
        uploadUrlRes.data.url,
        file,
        uploadUrlRes.data.ContentType,
      );
      if (uploadFileRes.data.error) throw new Error('Could not upload file');
      setFileKey(uploadUrlRes.data.Key);
      onSuccess && onSuccess(uploadUrlRes.data.Key, name, file.size);
    } catch (e) {
      setFileName('');
      setFileKey(null);
      toast.error('Error uploading file');
      onError && onError(e);
    }
    setIsUploading(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxSize,
    multiple: false,
    validator: customValidator?.validatorFunction,
    onDrop,
    onDropRejected: event => {
      const error = event[0].errors[0];
      switch (error.code) {
        case 'file-invalid-type':
          toast.error(
            `File type must be one of the following: ${Object.values(accept)
              .flat()
              .join(', ')}.`,
          );
          break;
        case 'file-too-large':
          toast.error(`File size must not exceed ${maxSize} bytes.`);
          break;
        case customValidator?.errorCode:
          toast.error(customValidator?.errorMessage);
          break;
        default:
          toast.error(error.message);
      }
    },
  });

  const handleDelete = onFileDelete
    ? () => {
        setFileName('');
        onFileDelete(fileKey);
      }
    : null;

  return (
    <FileDropzone
      currentFileName={fileName}
      getRootProps={getRootProps}
      isDragActive={isDragActive}
      getInputProps={getInputProps}
      isUploading={isUploading}
      onFileDelete={handleDelete}
    />
  );
};

export default FileUpload;
