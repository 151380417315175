import React, { FC } from 'react';
import { Form, Radio, Space } from 'antd';

interface OptionObject {
  key: string;
  text: string;
  value: string;
}

export interface RadioGroupPropsIF {
  label: string;
  name?: string;
  options: OptionObject[];
  onChange?: Function;
  required?: boolean;
  value?: string | number | boolean;
  vertical?: boolean;
}

const RadioGroup: FC<RadioGroupPropsIF> = props => {
  const { label, name, options, required, onChange, value, vertical } = props;
  return (
    <Form.Item
      label={label ? label.toString() : undefined}
      name={name || label}
      rules={[{ required: Boolean(required) }]}
      initialValue={value}
    >
      <Radio.Group
        value={value}
        onChange={e => {
          onChange(e.target.value);
        }}
      >
        <Space direction={vertical ? 'vertical' : undefined}>
          {options.map(opt => (
            <Radio key={opt.key} value={opt.value}>
              {opt.text}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </Form.Item>
  );
};

export { RadioGroup };
