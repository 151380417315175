import React, { FC, useEffect, useState } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useForm, FormProvider } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import { api } from '@src/lib/client';
import { useFullScreen } from '@src/hooks/useFullScreen';
import { RemindRecipientsStep } from './steps/RemindRecipients';
import { ReviewAndSendStep } from './steps/ReviewAndSend';
import { CollectionSchema } from '../lib/schema';
import { SurveyWriteParams } from '@src/lib/client/apis/surveys';

export enum RemindRecipientsModalStep {
  remind = 'remind',
  review = 'review',
}

interface RemindRecipientsModalProps {
  isModalOpen: boolean;
  onClose: Function;
  clientId?: string;
  clientName: string;
  surveyId: string;
  surveyName: string;
  collection: any;
}

const RemindRecipientsModal: FC<RemindRecipientsModalProps> = ({
  isModalOpen,
  onClose,
  clientId,
  clientName,
  surveyId,
  surveyName,
  collection,
}) => {
  const queryClient = useQueryClient();
  const [activeStep, setActiveStep] = useState(
    RemindRecipientsModalStep.remind,
  );
  const { fullScreen } = useFullScreen();

  const { mutate: remindRecipients } = useMutation({
    mutationFn: async (data: {
      body: CollectionSchema['recipients'];
      params: SurveyWriteParams;
    }) => {
      const { body, params } = data;
      return api.surveys.remindRecipients(
        surveyId,
        clientId,
        collection.id,
        body,
        params,
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['survey', surveyId, 'collections'],
      });
      handleClose();
    },
  });

  const formMethods = useForm<CollectionSchema>({
    defaultValues: { recipients: [] },
  });

  const handleClose = () => {
    formMethods.reset();
    onClose();
  };

  const onSubmit = body => {
    remindRecipients({
      body,
      params: {
        surveyName,
        clientName,
      },
    });
  };

  useEffect(() => {
    return () => {
      if (activeStep !== RemindRecipientsModalStep.remind) {
        setActiveStep(RemindRecipientsModalStep.remind);
      }
    };
  }, [activeStep, formMethods]);

  return (
    <Dialog
      open={isModalOpen}
      maxWidth={activeStep === RemindRecipientsModalStep.remind ? 'lg' : 'md'}
      fullScreen={fullScreen}
      onClose={() => {
        handleClose();
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          {activeStep === RemindRecipientsModalStep.remind && (
            <RemindRecipientsStep
              collection={collection}
              handleClose={handleClose}
              clientId={clientId}
              handleStepChange={() => {
                setActiveStep(RemindRecipientsModalStep.review);
              }}
            />
          )}
          {activeStep === RemindRecipientsModalStep.review && (
            <ReviewAndSendStep
              collection={collection}
              handleClose={handleClose}
              clientId={clientId}
              handleStepChange={() => {
                setActiveStep(RemindRecipientsModalStep.remind);
              }}
            />
          )}
        </form>
      </FormProvider>
    </Dialog>
  );
};

export { RemindRecipientsModal };
