import React, { FC } from 'react';
import SurveyQuestion from '@src/models/SurveyQuestion';
import { observer } from 'mobx-react';
import {
  QuestionTitle,
  QuestionSortableList,
  QuestionText,
} from './components';

const SQSingleSelect: FC<{
  surveyQuestion: SurveyQuestion;
  isSurveyEditable: boolean;
}> = ({ surveyQuestion, isSurveyEditable }) => {
  return (
    <div className='sq-editor-single-select'>
      <label className={`question-type-label ${surveyQuestion.type}`}>
        {surveyQuestion.type}
      </label>
      <QuestionTitle
        surveyQuestion={surveyQuestion}
        readOnly={!isSurveyEditable}
      />
      <QuestionText
        surveyQuestion={surveyQuestion}
        propName='helpText'
        isSurveyEditable={isSurveyEditable}
      />
      <QuestionSortableList
        surveyQuestion={surveyQuestion}
        isSurveyEditable={isSurveyEditable}
      />
    </div>
  );
};

export default observer(SQSingleSelect);
