import React from 'react';
import { Box, Typography } from '@mui/material';

const RatingScaleBox: React.FunctionComponent<{
  text: string;
  value: number;
  variant?: 'outlined' | 'contained' | 'disabled';
}> = ({ text, value, variant = 'outlined' }) => {
  const xs =
    variant === 'outlined'
      ? { border: '1px solid', borderColor: 'primary.main' }
      : variant === 'contained'
      ? { backgroundColor: 'primary.500' }
      : {
          backgroundColor: 'grey.400',
          border: '1px solid',
          borderColor: 'grey.300',
        };
  const colors = {
    outlined: 'primary',
    contained: 'white.main',
    disabled: 'black.main',
  };
  return (
    <Box
      sx={{
        borderRadius: '8px',
        padding: '12px 20px 12px 20px',
        width: '166px',
        textAlign: 'center',
        ...xs,
      }}
      key={value}
    >
      <Typography variant='body1' color={colors[variant]}>
        {text || value}
      </Typography>
    </Box>
  );
};

export { RatingScaleBox };
