import React, { FC } from 'react';
import MUITextField, {
  TextFieldProps as MUITextFieldProps,
} from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

interface TextFieldProps extends MUITextFieldProps<'standard'> {
  id: string;
  errorMessage?: string;
  helperTextPosition?: 'top' | 'bottom';
  innerRef?: any;
}

const TextField: FC<TextFieldProps> = ({
  label,
  innerRef,
  required,
  onChange,
  error,
  errorMessage,
  helperText,
  helperTextPosition = 'bottom',
  disabled,
  id,
  placeholder = 'Enter text...',
  fullWidth = true,
  ...props
}) => {
  return (
    <FormControl
      component={Stack}
      gap='8px'
      disabled={disabled}
      fullWidth={fullWidth}
    >
      {label && (
        <InputLabel
          id='input-label'
          htmlFor={id}
          required={required}
          disabled={disabled}
        >
          {label}
        </InputLabel>
      )}
      {helperText && helperTextPosition === 'top' && (
        <FormHelperText
          color='text'
          sx={{
            marginTop: 0,
            marginBottom: '2px',
          }}
        >
          {helperText}
        </FormHelperText>
      )}
      <MUITextField
        label=''
        id={id}
        onChange={onChange}
        error={error}
        fullWidth={fullWidth}
        {...props}
        required={required}
        disabled={disabled}
        aria-labelledby={'input-label'}
        ref={innerRef}
        sx={{
          '.MuiInputBase-root.Mui-disabled': {
            backgroundColor: 'grey.400',
            '.MuiInputBase-input': {
              color: 'grey.800',
              '-webkit-text-fill-color': 'unset',
              opacity: 'unset',
            },
          },
        }}
        placeholder={placeholder}
      />
      {errorMessage && error && (
        <FormHelperText error color='error'>
          {errorMessage}
        </FormHelperText>
      )}
      {helperText && helperTextPosition === 'bottom' && (
        <FormHelperText color='text'>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export { TextField };
