import { HTTPClient } from '@src/lib/client';

export class SkillsAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async get() {
    return this.http.request({
      url: `/skills/list`,
      method: 'get',
    });
  }
}
