import React, { FC } from 'react';
import { observer } from 'mobx-react';
import Stack from '@mui/material/Stack';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import './ImageUpload.less';
import uploadImageUri from '@src/lib/uploadImageUri';

interface ImageUploaderPropsIF {
  imageUrl?: string;
  onChange?: Function;
  imageType?: string;
  hasProfileImage?: boolean;
}

const ImageUploader: FC<ImageUploaderPropsIF> = ({
  imageUrl,
  onChange,
  imageType,
  hasProfileImage,
}) => (
  <div className={`gc-image-upload ${imageType}`}>
    <input
      onChange={async e => {
        const imgUri = await uploadImageUri(e.target.files[0]);
        onChange && onChange(imgUri);
      }}
      style={{
        visibility: 'hidden',
        position: 'absolute',
        left: '-999999px',
      }}
      type='file'
      id='upload-button'
    />
    <label
      className='gc-upload-container'
      htmlFor='upload-button'
      style={{
        backgroundImage: `url('${imageUrl}')`,
      }}
    >
      {!hasProfileImage ? (
        <Stack direction='row' alignItems='center' gap={1}>
          <FileUploadOutlinedIcon />
          Upload Profile Image
        </Stack>
      ) : null}
    </label>
  </div>
);
export default observer(ImageUploader);
