import React, { FC } from 'react';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import ActiveInactiveChip from '../ActiveInactiveChip';

interface GoalCycleDetailsStackProps {
  cycle: GoalCycle;
  actions?: JSX.Element;
  sx?: StackProps['sx'];
  showStatusChip?: boolean;
}

const GoalCycleDetailsStack: FC<GoalCycleDetailsStackProps> = ({
  cycle,
  actions,
  sx,
  showStatusChip,
}) => {
  const shouldShowDates = Boolean(
    cycle.goal_submission_deadline ||
      cycle.goal_edit_deadline ||
      cycle.goal_evaluation_start ||
      cycle.goal_evaluation_end,
  );

  return (
    <Stack spacing={1} sx={sx}>
      {showStatusChip && (
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          marginBottom='8px'
        >
          <Stack direction='row' alignItems='center' gap={1}>
            <Typography variant='subtitle2'>
              {dayjs(cycle.start).format('MMM DD, YYYY')} -{' '}
              {dayjs(cycle.end).format('MMM DD, YYYY')}
            </Typography>
            <ActiveInactiveChip
              active={dayjs().isBetween(cycle.start, cycle.end)}
            />
          </Stack>
        </Stack>
      )}
      <div dangerouslySetInnerHTML={{ __html: cycle.description }} />
      {shouldShowDates && (
        <Stack
          direction='row'
          divider={<Divider orientation='vertical' flexItem />}
          spacing={2}
          marginTop='16px'
        >
          {cycle.goal_submission_deadline && (
            <Stack direction='row' gap={1}>
              <Typography variant='body2m' sx={{ color: 'black.main' }}>
                Enter goals by
              </Typography>
              <Typography variant='body2'>
                {dayjs(cycle.goal_submission_deadline).format('MMM D, YYYY')}
              </Typography>
            </Stack>
          )}
          {cycle.goal_edit_deadline && (
            <Stack direction='row' gap={1}>
              <Typography variant='body2m' sx={{ color: 'black.main' }}>
                Submit final edits by
              </Typography>
              <Typography variant='body2'>
                {dayjs(cycle.goal_edit_deadline).format('MMM D, YYYY')}
              </Typography>
            </Stack>
          )}
          {cycle.goal_evaluation_start && (
            <Stack direction='row' gap={1}>
              <Typography variant='body2m' sx={{ color: 'black.main' }}>
                Goal evaluation period
              </Typography>
              <Typography variant='body2'>
                {dayjs(cycle.goal_evaluation_start).format('MMM D, YYYY')} -{' '}
                {dayjs(cycle.goal_evaluation_end).format('MMM D, YYYY')}
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
      {Boolean(actions) && <Box sx={{ marginTop: '16px' }}>{actions}</Box>}
    </Stack>
  );
};

export { GoalCycleDetailsStack };
