import React, { FC, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Add from '@mui/icons-material/Add';
import {
  Button,
  Avatar,
  IconMenuButton,
} from '@src/components/sc-design-system';
import User from '@src/models/User';

import { GoalTooltip } from '../GoalTooltip';
import './GoalCard.less';
import { AlignedGoalsModal } from '@src/components/common/AlignedGoalsModal';
import GoalStatusIndicator from '@src/components/common/GoalCard/GoalStatusIndicator';
import GoalWeightIndicator from '@src/components/common/GoalCard/GoalWeightIndicator';
import { useConfirm } from 'material-ui-confirm';

interface GoalCardProps {
  goal: Goal;
  isEditable: boolean;
  isBeforeDeadline?: boolean;
  isUserGoal?: boolean;
  entityType: 'user' | 'client';
  onAddOrEdit?: (alignedGoalId?: string) => void;
  onDelete?: () => void;
  hasAddButton: boolean;
  alignedGoals?: Goal[];
  onGoalStatusUpdate: (a?: string) => void;
  canManageGoalWeights?: boolean;
  onClickGoalWeight: Function;
}

const GoalCard: FC<GoalCardProps> = ({
  goal,
  isEditable,
  isBeforeDeadline = true,
  isUserGoal,
  onAddOrEdit,
  onDelete,
  hasAddButton,
  alignedGoals = [],
  entityType,
  onGoalStatusUpdate,
  canManageGoalWeights = false,
  onClickGoalWeight,
}) => {
  const confirm = useConfirm();
  const [isAlignedGoalsModalOpen, setIsAlignedGoalsModalOpen] = useState(false);
  const currentStatus = useMemo(() => {
    return goal.status_history?.[goal.status_history.length - 1];
  }, [goal.status_history]);

  const handleDeleteGoal = async () => {
    try {
      await confirm({
        title: 'Delete this goal?',
        description: 'This action cannot be undone.',
        confirmationText: 'Delete',
        dialogProps: { maxWidth: 'xs' },
        cancellationButtonProps: {
          variant: 'outlined',
          className: 'shape-pill',
        },
        confirmationButtonProps: {
          className: 'shape-pill',
        },
      });
      onDelete();
    } catch (e) {}
  };

  return (
    <Stack spacing={1}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <Stack spacing={1} width='100%'>
          <Typography
            variant='h4'
            color='black.main'
            sx={{ marginBottom: '0.25em' }}
          >
            {goal.title}
          </Typography>
          <Stack
            direction='row'
            divider={<Divider orientation='vertical' flexItem />}
            alignItems='center'
            spacing={1}
          >
            {Boolean(goal.type) && (
              <Typography variant='body2' color='grey.700' component='span'>
                {startCase(goal.type)}
              </Typography>
            )}
            {Boolean(goal.target_date) && (
              <Typography variant='body2' color='grey.700' component='span'>
                By {dayjs(goal.target_date).format('M/D/YY')}
              </Typography>
            )}
          </Stack>
          {goal.user_ids?.length > 0 && (
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignItems={{ sm: 'center' }}
              justifyContent='flex-start'
              spacing={1}
            >
              <GoalStatusIndicator status={currentStatus?.status} />
              {canManageGoalWeights && goal?.weight != null && (
                <GoalWeightIndicator
                  weight={goal?.weight}
                  onClick={() => onClickGoalWeight()}
                  isEditable={isEditable && isBeforeDeadline}
                />
              )}
            </Stack>
          )}
        </Stack>
        {isEditable ? (
          <IconMenuButton
            items={[
              ...(isBeforeDeadline
                ? [
                    {
                      key: 'edit',
                      label: 'Edit',
                      onClick: () => onAddOrEdit(),
                    },
                  ]
                : []),
              ...(onGoalStatusUpdate
                ? [
                    {
                      key: 'goal_status',
                      label: 'Update Goal Status',
                      onClick: () => onGoalStatusUpdate(),
                    },
                  ]
                : []),
              {
                key: 'delete',
                label: 'Delete',
                onClick: handleDeleteGoal,
                disabled: !isBeforeDeadline,
              },
            ]}
          />
        ) : null}
      </Stack>
      <Typography
        variant='body1'
        color='grey.700'
        dangerouslySetInnerHTML={{ __html: goal.description }}
      />
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ sm: 'center' }}
        spacing={1}
      >
        {entityType === 'user' && Boolean(alignedGoals.length) && (
          <GoalTooltip
            type='aligned'
            tooltipData={{ isUserGoal, alignedGoals }}
          />
        )}
        {isUserGoal && Boolean(goal.program_ids?.length) && (
          <GoalTooltip
            type='shared-with-programs'
            tooltipData={{ programCount: goal.program_ids?.length }}
          />
        )}
        {hasAddButton && (
          <Button
            startIcon={<Add />}
            text='Add Goal'
            onClick={() => {
              onAddOrEdit(goal.id);
            }}
            variant='text'
            size='small'
          />
        )}
        {entityType === 'client' && Boolean(alignedGoals.length) && (
          <Link
            component='button'
            variant='a2blank'
            onClick={() => setIsAlignedGoalsModalOpen(true)}
            sx={{
              display: 'flex',
              gap: '0.5em',
              alignItems: 'center',
              '> span': {
                color: 'secondary.500',
              },
            }}
          >
            <div className='avatar-stack'>
              {alignedGoals.slice(0, 3).map(g => (
                <Avatar
                  key={`aligned-${g.id}`}
                  size='sm'
                  srcId={(g.user_ids[0] as unknown as User)?.id}
                />
              ))}
            </div>
            <span
              style={{
                marginLeft:
                  alignedGoals.slice(0, 3).length === 3 ? '1em' : null,
              }}
            >{`${alignedGoals.length} Goal${
              alignedGoals.length > 1 ? 's' : ''
            } Aligned`}</span>
          </Link>
        )}
      </Stack>
      {isAlignedGoalsModalOpen && (
        <AlignedGoalsModal
          open={isAlignedGoalsModalOpen}
          goal={goal}
          alignedGoals={alignedGoals}
          handleClose={() => setIsAlignedGoalsModalOpen(false)}
        />
      )}
    </Stack>
  );
};

export { GoalCard };
