import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import _findIndex from 'lodash/findIndex';
import styled from '@emotion/styled';
import MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigation } from '@src/navigation';
import { Link } from '@mui/material';

const BreadcrumbsContext = createContext(null);

export const BreadcrumbsProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState<
    Array<{ path: string; label: string; search?: string }>
  >([]);

  const appendBreadcrumb = newBreadcrumb => {
    setBreadcrumbs([...breadcrumbs, newBreadcrumb]);
  };

  const resetBreadcrumbs = newBreadcrumbs => {
    setBreadcrumbs(newBreadcrumbs);
  };

  const trimBreadcrumbs = (idx, currentCrumbs) => {
    const crumbs = currentCrumbs || breadcrumbs;
    const updatedBreadcrumbs = [...crumbs].slice(0, idx + 1);
    setBreadcrumbs(updatedBreadcrumbs);
  };

  const updateBreadcrumb = (update, idx) => {
    const updatedBreadcrumbs = [...breadcrumbs].map((crumb, i) => {
      if (idx === i) {
        return update;
      }
      return crumb;
    });
    setBreadcrumbs(updatedBreadcrumbs);
  };

  return (
    <BreadcrumbsContext.Provider
      value={{
        breadcrumbs,
        resetBreadcrumbs,
        appendBreadcrumb,
        trimBreadcrumbs,
        updateBreadcrumb,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export const useBreadcrumbs = () => {
  return useContext(BreadcrumbsContext);
};

export const Breadcrumbs = () => {
  const { breadcrumbs, trimBreadcrumbs } = useBreadcrumbs();
  const navigation = useNavigation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const handleClickMenuItem = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // ====
  // Event listener callback is registered with initial breadcrumb state.
  // Without a ref, the listener won't know about any updates, so we need to
  // update the ref whenever breadcrumbs itself is updated via app navigation.
  const [activeCrumbs, _setActiveCrumbs] = useState(breadcrumbs);
  const breadcrumbsRef = useRef(activeCrumbs);

  function setActiveCrumbs(crumbs) {
    breadcrumbsRef.current = crumbs;
    _setActiveCrumbs(crumbs);
  }
  useEffect(() => {
    setActiveCrumbs(breadcrumbs);
  }, [breadcrumbs]);
  // ====

  const onBrowserNavEvent = e => {
    e.preventDefault();

    const currentLocation = navigation.location.pathname;
    const currentCrumbs = breadcrumbsRef.current;

    const currentLocationIndex = _findIndex(
      currentCrumbs,
      ({ path }) => path === currentLocation,
    );

    // If the current location isn't already in the breadcrumbs array,
    // this is either a forward action in the browser, in which case
    // we no longer have the breadcrumb data (TODO?),
    // or we're on a totally separate page, in which case
    // we let the breadcrumbs get reset.
    if (currentLocationIndex === -1) {
      return;
    }

    if (currentCrumbs.length > 1) {
      trimBreadcrumbs(currentCrumbs.length - 2, currentCrumbs);
    }

    const previousCrumb = currentCrumbs[currentCrumbs.length - 2];
    if (previousCrumb) {
      navigation.replace(previousCrumb.path + (previousCrumb.search || ''));
    }
  };

  // register listener for browser back/forward nav
  useEffect(() => {
    window.addEventListener('popstate', onBrowserNavEvent);

    return () => {
      window.removeEventListener('popstate', onBrowserNavEvent);
    };
    // eslint-disable-next-line
  }, []);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const isMenuOpen = Boolean(anchorEl);
  const menuId = isMenuOpen ? 'account-menu' : undefined;

  const crumbs = useMemo(() => {
    if (breadcrumbs.length > 3) {
      return breadcrumbs.reduce((arr, c, idx) => {
        if (idx === 0 || idx === breadcrumbs.length - 1) {
          arr.push(c);
        } else if (idx === 1) {
          arr.push([{ ...c, rootIndex: idx }]); // initialize menu items
        } else {
          arr[1].push({ ...c, rootIndex: idx }); // append menu item
        }
        return arr;
      }, []);
    }
    return breadcrumbs;
  }, [breadcrumbs]);

  if (breadcrumbs.length < 2) {
    return <div />;
  }

  return (
    <>
      <MUIBreadcrumbs aria-label='breadcrumbs'>
        {crumbs.map((b, idx) => {
          if (idx === crumbs.length - 1) {
            return (
              <LastBreadcrumb
                key={b.label}
                sx={{
                  fontSize: {
                    xs: '12px',
                    sm: '14px',
                    md: '16px',
                    lg: '16px',
                  },
                }}
              >
                {b.label}
              </LastBreadcrumb>
            );
          }
          if (idx === 0 || (idx === 1 && !Array.isArray(b))) {
            return (
              <Link
                key={b.label}
                onClick={() => {
                  navigation.replace(b.path + (b.search || ''));
                  trimBreadcrumbs(idx);
                }}
                component='button'
                underline='hover'
                color='#585858'
                sx={{
                  fontSize: {
                    xs: '12px',
                    sm: '14px',
                    md: '16px',
                    lg: '16px',
                  },
                }}
              >
                {b.label}
              </Link>
            );
          }
          return (
            <>
              <Link
                underline='hover'
                component='button'
                color='#585858'
                onClick={handleClickMenuItem}
                key='menu-btn'
              >
                ...
              </Link>
              <StyledMenu
                open={isMenuOpen}
                onClose={handleCloseMenu}
                aria-describedby={menuId}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                key='menu'
                sx={{
                  width: [225, 260, 300],
                }}
              >
                {b.map(item => (
                  <MenuItem
                    onClick={() => {
                      navigation.replace(item.path + (item.search || ''));
                      trimBreadcrumbs(item.rootIndex);
                      handleCloseMenu();
                    }}
                    key={item.label}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </StyledMenu>
            </>
          );
        })}
      </MUIBreadcrumbs>
    </>
  );
};

const LastBreadcrumb = styled(Typography)({
  fontWeight: 600,
  color: '#585858',
});

const StyledMenu = styled(Menu)({
  '.MuiMenu-paper': {
    border: '1px solid #D8D8D8',
    boxShadow: 'none',
    width: 300,
    padding: '.5em 1em',
  },
});
