import React, { FC } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import {
  Button,
  DialogTitle,
  useToast,
} from '@src/components/sc-design-system';
import { FeedbackFormsForm } from './FeedbackFormsForm';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { api } from '@src/lib/client';
import EditOutlined from '@mui/icons-material/EditOutlined';

interface FeedbackFormsModalProps {
  open: boolean;
  handleClose: (b: boolean) => void;
  performanceCycle: PerformanceCycleI;
  edit?: boolean;
  refetchPerformanceCycle: Function;
}

const FeedbackFormsModal: FC<FeedbackFormsModalProps> = ({
  open,
  performanceCycle,
  handleClose,
  edit,
  refetchPerformanceCycle,
}) => {
  const toast = useToast();
  const { id: clientId } = useParams();
  const navigate = useNavigate();

  const form = useForm({
    mode: 'all',
    defaultValues: {
      self_evaluation_form_id: performanceCycle?.self_evaluation_form_id ?? '',
      manager_evaluation_form_id:
        performanceCycle?.manager_evaluation_form_id ?? '',
    } as FeedbackFormsFormDefaultValues,
  });
  const {
    reset,
    formState: { isValid, isDirty },
  } = form;

  const { mutate: setFeedbackForms, isPending } = useMutation({
    mutationFn: async (payload: Partial<PerformanceCycleI>) => {
      // exclude empty form ids
      const data: Partial<PerformanceCycleI> = {};
      if (payload?.manager_evaluation_form_id !== '')
        data.manager_evaluation_form_id = payload.manager_evaluation_form_id;
      if (payload?.self_evaluation_form_id !== '')
        data.self_evaluation_form_id = payload.self_evaluation_form_id;
      return api.clients.editPerformanceCycle(clientId, {
        id: performanceCycle.id,
        ...data,
      });
    },
    onSuccess: () => {
      refetchPerformanceCycle();
      handleClose(false);
    },
    onError: () => {
      toast.error('Unable to set feedback forms');
    },
  });

  const onSubmit = (data: FeedbackFormsOnSubmitProps) => {
    setFeedbackForms(data);
  };

  function onClose() {
    handleClose(false);
    reset();
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm'>
      <DialogTitle
        title={`${edit ? 'Edit' : 'Add'} feedback forms`}
        buttonOnClick={onClose}
      />
      <DialogContent dividers>
        <Typography variant='h4' sx={{ marginBottom: '.5em' }}>
          Select form to add
        </Typography>
        <Typography variant='body2' sx={{ marginBottom: '1.5em' }}>
          Select an existing survey for your self-evaluation and manager
          evaluation.
        </Typography>
        <FeedbackFormsForm onSubmit={onSubmit} form={form} />
        <Divider />
        <Typography variant='h4' sx={{ marginBottom: '.5em' }}>
          Create new form to add
        </Typography>
        <Typography variant='body2' sx={{ marginBottom: '1.5em' }}>
          Don’t see the form you want to attach? Create a new survey in the
          survey editor.
        </Typography>
        <Button
          startIcon={<EditOutlined />}
          text='Create new form'
          variant='outlined'
          onClick={() => {
            navigate(`/insights/${clientId}/?tab=1&new=true`);
            onClose();
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button text='Cancel' variant='outlined' onClick={onClose} />
        <Button
          text='Save'
          type='submit'
          sx={{ marginLeft: '.5em' }}
          form='feedback-forms-form'
          disabled={!isValid || !isDirty}
          loading={isPending}
        />
      </DialogActions>
    </Dialog>
  );
};

export { FeedbackFormsModal };
