import React, { FC } from 'react';
import { Select } from 'antd';
import User from '@src/models/User';
import { observer } from 'mobx-react';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import CancelOutlined from '@mui/icons-material/CancelOutlined';

interface UserStatusProps {
  user: User;
  onChange?: Function;
}

const iconStyle = {
  width: '.8em',
  height: '.8em',
  position: 'relative',
  top: '5px',
};

const UserStatus: FC<UserStatusProps> = ({ user, onChange }) => {
  return (
    <Select
      bordered={false}
      style={{ fontSize: '1em' }}
      defaultValue={user.status}
      disabled={!onChange}
      showArrow={Boolean(onChange)}
      onChange={v => onChange && onChange(v)}
      value={user.status}
    >
      <Select.Option value='active'>
        <CheckCircleOutline color='success' sx={iconStyle} /> Active
      </Select.Option>
      {user.is_coach && (
        <Select.Option value='pending'>
          <InfoOutlined color='warning' sx={iconStyle} /> Paused
        </Select.Option>
      )}
      <Select.Option
        value='disabled'
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <CancelOutlined color='error' sx={iconStyle} /> Disabled
      </Select.Option>
    </Select>
  );
};

export default observer(UserStatus);
