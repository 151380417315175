import React from 'react';
import Stack from '@mui/material/Stack';
import { Statistic } from '@src/components/sc-design-system';

const IconStatistic: React.FunctionComponent<{
  title: string;
  value: string | number;
  Icon: JSX.Element;
}> = ({ title, value, Icon }) => {
  return (
    <Stack direction='row' alignItems='center' gap={1}>
      {Icon}
      <Statistic title={title} value={value} />
    </Stack>
  );
};

export default IconStatistic;
