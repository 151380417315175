import React, { FC } from 'react';
import { Bullet, Spin } from '@src/components/sc-design-system';
import { observer } from 'mobx-react';
import { Typography, Divider, Stack, Box } from '@mui/material';

interface AreaOfInterestDataPoint {
  skill: string;
  measure: number[];
  range: number[];
  target: number;
}

interface AreasOfInterest {
  data: AreaOfInterestDataPoint[];
}

const ClientDashboardAreasOfInterest: FC<{
  areasOfInterest?: AreasOfInterest;
  clientName: string;
  loading?: boolean;
}> = ({ areasOfInterest, clientName, loading }) => {
  if (loading) return <Spin sectionLoader />;

  const position = 'left' as const;

  const config = {
    appendPadding: 15,
    data: areasOfInterest?.data || [],
    measureField: 'measure',
    rangeField: 'range',
    targetField: 'target',
    xField: 'skill',
    color: {
      target: '#f54284',
    },
    yAxis: {
      position: position,
      label: {
        formatter: v => `${v}%`,
      },
    },
    label: {
      measure: {
        position: 'right',
        formatter: v =>
          Number.isNaN(v.measure) ? '0.00%' : `${v.measure?.toFixed(2)}%`,
      },
    },
    tooltip: {
      formatter: v => {
        return {
          name: Object.keys(v).includes('measure')
            ? 'Your Organization'
            : 'All Other Organizations',
          value:
            Object.keys(v).includes('measure') && !Number.isNaN(v.measure)
              ? `${v.measure?.toFixed(2)}%`
              : Object.keys(v).includes('target') && !Number.isNaN(v.measure)
              ? `${v.target?.toFixed(1)}%`
              : '0.00%',
        };
      },
    },
    size: { target: 28 },
    bulletStyle: {
      target: {
        lineWidth: 3,
      },
    },
  };
  return !areasOfInterest?.data?.length ? (
    <Typography variant='body1'>
      As soon as you have responses from 10 registered members, we will be able
      to show trends data on their expressed areas of coaching support.
    </Typography>
  ) : (
    <>
      <Bullet {...config} />
      <Divider />
      <Box sx={{ textAlign: 'center' }}>
        <Stack direction='row' justifyContent='center' spacing={2}>
          <Stack direction='row' alignItems='center' spacing={1}>
            <LegendIcon />
            <Typography variant='body1'>{clientName}</Typography>
          </Stack>
          <Stack direction='row' alignItems='center' spacing={1}>
            <LegendIcon color='#ED1F80' />
            <Typography variant='body1'>Other Organizations</Typography>
          </Stack>
        </Stack>
        <Typography variant='body2' sx={{ marginTop: '1em' }}>
          Percentage of users who selected the topic as an area of coaching
          interest
        </Typography>
      </Box>
    </>
  );
};

const LegendIcon: FC<{ color?: string }> = ({ color = '#0EBFDA' }) => (
  <svg
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='0.5' width='16' height='16' rx='4' fill={color} />
  </svg>
);

export default observer(ClientDashboardAreasOfInterest);
