import React, { FC, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import _uniqBy from 'lodash/uniqBy';
import _intersectionBy from 'lodash/intersectionBy';
import _pullAllBy from 'lodash/pullAllBy';
import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Button, DialogTitle, Spin } from '@src/components/sc-design-system';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { useInfiniteQuery } from '@tanstack/react-query';
import { api } from '@src/lib/client';

const PAGE_LIMIT = 200;

interface AddRecipientsStepProps {
  handleClose: Function;
  handleStepChange: Function;
  clientId?: string;
  isDraftMode: boolean;
}

const AddRecipientsStep: FC<AddRecipientsStepProps> = ({
  handleClose,
  handleStepChange,
  clientId,
  isDraftMode,
}) => {
  const { setValue, trigger: validate, watch } = useFormContext();
  const recipientsFormVal = watch('recipients');
  const [searchKey, setSearchKey] = useState('');
  const [departmentFilter, setDepartmentFilter] = useState('all');

  const { useGetClient } = useClientQueries(clientId);
  const { data: client } = useGetClient();

  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['clients', clientId, 'members', { limit: 200 }],
    queryFn: async ({ pageParam }) => {
      const res = await api.clients.getMembers(clientId, {
        limit: PAGE_LIMIT,
        page: pageParam,
      });
      return res.data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.data?.length === PAGE_LIMIT
        ? pages.length + 1
        : undefined;
    },
  });

  // attach department data for filtering
  const membersWithCollectionData = useMemo(() => {
    return (
      data?.pages?.reduce((arr, page) => {
        const pageData = page?.data.map(m => ({
          ...m,
          ...recipientsFormVal.find(r => r.id === m.id),
        }));
        return [...arr, ...pageData];
      }, []) || []
    );
  }, [data?.pages, recipientsFormVal]);

  // previous recipients will always be included in submission
  const alwaysIncludedMembers = useMemo(
    () => membersWithCollectionData?.filter(r => r.send_error || r.last_sent),
    [membersWithCollectionData],
  );

  const [selectedMembers, setSelectedMembers] = useState(
    membersWithCollectionData?.filter(m =>
      recipientsFormVal.some(r => r.id === m?.id),
    ),
  );

  // only display number of new or unsuccessful recipients
  const countToSend = useMemo(() => {
    return selectedMembers?.filter(m => !m.last_sent).length ?? 0;
  }, [selectedMembers]);

  const filteredMembers = membersWithCollectionData
    ?.filter(m => {
      const nameFilterMatch = searchKey
        ? m.first_name
            .toLocaleLowerCase()
            .includes(searchKey.toLocaleLowerCase()) ||
          m.last_name
            .toLocaleLowerCase()
            .includes(searchKey.toLocaleLowerCase())
        : true;

      const departmentFilterMatch =
        departmentFilter === 'all'
          ? true
          : departmentFilter === 'none'
          ? !m.department?.id
          : m.department?.id === departmentFilter;

      return nameFilterMatch && departmentFilterMatch;
    })
    .sort((a, b) => (a.first_name > b.first_name ? 1 : -1));

  const selectableMembers = useMemo(
    () =>
      filteredMembers?.filter(m => {
        return recipientsFormVal.every(r => {
          if (r.id === m.id) {
            return !r.last_sent && !r.send_error;
          }
          return true;
        });
      }),
    [filteredMembers, recipientsFormVal],
  );

  // Track which members have been selected within given department filter
  const selectedFilteredMembers = useMemo(() => {
    return _intersectionBy(
      // omit existing recipients
      _intersectionBy(selectedMembers, selectableMembers, 'id'),
      filteredMembers,
      'id',
    );
  }, [selectedMembers, filteredMembers, selectableMembers]);

  const onClickAdd = () => {
    const mappedSelected = selectedMembers.map(m => {
      return {
        id: m.id,
        email: m.email,
        first_name: m.first_name,
        last_name: m.last_name,
        last_sent: m?.last_sent,
        send_error: m?.send_error,
        submission_id: m?.submission_id,
      };
    });

    setValue(
      'recipients',
      _uniqBy([...mappedSelected, ...alwaysIncludedMembers], 'id'),
    );
    validate();
    handleStepChange();
  };

  const renderMainListItem = ({ index, style }) => {
    const m = filteredMembers?.[index];
    if (!m) return null;
    const selectedMember = selectedMembers?.find(s => s.id === m.id);
    const alreadySent =
      !isDraftMode && recipientsFormVal.some(r => r.id === m.id && r.last_sent);
    const hasSendError = recipientsFormVal.find(
      r => r.id === m.id && r.send_error,
    );
    return (
      <StyledListItem key={m.id} disablePadding style={style} divider>
        <ListItemText
          primary={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {m.first_name} {m.last_name}
              {hasSendError ? (
                <Tooltip title='Previous attempt failed to send. Member is automatically selected for next send.'>
                  <InfoIcon
                    sx={{ fontSize: 20, marginLeft: '5px' }}
                    color='error'
                  />
                </Tooltip>
              ) : null}
            </div>
          }
        />
        <Checkbox
          sx={{ right: '-12px' }}
          checked={Boolean(selectedMember && (hasSendError || !alreadySent))}
          disabled={alreadySent || hasSendError}
          onChange={e => {
            const checked = e.target.checked;
            if (checked) {
              setSelectedMembers([...selectedMembers, m]);
            } else {
              const filtered = selectedMembers.filter(({ id }) => id !== m.id);
              setSelectedMembers(filtered);
            }
          }}
        />
      </StyledListItem>
    );
  };

  const renderSelectedListItem = ({ index, style }) => {
    const m = selectedMembers?.filter(m => !m.last_sent)?.[index];
    if (!m) return null;
    const hasSendError = recipientsFormVal.some(
      r => r.id === m.id && r.send_error,
    );
    return (
      <StyledListItem key={m.id} style={style} disablePadding divider>
        <ListItemText primary={`${m.first_name} ${m.last_name}`} />
        {!hasSendError && (
          <IconButton
            disabled={hasSendError}
            onClick={() => {
              const filtered = selectedMembers.filter(({ id }) => id !== m.id);
              setSelectedMembers(filtered);
            }}
            sx={{ right: '-12px' }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </StyledListItem>
    );
  };

  return (
    <>
      <DialogTitle title='Add Recipients' buttonOnClick={() => handleClose()} />
      <DialogContent style={{ overflowY: 'auto', padding: '2em' }}>
        <Grid
          container
          spacing={2}
          columns={{ xs: 1, sm: 1, md: 16 }}
          sx={{ flexGrow: 1 }}
        >
          <Grid item xs={8} height='auto'>
            <StyledCard
              style={{
                height: '100%',
                padding: 0,
              }}
              variant='outlined'
            >
              <div
                style={{
                  borderBottom: '1px solid #D8D8D8',
                  padding: '1.5em 1.5em .5em',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 16,
                }}
              >
                <TextField
                  label=''
                  value={searchKey}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  placeholder='Search'
                  onChange={e => setSearchKey(e.target.value)}
                />
                <Stack spacing={1}>
                  <StyledInputLabel id='department-filter-label'>
                    Filter by department
                  </StyledInputLabel>
                  <Select
                    labelId='department-filter-label'
                    value={departmentFilter}
                    onChange={e => setDepartmentFilter(e.target.value)}
                    IconComponent={ExpandMoreIcon}
                    fullWidth
                  >
                    <MenuItem key='all' value='all'>
                      All departments
                    </MenuItem>
                    {client?.departments
                      ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map(d => (
                        <MenuItem key={d.id} value={d.id}>
                          {d.name}
                        </MenuItem>
                      ))}
                    <MenuItem key='none' value='none'>
                      No department
                    </MenuItem>
                  </Select>
                </Stack>
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      indeterminate={
                        selectedFilteredMembers?.length > 0 &&
                        selectedFilteredMembers?.length <
                          selectableMembers?.length &&
                        selectedFilteredMembers?.length !==
                          alwaysIncludedMembers.length
                      }
                      checked={
                        selectableMembers?.length &&
                        selectableMembers?.length ===
                          selectedFilteredMembers?.length
                      }
                      disabled={!selectableMembers?.length}
                      onChange={e => {
                        if (e.target.checked) {
                          setSelectedMembers(
                            _uniqBy(
                              [
                                ...alwaysIncludedMembers,
                                ...selectedMembers,
                                ...selectableMembers,
                              ],
                              'id',
                            ),
                          );
                        } else {
                          const updated = _pullAllBy(
                            selectedMembers,
                            selectedFilteredMembers,
                            'id',
                          );
                          setSelectedMembers(
                            _uniqBy(
                              [...alwaysIncludedMembers, ...updated],
                              'id',
                            ),
                          );
                        }
                      }}
                    />
                  }
                  label={
                    departmentFilter !== 'all'
                      ? 'Select all members in filtered department'
                      : 'Select all registered members'
                  }
                  labelPlacement='start'
                />
              </div>
              {isFetching && !membersWithCollectionData?.length ? (
                <Spin sectionLoader style={{ minHeight: 360 }} />
              ) : (
                <InfiniteLoader
                  isItemLoaded={index => index < filteredMembers?.length}
                  itemCount={
                    hasNextPage
                      ? filteredMembers?.length + 1
                      : filteredMembers?.length
                  }
                  minimumBatchSize={2}
                  loadMoreItems={
                    isFetching
                      ? () => {}
                      : () => {
                          if (hasNextPage) fetchNextPage();
                        }
                  }
                >
                  {({ onItemsRendered, ref }) => (
                    <StyledList
                      height={374}
                      itemSize={56}
                      itemCount={membersWithCollectionData?.length}
                      itemData={filteredMembers}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                    >
                      {renderMainListItem}
                    </StyledList>
                  )}
                </InfiniteLoader>
              )}
            </StyledCard>
          </Grid>
          <Grid item xs={8}>
            <StyledCard
              sx={{
                height: '100%',
                backgroundColor: 'grey.100',
              }}
            >
              <div style={{ marginBottom: '1em', padding: '1.5em 1.5em 0' }}>
                <Typography variant='body1'>{`${countToSend || 0} recipient${
                  countToSend === 1 ? '' : 's'
                } added`}</Typography>
              </div>
              <StyledList
                className='selected-list'
                height={560}
                itemSize={56}
                itemCount={selectedMembers?.length}
              >
                {renderSelectedListItem}
              </StyledList>
            </StyledCard>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          text='Back'
          variant='outlined'
          onClick={() => handleStepChange()}
        />
        <Button
          text='Save'
          disabled={Boolean(
            selectedMembers?.length > 0 &&
              selectedMembers?.length === recipientsFormVal.length,
          )}
          onClick={onClickAdd}
        />
      </DialogActions>
    </>
  );
};

const StyledCard = styled(Card)({
  borderRadius: '0.6em',
  boxShadow: 'none',
  fontSize: 16,
});

const StyledListItem = styled(ListItem)({
  minHeight: 56,
});

const StyledFormControlLabel = styled(FormControlLabel)({
  '&.MuiFormControlLabel-root': {
    justifyContent: 'space-between',
    marginLeft: 0,
    '.MuiFormControlLabel-label': {
      fontSize: 16,
      lineHeight: '19px',
      fontWeight: 400,
    },
  },
});

const StyledInputLabel = styled(InputLabel)({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '16px',
  color: '#1A1A2E',
});

const StyledList = styled(FixedSizeList)({
  paddingLeft: '1.5em',
  paddingRight: '1.5em',
  marginBottom: '1.5em',
  '> div': {
    position: 'relative',
  },
});

export { AddRecipientsStep };
