import React, { useMemo, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMediaQuery, useTheme } from '@mui/material';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Add from '@mui/icons-material/Add';
import { api } from '@src/lib/client';
import Client from '@src/models/Client';
import {
  Card,
  Button,
  DialogTitle,
  TableMUI as Table,
} from '@src/components/sc-design-system';
import { EmptyContent } from './components/EmptyContent';

const ClientDepartments = ({ client, isClientLoading }) => {
  const [popoverAnchor, setPopoverAnchor] = React.useState<HTMLElement | null>(
    null,
  );
  const queryClient = useQueryClient();
  const [isAddDepartmentModalOpen, setIsAddDepartmentModalOpen] =
    useState(false);
  const [activeDepartment, setActiveDepartment] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { mutate: saveDepartments, isPending } = useMutation({
    mutationFn: async updates => {
      return api.clients.saveClient({
        id: client.id,
        departments: updates,
      });
    },
    onSuccess: ({ data: updatedClient }) => {
      queryClient.setQueryData(
        ['client', client.id],
        new Client(updatedClient),
      );
      if (!isClientLoading) {
        setActiveDepartment(null);
        setIsAddDepartmentModalOpen(false);
      }
    },
  });

  const submit = () => {
    const updatedDepartments = activeDepartment.id
      ? client.data?.departments?.map(d =>
          d.id === activeDepartment.id ? activeDepartment : d,
        )
      : [...(client.data?.departments ?? []), activeDepartment];
    saveDepartments(updatedDepartments);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
    setActiveDepartment(null);
  };

  const isPopoverOpen = Boolean(popoverAnchor);
  const popoverId = isPopoverOpen ? 'delete-button-popover' : undefined;

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Department',
        size: 500,
        maxSize: 750,
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        cell: ({ row }) => {
          const d = row.original;
          const canDelete =
            client.data?.members.filter(
              ({ department }) => department?.id === d.id,
            ).length < 1;
          return (
            <Box
              sx={{
                display: 'flex',
                gap: { sm: 1, md: 2 },
              }}
            >
              <IconButton
                onClick={() => {
                  setActiveDepartment(d);
                  setIsAddDepartmentModalOpen(true);
                }}
                disabled={isPending || isClientLoading}
              >
                <EditOutlinedIcon />
              </IconButton>
              <Tooltip
                title={
                  !canDelete
                    ? 'This department is currently associated with member(s) in your organization and cannot be removed'
                    : ''
                }
              >
                <span>
                  <IconButton
                    aria-describedby={popoverId}
                    aria-haspopup='true'
                    onClick={e => {
                      handlePopoverOpen(e);
                      setActiveDepartment(d);
                    }}
                    disabled={isPending || isClientLoading || !canDelete}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    [isClientLoading, isPending, client, popoverId],
  );

  return (
    <Card
      title='Departments'
      titleActions={
        <Button
          startIcon={<Add />}
          variant='text'
          onClick={() => {
            setIsAddDepartmentModalOpen(true);
          }}
          text='Add Department'
          disabled={isClientLoading}
          aria-label='Add Department'
        />
      }
    >
      {!client?.data?.departments?.length && <EmptyContent />}
      {Boolean(client?.data?.departments?.length) && (
        <>
          <Typography sx={{ marginBottom: '1.5em' }}>
            Create and manage departments within your organization. To delete a
            department, navigate to the members tab to remove all members within
            the department.
          </Typography>
          <Table
            data={client?.data?.departments}
            columns={columns}
            initialRowsPerPage={25}
          />
          <Popover
            id={popoverId}
            open={isPopoverOpen}
            anchorEl={popoverAnchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handlePopoverClose}
            slotProps={{
              paper: {
                sx: { padding: '1em' },
              },
            }}
          >
            <Typography variant='body2'>
              You have selected to delete {activeDepartment?.name}. Do you wish
              to continue?
            </Typography>
            <Stack
              direction='row'
              justifyContent='flex-end'
              spacing={1}
              sx={{ marginTop: 2 }}
            >
              <Button
                text='Cancel'
                variant='outlined'
                size='small'
                onClick={handlePopoverClose}
              />
              <Button
                text='Confirm'
                size='small'
                onClick={() => {
                  const filtered = client.data?.departments.filter(
                    ({ id }) => activeDepartment.id !== id,
                  );
                  saveDepartments(filtered);
                  handlePopoverClose();
                }}
              />
            </Stack>
          </Popover>
        </>
      )}

      <Box sx={{ textAlign: 'center', marginTop: 4 }}>
        <Button
          startIcon={<Add />}
          text={`Add ${client.departments?.length ? 'New ' : ''}Department`}
          onClick={() => {
            setIsAddDepartmentModalOpen(true);
          }}
          disabled={isClientLoading}
          aria-label='Add Department'
        />
      </Box>
      <Dialog
        open={isAddDepartmentModalOpen}
        onClose={() => {
          setIsAddDepartmentModalOpen(false);
          setActiveDepartment(null);
        }}
        fullScreen={fullScreen}
        maxWidth='sm'
      >
        <DialogTitle
          title='Add Department'
          buttonOnClick={() => {
            setIsAddDepartmentModalOpen(false);
            setActiveDepartment(null);
          }}
        />
        <DialogContent dividers>
          {/* TODO: text field theme */}
          <InputLabel
            style={{ fontSize: '14px', fontWeight: 600, marginBottom: '.5em' }}
            required
          >
            Department
          </InputLabel>
          <TextField
            label=''
            onChange={e => {
              setActiveDepartment({
                ...activeDepartment,
                name: e.target.value,
              });
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') submit();
            }}
            fullWidth
            value={activeDepartment?.name ?? ''}
          />
        </DialogContent>
        <DialogActions>
          {activeDepartment?.id && (
            <Button
              text='Cancel'
              variant='outlined'
              onClick={() => {
                setIsAddDepartmentModalOpen(false);
                setActiveDepartment(null);
              }}
            />
          )}
          <Button
            text={activeDepartment?.id ? 'Edit' : 'Add'}
            disabled={Boolean(!activeDepartment?.name) || isPending}
            loading={isPending}
            onClick={() => {
              submit();
            }}
          />
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export { ClientDepartments };
