import React, { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';
import * as useVerbiage from '@shared/lib/src/use-verbiage';
import { Button, Avatar } from '@src/components/sc-design-system';
import Contact from './components/Contact';
import ProgramResources from './components/ProgramResources';
import { useStore } from '@src/models/Store';
import { SessionDetailsViewModel } from '../SessionDetails/SessionDetailsViewModel';
import { useNavigate } from 'react-router';
import { CoachViewModal } from '@src/components/common/CoachViewModal';
import CoacheeBio from '../CoacheeBio';
import { api } from '@src/lib/client';
import { SessionDetailsModal } from '@src/components/common/SessionDetails/SessionDetailsModal';
import { IProgram, ISession } from './types';
import { ProgramDetails } from './components/ProgramDetails';
import { StakeholderManagerModal } from '@src/components/common/StakeholderManagerModal';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Link, Stack, Divider, Typography, Box, Badge } from '@mui/material';
import styled from '@emotion/styled';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  BookSession,
  Completed,
  Missed,
  Placeholder,
  Scheduled,
  Started,
} from '@src/components/common/SessionStatusButton';

const StackHideFirstDivider = styled(Stack)({
  margin: '18px 0 15px 0',
  'hr:first-of-type': { visibility: 'hidden', display: 'none' },
  'p:first-of-type': { margin: 0, minWidth: '80px' },
});

const CoachCardHeader: FC<{ program: IProgram }> = ({ program }) => {
  const store = useStore();

  return (
    <div className='coach-card-header'>
      <CoacheeBio
        coachee={Object.assign({}, program.coachee, {
          client_name: program.client,
          client_id: program.client_id,
          is_aida_active: program.is_aida_active,
          program_name: program.name,
          program_info: {
            id: program.id,
            is_active: program.is_active,
            start_date: program.start_date,
            end_date: program.end_date,
            type: program.type,
            coach_id: program?.coach?.id,
          },
          sessions_allocated: program.sessions_allocated,
        })}
        hideSurveyResponses={program.type === 'mentoring'}
      >
        <div
          style={{
            display: 'inline-flex',
            gap: 8,
            cursor: 'pointer',
            alignItems: 'center',
          }}
        >
          <Avatar
            srcId={program.coachee.id}
            alt={`${program.coachee.first_name} ${program.coachee.last_name}`}
            variant='interactive'
            sx={{ marginRight: '0.25em' }}
          />
          <Typography variant='h3' sx={{ cursor: 'pointer' }}>
            {program.coachee.first_name} {program.coachee.last_name}
          </Typography>
        </div>
      </CoacheeBio>
      <div className='program-details'>
        {program.client}: {program.name}
      </div>
      <ProgramMemberRow program={program} user={store.user} />
      <Program360sRow program={program} user={store.user} />
    </div>
  );
};

const Program360sRow = ({
  program,
  user,
}: {
  program: IProgram;
  user: any;
}) => {
  const navigate = useNavigate();
  const [showStakeholderManagerModal, setShowStakeholderManagerModal] =
    useState(false);
  if (
    (!program.self_assessment_enabled &&
      !program.stakeholder_assessment_enabled) ||
    program.type === 'mentoring'
  ) {
    return null;
  }

  return (
    <>
      <StakeholderManagerModal
        open={showStakeholderManagerModal}
        programId={program?.id}
        memberId={program.coachee.id}
        isActive={program.is_active}
        onCancel={() => setShowStakeholderManagerModal(false)}
      />
      <StackHideFirstDivider
        direction='row'
        alignItems='center'
        divider={<Divider orientation='vertical' flexItem />}
        spacing={2}
      >
        <Typography variant='body1' color='grey.700'>
          360s
        </Typography>
        {program?.self_assessment_enabled &&
          (program?.self_360_assessment_id ? (
            (window.location.pathname.includes('/my-programs') && (
              <Link
                variant='a1'
                component='button'
                onClick={() =>
                  navigate(
                    `/my-programs?program_id=${program.id}&survey_id=${program.self_360_assessment_id}&user_id=${user.id}`,
                  )
                }
              >
                Take Self-Assessment
              </Link>
            )) ||
            (window.location.pathname.includes('/my-coachees') && (
              <Stack flexDirection='row' alignItems='center'>
                <Typography variant='body1'>
                  Self-Assessment Not Submitted{' '}
                </Typography>
                <ErrorOutline sx={{ marginLeft: '7px' }} color='warning' />
              </Stack>
            ))
          ) : (
            <Stack flexDirection='row' alignItems='center'>
              <Typography variant='body1'>
                Self-Assessment Submitted{' '}
              </Typography>
              <CheckCircleOutlineIcon
                sx={{ marginLeft: '7px' }}
                color='success'
              />
            </Stack>
          ))}
        {Boolean(program?.stakeholder_assessment_enabled) &&
          ((window.location.pathname.includes('/my-programs') && (
            <Link
              variant='a1'
              onClick={() => navigate(`/my-programs/${program.id}`)}
              component='button'
            >
              {!program?.stakeholders?.length
                ? 'Add 360 Participants'
                : 'Manage Participants'}
            </Link>
          )) ||
            (window.location.pathname.includes('/my-coachees') && (
              <Link
                variant='a1'
                onClick={() => setShowStakeholderManagerModal(true)}
                component='button'
              >
                Feedback Requested (
                {
                  program?.stakeholders?.filter(s => s.survey_submission_id)
                    .length
                }
                /{program?.stakeholders?.length})
              </Link>
            )))}
        {program?.self_assessment_enabled &&
          !program?.self_360_assessment_id &&
          program?.stakeholder_assessment_enabled &&
          Boolean(
            program?.stakeholders?.filter(s => s.survey_submission_id).length,
          ) &&
          window.location.pathname.includes('/my-coachees') && (
            <Button
              size='small'
              variant='outlined'
              text='View Report'
              startIcon={<OpenInNewIcon />}
              onClick={() =>
                navigate(`/report/${program.coachee.id}/${program.id}`)
              }
            />
          )}
      </StackHideFirstDivider>
    </>
  );
};

function ProgramCoachRow({
  program,
  user,
  noCoachSelected,
  setShowCoachViewModal,
}: {
  program: IProgram;
  user: any;
  noCoachSelected: boolean;
  setShowCoachViewModal: (b: boolean) => void;
}) {
  const navigate = useNavigate();
  const [contactOpen, setContactOpen] = useState(false);
  const v = useVerbiage.init(program?.type === 'mentoring' ? ['isMentor'] : []);

  if (noCoachSelected && !program?.is_active) {
    return null;
  }

  return (
    <>
      {contactOpen && (
        <Contact
          onClose={() => setContactOpen(false)}
          senderFirstName={user.first_name}
          senderLastName={user.last_name}
          senderAddress={user.email}
          recipientFirstName={program?.coach?.first_name}
          recipientLastName={program?.coach?.last_name}
          recipientAddress={program?.coach?.email}
        />
      )}
      <StackHideFirstDivider
        direction='row'
        alignItems='center'
        divider={<Divider orientation='vertical' flexItem />}
        spacing={2}
      >
        <Typography variant='body1' color='grey.700'>
          {startCase(v('coach'))}
        </Typography>
        {program?.coach?.id && (
          <>
            <Avatar
              onClick={() => setShowCoachViewModal(true)}
              srcId={program?.coach.id}
              alt={`${program?.coach.first_name} ${program?.coach.last_name}`}
              variant='interactive'
            />
            <Link onClick={() => setShowCoachViewModal(true)} variant='a1'>
              {program?.coach.first_name} {program?.coach.last_name}
            </Link>
          </>
        )}
        {program?.coach?.id && (
          <Link
            variant='a1'
            onClick={() => setContactOpen(true)}
            component='button'
          >
            Contact {startCase(v('coach'))}
          </Link>
        )}
        {noCoachSelected && program?.is_active && (
          <>
            {`No ${v('coach')} selected  —`}
            <Link
              variant='a1'
              onClick={() =>
                navigate(
                  `/coaches?program_id=${program?.id}&program_type=${
                    program?.type || 'standard_coaching'
                  }`,
                )
              }
              component='button'
            >
              Select {startCase(v('coach'))}
            </Link>
          </>
        )}
      </StackHideFirstDivider>
    </>
  );
}

function ProgramMemberRow({ program, user }: { program: IProgram; user: any }) {
  const [contactOpen, setContactOpen] = useState(false);
  const v = useVerbiage.init(program?.type === 'mentoring' ? ['isMentor'] : []);

  return (
    <>
      {contactOpen && (
        <Contact
          onClose={() => setContactOpen(false)}
          senderFirstName={user.first_name}
          senderLastName={user.last_name}
          senderAddress={user.email}
          recipientFirstName={program?.coachee?.first_name}
          recipientLastName={program?.coachee?.last_name}
          recipientAddress={program?.coachee?.email}
        />
      )}
      {program?.coach?.id && (
        <StackHideFirstDivider
          direction='row'
          alignItems='center'
          divider={<Divider orientation='vertical' flexItem />}
          spacing={2}
        >
          <Typography variant='body1' color='grey.700'>
            {startCase(v('coachee'))}
          </Typography>
          <CoacheeBio
            coachee={Object.assign({}, program.coachee, {
              client_name: program.client,
              client_id: program.client_id,
              is_aida_active: program.is_aida_active,
              program_name: program.name,
              program_info: {
                id: program.id,
                is_active: program.is_active,
                start_date: program.start_date,
                end_date: program.end_date,
                type: program.type,
                coach_id: program?.coach?.id,
              },
              sessions_allocated: program.sessions_allocated,
            })}
            hideSurveyResponses={program.type === 'mentoring'}
          >
            <Link variant='a1' onClick={() => {}} component='button'>
              View {startCase(program.coachee.first_name)}'s Profile
            </Link>
          </CoacheeBio>
          <Link
            variant='a1'
            onClick={() => setContactOpen(true)}
            component='button'
          >
            Contact {startCase(program.coachee.first_name)}
          </Link>
        </StackHideFirstDivider>
      )}
    </>
  );
}

const CoacheeCardHeader: FC<{
  program: IProgram;
  user: any;
  noCoachSelected: boolean;
}> = ({ program, user, noCoachSelected }) => {
  const queryClient = useQueryClient();
  const [showCoachViewModal, setShowCoachViewModal] = useState(false);

  return (
    <div className='coachee-card-header'>
      {showCoachViewModal && (
        <CoachViewModal
          coach={program.coach}
          isBooking={false}
          bookingProgram={program}
          lastRerender={Date.now()}
          onClose={async () => {
            setShowCoachViewModal(false);
            queryClient.invalidateQueries({
              queryKey: ['user', user.id, 'programs', 'coachee'],
            });
            queryClient.invalidateQueries({
              queryKey: ['user', user.id, 'upNext', 'coachee'],
            });
          }}
        />
      )}
      <ProgramDetails program={program} />
      <ProgramCoachRow
        program={program}
        user={user}
        noCoachSelected={noCoachSelected}
        setShowCoachViewModal={setShowCoachViewModal}
      />
      <Program360sRow program={program} user={user} />
    </div>
  );
};

const UserProgramCardHeader: FC<{
  program: IProgram;
  scRole: string;
  noCoachSelected: boolean;
}> = ({ program, scRole, noCoachSelected }) => {
  const { user } = useStore();
  const [programResourcesOpen, setProgramResourcesOpen] = useState(false);

  return (
    <Stack direction='row' justifyContent='space-between'>
      {scRole === 'coach' ? (
        <CoachCardHeader program={program} />
      ) : (
        <CoacheeCardHeader
          program={program}
          user={user}
          noCoachSelected={noCoachSelected}
        />
      )}
      <div>
        {programResourcesOpen && (
          <ProgramResources
            onClose={() => setProgramResourcesOpen(false)}
            program={program}
          />
        )}
        <Button
          size='small'
          variant='outlined'
          color='primary'
          onClick={() => {
            setProgramResourcesOpen(true);
          }}
          text='Resources'
        />
      </div>
    </Stack>
  );
};

export const SessionItem: FC<{
  session: ISession;
  scRole: string;
  detailsOpen?: boolean;
  setSessionDetailsOpen?: Function;
  programType?: string;
}> = ({ session, scRole, detailsOpen, setSessionDetailsOpen, programType }) => {
  const { user } = useStore();

  const [sessionVM, setSessionVM] = useState(
    new SessionDetailsViewModel(user.id, scRole, session),
  );
  useEffect(() => {
    setSessionVM(new SessionDetailsViewModel(user.id, scRole, session));
  }, [user.id, scRole, session]);

  const date = dayjs(sessionVM.start_time);

  const SessionButton = {
    completed: Completed,
    missed: Missed,
    scheduled: Scheduled,
    started: Started,
  }[sessionVM.current_status];

  return (
    <>
      <SessionDetailsModal
        open={Boolean(detailsOpen)}
        session={sessionVM}
        userRole={scRole}
        onClose={() => {
          setSessionDetailsOpen(false);
        }}
        programType={programType}
      />
      <Stack textAlign='center' spacing={1} width='100%'>
        {Boolean(SessionButton) && (
          <Badge
            badgeContent={<PriorityHighIcon sx={{ fontSize: '1em' }} />}
            color='warning'
            invisible={
              !(
                sessionVM.needsRating ||
                (sessionVM.current_status === 'missed' &&
                  !sessionVM.missedFeedback)
              )
            }
            sx={{
              '.MuiBadge-badge': {
                height: '22px',
                width: '22px',
                borderRadius: '50%',
              },
            }}
          >
            <SessionButton
              onClick={() => {
                setSessionDetailsOpen(sessionVM.id);
              }}
            />
          </Badge>
        )}
        <Typography variant='body1' color='grey.700'>
          {date.format('M/D')} at {date.format('h:mm a')}
        </Typography>
      </Stack>
    </>
  );
};

export const UserProgramCard: FC<{
  program: IProgram;
  user: any;
  scRole: string;
}> = ({ program, user, scRole }) => {
  const queryClient = useQueryClient();
  const [sessionDetailsOpen, setSessionDetailsOpen] = useState();
  const [showCoachViewModal, setShowCoachViewModal] = useState(false);
  const [sessionsAvailable, setSessionsAvailable] = useState(false);
  let noCoachSelected;
  const participantRole = scRole === 'coach' ? 'coachee' : 'coach';
  if (scRole === 'coachee' && !program[participantRole]) {
    noCoachSelected = true;
  }

  const { data } = useQuery({
    queryKey: ['program', program.id, 'sessions-available'],
    queryFn: async () =>
      api.sessions.checkSessionsAvailableForProgram(program.id),
    select: ({ data }) => data,
    throwOnError: false,
    retry: false,
  });

  useEffect(() => {
    if (data) {
      setSessionsAvailable(true);
    }
  }, [data]);

  return (
    <Box sx={{ padding: '1em 0' }}>
      <UserProgramCardHeader
        program={program}
        scRole={scRole}
        noCoachSelected={noCoachSelected}
      />
      {showCoachViewModal && program.coach && (
        <CoachViewModal
          coach={program.coach}
          isBooking={true}
          bookingProgram={program}
          lastRerender={Date.now()}
          onClose={async () => {
            setShowCoachViewModal(false);
            queryClient.invalidateQueries({
              queryKey: ['user', user.id, 'programs', 'coachee'],
            });
            queryClient.invalidateQueries({
              queryKey: ['user', user.id, 'upNext', 'coachee'],
            });
          }}
        />
      )}
      {Boolean(program.sessions?.length) && (
        <Stack
          direction='row'
          gap={1.5}
          width='100%'
          paddingTop='1em'
          overflow='auto'
        >
          {program.sessions.map((session, idx) => {
            const isBookable =
              sessionsAvailable &&
              program.is_active &&
              (program.sessions[idx - 1] || idx === 0) &&
              scRole === 'coachee';

            if (!session) {
              return (
                <Stack width='100%' key={session?.id || idx}>
                  {isBookable && !noCoachSelected ? (
                    <BookSession onClick={() => setShowCoachViewModal(true)} />
                  ) : (
                    <Placeholder text={`${idx + 1}`} />
                  )}
                </Stack>
              );
            }

            return (
              <SessionItem
                key={session?.id || idx}
                session={session}
                scRole={scRole}
                detailsOpen={Boolean(sessionDetailsOpen === session?.id)}
                setSessionDetailsOpen={setSessionDetailsOpen}
                programType={program?.type}
              />
            );
          })}
        </Stack>
      )}
    </Box>
  );
};
