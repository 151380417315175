import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import _startCase from 'lodash/startCase';
import SessionDetails from './SessionDetails';
import { DialogTitle, ConfirmDialog } from '@src/components/sc-design-system';
import dayjs from 'dayjs';
import {
  Typography,
  Dialog,
  DialogContent,
  Stack,
  Divider,
} from '@mui/material';
import { useFullScreen } from '@src/hooks/useFullScreen';

export interface SessionDetailsModalIF {
  session: any;
  userRole: string;
  onClose: Function;
  open: boolean;
  programType?: string;
}

const SessionDetailsModal: FC<SessionDetailsModalIF> = observer(
  ({ session, userRole, onClose, open, programType }) => {
    const [sectionsUpdated, setSectionsUpdated] = useState(new Object());
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const { fullScreen } = useFullScreen();

    const handleClose = () => {
      if (Object.keys(sectionsUpdated).length) {
        setConfirmDialogOpen(true);
      } else {
        onClose();
      }
    };

    return (
      <>
        <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
          <DialogTitle
            title={
              <Stack spacing={1}>
                <Typography variant='h3'>Session Reflection</Typography>
                <Stack
                  direction='row'
                  spacing={2}
                  divider={<Divider orientation='vertical' flexItem />}
                >
                  <Typography
                    variant='body2'
                    sx={{
                      fontWeight: 600,
                      color:
                        session.current_status === 'missed'
                          ? 'error'
                          : 'success.main',
                    }}
                  >
                    {_startCase(session.current_status)}
                  </Typography>
                  <Typography variant='body2'>
                    {dayjs(session?.start_time).format('LLL')}
                  </Typography>
                </Stack>
              </Stack>
            }
            buttonOnClick={() => handleClose()}
          />
          <DialogContent>
            <SessionDetails
              session={session}
              userRole={userRole}
              sectionsUpdated={sectionsUpdated}
              setSectionsUpdated={setSectionsUpdated}
              programType={programType}
            />
          </DialogContent>
        </Dialog>
        {confirmDialogOpen && (
          <ConfirmDialog
            open={confirmDialogOpen}
            setOpenModal={setConfirmDialogOpen}
            title='Close Without Saving'
            body='Are you sure you want to close this session reflection? Unsaved changes will not be saved.'
            cancelText='Close'
            onCancel={onClose}
            buttonProps={{ text: 'Continue Editing' }}
          />
        )}
      </>
    );
  },
);
export { SessionDetailsModal };
