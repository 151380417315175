import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Space } from 'antd';
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Add from '@mui/icons-material/Add';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';
import { QuestionListItem } from './QuestionListItem';
import SurveyQuestion from '@src/models/SurveyQuestion';
import { Button } from '@src/components/sc-design-system';

import './styles.less';

const QuestionSortableList: FC<{
  surveyQuestion: SurveyQuestion;
  isSurveyEditable: boolean;
}> = observer(({ surveyQuestion, isSurveyEditable }) => {
  const [lastSortedItem, setLastSortedItem] = useState<number>();
  return (
    <div className='sq-option-list'>
      <ul>
        <SortableList
          onSortEnd={(oldIndex: number, newIndex: number) => {
            if (oldIndex !== newIndex) {
              surveyQuestion.options = arrayMoveImmutable(
                surveyQuestion.options,
                oldIndex,
                newIndex,
              );
              setLastSortedItem(newIndex);
              setTimeout(() => {
                setLastSortedItem(-1);
              }, 1000);
            }
          }}
          className='list'
          draggedItemClassName='sq-list-dragging'
          lockAxis='y'
          allowDrag={isSurveyEditable}
        >
          {surveyQuestion.options.map((item, idx) => (
            <SortableItem key={item.id}>
              <li className={`${lastSortedItem === idx ? 'last-sorted' : ''}`}>
                <Space direction='horizontal' align='center'>
                  {isSurveyEditable && (
                    <SortableKnob>
                      <DragIndicatorIcon
                        fontSize='small'
                        sx={{ marginTop: 1 }}
                      />
                    </SortableKnob>
                  )}
                  <QuestionListItem
                    item={item}
                    onChange={updated => {
                      surveyQuestion.options = surveyQuestion.options.map(o => {
                        if (updated.id === o.id) {
                          return updated;
                        }
                        return o;
                      });
                    }}
                    isSurveyEditable={isSurveyEditable}
                  />
                  {isSurveyEditable && (
                    <StyledIconButton
                      onClick={() => surveyQuestion.deleteOption(item.id)}
                      aria-label='Delete option'
                    >
                      <DeleteOutlinedIcon color='error' fontSize='small' />
                    </StyledIconButton>
                  )}
                </Space>
              </li>
            </SortableItem>
          ))}
        </SortableList>
      </ul>
      {isSurveyEditable && (
        <Button
          text='Add Option'
          startIcon={<Add />}
          variant='text'
          size='small'
          onClick={() => surveyQuestion.addOption()}
        />
      )}
    </div>
  );
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&.MuiIconButton-root': {
    '.MuiSvgIcon-root': {
      color: theme.palette.error.light,
    },
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      '.MuiSvgIcon-root': {
        color: theme.palette.error.main,
      },
    },
    '&:active': {
      backgroundColor: theme.palette.background.default,
      '.MuiSvgIcon-root': {
        color: theme.palette.error.dark,
      },
    },
  },
}));

export { QuestionSortableList };
