import React, { FC } from 'react';
import { ReactComponent as Logo } from '@shared/theme/src/assets/logomark.svg';

const logoSizeDefinitions = {
  small: '24px',
  default: '30px',
  large: '38px',
};

export const SCLogo: FC<{
  showText?: boolean;
  size?: 'small' | 'default' | 'large' | number;
  className?: string;
  logoClassName?: string;
  textClassName?: string;
  clickable?: boolean;
  uppercase?: boolean;
}> = ({
  showText,
  size = 'default',
  className,
  logoClassName,
  textClassName,
  clickable = false,
  uppercase = true,
}) => {
  const logoSize =
    typeof size === 'string'
      ? logoSizeDefinitions[size] || logoSizeDefinitions['default']
      : `${size}px`;
  const fontSize = `calc(${logoSize} * .63)`;
  return (
    <div className={`sc-logo ${className || ''}`}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          className={`sc-logo-image ${logoClassName || ''}`}
          style={{
            lineHeight: 1,
            width: logoSize,
            height: logoSize,
          }}
        >
          <Logo
            style={{
              width: logoSize,
              height: logoSize,
            }}
            aria-label='SkillCycle'
          />
        </div>
        {showText && (
          <div
            style={{
              paddingTop: `calc(${logoSize} * .2)`,
              paddingLeft: `calc(${logoSize} * .25)`,
              margin: 0,
              lineHeight: 1,
              height: logoSize,
              fontSize: fontSize,
              textTransform: uppercase ? 'uppercase' : 'none',
              fontWeight: '200',
              letterSpacing: '.1em',
              cursor: clickable ? 'pointer' : 'default',
              userSelect: 'none',
            }}
            className={`sc-logo-text ${textClassName || ''}`}
          >
            SkillCycle
          </div>
        )}
      </div>
    </div>
  );
};
