import React, { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import usStates from '@src/lib/statics/us-states';
import caProvinces from '@src/lib/statics/ca-provinces';
import countries from '@src/lib/statics/countries';
import { Autocomplete, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { TextField } from '@src/components/sc-design-system';

interface AddressFormGridProps {
  currentAddressType?: Address['type'];
  isSubmitting?: boolean;
}

const AddressFormGrid: FC<AddressFormGridProps> = ({
  currentAddressType,
  isSubmitting,
}) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors, touchedFields },
  } = useFormContext();

  const currentIndex = useMemo(
    () => (currentAddressType === 'billing' ? 1 : 0),
    [currentAddressType],
  );

  const useMailingForAll = watch('addresses.0.type') === 'all';

  const countryOptions = useMemo(
    () => Object.entries(countries).map(([value, label]) => ({ value, label })),
    [],
  );

  const stateOptions = useMemo(() => {
    const states = Object.entries(usStates).map(([value, label]) => ({
      value,
      label,
    }));
    const provinces = Object.entries(caProvinces).map(([value, label]) => ({
      value,
      label,
    }));
    return [...states, ...provinces].sort();
  }, []);

  const handleCheckbox = e => {
    if (e.target.checked) {
      setValue('addresses.0.type', 'all', {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue(
        'addresses.1',
        {
          ...watch('addresses.0'),
          type: 'billing',
        },
        {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        },
      );
    } else {
      setValue('addresses.0.type', 'mailing', {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue(
        'addresses.1',
        { type: 'billing' },
        {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        },
      );
    }
  };

  return (
    <>
      {currentAddressType === 'billing' && (
        <FormControlLabel
          sx={{ marginBottom: '1em' }}
          control={
            <Checkbox onChange={handleCheckbox} checked={useMailingForAll} />
          }
          label='Use mailing address'
          disabled={isSubmitting}
        />
      )}
      <Grid container columns={1} direction='column' rowSpacing={2}>
        <Grid item>
          <Controller
            name={`addresses.${currentIndex}.street_address_1`}
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  label='Street Address 1'
                  fullWidth
                  {...field}
                  id={field.name}
                  innerRef={field.ref}
                  disabled={
                    currentAddressType === 'billing' && useMailingForAll
                  }
                  error={Boolean(
                    touchedFields?.addresses?.[currentIndex]
                      ?.street_address_1 &&
                      errors?.addresses?.[currentIndex]?.street_address_1
                        ?.message,
                  )}
                  errorMessage={
                    touchedFields?.addresses?.[currentIndex]
                      ?.street_address_1 &&
                    errors?.addresses?.[currentIndex]?.street_address_1?.message
                  }
                />
              );
            }}
          />
        </Grid>
        <Grid item>
          <Controller
            name={`addresses.${currentIndex}.street_address_2`}
            control={control}
            render={({ field }) => (
              <TextField
                label='Street Address 2'
                fullWidth
                {...field}
                id={field.name}
                innerRef={field.ref}
                disabled={currentAddressType === 'billing' && useMailingForAll}
              />
            )}
          />
        </Grid>
        <Grid
          item
          container
          direction={{ sm: 'column', md: 'row' }}
          columnSpacing={2}
          rowSpacing={2}
          sm={1}
          md={3}
        >
          <Grid item sm={4} sx={{ width: { xs: '100%' } }}>
            <Controller
              name={`addresses.${currentIndex}.city`}
              control={control}
              render={({ field }) => (
                <TextField
                  label='City'
                  fullWidth
                  {...field}
                  id={field.name}
                  innerRef={field.ref}
                  disabled={
                    currentAddressType === 'billing' && useMailingForAll
                  }
                  error={Boolean(
                    touchedFields?.addresses?.[currentIndex]?.city &&
                      errors?.addresses?.[currentIndex]?.city?.message,
                  )}
                  errorMessage={
                    touchedFields?.addresses?.[currentIndex]?.city &&
                    errors?.addresses?.[currentIndex]?.city?.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item sm={4} sx={{ width: { xs: '100%' } }}>
            <Controller
              name={`addresses.${currentIndex}.state`}
              control={control}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...field}
                    fullWidth
                    disabled={
                      currentAddressType === 'billing' && useMailingForAll
                    }
                    value={
                      field.value
                        ? stateOptions.find(option => {
                            return field.value === option.value;
                          }) ?? null
                        : null
                    }
                    getOptionLabel={option => option.label ?? ''}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                        inputRef={field.ref}
                        label='State'
                        placeholder='Select state'
                        error={Boolean(
                          touchedFields?.addresses?.[currentIndex]?.state &&
                            errors?.addresses?.[currentIndex]?.state?.message,
                        )}
                        errorMessage={
                          touchedFields?.addresses?.[currentIndex]?.state &&
                          errors?.addresses?.[currentIndex]?.state?.message
                        }
                      />
                    )}
                    options={stateOptions}
                    onChange={(e, option) => {
                      field.onChange(option?.value ?? null);
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item sm={4} sx={{ width: { xs: '100%' } }}>
            <Controller
              name={`addresses.${currentIndex}.zip`}
              control={control}
              render={({ field }) => (
                <TextField
                  label='Zip Code'
                  fullWidth
                  {...field}
                  id={field.name}
                  innerRef={field.ref}
                  disabled={
                    currentAddressType === 'billing' && useMailingForAll
                  }
                  error={Boolean(
                    touchedFields?.addresses?.[currentIndex]?.zip &&
                      errors?.addresses?.[currentIndex]?.zip?.message,
                  )}
                  errorMessage={
                    touchedFields?.addresses?.[currentIndex]?.zip &&
                    errors?.addresses?.[currentIndex]?.zip?.message
                  }
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Controller
            name={`addresses.${currentIndex}.country`}
            control={control}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  fullWidth
                  disabled={
                    currentAddressType === 'billing' && useMailingForAll
                  }
                  value={
                    field.value
                      ? countryOptions.find(option => {
                          return field.value === option.value;
                        }) ?? null
                      : null
                  }
                  getOptionLabel={option => option.label ?? ''}
                  renderInput={params => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                      inputRef={field.ref}
                      label='Country'
                      placeholder='Select country'
                      error={Boolean(
                        touchedFields?.addresses?.[currentIndex]?.country &&
                          errors?.addresses?.[currentIndex]?.country?.message,
                      )}
                      errorMessage={
                        touchedFields?.addresses?.[currentIndex]?.country &&
                        errors?.addresses?.[currentIndex]?.country?.message
                      }
                    />
                  )}
                  options={countryOptions}
                  onChange={(_, option) => {
                    field.onChange(option?.value ?? null);
                  }}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      {(currentAddressType === 'mailing' || currentAddressType === 'all') && (
        <FormControlLabel
          sx={{ marginTop: '1em' }}
          control={
            <Checkbox
              onChange={handleCheckbox}
              checked={useMailingForAll}
              disabled={isSubmitting}
            />
          }
          label='Use mailing address for billing address'
        />
      )}
    </>
  );
};

export { AddressFormGrid };
