import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Box, Stack, Typography, IconButton, Divider } from '@mui/material';
import { TextField, Button } from '@src/components/sc-design-system';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { useUser } from '@src/hooks/useUser';

interface NotesTabProps {
  form: UseFormReturn<SOWFormDefaultValues, undefined>;
}

const NotesTab: FC<NotesTabProps> = ({ form }) => {
  const { user } = useUser();
  const [currentNote, setCurrentNote] = useState('');
  const { control } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'notes',
  });

  return (
    <>
      <Stack spacing={2} divider={<Divider sx={{ margin: '0 !important' }} />}>
        {fields.map((item, i) => (
          <Stack key={item.id}>
            <Box sx={{ backgroundColor: 'grey.50', padding: '1em' }}>
              <Stack direction='row' alignItems='center'>
                <Typography variant='body1m'>{item.author.name}</Typography>
                <Typography
                  variant='body2'
                  color='grey.500'
                  sx={{ marginLeft: '.5em' }}
                >
                  {dayjs(item.timestamp).format('lll')}
                </Typography>
                <IconButton
                  onClick={() => remove(i)}
                  sx={{ marginLeft: 'auto' }}
                >
                  <CancelOutlinedIcon color='error' fontSize='small' />
                </IconButton>
              </Stack>
            </Box>
            <Typography
              variant='body2'
              sx={{
                whiteSpace: 'pre-line',
                padding: '1em',
              }}
            >
              {item.message}
            </Typography>
          </Stack>
        ))}
      </Stack>
      {Boolean(fields?.length) && <Divider sx={{ marginTop: 0 }} />}
      <TextField
        id='notes'
        multiline
        rows={4}
        value={currentNote}
        onChange={e => setCurrentNote(e.target.value)}
        placeholder='SOW notes'
      />
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1em' }}
      >
        <Button
          text='Add Note'
          onClick={() => {
            append({
              author: {
                id: user.id,
                name: `${user.first_name} ${user.last_name}`,
              },
              timestamp: Date.now(),
              message: currentNote,
            });
            setCurrentNote('');
          }}
          disabled={!currentNote}
          sx={{ width: 'fit-content', marginLeft: 'auto' }}
        />
      </Box>
    </>
  );
};

export { NotesTab };
