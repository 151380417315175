import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { DialogTitle, Button } from '@src/components/sc-design-system';
import { useFullScreen } from '@src/hooks/useFullScreen';

interface TermsModalProps {
  title: string;
  articleId: string;
  onClose(): void;
}

const TermsModal: FC<TermsModalProps> = ({ title, articleId, onClose }) => {
  const { fullScreen } = useFullScreen();
  const { data: html } = useQuery({
    queryKey: ['terms', articleId],
    queryFn: async () => {
      return fetch(`https://app.termageddon.com/api/policy/${articleId}`).then(
        res => res.text(),
      );
    },
    enabled: !!articleId,
  });

  return (
    <Dialog open onClose={onClose} fullScreen={fullScreen}>
      <DialogTitle buttonOnClick={onClose} title={title} />
      <DialogContent>
        {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
      </DialogContent>
      <DialogActions>
        <Button text='OK' onClick={onClose} formatText={false} />
      </DialogActions>
    </Dialog>
  );
};

export { TermsModal };
