import { useQuery } from '@tanstack/react-query';

/**
 * In certain cases, we can't use the Avatar component with an external URL as the src prop.
 * This hook is a workaround to fetch the image as a blob and convert it to a base64 string.
 */
const useLogoBlob = (clientId: string) => {
  const { data: avatarSrc } = useQuery({
    queryKey: ['avatar', clientId],
    queryFn: async () => {
      return `${
        process.env.ASSETS_ROOT
      }/${clientId}/avatar.png?cache=${Date.now()}`;
    },
  });

  const { data: logoBlob, ...rest } = useQuery({
    queryKey: ['logoBlob', clientId],
    queryFn: async () => {
      const response = await fetch(avatarSrc);
      if (!response.ok) {
        throw new Error('Could not retrieve logo');
      }
      const blob = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((res, reject) => {
        reader.onloadend = () => {
          res(reader.result);
        };
        reader.onerror = reject;
      });
    },
    enabled: !!avatarSrc,
  });

  return {
    logoBlob,
    ...rest,
  };
};

export { useLogoBlob };
