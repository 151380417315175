import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useStore } from '@src/models/Store';
import { useEffect, useMemo } from 'react';
import { api } from '@src/lib/client';
import { useNavigation } from '@src/navigation';
import { useQueryParams } from '@src/utils';

const INTAKE_SURVEY_ID = '3636f28e-f057-4697-8cb4-4aa957131202';

const canSkip = ['development', 'uat'].includes(process.env.NODE_ENV);

let dismissed = false;

const useIntakeSurvey = () => {
  const { user, loading } = useStore();
  const query = useQueryParams();
  const queryClient = useQueryClient();
  const navigation = useNavigation();

  const {
    data: answers,
    isError,
    isLoading: isLoadingSubmission,
  } = useQuery({
    queryKey: ['user', user?.id, 'answers'],
    queryFn: async () =>
      api.surveys.getSurveySubmission(user?.id, INTAKE_SURVEY_ID),
    select: ({ data }) => data,
    enabled: !!user?.id,
    staleTime: Infinity,
  });

  const needsSurvey = useMemo(() => {
    if (isLoadingSubmission || loading || !user?.id) return false;
    if (isError) return true;
    return answers ? Boolean(!answers?.results?.length) : true;
  }, [answers, isError, isLoadingSubmission, loading, user?.id]);

  useEffect(() => {
    if (needsSurvey && !dismissed) {
      query.set('survey_id', INTAKE_SURVEY_ID);
      query.set('user_id', user.id);
      navigation.replace(`${navigation.location.pathname}?${query}`, {
        allowDraft: false,
      });
      if (canSkip) {
        dismissed = true;
      }
    }
  }, [needsSurvey, navigation, query, user]);

  return {
    onSubmitIntake: () => {
      queryClient.invalidateQueries({
        queryKey: ['user', user?.id, 'answers'],
      });
    },
  };
};

export { useIntakeSurvey };
