import { useStore } from '@src/models/Store';
import { useLocation } from 'react-router';
import { ActiveClientKey, useActiveClient } from './useActiveClient';

const SHARED_ROUTES = [
  '/my-programs',
  '/performance-and-growth',
  '/sessions',
  '/meetings',
  '/evaluation-report',
  '/feedback-report',
  '/aida',
];
const COACHEE_ROUTES = ['/coaches'];
const CLIENT_ADMIN_ROUTES = ['/my-business', '/program', '/insights'];
const MANAGER_AND_ADMIN_ROUTES = ['/profile'];
const MANAGER_ROUTES = ['/my-team', '/team'];
const COACH_MENTOR_ROUTES = ['/my-coachees', '/my-mentees', '/report'];

const useRedirect = () => {
  const { user } = useStore();
  const { pathname } = useLocation();
  const { activeClientMeta } = useActiveClient({
    queryKey: ActiveClientKey.profile_active_client_id,
  });

  const { is_admin: isSCAdmin, isCoachOrMentor } = user;

  if (isSCAdmin) {
    return false;
  }

  if (SHARED_ROUTES.some(r => pathname.startsWith(r))) {
    return false;
  }

  if (CLIENT_ADMIN_ROUTES.some(r => pathname.startsWith(r))) {
    const isClientAdmin = activeClientMeta?.userIsClientAdmin;
    return isSCAdmin ? false : !isClientAdmin;
  }

  if (MANAGER_ROUTES.some(r => pathname.startsWith(r))) {
    const isManager = activeClientMeta?.hasDirectReports;
    return !isManager;
  }

  if (MANAGER_AND_ADMIN_ROUTES.some(r => pathname.startsWith(r))) {
    const isClientAdmin = activeClientMeta?.userIsClientAdmin;
    const isManager = activeClientMeta?.hasDirectReports;
    return !isManager && !isClientAdmin;
  }

  if (COACH_MENTOR_ROUTES.some(r => pathname.startsWith(r))) {
    return !isCoachOrMentor;
  }

  if (COACHEE_ROUTES.some(r => pathname.startsWith(r))) {
    return false;
  }

  return true;
};

export { useRedirect };
