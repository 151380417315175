import React, { FC, useMemo, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Tabs, Tab } from '@mui/material';
import {
  DialogTitle,
  Button,
  useToast,
} from '@src/components/sc-design-system';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import { useFullScreen } from '@src/hooks/useFullScreen';
import { ClientType } from '@shared/schemas';
import { AddressFormGrid } from '@src/components/common/AddressFormGrid';
import { ClientDepartments } from './ClientDepartments';
import { ClientGeneralFields } from './ClientGeneralFields';
import Client from '@src/models/Client';
import { clientSchema, useSchemaResolver } from './schema';

interface ClientEditorModalProps {
  onClose: Function;
  client?: Client;
  onSave: Function;
}

const ClientEditorModal: FC<ClientEditorModalProps> = ({
  onClose,
  client,
  onSave,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const { fullScreen } = useFullScreen();
  const toast = useToast();
  const tabs = useMemo(() => {
    return [
      { label: 'General', ...a11yProps(0) },
      { label: 'Mailing address', ...a11yProps(1) },
      { label: 'Billing address', ...a11yProps(2) },
      ...(!client?.hris_enabled
        ? [{ label: 'Departments', ...a11yProps(3) }]
        : []),
    ];
  }, [client]);

  const defaultValues = useMemo(
    () => ({
      id: client?.id,
      name: client?.name ?? '',
      email_domain: client?.email_domain ?? '',
      website: client?.website ?? '',
      salesforce_id: client?.salesforce_id ?? '',
      default_sow_id: client?.default_sow_id ?? '',
      addresses: client?.addresses?.map(a => a.data) || [
        { type: 'mailing' },
        { type: 'billing' },
      ],
      departments: client?.departments,
      hris_enabled: client?.hris_enabled,
      aida_enabled: client?.aida_enabled,
    }),
    [client],
  );

  const form = useForm({
    defaultValues,
    mode: 'all',
    resolver: useSchemaResolver(clientSchema),
  });

  const handleClose = () => {
    form.reset(defaultValues);
    onClose();
  };

  const { mutateAsync: saveClient, isPending } = useMutation({
    mutationFn: async (data: ClientType) => {
      await api.clients.saveClient(data);
    },
    onSuccess() {
      toast.success('Client saved');
      onSave();
      handleClose();
    },
    onError() {
      toast.error('Unable to save client');
    },
  });

  const onSubmit = async (data: any) => {
    await saveClient({
      ...data,
      addresses: data.addresses.map(({ id, ...a }) => a),
      departments: data.departments?.filter(({ name }) => !!name),
    });
  };

  return (
    <Dialog open onClose={handleClose} fullScreen={fullScreen}>
      <DialogTitle
        title={client?.name ?? 'New Client'}
        buttonOnClick={handleClose}
      />
      <DialogContent>
        <Tabs
          value={activeTab}
          onChange={(_, newTab) => {
            setActiveTab(newTab);
          }}
          sx={{ marginBottom: '1em' }}
        >
          {tabs.map(tab => (
            <Tab key={tab.label} label={tab.label} />
          ))}
        </Tabs>
        <FormProvider {...form}>
          <form id='client-editor-form' onSubmit={form.handleSubmit(onSubmit)}>
            <TabPanel value={activeTab} index={0}>
              <ClientGeneralFields client={client} />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <AddressFormGrid
                currentAddressType='mailing'
                isSubmitting={isPending}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <AddressFormGrid
                currentAddressType='billing'
                isSubmitting={isPending}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={3}>
              <ClientDepartments />
            </TabPanel>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => onClose()} text='Close' />
        <Button
          disabled={!form.formState?.isValid || isPending}
          loading={isPending}
          type='submit'
          form='client-editor-form'
          text='Save'
        />
      </DialogActions>
    </Dialog>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export { ClientEditorModal };
