import React from 'react';
import { Chip } from '@mui/material';

interface GoalWeightIndicatorProps {
  weight?: number;
  onClick: Function;
  isEditable?: boolean;
}

export default function GoalWeightIndicator({
  weight,
  onClick,
  isEditable = true,
}: GoalWeightIndicatorProps) {
  if (weight == null) {
    return null;
  }

  return (
    <Chip
      label={`${weight}% Weight`}
      onClick={() => {
        isEditable && onClick();
      }}
      color='info'
    />
  );
}
