import React, { FC } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { Card, useBreadcrumbs } from '@src/components/sc-design-system';
import { useNavigate } from 'react-router';
import User from '@src/models/User';

interface FinalPerformanceReviewProps {
  profileUser: User;
  clientId: string;
  performanceCycleId: string;
}

const FinalPerformanceReview: FC<FinalPerformanceReviewProps> = ({
  profileUser,
  clientId,
  performanceCycleId,
}) => {
  const navigate = useNavigate();
  const { appendBreadcrumb } = useBreadcrumbs();
  return (
    <Stack spacing={1} gap={2} sx={{ marginBottom: '1.5em' }}>
      <Typography variant='h3'>Final performance review</Typography>
      <Card
        elevation={5}
        variant='elevation'
        sx={{ margin: '.75em 0', width: { md: '50%' } }}
      >
        <Typography variant='h4' sx={{ marginBottom: '1em' }}>
          View your report
        </Typography>
        <Typography sx={{ marginBottom: '1em' }}>
          Your report provides a comprehensive overview of your performance
          based on collective feedback.
        </Typography>
        <Link
          sx={{ width: 'fit-content' }}
          onClick={() => {
            const path = `/evaluation-report?clientId=${clientId}&performanceCycleId=${performanceCycleId}&userId=${
              profileUser.id
            }&name=${encodeURIComponent(
              `${profileUser.first_name} ${profileUser.last_name}`,
            )}`;
            appendBreadcrumb({
              path,
              label: `Report for ${profileUser.first_name} ${profileUser.last_name}`,
            });
            navigate(path, {
              state: {
                employeeName: `${profileUser.first_name} ${profileUser.last_name}`,
              },
            });
          }}
        >
          View
        </Link>
      </Card>
    </Stack>
  );
};

export { FinalPerformanceReview };
