import React from 'react';
import { Chip, ChipProps } from '@mui/material';

import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import LinkIcon from '@mui/icons-material/Link';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

type Types = {
  [K in ResourceTypes]: {
    icon: React.ReactElement;
    color: ChipProps['color'];
    label?: string;
  };
};

const types: Types = {
  embed: {
    icon: <PlayArrowOutlinedIcon fontSize='small' />,
    color: 'primary',
    label: 'Video',
  },
  link: { icon: <LinkIcon fontSize='small' />, color: 'secondary' },
  document: {
    icon: <ArticleOutlinedIcon fontSize='small' />,
    color: 'tertiary',
  },
};

interface ResourceTypeChipProps {
  type: Resource['type'];
}

function ResourceTypeChip({ type }: ResourceTypeChipProps) {
  const { icon, color, label } = types[type];
  return (
    <Chip
      className='shape-rounded-square'
      icon={icon}
      label={label || type}
      color={color}
      sx={{ textTransform: 'capitalize' }}
    />
  );
}

export { ResourceTypeChip };
