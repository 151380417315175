import React, { FC, useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from '@emotion/styled';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, DialogTitle } from '@src/components/sc-design-system';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { RecipientsTable } from './components/RecipientsTable';

interface RemindRecipientsStepProps {
  collection?: any;
  handleClose: Function;
  clientId: string;
  handleStepChange: Function;
}

const RemindRecipientsStep: FC<RemindRecipientsStepProps> = ({
  collection,
  handleClose,
  clientId,
  handleStepChange,
}) => {
  const { setValue, trigger: validate, watch } = useFormContext();
  const newRecipients = watch('recipients');
  const [departmentFilter, setDepartmentFilter] = useState('all');
  const [searchKey, setSearchKey] = useState('');
  const [selected, setSelected] = useState(newRecipients);
  const [updatePage, setUpdatePage] = useState(Date.now());

  const { useGetClient } = useClientQueries(clientId);
  const { data: client } = useGetClient();

  const filteredRecipients = collection?.recipients
    ?.filter(m => {
      const nameFilterMatch = searchKey
        ? m.first_name
            .toLocaleLowerCase()
            .includes(searchKey.toLocaleLowerCase()) ||
          m.last_name
            .toLocaleLowerCase()
            .includes(searchKey.toLocaleLowerCase()) ||
          m.email.toLowerCase().includes(searchKey.toLocaleLowerCase())
        : true;

      const departmentFilterMatch =
        departmentFilter === 'all'
          ? true
          : departmentFilter === 'none'
          ? !m.department?.id
          : m.department?.id === departmentFilter;

      return nameFilterMatch && departmentFilterMatch;
    })
    .sort((a, b) => (a.first_name > b.first_name ? 1 : -1));

  const selectableRecipients = useMemo(
    () => filteredRecipients?.filter(m => !m.submission_id && !m.send_error),
    [filteredRecipients],
  );

  const onClickSubmit = () => {
    setValue(
      'recipients',
      selected.map(({ department, ...r }) => r),
    );
    validate();
    handleStepChange();
  };

  return (
    <>
      <DialogTitle
        title='Remind Recipients'
        buttonOnClick={() => handleClose()}
      />
      <DialogContent>
        <Stack spacing={4}>
          <Stack spacing={0} sx={{ width: '100%' }} direction='row'>
            <StyledSelect
              value={departmentFilter}
              onChange={e => {
                setUpdatePage(Date.now());
                setDepartmentFilter(e.target.value as string);
              }}
              IconComponent={ExpandMoreIcon}
            >
              <MenuItem key='all' value='all'>
                All departments
              </MenuItem>
              {client?.departments
                ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                ?.map(d => (
                  <MenuItem key={d.id} value={d.id}>
                    {d.name}
                  </MenuItem>
                ))}
              <MenuItem key='none' value='none'>
                No department
              </MenuItem>
            </StyledSelect>
            <StyledTextField
              label=''
              value={searchKey}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder='Search recipients'
              onChange={e => {
                setSearchKey(e.target.value);
                setUpdatePage(Date.now());
              }}
            />
          </Stack>
          <RecipientsTable
            recipientsList={filteredRecipients}
            selectedRecipients={selected}
            selectableRecipients={selectableRecipients}
            handleSelect={setSelected}
            showCheckboxColumn
            updatePage={updatePage}
          />
        </Stack>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Typography variant='body1'>{selected?.length} selected</Typography>
        <Button
          text='Review and Remind'
          onClick={() => onClickSubmit()}
          disabled={!selected?.length}
        />
      </DialogActions>
    </>
  );
};

export { RemindRecipientsStep };

const StyledSelect = styled(Select)({
  '&.MuiInputBase-root': {
    borderRadius: '4px 0px 0px 4px',
    width: '35%',
  },
});

const StyledTextField = styled(TextField)({
  '.MuiOutlinedInput-notchedOutline': {
    borderRadius: '0px 4px 4px 0px',
  },
});
