export const DEFAULT_PLATFORM_NOTIFICATION = 'You have a survey to complete.';

export const STATIC_EMAIL_CONTENT = `
<p>
  <a href="%{EMAIL_LINK}%">Here's your unique link to submit your feedback.</a> Using any other link will result in errors capturing your response. If you have any questions or concerns, please let us know by responding to this message.
</p>
<p>
  Thanks in advance for your participation!<br />
  The SkillCycle Team
</p>
`;

export const DEFAULT_EMAIL_CONTENT = `
<p style="margin-top:0">
  Hi there -
</p>
<p>
  We hope this email finds you well! We are requesting, on behalf of your organization, that you provide feedback by completing a short survey.
</p>
  Please have your response submitted as soon as possible. It should take no longer than 5 minutes.
</p>
`;
