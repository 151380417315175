import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import {
  Button,
  TextField,
  useBreadcrumbs,
  DialogTitle,
} from '@src/components/sc-design-system';
import { useFullScreen } from '@src/hooks/useFullScreen';
import { useForm, Controller } from 'react-hook-form';

interface NewSurveyModalProps {
  showModal: boolean;
  setShowModal: (b: boolean) => void;
}

const NewSurveyModal = ({ showModal, setShowModal }: NewSurveyModalProps) => {
  const { fullScreen } = useFullScreen();
  const { pathname } = useLocation();
  const { appendBreadcrumb } = useBreadcrumbs();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { isValid, touchedFields, isDirty },
    getValues,
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
    },
  });

  const onSubmit = (data: any) => {
    const suffix =
      pathname.includes('insights') || pathname.includes('my-business')
        ? '/surveys'
        : '';
    const path = `.${suffix}/editor?id=create&title=${data.title}`;
    navigate(path, { state: { description: data.description } });
    appendBreadcrumb({
      path,
      label: data.title,
      search: location.search,
    });
    setShowModal(false);
  };

  return (
    <Dialog
      onClose={() => setShowModal(false)}
      open={showModal}
      maxWidth='sm'
      fullScreen={fullScreen}
    >
      <DialogTitle
        title='Create survey'
        buttonOnClick={() => setShowModal(false)}
      />
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} id='main'>
          <Stack gap='2em'>
            <Controller
              name='title'
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <TextField
                    {...fieldProps}
                    innerRef={ref}
                    label='Survey name'
                    required
                    fullWidth
                    error={touchedFields.title && !getValues('title')}
                    errorMessage={
                      touchedFields.title &&
                      !getValues('title') &&
                      'Survey name is required'
                    }
                    id={field.name}
                  />
                );
              }}
            />
            <Controller
              name='description'
              control={control}
              render={({ field }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <TextField
                    {...fieldProps}
                    innerRef={ref}
                    id={field.name}
                    label='What do you intend to use the survey for?'
                    multiline
                    rows={6}
                    fullWidth
                    helperText='Optionally enter a short description that is viewable only by you and other client admins within your organization.'
                    helperTextPosition='top'
                  />
                );
              }}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: '1em' }}>
        <Button
          variant='outlined'
          onClick={() => setShowModal(false)}
          text='Cancel'
        />
        <Button
          type='submit'
          form='main'
          text='Create'
          disabled={!isValid || !isDirty}
        />
      </DialogActions>
    </Dialog>
  );
};

export { NewSurveyModal };
