import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import CoachView from '@src/components/views/Coaches/CoachView/CoachView';
import { useStore } from '@src/models/Store';
import Program from '@src/models/Program';
import { api } from '@src/lib/client';
import {
  Button,
  useToast,
  DialogTitle,
} from '@src/components/sc-design-system';
import * as useVerbiage from '@shared/lib/src/use-verbiage';
import { Typography, Dialog, DialogContent, Stack } from '@mui/material';
import { handleKeyboardNav, useQueryParams } from '@src/utils';
import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined';
import PersonOutlined from '@mui/icons-material/PersonOutlined';
import { useUser } from '@src/hooks/useUser';
import { useUserQueries } from '@src/hooks/useUserQueries';

export let CoachViewModalButton: React.FC<{
  coach: any;
  isBooking?: boolean;
  buttonText?: string;
  trigger?: React.ReactNode;
  bookingProgram?: Program;
  disabled?: boolean;
  tabDisabled?: boolean;
  onCloseModal?: Function;
}> = ({
  coach,
  isBooking = false,
  buttonText,
  trigger,
  bookingProgram,
  disabled = false,
  tabDisabled = false,
  onCloseModal,
}) => {
  const toast = useToast();
  const query = useQueryParams();
  const programId = query.get('program_id');
  const [lastRerender, setLastRerender] = React.useState(Date.now());
  const [showCoachViewModal, setShowCoachViewModal] = React.useState(false);
  const [runningAllocationCheck, setRunningAllocationCheck] =
    React.useState(false);

  const { user } = useUser();
  const { useGetUserPrograms } = useUserQueries(user.id);
  const { userPrograms } = useGetUserPrograms('coachee');

  const currentBookingProgram = useMemo(() => {
    if (bookingProgram) return bookingProgram;
    if (programId) {
      return userPrograms?.find(p => p.id === programId);
    }
    return userPrograms?.find(p => !p.coach && p.is_active);
  }, [programId, userPrograms, bookingProgram]);

  const coachIsLegit =
    coach &&
    coach.first_name &&
    coach.last_name &&
    coach.coaching_topics?.length > 1 &&
    coach.biography?.length > 1;
  const v = useVerbiage.init(currentBookingProgram?.type ? ['isMentor'] : []);

  if (!coach) {
    return null;
  }

  const toggleModal = () => {
    if (coachIsLegit) {
      setShowCoachViewModal(true);
    } else {
      toast.warn(`Could not load ${v('coach')} profile`);
    }
  };

  return (
    <>
      {showCoachViewModal && (
        <CoachViewModal
          coach={coach}
          isBooking={isBooking}
          lastRerender={lastRerender}
          onClose={() => {
            setShowCoachViewModal(false);
            onCloseModal && onCloseModal();
          }}
          currentBookingProgram={currentBookingProgram}
        />
      )}
      {trigger ? (
        <span
          role='button'
          tabIndex={0}
          style={{ cursor: 'pointer' }}
          onClick={toggleModal}
          onKeyDown={e => handleKeyboardNav(e.key, toggleModal)}
        >
          {trigger}
        </span>
      ) : (
        <Button
          loading={runningAllocationCheck}
          startIcon={isBooking ? <CalendarTodayOutlined /> : <PersonOutlined />}
          onClick={async () => {
            await user.getInterviews('coachee');
            if (isBooking) {
              setRunningAllocationCheck(true);
              try {
                await api.sessions.checkSessionsAvailableForProgram(
                  currentBookingProgram.id,
                );
                setShowCoachViewModal(true);
              } catch (e) {
                toast.error(
                  'Unable to schedule session at this time, please contact your program administrator',
                );
              }
              setRunningAllocationCheck(false);
            } else {
              setShowCoachViewModal(true);
            }
            setLastRerender(Date.now());
          }}
          disabled={disabled}
          tabIndex={tabDisabled ? -1 : 0}
          size='small'
          text={
            buttonText ||
            (isBooking
              ? `Schedule ${bookingProgram ? 'Session' : 'Interview'}`
              : 'View')
          }
        />
      )}
    </>
  );
};
CoachViewModalButton = observer(CoachViewModalButton);

export let CoachViewModal = ({
  coach,
  isBooking,
  bookingProgram,
  lastRerender,
  onClose,
  currentBookingProgram,
}: {
  coach: any;
  lastRerender?: any;
  isBooking?: boolean;
  bookingProgram?: Program;
  currentBookingProgram?: Program;
  onClose(): void;
}) => {
  const store = useStore();
  const v = useVerbiage.init(
    bookingProgram?.type === 'mentoring' ? ['isMentor'] : [],
  );
  return (
    <Dialog
      maxWidth='lg'
      open
      onClose={() => {
        store.user.getSavedAndSelectedCoaches();
        onClose();
      }}
    >
      <DialogTitle
        title={
          <Stack spacing={1}>
            <Typography variant='h3' component='h2'>
              {coach
                ? `${coach.first_name} ${coach.last_name}`
                : `${v('coach', true)} Profile`}
            </Typography>
            {coach.addresses && coach.addresses[0] && coach.addresses[0].city && (
              <Typography variant='body2' sx={{ color: 'grey.700' }}>
                {coach.addresses[0].city}, {coach.addresses[0].state}
              </Typography>
            )}
          </Stack>
        }
        buttonOnClick={() => {
          store.user.getSavedAndSelectedCoaches();
          onClose();
        }}
      />
      <DialogContent>
        <CoachView
          lastRerender={lastRerender}
          viewCoach={coach}
          store={store}
          isBooking={!!isBooking}
          bookingProgram={currentBookingProgram || bookingProgram}
        />
      </DialogContent>
    </Dialog>
  );
};
CoachViewModal = observer(CoachViewModal);

export default CoachViewModalButton;
