import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Stack,
} from '@mui/material';
import { DialogTitle, Button, Spin } from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import { SurveySubmissionType } from '@shared/schemas';
import { SurveySubmissionStack } from '../SurveySubmissionStack';

interface SurveySubmissionModalProps {
  surveySubmission: SurveySubmissionType;
  onClose: () => void;
  ratingType?: 'self' | 'manager' | 'report'; // self = user looking at their own vs report = manager looking at a user's
  firstName?: string;
  title?: string;
}

const SurveySubmissionModal: FC<SurveySubmissionModalProps> = ({
  surveySubmission,
  onClose,
  ratingType,
  firstName,
  title,
}) => {
  const { data: survey, isLoading } = useQuery({
    queryKey: ['survey', surveySubmission.survey_id],
    queryFn: async () => api.surveys.getById(surveySubmission.survey_id),
    select: ({ data }) => data,
  });

  const modalTitle =
    title ||
    (ratingType === 'self'
      ? 'Self-evaluation'
      : ratingType === 'manager'
      ? `Evaluation of ${firstName}`
      : `${firstName}'s self-evaluation`);

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle
        title={
          <Stack direction='column'>
            <Typography variant='h3' component='h2'>
              {modalTitle}
            </Typography>
            {Boolean(firstName) && (
              <Typography variant='body2' color='grey.700'>
                Survey for {firstName}
              </Typography>
            )}
          </Stack>
        }
        buttonOnClick={onClose}
      />
      <DialogContent>
        {isLoading ? (
          <Spin sectionLoader />
        ) : (
          <Stack spacing={3}>
            <Typography variant='h4'>{survey.title}</Typography>
            <SurveySubmissionStack results={surveySubmission.results} />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} text='Close' />
      </DialogActions>
    </Dialog>
  );
};

export { SurveySubmissionModal };
