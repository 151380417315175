import React, { FC, useState, useRef } from 'react';
import ButtonGroup, { ButtonGroupProps } from '@mui/material/ButtonGroup';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button, { ButtonProps } from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export interface MenuButtonProps {
  variant?: ButtonGroupProps['variant'];
  fullWidth?: ButtonProps['fullWidth'];
  disablePadding?: boolean;
  primaryActionText: string | React.ReactNode;
  shape?: 'pill' | 'rounded-square';
  size?: ButtonProps['size'];
  onClickPrimaryAction?: ButtonProps['onClick'];
  piggybackSecondaryActionOnPrimaryClick?: boolean;
  color?: ButtonGroupProps['color'];
  primaryActionIcon?: React.ReactNode;
  secondaryActionIcon?: React.ReactNode;
  className?: string;
  onClickSecondaryAction?: (key: string) => void;
  secondaryActions: Array<{
    label: string;
    key: string;
    onClick?: Function;
    disabled?: boolean;
  }>;
}

const MenuButton: FC<MenuButtonProps> = ({
  variant,
  fullWidth,
  disablePadding = false,
  shape = 'pill',
  primaryActionText,
  onClickPrimaryAction,
  piggybackSecondaryActionOnPrimaryClick,
  primaryActionIcon,
  secondaryActionIcon,
  secondaryActions = [],
  onClickSecondaryAction,
  className,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const classNames = [
    `shape-${shape}`,
    fullWidth ? 'full-width' : '',
    disablePadding ? 'compact' : '',
  ].join(' ');
  return (
    <React.Fragment>
      <ButtonGroup
        {...props}
        variant={variant}
        ref={anchorRef}
        className={classNames}
      >
        <Button
          onClick={event => {
            onClickPrimaryAction(event);
            piggybackSecondaryActionOnPrimaryClick && setOpen(true);
          }}
          fullWidth={fullWidth}
          startIcon={primaryActionIcon}
        >
          {primaryActionText}
        </Button>
        <Button onClick={() => setOpen(!open)}>
          {secondaryActionIcon ? secondaryActionIcon : <MoreHorizIcon />}
        </Button>
      </ButtonGroup>
      <Menu
        open={open}
        onClose={() => setOpen(!open)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '.MuiMenu-paper': {
            marginLeft: '-8px',
            padding: '2px',
          },
        }}
      >
        {secondaryActions.map(a => (
          <MenuItem
            disableRipple
            key={a.key}
            onClick={e => {
              if (a.onClick) {
                a.onClick(e);
              } else if (onClickSecondaryAction) {
                onClickSecondaryAction(a.key);
              }
              setOpen(false);
            }}
            disabled={a.disabled}
          >
            {a.label}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export { MenuButton };
