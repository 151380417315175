import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import HelpOutline from '@mui/icons-material/HelpOutline';
import {
  Button,
  Card,
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
} from '@src/components/sc-design-system';
import Surveys from '@src/components/views/SurveysManager/Surveys/Surveys';
import SurveyQuestions from '@src/components/views/SurveysManager/SurveyQuestions/SurveyQuestions';
import { NewSurveyModal } from '@src/components/views/SurveysManager/SurveyEditor/NewSurveyModal';
import { Alert, AlertTitle, Divider, Typography } from '@mui/material';

const SurveysManager: FC<{}> = () => {
  const [tabKey, setTabKey] = useState('surveys');
  const [openSurveyQuestionModal, setOpenSurveyQuestionModal] = useState(false);
  const [openNewSurveyModal, setOpenNewSurveyModal] = useState(false);
  return (
    <View>
      {openNewSurveyModal && (
        <NewSurveyModal
          showModal={openNewSurveyModal}
          setShowModal={setOpenNewSurveyModal}
        />
      )}
      <ViewHeader
        title='Survey Management'
        titleIcon={<FeedOutlinedIcon fontSize='large' />}
        actions={[
          {
            render: (
              <Button
                size='small'
                key='new-survey-button'
                onClick={() => {
                  if (tabKey === 'surveyQuestions') {
                    setOpenSurveyQuestionModal(true);
                  } else {
                    setOpenNewSurveyModal(true);
                  }
                }}
                text={
                  tabKey === 'surveyQuestions'
                    ? 'New Survey Question'
                    : 'New Survey'
                }
              />
            ),
          },
        ]}
      />
      <ViewBody>
        <ViewBodySection
          title='Surveys'
          icon={<FeedOutlinedIcon />}
          onChangeTo={() => setTabKey('surveys')}
        >
          <Card>
            <Alert severity='warning' icon={false}>
              <AlertTitle>
                The surveys in this view are platform-wide SkillCycle surveys
                and templates to be used internally.
              </AlertTitle>
              <Typography variant='body2'>
                To create a survey for a specific client to use please navigate
                to the client's dashboard and go to the 'Surveys' tab.
              </Typography>
            </Alert>
            <Divider />
            <Surveys />
          </Card>
        </ViewBodySection>
        <ViewBodySection
          title='Questions'
          icon={<HelpOutline />}
          onChangeTo={() => setTabKey('surveyQuestions')}
        >
          <Card>
            <SurveyQuestions
              openModal={openSurveyQuestionModal}
              setOpenModal={setOpenSurveyQuestionModal}
            />
          </Card>
        </ViewBodySection>
      </ViewBody>
    </View>
  );
};

export default observer(SurveysManager);
