import React from 'react';

import { useMutation } from '@tanstack/react-query';
import { Badge, IconButton, Stack, Tooltip } from '@mui/material';

import { ConversationFeedbackModal } from './ConversationFeedbackModal';
import { ConversationShareModal } from './ConversationShareModal';
import { useUser } from '@src/hooks/useUser';
import { api } from '@src/lib/client';

import ReplayIcon from '@mui/icons-material/Replay';
import SyncIcon from '@mui/icons-material/Sync';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ShareIcon from '@mui/icons-material/Share';
import { Download } from './helper';

interface AidaFooterProps {
  assistantMessage: AidaMessage;
  setAssistantMessage: (m: AidaMessage) => void;
  handlePromptSelection: HandlePromptSelection;
  selectedPrompt: Prompts;
  conversations: Conversations;
  fetchConversations: Function;
  setAidaError: (b: boolean) => void;
  shareWith: ShareWith[];
  shareCount: number;
}

interface AidaActionButtonsProps {
  assistantMessage: AidaMessage;
  setAssistantMessage: (m: AidaMessage) => void;
  handlePromptSelection: HandlePromptSelection;
  selectedPrompt: Prompts;
  conversations: Conversations;
  setAidaError: (b: boolean) => void;
  shareWith: ShareWith[];
  userId: string;
  fetchConversations: Function;
  shareCount: number;
}

interface AidaRatingProps {
  conversation: AidaConversation;
  fetchConversations: Function;
}

function AidaActionButtons(props: AidaActionButtonsProps) {
  const {
    assistantMessage,
    setAssistantMessage,
    handlePromptSelection,
    selectedPrompt,
    conversations,
    setAidaError,
    shareWith,
    userId,
    fetchConversations,
    shareCount,
  } = props;

  const { mutate: dismissConversation } = useMutation({
    mutationFn: async ({
      conversationId,
    }: {
      conversationId: AidaConversation['id'];
    }) => {
      return api.users.dismissAidaConversation(userId, conversationId);
    },
    onSuccess: async () => {
      await fetchConversations();
    },
  });

  const promptHistory = React.useMemo(() => {
    if (
      Array.isArray(conversations?.[selectedPrompt]) &&
      conversations[selectedPrompt].length === 2 &&
      assistantMessage.id !== conversations[selectedPrompt][1].id
    ) {
      return conversations[selectedPrompt][1];
    }
  }, [selectedPrompt, conversations, assistantMessage]);
  const [openShareModal, setOpenShareModal] = React.useState(false);

  return (
    <Stack direction='row'>
      {promptHistory && (
        <Tooltip title='Revert'>
          <IconButton
            onClick={() => {
              setAidaError(false);
              const isJson = ['resources', 'coaches'].includes(selectedPrompt);
              dismissConversation({
                conversationId: conversations[selectedPrompt][0].id,
              });
              const summary = isJson
                ? promptHistory?.output_data?.summary
                : promptHistory.output_text;
              const data = isJson && promptHistory?.output_data?.data;
              setAssistantMessage({
                id: promptHistory.id,
                summary,
                data,
                timestamp: promptHistory.timestamp,
                additionalData: promptHistory?.additional_data,
              });
            }}
            color='primary'
            size='small'
            children={<ReplayIcon />}
          />
        </Tooltip>
      )}
      <Tooltip title='Refresh'>
        <IconButton
          onClick={() => {
            setAssistantMessage(null);
            handlePromptSelection(selectedPrompt, true);
          }}
          color='primary'
          size='small'
          children={<SyncIcon />}
        />
      </Tooltip>
      {['summary', 'focus_areas'].includes(selectedPrompt) && (
        <>
          <Download selectedPrompt={selectedPrompt} />
          {shareWith.length > 0 && (
            <>
              <Tooltip title='Share'>
                <IconButton
                  onClick={() => setOpenShareModal(true)}
                  color='primary'
                  size='small'
                  children={
                    <Badge badgeContent={shareCount} color='tertiary'>
                      <ShareIcon />
                    </Badge>
                  }
                />
              </Tooltip>
              <ConversationShareModal
                openModal={openShareModal}
                setOpenModal={setOpenShareModal}
                assistantMessage={assistantMessage}
                selectedPrompt={selectedPrompt}
                shareWith={shareWith}
              />
            </>
          )}
        </>
      )}
    </Stack>
  );
}

function AidaRating(props: AidaRatingProps) {
  const { conversation, fetchConversations } = props;
  const { user } = useUser();
  const rating = conversation?.rating?.find(r => r.rated_by === user?.id);
  // used to pass to modal but not to determine icons, etc
  const [thumbsUp, setThumbsUp] = React.useState<boolean>();
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <Stack direction='row' spacing='2'>
      <IconButton
        onClick={() => {
          setThumbsUp(true);
          setOpenModal(true);
        }}
        color='primary'
        size='small'
        disabled={!!rating}
        children={rating?.thumbs_up ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
      />
      <IconButton
        onClick={() => {
          setThumbsUp(false);
          setOpenModal(true);
        }}
        color='primary'
        size='small'
        disabled={!!rating}
        children={
          rating?.thumbs_up === false ? (
            <ThumbDownIcon />
          ) : (
            <ThumbDownOutlinedIcon />
          )
        }
      />
      <ConversationFeedbackModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        conversationId={conversation?.id}
        conversationUserId={conversation?.user_id}
        thumbsUp={thumbsUp}
        fetchConversations={fetchConversations}
      />
    </Stack>
  );
}

function AidaFooter(props: AidaFooterProps) {
  const {
    selectedPrompt,
    assistantMessage,
    conversations,
    fetchConversations,
    shareWith,
    shareCount,
  } = props;
  const conversationId = assistantMessage.id;
  const conversation = conversations?.[selectedPrompt]?.find(
    c => c.id === conversationId,
  );
  const { user } = useUser();

  return (
    // width should match ChatBubble width for aida messages
    <Stack direction='row' justifyContent='space-between' sx={{ width: '90%' }}>
      <AidaActionButtons
        {...props}
        shareWith={shareWith}
        userId={user.id}
        shareCount={shareCount}
      />
      <AidaRating
        conversation={conversation}
        fetchConversations={fetchConversations}
      />
    </Stack>
  );
}

export { AidaFooter };
