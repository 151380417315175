import React, { FC } from 'react';
import { useParams } from 'react-router';
import WebIcon from '@mui/icons-material/Web';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import {
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
} from '@src/components/sc-design-system';
import { ProgramsDashboard } from './tabs/Dashboard/ProgramsDashboard';
import { ClientSOWsAndPrograms } from './tabs/SOWsAndPrograms';
import { useClientQueries } from '@src/hooks/useClientQueries';

const ClientPrograms: FC = () => {
  const { id } = useParams();

  const { useGetClient } = useClientQueries(id);
  const { data: client, isLoading, refetch, isError } = useGetClient();

  return (
    <View isLoading={isLoading} isError={isError} refreshFn={refetch}>
      <ViewHeader title='Programs' titleIcon={<WebIcon fontSize='large' />} />
      <ViewBody>
        <ViewBodySection title='Dashboard' icon={<DashboardOutlinedIcon />}>
          <ProgramsDashboard client={client} />
        </ViewBodySection>
        <ViewBodySection title='Programs' icon={<WebIcon />}>
          <ClientSOWsAndPrograms />
        </ViewBodySection>
      </ViewBody>
    </View>
  );
};

export { ClientPrograms };
