import React, { FC, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import CoachViewModal from '../CoachViewModal';
import SelectCoach from '../SelectCoach';
import dayjs from 'dayjs';
import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined';
import {
  Avatar,
  Button,
  DialogTitle,
  Select,
} from '@src/components/sc-design-system';
import { useUser } from '@src/hooks/useUser';
import { useUserQueries } from '@src/hooks/useUserQueries';
import {
  Typography,
  Divider,
  Stack,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import Program from '@src/models/Program';

const SavedCoachesList: FC<{}> = () => {
  const { user } = useUser();
  const { useGetUserPrograms } = useUserQueries(user.id);
  const { userPrograms } = useGetUserPrograms('coachee');

  const interviewPrograms = useMemo(() => {
    return userPrograms?.filter(p => {
      return p.is_active && !p.coach;
    });
  }, [userPrograms]);

  return (
    <Stack divider={<Divider />}>
      {user.savedAndInterviewCoachList.map((coach, idx) => {
        return (
          <Stack
            key={coach.id + idx}
            direction='row'
            justifyContent='space-between'
          >
            <CoachViewModal
              coach={coach}
              trigger={
                <Stack direction='row' spacing={2} alignItems='center'>
                  <Avatar
                    srcId={coach.id}
                    alt={`${coach.first_name} ${coach.last_name}`}
                    size='xl'
                    variant='interactive'
                  />
                  <div>
                    <Typography variant='h3'>
                      {coach.first_name} {coach.last_name}
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{ textTransform: 'capitalize' }}
                      color='grey.700'
                    >
                      {coach.association}
                    </Typography>
                  </div>
                </Stack>
              }
            />
            <Stack
              direction='row'
              alignItems='center'
              marginLeft='auto'
              spacing={1}
            >
              {coach.interview.status && !coach.is_selected && (
                <>
                  <CalendarTodayOutlined />
                  <Typography color='grey.700'>
                    {dayjs(coach.interview.start).format('M/DD h:mma')}
                  </Typography>
                  <Chip
                    label={`Interview ${coach.interview.status}`}
                    color='tertiary'
                    className='shape-rounded-square'
                  />
                </>
              )}
              {coach.association !== 'selected' && (
                <SelectCoach user={user} coach={coach} />
              )}
              {coach.association === 'saved' &&
                interviewPrograms?.length === 1 && (
                  <CoachViewModal
                    coach={coach}
                    isBooking={true}
                    bookingProgram={interviewPrograms[0]}
                    buttonText='Schedule Interview'
                  />
                )}
              {coach.association === 'saved' &&
                interviewPrograms?.length > 1 && (
                  <InterviewProgramSelect
                    coach={coach}
                    programs={interviewPrograms}
                  />
                )}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

const InterviewProgramSelect: FC<{
  coach: any;
  programs: Program[];
}> = ({ coach, programs }) => {
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [programSelectModalOpen, setProgramSelectModalOpen] = useState(false);

  return (
    <>
      <Dialog
        maxWidth='sm'
        open={programSelectModalOpen}
        onClose={() => setProgramSelectModalOpen(false)}
      >
        <DialogTitle
          title='Select program'
          buttonOnClick={() => setProgramSelectModalOpen(false)}
        />
        <DialogContent>
          <Typography variant='body2' sx={{ marginBottom: '1.35em' }}>
            Please select which program you would like to interview{' '}
            {coach.first_name} for:
          </Typography>
          <Select
            fullWidth
            onChange={e =>
              setSelectedProgram(programs.find(p => p.id === e.target.value))
            }
            options={[
              {
                options: programs.map(p => ({
                  label: p.name,
                  value: p.id,
                })),
              },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <CoachViewModal
            coach={coach}
            isBooking={true}
            bookingProgram={selectedProgram}
            buttonText='Schedule interview'
            onCloseModal={() => setProgramSelectModalOpen(false)}
          />
        </DialogActions>
      </Dialog>
      <Button
        startIcon={<CalendarTodayOutlined />}
        text='Schedule interview'
        onClick={() => {
          setProgramSelectModalOpen(true);
        }}
        size='small'
      />
    </>
  );
};

export default observer(SavedCoachesList);
