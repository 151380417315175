import React, { FC, useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { Card } from '@src/components/sc-design-system';
import { Stack, Chip, Typography, Divider } from '@mui/material';
import { GoalActivity } from './GoalActivity';
import { PerformanceCycleSelector } from '@src/components/common/PerformanceCycleSelector';
import { EvaluationPeriod } from './EvaluationPeriod';
import { useStore } from '@src/models/Store';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { FinalPerformanceReview } from './FinalPerformanceReview';
import { CollectFeedback } from './CollectFeedback';
import { useQueryParams } from '@src/utils';
import { useNavigation } from '@src/navigation';

interface MyPerformanceProps {
  performanceCycles: {
    active: PerformanceCycleI[];
    inactive: PerformanceCycleI[];
  };
  activeGoalCycles: GoalCycle[];
  clientName: string;
  clientId: string;
}

const MyPerformance: FC<MyPerformanceProps> = ({
  performanceCycles,
  activeGoalCycles,
  clientName,
  clientId,
}) => {
  const query = useQueryParams();
  const navigation = useNavigation();
  const performanceCycleId = query.get('pc');
  const [selectedPerformanceCycleId, setSelectedPerformanceCycleId] =
    React.useState(performanceCycleId || null);
  const [selectedPerformanceCycle, setSelectedPerformanceCycle] =
    useState(null);
  const { user } = useStore();
  const { useGetPerformanceCycleResultsForDirectReport } =
    useClientQueries(clientId);

  useEffect(() => {
    if (!selectedPerformanceCycle) {
      // TODO: Which performance cycle should display initially?
      setSelectedPerformanceCycle(
        performanceCycles?.active?.find(pc => pc.goal_cycles) ||
          performanceCycles?.active?.find(p => p) ||
          performanceCycles?.inactive?.find(p => p.goal_cycles) ||
          performanceCycles?.inactive?.find(p => p),
      );
    } else {
      setSelectedPerformanceCycle(
        [
          ...(performanceCycles?.active || []),
          ...(performanceCycles?.inactive || []),
        ]?.find(pc => pc.id === selectedPerformanceCycle.id),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performanceCycles]);

  const { results: performanceCycleResults } =
    useGetPerformanceCycleResultsForDirectReport(
      selectedPerformanceCycle?.id,
      user.id,
    );

  React.useEffect(
    function () {
      setSelectedPerformanceCycle(
        performanceCycles?.active?.find(
          pc => pc.id === selectedPerformanceCycleId,
        ) || // TODO: Which performance cycle should display is there is no pc QSP?
          performanceCycles?.active?.find(pc => pc.goal_cycles) ||
          performanceCycles?.active?.find(p => p) ||
          performanceCycles?.inactive?.find(p => p.goal_cycles) ||
          performanceCycles?.inactive?.find(p => p),
      );
    },
    [selectedPerformanceCycleId, performanceCycles],
  );

  const hasEvaluationPeriodStarted =
    selectedPerformanceCycle?.self_evaluation_start &&
    selectedPerformanceCycle?.self_evaluation_end &&
    dayjs().isAfter(dayjs(selectedPerformanceCycle?.self_evaluation_start));

  const isReportViewable = Boolean(
    hasEvaluationPeriodStarted &&
      performanceCycleResults?.results?.direct_report
        ?.evaluation_submission_id &&
      performanceCycleResults?.results?.manager?.evaluation_submission_id &&
      selectedPerformanceCycle?.release_evaluations,
  );

  const performanceCycleIsActive = useMemo(() => {
    return (performanceCycles?.active || []).some(
      pc => pc.id === selectedPerformanceCycle?.id,
    );
  }, [selectedPerformanceCycle, performanceCycles]);
  const isOngoingFeedbackEnabled = Boolean(
    performanceCycleIsActive &&
      selectedPerformanceCycle.ongoing_feedback_form_id,
  );

  return (
    <Card
      key={selectedPerformanceCycle?.id}
      headerColor='primary'
      title={selectedPerformanceCycle?.name}
      titleActions={
        <PerformanceCycleSelector
          onChange={performanceCycle => {
            setSelectedPerformanceCycle(performanceCycle);
            setSelectedPerformanceCycleId(performanceCycle.id);
            query.set('pc', performanceCycle.id);
            navigation.replace(
              `${navigation.location.pathname}${
                [...new Set(query.keys())].length ? '?' + query : ''
              }`,
            );
          }}
          performanceCycles={[
            ...(performanceCycles?.active || []),
            ...(performanceCycles?.inactive || []),
          ]}
          selectedPerformanceCycle={selectedPerformanceCycle}
          whiteLabel
        />
      }
      subheader={
        <Stack direction='row' spacing={2}>
          <Typography variant='body1' color='white.main'>
            {dayjs(selectedPerformanceCycle?.start).format('MMM DD, YYYY')} -{' '}
            {dayjs(selectedPerformanceCycle?.end).format('MMM DD, YYYY')}
          </Typography>
          <Chip color='success' label='Active' />
        </Stack>
      }
    >
      <Stack spacing={4} divider={<Divider />}>
        {isReportViewable && (
          <FinalPerformanceReview
            profileUser={user}
            clientId={clientId}
            performanceCycleId={selectedPerformanceCycle?.id}
          />
        )}
        {hasEvaluationPeriodStarted && (
          <EvaluationPeriod
            performanceCycle={selectedPerformanceCycle}
            clientId={clientId}
            user={user}
          />
        )}
        {isOngoingFeedbackEnabled && (
          <CollectFeedback
            clientId={clientId}
            userId={user.id}
            userFirstName={user.first_name}
            userLastName={user.last_name}
            performanceCycleId={selectedPerformanceCycle.id}
            feedbackFormId={selectedPerformanceCycle.ongoing_feedback_form_id}
          />
        )}
        {selectedPerformanceCycle?.goal_cycles?.length ? (
          <GoalActivity
            performanceCycleId={selectedPerformanceCycle?.id}
            clientName={clientName}
            clientId={clientId}
            goalCycles={selectedPerformanceCycle?.goal_cycles}
            allActiveCycles={activeGoalCycles}
          />
        ) : selectedPerformanceCycle ? (
          <Stack spacing={2}>
            <Typography variant='h3'>Goal Activity</Typography>
            <Card variant='elevation'>
              <Typography variant='body1' align='center'>
                Your organization has not set up any goals yet for this
                performance cycle. You can still set goals under the Personal
                Growth tab.
              </Typography>
            </Card>
          </Stack>
        ) : (
          <Typography variant='body1' align='center'>
            Your organization has not set up any performance cycles or goals
            yet.
          </Typography>
        )}
      </Stack>
    </Card>
  );
};

export { MyPerformance };
