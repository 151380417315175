import React from 'react';
import { observer } from 'mobx-react';
import { EChart } from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import { ComparisonType } from '@shared/schemas';
import Message from './Message';

import LoadingIcon from '@src/components/common/LoadingIcon';
import { Typography } from '@mui/material';

interface ChartProps {
  clientId: string;
  surveyId: string;
  questionId: string;
  comparisonType?: ComparisonType;
  setDisableCompareBy: (b: boolean) => void;
}

function Chart(props: ChartProps) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [failedToLoadResults, setFailedToLoadResults] = React.useState(false);
  const [results, setResults] = React.useState<any[]>([]);

  React.useEffect(() => {
    const getChartOptions = async () => {
      setIsLoading(true);
      setFailedToLoadResults(false);
      try {
        const res = await api.surveys.getAggregateQuestionResults(
          props.clientId,
          props.surveyId,
          props.questionId,
          props.comparisonType,
        );

        if (res.data.length && res.data.length > 0) {
          setResults(res.data);
          props.setDisableCompareBy(false);
        }
      } catch (error) {
        setFailedToLoadResults(true);
      }
      setIsLoading(false);
    };
    getChartOptions();
  }, [props]);

  if (isLoading) {
    return <LoadingIcon />;
  } else {
    if (failedToLoadResults) {
      return (
        <Message
          headline='Oops!'
          text='Unable to load chart. Please try again later.'
        />
      );
    } else {
      if (Array.isArray(results) && results.length > 0) {
        return (
          <>
            {results.map((result, index) => (
              <React.Fragment key={index}>
                {result.compare && (
                  <Typography variant='h5' sx={{ paddingLeft: '1.5em' }}>
                    {result.compare === 'undefined' || result.compare === 'null'
                      ? `No ${props.comparisonType} assigned`
                      : result.compare}
                  </Typography>
                )}
                <EChart
                  props={{
                    height: '550px',
                    width: '650px',
                    options: result.options,
                    chartType: result.chartType,
                  }}
                />
              </React.Fragment>
            ))}
          </>
        );
      } else {
        return (
          <Message text='To preserve confidentiality, SkillCycle only shows results when there are 5 or more responses to a question.' />
        );
      }
    }
  }
}

export default observer(Chart);
