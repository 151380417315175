import { HTTPClient } from '@src/lib/client';

export class AnalyticsAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async getAdminAnalytics(refresh: boolean) {
    return this.http.request({
      url: `/analytics/admin${refresh ? '?refresh=true' : ''}`,
      method: 'get',
    });
  }

  async getClientMetrics(refresh: boolean, clientId: string) {
    return this.http.request({
      url: `/analytics/client/metrics/${clientId}${
        refresh ? '?refresh=true' : ''
      }`,
      method: 'get',
    });
  }

  async getClientDashboardData(refresh: boolean, clientId: string) {
    return this.http.request({
      url: `/analytics/client/dashboard/${clientId}${
        refresh ? '?refresh=true' : ''
      }`,
      method: 'get',
    });
  }
}
