import { HTTPClient } from '@src/lib/client';

interface DataIF {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

interface ContactParams {
  senderFirstName: string;
  senderLastName: string;
  senderAddress: string;
  recipientAddress: string;
  message: string;
}

export class MailerAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async sendSupportMessage(data: DataIF) {
    return this.http.request({
      url: `/mailer/support-message`,
      method: 'post',
      data,
    });
  }

  async contact(data: ContactParams) {
    return this.http.request({
      url: `/mailer/contact`,
      method: 'post',
      data,
    });
  }
}
