import React, { FC, useMemo, useState } from 'react';
import {
  Card,
  TableMUI as Table,
  useBreadcrumbs,
} from '@src/components/sc-design-system';
import NotificationsOutlined from '@mui/icons-material/NotificationsOutlined';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { Link as MUILink, Typography } from '@mui/material';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { FeedbackStatusModal } from '@src/components/common/FeedbackStatusModal';
import { Link } from 'react-router-dom';

interface FeedbackStatusProps {
  clientId: string;
  performanceCycle: PerformanceCycleI;
}

const FeedbackStatus: FC<FeedbackStatusProps> = ({
  clientId,
  performanceCycle,
}) => {
  const { appendBreadcrumb } = useBreadcrumbs();
  const [modalUser, setModalUser] = useState<{
    id: string;
    first_name: string;
    last_name: string;
  } | null>(null);
  const { useGetPerformanceCycleFeedbackStatus, useGetClientMembers } =
    useClientQueries(clientId);
  const { members, isLoading: isMembersLoading } = useGetClientMembers(
    {},
    { enabled: !!performanceCycle?.ongoing_feedback_form_id },
  );
  const { feedbackData, isLoading } = useGetPerformanceCycleFeedbackStatus(
    performanceCycle?.id,
    performanceCycle?.ongoing_feedback_form_id,
  );

  const columns = useMemo(() => {
    return [
      {
        header: 'Member',
        accessor: 'first_name',
        cell: ({ row }) => (
          <Typography variant='body2'>
            {row.original.first_name} {row.original.last_name}
          </Typography>
        ),
      },
      {
        header: 'Feedback',
        accessor: 'stakeholders',
        cell: ({ row }) => {
          const stakeholders = row.original.stakeholders;
          const completedTotal = stakeholders.reduce((num, s) => {
            if (s.feedback_submission_id) {
              return num + 1;
            }
            return num;
          }, 0);

          return (
            <MUILink
              onClick={() =>
                setModalUser({
                  id: row.original.id,
                  first_name: row.original.first_name,
                  last_name: row.original.last_name,
                })
              }
            >
              {completedTotal}/{stakeholders.length} completed
            </MUILink>
          );
        },
      },
      {
        header: 'Report',
        accessor: 'stakeholders',
        cell: ({ row }) => {
          const stakeholders = row.original.stakeholders;
          const completedTotal = stakeholders.reduce((num, s) => {
            if (s.feedback_submission_id) {
              return num + 1;
            }
            return num;
          }, 0);

          if (completedTotal < 5) {
            return <Typography variant='body2'>n/a</Typography>;
          }

          const path = `/feedback-report?clientId=${clientId}&performanceCycleId=${
            performanceCycle.id
          }&feedbackFormId=${
            performanceCycle.ongoing_feedback_form_id
          }&userId=${row.original.id}&name=${encodeURIComponent(
            `${row.original.first_name} ${row.original.last_name}`,
          )}`;
          return (
            <Link
              to={path}
              target='_blank'
              rel='noopener noreferrer'
              state={{
                employeeName: `${row.original.first_name} ${row.original.last_name}`,
              }}
              onClick={() => {
                appendBreadcrumb({
                  path,
                  label: `Report for ${row.original.first_name} ${row.original.last_name}`,
                });
              }}
            >
              <MUILink>
                View
                <OpenInNew
                  fontSize='small'
                  sx={{
                    marginLeft: '4px',
                    marginBottom: '-3px',
                    height: '.7em',
                    width: '.7em',
                  }}
                />
              </MUILink>
            </Link>
          );
        },
      },
    ];
  }, [appendBreadcrumb, clientId, performanceCycle]);

  const data = useMemo(() => {
    if (isLoading || isMembersLoading || !feedbackData) {
      return [];
    }
    return feedbackData?.members?.map(m => {
      const member = members.find(member => member.id === m.member_id);
      return {
        id: m.member_id,
        stakeholders: m.stakeholders,
        first_name: member?.first_name,
        last_name: member?.last_name,
      };
    });
  }, [feedbackData, members, isLoading, isMembersLoading]);

  return (
    <Card
      variant='elevation'
      titleStyle='h2styleh4'
      title='Feedback status'
      titleIcon={<NotificationsOutlined />}
    >
      <Table
        columns={columns}
        data={data}
        emptyState='Data will appear here once members begin requesting feedback from stakeholders.'
        dataIsLoading={isLoading}
      />

      {!!modalUser && (
        <FeedbackStatusModal
          open
          onClose={() => setModalUser(null)}
          clientId={clientId}
          userId={modalUser.id}
          userFirstName={modalUser.first_name}
          userLastName={modalUser.last_name}
          performanceCycleId={performanceCycle?.id}
          feedbackFormId={performanceCycle?.ongoing_feedback_form_id}
        />
      )}
    </Card>
  );
};

export { FeedbackStatus };
