import React from 'react';
import { Stack, Typography, Switch, Divider } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Card, useToast } from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import Client from '@src/models/Client';

interface RolesCardProps {
  member: ClientMember;
  client: Client;
  mentoringEnabled: boolean;
  updatedProps: string[];
  setUpdatedProps: (sA: string[]) => void;
  setUpdatedData: (cM: ClientMember[]) => void;
}

const updateAdminStatus = async ({ clientId, memberId, isAdmin }) =>
  api.clients.changeAdminStatus(clientId, memberId, isAdmin);
const updateMentorStatus = async ({ clientId, memberId, isMentor }) =>
  api.clients.changeMentorStatus(clientId, memberId, isMentor);

export default function RolesCard(props: RolesCardProps) {
  const {
    member,
    client,
    mentoringEnabled,
    updatedProps,
    setUpdatedProps,
    setUpdatedData,
  } = props;
  const toast = useToast();
  const [clientAdmin, setClientAdmin] = React.useState(
    !!client.members.find(m => m.id === member.id && m.role === 'manager'),
  );
  const [isMentor, setIsMentor] = React.useState(
    !!client.mentors.find(m => m === member.id),
  );

  const { mutate: updateMemberStatus, isPending: isMemberRoleChangeLoading } =
    useMutation({
      mutationFn: updateAdminStatus,
      onSuccess: ({ data }) => {
        setUpdatedProps(['role', ...updatedProps]);
        setUpdatedData(data);
        toast.success('Client admin selection successfully updated');
      },
      onError() {
        toast.error('Could not change admin status of member');
      },
    });
  const {
    mutate: updateMemberMentorStatus,
    isPending: isMentorStatusChangeLoading,
  } = useMutation({
    mutationFn: updateMentorStatus,
    onSuccess: () => {
      setUpdatedProps(['is_mentor', ...updatedProps]);
      toast.success('Mentor selection successfully updated');
    },
    onError() {
      toast.error('Could not change mentor status of member');
    },
  });

  const readOnly = ['not_invited', 'pending_invite'].includes(member.status);

  return (
    <Card
      variant='elevation'
      title='Role'
      titleStyle='h3styleh4'
      sx={{ marginTop: '2em' }}
    >
      <Stack spacing={2} divider={<Divider orientation='vertical' flexItem />}>
        <>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography variant='body1m'>Client admin</Typography>
            <Switch
              disabled={isMemberRoleChangeLoading || readOnly}
              checked={clientAdmin}
              onChange={event => {
                updateMemberStatus({
                  clientId: client.id,
                  memberId: member.id,
                  isAdmin: event.target.checked,
                });
                setClientAdmin(event.target.checked);
              }}
            />
          </Stack>
          <Typography variant='body1' color='gray.700'>
            Enabling this will allow users to have client admin access and
            responsibilities.
          </Typography>
        </>
        {mentoringEnabled && (
          <>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography variant='body1m'>Mentor</Typography>
              <Switch
                disabled={isMentorStatusChangeLoading || readOnly}
                checked={isMentor}
                onChange={event => {
                  updateMemberMentorStatus({
                    clientId: client.id,
                    memberId: member.id,
                    isMentor: event.target.checked,
                  });
                  setIsMentor(event.target.checked);
                }}
              />
            </Stack>
            <Typography variant='body1' color='gray.700'>
              Turning this on will allow this employee to become a mentor.
            </Typography>
          </>
        )}
      </Stack>
    </Card>
  );
}
