import React from 'react';
import {
  Pagination as MUIPagination,
  PaginationProps as MuiPaginationProps,
} from '@mui/material';

export interface PaginationProps extends MuiPaginationProps {
  totalItems: number;
  pageSize: number;
}

const Pagination: React.FunctionComponent<PaginationProps> = ({
  totalItems,
  pageSize,
  ...props
}) => {
  const count = Math.ceil(totalItems / pageSize);
  return <MUIPagination count={count} {...props} />;
};

export { Pagination };
