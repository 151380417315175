import React, { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import Stack from '@mui/material/Stack';
import { Button, InputField } from '@src/components/sc-design-system';
import { AssessmentStakeholder } from '@src/models/Program';
import { Typography } from '@mui/material';

interface StakeholderFormProps {
  stakeholder: AssessmentStakeholder;
  role: 'manager' | 'report' | 'peer';
  onSubmit: Function;
  onEdit: Function;
  isUpdating: boolean;
  isActive: boolean;
  isEditable?: boolean;
}

const StakeholderForm: FC<StakeholderFormProps> = ({
  stakeholder,
  role,
  onSubmit,
  onEdit,
  isUpdating,
  isActive,
  isEditable,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [form] = Form.useForm<{ name?: string; email: string }>();

  useEffect(() => {
    onEdit(isDirty);
  }, [onEdit, isDirty]);

  return (
    <Form
      form={form}
      initialValues={{
        name: stakeholder.name ?? '',
        email: stakeholder.email ?? '',
      }}
      onFieldsChange={() => {
        const values = form.getFieldsValue();
        setIsDirty(
          stakeholder.name !== values.name ||
            stakeholder.email !== values.email,
        );
        setIsValid(form.getFieldError('email').length === 0);
      }}
      onFinish={values => {
        onSubmit({ role, ...stakeholder, ...values });
        onEdit(false);
      }}
    >
      <Stack direction='row' alignItems='center' spacing={1}>
        <Stack>
          <Typography variant='h4'>Name</Typography>
          <InputField
            name='name'
            label=''
            value={form.getFieldValue('name')}
            onChange={name => form.setFieldsValue({ name })}
            disabled={!isEditable || !isActive || !!stakeholder.sent}
          />
        </Stack>
        <Stack>
          <Typography variant='h4'>Email</Typography>
          <InputField
            name='email'
            label=''
            value={form.getFieldValue('email')}
            rules={[{ type: 'email' }, { required: true }]}
            onChange={email => form.setFieldsValue({ email })}
            disabled={!isEditable || !isActive || !!stakeholder.sent}
          />
        </Stack>
        {isEditable && isActive && !stakeholder.sent && (
          <Button
            type='submit'
            disabled={!isDirty || !isValid || isUpdating}
            text='Save Stakeholder'
          />
        )}
      </Stack>
    </Form>
  );
};

export default StakeholderForm;
