import React, { FC, useMemo } from 'react';
import dayjs from 'dayjs';
import { Grid } from '@src/components/sc-design-system';
import { GoalCard } from '@src/components/common/GoalCard';
import './GoalCycleGrid.less';
import { Typography } from '@mui/material';

export interface GoalCycleGridProps {
  cycle: GoalCycleWithGoals;
  entityType?: 'user' | 'client';
  canEditGoals?: boolean;
  canAddGoals?: boolean;
  onDeleteGoal?: (g: Goal) => void;
  onAddOrEditGoal?: (type: string, g: Goal, t?: GoalCycle, a?: string) => void;
  tabDefinitions?: Array<{
    label: string;
    emptyText?: string;
    isEditable?: boolean;
  }>;
  sections?: Goal[][];
  activeTab?: string;
  onGoalStatusUpdate?: (t: GoalCycle, g?: Goal, a?: string) => void;
  canManageGoalWeights?: boolean;
  onClickGoalWeight?: (c: GoalCycle) => void;
  performanceCycleId?: string;
}

const GoalCycleGrid: FC<GoalCycleGridProps> = ({
  cycle,
  canEditGoals,
  onAddOrEditGoal,
  onDeleteGoal,
  entityType,
  sections = [],
  tabDefinitions,
  onGoalStatusUpdate,
  canManageGoalWeights = false,
  onClickGoalWeight,
  performanceCycleId,
  activeTab,
}) => {
  const isBeforeDeadline = dayjs().isSameOrBefore(cycle.goal_edit_deadline);

  const isClientGoal = entityType === 'client' || Boolean(performanceCycleId);

  const tabItems = useMemo(() => {
    if (sections.length <= 1) return [];
    return sections.map((goals, i) => {
      const isSectionEditable = tabDefinitions[i].isEditable;
      return {
        key: `${cycle.id}-section-${tabDefinitions[i].label}`,
        label: tabDefinitions[i].label,
        children:
          goals.length || isSectionEditable ? (
            <GoalCycleGridLayout
              data={goals}
              cycle={cycle}
              onAddOrEditGoal={onAddOrEditGoal}
              onGoalStatusUpdate={onGoalStatusUpdate}
              isBeforeDeadline={isBeforeDeadline}
              entityType={entityType}
              isClientGoal={isClientGoal}
              onDeleteGoal={onDeleteGoal}
              isEditable={isSectionEditable}
              canAddGoals={isSectionEditable && isBeforeDeadline}
              canManageGoalWeights={canManageGoalWeights}
              onClickGoalWeight={onClickGoalWeight}
            />
          ) : tabDefinitions[i].emptyText ? (
            <Typography variant='body1'>
              {tabDefinitions[i].emptyText}
            </Typography>
          ) : null,
      };
    });
  }, [
    tabDefinitions,
    entityType,
    cycle,
    isClientGoal,
    onAddOrEditGoal,
    onDeleteGoal,
    onGoalStatusUpdate,
    sections,
    canManageGoalWeights,
    onClickGoalWeight,
    isBeforeDeadline,
  ]);

  if (tabItems.length > 1) {
    return (
      <>
        {tabItems.map(tab => (
          <div
            key={`${tab.key}-panel`}
            role='tabpanel'
            hidden={(activeTab || tabItems[0].label) !== tab.label}
          >
            {(activeTab || tabItems[0].label) === tab.label && tab.children}
          </div>
        ))}
      </>
    );
  }

  return (
    <GoalCycleGridLayout
      className='goal-cycle-grid'
      data={sections[0] as Goal[]}
      cycle={cycle}
      isBeforeDeadline={isBeforeDeadline}
      onAddOrEditGoal={onAddOrEditGoal}
      onGoalStatusUpdate={onGoalStatusUpdate}
      entityType={entityType}
      isClientGoal={isClientGoal}
      onDeleteGoal={onDeleteGoal}
      isEditable={canEditGoals}
      canManageGoalWeights={canManageGoalWeights}
      canAddGoals={canEditGoals && isBeforeDeadline}
      onClickGoalWeight={() => {
        onClickGoalWeight(cycle);
      }}
    />
  );
};

interface GoalCycleGridLayoutProps {
  data: Goal[];
  cycle: GoalCycleWithGoals;
  onAddOrEditGoal: Function;
  entityType: 'user' | 'client';
  isBeforeDeadline?: boolean;
  isClientGoal: boolean;
  onDeleteGoal?: Function;
  isEditable: boolean;
  canAddGoals: boolean;
  className?: string;
  onGoalStatusUpdate: Function;
  canManageGoalWeights?: boolean;
  onClickGoalWeight: Function;
}

const GoalCycleGridLayout: FC<GoalCycleGridLayoutProps> = ({
  data,
  cycle,
  onAddOrEditGoal,
  entityType,
  isClientGoal,
  onDeleteGoal,
  isEditable,
  canAddGoals,
  className = '',
  onGoalStatusUpdate,
  canManageGoalWeights = false,
  onClickGoalWeight,
  isBeforeDeadline,
}) => {
  const goals = useMemo(() => {
    const filtered =
      entityType === 'client' ? data.filter(g => !g.aligned_goal_id) : data;
    return filtered;
  }, [entityType, data]);

  return (
    <Grid
      data={goals}
      className={className}
      addItemLabel={canAddGoals ? 'Add Goal' : undefined}
      onClickAddItem={
        canAddGoals ? () => onAddOrEditGoal('add', {}, cycle) : undefined
      }
      renderer={goal => {
        return (
          <div style={{ width: '100%' }}>
            <GoalCard
              goal={goal}
              key={goal.id}
              entityType={entityType}
              isEditable={isEditable}
              isBeforeDeadline={isBeforeDeadline}
              isUserGoal={Boolean(goal.user_ids?.length)}
              alignedGoals={cycle.goals?.filter(
                g =>
                  g.id === goal.aligned_goal_id ||
                  g.aligned_goal_id === goal.id,
              )}
              onAddOrEdit={alignedGoalId => {
                onAddOrEditGoal(
                  'edit',
                  alignedGoalId ? {} : goal,
                  cycle,
                  alignedGoalId,
                );
              }}
              {...(entityType === 'user'
                ? {
                    onGoalStatusUpdate: alignedGoalId =>
                      onGoalStatusUpdate(
                        cycle,
                        alignedGoalId ? {} : goal,
                        alignedGoalId,
                      ),
                  }
                : undefined)}
              onDelete={() => onDeleteGoal(goal)}
              hasAddButton={
                entityType === 'user' &&
                isClientGoal &&
                !goal.user_ids?.length &&
                isBeforeDeadline
              }
              canManageGoalWeights={canManageGoalWeights}
              onClickGoalWeight={onClickGoalWeight}
            />
          </div>
        );
      }}
    />
  );
};

export { GoalCycleGrid };
