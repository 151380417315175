import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import {
  Avatar,
  Button,
  ConfirmDialog,
  Spin,
  useToast,
} from '@src/components/sc-design-system';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Client from '@src/models/Client';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { api } from '@src/lib/client';

const HRISExistingIntegration: FC<{
  client: Client;
  setIsRemoved: (isRemoved: boolean) => void;
}> = ({ client, setIsRemoved }) => {
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const { useGetClientMergeHrisIntegration } = useClientQueries(client.id);
  const { data } = useGetClientMergeHrisIntegration();
  const queryClient = useQueryClient();
  const toast = useToast();

  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      await api.integrations.deleteMergeHrisIntegration(client.id);
      return;
    },
    onSuccess: () => {
      setIsRemoved(true);
      queryClient.invalidateQueries({
        queryKey: ['client', client.id, 'mergeHrisIntegration'],
      });
    },
    onError() {
      toast.error('Unable to disconnect HRIS integration');
    },
  });

  if (isPending) {
    return <Spin sectionLoader />;
  }

  return (
    <>
      <ConfirmDialog
        open={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        title='Disconnect confirmation'
        body='Completing this action will disconnect your HRIS. Your data will be saved based on the last HRIS sync.'
        buttonProps={{
          color: 'error',
          text: 'Disconnect',
          onClick: async () => {
            mutate();
          },
        }}
      />
      <Stack spacing={2}>
        <Stack spacing={2} direction='row'>
          <Avatar src={data?.squareImage} />
          <Typography variant='body1sb'>{data?.name}</Typography>
        </Stack>
        <Typography variant='body2' color='grey.700'>
          Last synced {dayjs(client.merge_last_modified).format('MM/DD/YYYY')}
        </Typography>
      </Stack>
      <Button
        startIcon={<HighlightOffIcon />}
        variant='outlined'
        text='Disconnect HRIS'
        formatText={false}
        onClick={handleOpenConfirmModal}
      />
    </>
  );
};

export { HRISExistingIntegration };
