import React from 'react';
import {
  Grid,
  Stack,
  Typography,
  ButtonBase,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import usePrevious from '@src/hooks/usePrevious';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import TrendingUp from '@mui/icons-material/TrendingUp';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';

export enum AidaUserDisplayMessage {
  summary = 'Can you provide me a brief summary of my feedback and development opportunities?',
  focus_areas = 'Identify focus areas for my growth',
  resources = 'Suggest relevant resources',
  coaches = 'Suggest coaches based on my needs',
}

interface AssistantButtonsProps {
  selectedPrompt: Prompts;
  setAssistantMessage: (m: AidaMessage) => void;
  setUserDisplayMessage: (m: string) => void;
  handlePromptSelection: HandlePromptSelection;
  conversations: Conversations;
}

interface AssistantButtonProps
  extends Omit<AssistantButtonsProps, 'conversations'> {
  prompt: Prompts;
  label: string;
  message: string;
  updatePromptOnSelect?: boolean;
  promptDisabled?: boolean;
}

interface Assistant {
  prompt: Prompts;
  label: string;
  message: string;
  updatePromptOnSelect?: boolean;
  promptDisabled?: boolean;
}

const assistantObj = (
  c: Conversations,
  prevConversations?: Conversations,
): Assistant[] => {
  return [
    {
      prompt: 'summary',
      label: 'Summarize my development opportunities',
      message: AidaUserDisplayMessage.summary,
    },
    {
      prompt: 'focus_areas',
      label: 'Identify focus areas for my growth',
      message: AidaUserDisplayMessage.focus_areas,
      promptDisabled: !c?.summary,
    },
    {
      prompt: 'resources',
      label: 'Suggest relevant resources',
      message: AidaUserDisplayMessage.resources,
      promptDisabled: !c?.summary || !c?.focus_areas,
    },
    {
      prompt: 'coaches',
      label: 'Recommend coaches based on my needs',
      message: AidaUserDisplayMessage.coaches,
      // promptDisabled unnecessary for coaches prompt - we must make the request so we can get 3 random coaches
      updatePromptOnSelect:
        c &&
        c?.summary &&
        (!c?.focus_areas ||
          (c?.focus_areas && !prevConversations?.focus_areas)),
    },
    {
      prompt: 'ask',
      label: 'Ask Aida',
      message: '',
    },
  ];
};

function AssistantButton(props: AssistantButtonProps) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const {
    selectedPrompt,
    setAssistantMessage,
    setUserDisplayMessage,
    handlePromptSelection,
    prompt,
    label,
    message,
    updatePromptOnSelect,
    promptDisabled,
  } = props;
  const Icon = {
    summary: FeedOutlinedIcon,
    focus_areas: TrendingUp,
    resources: MenuBookIcon,
    coaches: GroupOutlinedIcon,
    ask: ChatOutlinedIcon,
  }[prompt];

  return (
    <ButtonBase
      disableTouchRipple
      disableRipple
      sx={{
        height: { md: '152px', sm: '121.6px', xs: '114.5px' },
        width: { md: '175px', sm: '140px', xs: '131.25px' },
        overflow: 'hidden',
        borderRadius: '8px',
        boxShadow: '0 1px 3px 0 rgba(26,26,46,0.1), 0 0 0px rgba(26,26,46,0.1)',
        border: `1px solid`,
        borderColor: 'grey.200',
        padding: '1em',
        '&:focus': { backgroundColor: 'primary.100' },
        '&:hover': { backgroundColor: 'primary.100' },
        '&:active': { backgroundColor: 'primary.100' },
        '&:disabled': { backgroundColor: 'grey.200' },
        backgroundColor:
          selectedPrompt === prompt ? 'primary.100' : 'white.main',
        textAlign: 'start',
      }}
      onClick={() => {
        setAssistantMessage(null);
        setUserDisplayMessage(message);
        handlePromptSelection(prompt, updatePromptOnSelect, promptDisabled);
      }}
    >
      <Stack
        justifyContent='flex-start'
        spacing={{ xs: 1, md: 2 }}
        height='100%'
        width='100%'
      >
        <Icon color='primary' fontSize={'medium'} />
        <Typography variant={md ? 'body1m' : 'body2m'} component='span'>
          {label}
        </Typography>
      </Stack>
    </ButtonBase>
  );
}

const AssistantButtons: React.FunctionComponent<AssistantButtonsProps> = ({
  conversations,
  ...props
}) => {
  const prevConversations = usePrevious(conversations);
  const assistants = React.useMemo(
    function () {
      return assistantObj(conversations, prevConversations);
    },
    [conversations, prevConversations],
  );
  return (
    <Grid
      container
      direction='row'
      columns={{ md: 5, xs: 3 }}
      spacing={{ md: 4, xs: 2 }}
      justifyContent='center'
      width='100%'
      padding='0 2em'
    >
      {assistants.map(assistant => (
        <Grid item key={assistant.prompt}>
          <AssistantButton {...props} {...assistant} />
        </Grid>
      ))}
    </Grid>
  );
};

export { AssistantButtons };
