import React, { FC } from 'react';
import { useParams } from 'react-router';
import { Divider, Typography, Stack, Button } from '@mui/material';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import * as useVerbiage from '@shared/lib/src/use-verbiage';
import {
  useBreadcrumbs,
  Pagination,
  Spin,
  Card,
} from '@src/components/sc-design-system';
import { useClientQueries } from '@src/hooks/useClientQueries';
import { useQueryParams } from '@src/utils';
import { useNavigation } from '@src/navigation';
import ActiveInactiveChip from '@src/components/common/ActiveInactiveChip';

function Program(props) {
  const { program, idx, navigation, appendBreadcrumb } = props;
  const typeV = useVerbiage.init(
    program.type === 'mentoring'
      ? ['isMentor']
      : program.type === 'custom_coach_selection'
      ? ['isCustomCoachSelection']
      : [],
  );
  const sessionV = useVerbiage.init(
    program.sessions_reserved === 1 ? [] : ['plural'],
  );
  return (
    <React.Fragment key={program.id || `program-${idx}`}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h2'>{program.name}</Typography>
        <Button
          variant='text'
          size='small'
          onClick={() => {
            const path = `/program/${program.id}`;
            navigation.push(path);
            appendBreadcrumb({
              path,
              label: program.name,
            });
          }}
          endIcon={<ArrowForwardIcon />}
        >
          View Program
        </Button>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        divider={<Divider orientation='vertical' flexItem />}
        sx={{ marginTop: '.5em !important' }}
      >
        <Typography variant='body2' color='grey.700'>
          {typeV('1:1 coaching', true)}
        </Typography>
        <Typography variant='body2' color='grey.700'>
          {dayjs(program.start_date).format('ll')} -{' '}
          {dayjs(program.end_date).format('ll')}
        </Typography>
        <Typography variant='body2' color='grey.700'>
          {program.sessions_reserved} {sessionV('session', true)}
        </Typography>
      </Stack>
    </React.Fragment>
  );
}

const ClientSOWsAndPrograms: FC = observer(() => {
  const { id } = useParams();
  const query = useQueryParams();
  const page = +query.get('page') || 1;
  const navigation = useNavigation();
  const isActive = (start: number, end: number): boolean =>
    start < Date.now() && end > Date.now();

  const { appendBreadcrumb } = useBreadcrumbs();

  const { useGetClientSOWs } = useClientQueries(id);
  const { sows, totalRecords, isLoading } = useGetClientSOWs({ page });

  return (
    <Card>
      {isLoading ? (
        <Spin sectionLoader />
      ) : (
        <>
          {sows?.length &&
            sows?.map((sow, sIdx) => {
              const isSOWActive = isActive(sow.start_date, sow.end_date);
              return (
                <Card
                  key={sow.id || `sow-${sIdx}`}
                  sx={{ marginBottom: '2em' }}
                  headerColor={!isSOWActive ? 'grey' : 'primary'}
                  collapsible={true}
                  title={sow.name}
                  titleActions={[
                    <ActiveInactiveChip
                      active={isSOWActive}
                      key={`status-indicator-${sIdx}`}
                    />,
                  ]}
                  subheader={
                    <Stack
                      direction='row'
                      spacing={2}
                      // divider={<Divider orientation='vertical' flexItem />}
                    >
                      <Typography
                        variant='body1'
                        color={isSOWActive ? 'white.main' : 'black.main'}
                      >
                        {sow.options?.custom_coach_selection
                          ? 'Custom Coach Selection'
                          : sow.options?.mentoring
                          ? 'Mentoring'
                          : 'Standard'}
                      </Typography>
                      <Typography
                        variant='body1'
                        color={isSOWActive ? 'white.main' : 'black.main'}
                      >
                        {dayjs(sow.start_date).format('ll')} -{' '}
                        {dayjs(sow.end_date).format('ll')}
                      </Typography>
                    </Stack>
                  }
                >
                  <Stack spacing={4} divider={<Divider />}>
                    {sow.programs.length > 0 ? (
                      sow.programs.map((program, pIdx) => (
                        <Program
                          key={pIdx}
                          program={program}
                          idx={pIdx}
                          navigation={navigation}
                          appendBreadcrumb={appendBreadcrumb}
                        />
                      ))
                    ) : (
                      <Typography
                        variant='body2'
                        color='grey.700'
                        sx={{ textAlign: 'center' }}
                      >
                        No program has been assigned to this SOW.
                      </Typography>
                    )}
                  </Stack>
                </Card>
              );
            })}
          <Stack alignItems='center' sx={{ marginTop: '25px' }}>
            <Pagination
              page={+page}
              totalItems={totalRecords}
              pageSize={25}
              onChange={(_, value) => {
                query.set('page', value.toString());
                navigation.replace(
                  `${navigation.location.pathname}?${query}`,
                  navigation.location.state || {},
                );
              }}
            />
          </Stack>
        </>
      )}
    </Card>
  );
});

export { ClientSOWsAndPrograms };
