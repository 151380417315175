import React, { FC } from 'react';
import { Table as AntdTable, TableProps as AntdTableProps } from 'antd';

export interface TableProps extends AntdTableProps<any> {
  hidePagination?: boolean;
}

const Table: FC<TableProps> = ({ hidePagination, pagination, ...props }) => {
  return (
    <AntdTable
      pagination={hidePagination ? { position: [] } : pagination}
      {...props}
    />
  );
};

export { Table };
