import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';

obey.creator('uuid', () => uuidv4());
obey.creator('now', () => Date.now());
obey.modifier('emptyToNull', val => (!val || val.length === 0 ? null : val));
obey.modifier('now', () => Date.now());

export enum ComparisonType {
  Department = 'department',
  Program = 'program',
}

export enum CollectionStatus {
  Draft = 'draft',
  Sent = 'sent',
}

export const surveyTypes = [
  'intake',
  '360_self',
  '360_stakeholder',
  'midpoint_engagement',
  'final_engagement',
];

const groupTypes = ['competency'];

export const SurveyQuestionSchema = {
  id: { type: 'string', required: true, creator: 'uuid' },
  client_id: { type: 'string' },
  inQuestionBank: { type: 'boolean', default: false },
  required: { type: 'boolean', default: false },
  name: {
    type: 'string',
    empty: true,
  },
  title: { type: 'string', required: true },
  helpText: { type: 'string', empty: true },
  privacyText: {
    type: 'string',
    empty: true,
  },
  logo: { type: 'string', empty: true },
  allow_other: { type: 'boolean', default: false }, // ...and show text box
  other_answer_required: { type: 'boolean', default: false }, //for other text box
  allow_none: { type: 'boolean', default: false }, // "None of the Above"
  isNumberInput: { type: 'boolean', default: false },
  type: {
    type: 'string',
    required: true,
    allow: [
      'message',
      'text',
      'single-select',
      'multi-select',
      'range',
      'textarea',
      'competency',
    ],
  },
  meta_data: { type: 'object' },
  condition: { type: 'array', values: { type: 'any' } },
  options: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        id: { type: 'string', required: true, creator: 'uuid' },
        value: { type: 'any', required: true },
        text: { type: 'string', required: true },
        index_position: { type: 'number' },
      },
    },
  },
  range_maximum: { type: 'number', default: 10 },
  range_labels: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        value: { type: 'number' },
        text: { type: 'string', empty: true },
      },
    },
  },
  updatedAt: { type: 'number' },
};

export const SurveySubmissionSchema = {
  id: { type: 'string', required: true, creator: 'uuid' },
  submission_id: { type: 'string' },
  survey_id: { type: 'string', required: true },
  user_id: { type: 'string', required: true },
  hiddens: { type: 'object', strict: false },
  submitted: { type: 'number' },
  results: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        question: { type: 'object', keys: SurveyQuestionSchema },
        answer: { type: 'any' },
        other_value: { type: 'string', empty: true }, // for allow_other questions' data
      },
    },
    empty: true,
  },
  is_draft: { type: 'boolean', default: false },
};

export const SurveySchema = {
  id: { type: 'string', required: true, creator: 'uuid' },
  client_id: { type: 'string' },
  type: { type: 'string', allow: surveyTypes, allowNull: true },
  title: { type: 'string', required: true },
  hidden: {
    type: 'array',
    values: { type: 'string' },
    default: [],
    empty: true,
  },
  on_submit: {
    type: 'object',
    keys: {
      submit_title: {
        type: 'string',
        empty: 'true', //TODO: Remove after migration
        default: 'Thank you!',
      },
      submit_message: {
        type: 'string',
        empty: 'true', //TODO: Remove after migration
        default: 'You have successfully submitted a response to the survey.',
      },
      action: {
        type: 'array',
        values: { type: 'string' },
        default: [],
        empty: true,
      },
    },
  },
  questions: {
    type: 'array',
    required: true,
    min: 1,
    values: {
      type: 'object',
      keys: {
        id: { type: 'string' },
        condition: { type: 'array', values: { type: 'any' } },
        group: {
          type: 'object',
          keys: {
            id: { type: 'string', creator: 'uuid' },
            title: { type: 'string' },
            helpText: { type: 'string', default: true },
            condition: {
              type: 'array',
              values: {
                type: 'any',
              },
            },
            questions: {
              type: 'array',
              values: {
                type: 'any',
              },
            },
            type: { type: 'string', allow: groupTypes },
          },
        },
      },
    },
  },
  archived_at: { type: 'number' },
  description: { type: 'string', empty: true },
};

export const PublishedSurveySchema = {
  id: { type: 'uuid', creator: 'uuid' },
  survey_id: { type: 'uuid', required: true },
  client_id: { type: 'string' },
  type: { type: 'string', allow: surveyTypes, allowNull: true },
  title: { type: 'string', required: true },
  notes: { type: 'string' },
  hidden: {
    type: 'array',
    values: { type: 'string' },
    default: [],
    empty: true,
  },
  on_submit: {
    type: 'object',
    keys: {
      submit_title: {
        type: 'string',
        empty: 'true', //TODO: Remove after migration
        default: 'Thank you!',
      },
      submit_message: {
        type: 'string',
        empty: 'true', //TODO: Remove after migration
        default: 'You have successfully submitted a response to the survey.',
      },
      action: {
        type: 'array',
        values: { type: 'string' },
        default: [],
        empty: true,
      },
    },
  },
  questions: {
    type: 'array',
    required: true,
    min: 1,
    values: {
      type: 'object',
      keys: {
        ...SurveyQuestionSchema,
        condition: { type: 'array', values: { type: 'any' } },
        group: {
          type: 'object',
          keys: {
            id: { type: 'string' },
            title: { type: 'string' },
            helpText: { type: 'string', default: true },
            condition: {
              type: 'array',
              values: {
                type: 'any',
              },
            },
            questions: {
              type: 'array',
              values: {
                type: 'any',
              },
            },
            type: { type: 'string', allow: groupTypes },
            meta_data: { type: 'object' },
          },
        },
      },
    },
  },
  published_at: { type: 'number', creator: 'now' },
  description: { type: 'string', empty: true },
};

export const SurveyCollectionSchema = {
  id: { type: 'uuid', creator: 'uuid', required: true },
  client_id: { type: 'string' },
  survey_id: { type: 'uuid', required: true },
  name: { type: 'string' },
  platform_notification: {
    type: 'object',
    keys: {
      enabled: {
        type: 'boolean',
      },
      message: {
        type: 'string',
        default: 'You have a survey to complete!',
      },
    },
  },
  email_notification: {
    type: 'object',
    keys: {
      enabled: {
        type: 'boolean',
      },
      message: {
        type: 'string',
      },
    },
  },
  status: {
    type: 'string',
    allow: Object.values(CollectionStatus),
  },
  recipients: {
    type: 'array',
    values: {
      type: 'object',
      keys: {
        id: { type: 'string' },
        email: { type: 'string' },
        last_sent: { type: 'number' },
        send_error: { type: 'boolean' },
        submission_id: { type: 'string' },
      },
    },
    empty: true,
  },
  updatedAt: { type: 'number', modifier: 'now' },
};

// eslint-disable-next-line no-unused-vars
export type SurveyType = { [K in keyof typeof SurveySchema]?: any };
export type PublishedSurveyType = {
  [K in keyof typeof PublishedSurveySchema]?: any;
};
export type SurveyQuestionType = {
  [K in keyof typeof SurveyQuestionSchema]?: any;
};
export type SurveySubmissionType = {
  [K in keyof typeof SurveySubmissionSchema]?: any;
};
export type SurveyCollectionType = {
  [K in keyof typeof SurveyCollectionSchema]?: any;
};

export const SurveyModel = obey.model(SurveySchema);
export const SurveyQuestionModel = obey.model(SurveyQuestionSchema);
export const SurveySubmissionModel = obey.model(SurveySubmissionSchema);
export const PublishedSurveyModel = obey.model(PublishedSurveySchema);
export const SurveyCollectionModel = obey.model(SurveyCollectionSchema);
