import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Typography, Stack, Alert } from '@mui/material';
import CreateSessionModal from './CreateSessionModal';
import SessionTrackerStatus from './SessionTrackerStatus';
import Program from '@src/models/Program';
import User from '@src/models/User';
import SessionDetails from '../SessionDetails/SessionDetails';
import { SessionDetailsModal } from '@src/components/common/SessionDetails/SessionDetailsModal';
import {
  CreateSessionButton,
  SessionChip,
  SessionChipWithPopover,
} from '@src/components/common/SessionTracker/components';

export interface SessionTrackerPropsIF {
  user: User;
  member: User;
  allowedSessions?: number;
  coacheeSessions?: any[];
  role?: string;
  onSessionStatusChange?: Function;
  detailMode?: boolean;
  bookSessionButton?: React.ReactElement;
  program: Program;
}

const getSessionsObject = (
  sessionsByProgram,
  programId,
  memberId,
  allowedSessions,
) => {
  if (!sessionsByProgram[programId])
    return { list: [], numSessions: allowedSessions || 0 };
  const list =
    sessionsByProgram[programId]
      .filter(s => s.members.some(m => m.id === memberId))
      .filter(
        s =>
          s.calendly_event &&
          s.current_status !== 'canceled' &&
          s.type === 'coaching',
      )
      .sort((a, b) =>
        a.calendly_event.start_time > b.calendly_event.start_time ? 1 : -1,
      ) || [];
  const membersInfo = list[0]?.program_info?.members?.find(
    m => m.id === memberId,
  );
  return {
    list,
    numSessions: allowedSessions
      ? allowedSessions
      : membersInfo
      ? membersInfo.sessions_allocated ||
        (membersInfo.sessions_allocated === 0
          ? 0
          : list[0]?.program_info.sessions_per_member || 0)
      : 0,
  };
};

const SessionTracker: FC<SessionTrackerPropsIF> = observer(
  ({
    member,
    user,
    allowedSessions,
    coacheeSessions,
    role = 'coachee',
    onSessionStatusChange,
    detailMode,
    program,
  }) => {
    const { coachingSessionsByProgram } = user;
    const [sessionsTrackerInfo, setSessionTrackerInfo] = useState(
      getSessionsObject(
        coacheeSessions
          ? {
              [program.id]: coacheeSessions.filter(s =>
                s.members.some(m => m.id === user.id && m.role === role),
              ),
            }
          : coachingSessionsByProgram,
        program.id,
        member.id || user.id,
        allowedSessions,
      ),
    );
    const [statusList, setStatusList] = useState([]);
    const [selectedSessionIndex, setSelectedSessionIndex] = useState<
      null | number
    >(null);
    const [selectedSessionStatus, setSelectedSessionStatus] = useState();

    const [sessionDetailsOpen, setSessionDetailsOpen] = useState<
      boolean | string
    >(false);
    const [createSessionModalOpen, setCreateSessionModalOpen] =
      useState<boolean>(false);

    const updateSelectedSessionIndex = (index, status) => {
      setSelectedSessionIndex(index);
      setSelectedSessionStatus(status);
    };

    const buildStatusList = (memberSessions, numSessions) => {
      const items = [];
      let lastScheduled = false;

      const defaultEnd = memberSessions.length - 1;
      const nextUpcoming = memberSessions.findIndex(s => {
        return (
          s.current_status === 'scheduled' &&
          s.calendly_event?.end_time_time > Date.now()
        );
      });
      if (nextUpcoming >= 0) {
        const status =
          memberSessions[nextUpcoming]?.calendly_event?.end_date <
          Date.now() * 1000 * 60 * 30
            ? 'missed'
            : 'scheduled';
        updateSelectedSessionIndex(nextUpcoming, status);
      } else {
        const record = memberSessions[defaultEnd];
        const status =
          record?.calendly_event?.end_date < Date.now()
            ? 'missed'
            : record?.current_status;
        updateSelectedSessionIndex(defaultEnd, status);
      }

      for (let index = 0; index < numSessions; index++) {
        const curSession = memberSessions[index];
        let color = 'default';
        let status = curSession?.current_status;
        // set some display values
        if (curSession?.current_status === 'completed') {
          color = 'tertiary';
        } else if (
          curSession?.current_status === 'scheduled' &&
          curSession?.calendly_event?.end_time > Date.now() + 1000 * 60 * 30
        ) {
          color = !lastScheduled ? 'primary' : 'info';
          status = !lastScheduled ? 'upcoming' : 'scheduled';
          if (!lastScheduled) {
            lastScheduled = true;
          }
        } else if (
          curSession?.current_status === 'scheduled' &&
          curSession?.calendly_event?.end_time < Date.now() + 1000 * 60 * 30
        ) {
          color = 'error';
          status = 'missed';
          lastScheduled = !lastScheduled;
        }

        items.push({ index, color, status });
        setStatusList(items);
      }
    };

    useEffect(() => {
      setSessionTrackerInfo(
        getSessionsObject(
          coacheeSessions
            ? {
                [program.id]: coacheeSessions.filter(
                  s => s.current_status !== 'canceled',
                ),
              }
            : coachingSessionsByProgram,
          program.id,
          member.id || user.id,
          allowedSessions,
        ),
      );
      // FIXME: invalid dependency array
      // eslint-disable-next-line
    }, [allowedSessions, coacheeSessions]);

    useEffect(() => {
      buildStatusList(
        sessionsTrackerInfo.list,
        sessionsTrackerInfo.numSessions,
      );
      // FIXME: invalid dependency array
      // eslint-disable-next-line
    }, [sessionsTrackerInfo]);

    const userRole = sessionsTrackerInfo.list[
      selectedSessionIndex
    ]?.members?.find(m => m.id === user.id)?.role;

    return (
      sessionsTrackerInfo && (
        <div>
          <>
            {sessionsTrackerInfo.numSessions === 0 && allowedSessions !== 0 ? (
              <Alert severity='warning'>
                <Typography variant='body2'>
                  No sessions have been booked
                </Typography>
              </Alert>
            ) : (
              <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
                {statusList.map((i, idx) => {
                  return i.status === undefined ? (
                    <SessionChip
                      key={i.index}
                      session={i}
                      updateSelectedSessionIndex={updateSelectedSessionIndex}
                      variant={
                        selectedSessionIndex === idx ? 'filled' : 'outlined'
                      }
                    />
                  ) : (
                    <SessionChipWithPopover
                      key={i.index}
                      session={i}
                      updateSelectedSessionIndex={updateSelectedSessionIndex}
                      sessionsTrackerInfo={sessionsTrackerInfo}
                      program={program}
                      user={user}
                      variant={
                        selectedSessionIndex === idx ? 'filled' : 'outlined'
                      }
                    />
                  );
                })}
                {member.selected_coach?.id &&
                  (sessionsTrackerInfo.list.length || 0) <
                    sessionsTrackerInfo.numSessions &&
                  user.is_admin && (
                    <CreateSessionButton
                      setCreateSessionModalOpen={setCreateSessionModalOpen}
                    />
                  )}
                {createSessionModalOpen && (
                  <CreateSessionModal
                    sessionsTrackerInfo={sessionsTrackerInfo}
                    setSessionTrackerInfo={setSessionTrackerInfo}
                    setCreateSessionModalOpen={setCreateSessionModalOpen}
                    program={program}
                    member={member}
                    user={user}
                  />
                )}
              </Stack>
            )}
          </>
          {detailMode ? (
            <SessionDetails
              session={sessionsTrackerInfo.list[selectedSessionIndex]}
              userRole={userRole}
            />
          ) : (
            <>
              {!!sessionsTrackerInfo.list &&
              selectedSessionIndex >= 0 &&
              sessionsTrackerInfo.list[selectedSessionIndex] ? (
                <>
                  <SessionTrackerStatus
                    session={sessionsTrackerInfo.list[selectedSessionIndex]}
                    status={selectedSessionStatus}
                    userId={user.id}
                    isAdmin={user.is_admin}
                    onSessionStatusChange={onSessionStatusChange}
                  />
                  <SessionDetailsModal
                    session={sessionsTrackerInfo.list[selectedSessionIndex]}
                    userRole={userRole}
                    onClose={() => setSessionDetailsOpen(false)}
                    open={
                      sessionDetailsOpen ===
                      sessionsTrackerInfo.list[selectedSessionIndex].id
                    }
                  />
                </>
              ) : (
                // Stack is used so it matches how scheduled session info appears
                <Stack spacing={1} sx={{ marginTop: '1em' }}>
                  <Typography variant='body2' color='grey.700'>
                    Session not scheduled
                  </Typography>
                </Stack>
              )}
            </>
          )}
        </div>
      )
    );
  },
);

export default SessionTracker;
