/* eslint no-useless-escape: 0 */
import React, { useState, FC, useEffect } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { InputField } from '@src/components/sc-design-system';
import { Alert, Typography } from '@mui/material';

interface PRProps {
  isRecovery?: boolean;
  onChange: Function;
}

const PasswordReset: FC<PRProps> = ({ isRecovery, onChange }) => {
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [newPasswordValid, setNewPasswordValid] = useState(false);

  const pwRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/;

  useEffect(
    () => {
      const currentPasswordValid = !isRecovery
        ? currentPassword.length >= 8
        : true;
      const isValid = Boolean(
        currentPasswordValid && pwRegex.test(newPassword),
      );
      onChange({
        valid: isValid && newPassword === newPasswordConfirm,
        currentPassword,
        newPassword,
      });
      setNewPasswordValid(isValid);
    },
    // FIXME: invalid dependency array
    // eslint-disable-next-line
    [currentPassword, newPassword, newPasswordConfirm],
  );

  return (
    <Form layout='vertical'>
      {!isRecovery && (
        <InputField
          label={t('Current Password')}
          rules={[{ required: true, min: 8 }]}
          value={currentPassword}
          onChange={setCurrentPassword}
        />
      )}
      <Alert
        sx={{
          fontSize: '.9em',
          marginBottom: '1.5em',
          padding: '1em',
        }}
        icon={false}
        severity={
          newPasswordValid && newPassword === newPasswordConfirm
            ? 'success'
            : 'warning'
        }
      >
        <Typography variant='body2'>
          <strong>
            {!newPasswordValid
              ? 'Please use letters, numbers, and special characters'
              : newPassword !== newPasswordConfirm
              ? 'Both passwords must match'
              : 'Your password is valid!'}
          </strong>
        </Typography>
      </Alert>
      <InputField
        label={t('New Password')}
        type='password'
        rules={[{ required: true, min: 8 }]}
        value={newPassword}
        onChange={setNewPassword}
      />
      <InputField
        type='password'
        label={t('Confirm Password')}
        value={newPasswordConfirm}
        onChange={setNewPasswordConfirm}
        rules={[
          { required: true, min: 8 },
          () => ({
            validator(_, value) {
              if (!value || newPassword === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('Passwords must match')));
            },
          }),
        ]}
      />
    </Form>
  );
};

export default PasswordReset;
