import React, { FC } from 'react';
import dayjs from 'dayjs';
import { Table } from 'antd';
import startCase from 'lodash/startCase';
import { Button, DialogTitle } from '@src/components/sc-design-system';
import GoalStatusIndicator from '@src/components/common/GoalCard/GoalStatusIndicator';
import {
  Typography,
  Stack,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useFullScreen } from '@src/hooks/useFullScreen';

interface AlignedGoalsModalProps {
  open: boolean;
  goal: Goal;
  alignedGoals: Goal[];
  handleClose(): void;
}

const AlignedGoalsModal: FC<AlignedGoalsModalProps> = ({
  open,
  goal,
  alignedGoals,
  handleClose,
}) => {
  const { fullScreen } = useFullScreen();
  const alignedGoalsDataSource = alignedGoals.map((ag, i) => {
    ag['key'] = i;
    return ag;
  });
  return (
    <Dialog
      open={open}
      maxWidth='lg'
      onClose={handleClose}
      fullScreen={fullScreen}
    >
      <DialogTitle
        title={
          <Stack spacing={1}>
            <Typography variant='h3'>{goal.title}</Typography>
            <Stack
              direction='row'
              divider={<Divider orientation='vertical' flexItem />}
              spacing={2}
            >
              {Boolean(goal.type) && (
                <Typography variant='body2' color='grey.700'>
                  {startCase(goal.type)}
                </Typography>
              )}
              {Boolean(goal.target_date) && (
                <Typography variant='body2' color='grey.700'>
                  By {dayjs(goal.target_date).format('M/D/YY')}
                </Typography>
              )}
            </Stack>
          </Stack>
        }
        buttonOnClick={handleClose}
      />
      <DialogContent>
        <Typography variant='h4'>Description</Typography>
        <Typography
          variant='body1'
          dangerouslySetInnerHTML={{ __html: goal.description }}
        />
        <Divider orientation='horizontal' color='grey.300' />
        <Typography variant='h4' sx={{ marginBottom: 1 }}>
          Aligned Goals
        </Typography>
        <Typography variant='body2' sx={{ marginBottom: '1.5em' }}>
          The following goals have been aligned to the organizational goal{' '}
          {goal.title} by your members.
        </Typography>
        <Table
          dataSource={alignedGoalsDataSource}
          pagination={false}
          columns={[
            {
              title: 'Employee',
              dataIndex: 'user_ids',
              key: 'employee',
              render: users => `${users[0].first_name} ${users[0].last_name}`,
              width: '25%',
            },
            { title: 'Goal Name', dataIndex: 'title', key: 'goal-name' },
            {
              title: 'Status',
              dataIndex: 'current_status',
              key: 'status',
              render: (_, goal) => {
                const currentStatus =
                  goal.status_history?.[goal.status_history.length - 1];
                return <GoalStatusIndicator status={currentStatus?.status} />;
              },
            },
            {
              title: 'Target Date',
              dataIndex: 'target_date',
              key: 'target_date',
              render: date => dayjs(date).format('MM/DD/YYYY'),
              width: '22%',
            },
          ]}
          expandable={{
            expandedRowRender: record => (
              <Typography
                variant='body1'
                dangerouslySetInnerHTML={{ __html: record.description }}
              />
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button key='cancel' text='Close' onClick={handleClose} />
      </DialogActions>
    </Dialog>
  );
};

export { AlignedGoalsModal };
