import React, { FC, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import WYSIWYG from '@src/components/common/WYSIWYG';
import { STATIC_EMAIL_CONTENT } from '../../../../lib/constants';
import { StyledCard } from '../../../../StyledComponents';
import { Button } from '@src/components/sc-design-system';

interface NotificationsSectionProps {
  isDraftMode: boolean;
  onEditMessage: Function;
  isSaving?: boolean;
}

const NotificationsSection: FC<NotificationsSectionProps> = ({
  isDraftMode,
  onEditMessage,
  isSaving,
}) => {
  return (
    <Stack spacing={1} gap='1em'>
      <Typography variant='h4'>Notification settings</Typography>
      <NotificationsSubsection
        heading='In-platform notification'
        tooltipText='Registered members will be notified via SkillCycle platform'
        checkboxLabel='Notify via platform'
        formProp='platform_notification'
        messageCardHeader='Message'
        isEditable={false}
        isDraftMode={isDraftMode}
      />
      <NotificationsSubsection
        heading='Email notification'
        tooltipText='All recipients will be notified via email'
        checkboxLabel='Notify via email'
        formProp='email_notification'
        messageCardHeader='Custom Message'
        staticSubstring={STATIC_EMAIL_CONTENT}
        onEditMessage={onEditMessage}
        isEditable={isDraftMode && !isSaving}
        isDraftMode={isDraftMode}
      />
    </Stack>
  );
};

interface NotificationsSubsectionProps {
  heading: string;
  tooltipText: string;
  checkboxLabel: string;
  messageCardHeader: string;
  formProp: string;
  staticSubstring?: string;
  isDraftMode?: boolean;
  isEditable?: boolean;
  onEditMessage?: Function;
}
const NotificationsSubsection: FC<NotificationsSubsectionProps> = ({
  heading,
  tooltipText,
  checkboxLabel,
  messageCardHeader,
  formProp,
  staticSubstring = '',
  isEditable,
  isDraftMode,
  onEditMessage,
}) => {
  const { control, getValues, setValue } = useFormContext();
  const [isEditing, setIsEditing] = useState(false);
  const toggleEdit = () => {
    setIsEditing(!isEditing);
    if (onEditMessage) {
      onEditMessage(!isEditing);
    }
  };

  return (
    <Stack spacing={1}>
      <div style={{ display: 'inline-flex', gap: 4, alignItems: 'center' }}>
        <Typography variant='body1m' style={{ marginBottom: 0 }}>
          {heading}
        </Typography>
        <Tooltip title={tooltipText} arrow>
          <InfoIcon fontSize='small' />
        </Tooltip>
      </div>
      <FormControlLabel
        control={
          <Controller
            name={`${formProp}.enabled`}
            control={control}
            render={({ field }) => (
              <Checkbox {...field} checked={field.value} disableRipple />
            )}
          />
        }
        disabled={!isDraftMode}
        label={checkboxLabel}
      />
      {!isEditing ? (
        <StyledCard>
          <Stack spacing={1.5}>
            <div
              style={{
                display: 'inline-flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant='h5'>{messageCardHeader}</Typography>
              {isEditable && (
                <Button
                  variant='text'
                  text='Edit'
                  startIcon='editOutlined'
                  onClick={() => toggleEdit()}
                />
              )}
            </div>
            <div
              style={{ marginTop: 0 }}
              dangerouslySetInnerHTML={{
                __html: getValues(`${formProp}.message`),
              }}
            />
          </Stack>
        </StyledCard>
      ) : (
        <>
          <Controller
            name={`${formProp}.message`}
            control={control}
            render={({ field }) => (
              <WYSIWYG
                {...field}
                value={field.value.replace(staticSubstring, '')}
              />
            )}
          />
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              text='Save'
              onClick={() => {
                let message = getValues(`${formProp}.message`);
                if (!message.match(staticSubstring)) {
                  message += staticSubstring;
                }
                setValue(`${formProp}.message`, message);
                toggleEdit();
              }}
            />
          </div>
        </>
      )}
    </Stack>
  );
};

export { NotificationsSection };
