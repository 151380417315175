import React, { useState, useMemo, FC } from 'react';
import { Button, ButtonProps } from '@src/components/sc-design-system/Button';
import { Box } from '@mui/material';
import { useUser } from '@src/hooks/useUser';
import { SurveySubmissionModal } from '@src/components/common/SurveySubmissionModal';
import { RequestFeedbackModal } from '@src/components/common/RequestFeedbackModal';
import { FileUploadModal } from '@src/components/common/FileUploadModal';
import { useQueryClient } from '@tanstack/react-query';

interface ModalActionProps {
  task: SummaryInputTask;
  buttonText: string;
  variant?: ButtonProps['variant'];
  clientId?: string;
  buttonDisabled?: boolean;
}

const ModalAction: FC<ModalActionProps> = ({
  task,
  buttonText,
  variant = 'contained',
  clientId,
  buttonDisabled = false,
}) => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const [isSubmissionModalOpen, setIsSubmissionModalOpen] = useState(false);
  const [isFilesModalOpen, setIsFilesModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const actions = useMemo(
    () => ({
      intake: setIsSubmissionModalOpen,
      files: setIsFilesModalOpen,
      feedback: setIsFeedbackModalOpen,
    }),
    [],
  );

  const onClick = useMemo(() => {
    return actions[task.type];
  }, [task.type, actions]);

  return (
    <Box>
      <Button
        disabled={buttonDisabled}
        text={buttonText}
        formatText={false}
        onClick={() => onClick(true)}
        size='small'
        variant={variant}
        sx={{ width: { md: '195px', xs: 'fit-content' } }}
      />
      {isSubmissionModalOpen && (
        <SurveySubmissionModal
          surveySubmission={task?.meta?.surveySubmission}
          onClose={() => setIsSubmissionModalOpen(false)}
          title={task?.meta?.surveyTitle}
        />
      )}
      {isFeedbackModalOpen && (
        <RequestFeedbackModal
          open={isFeedbackModalOpen}
          onClose={() => setIsFeedbackModalOpen(false)}
          clientId={clientId}
          userId={user.id}
          userFirstName={user.first_name}
          userLastName={user.last_name}
          performanceCycleId={task?.meta?.performanceCycleId}
          feedbackFormId={task?.meta?.feedbackFormId}
        />
      )}
      {isFilesModalOpen && (
        <FileUploadModal
          open={isFilesModalOpen}
          onClose={() => setIsFilesModalOpen(false)}
          onSuccess={async (key: string) => {
            // TODO: refactor to remove mobx
            user.documents.push(key);
            await user.save();
            queryClient.invalidateQueries({
              queryKey: ['user', user.id, 'summary-inputs'],
            });
          }}
          accept={{
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
              ['.docx'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              ['.xlsx'],
            'text/csv': ['.csv'],
            'text/html': ['.htm', '.html'],
            'text/markdown': ['.md'],
            'text/plain': ['.txt'],
          }}
          maxSize={4500000} /* 4.5 MB */
        />
      )}
    </Box>
  );
};

export { ModalAction };
