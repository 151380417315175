import React from 'react';
import { Button } from '@src/components/sc-design-system';
import { ButtonProps } from '@src/components/sc-design-system/Button';
import AddIcon from '@mui/icons-material/Add';
import { GoalCycleManager } from '@src/components/common/GoalCycleManager';

const CreateGoalCycleButton: React.FunctionComponent<{
  performanceCycleId: string;
  clientId: string;
  text?: string;
  variant?: ButtonProps['variant'];
  onCreate?: Function;
  showIcon?: boolean;
}> = ({
  performanceCycleId,
  clientId,
  variant = 'outlined',
  text = 'Create goal cycle',
  showIcon = true,
  onCreate,
}) => {
  const [openCreateModal, setOpenCreateModal] = React.useState(false);

  return (
    <>
      <GoalCycleManager
        open={openCreateModal}
        clientId={clientId}
        performanceCycleId={performanceCycleId}
        onClose={() => setOpenCreateModal(false)}
        onCreate={onCreate}
      />
      <Button
        sx={{ width: 'fit-content' }}
        text={text}
        variant={variant}
        startIcon={showIcon ? <AddIcon /> : undefined}
        onClick={() => setOpenCreateModal(true)}
      />
    </>
  );
};

export { CreateGoalCycleButton };
