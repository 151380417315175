import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import _partition from 'lodash/partition';
import { api } from '@src/lib/client';
import Client from '@src/models/Client';
import SurveyType from '@src/models/Survey';
import { useStore } from '@src/models/Store';
import { CompetencySet } from '@src/models/CompetencySet';
import { useActiveClient, ActiveClientKey } from './useActiveClient';

const useClientQueries = (clientId: string) => {
  const store = useStore();
  const { client: activeClient } = useActiveClient({
    queryKey: ActiveClientKey.profile_active_client_id,
  });
  const isSCAdminOrClientMember = useMemo(() => {
    return (
      store.user.is_admin ||
      (activeClient?.id === clientId &&
        store.user?.clients?.some(({ id }) => id === clientId))
    );
  }, [store, activeClient, clientId]);

  const useGetClient = () =>
    useQuery({
      queryKey: ['client', clientId],
      queryFn: async () => api.clients.getClient(clientId),
      select: ({ data }) => new Client(data),
      enabled: !!clientId && isSCAdminOrClientMember,
    });

  const useGetClientFields = return_fields =>
    useQuery({
      queryKey: ['client', clientId, return_fields],
      queryFn: async () =>
        api.clients.getClientFields(clientId, { return_fields }),
      select: ({ data }) => data.invitees || [],
      enabled: !!clientId && isSCAdminOrClientMember,
    });

  const useGetClientSOWs = (params: Omit<GetSOWsProps, 'client'> = {}) => {
    const { data, ...rest } = useQuery({
      queryKey: ['client', clientId, 'sows', params],
      queryFn: async () => api.sows.get({ client: clientId, ...params }),
      select: ({ data }) => data,
      enabled: !!clientId && isSCAdminOrClientMember,
    });

    return {
      sows: data?.data ?? null,
      totalRecords: data?.totalRecords ?? null,
      ...rest,
    };
  };

  const useGetClientCompetencies = () => {
    const { data, ...rest } = useQuery({
      queryKey: ['client', clientId, 'competencies'],
      queryFn: async () => api.goals_competencies.getSets('client', clientId),
      select: res =>
        res.data.reduce((arr, item) => {
          if (item?.competency_sets) {
            item.competency_sets.forEach(set => {
              arr.push(new CompetencySet(set));
            });
          }
          return arr;
        }, []),
    });
    return {
      competencySets: data ?? null,
      ...rest,
    };
  };

  const useGetPerformanceCycleGoals = (performanceCycleId: string) => {
    const { data, ...rest } = useQuery({
      queryKey: [
        'client',
        clientId,
        'performanceCycle',
        performanceCycleId,
        'goalCycles',
      ],
      queryFn: async () =>
        api.clients.getClientGoalsByPerformanceCycleId(
          clientId,
          performanceCycleId,
        ),
      select: ({ data }) => {
        return data?.goal_cycles;
      },
    });

    return {
      goalCycles: data ?? null,
      ...rest,
    };
  };

  const useGetPerformanceCycleById = (performanceCycleId: string) => {
    const { data, ...rest } = useQuery({
      queryKey: ['client', clientId, 'performanceCycle', performanceCycleId],
      queryFn: async () =>
        api.clients.getPerformanceCycleById(clientId, performanceCycleId),
      select: ({ data }) => {
        return data;
      },
    });

    return {
      performanceCycle: data?.data,
      ...rest,
    };
  };

  const useGetPerformanceCycleResults = (performanceCycleId: string) => {
    const { data, ...rest } = useQuery({
      queryKey: [
        'client',
        clientId,
        'performanceCycle',
        performanceCycleId,
        'results',
      ],
      queryFn: async () =>
        api.clients.getPerformanceCycleResults(clientId, performanceCycleId),
      select: ({ data }) => {
        return data;
      },
    });

    return {
      results: data,
      ...rest,
    };
  };

  const useGetPerformanceCycleResultsForDirectReport = (
    performanceCycleId: string,
    directReportId: string,
  ) => {
    const { data, ...rest } = useQuery({
      queryKey: [
        'client',
        clientId,
        'performanceCycle',
        performanceCycleId,
        'results',
        'directReportId',
        directReportId,
      ],
      queryFn: async () =>
        api.clients.getPerformanceCycleResultsForDirectReport(
          clientId,
          performanceCycleId,
          directReportId,
        ),
      select: ({ data }) => {
        return data;
      },
    });

    return {
      results: data,
      ...rest,
    };
  };

  const useGetClientNonAlignedGoals = () => {
    const { data, ...rest } = useQuery({
      queryKey: ['client', clientId, 'nonAlignedGoals'],
      queryFn: async () => api.goals_competencies.getNonAlignedGoals(clientId),
      select: res =>
        res.data.reduce((arr, goal) => {
          arr.push(goal);
          return arr;
        }, []),
    });

    return {
      nonAlignedGoals: data ?? null,
      ...rest,
    };
  };

  const useGetClientMetrics = () =>
    useQuery({
      queryKey: ['client', clientId, 'metrics'],
      queryFn: async () => api.analytics.getClientMetrics(true, clientId),
      select: res => res.data,
      enabled: isSCAdminOrClientMember,
    });

  const useGetClientDashboardData = () => {
    const { data, ...rest } = useQuery({
      queryKey: ['client', clientId, 'dashboardData'],
      queryFn: async () =>
        api.analytics.getClientDashboardData(false, clientId),
      select: res => res.data,
      enabled: isSCAdminOrClientMember,
    });

    return {
      areasOfInterest: data?.areasOfInterest ?? null,
      strengths: data?.strengths ?? null,
      weaknesses: data?.weaknesses ?? null,
      ...rest,
    };
  };

  const useGetClientDirectReports = (userId: string) =>
    useQuery({
      queryKey: ['client', clientId, 'direct-reports', userId],
      queryFn: async () =>
        api.clients.getClientDirectReportsByManagerId(userId, clientId),
      select: res => res.data,
      enabled: isSCAdminOrClientMember,
    });

  const useGetClientMembers = (
    queryParams: {
      keyword?: string;
      type?: 'administrators' | 'coaches' | 'coachees';
      memberType?: ClientMemberTypes;
      page?: number;
      return_fields?: string[];
      include_invitees?: boolean;
      include_hris?: boolean;
    } = {},
    { enabled } = { enabled: true },
  ) => {
    const { data, ...rest } = useQuery({
      queryKey: ['client', clientId, 'members', queryParams],
      queryFn: async () => api.clients.getMembers(clientId, queryParams),
      select: res => {
        const { data, totalRecords } = res.data;
        const [admins, members] = _partition(data, m => m.role === 'manager');
        return {
          members: [
            ...admins?.sort((a, b) => (a.first_name > b.first_name ? 1 : -1)),
            ...members?.sort((a, b) => (a.first_name > b.first_name ? 1 : -1)),
          ],
          totalRecords,
        };
      },
      enabled: isSCAdminOrClientMember && enabled,
    });

    return {
      members: data?.members ?? null,
      totalRecords: data?.totalRecords ?? null,
      ...rest,
    };
  };

  const useGetClientSurveys = (queryParams: {
    keyword?: string;
    limit?: number;
    page?: number;
    includeProgramData?: boolean;
  }) => {
    const { keyword, limit, page, includeProgramData = true } = queryParams;
    const { data, ...rest } = useQuery({
      queryKey: ['client', clientId, 'surveys', queryParams],
      queryFn: async () => {
        return api.surveys.get({
          limit,
          includeProgramData,
          keyword,
          page,
          client_id: clientId,
        });
      },
      select: ({ data }) =>
        data.filter(s => !s.archived_at).map(s => new SurveyType(s)),
    });

    return {
      surveys: data ?? null,
      total: data?.length ?? null,
      ...rest,
    };
  };

  const useGetClientPublishedSurveys = () => {
    const { data, ...rest } = useQuery({
      queryKey: ['client', clientId, 'publishedSurveys'],
      queryFn: async () => {
        return api.surveys.getPublishedSurveys({
          limit: 100,
          client_id: clientId,
        });
      },
      select: ({ data }) => data,
    });

    return {
      surveys: data ?? null,
      ...rest,
    };
  };

  const useGetPerformanceCycleFeedbackStatusForMember = (
    performanceCycleId: string,
    feedbackFormId: string,
    memberId: string,
  ) => {
    const { data, ...rest } = useQuery({
      queryKey: [
        'client',
        clientId,
        'performanceCycle',
        performanceCycleId,
        'feedback',
        feedbackFormId,
        'memberId',
        memberId,
      ],
      queryFn: async () =>
        api.clients.getPerformanceCycleFeedbackStatusForMember(
          clientId,
          performanceCycleId,
          feedbackFormId,
          memberId,
        ),
      select: ({ data }) => data,
    });

    return {
      feedbackData: data,
      ...rest,
    };
  };

  const useGetPerformanceCycleFeedbackStatus = (
    performanceCycleId: string,
    feedbackFormId: string,
  ) => {
    const { data, ...rest } = useQuery({
      queryKey: [
        'client',
        clientId,
        'performanceCycle',
        performanceCycleId,
        'feedback',
        feedbackFormId,
      ],
      queryFn: async () =>
        api.clients.getPerformanceCycleFeedbackStatus(
          clientId,
          performanceCycleId,
          feedbackFormId,
        ),
      select: ({ data }) => data,
      enabled: Boolean(performanceCycleId && feedbackFormId),
    });

    return {
      feedbackData: data,
      ...rest,
    };
  };

  const useGetClientMergeHrisIntegration = () =>
    useQuery({
      queryKey: ['client', clientId, 'mergeHrisIntegration'],
      queryFn: async () => api.integrations.getMergeHrisIntegration(clientId),
      select: res => res.data,
      enabled: isSCAdminOrClientMember,
    });

  return {
    useGetClient,
    useGetClientFields,
    useGetClientSOWs,
    useGetClientCompetencies,
    useGetPerformanceCycleGoals,
    useGetClientNonAlignedGoals,
    useGetClientMetrics,
    useGetClientDashboardData,
    useGetClientMembers,
    useGetClientDirectReports,
    useGetClientSurveys,
    useGetClientPublishedSurveys,
    useGetPerformanceCycleById,
    useGetPerformanceCycleResults,
    useGetPerformanceCycleResultsForDirectReport,
    useGetPerformanceCycleFeedbackStatusForMember,
    useGetPerformanceCycleFeedbackStatus,
    useGetClientMergeHrisIntegration,
  };
};

export { useClientQueries };
