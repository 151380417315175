import React, { FC, useMemo } from 'react';
import findIndex from 'lodash/findIndex';
import dayjs from 'dayjs';
import { Grid } from '@src/components/sc-design-system';
import { Stack, Typography } from '@mui/material';
import ActiveInactiveChip from '../../ActiveInactiveChip';

interface CycleSelectProps {
  goalCycles: GoalCycle[];
  stagedCycle: GoalCycle;
  onSelectCycle: Function;
}

const CycleSelect: FC<CycleSelectProps> = ({
  goalCycles,
  stagedCycle,
  onSelectCycle,
}) => {
  const allowedCycles = useMemo(() => {
    return goalCycles?.filter(
      gc => !gc.goal_edit_deadline || dayjs().isBefore(gc.goal_edit_deadline),
    );
  }, [goalCycles]);

  return (
    <Grid
      cols={1}
      data={allowedCycles}
      isSelectable
      onSelectItem={cycle => {
        onSelectCycle(cycle);
      }}
      selectedItem={
        stagedCycle
          ? findIndex(allowedCycles, (c: GoalCycle) => c.id === stagedCycle.id)
          : null
      }
      renderer={cycle => {
        const isClientCycle = Boolean(cycle.performanceCycleId);
        return (
          <Stack spacing={2} gap='.5em'>
            <Stack direction='row' alignItems='center' spacing={2}>
              <Typography variant='h3' color='primary' sx={{ marginBottom: 0 }}>
                {cycle.name}
              </Typography>
              {isClientCycle && (
                <ActiveInactiveChip
                  active={
                    !cycle.start || dayjs().isBetween(cycle.start, cycle.end)
                  }
                />
              )}
            </Stack>
            {isClientCycle && Boolean(cycle.start && cycle.end) && (
              <Typography
                variant='subtitle2'
                sx={{ marginTop: '0 !important' }}
              >
                {dayjs(cycle.start).format('MMM DD, YYYY')} -{' '}
                {dayjs(cycle.end).format('MMM DD, YYYY')}
              </Typography>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: cycle.description,
              }}
            />
          </Stack>
        );
      }}
    />
  );
};

export { CycleSelect };
