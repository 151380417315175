import React from 'react';
import { Link, Typography } from '@mui/material';
import { Card, Button } from '@src/components/sc-design-system';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AddIcon from '@mui/icons-material/Add';
import { FeedbackFormsModal } from '@src/components/views/MyBusiness/Performance/tabs/PerformanceCycles/PerformanceCycleLandingPage/modals/FeedbackFormsModal';
import { useParams } from 'react-router';
import { useClientQueries } from '@src/hooks/useClientQueries';

const FeedbackForms: React.FunctionComponent<{
  performanceCycle: PerformanceCycleI;
  refetchPerformanceCycle: Function;
  hasNotStartedOrIsAdmin: boolean;
}> = props => {
  const { performanceCycle, refetchPerformanceCycle, hasNotStartedOrIsAdmin } =
    props;
  const feedbackFormsSet =
    performanceCycle.self_evaluation_form_id ||
    performanceCycle.manager_evaluation_form_id;
  return feedbackFormsSet ? (
    <ViewFeedbackForms
      performanceCycle={performanceCycle}
      refetchPerformanceCycle={refetchPerformanceCycle}
      hasNotStartedOrIsAdmin={hasNotStartedOrIsAdmin}
    />
  ) : !feedbackFormsSet && hasNotStartedOrIsAdmin ? (
    <AddFeedbackForms
      performanceCycle={performanceCycle}
      refetchPerformanceCycle={refetchPerformanceCycle}
    />
  ) : (
    <EvaluationPeriodEnded />
  );
};

interface AddFeedbackFormsProps {
  performanceCycle: PerformanceCycleI;
  refetchPerformanceCycle: Function;
}

const AddFeedbackForms: React.FunctionComponent<
  AddFeedbackFormsProps
> = props => {
  const { performanceCycle, refetchPerformanceCycle } = props;
  const [openModal, setOpenModal] = React.useState(false);
  return (
    <Card
      variant='elevation'
      title='Add feedback forms'
      titleIcon={<ListAltIcon />}
      titleStyle='h2styleh4'
      sx={{ margin: '2em 0', padding: '.5em' }}
    >
      <Typography variant='body1' color='grey.700' sx={{ margin: '.5em 0' }}>
        Allow employees and their managers to provide feedback through custom
        evaluation forms.
      </Typography>
      <FeedbackFormsModal
        open={openModal}
        handleClose={setOpenModal}
        performanceCycle={performanceCycle}
        refetchPerformanceCycle={refetchPerformanceCycle}
      />
      <Button
        sx={{ marginTop: '1em' }}
        size='small'
        variant='outlined'
        startIcon={<AddIcon />}
        text='Add forms'
        onClick={() => setOpenModal(true)}
      />
    </Card>
  );
};

interface ViewFeedbackFormsProps {
  performanceCycle: PerformanceCycleI;
  hasNotStartedOrIsAdmin: boolean;
  refetchPerformanceCycle: Function;
}

const ViewFeedbackForms: React.FunctionComponent<
  ViewFeedbackFormsProps
> = props => {
  const { performanceCycle, hasNotStartedOrIsAdmin, refetchPerformanceCycle } =
    props;
  const { id: clientId } = useParams();
  const [openModal, setOpenModal] = React.useState(false);
  const { useGetClientPublishedSurveys } = useClientQueries(clientId);
  const { surveys } = useGetClientPublishedSurveys();
  const [selfEval, managerEval] = React.useMemo(() => {
    let self = null;
    let manager = null;
    surveys?.forEach(s => {
      if (s.id === performanceCycle.self_evaluation_form_id) {
        self = s;
      }
      if (s.id === performanceCycle.manager_evaluation_form_id) {
        manager = s;
      }
    });
    return [self, manager];
  }, [surveys, performanceCycle]);
  return (
    <Card
      variant='elevation'
      titleIcon={<ListAltIcon />}
      titleStyle='h2styleh4'
      title='Feedback forms'
      titleActions={
        hasNotStartedOrIsAdmin && (
          <Link
            key='edit-feedback-forms'
            variant='a1'
            onClick={() => setOpenModal(true)}
          >
            Edit
          </Link>
        )
      }
    >
      <Typography variant='body1sb'>Self-evaluation</Typography>
      <Typography
        variant='body1'
        color={selfEval?.title ? null : 'grey.700'}
        sx={{ margin: '.5em 0 1.5em' }}
      >
        {selfEval?.title || 'Not set'}
      </Typography>
      <Typography variant='body1sb'>Manager evaluation</Typography>
      <Typography
        variant='body1'
        color={managerEval?.title ? null : 'grey.700'}
        sx={{ margin: '.5em 0 0' }}
      >
        {managerEval?.title || 'Not set'}
      </Typography>
      <FeedbackFormsModal
        handleClose={setOpenModal}
        open={openModal}
        performanceCycle={performanceCycle}
        edit
        refetchPerformanceCycle={refetchPerformanceCycle}
      />
    </Card>
  );
};

function EvaluationPeriodEnded() {
  return (
    <Card
      variant='elevation'
      titleIcon={<ListAltIcon />}
      titleStyle='h2styleh4'
      title='Feedback forms'
    >
      <Typography variant='body1sb'>Self-evaluation</Typography>
      <Typography
        variant='body1'
        color='grey.700'
        sx={{ margin: '.5em 0 1.5em' }}
      >
        Not set
      </Typography>
      <Typography variant='body1sb'>Manager evaluation</Typography>
      <Typography variant='body1' color='grey.700' sx={{ margin: '.5em 0 0' }}>
        Not set
      </Typography>
    </Card>
  );
}

export { FeedbackForms };
