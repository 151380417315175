import React, { FC, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Box, Chip, IconButton, Stack, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { TableMUI as Table } from '@src/components/sc-design-system';
import { useQuery } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import { useNavigation } from '@src/navigation';
import { useQueryParams } from '@src/utils';
import User from '@src/models/User';
import { SurveySubmissionModal } from '@src/components/common/SurveySubmissionModal';
import EditOutlined from '@mui/icons-material/EditOutlined';

interface SurveysProps {
  user: User;
}

const INTAKE_SURVEY_ID = '3636f28e-f057-4697-8cb4-4aa957131202';

const Surveys: FC<SurveysProps> = ({ user }) => {
  const navigation = useNavigation();
  const query = useQueryParams();
  const [surveySubmissionModalData, setSurveySubmissionModalData] =
    useState<SurveySubmission | null>(null);
  const [surveyTitle, setSurveyTitle] = useState<string | null>(null);
  const { data: surveySubmissions, isLoading } = useQuery({
    queryKey: ['user', user?.id, 'survey-submissions'],
    queryFn: async () => api.surveys.getSurveySubmissionsForUser(user?.id),
    select: ({ data }) => data,
  });

  const columns = useMemo(() => {
    return [
      {
        header: 'Survey',
        accessor: 'survey',
        size: 300,
        minSize: 50,
        maxSize: 300,
        cell: ({ row }) => row?.original?.survey?.title,
      },
      {
        header: 'Status',
        accessor: 'submission',
        size: 200,
        minSize: 50,
        maxSize: 200,
        cell: ({ row }) => {
          const { submission } = row?.original || {};

          if (submission?.is_draft) {
            return <Chip color='warning' label='Draft' />;
          }
          return <Chip label='Submitted' color='success' />;
        },
      },
      {
        header: 'Last updated',
        accessor: 'request',
        size: 150,
        minSize: 50,
        maxSize: 150,
        cell: ({ row }) => {
          const { submission } = row?.original || {};
          return dayjs(
            submission?.submitted || submission?.updatedAt || 0,
          ).format('MM/DD/YYYY');
        },
      },
      {
        id: 'actions',
        header: '',
        accessor: '',
        size: 100,
        minSize: 50,
        maxSize: 100,
        cell: ({ row }) => {
          const { submission, survey } = row?.original || {};
          return (
            <Box sx={{ textAlign: 'center' }}>
              {submission?.is_draft || survey.id === INTAKE_SURVEY_ID ? (
                <IconButton
                  onClick={() => {
                    // hiddens doesn't always contain user_id, so set it explicitly
                    query.set('user_id', submission.user_id);
                    Object.entries(submission.hiddens).forEach(
                      ([key, value]) => {
                        if (value && key !== 'user_id') {
                          query.set(key, value as string);
                        }
                      },
                    );

                    navigation.replace(
                      `${navigation.location.pathname}?${query}`,
                    );
                  }}
                >
                  <EditOutlined color='primary' />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    setSurveySubmissionModalData(submission);
                    setSurveyTitle(survey?.title);
                  }}
                >
                  <VisibilityOutlinedIcon color='primary' />
                </IconButton>
              )}
            </Box>
          );
        },
      },
    ];
  }, [navigation, query]);

  return (
    <Stack>
      <Typography variant='h4' component='h3'>
        Surveys
      </Typography>
      <Typography color='grey.700' sx={{ marginTop: '.5em' }}>
        View a history of all your draft and submitted surveys.
      </Typography>
      <Table
        dataIsLoading={isLoading}
        columns={columns}
        data={surveySubmissions || []}
        sx={{ marginTop: '1.5em' }}
      />
      {Boolean(surveySubmissionModalData) && (
        <SurveySubmissionModal
          surveySubmission={surveySubmissionModalData}
          onClose={() => {
            setSurveySubmissionModalData(null);
            setSurveyTitle(null);
          }}
          title={surveyTitle}
        />
      )}
    </Stack>
  );
};

export { Surveys };
