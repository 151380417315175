import React, { FC, useState, useMemo } from 'react';
import _partition from 'lodash/partition';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GoalManagerDrawer } from '@src/components/common/GoalManagerDrawer';
import { GoalCycleCard } from '@src/components/common/GoalCycleCard';
import {
  GoalCycleGrid,
  GoalCycleGridProps,
} from '@src/components/common/GoalCycleGrid';
import { GoalManagerModal } from '@src/components/common/GoalManagerModal';
import { GoalManagerStep } from '@src/components/common/GoalManagerModal/GoalManagerModal';
import {
  useToast,
  useLayoutToggle,
  LayoutType,
} from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import { useUserQueries } from '@src/hooks/useUserQueries';
import { GoalCycleList } from '@src/components/common/GoalCycleList';
import { GoalWeightingModal } from '@src/components/common/GoalWeightingModal';

interface UserGoalCycleCardProps {
  cycle: GoalCycleWithGoals;
  goalCycles?: GoalCycle[];
  clientId?: string;
  performanceCycleId?: string;
  userId: string;
  defaultOpen?: boolean;
  tabDefinitions?: GoalCycleGridProps['tabDefinitions'];
  tabPartitionBy?: (g: Goal) => void;
  showStatusChip?: boolean;
}

const UserGoalCycleCard: FC<UserGoalCycleCardProps> = ({
  cycle,
  goalCycles,
  clientId,
  performanceCycleId,
  userId,
  defaultOpen,
  tabDefinitions,
  tabPartitionBy,
  showStatusChip,
}) => {
  const queryClient = useQueryClient();
  const [layoutType, setLayoutType] = useLayoutToggle(`goal-cycle-${cycle.id}`);

  const { useGetUserPrograms } = useUserQueries(userId);
  const { userPrograms } = useGetUserPrograms('coachee');

  const refetchGoals = () => {
    queryClient.invalidateQueries({
      queryKey: ['user', userId, 'goals'],
    });
  };
  const toast = useToast();
  const [editorModalOpen, setEditorModalOpen] = useState<
    false | 'edit' | 'add'
  >(false);
  const [alignedGoalId, setAlignedGoalId] = useState<string | null>(null);
  const [selectedGoal, setSelectedGoal] = useState<Goal | null>(null);
  const [selectedCycle, setSelectedCycle] = useState<GoalCycle | null>(cycle);
  const [isGoalStatusDrawerOpen, setIsGoalStatusDrawerOpen] = useState(false);
  const [isGoalWeightModalOpen, setIsGoalWeightModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(null);

  const sections = useMemo(() => {
    return tabDefinitions?.length && tabPartitionBy
      ? _partition([...cycle.goals], tabPartitionBy)
      : [[...cycle.goals]];
  }, [cycle.goals, tabPartitionBy, tabDefinitions]);

  const { mutateAsync: deleteGoal } = useMutation({
    mutationFn: async (goalId: string) => {
      await api.goals_competencies.deleteGoal(goalId);
    },
    onSuccess: async () => {
      toast.success('Goal deleted');
      setEditorModalOpen(false);
      refetchGoals();
    },
    onError() {
      toast.error('Could not delete goal');
    },
  });

  const handleDeleteGoal = async (goal: Goal) => {
    await deleteGoal(goal.id);
  };

  const toggleEditorModal = (
    type,
    goal = null,
    cycle = null,
    alignedId = null,
  ) => {
    setSelectedGoal(goal || null);
    setSelectedCycle(cycle ? { ...cycle, performanceCycleId } : null);
    setAlignedGoalId(alignedId);
    setEditorModalOpen(type);
  };

  return (
    <>
      <GoalCycleCard
        cycle={cycle}
        performanceCycleId={performanceCycleId}
        defaultOpen={defaultOpen}
        showStatusChip={showStatusChip}
        view='user'
        tabDefinitions={tabDefinitions}
        onChangeLayout={setLayoutType}
        userId={userId}
        showGoalWeightButton={cycle.goal_weighting_enabled}
        onChangeTab={setActiveTab}
        refetchGoals={refetchGoals}
      >
        {layoutType === LayoutType.grid && (
          <GoalCycleGrid
            cycle={cycle}
            entityType='user'
            onAddOrEditGoal={toggleEditorModal}
            onDeleteGoal={handleDeleteGoal}
            canEditGoals={Boolean(!performanceCycleId)}
            sections={sections}
            tabDefinitions={tabDefinitions}
            onGoalStatusUpdate={(_, g) => {
              setIsGoalStatusDrawerOpen(true);
              setSelectedGoal(g);
            }}
            performanceCycleId={performanceCycleId}
            activeTab={activeTab}
            canManageGoalWeights={cycle.goal_weighting_enabled}
            onClickGoalWeight={() => {
              setIsGoalWeightModalOpen(true);
            }}
          />
        )}
        {layoutType === LayoutType.list && (
          <GoalCycleList
            key={cycle.id}
            cycle={cycle}
            entityType='user'
            onAddOrEditGoal={toggleEditorModal}
            onDeleteGoal={handleDeleteGoal}
            canEditGoals={Boolean(!performanceCycleId)}
            sections={sections}
            tabDefinitions={tabDefinitions}
            activeTab={activeTab}
            onGoalStatusUpdate={(_, g) => {
              setIsGoalStatusDrawerOpen(true);
              setSelectedGoal(g);
            }}
            canManageGoalWeights={cycle.goal_weighting_enabled}
            onClickGoalWeight={() => {
              setIsGoalWeightModalOpen(true);
            }}
            performanceCycleId={performanceCycleId}
          />
        )}
      </GoalCycleCard>
      {Boolean(editorModalOpen) && (
        <GoalManagerModal
          userId={userId}
          clientId={clientId}
          selectedGoalCycle={selectedCycle}
          performanceCycleId={performanceCycleId}
          open={editorModalOpen}
          onClose={() => toggleEditorModal(null)}
          selectedGoal={selectedGoal}
          programs={userPrograms}
          goalCycles={goalCycles}
          onSave={() => {
            refetchGoals();
          }}
          entityType='user'
          defaultActiveStep={GoalManagerStep.alignment}
          onChangeCycle={setSelectedCycle}
          alignedGoalId={alignedGoalId}
        />
      )}
      {isGoalWeightModalOpen && (
        <GoalWeightingModal
          userId={userId}
          goalCycle={cycle}
          onClose={() => setIsGoalWeightModalOpen(false)}
          open={isGoalWeightModalOpen}
          onGoalUpdate={() => {
            refetchGoals();
          }}
        />
      )}
      {isGoalStatusDrawerOpen && (
        <GoalManagerDrawer
          userId={userId}
          clientId={clientId}
          selectedGoalCycle={selectedCycle || cycle}
          open={editorModalOpen}
          onClose={() => {
            setIsGoalStatusDrawerOpen(false);
          }}
          selectedGoal={selectedGoal}
          programs={userPrograms}
          goalCycles={goalCycles}
          onSave={updatedGoal => {
            setSelectedGoal(updatedGoal);
            refetchGoals();
          }}
          entityType='user'
          onChangeCycle={setSelectedCycle}
          alignedGoalId={alignedGoalId}
          onClickGoalWeight={() => {
            setIsGoalWeightModalOpen(true);
          }}
          canManageGoalWeights
          performanceCycleId={performanceCycleId}
          onDeleteGoal={handleDeleteGoal}
        />
      )}
    </>
  );
};

export { UserGoalCycleCard };
