import React, { FC, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import FeedOutlined from '@mui/icons-material/FeedOutlined';
import GroupOutlined from '@mui/icons-material/GroupOutlined';
import TrackChanges from '@mui/icons-material/TrackChanges';
import { useStore } from '@src/models/Store';
import { useNavigation } from '@src/navigation';
import { Button, useBreadcrumbs } from '@src/components/sc-design-system';
import ProgramResources from './components/ProgramResources';
import { ProgramDetails } from './components/ProgramDetails';
import { IProgram } from './types';
import { handleKeyboardNav } from '@src/utils';
import { Box, Stack } from '@mui/material';

const UserProgramCardHeader: FC<{
  program: IProgram;
}> = ({ program }) => {
  const [programResourcesOpen, setProgramResourcesOpen] = useState(false);

  return (
    <Row className='program-card-header'>
      <Col className='header-info' flex='1'>
        <ProgramDetails program={program} />
      </Col>
      <Col className='header-actions' flex={0}>
        <div className='header-actions-content'>
          {programResourcesOpen && (
            <ProgramResources
              onClose={() => setProgramResourcesOpen(false)}
              program={program}
            />
          )}
          <Button
            variant='outlined'
            size='small'
            onClick={() => {
              setProgramResourcesOpen(true);
            }}
            text='Resources'
          />
        </div>
      </Col>
    </Row>
  );
};

const UserPlatformProgramCard: FC<{ program: IProgram }> = ({ program }) => {
  const navigation = useNavigation();
  const { appendBreadcrumb } = useBreadcrumbs();
  const { user } = useStore();
  const selfAssessmentSubmitted = !program.self_360_assessment_id;

  const hasGoals = useMemo(() => {
    return (
      Boolean(user?.goal_cycles?.length) &&
      user.goal_cycles?.every(gc => gc?.goals?.length > 0)
    );
  }, [user]);

  const maybeOpenSurvey = () => {
    if (!selfAssessmentSubmitted) {
      navigation.replace(
        `/my-programs?program_id=${program.id}&survey_id=${program.self_360_assessment_id}&user_id=${user.id}`,
      );
    }
  };

  const goToStakeholders = () => {
    const path = `/my-programs/${program.id}`;
    navigation.push(path);
    appendBreadcrumb({
      path,
      label: 'Manage Stakeholders',
    });
  };

  const goToProfile = () => {
    navigation.push('/performance-and-growth');
  };

  return (
    <Box className='user-program-card' sx={{ padding: '1em 0' }}>
      <UserProgramCardHeader program={program} />
      <Stack
        direction='row'
        alignItems='center'
        width='100%'
        gap={1.5}
        paddingTop='1em'
      >
        {program.self_assessment_enabled && (
          <Button
            shape='rounded-square'
            sx={{ width: '100%' }}
            onClick={maybeOpenSurvey}
            onKeyDown={e => handleKeyboardNav(e.key, maybeOpenSurvey)}
            startIcon={<FeedOutlined />}
            text={
              !selfAssessmentSubmitted
                ? 'Take Self Assessment'
                : 'Self Assessment Submitted'
            }
            disabled={selfAssessmentSubmitted}
          />
        )}
        {program.stakeholder_assessment_enabled && (
          <Button
            shape='rounded-square'
            sx={{ width: '100%' }}
            color='secondary'
            onClick={goToStakeholders}
            startIcon={<GroupOutlined />}
            text='Manage 360 Participants'
          />
        )}
        <Button
          shape='rounded-square'
          sx={{ width: '100%' }}
          color='tertiary'
          onClick={goToProfile}
          startIcon={<TrackChanges />}
          text={`${hasGoals ? 'Manage' : 'Create'} Goals`}
        />
      </Stack>
    </Box>
  );
};

export { UserPlatformProgramCard };
