import React, { useState, FC } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Link from '@mui/material/Link';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import { useFullScreen } from '@src/hooks/useFullScreen';
import {
  Button,
  TextField,
  DatePicker,
  DialogTitle,
} from '@src/components/sc-design-system';
import WYSIWYG from '@src/components/common/WYSIWYG';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import styled from '@emotion/styled';

interface GoalCycleManagerPropsIF {
  clientId: string;
  goalCycle?: GoalCycle;
  open: boolean;
  onClose: Function;
  onCreate?: Function;
  performanceCycleId?: string;
}

const GoalCycleManager: FC<GoalCycleManagerPropsIF> = ({
  clientId,
  goalCycle,
  open,
  onClose,
  onCreate,
  performanceCycleId,
}) => {
  const queryClient = useQueryClient();
  const { fullScreen } = useFullScreen();
  const [isSubmissionDeadlineEnabled, setIsSubmissionDeadlineEnabled] =
    useState(Boolean(goalCycle?.goal_submission_deadline));
  const [isEditDeadlineEnabled, setIsEditDeadlineEnabled] = useState(
    Boolean(goalCycle?.goal_edit_deadline),
  );
  const [isGoalEvalEnabled, setIsGoalEvalEnabled] = useState(
    Boolean(goalCycle?.goal_evaluation_start || goalCycle?.goal_evaluation_end),
  );

  const {
    control,
    handleSubmit,
    formState: { isValid, touchedFields, isDirty, errors },
    getValues,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    mode: 'all',
    defaultValues: {
      id: goalCycle?.id ?? '',
      name: goalCycle?.name ?? '',
      description: goalCycle?.description ?? '',
      start: goalCycle?.start ? dayjs(goalCycle.start) : null,
      end: goalCycle?.end ? dayjs(goalCycle.end) : null,
      goal_weighting_enabled: goalCycle?.goal_weighting_enabled ?? false,
      goal_submission_deadline: goalCycle?.goal_submission_deadline
        ? dayjs(goalCycle.goal_submission_deadline)
        : null,
      goal_edit_deadline: goalCycle?.goal_edit_deadline
        ? dayjs(goalCycle.goal_edit_deadline)
        : null,
      goal_evaluation_start: goalCycle?.goal_evaluation_start
        ? dayjs(goalCycle.goal_evaluation_start)
        : null,
      goal_evaluation_end: goalCycle?.goal_evaluation_end
        ? dayjs(goalCycle.goal_evaluation_end)
        : null,
    },
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const { mutate: saveGoalCycle, isPending } = useMutation({
    mutationFn: async (data: { body: GoalCycle }) => {
      const { body } = data;
      return api.clients.writeGoalCycle(body, clientId, performanceCycleId);
    },
    onSuccess({ data }) {
      handleClose();
      queryClient.invalidateQueries({
        queryKey: ['client', clientId, 'performanceCycle', performanceCycleId],
      });
      if (onCreate) onCreate(data);
    },
  });

  const onSubmit = async data => {
    const body = {
      ...data,
      start: data.start.valueOf(),
      end: data.end.valueOf(),
      goal_submission_deadline:
        data.goal_submission_deadline?.valueOf() ?? undefined,
      goal_edit_deadline: data.goal_edit_deadline?.valueOf() ?? undefined,
      goal_evaluation_start: data.goal_evaluation_start?.valueOf() ?? undefined,
      goal_evaluation_end: data.goal_evaluation_end?.valueOf() ?? undefined,
    };
    saveGoalCycle({ body });
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      maxWidth='md'
    >
      <DialogTitle
        title='Create a goal cycle'
        buttonOnClick={() => handleClose()}
      />
      <DialogContent>
        <Stack spacing={2}>
          <form id='main' onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              <Stack gap='8px' marginBottom='24px'>
                <Typography
                  component={FormLabel}
                  variant='h4'
                  required
                  sx={{ color: 'black.main' }}
                >
                  Basic Info
                </Typography>
                <Typography variant='body2' sx={{ color: 'grey.700' }}>
                  Goal cycles are used to group goals for clear alignment and
                  reporting. Once you create a goal cycle, your members will be
                  able to enter their goals for the cycle.
                </Typography>
              </Stack>
              <Controller
                name='name'
                control={control}
                rules={{ required: 'Goal cycle name is required' }}
                render={({ field }) => (
                  <TextField
                    id={field.name}
                    label='Name'
                    placeholder='e.g. 2023 Company Goals'
                    required
                    fullWidth
                    {...field}
                    innerRef={field.ref}
                    error={Boolean(touchedFields.name && errors?.name?.message)}
                    errorMessage={touchedFields.name && errors?.name?.message}
                  />
                )}
              />
              <Stack marginTop='24px' gap='8px'>
                <InputLabel id='wysiwyg-label' htmlFor='description' required>
                  Description
                </InputLabel>
                <Controller
                  name='description'
                  control={control}
                  rules={{
                    required: 'Description is required',
                    validate: {
                      description: (v: string) =>
                        (v && v !== '<br>') || 'Description is required',
                    },
                  }}
                  render={({ field }) => (
                    <WYSIWYG
                      {...field}
                      innerRef={field.ref}
                      placeholder='Enter description here'
                    />
                  )}
                />
                {errors?.description && (
                  <FormHelperText error color='error'>
                    {errors?.description?.message}
                  </FormHelperText>
                )}
              </Stack>
              <div style={{ marginTop: 24 }}>
                <FormControlLabel
                  control={
                    <Controller
                      name='goal_weighting_enabled'
                      control={control}
                      render={({ field }) => (
                        <Switch {...field} checked={field.value} />
                      )}
                    />
                  }
                  label='Goal Weighting'
                  labelPlacement='start'
                  sx={{ margin: 0 }}
                />
              </div>
              <FormDivider />
              <Stack gap='8px' marginBottom='24px'>
                <Typography
                  variant='h4'
                  component={FormLabel}
                  required
                  sx={{ color: 'black.main' }}
                >
                  What is the timeframe for the goal cycle?
                </Typography>
                <Typography variant='body2' sx={{ color: 'grey.700' }}>
                  This determines when a goal cycle is active.
                </Typography>
              </Stack>
              <Stack direction='row' spacing={2}>
                <Stack spacing={1} width='100%'>
                  <Controller
                    name='start'
                    control={control}
                    rules={{
                      required: 'Start date is required',
                      validate: {
                        start: (v: Dayjs, context) => {
                          if (!v.isValid()) {
                            return 'Please enter a valid date';
                          }
                          if (
                            context.end?.isValid() &&
                            v.isAfter(context.end)
                          ) {
                            return 'Start date must be before end date';
                          }
                          return true;
                        },
                      },
                    }}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          {...field}
                          label='Start'
                          innerRef={field.ref}
                          maxDate={getValues('end') ?? null}
                          clearable
                          error={Boolean(errors?.start)}
                          errorMessage={errors?.start?.message}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack spacing={1} width='100%'>
                  <Controller
                    name='end'
                    control={control}
                    rules={{
                      required: 'End date is required',
                      validate: {
                        end: (v: Dayjs, context) => {
                          if (!v.isValid()) {
                            return 'Please enter a valid date';
                          }
                          if (
                            context.start.isValid() &&
                            v.isBefore(context.start)
                          ) {
                            return 'End date must be after start date';
                          }
                          return true;
                        },
                      },
                    }}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          label='End'
                          {...field}
                          innerRef={field.ref}
                          minDate={getValues('start') ?? null}
                          clearable
                          error={Boolean(errors?.end)}
                          errorMessage={errors?.end?.message}
                        />
                      );
                    }}
                  />
                </Stack>
              </Stack>
              <FormDivider />
              <Stack gap='8px' marginBottom='16px'>
                <Typography variant='h4'>
                  Do you want to set a deadline for entering goals?
                </Typography>
                <Typography variant='body2' sx={{ color: 'grey.700' }}>
                  Employees need to have their goals entered into the system by
                  this date.
                </Typography>
              </Stack>
              <RadioGroup row>
                <FormControlLabel
                  value={'true'}
                  control={
                    <Radio
                      disableRipple
                      checked={isSubmissionDeadlineEnabled}
                      onChange={e => {
                        setIsSubmissionDeadlineEnabled(e.target.checked);
                      }}
                    />
                  }
                  label='Yes'
                />
                <FormControlLabel
                  value={'false'}
                  control={
                    <Radio
                      disableRipple
                      checked={!isSubmissionDeadlineEnabled}
                      onChange={e => {
                        setIsSubmissionDeadlineEnabled(!e.target.checked);
                        setValue('goal_submission_deadline', null, {
                          shouldTouch: true,
                          shouldDirty: true,
                        });
                      }}
                    />
                  }
                  label='No'
                />
              </RadioGroup>
              {isSubmissionDeadlineEnabled && (
                <Stack
                  spacing={1}
                  width={fullScreen ? '100%' : '49%'}
                  marginTop='16px'
                >
                  <Controller
                    name='goal_submission_deadline'
                    control={control}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          label='Date'
                          {...field}
                          innerRef={field.ref}
                          minDate={getValues('start') ?? null}
                          maxDate={getValues('end') ?? null}
                          clearable
                        />
                      );
                    }}
                  />
                </Stack>
              )}
              <FormDivider />
              <Stack gap='8px' marginBottom='16px'>
                <Typography variant='h4'>
                  Do you want to lock goals for editing on a certain date?
                </Typography>
                <Typography variant='body2' sx={{ color: 'grey.700' }}>
                  Goals will be set and uneditable by this date. If applicable,
                  goal status updates and evaluation will still be available.
                </Typography>
              </Stack>
              <RadioGroup row>
                <FormControlLabel
                  value={'true'}
                  control={
                    <Radio
                      disableRipple
                      checked={isEditDeadlineEnabled}
                      onChange={e => {
                        setIsEditDeadlineEnabled(e.target.checked);
                      }}
                    />
                  }
                  label='Yes'
                />
                <FormControlLabel
                  value={'false'}
                  control={
                    <Radio
                      disableRipple
                      checked={!isEditDeadlineEnabled}
                      onChange={e => {
                        setIsEditDeadlineEnabled(!e.target.checked);
                        setValue('goal_edit_deadline', null, {
                          shouldTouch: true,
                          shouldDirty: true,
                        });
                      }}
                    />
                  }
                  label='No'
                />
              </RadioGroup>
              {isEditDeadlineEnabled && (
                <Stack
                  spacing={1}
                  width={fullScreen ? '100%' : '49%'}
                  marginTop='16px'
                >
                  <Controller
                    name='goal_edit_deadline'
                    control={control}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          label='Date'
                          {...field}
                          innerRef={field.ref}
                          minDate={getValues('start') ?? null}
                          maxDate={getValues('end') ?? null}
                          clearable
                        />
                      );
                    }}
                  />
                </Stack>
              )}
              <FormDivider />
              <Stack gap='8px' marginBottom='16px'>
                <Typography variant='h4'>Will goals be evaluated?</Typography>
                <Typography variant='body2' sx={{ color: 'grey.700' }}>
                  During this phase, status updates are no longer available and
                  simple evaluation terms and updates are the focus.
                </Typography>
              </Stack>
              <RadioGroup row>
                <FormControlLabel
                  value={'true'}
                  control={
                    <Radio
                      disableRipple
                      checked={isGoalEvalEnabled}
                      onChange={e => {
                        setIsGoalEvalEnabled(e.target.checked);
                      }}
                    />
                  }
                  label='Yes'
                />
                <FormControlLabel
                  value={'false'}
                  control={
                    <Radio
                      disableRipple
                      checked={!isGoalEvalEnabled}
                      onChange={e => {
                        setIsGoalEvalEnabled(!e.target.checked);
                        setValue('goal_evaluation_start', null, {
                          shouldTouch: true,
                          shouldDirty: true,
                        });
                        setValue('goal_evaluation_end', null, {
                          shouldTouch: true,
                          shouldDirty: true,
                        });
                        clearErrors('goal_evaluation_start');
                        clearErrors('goal_evaluation_end');
                      }}
                    />
                  }
                  label='No'
                />
              </RadioGroup>
              {isGoalEvalEnabled && (
                <Stack direction='row' spacing={2} marginTop='16px'>
                  <Stack spacing={1} width='100%'>
                    <Controller
                      name='goal_evaluation_start'
                      control={control}
                      rules={{
                        validate: {
                          start: (v: Dayjs, context) => {
                            if (v == null && !context.goal_evaluation_end) {
                              return true;
                            }
                            if (!v?.isValid()) {
                              return 'Please enter a valid date';
                            }
                            if (
                              context.goal_evaluation_end?.isValid() &&
                              v?.isAfter(context.goal_evaluation_end)
                            ) {
                              return 'Start date must be before end date';
                            }
                            return true;
                          },
                        },
                      }}
                      render={({ field }) => {
                        return (
                          <DatePicker
                            label='Start'
                            {...field}
                            innerRef={field.ref}
                            minDate={getValues('start') ?? null}
                            maxDate={
                              getValues('goal_evaluation_end') ||
                              getValues('end') ||
                              null
                            }
                            clearable
                            error={Boolean(errors?.goal_evaluation_start)}
                            errorMessage={
                              errors?.goal_evaluation_start?.message
                            }
                          />
                        );
                      }}
                    />
                  </Stack>
                  <Stack spacing={1} width='100%'>
                    <Controller
                      name='goal_evaluation_end'
                      control={control}
                      rules={{
                        validate: {
                          start: (v: Dayjs, context) => {
                            if (v == null && !context.goal_evaluation_start) {
                              return true;
                            }
                            if (!v?.isValid()) {
                              return 'Please enter a valid date';
                            }
                            if (
                              context.goal_evaluation_start?.isValid() &&
                              v?.isBefore(context.goal_evaluation_start)
                            ) {
                              return 'End date must be after start date';
                            }
                            return true;
                          },
                        },
                      }}
                      render={({ field }) => {
                        return (
                          <DatePicker
                            label='End'
                            {...field}
                            innerRef={field.ref}
                            minDate={
                              getValues('goal_evaluation_start') ||
                              getValues('start') ||
                              null
                            }
                            maxDate={getValues('end') ?? null}
                            clearable
                            error={Boolean(errors?.goal_evaluation_end)}
                            errorMessage={errors?.goal_evaluation_end?.message}
                          />
                        );
                      }}
                    />
                  </Stack>
                </Stack>
              )}
            </Stack>
          </form>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Link
          component='button'
          variant='a2'
          onClick={() => {
            // clear errors and touched fields
            reset();
            setIsSubmissionDeadlineEnabled(false);
            setIsEditDeadlineEnabled(false);
            setIsGoalEvalEnabled(false);
            // override defaults
            setValue('name', '');
            setValue('description', '');
            setValue('start', null);
            setValue('end', null);
            setValue('goal_weighting_enabled', false);
            setValue('goal_submission_deadline', null);
            setValue('goal_edit_deadline', null);
            setValue('goal_evaluation_start', null);
            setValue('goal_evaluation_end', null);
          }}
          sx={{ marginRight: 'auto' }}
        >
          Clear all fields
        </Link>
        <Button variant='outlined' onClick={handleClose} text='Cancel' />
        <Button
          form='main'
          type='submit'
          disabled={
            !isValid ||
            getValues('description') === '<br>' ||
            !isDirty ||
            isPending
          }
          loading={isPending}
          text={`${goalCycle?.id ? 'Update' : 'Create'} Goal Cycle`}
        />
      </DialogActions>
    </Dialog>
  );
};

export { GoalCycleManager };

const FormDivider = styled(Divider)({
  margin: '2em 0 !important',
});
