import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import LayersOutlined from '@mui/icons-material/LayersOutlined';
import TrackChangesOutlined from '@mui/icons-material/TrackChangesOutlined';
import {
  Card,
  Button,
  useBreadcrumbs,
  Avatar,
} from '@src/components/sc-design-system';
import IconStatistic from '@src/components/common/IconStatistic';
import DirectReportTeam from '@src/components/views/TeamManagement/TeamDashboard/DirectReportTeam';
import { DirectReport } from '@src/components/views/TeamManagement/types';
import { Typography, Stack, Divider } from '@mui/material';

interface AvatarUserInfoProps {
  firstName: string;
  lastName: string;
  userId: string;
  jobTitle: string;
  department: string;
}

function AvatarUserInfo(props: AvatarUserInfoProps) {
  const { firstName, lastName, userId, jobTitle, department } = props;
  return (
    <Stack direction='row' alignItems='center' sx={{ width: '325px' }}>
      <Stack sx={{ marginRight: '1em' }}>
        <Avatar
          size='xxxl'
          srcId={userId}
          alt={`${firstName} ${lastName}`}
          sx={{ marginRight: '.5em' }}
        />
      </Stack>
      <Stack>
        <Typography variant='h3' sx={{ marginBottom: '0.25em' }}>
          {firstName} {lastName}
        </Typography>
        {jobTitle && (
          <>
            <Typography variant='body1' color='grey.700'>
              {jobTitle}
            </Typography>
          </>
        )}
        {department && (
          <Typography variant='body1' color='grey.700'>
            {department}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

interface GoalProgramStatisticsProps {
  aligned_goals_count: number;
  enrolledProgramCount: number;
}

function GoalProgramStatistics({
  aligned_goals_count,
  enrolledProgramCount,
}: GoalProgramStatisticsProps) {
  return (
    <Stack direction='row' paddingLeft={4} spacing={4}>
      <IconStatistic
        title='Aligned Goals'
        value={aligned_goals_count}
        Icon={
          <TrackChangesOutlined
            sx={{
              color: 'secondary.500',
            }}
            fontSize='large'
          />
        }
      />
      <IconStatistic
        title='Enrolled Programs'
        value={enrolledProgramCount}
        Icon={
          <LayersOutlined
            sx={{
              color: 'tertiary.500',
            }}
            fontSize='large'
          />
        }
      />
    </Stack>
  );
}

const DirectReportCard: React.FunctionComponent<{
  directReport: DirectReport;
  cardKey: string;
}> = observer(({ directReport, cardKey }) => {
  const navigate = useNavigate();
  const { appendBreadcrumb } = useBreadcrumbs();
  return (
    <Card
      key={cardKey}
      title={
        <Stack
          direction='row'
          alignItems='center'
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={{ margin: 0, display: { sm: 'none', md: 'flex' } }}
            />
          }
        >
          <AvatarUserInfo
            firstName={directReport.first_name}
            lastName={directReport.last_name}
            userId={directReport.id}
            jobTitle={directReport.title}
            department={directReport.department}
          />
          <GoalProgramStatistics
            aligned_goals_count={directReport.aligned_goals_count}
            enrolledProgramCount={directReport.active_program_count}
          />
        </Stack>
      }
      titleActions={
        <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
          <Button
            variant='outlined'
            onClick={() => {
              const path = `/profile/${directReport.id}`;
              navigate(path);
              appendBreadcrumb({
                path,
                label: `${directReport.first_name} ${directReport.last_name}`,
              });
            }}
            text='View Profile'
          />
        </div>
      }
      bodyStyle={
        directReport.has_direct_reports
          ? null
          : { '&.MuiCardContent-root': { padding: 0 } }
      }
    >
      {directReport.has_direct_reports && (
        <DirectReportTeam
          userId={directReport.id}
          firstName={directReport.first_name}
          lastName={directReport.last_name}
        />
      )}
    </Card>
  );
});

export default DirectReportCard;
