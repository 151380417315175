import React, { FC } from 'react';
import Rating, { RatingProps, IconContainerProps } from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import styled from '@emotion/styled';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons: {
  [index: string]: {
    Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    color: 'error' | 'warning' | 'success';
  };
} = {
  1: {
    Icon: SentimentVeryDissatisfiedIcon,
    color: 'error',
  },
  2: {
    Icon: SentimentDissatisfiedIcon,
    color: 'error',
  },
  3: {
    Icon: SentimentSatisfiedIcon,
    color: 'warning',
  },
  4: {
    Icon: SentimentSatisfiedAltIcon,
    color: 'success',
  },
  5: {
    Icon: SentimentVerySatisfiedIcon,
    color: 'success',
  },
};

function IconContainer(props: IconContainerProps, currentValue) {
  const { value, ...other } = props;
  const { Icon, color } = customIcons[value];

  return (
    <span {...other}>
      <Icon
        color={value < currentValue ? customIcons[currentValue].color : color}
        sx={{ fontSize: '2em' }}
      />
    </span>
  );
}

const SessionRating: FC<RatingProps> = props => {
  return (
    <StyledRating
      {...props}
      IconContainerComponent={(p: IconContainerProps) =>
        IconContainer(p, props.value)
      }
      size='large'
    />
  );
};

export { SessionRating };
