import React, { FC, useState } from 'react';
import { Button } from '@src/components/sc-design-system';
import { IconButton } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ClientEditorModal } from './ClientEditorModal';

interface ClientEditorButtonProps {
  buttonType?: 'icon' | 'standard';
  client?: any;
  onSave: Function;
}

const ClientEditorButton: FC<ClientEditorButtonProps> = ({
  buttonType = 'standard',
  client,
  onSave,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      {buttonType === 'icon' ? (
        <IconButton onClick={() => setModalOpen(true)}>
          <EditOutlinedIcon fontSize='small' />
        </IconButton>
      ) : (
        <Button
          text='New Client'
          onClick={() => setModalOpen(true)}
          size='small'
        />
      )}
      {modalOpen && (
        <ClientEditorModal
          onClose={() => setModalOpen(false)}
          client={client}
          onSave={onSave}
        />
      )}
    </>
  );
};

export { ClientEditorButton };
