import * as React from 'react';
import { action, makeObservable, observable } from 'mobx';
import User from './User';
import Base from './_Base';

export class Store extends Base {
  loading: boolean = false;
  user: User;
  session: boolean | object = false;

  constructor() {
    super();
    makeObservable(this, {
      loading: observable,
      user: observable,
      session: observable,
      // actions
      checkSession: action,
    });
    this.loading = true;
    this.user = new User({});
  }

  init() {
    this.checkSession();
  }

  checkSession = async () => {
    try {
      this.session = await this.user.getSession();
    } catch (e) {
      this.session = false;
    }
    this.loading = false;
  };
}

export const StoreContext = React.createContext<Store>(null!);

export const useStore = () => React.useContext(StoreContext);

export default Store;
