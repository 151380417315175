import React from 'react';
import { Col, Row } from 'antd';
import { Card, Spin, Pie, Column } from '@src/components/sc-design-system';
import { Link, Divider } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import WebIcon from '@mui/icons-material/Web';
import HistoryIcon from '@mui/icons-material/History';
import TuneIcon from '@mui/icons-material/Tune';
import { observer } from 'mobx-react';
import { useStore } from '@src/models/Store';
import SessionAlerts from '../../SessionAlerts/SessionAlerts';
import UserAlerts from '../../UserAlerts/UserAlerts';
import styled from '@emotion/styled';
import { useAdminQueries } from '@src/hooks/useAdmin';

const StatisticLink = styled(Link)({ fontSize: '48px' });

import './Overview.less';
import GroupOutlined from '@mui/icons-material/GroupOutlined';

const cardBodyStyle = { textAlign: 'center', paddingBottom: '0 !important' };

export const Overview = observer(() => {
  const store = useStore();
  const { useAnalytics, useSessionStats } = useAdminQueries();
  const { analytics, isLoading: isAnalyticsLoading } = useAnalytics();
  const { sessionStats, isLoading: isSessionStatsLoading } = useSessionStats();

  return (
    <div className='dashboard-overview'>
      <Row
        gutter={50}
        style={{ marginBottom: '50px', marginLeft: '0', marginRight: '0' }}
      >
        <Col flex='1'>
          <Card
            sx={{ padding: '1em !important' }}
            bodyStyle={{ padding: '1em !important' }}
          >
            <SessionAlerts userId={store.user.id} />
          </Card>
        </Col>
        <Col flex='1'>
          <Card
            sx={{ padding: '1em !important' }}
            bodyStyle={{ padding: '.6em !important' }}
          >
            <UserAlerts />
          </Card>
        </Col>
      </Row>
      <Row gutter={50}>
        <Col flex={1}>
          <Card
            titleIcon={<GridViewIcon fontSize='large' />}
            title='Clients'
            bodyStyle={cardBodyStyle}
          >
            <StatisticLink
              variant='a1blank'
              href='/administration/clients?page=1'
            >
              {isAnalyticsLoading ? '...' : analytics?.clientCounts}
            </StatisticLink>
            <Divider />
            <Link
              variant='a2'
              key='controls'
              href='/administration/clients?page=1'
            >
              <TuneIcon fontSize='large' />
            </Link>
          </Card>
        </Col>
        <Col flex={1}>
          <Card
            titleIcon={<ContentPasteIcon fontSize='large' />}
            title='SOWs'
            bodyStyle={cardBodyStyle}
          >
            <StatisticLink variant='a1blank' href='/administration/sows?page=1'>
              {isAnalyticsLoading ? '...' : analytics?.sowsCounts}
            </StatisticLink>
            <Divider />
            <Link
              variant='a2'
              key='controls'
              href='/administration/sows?page=1'
            >
              <TuneIcon fontSize='large' />
            </Link>
          </Card>
        </Col>
        <Col flex={1}>
          <Card
            titleIcon={<WebIcon fontSize='large' />}
            title='Programs'
            bodyStyle={cardBodyStyle}
          >
            <StatisticLink
              variant='a1blank'
              href='/administration/programs?page=1'
            >
              {isAnalyticsLoading ? '...' : analytics?.programsCounts}
            </StatisticLink>
            <Divider />
            <Link
              variant='a2'
              key='controls'
              href='/administration/programs?page=1'
            >
              <TuneIcon fontSize='large' />
            </Link>
          </Card>
        </Col>
      </Row>
      <Row gutter={50} style={{ marginTop: '3em' }}>
        <Col flex='1' md={24} lg={12}>
          <Card titleIcon={<GroupOutlined fontSize='large' />} title='Users'>
            <Pie
              loading={isAnalyticsLoading}
              data={Object.keys(analytics?.allUsers ?? {})
                .filter(p => p !== 'total')
                .map(prop => ({
                  type: prop,
                  value: analytics?.allUsers[prop],
                }))}
              appendPadding={10}
              angleField='value'
              colorField='type'
            />
          </Card>
        </Col>
        <Col flex='1' md={24} lg={12}>
          <Card titleIcon={<HistoryIcon fontSize='large' />} title='Sessions'>
            <Pie
              loading={isAnalyticsLoading}
              data={Object.keys(analytics?.sessionStatusCounts ?? {})
                .filter(p => p !== 'total')
                .map(prop => ({
                  type: prop,
                  value: analytics?.sessionStatusCounts[prop],
                }))}
              appendPadding={10}
              angleField='value'
              colorField='type'
            />
          </Card>
        </Col>
      </Row>
      <Card
        sx={{ marginTop: '3em', marginLeft: '1.3em', marginRight: '1.3em' }}
        titleIcon={<HistoryIcon fontSize='large' />}
        title='Sessions - Last 7 Days'
      >
        {sessionStats ? (
          <Column
            {...{
              loading: isSessionStatsLoading,
              data: sessionStats,
              yField: 'count',
              xField: 'date',
              seriesField: 'name',
              isGroup: true,
            }}
          />
        ) : (
          <Spin sectionLoader />
        )}
      </Card>
    </div>
  );
});
