import React from 'react';
import { observer } from 'mobx-react';
import { EChart, Spin } from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import { Typography } from '@mui/material';

function ReportStakeholderAssessmentChart(
  props: ReportStakeholderAssessmentChartProps,
) {
  const { isLoadingStakeholderAssessmentCharts } = props;
  const [stakeholderCharts, setStakeholderCharts] = React.useState<any[]>([]);

  React.useEffect(() => {
    (async function () {
      try {
        if (isLoadingStakeholderAssessmentCharts) {
          const res = await api.surveys.getAggregateQuestionResultsByIds(
            props.clientId,
            props.surveyId,
            props.stakeholderQuestionIds,
            props.userId,
            props.programId,
          );
          if (res.status === 200) setStakeholderCharts(res.data);
          props.setIsLoadingStakeholderAssessmentCharts(false);
        }
      } catch (error) {
        props.setIsLoadingStakeholderAssessmentCharts('error');
      }
    })();
  }, [props, isLoadingStakeholderAssessmentCharts]);

  return (
    <>
      {isLoadingStakeholderAssessmentCharts === true && <Spin sectionLoader />}
      {isLoadingStakeholderAssessmentCharts === 'error' && null}
      {isLoadingStakeholderAssessmentCharts === false &&
        stakeholderCharts.length === 0 &&
        null}
      {isLoadingStakeholderAssessmentCharts === false &&
        stakeholderCharts.length > 0 &&
        stakeholderCharts.map((chart, index) => (
          <React.Fragment key={index}>
            <EChart
              props={{
                height: '550px',
                width: '100%',
                Title: <Typography variant='body1m'>{chart.title}</Typography>,
                options: chart.options,
                chartType: chart.chartType,
              }}
            />
          </React.Fragment>
        ))}
    </>
  );
}

export default observer(ReportStakeholderAssessmentChart);
