import React, { FC, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Divider, Link, Stack, Typography } from '@mui/material';
import { useStore } from '@src/models/Store';
import { UserGoalCycleCard } from '../components/UserGoalCycleCard';

interface GoalActivityProps {
  performanceCycleId: string;
  allActiveCycles: GoalCycle[];
  goalCycles: GoalCycle[];
  clientName: string;
  clientId: string;
}

const GoalActivity: FC<GoalActivityProps> = props => {
  const {
    performanceCycleId,
    allActiveCycles,
    goalCycles,
    clientName,
    clientId,
  } = props;
  const { user } = useStore();
  const [isArchiveVisible, setIsArchiveVisible] = useState(false);
  const activeCycles = useMemo(() => {
    return goalCycles?.filter(c => !c.end || dayjs().isBefore(c.end));
  }, [goalCycles]);
  const archivedCycles = useMemo(() => {
    return goalCycles?.filter(c => dayjs().isAfter(c.end));
  }, [goalCycles]);

  const tabDefinitions = useMemo(
    () => [
      { label: 'My Goals', isEditable: true },
      {
        label: `${clientName || 'Organization'} Goals`,
        emptyText: `${
          clientName || 'This organization'
        } has not added any goals to this cycle.`,
      },
    ],
    [clientName],
  );
  const tabPartitionBy = useMemo(
    () => (g: GoalBase) => g.user_ids?.includes(user.id),
    [user],
  );

  return (
    <Stack spacing={2}>
      <Typography variant='h3'>Goal activity</Typography>
      {activeCycles?.map((c, idx) => (
        <UserGoalCycleCard
          key={c.id}
          cycle={c}
          goalCycles={allActiveCycles}
          userId={user.id}
          tabDefinitions={tabDefinitions}
          tabPartitionBy={tabPartitionBy}
          clientId={clientId}
          performanceCycleId={performanceCycleId}
          defaultOpen={idx === 0}
          showStatusChip={true}
        />
      ))}
      {Boolean(archivedCycles?.length) && (
        <Stack>
          <Stack>
            <Divider />
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              sx={{ marginBottom: isArchiveVisible ? '2em' : 0 }}
            >
              <Typography variant='h4'>Archived Goals</Typography>
              <Link
                variant='a1blank'
                component='button'
                onClick={() => setIsArchiveVisible(!isArchiveVisible)}
              >
                {isArchiveVisible ? 'Hide' : 'Show'} Section
              </Link>
            </Stack>
            {isArchiveVisible &&
              archivedCycles?.map(c => (
                <UserGoalCycleCard
                  key={c.id}
                  cycle={c}
                  goalCycles={allActiveCycles}
                  userId={user.id}
                  tabDefinitions={tabDefinitions}
                  tabPartitionBy={tabPartitionBy}
                  clientId={clientId}
                  performanceCycleId={performanceCycleId}
                  defaultOpen={false}
                  showStatusChip={true}
                />
              ))}
            <Divider />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export { GoalActivity };
