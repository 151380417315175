import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { sow as SOWSchema } from '@shared/schemas';
import Base from './_Base';
import Program from './Program';

export const sowProperties = [
  'id',
  'client_id',
  'name',
  'description',
  'type',
  'salesforce_opp_id',
  'sessions_reserved',
  'sessions_per_member',
  'seats_reserved',
  'rate',
  'coach_rate',
  'start_date',
  'end_date',
  'is_active',
  'allowed_durations',
  'notes',
  'default_program_id',
  'resources',
  'options',
];

export default class SOW extends Base {
  id;
  client_id = undefined;
  name = '';
  description = undefined;
  type = 'standard';
  salesforce_opp_id = undefined;
  sessions_reserved = 0;
  sessions_per_member = 0;
  seats_reserved = 0;
  rate = 0;
  coach_rate = 0;
  start_date = Date.now();
  end_date = Date.now() + 1000 + 60 * 60 * 24 * 90;
  is_active = true;
  resources: string[] = [];
  allowed_durations = [60];
  notes: SOWNote[] = [];
  default_program_id?: string | boolean = false;
  programs?: Program[] = [];
  options = {
    custom_coach_selection: false,
    mentoring: false,
  };

  constructor(sow: SOWSchema.SOWType = {}) {
    super();
    makeObservable(this, {
      id: observable,
      client_id: observable,
      name: observable,
      type: observable,
      salesforce_opp_id: observable,
      sessions_reserved: observable,
      sessions_per_member: observable,
      seats_reserved: observable,
      rate: observable,
      coach_rate: observable,
      start_date: observable,
      end_date: observable,
      is_active: observable,
      allowed_durations: observable,
      notes: observable,
      default_program_id: observable,
      resources: observable,
      options: observable,
      // Helpers
      programs: observable,
      // Actions
      mergeData: action,
      // Computeds
      data: computed,
    });
    this.mergeData(sow);
  }

  mergeData = (sow: SOWSchema.SOWType) => {
    Object.keys(sow).forEach(prop => {
      this[prop] = sow[prop];
    });
  };

  get data() {
    return JSON.parse(
      JSON.stringify(
        sowProperties.reduce((acc, prop) => {
          acc[prop] = toJS(this[prop]);
          return acc;
        }, {}),
      ),
    );
  }
}
