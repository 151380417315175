import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Button, DialogTitle } from '@src/components/sc-design-system';
import { RecipientsTable } from './components/RecipientsTable';

interface ReviewAndSendStepProps {
  collection?: any;
  handleClose: Function;
  clientId: string;
  handleStepChange: Function;
}

const ReviewAndSendStep: FC<ReviewAndSendStepProps> = ({
  handleClose,
  handleStepChange,
}) => {
  const { watch } = useFormContext();
  const newRecipients = watch('recipients');

  return (
    <>
      <DialogTitle
        title='Review and Remind Recipients'
        buttonOnClick={() => handleClose()}
      />
      <DialogContent>
        <Stack spacing={4}>
          <Typography variant='body1'>
            You are about to send{' '}
            {newRecipients.length === 1 ? 'a reminder' : 'reminders'} to{' '}
            <strong>
              {newRecipients.length} user
              {newRecipients.length === 1 ? '' : 's'}
            </strong>{' '}
            who {newRecipients.length === 1 ? 'has' : 'have'} not completed the
            survey:
          </Typography>
          <RecipientsTable recipientsList={newRecipients} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          text='Back'
          variant='outlined'
          onClick={() => handleStepChange()}
        />
        <Button text='Remind' type='submit' disabled={!newRecipients?.length} />
      </DialogActions>
    </>
  );
};

export { ReviewAndSendStep };
