import React, { FC, useEffect, useState } from 'react';
import MUIAvatar, { AvatarProps as MUIAvatarProps } from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { COLOR } from './tokens/colors';

export interface AvatarProps extends MUIAvatarProps {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
  srcId?: string;
  src?: string;
}

const sizeMap = {
  xs: { height: 20, width: 20 },
  sm: { height: 24, width: 24 },
  md: { height: 28, width: 28 },
  lg: { height: 32, width: 32 },
  xl: { height: 40, width: 40 },
  xxl: { height: 48, width: 48 },
  xxxl: { height: 60, width: 60 },
};

const typographySizeMap = {
  circular: {
    xs: { fontSize: '8px' },
    sm: { fontSize: '10px' },
    md: { fontSize: '12px' },
    lg: { fontSize: '14px' },
    xl: { fontSize: '18px' },
    xxl: { fontSize: '21px' },
    xxxl: { fontSize: '24px' },
  },
  interactive: {
    xs: { fontSize: '6px' },
    sm: { fontSize: '8px' },
    md: { fontSize: '10px' },
    lg: { fontSize: '12px' },
    xl: { fontSize: '14px' },
    xxl: { fontSize: '16px' },
    xxxl: { fontSize: '18px' },
  },
};

const colorMap = {
  [COLOR.PRIMARY[500]]: { bgcolor: 'primary.500' },
  [COLOR.SECONDARY[500]]: {
    bgcolor: 'secondary.500',
    color: 'black !important',
  },
  [COLOR.TERTIARY[500]]: {
    bgcolor: 'tertiary.500',
    color: 'grey.900',
  },
  [COLOR.ACCENTS.RED[500]]: {
    bgcolor: 'error.500',
    color: 'black !important',
  },
  [COLOR.ACCENTS.YELLOW[500]]: {
    bgcolor: 'warning.500',
    color: 'black !important',
  },
};

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const avatarConfigCache = {};

function fallbackAvatar(name?: string) {
  if (!name) {
    return {
      sx: colorMap[Object.keys(colorMap)[Math.floor(Math.random() * 4)]],
    };
  }

  const config = avatarConfigCache[name]
    ? avatarConfigCache[name]
    : {
        sx: closestColor(stringToColor(name), colorMap),
        children: `${name.split(' ')[0][0]}${name.split(' ')[1]?.[0] ?? ''}`,
      };

  if (!avatarConfigCache[name]) {
    avatarConfigCache[name] = config;
  }

  return config;
}

const Avatar: FC<AvatarProps> = ({
  size = 'lg',
  sx,
  src,
  srcId,
  children,
  variant = 'circular',
  ...props
}) => {
  const [fallbackProps, setFallbackProps] = useState<{
    sx?: any;
    children?: any;
  }>({ sx: {} });

  const { data: avatarSrc } = useQuery({
    queryKey: ['avatar', srcId],
    queryFn: async () => {
      return `${
        process.env.ASSETS_ROOT
      }/${srcId}/avatar.png?cache=${Date.now()}`;
    },
    staleTime: 1000 * 60 * 60,
    enabled: !!srcId && !src,
  });

  // If the src or srcId changes, reset the fallback props
  // (This is primarily for the ClientSelector in case
  // one client has a logo and another doesn't)
  useEffect(() => {
    setFallbackProps({ sx: {} });
  }, [src, srcId]);

  return (
    <MUIAvatar
      sx={{ ...sizeMap[size], ...sx, ...fallbackProps?.sx }}
      src={srcId ? avatarSrc || src : 'missing_src_id'} // invited users will not have an id yet
      variant={variant}
      {...props}
      onError={() => {
        if (!children) {
          setFallbackProps(fallbackAvatar(props.alt));
        }
      }}
    >
      {children ? (
        children
      ) : fallbackProps?.children ? (
        <Typography
          sx={{ margin: '0 !important', ...typographySizeMap[variant][size] }}
        >
          {fallbackProps?.children}
        </Typography>
      ) : null}
    </MUIAvatar>
  );
};

export { Avatar };

// ------------------
// Adapted from https://nesin.io/blog/find-closest-color-javascript
// ------------------
function hexToRGB(hex) {
  // Remove the # character from the beginning of the hex code
  hex = hex.replace('#', '');

  // Convert the red, green, and blue components from hex to decimal
  // you can substring instead of slice as well
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Return the RGB value as an object with properties r, g, and b
  return [r, g, b];
}

const closestColor = (targetColor, colorMap) => {
  let closestDistance = null;
  let closestColor = null;

  // Convert target color from hex string to RGB values
  const [r1, g1, b1] = hexToRGB(targetColor);

  // Loop through the array of colors
  Object.keys(colorMap).forEach(color => {
    // Convert current color from hex string to RGB values
    const [r2, g2, b2] = hexToRGB(color);

    // Calculate the Euclidean distance between the target color and current color
    const distance = Math.sqrt(
      (r1 - r2) ** 2 + (g1 - g2) ** 2 + (b1 - b2) ** 2,
    );

    // Update closest color and distance if the current distance is smaller than the closest distance
    if (distance < closestDistance) {
      closestDistance = distance;
      closestColor = color;
    } else if (!closestDistance) {
      closestDistance = distance;
      closestColor = color;
    }
  });

  return colorMap[closestColor];
};
