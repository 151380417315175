import React, { useState, FC } from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import {
  Button,
  InputField,
  RadioGroup,
  TextareaField,
  DialogTitle,
} from '@src/components/sc-design-system';
import { CompetencySet } from '@src/models/CompetencySet';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { useFullScreen } from '@src/hooks/useFullScreen';

interface CompetencySetManagerPropsIF {
  clientId: string;
  selectedSet: CompetencySet;
  open: boolean;
  onClose: Function;
  onCreate?: Function;
  onSave: Function;
}

const CompetencySetManager: FC<CompetencySetManagerPropsIF> = ({
  clientId,
  selectedSet,
  open,
  onClose,
  onCreate,
  onSave,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const { fullScreen } = useFullScreen();

  const onFieldChange = (name: string, val: any) => {
    selectedSet[name] = val;
    validate();
  };

  const handleClose = () => {
    onClose();
  };

  const validate = () => {
    setIsValid(
      !!selectedSet.name?.length &&
        !!selectedSet.description &&
        !!selectedSet.competency_label,
    );
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    const isNew = !selectedSet.id;
    await selectedSet.save(clientId);
    if (isNew) {
      onCreate(selectedSet);
    }
    handleClose();
    await onSave();
    setIsSaving(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
      <DialogTitle
        title={`${selectedSet?.id ? 'Edit' : 'Create'} Competency Set`}
        buttonOnClick={handleClose}
      />
      <DialogContent>
        <Form
          id='main'
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={selectedSet}
        >
          <InputField
            name='name'
            rules={[{ required: true }]}
            label='Set Name'
            value={selectedSet?.name}
            onChange={val => onFieldChange('name', val)}
          />
          <TextareaField
            name='description'
            label='Description'
            rules={[{ required: true }]}
            value={selectedSet?.description}
            onChange={val => onFieldChange('description', val)}
            placeholder='Enter description here'
          />
          <RadioGroup
            name='competency_label'
            label='Competency Label'
            required
            options={[
              { key: 'competency', text: 'Competency', value: 'competency' },
              { key: 'value', text: 'Value', value: 'value' },
            ]}
            value={selectedSet?.competency_label ?? 'competency'}
            onChange={val => onFieldChange('competency_label', val)}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose} text='Cancel' />
        <Button
          form='main'
          type='submit'
          disabled={!isValid || isSaving}
          loading={isSaving}
          text='Save'
        />
      </DialogActions>
    </Dialog>
  );
};

export default observer(CompetencySetManager);
