import * as React from 'react';
import { observer } from 'mobx-react';
import {
  IntercomProvider as ReactIntercomProvider,
  useIntercom,
  IntercomProps,
} from 'react-use-intercom';
import { api } from '@src/lib/client';
import User from '@src/models/User';
import Program from '@src/models/Program';
import { useUser } from '@src/hooks/useUser';
import { useUserQueries } from '@src/hooks/useUserQueries';

const INTERCOM_APP_ID = 'hv1lq3u7';

export const IntercomProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isShown, setIsShown] = React.useState(false);
  return (
    <ReactIntercomProvider
      appId={INTERCOM_APP_ID}
      autoBoot
      onShow={() => setIsShown(true)}
      onHide={() => setIsShown(false)}
    >
      {children}
      <IntercomObserver isShown={isShown} />
    </ReactIntercomProvider>
  );
};

let IntercomObserver = ({ isShown }: { isShown: boolean }) => {
  const { update, hide } = useIntercom();
  const { user } = useUser();
  const { useGetUserPrograms } = useUserQueries(user.id);
  const { userPrograms, isLoading: isLoadingPrograms } =
    useGetUserPrograms('coachee');

  React.useEffect(() => {
    if (isLoadingPrograms) return;
    const details = getUserDetails(user, userPrograms);
    update(details);
  }, [user, userPrograms, isLoadingPrograms, update]);

  React.useEffect(() => {
    if (!isShown) return;

    const intercom = document.getElementById('intercom-frame');
    if (intercom) {
      const handleDocumentClick = (e: MouseEvent) => {
        if (!intercom.contains(e.target as any)) {
          hide();
        }
      };
      document.addEventListener('click', handleDocumentClick);
      return () => {
        document.removeEventListener('click', handleDocumentClick);
      };
    }
  }, [isShown, hide]);

  return null;
};
IntercomObserver = observer(IntercomObserver);

const getUserDetails = (
  user: User,
  programs?: Program[],
): Partial<IntercomProps> => {
  // PROD ONLY -> Is user in a trial client program?
  const trial_client = programs?.some(
    p => p.client_id === 'abb24888-4860-49b3-b784-d42111d5e554',
  );
  // Persist if we have value
  if (user.first_name && user.email) {
    localStorage.setItem(
      'intercom_name',
      `${user.first_name} ${user.last_name}`,
    );
    localStorage.setItem('intercom_email', user.email);
    localStorage.setItem(
      'intercom_role',
      user.is_admin ? 'administrator' : user.is_coach ? 'coach' : 'coachee',
    );
    localStorage.setItem(
      'intercom_trial_client',
      (trial_client ? 1 : 0).toString(),
    );
    const activeClientId = user.activeClientId;
    localStorage.setItem('intercom_client_id', activeClientId);
    api.clients.getClient(activeClientId).then(c => {
      localStorage.setItem('intercom_client_name', c.data?.name);
    });
  }
  // Get from LS persistance
  const intercomName = localStorage.getItem('intercom_name');
  const intercomEmail = localStorage.getItem('intercom_email');
  const intercomRole = localStorage.getItem('intercom_role');
  const intercomTrialClient = Boolean(
    +localStorage.getItem('intercom_trial_client'),
  );
  const intercomCompany = {
    companyId: localStorage.getItem('intercom_client_id'),
    name: localStorage.getItem('intercom_client_name'),
  };
  return {
    name: user.first_name
      ? `${user.first_name} ${user.last_name}`
      : intercomName || undefined,
    email: user.email || intercomEmail || undefined,
    company: intercomCompany,
    customAttributes: {
      role: user.is_admin
        ? 'administrator'
        : user.is_coach
        ? 'coach'
        : intercomRole || 'coachee',
      trial_client: intercomTrialClient || trial_client,
    },
  };
};
