import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Checkbox,
  Chip,
  InputAdornment,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import {
  useBreadcrumbs,
  Avatar,
  TableMUI,
  TextField,
  Select,
  MenuButton,
} from '@src/components/sc-design-system';
import Client from '@src/models/Client';
import { useClientQueries } from '@src/hooks/useClientQueries';
import MemberEditPanel from './ClientMemberListEditPanel';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { theme } from '@src/theme';

interface ClientMembersListProps {
  client: Client;
  currentUserId: string;
  isFetchingClient: boolean;
  enableRowSelectionColumn: boolean;
  toInvite?: Set<string>;
  setToInvite?: (s: Set<string>) => void;
  toRemind?: Set<string>;
  setToRemind?: (s: Set<string>) => void;
  hrisEnabled: boolean;
}

interface MemberFilterProps {
  memberType: string;
  setMemberType: (s: ClientMemberTypes) => void;
  setKeyword: (s: string) => void;
  mentoringEnabled: boolean;
}

function MemberFilter(props: MemberFilterProps) {
  const { memberType, setMemberType, setKeyword, mentoringEnabled } = props;
  const options = [
    {
      options: [
        { label: 'All', value: 'all' },
        { label: 'Client admin', value: 'client_admin' },
      ],
    },
  ];
  if (mentoringEnabled)
    options[0].options.push({ label: 'Mentor', value: 'mentor' });
  const [input, setInput] = React.useState('');
  const debouncedEventHandler = React.useMemo(
    () => debounce(value => setKeyword(value), 200),
    [setKeyword],
  );

  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={{ marginBottom: '2em', flexGrow: '1' }}
    >
      <Select
        sx={{
          borderRadius: '24px 0 0 24px',
          margin: 0,
          backgroundColor: 'grey.50',
          width: '150px',
        }}
        defaultValue={memberType}
        value={memberType}
        size='small'
        onChange={event =>
          setMemberType(event.target.value as ClientMemberTypes)
        }
        options={options}
      />
      <TextField
        fullWidth
        size='small'
        id='search'
        placeholder='Search'
        value={input}
        onChange={event => {
          setInput(event.target.value);
          debouncedEventHandler(event.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon fontSize='small' />
            </InputAdornment>
          ),
          sx: {
            borderRadius: '0 24px 24px 0',
            backgroundColor: 'grey.50',
            fieldset: { borderLeftWidth: '0' },
          },
        }}
      />
    </Stack>
  );
}

function InviteCheckboxHeader(props: {
  toInvite: Set<string>;
  setToInvite: (s: Set<string>) => void;
  toRemind: Set<string>;
  setToRemind: (s: Set<string>) => void;
  notInvitedList: string[];
  pendingList: string[];
}) {
  const {
    toInvite,
    notInvitedList,
    setToInvite,
    toRemind,
    setToRemind,
    pendingList,
  } = props;
  return (
    <MenuButton
      shape='rounded-square'
      color='grey'
      disablePadding
      size='compact'
      primaryActionText={
        <Checkbox
          sx={{
            padding: 0,
            '& svg': { fill: `${theme.palette.grey[500]} !important` },
          }}
          size='small'
          disabled={true}
          checked={
            (toInvite.size > 0 && toInvite.size === notInvitedList.length) ||
            (toRemind.size > 0 && toRemind.size === pendingList.length)
          }
          indeterminate={
            (toInvite.size > 0 && notInvitedList.length > toInvite.size) ||
            (toRemind.size > 0 && pendingList.length > toRemind.size)
          }
        />
      }
      onClickPrimaryAction={() => {
        if (toInvite.size > 0 || toRemind.size > 0) {
          setToInvite(new Set([]));
          setToRemind(new Set([]));
        }
      }}
      piggybackSecondaryActionOnPrimaryClick={true}
      secondaryActionIcon={<ArrowDropDownIcon sx={{ fontSize: '1.5rem' }} />}
      secondaryActions={[
        {
          key: 'pending',
          label: 'Pending',
          onClick: () => {
            setToRemind(new Set(pendingList));
            setToInvite(new Set());
          },
        },
        {
          key: 'not_invited',
          label: 'Not invited',
          onClick: () => {
            setToInvite(new Set(notInvitedList));
            setToRemind(new Set());
          },
        },
      ]}
    />
  );
}

function ClientMembersList(props: ClientMembersListProps) {
  const {
    client,
    currentUserId,
    isFetchingClient,
    enableRowSelectionColumn,
    toInvite,
    setToInvite,
    toRemind,
    setToRemind,
    hrisEnabled,
  } = props;
  const navigate = useNavigate();
  const { appendBreadcrumb } = useBreadcrumbs();
  const [keyword, setKeyword] = React.useState('');
  const [memberType, setMemberType] = React.useState<ClientMemberTypes>('all');
  const { useGetClientMembers, useGetClientSOWs } = useClientQueries(client.id);
  const {
    members,
    isLoading: membersLoading,
    isFetching: membersFetching,
  } = useGetClientMembers({
    keyword,
    memberType,
    include_invitees: true,
    include_hris: true,
  });
  if ((membersLoading || membersFetching) && toInvite?.size > 0) {
    setToInvite(new Set());
    setToRemind(new Set());
  }
  const { members: allClientMembers } = useGetClientMembers();
  const { sows } = useGetClientSOWs();
  const mentoringEnabled = React.useMemo(() => {
    return (sows || []).find(sow => sow?.options?.mentoring === true);
  }, [sows]);
  const programs = React.useMemo(() => {
    return sows?.reduce((arr, s) => {
      return arr.concat(s.programs);
    }, []);
  }, [sows]);

  const groupedStatuses = React.useMemo(
    function () {
      const grouped = {
        registered: [],
        pending_invite: [],
        not_invited: [],
      };
      for (const member of members || []) {
        if (member.status && grouped[member.status])
          grouped[member.status].push(member.email);
      }
      return grouped;
    },
    [members],
  );

  const columnHelper = createColumnHelper<ClientMember>();
  const columns = React.useMemo(
    function () {
      const c = [
        columnHelper.accessor('last_name', {
          cell: info => {
            const fullName = `${info.row.original.first_name} ${info.row.original.last_name}`;
            return (
              <Stack direction='row' spacing={2} alignItems='center'>
                <Avatar alt={fullName} srcId={info.row.original.id} size='xs' />
                <Link
                  variant='a2'
                  component='button'
                  onClick={() => {
                    const path = `/profile/${info.row.original.id}`;
                    navigate(path);
                    appendBreadcrumb({
                      path,
                      label: fullName,
                    });
                  }}
                  sx={{ textAlign: 'left' }}
                >
                  {fullName}
                </Link>
              </Stack>
            );
          },
          header: () => <span>Member</span>,
        }),
        columnHelper.accessor('status', {
          cell: info => {
            const status = info.getValue();
            return (
              <Chip
                variant='outlined'
                color={
                  status === 'registered'
                    ? 'success'
                    : status === 'pending_invite'
                    ? 'warning'
                    : 'default'
                }
                label={
                  status === 'registered'
                    ? 'Registered'
                    : status === 'pending_invite'
                    ? 'Pending invite'
                    : 'Not invited'
                }
                icon={
                  status === 'registered' ? (
                    <CheckCircleOutlineIcon />
                  ) : status === 'pending_invite' ? (
                    <WarningAmberIcon />
                  ) : (
                    <HighlightOffIcon />
                  )
                }
              />
            );
          },
          header: 'Status',
        }),
        columnHelper.accessor('title', {
          cell: info => (info.getValue() ? info.getValue() : '-'),
          header: 'Title',
        }),
        columnHelper.accessor('department', {
          cell: info => {
            const department = client.departments.find(
              ({ id }) => id === info.getValue()?.id,
            );
            return department
              ? department.name
              : typeof info.row.original?.department === 'string'
              ? info.row.original.department
              : '-';
          },
          header: 'Department',
        }),
        columnHelper.accessor('manager', {
          cell: info => {
            const managerMember = allClientMembers?.find(
              ({ id }) => id === info.getValue(),
            );
            return managerMember
              ? `${managerMember.first_name} ${managerMember.last_name}`
              : typeof info.row.original?.manager === 'string'
              ? info.row.original.manager
              : '-';
          },
          header: 'Manager',
        }),
        {
          id: 'edit_panel',
          cell: info => (
            <MemberEditPanel
              member={info.row.original}
              client={client}
              allClientMembers={allClientMembers}
              programs={programs}
              mentoringEnabled={mentoringEnabled}
              currentUserId={currentUserId}
            />
          ),
          header: '',
          size: 35,
          meta: { fitContent: true },
        },
      ];

      if (
        enableRowSelectionColumn &&
        (hrisEnabled || groupedStatuses.pending_invite.length > 0)
      ) {
        c.unshift({
          id: 'invite_checkbox',
          cell: info =>
            info.row.original.status === 'not_invited' ? (
              <Stack alignItems='center'>
                <Checkbox
                  sx={{ padding: 0 }}
                  defaultChecked={toInvite.has(info.row.original.email)}
                  onChange={event => {
                    const email = info.row.original.email;
                    if (event.target.checked) {
                      setToInvite(new Set(toInvite).add(email));
                    } else {
                      const newState = new Set(toInvite);
                      newState.delete(email);
                      setToInvite(newState);
                    }
                  }}
                  disabled={toRemind.size > 0}
                />
              </Stack>
            ) : info.row.original.status === 'pending_invite' ? (
              <Stack alignItems='center'>
                <Checkbox
                  sx={{ padding: 0 }}
                  defaultChecked={toRemind.has(info.row.original.email)}
                  onChange={event => {
                    const email = info.row.original.email;
                    if (event.target.checked) {
                      setToRemind(new Set(toRemind).add(email));
                    } else {
                      const newState = new Set(toRemind);
                      newState.delete(email);
                      setToRemind(newState);
                    }
                  }}
                  disabled={toInvite.size > 0}
                />
              </Stack>
            ) : null,
          header: () => (
            <InviteCheckboxHeader
              toInvite={toInvite}
              setToInvite={setToInvite}
              toRemind={toRemind}
              setToRemind={setToRemind}
              notInvitedList={groupedStatuses.not_invited}
              pendingList={groupedStatuses.pending_invite}
            />
          ),
          size: 71,
          meta: { fitContent: true },
        });
      }
      return c;
    },
    [
      allClientMembers,
      appendBreadcrumb,
      client,
      columnHelper,
      currentUserId,
      enableRowSelectionColumn,
      groupedStatuses,
      mentoringEnabled,
      navigate,
      programs,
      setToInvite,
      toInvite,
      setToRemind,
      toRemind,
      hrisEnabled,
    ],
  );

  return (
    <>
      <Stack
        direction='row'
        spacing={2}
        alignItems='baseline'
        sx={{ marginBottom: '1.5em' }}
      >
        <MemberFilter
          memberType={memberType}
          setMemberType={setMemberType}
          setKeyword={setKeyword}
          mentoringEnabled={mentoringEnabled}
        />
        {hrisEnabled && (
          <Typography variant='body2' color='grey.700'>
            Last synced {dayjs(client.merge_last_modified).format('MM/DD/YYYY')}
          </Typography>
        )}
      </Stack>
      <TableMUI
        data={members?.filter(m => !m.removedAt)}
        columns={columns}
        initialRowsPerPage={25}
        emptyState='No members found.'
        dataIsLoading={membersLoading || membersFetching || isFetchingClient}
        selectedRows={toInvite}
        selectedRowProp='email'
      />
    </>
  );
}

export default ClientMembersList;
