import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { UserSession } from '@src/session';
import { IntegrationsAPI } from './apis/integrations';
import { UsersAPI } from './apis/users';
import { SkillsAPI } from './apis/skills';
import { CoachesAPI } from './apis/coaches';
import { ClientsAPI } from './apis/clients';
import { SOWsAPI } from './apis/sows';
import { SessionsAPI } from './apis/sessions';
import { LoggerAPI } from './apis/logger';
import { PlatformAPI } from './apis/platform-api';
import { ProgramsAPI } from './apis/programs';
import { AnalyticsAPI } from './apis/analytics';
import { QuestionsAPI } from './apis/questions';
import { MailerAPI } from './apis/mailer';
import { ResourcesAPI } from './apis/resources';
import { FilesAPI } from './apis/files';
import { SurveysAPI } from './apis/surveys';
import { NotificationsAPI } from './apis/notifications';
import { AdminAPI } from './apis/admin';
import { EngagementAPI } from './apis/engagement';
import { ImagesAPI } from './apis/images';
import { GoalsCompetenciesAPI } from './apis/goals-competencies';
import { message } from 'antd';

export type APIClient = ReturnType<typeof createAPIClient>;
export type APIResponse<T> = Promise<AxiosResponse<T>>;

export type { Notification } from './apis/notifications';

export const createAPIClient = (http: HTTPClient) => {
  return {
    analytics: new AnalyticsAPI(http),
    clients: new ClientsAPI(http),
    coaches: new CoachesAPI(http),
    engagement: new EngagementAPI(http),
    files: new FilesAPI(http),
    integrations: new IntegrationsAPI(http),
    logger: new LoggerAPI(http),
    mailer: new MailerAPI(http),
    notifications: new NotificationsAPI(http),
    platform: new PlatformAPI(http),
    programs: new ProgramsAPI(http),
    questions: new QuestionsAPI(http),
    resources: new ResourcesAPI(http),
    surveys: new SurveysAPI(http),
    sessions: new SessionsAPI(http),
    skills: new SkillsAPI(http),
    sows: new SOWsAPI(http),
    users: new UsersAPI(http),
    admin: new AdminAPI(http),
    images: new ImagesAPI(http),
    goals_competencies: new GoalsCompetenciesAPI(http),
  };
};

export class HTTPClient {
  session: UserSession;
  instance: AxiosInstance;
  amplifySession: { jwt: any } | false;
  clientSession: { token: string | null; lastCache: number };

  constructor(session: UserSession) {
    this.session = session;
    let baseURL = '';
    if (['development', 'uat'].includes(process.env.NODE_ENV)) {
      baseURL = `${window.location.origin}/local`;
    } else {
      baseURL = process.env.API_ROOT;
    }
    this.amplifySession = false;
    this.clientSession = { token: null, lastCache: 0 };
    this.instance = axios.create({ baseURL });
  }

  async request(config: AxiosRequestConfig): Promise<AxiosResponse> {
    const session = await this.session.getSessionInfo();
    const userName = session
      ? `${session.attributes.given_name} ${session.attributes.family_name}`
      : 'User Not Defined';
    if (
      !this.clientSession.token ||
      this.clientSession.lastCache < Date.now() - 300000 // Max age 5m
    ) {
      this.amplifySession = await this.session.getSessionInfo();
      this.clientSession = {
        token: this.amplifySession && this.amplifySession.jwt,
        lastCache: Date.now(),
      };
    }
    config.headers = Object.assign(
      {},
      {
        Authorization: this.clientSession.token
          ? `Bearer ${this.clientSession.token}`
          : false,
      },
      {
        'X-Username': userName,
      },
      config.headers || {},
    );
    try {
      return await this.instance.request(config);
    } catch (e) {
      if (e.statusCode >= 500)
        message.error(
          'An error occured processing your request, please contact support',
        );
      throw e;
    }
  }
}

// FIXME: refactor to not be global, which will also fix this initialization
// issue in tests.
export let api: APIClient;
export const setGlobalAPIClient = (client: APIClient) => {
  api = client;
};
