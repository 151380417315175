import React, { useState, FC, useEffect } from 'react';
import { Form, Row, Col } from 'antd';
import {
  Button,
  InputField,
  TextareaField,
} from '@src/components/sc-design-system';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from '@mui/material';

import { Testimonial } from '@src/models/User';
import { observer } from 'mobx-react';

const TestimonialsManager: FC<{
  testimonials: Testimonial[];
  onChange: Function;
}> = ({ testimonials, onChange }) => {
  const [current, setCurrent] = useState(testimonials || []);

  useEffect(
    () => {
      onChange(current);
    },
    // FIXME: invalid dependency array
    // eslint-disable-next-line
    [current],
  );

  const isValid = () => {
    const isNotValid = current.filter(t => t.isValid === false);
    if (isNotValid.length === 0) {
      return true;
    }
    return false;
  };

  const addItem = () => {
    if (isValid()) {
      setCurrent([...current, new Testimonial({})]);
    }
  };

  const deleteItem = (index: number) =>
    setCurrent(current.filter((v, i) => i !== index));

  return (
    <>
      {current.map((testimonial, i) => (
        <div key={i}>
          {i > 0 && <Divider />}
          <Form>
            <Row>
              <Col flex={1}>
                <InputField
                  name={`testimonial-${i}.name`}
                  value={testimonial.name}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  validateStatus={
                    testimonial.name.length === 0 ? (
                      <>This field is required</>
                    ) : null
                  }
                  validateTrigger={['onSubmit', 'onBlur']}
                  placeholder='Name'
                  onChange={val => {
                    testimonial.name = val;
                    setCurrent([...current]);
                  }}
                />
              </Col>
              <Col flex={1}>
                <InputField
                  name={`testimonial-${i}.company`}
                  value={testimonial.company}
                  rules={[{ required: true }]}
                  placeholder='Company'
                  onChange={val => {
                    testimonial.company = val;
                    setCurrent([...current]);
                  }}
                />
              </Col>
              <Col flex={1}>
                <InputField
                  name={`testimonial-${i}.title`}
                  value={testimonial.title}
                  rules={[{ required: true }]}
                  validateStatus={
                    testimonial.title.length === 0 ? (
                      <>This field is required</>
                    ) : null
                  }
                  placeholder='Title'
                  onChange={val => {
                    testimonial.title = val;
                    setCurrent([...current]);
                  }}
                />
              </Col>
              <Col flex={0}>
                <IconButton onClick={() => deleteItem(i)}>
                  <CloseIcon />
                </IconButton>
              </Col>
            </Row>
            <Row>
              <Col flex={1} style={{ paddingRight: '2.8em' }}>
                <TextareaField
                  name={`testimonial-${i}.review`}
                  value={testimonial.review}
                  rules={[{ required: true }]}
                  validateStatus={
                    testimonial.review.length === 0 ? (
                      <>This field is required</>
                    ) : null
                  }
                  placeholder='Testimonial'
                  onChange={val => {
                    testimonial.review = val;

                    setCurrent([...current]);
                  }}
                />
              </Col>
            </Row>
          </Form>
        </div>
      ))}
      <div style={{ textAlign: 'center' }}>
        <Button
          disabled={!isValid()}
          onClick={addItem}
          size='small'
          text='Add New Testimonial'
        />
      </div>
    </>
  );
};

export default observer(TestimonialsManager);
