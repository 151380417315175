import React, { FC, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Box, Chip, IconButton, Stack, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { TableMUI as Table, Button } from '@src/components/sc-design-system';
import { useQuery } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import { ActiveClientKey, useActiveClient } from '@src/hooks/useActiveClient';
import { useNavigation } from '@src/navigation';
import { useQueryParams } from '@src/utils';
import User from '@src/models/User';
import { SurveySubmissionModal } from '@src/components/common/SurveySubmissionModal';

interface FeedbackRequestsProps {
  user: User;
}

const FeedbackRequests: FC<FeedbackRequestsProps> = ({ user }) => {
  const navigation = useNavigation();
  const query = useQueryParams();
  const [surveySubmissionModalData, setSurveySubmissionModalData] =
    useState<SurveySubmission | null>(null);
  const [surveySubjectName, setSurveySubjectName] = useState<string | null>(
    null,
  );
  const { client } = useActiveClient({
    queryKey: ActiveClientKey.profile_active_client_id,
  });
  const { data: feedbackRequests, isLoading } = useQuery({
    queryKey: ['user', user?.id, 'feedback-requests', client?.id],
    queryFn: async () =>
      api.users.getUserFeedbackRequests(user?.id, client?.id),
    select: ({ data }) => data?.feedback_requests,
    enabled: !!client,
  });

  const columns = useMemo(() => {
    return [
      {
        header: 'Feedback for',
        size: 250,
        meta: { fitContent: true },
        cell: ({ row }) => {
          return `${row.original?.member_first_name} ${row.original?.member_last_name}`;
        },
      },
      {
        header: 'Performance cycle',
        size: 250,
        meta: { fitContent: true },
        cell: ({ row }) => {
          return row.original?.performance_cycle_name;
        },
      },
      {
        header: 'Status',
        size: 150,
        meta: { fitContent: true },
        cell: ({ row }) => {
          const { request_data: requestData, submissions } = row.original || {};
          if (!submissions?.length) {
            return <Chip label='Not Started' />;
          }
          const latestSubmission = submissions[0];
          if (
            requestData?.feedback_submission_id &&
            !requestData.feedback_pending
          ) {
            return <Chip label='Submitted' color='success' />;
          }

          if (requestData?.feedback_pending) {
            if (latestSubmission.is_draft) {
              return <Chip color='warning' label='Draft' />;
            }
            return <Chip label='Not Started' />;
          }
        },
      },
      {
        header: 'Last updated',
        size: 100,
        meta: { fitContent: true },
        cell: ({ row }) => {
          const { request_data: requestData, submissions } = row.original || {};
          const latestSubmission = submissions?.[0];
          return dayjs(
            Math.max(
              latestSubmission?.updatedAt || 0,
              requestData?.requested_at,
            ),
          ).format('MM/DD/YYYY');
        },
      },
      {
        id: 'actions',
        header: '',
        size: 200,
        meta: { fitContent: true },
        cell: ({ row }) => {
          const { request_data: requestData, submissions } = row.original || {};
          const latestSubmission = submissions?.[0];
          return (
            <Box sx={{ textAlign: 'center' }}>
              {!latestSubmission ||
              requestData?.feedback_pending ||
              latestSubmission.is_draft ? (
                <Button
                  text='Give feedback'
                  formatText={false}
                  onClick={() => {
                    query.set('survey_id', row.original?.feedback_form_id);
                    query.set('user_id', row.original?.member_id);
                    query.set('client_id', client?.id);
                    query.set(
                      'performance_cycle_id',
                      row.original?.performance_cycle_id,
                    );
                    query.set('submitter_id', user.id);
                    query.set('email', user.email);
                    query.set('type', 'feedback');
                    query.set('persist_notification', 'true');
                    query.set(
                      'user_first_name',
                      row.original?.member_first_name,
                    );
                    query.set('user_last_name', row.original?.member_last_name);

                    navigation.replace(
                      `${navigation.location.pathname}?${query}`,
                    );
                  }}
                  size='small'
                />
              ) : (
                <IconButton
                  onClick={() => {
                    setSurveySubmissionModalData(latestSubmission);
                    setSurveySubjectName(
                      `${row.original?.member_first_name} ${row.original?.member_last_name}`,
                    );
                  }}
                >
                  <VisibilityOutlinedIcon color='primary' />
                </IconButton>
              )}
            </Box>
          );
        },
      },
    ];
  }, [navigation, query, client, user]);

  return (
    <Stack>
      <Typography variant='h4' component='h3'>
        Feedback requests
      </Typography>
      <Typography color='grey.700' sx={{ marginTop: '.5em' }}>
        Provide feedback to employees that have invited you as a stakeholder.
      </Typography>
      <Table
        dataIsLoading={isLoading}
        columns={columns}
        data={feedbackRequests || []}
        sx={{ marginTop: '1.5em' }}
      />
      {Boolean(surveySubmissionModalData) && (
        <SurveySubmissionModal
          surveySubmission={surveySubmissionModalData}
          onClose={() => {
            setSurveySubmissionModalData(null);
            setSurveySubjectName(null);
          }}
          title={`Feedback for ${surveySubjectName}`}
        />
      )}
    </Stack>
  );
};

export { FeedbackRequests };
