import React, { FC } from 'react';
import TrackChanges from '@mui/icons-material/TrackChanges';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import MessageOutlined from '@mui/icons-material/MessageOutlined';
import SpaceDashboardOutlined from '@mui/icons-material/SpaceDashboardOutlined';
import {
  View,
  ViewBody,
  ViewBodySection,
  ViewHeader,
} from '@src/components/sc-design-system';
import { GoalCycles } from './tabs/GoalCycles';
import { Feedback } from './tabs/Feedback';
import { PerformanceEvaluation } from './tabs/PerformanceEvaluation';
import { StatusDashboard } from './tabs/StatusDashboard';
import { useParams } from 'react-router';
import { useClientQueries } from '@src/hooks/useClientQueries';

const PerformanceCycleLandingPage: FC = () => {
  const { id: clientId, performanceCycleId } = useParams();
  const { useGetPerformanceCycleById } = useClientQueries(clientId);
  const { performanceCycle, isLoading } =
    useGetPerformanceCycleById(performanceCycleId);

  return (
    <View isLoading={isLoading}>
      <ViewHeader title={performanceCycle?.name} />
      <ViewBody>
        <ViewBodySection title='Goal Cycles' icon={<TrackChanges />}>
          <GoalCycles />
        </ViewBodySection>
        <ViewBodySection title='Feedback' icon={<MessageOutlined />}>
          <Feedback />
        </ViewBodySection>
        <ViewBodySection
          title='Performance Evaluation'
          icon={<ContentPasteIcon />}
        >
          <PerformanceEvaluation />
        </ViewBodySection>
        <ViewBodySection
          title='Performance Evaluation Status'
          icon={<SpaceDashboardOutlined />}
        >
          <StatusDashboard />
        </ViewBodySection>
      </ViewBody>
    </View>
  );
};

export { PerformanceCycleLandingPage };
