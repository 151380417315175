import React from 'react';
import {
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import {
  Button,
  DialogTitle,
  useToast,
} from '@src/components/sc-design-system';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { api } from '@src/lib/client';
import { useParams } from 'react-router';
import { EvaluationPeriodForm } from './EvaluationPeriodForm';
import dayjs from 'dayjs';

interface SetEvaluationPeriodModalProps {
  setOpenModal: (b: boolean) => void;
  openModal: boolean;
  performanceCycle: PerformanceCycleI;
  edit?: boolean;
  refetchPerformanceCycle: Function;
}

const SetEvaluationPeriodModal: React.FunctionComponent<
  SetEvaluationPeriodModalProps
> = props => {
  const toast = useToast();
  const { setOpenModal, openModal, performanceCycle, edit } = props;
  const { id: clientId } = useParams();

  const form = useForm({
    mode: 'all',
    defaultValues: {
      self_evaluation_start: performanceCycle?.self_evaluation_start
        ? dayjs(performanceCycle.self_evaluation_start)
        : '',
      self_evaluation_end: performanceCycle?.self_evaluation_end
        ? dayjs(performanceCycle.self_evaluation_end)
        : '',
      manager_evaluation_start: performanceCycle?.manager_evaluation_start
        ? dayjs(performanceCycle.manager_evaluation_start)
        : '',
      manager_evaluation_end: performanceCycle?.manager_evaluation_end
        ? dayjs(performanceCycle.manager_evaluation_end)
        : '',
    } as EvaluationPeriodFormDefaultValues,
  });
  const {
    reset,
    formState: { isValid, isDirty },
  } = form;

  const { mutate: setEvaluationPeriod, isPending } = useMutation({
    mutationFn: async (payload: Partial<PerformanceCycleI>) => {
      return api.clients.editPerformanceCycle(clientId, {
        ...payload,
        id: performanceCycle.id,
      });
    },
    onSuccess: () => {
      props.refetchPerformanceCycle();
      setOpenModal(false);
    },
    onError: () => {
      toast.error('Unable to set evaluation period');
    },
  });

  const onSubmit = (data: EvaluationPeriodOnSubmitProps) => {
    setEvaluationPeriod({
      self_evaluation_start: data.self_evaluation_start.valueOf(),
      self_evaluation_end: data.self_evaluation_end.valueOf(),
      manager_evaluation_start: data.manager_evaluation_start.valueOf(),
      manager_evaluation_end: data.manager_evaluation_end.valueOf(),
    });
  };

  function handleClose() {
    setOpenModal(false);
    reset();
  }

  return (
    <Dialog open={openModal} onClose={() => handleClose()}>
      <DialogTitle title={`${edit ? 'Set' : 'Edit'} evaluation period`} />
      <DialogContent dividers>
        <Alert severity='info' icon={false}>
          <AlertTitle>Important!</AlertTitle>
          <Typography variant='body2'>
            Once an evaluation period begins, <strong>no changes</strong> can be
            made to the evaluation dates, feedback forms, and rating scale.
          </Typography>
        </Alert>
        <EvaluationPeriodForm
          onSubmit={onSubmit}
          form={form}
          performanceCycleStartDate={performanceCycle.start}
        />
      </DialogContent>
      <DialogActions>
        <Button
          key='cancel'
          text='Cancel'
          onClick={handleClose}
          variant='outlined'
        />
        <Button
          sx={{ marginLeft: '.5em' }}
          type='submit'
          form='evaluation-period-form'
          disabled={!isValid || !isDirty}
          key='save'
          text='Save'
          loading={isPending}
        />
      </DialogActions>
    </Dialog>
  );
};

export { SetEvaluationPeriodModal };
