import React, { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import startCase from 'lodash/startCase';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Close from '@mui/icons-material/Close';
import { useConfirm } from 'material-ui-confirm';
import { api } from '@src/lib/client';
import { useQueryParams } from '@src/utils';
import {
  Button,
  IconMenuButton,
  useBreadcrumbs,
  Pagination,
  useToast,
} from '@src/components/sc-design-system';
import SurveyType from '@src/models/Survey';
import { Survey } from '@src/components/common/Survey/Survey';
import { DuplicateSurveyModal } from '../DuplicateSurveyModal';
import './Surveys.less';

const PAGE_LIMIT = 100;

function truncateDescription(desc) {
  const maxLength = 30;
  if (!desc || desc.length <= maxLength) return false;
  return desc.substring(0, maxLength) + '...';
}

const setQueryParams = (
  navigate: Function,
  query: any,
  tabIndex: number,
  name: string,
  value: string | number,
) => {
  let currentKeyword = query.get('keyword') || '';
  let currentPage = query.get('page') || 1;

  // Handle prop change
  if (name === 'keyword') {
    currentPage = 1; // Need reset on search
    currentKeyword = value;
  }
  if (name === 'page') {
    currentPage = value;
  }

  // Build query
  const composedQuery = `${
    currentKeyword && currentKeyword.length > 1
      ? `&keyword=${currentKeyword}`
      : ''
  }${currentPage && `&page=${currentPage}`}${
    tabIndex != null && `&tab=${tabIndex}`
  }`;
  navigate({
    pathname: window.location.pathname,
    search: composedQuery,
  });
};

let currentGetReqTimeout: any;

const Surveys: FC<{
  clientId?: string;
  openNewSurvey?: Function;
  tabIndex?: number;
}> = ({ clientId, openNewSurvey, tabIndex }) => {
  const queryClient = useQueryClient();
  const query = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { appendBreadcrumb } = useBreadcrumbs();
  const setQuery = setQueryParams.bind(null, navigate, query, tabIndex);
  const keyword = query.get('keyword') || '';
  const page = +query.get('page') || 1;
  const [currentKeyword, setCurrentKeyword] = useState(keyword);
  const [showSurveyPreview, setSurveyPreview] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState<
    undefined | SurveyType
  >();
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showDuplicateForClientModal, setShowDuplicateForClientModal] =
    useState(false);

  const { data, isLoading } = useQuery({
    queryKey: clientId
      ? ['client', clientId, 'surveys', { keyword, page }]
      : ['surveys', { keyword, page }],
    queryFn: async () =>
      api.surveys.get({
        limit: PAGE_LIMIT,
        includeProgramData: true,
        keyword,
        page,
        client_id: clientId,
      }),
    select: ({ data }) => {
      const activeSurveys = data
        .filter(s => !s.archived_at)
        .map(s => new SurveyType(s));
      return {
        surveys: activeSurveys,
        total: activeSurveys.length,
      };
    },
  });
  const confirm = useConfirm();
  const toast = useToast();

  const previewSurvey = async (surveyId: string) => {
    setSurveyPreview(true);
    const res = await api.surveys.getById(surveyId);
    setSelectedSurvey(res.data);
  };

  function handleDuplicate(survey: SurveyType, forClient: boolean = false) {
    if (!forClient) {
      setShowDuplicateModal(true);
    } else {
      setShowDuplicateForClientModal(true);
    }
    setSelectedSurvey(survey);
  }

  const handleArchive = async (
    survey: SurveyType,
    hasResponses: boolean,
    isUsedInPrograms: boolean,
  ) => {
    // TODO: confirmation
    if (hasResponses) {
      toast.warn('This survey has responses submitted and cannot be deleted.');
      return;
    }
    if (isUsedInPrograms) {
      toast.warn(
        'This survey is currently enabled on a program. In order to delete, it needs to be removed from the program.',
      );
      return;
    }
    try {
      await confirm({
        description: `Are you sure you want to delete ${survey.title}?`,
        title: '',
        dialogProps: { maxWidth: 'xs' },
        confirmationText: 'Confirm',
        cancellationButtonProps: {
          variant: 'outlined',
          className: 'shape-pill',
        },
        confirmationButtonProps: {
          className: 'shape-pill',
        },
      });
      await api.surveys.archiveSurvey(survey.id);
      queryClient.invalidateQueries({
        queryKey: clientId ? ['client', clientId, 'surveys'] : ['surveys'],
      });
    } catch (err) {}
  };

  if (data?.surveys?.length === 0 && !isLoading && !keyword) {
    return (
      <Stack spacing={2} textAlign='center'>
        <Typography variant='h3'>
          Get started creating surveys for your organization.
        </Typography>
        <div style={{ marginTop: '1em' }}>
          <Button onClick={() => openNewSurvey()} text='Start Now' />
        </div>
      </Stack>
    );
  }

  return (
    <>
      <TextField
        placeholder='Search...'
        fullWidth
        onChange={e => {
          setCurrentKeyword(e.target.value);
          if (currentGetReqTimeout) clearTimeout(currentGetReqTimeout);
          currentGetReqTimeout = setTimeout(async () => {
            setQuery('keyword', e.target.value);
          }, 200);
        }}
        value={currentKeyword}
        InputProps={{
          endAdornment: currentKeyword ? (
            <InputAdornment position='end'>
              <IconButton
                aria-label='clear search input'
                onClick={() => {
                  setQuery('keyword', '');
                  setCurrentKeyword('');
                }}
                // TODO: abstract this to theme
                sx={{
                  border: 'none',
                  '&:focus': { backgroundColor: 'grey.200' },
                  '&:hover': { backgroundColor: 'grey.200' },
                  '&:active': { backgroundColor: 'grey.400' },
                }}
              >
                <Close fontSize='small' />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant='head' sx={{ width: '30%' }}>
              Survey name
            </TableCell>
            <TableCell variant='head' sx={{ width: '12%' }}>
              Status
            </TableCell>
            <TableCell variant='head' sx={{ width: '20%' }}>
              Description
            </TableCell>
            <TableCell variant='head' sx={{ width: '20%' }}>
              Total Questions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.surveys?.map(s => {
            const hasResponses = s.responses > 0;
            const isUsedInPrograms = s.programs > 0;
            const truncatedDescription = truncateDescription(s.description);
            return (
              <TableRow key={s.id}>
                <TableCell>
                  <Link
                    component='button'
                    onClick={() => {
                      const queryString = `?id=${s.id}`;
                      const path = `${
                        clientId ? `/my-business/${clientId}` : ''
                      }/surveys/editor`;
                      navigate(`${path}${queryString}`);
                      appendBreadcrumb({
                        path,
                        label: s.title,
                        search: location.search,
                      });
                    }}
                    variant='a1dash'
                    sx={{ textAlign: 'left' }}
                  >
                    {s.title}
                  </Link>
                </TableCell>
                <TableCell>
                  <Chip
                    label={startCase(s.status)}
                    color={
                      s.status === 'published'
                        ? 'success'
                        : s.status === 'unpublished-changes'
                        ? 'warning'
                        : 'default'
                    }
                    className={
                      !s.status || s.status === 'draft' ? 'grey' : null
                    }
                  />
                </TableCell>
                <TableCell>
                  {s.description ? (
                    <Tooltip title={truncatedDescription ? s.description : ''}>
                      <Typography>
                        {truncatedDescription || s.description}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography color='grey.700'>No description</Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Stack
                    justifyContent='space-between'
                    direction='row'
                    alignItems='center'
                  >
                    <Typography>{s.questions.length}</Typography>
                    <IconMenuButton
                      items={[
                        [
                          {
                            key: 'edit',
                            label: 'Edit',
                            onClick: () => {
                              const queryString = `?id=${s.id}`;
                              const path = `${
                                clientId ? `/my-business/${clientId}` : ''
                              }/surveys/editor`;
                              navigate(`${path}${queryString}`);
                              appendBreadcrumb({
                                path,
                                label: s.title,
                                search: location.search,
                              });
                            },
                          },
                          /**
                           * Only show Collect Responses for published surveys
                           */
                          ...(s.last_published_id
                            ? [
                                {
                                  key: 'collect-responses',
                                  label: 'Collect Responses',
                                  onClick: () => {
                                    const queryString = `?id=${s.id}`;
                                    const path = `${
                                      clientId ? `/my-business/${clientId}` : ''
                                    }/surveys/editor`;
                                    navigate(`${path}${queryString}&tab=1`);
                                    appendBreadcrumb({
                                      path,
                                      label: s.title,
                                      search: location.search,
                                    });
                                  },
                                },
                              ]
                            : []),
                          /**
                           * Only show View Insights for current published client surveys
                           */
                          ...(s.client_id &&
                          s.status === 'published' &&
                          s.last_published_id
                            ? [
                                {
                                  key: 'insights',
                                  label: 'View Insights',
                                  onClick: () => {
                                    navigate(
                                      `/insights/${s.client_id}?sid=${s.last_published_id}`,
                                    );
                                  },
                                },
                              ]
                            : []),
                        ],
                        {
                          key: 'preview',
                          label: 'Preview',
                          onClick: () => previewSurvey(s.id),
                        },
                        {
                          key: 'duplicate',
                          label: 'Duplicate',
                          onClick: () => handleDuplicate(s),
                        },
                        ...(window.location.pathname === '/surveys'
                          ? [
                              {
                                key: 'duplicate-for-client',
                                label: 'Duplicate for Client',
                                onClick: () => handleDuplicate(s, true),
                              },
                            ]
                          : []),
                        ...(!s.isSCDefault
                          ? [
                              {
                                key: 'archive',
                                label: 'Archive',
                                onClick: () =>
                                  handleArchive(
                                    s,
                                    hasResponses,
                                    isUsedInPrograms,
                                  ),
                              },
                            ]
                          : []),
                      ]}
                    />
                  </Stack>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {showSurveyPreview && selectedSurvey && (
        <Survey
          previewSurvey={selectedSurvey}
          previewQuestions={selectedSurvey.questions}
          handleClose={() => {
            setSurveyPreview(false);
            setSelectedSurvey(undefined);
          }}
        />
      )}
      {showDuplicateModal && (
        <DuplicateSurveyModal
          survey={selectedSurvey}
          handleClose={() => {
            setSelectedSurvey(undefined);
            setShowDuplicateModal(false);
          }}
        />
      )}
      {showDuplicateForClientModal && (
        <DuplicateSurveyModal
          survey={selectedSurvey}
          forClient={true}
          handleClose={() => {
            setSelectedSurvey(undefined);
            setShowDuplicateForClientModal(false);
          }}
        />
      )}
      <Pagination
        sx={{ marginTop: '2em' }}
        pageSize={PAGE_LIMIT}
        totalItems={data?.total ?? 1}
        onChange={(_, value) => {
          setQuery('page', value);
        }}
      />
    </>
  );
};

export default Surveys;
