import { HTTPClient } from '@src/lib/client';

export class ImagesAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async uploadImage(data: SaveImageRequest) {
    return this.http.request({
      url: '/images/upload',
      method: 'post',
      data,
    });
  }
}
