import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';

obey.creator('uuid', () => uuidv4());
obey.creator('now', () => Date.now());

export const NotificationSchema = {
  id: { type: 'string', required: true, creator: 'uuid' },
  user_id: { type: 'string' },
  data: {
    type: 'object',
    keys: {
      program_id: { type: 'string' },
      first_name: { type: 'string' },
      last_name: { type: 'string' },
      email: { type: 'string' },
      type: { type: 'string' },
      subtype: { type: 'string' },
      actions: { type: 'array', values: { type: 'string' } },
    },
  },
  meta: { type: 'object' },
  dismissable: { type: 'boolean', default: false },
  url: { type: 'string' },
  dismissed_at: { type: 'number' },
  completed_at: { type: 'number' },
  nudged: {
    type: 'object',
    keys: {
      sendEmail: {
        type: 'array',
        values: {
          type: 'object',
          keys: {
            nudgeAttemptedAt: { type: 'number' },
            result: { type: 'string' },
          },
        },
      },
    },
  },
};

// eslint-disable-next-line no-unused-vars
export type NotificationType = { [K in keyof typeof NotificationSchema]?: any };

export const NotificationModel = obey.model(NotificationSchema);
