import React, { FC } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { DialogTitle, Button } from '@src/components/sc-design-system';
import { api } from '@src/lib/client';
import { InviteeSelect } from './InviteeSelect';

interface FeedbackInviteModalProps {
  onClose: () => void;
  setStakeholders: Function;
  stakeholders: any[];
  keyword: string | null;
  setKeyword: Function;
  filteredMemberOptions: any[];
  isFetching?: boolean;
  userId: string;
  userFirstName: string;
  userLastName: string;
  clientId: string;
  performanceCycleId: string;
  feedbackFormId: string;
  onSubmit: () => void;
}

const FeedbackInviteModal: FC<FeedbackInviteModalProps> = ({
  onClose,
  setStakeholders,
  stakeholders,
  keyword,
  setKeyword,
  filteredMemberOptions,
  isFetching,
  clientId,
  performanceCycleId,
  feedbackFormId,
  userId,
  userFirstName,
  userLastName,
  onSubmit,
}) => {
  const queryClient = useQueryClient();
  const { mutate: inviteStakeholders, isPending } = useMutation({
    mutationFn: async (data: any) => {
      return api.clients.updatePerformanceCycleFeedback(
        clientId,
        performanceCycleId,
        feedbackFormId,
        data,
      );
    },
    onSuccess: () => {
      setStakeholders([]);
      onClose();
      onSubmit();
      queryClient.invalidateQueries({
        queryKey: ['user', userId, 'summary-inputs', clientId],
      });
    },
  });

  const handleSubmit = () => {
    const body = {
      member_id: userId,
      member_first_name: userFirstName,
      member_last_name: userLastName,
      stakeholders: stakeholders?.map(s => s.value),
    };
    inviteStakeholders(body);
  };

  return (
    <Dialog open onClose={onClose} maxWidth='sm'>
      <DialogTitle title='Invite stakeholders' />
      <DialogContent>
        <Typography sx={{ marginBottom: '1em' }}>
          You are requesting feedback from the following people. They will be
          notified via an in-platform notification and email request.
        </Typography>
        <InviteeSelect
          value={stakeholders}
          loading={isFetching}
          disabled={isPending}
          limitTags={2}
          keyword={keyword}
          options={filteredMemberOptions}
          onSelect={(e, value) => {
            setStakeholders(value);
            setKeyword(null);
            if (value.length === 0) {
              onClose();
            }
          }}
          onInputChange={setKeyword}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          text='Back'
          disabled={isPending}
          variant='outlined'
        />
        <Button
          onClick={handleSubmit}
          loading={isPending}
          disabled={isPending}
          text='Invite'
        />
      </DialogActions>
    </Dialog>
  );
};

export { FeedbackInviteModal };
