import React, { useState, FC, useEffect } from 'react';
import { Form, Row, Col } from 'antd';
import {
  InputField,
  SelectField,
  Button,
  Icon,
} from '@src/components/sc-design-system';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface LinkItem {
  type: string;
  value: string;
}

const options = [
  'website',
  'linkedin',
  'youtube',
  'twitter',
  'facebook',
  'instagram',
  'video',
];
const upperCaseFirst = (s: string) => `${s[0].toUpperCase()}${s.slice(1)}`;

const urlRegEx = new RegExp(
  '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$',
);

const LinksManager: FC<{ links: LinkItem[]; onChange: Function }> = ({
  links,
  onChange,
}) => {
  const [current, setCurrent] = useState(links || []);
  useEffect(
    () => {
      onChange(current);
    },
    // FIXME: invalid dependency array
    // eslint-disable-next-line
    [current],
  );
  const addItem = () => {
    if (isValid()) {
      setCurrent([...current, { type: '', value: '' }]);
    }
  };
  const deleteItem = (index: number) =>
    setCurrent(current.filter((v, i) => i !== index));
  const changeItem = (index: number, item: LinkItem) =>
    setCurrent(current.map((c, i) => (i === index ? item : c)));

  const isValid = () => {
    const isNotValid = current.filter(
      c => c.type.length === 0 || urlRegEx.test(c.value) === false,
    );
    if (isNotValid.length === 0) {
      return true;
    }
    return false;
  };

  return (
    <Form validateTrigger='onChange'>
      {current.map((item, i) => (
        <Row key={i}>
          <Col flex='0' style={{ minWidth: '15em' }}>
            <SelectField
              search={false}
              options={options.map(c => ({
                value: c,
                label: (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon
                      name={c}
                      style={{
                        marginRight: '4px',
                      }}
                    />{' '}
                    <div style={{ paddingLeft: '.3em' }}>
                      {upperCaseFirst(c)}
                    </div>
                  </div>
                ),
              }))}
              value={item.type}
              initialValue={item.type}
              rules={[
                {
                  required: true,
                },
              ]}
              validateStatus={
                item.type.length === 0 ? (
                  <span>This field is required</span>
                ) : null
              }
              name={`link-${i}.type`}
              onChange={val => {
                changeItem(i, { type: val, value: item.value });
              }}
            />
          </Col>
          <Col flex='1'>
            <InputField
              name={`link-${i}.value`}
              rules={[{ required: true }, { type: 'url', warningOnly: true }]}
              validateStatus={
                item.value.length === 0 ? (
                  <span>This field is required</span>
                ) : !urlRegEx.test(item.value) ? (
                  <span>Please provide a valid URL</span>
                ) : null
              }
              value={item.value}
              placeholder='URL'
              onChange={val => {
                changeItem(i, { type: item.type, value: val });
              }}
            />
          </Col>
          <Col>
            <IconButton onClick={() => deleteItem(i)}>
              <CloseIcon />
            </IconButton>
          </Col>
        </Row>
      ))}
      <Form.Item>
        <div style={{ textAlign: 'center' }}>
          <Button
            disabled={!isValid()}
            onClick={addItem}
            size='small'
            text='Add Social Media Link'
          />
        </div>
      </Form.Item>
    </Form>
  );
};

export default LinksManager;
