import React, { FC, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { Button, useToast } from '@src/components/sc-design-system';
import SyncIcon from '@mui/icons-material/Sync';
import { api } from '@src/lib/client';
import { useStore } from '@src/models/Store';
import Client from '@src/models/Client';
import { MergeLink } from './MergeLink';

const HRISSetupIntegration: FC<{ client: Client }> = ({ client }) => {
  const store = useStore();
  const [linkToken, setLinkToken] = React.useState<string | null>(null);
  const [isGettingLinkToken, setIsGettingLinkToken] = React.useState(false);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const toast = useToast();

  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true);
  };

  useEffect(() => {
    if (linkToken) {
      handleOpenConfirmModal();
    }
  }, [linkToken]);

  return (
    <>
      {openConfirmModal && (
        <MergeLink
          client={client}
          linkToken={linkToken}
          openConfirmModal={openConfirmModal}
          setOpenConfirmModal={setOpenConfirmModal}
        />
      )}
      <Stack spacing={2}>
        <Typography variant='body1m'>Connect your HRIS</Typography>
        <Typography variant='body1'>
          Easily add all of your employee and desired information
        </Typography>
      </Stack>
      <Button
        startIcon={<SyncIcon />}
        variant='outlined'
        text='Connect HRIS'
        formatText={false}
        loading={isGettingLinkToken}
        onClick={async () => {
          setIsGettingLinkToken(true);
          try {
            const token = await api.integrations.getMergeLinkToken({
              adminEmail: store.user.email,
              clientName: client.name,
              clientId: client.id,
            });
            setLinkToken(token.data);
          } catch (error) {
            toast.error('Unable to connect HRIS integration');
          } finally {
            setIsGettingLinkToken(false);
          }
        }}
      />
    </>
  );
};

export { HRISSetupIntegration };
