import User from '@src/models/User';
import { observer } from 'mobx-react';
import React, { FC, ReactChild, useState } from 'react';
import {
  Avatar,
  Button,
  ConfirmDialog,
} from '@src/components/sc-design-system';
import { UserSchema } from '@shared/schemas';
import {
  Link,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  Switch,
  FormControlLabel,
  Stack,
} from '@mui/material';
import { handleKeyboardNav } from '@src/utils';

interface UserProfileProps {
  user: User;
  children: ReactChild;
  list?: typeof UserSchema[];
  setList?: Function;
}

const UserProfile: FC<UserProfileProps> = observer(
  ({ user, children, list, setList }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    return (
      <>
        <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
          <DialogContent sx={{ textAlign: 'center' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Avatar
                size='xxxl'
                srcId={user.id}
                alt={`${user.first_name} ${user.last_name}`}
              />
            </div>
            <Typography variant='h2' style={{ marginTop: '1em' }}>
              {user.first_name} {user.last_name}
            </Typography>
            <Link variant='a2' href={`mailto://${user.email}`}>
              {user.email}
            </Link>
            <Divider />
            <Stack spacing={2} alignItems='center'>
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      aria-labelledby='coach-switch-label'
                      checked={user.is_coach}
                      onChange={e => {
                        user.setRole('is_coach', e.target.checked);
                        setList([...list]);
                      }}
                    />
                  }
                  label='Coach'
                />
                <FormControlLabel
                  label='Administrator'
                  control={
                    <Switch
                      checked={user.is_admin}
                      onChange={() => {
                        setConfirmModalOpen(true);
                      }}
                    />
                  }
                />
              </div>
            </Stack>
            {confirmModalOpen && (
              <ConfirmDialog
                open={confirmModalOpen}
                setOpenModal={setConfirmModalOpen}
                title='Are you sure you wish to continue?'
                body={
                  user.is_admin
                    ? "This will disable the user's ability to access the application as an administrator."
                    : 'This will give the user administrative access to the application.'
                }
                buttonProps={{
                  color: 'error',
                  onClick: () => {
                    user.setRole('is_admin', !user.is_admin);
                    setConfirmModalOpen(false);
                    setList([...list]);
                  },
                  text: user.is_admin
                    ? 'Revoke Admin Rights'
                    : 'Grant Admin Rights',
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button text='Close' onClick={() => setModalOpen(false)} />
          </DialogActions>
        </Dialog>
        <span
          role='button'
          tabIndex={0}
          className='modal-open-click-target'
          onClick={() => setModalOpen(true)}
          onKeyDown={e => handleKeyboardNav(e.key, () => setModalOpen(true))}
        >
          {children}
        </span>
      </>
    );
  },
);

export default UserProfile;
