import React from 'react';
import dayjs from 'dayjs';
import { Chip, Link, Stack, Typography } from '@mui/material';
import { Button, Card, useBreadcrumbs } from '@src/components/sc-design-system';
import { EditPerformanceCycleModal } from '@src/components/views/MyBusiness/Performance/tabs/PerformanceCycles/modals/EditPerformanceCycleModal';
import { useNavigation } from '@src/navigation';

interface PerformanceCycleCardProps {
  active: boolean;
  clientId: string;
  performanceCycle: PerformanceCycleI;
  performanceCycleNames: string[];
}

const PerformanceCycleCard: React.FunctionComponent<
  PerformanceCycleCardProps
> = props => {
  const navigation = useNavigation();
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const { active, clientId, performanceCycle, performanceCycleNames } = props;
  const { appendBreadcrumb } = useBreadcrumbs();

  return (
    <>
      <Card
        headerColor={!active ? 'grey' : undefined}
        title={performanceCycle.name}
        subheader={
          <Stack direction='row' spacing={1.5} sx={{ marginTop: '1em' }}>
            <Typography
              variant='body1'
              color={active ? 'grey.700' : 'black.main'}
            >
              {dayjs(performanceCycle.start).format('MMM DD, YYYY')} -{' '}
              {dayjs(performanceCycle.end).format('MMM DD, YYYY')}
            </Typography>
            {active ? (
              <Chip color='success' label='Active' />
            ) : (
              <Chip label='Inactive' />
            )}
          </Stack>
        }
        titleActions={
          <Stack spacing={2} alignItems='center'>
            <Button
              text='View'
              sx={{ width: '200px' }}
              onClick={() =>
                navigation.push(
                  `/my-business/${clientId}/performance/${performanceCycle.id}`,
                )
              }
            />
            <Link
              key='edit-performance-cycle'
              variant='a2'
              onClick={event => {
                event.stopPropagation();
                setOpenEditModal(true);
              }}
            >
              Edit details
            </Link>
          </Stack>
        }
        headerOnClick={() => {
          const path = `/my-business/${clientId}/performance/${performanceCycle.id}`;
          navigation.push(path);
          appendBreadcrumb({
            path,
            label: performanceCycle.name,
          });
        }}
        bodyStyle={{ '&.MuiCardContent-root': { padding: 0 } }}
      />
      <EditPerformanceCycleModal
        clientId={clientId}
        performanceCycle={performanceCycle}
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        performanceCycleNames={performanceCycleNames}
      />
    </>
  );
};

export { PerformanceCycleCard };
