import Store from '@src/models/Store';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Input, Pagination, Space } from 'antd';
import {
  Card,
  View,
  ViewHeader,
  ViewBody,
  ViewBodySection,
  useBreadcrumbs,
  Avatar,
} from '@src/components/sc-design-system';
import { observer } from 'mobx-react';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { handleKeyboardNav, useQueryParams } from '@src/utils';
import { useQueryClient } from '@tanstack/react-query';
import { ActiveClientKey } from '@src/hooks/useActiveClient';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { ClientEditorButton } from './components/ClientEditorButton';
import { useClients } from '@src/hooks/useClients';
import { Stack, IconButton, Divider } from '@mui/material';
import { ClientMembersModal } from './ClientMembersModal';

const PAGE_LIMIT = 25;

interface ClientsListIF {
  store: Store;
}

const setQueryParams = (
  navigate: Function,
  query: any,
  name: string,
  value: string | number,
) => {
  let currentKeyword = query.get('keyword') || '';
  let currentPage = query.get('page') || 1;

  // Handle prop change
  if (name === 'keyword') {
    currentPage = 1; // Need reset on search
    currentKeyword = value;
  }
  if (name === 'page') {
    currentPage = value;
  }

  // Build query
  const composedQuery = `?${
    currentKeyword && currentKeyword.length > 1
      ? `&keyword=${currentKeyword}`
      : ''
  }${currentPage && `&page=${currentPage}`}`;
  navigate({
    pathname: window.location.pathname,
    search: composedQuery,
  });
};

let currentGetReqTimeout: any;

const ClientsList: FC<ClientsListIF> = observer(() => {
  const queryClient = useQueryClient();
  const query = useQueryParams();
  const { appendBreadcrumb, resetBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  const setQuery = setQueryParams.bind(null, navigate, query);
  const keyword = query.get('keyword') || '';
  const page = +query.get('page') || 1;
  const { clients, totalRecords, isLoading, refetch } = useClients({
    keyword,
    page,
    limit: PAGE_LIMIT,
  });
  const [currentClientMemberModal, setCurrentClientMemberModal] = useState<
    boolean | string
  >(false);
  const [isSwitchingClient, setIsSwitchingClient] = React.useState(false);

  return (
    <View>
      <ViewHeader
        title='Clients'
        titleIcon={<WorkOutlineIcon fontSize='large' />}
        actions={[
          {
            render: <ClientEditorButton onSave={refetch} key='new_client' />,
          },
        ]}
      />
      <ViewBody>
        <ViewBodySection title='Client List' isLoading={isSwitchingClient}>
          <Card>
            <Input
              size='middle'
              placeholder='Search...'
              defaultValue={keyword}
              allowClear={true}
              onChange={e => {
                // Debounce to prevent keyword onchange from blasting the api
                if (currentGetReqTimeout) clearTimeout(currentGetReqTimeout);
                currentGetReqTimeout = setTimeout(async () => {
                  setQuery('keyword', e.target.value);
                }, 200);
              }}
            />
            <Divider />
            <Table
              bordered
              loading={isLoading}
              pagination={false}
              dataSource={clients}
              columns={[
                {
                  width: 30,
                  dataIndex: 'logo_image',
                  key: 'avatar',
                  align: 'center' as 'center',
                  render: (text: string, client) => (
                    <Avatar srcId={client.id} alt={client.name}>
                      {/* TODO: this is a fallback until logos are fully migrated */}
                      {client.logo_image ? (
                        <img
                          src={client.logo_image}
                          alt={client.name}
                          style={{
                            width: 'inherit',
                            height: 'inherit',
                          }}
                        />
                      ) : null}
                    </Avatar>
                  ),
                },
                {
                  title: 'Name',
                  dataIndex: 'name',
                  key: 'name',
                  sorter: (a: any, b: any) => a.name.localeCompare(b.name),
                  showSorterTooltip: false,
                },
                {
                  title: 'Location',
                  key: 'location',
                  responsive: ['lg' as Breakpoint],
                  render: (text: string, client) => (
                    <span>
                      {client.addresses &&
                      client.addresses.length > 0 &&
                      client.addresses[0] &&
                      client.addresses[0].city
                        ? `${client.addresses[0].city}, `
                        : 'Not Specified'}{' '}
                      {client.addresses &&
                      client.addresses.length > 0 &&
                      client.addresses[0] &&
                      client.addresses[0].state
                        ? client.addresses[0].state
                        : ''}
                    </span>
                  ),
                  sorter: (a: any, b: any) =>
                    a.addresses[0].city.localeCompare(b.addresses[0].city),
                  showSorterTooltip: false,
                },
                {
                  width: 30,
                  title: 'SOWs',
                  key: 'sows',
                  align: 'center' as 'center',
                  render: (text: string, client) => {
                    const goToSOWs = () => {
                      const path = `/administration/sows/${client.id}`;
                      navigate(path);
                      appendBreadcrumb({
                        path,
                        label: `${client.name} SOWs`,
                      });
                    };
                    return (
                      // TODO: this should be changed to a button, but needs design input
                      <Space className='gc-table-actions text-actions'>
                        <div
                          role='button'
                          tabIndex={0}
                          className='text modal-open-click-target'
                          onClick={goToSOWs}
                          onKeyDown={e => handleKeyboardNav(e.key, goToSOWs)}
                        >
                          {client.sows && client.sows.length}
                        </div>
                      </Space>
                    );
                  },
                },
                {
                  width: 30,
                  title: 'Programs',
                  key: 'programs',
                  align: 'center' as 'center',
                  render: (text: string, client) => {
                    const goToPrograms = () => {
                      const path = `/administration/programs/${client.id}`;
                      navigate(path);
                      appendBreadcrumb({
                        path,
                        label: `${client.name} Programs`,
                      });
                    };
                    return (
                      // TODO: this should be changed to a button, but needs design input
                      <Space className='gc-table-actions text-actions'>
                        <div
                          role='button'
                          tabIndex={0}
                          onClick={goToPrograms}
                          onKeyDown={e =>
                            handleKeyboardNav(e.key, goToPrograms)
                          }
                          className='text modal-open-click-target'
                        >
                          {client.programs && client.programs.length}
                        </div>
                      </Space>
                    );
                  },
                },
                {
                  width: 30,
                  title: 'Actions',
                  key: 'actions',
                  responsive: ['lg' as Breakpoint],
                  align: 'center' as 'center',
                  render: (text: string, client) => (
                    <Stack direction='row' alignItems='center' spacing={1}>
                      <ClientEditorButton
                        key={`edit_client_${client.id}`}
                        buttonType='icon'
                        client={client}
                        onSave={refetch}
                      />

                      <div>
                        <IconButton
                          className='modal-open-click-target'
                          onClick={() => setCurrentClientMemberModal(client.id)}
                        >
                          <PeopleOutlinedIcon fontSize='small' />
                        </IconButton>
                        <ClientMembersModal
                          client={client}
                          onClose={() => setCurrentClientMemberModal(false)}
                          open={currentClientMemberModal === client.id}
                        />
                      </div>
                    </Stack>
                  ),
                },
                {
                  title: 'View',
                  key: 'view',
                  align: 'center' as 'center',
                  width: 1,
                  render: (text: string, client) => (
                    <Stack direction='row' alignItems='center' spacing={1}>
                      <IconButton
                        onClick={async () => {
                          const activeClientId = localStorage.getItem(
                            ActiveClientKey.sc_admin_active_client_id,
                          );
                          if (activeClientId !== client.id) {
                            localStorage.setItem(
                              ActiveClientKey.sc_admin_active_client_id,
                              client.id,
                            );
                            setIsSwitchingClient(true);
                            await queryClient.invalidateQueries();
                          }
                          navigate(`/my-business/${client.id}`);
                          resetBreadcrumbs([]);
                        }}
                      >
                        <ChevronRightIcon fontSize='small' />
                      </IconButton>
                    </Stack>
                  ),
                },
              ]}
              locale={{ emptyText: 'No clients found' }}
              rowKey={'id'}
            />
            <Pagination
              style={{ marginTop: '2em' }}
              pageSize={PAGE_LIMIT}
              current={+page}
              total={totalRecords}
              onChange={val => setQuery('page', val)}
            />
          </Card>
        </ViewBodySection>
      </ViewBody>
    </View>
  );
});

export default ClientsList;
