import SQMessage from './SQMessage';
import React, { FC } from 'react';
import { Space, Switch } from 'antd';
import SQText from './SQText';
import SQNumber from './SQNumber';
import SQSingleSelect from './SQSingleSelect';
import SQMultiSelect from './SQMultiSelect';
import SQTextArea from './SQTextArea';
import SurveyQuestion from '@src/models/SurveyQuestion';
import { observer } from 'mobx-react';

import './index.less';
import SQRange from './SQRange';
import SQCompetency from './SQCompetency';
import SurveyQuestionOptions from './components/SQOptions';

const EditorFormFooter: FC<{
  surveyQuestion: SurveyQuestion;
  isSurveyEditable: boolean;
}> = observer(({ surveyQuestion, isSurveyEditable }) => {
  const { type } = surveyQuestion;

  if (type === 'message') return null;

  return (
    <Space className='se-question-form-footer'>
      <Space className='se-switch'>
        <Switch
          checked={
            surveyQuestion.required ||
            surveyQuestion.group?.questions?.find(
              q => q.type !== 'textarea' && q.required,
            )
          }
          onChange={checked => {
            if (surveyQuestion.group) {
              surveyQuestion.group.questions.map(q => {
                if (
                  surveyQuestion.type === 'competency' &&
                  q.type !== 'textarea' // Never make the comments question for a competency required
                )
                  q.required = checked;
              });
            }
            surveyQuestion.required = checked;
          }}
          disabled={!isSurveyEditable}
        />
        <span className='se-switch-label'>Required</span>
      </Space>
      {['single-select', 'multi-select'].includes(type) && (
        <>
          <Space>
            <Switch
              checked={surveyQuestion.allow_other}
              onChange={checked => {
                surveyQuestion.allow_other = checked;
                if (!checked) {
                  surveyQuestion.other_answer_required = checked;
                }
              }}
              disabled={!isSurveyEditable}
            />
            <span className='se-switch-label'>Allow Other</span>
          </Space>
          {surveyQuestion.allow_other && (
            <Space>
              <Switch
                checked={surveyQuestion.other_answer_required}
                onChange={checked => {
                  surveyQuestion.other_answer_required = checked;
                }}
                disabled={!isSurveyEditable}
              />
              <span className='se-switch-label'>Other Answer Required</span>
            </Space>
          )}
        </>
      )}
      <SurveyQuestionOptions
        surveyQuestion={surveyQuestion}
        isSurveyEditable={isSurveyEditable}
      />
    </Space>
  );
});

const SurveyQuestionEditorForm: FC<{
  surveyQuestion: SurveyQuestion;
  isSurveyEditable: boolean;
}> = ({ surveyQuestion, isSurveyEditable }) => {
  let sqEditorForm;
  switch (surveyQuestion.type) {
    case 'message':
      sqEditorForm = (
        <SQMessage
          surveyQuestion={surveyQuestion}
          isSurveyEditable={isSurveyEditable}
        />
      );
      break;
    case 'text':
      if (surveyQuestion.isNumberInput) {
        sqEditorForm = (
          <SQNumber
            surveyQuestion={surveyQuestion}
            isSurveyEditable={isSurveyEditable}
          />
        );
        break;
      }
      sqEditorForm = (
        <SQText
          surveyQuestion={surveyQuestion}
          isSurveyEditable={isSurveyEditable}
        />
      );
      break;
    case 'textarea':
      sqEditorForm = (
        <SQTextArea
          surveyQuestion={surveyQuestion}
          isSurveyEditable={isSurveyEditable}
        />
      );
      break;
    case 'range':
      sqEditorForm = (
        <SQRange
          surveyQuestion={surveyQuestion}
          isSurveyEditable={isSurveyEditable}
        />
      );
      break;
    case 'single-select':
      sqEditorForm = (
        <SQSingleSelect
          surveyQuestion={surveyQuestion}
          isSurveyEditable={isSurveyEditable}
        />
      );
      break;
    case 'multi-select':
      sqEditorForm = (
        <SQMultiSelect
          surveyQuestion={surveyQuestion}
          isSurveyEditable={isSurveyEditable}
        />
      );
      break;
    case 'competency':
      sqEditorForm = (
        <SQCompetency
          surveyQuestion={surveyQuestion}
          isSurveyEditable={isSurveyEditable}
        />
      );
      break;
  }

  return (
    <div className={`se-question-form ${!sqEditorForm ? 'invalid' : ''}`}>
      {sqEditorForm || `Invalid Question Type ${surveyQuestion.type}`}
      {sqEditorForm && (
        <EditorFormFooter
          surveyQuestion={surveyQuestion}
          isSurveyEditable={isSurveyEditable}
        />
      )}
    </div>
  );
};

export default observer(SurveyQuestionEditorForm);
