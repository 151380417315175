import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Card } from '@src/components/sc-design-system';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import { Divider, Stack, Typography } from '@mui/material';

const DirectReportOverviewCard: FC<{
  department: string;
  title: string;
  alignedGoalsNum: number;
  programs: any[];
}> = ({ department, alignedGoalsNum, title, programs }) => {
  return (
    <Card title='Overview'>
      <Stack
        direction='row'
        justifyContent='space-evenly'
        spacing={1}
        divider={<Divider orientation='vertical' flexItem />}
      >
        {title && (
          <Stack direction='row' alignItems='center' spacing={1}>
            <WorkIcon sx={{ color: 'primary.main' }} fontSize='large' />
            <Typography variant='h3'>{title}</Typography>
          </Stack>
        )}

        {department?.length ? (
          <>
            <Stack direction='row' alignItems='center' spacing={1}>
              <BusinessIcon sx={{ color: 'tertiary.main' }} fontSize='large' />
              <Typography variant='h3'>
                {department.charAt(0).toUpperCase() + department.slice(1)}
              </Typography>
            </Stack>
          </>
        ) : null}
        <Stack direction='row' alignItems='center' spacing={1}>
          <TrackChangesIcon fontSize='large' sx={{ color: 'secondary.500' }} />
          <Typography variant='h3'>
            {alignedGoalsNum || 0} Aligned Goals
          </Typography>
        </Stack>
        <Stack direction='row' alignItems='center' spacing={1}>
          <LayersOutlinedIcon sx={{ color: 'warning.main' }} fontSize='large' />
          <Typography variant='h3'>
            {programs ? programs.length : 0} Enrolled Programs
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default observer(DirectReportOverviewCard);
