import querystring from 'node:querystring';
import { HTTPClient } from '@src/lib/client';
import { UserType } from '@shared/schemas';

interface GetListParamsIF {
  keyword?: string;
  type?: 'administrators' | 'coaches' | 'coachees';
  limit?: number;
  page?: number;
  client?: string;
}

export class UsersAPI {
  http: HTTPClient;
  constructor(http: HTTPClient) {
    this.http = http;
  }

  async get(id?: string, client?: string) {
    return this.http.request({
      url: `/users/${id || ''}${client ? `?client=${client}` : ''}`, // uses JWT sub if not provided
      method: 'get',
    });
  }

  async getPrograms(id?: string, role?: 'coachee' | 'coach', client?: string) {
    return this.http.request({
      url: `/users/programs/${id || ''}`, // uses JWT sub if not provided
      method: 'get',
      params: {
        client,
        role,
      },
    });
  }

  async getInterviews(id?: string, role?: 'coachee' | 'coach') {
    return this.http.request({
      url: `/users/interviews/${id || ''}${role ? `?role=${role}` : ''}`, // uses JWT sub if not provided
      method: 'get',
    });
  }

  async getUpNext(id?: string, role?: 'coachee' | 'coach') {
    return this.http.request({
      url: `/users/up-next/${id || ''}${role ? `?role=${role}` : ''}`, // uses JWT sub if not provided
      method: 'get',
    });
  }

  async create(
    user: UserType,
    coach: boolean,
    clientId: string,
    programId: string,
  ) {
    return this.http.request({
      url: `/users/create?program-id=${programId}&coach=${coach}&client-id=${clientId}`,
      method: 'post',
      data: user,
    });
  }

  async update(user: UserType) {
    return this.http.request({
      url: `/users/${user.id}`,
      method: 'patch',
      data: user,
    });
  }

  async getCounts() {
    return this.http.request({
      url: '/users/counts',
      method: 'get',
    });
  }

  async getList(queryParams: GetListParamsIF) {
    return this.http.request({
      url: `/users/list?${Object.keys(queryParams)
        .filter(k => queryParams[k])
        .map(key => key + '=' + encodeURIComponent(queryParams[key]))
        .join('&')}`,
      method: 'get',
    });
  }

  async getUserSavedAndSelectedCoaches(id: string) {
    return this.http.request({
      url: `/users/saved-and-selected-coaches/${id}`,
      method: 'get',
    });
  }

  async getUserCoachees(id: string) {
    return this.http.request({
      url: `/users/coachees/${id}`,
      method: 'get',
    });
  }

  async adminAlerts() {
    return this.http.request({
      url: '/users/alerts',
      method: 'get',
    });
  }

  async acceptTerms(user_id: string) {
    return this.http.request({
      url: '/users/acceptTerms',
      method: 'post',
      data: {
        user_id,
      },
    });
  }

  async updateLastPlatformVisit(userId: string) {
    return this.http.request({
      url: `/users/lastPlatformVisit/${userId}`,
      method: 'patch',
    });
  }

  async getUserGoals(userId: string) {
    return this.http.request({
      url: `/users/goals/${userId}`,
      method: 'get',
    });
  }

  async getUserFeedbackRequests(userId: string, clientId: string) {
    return this.http.request({
      url: `/users/${userId}/feedback-requests?client_id=${clientId}`,
      method: 'get',
    });
  }

  async getUserAidaSummaryInputs(userId: string, clientId: string) {
    return this.http.request({
      url: `/users/${userId}/summary?client_id=${clientId}`,
      method: 'get',
    });
  }

  async getUserAidaConversations(
    userId: string,
    params: {
      clientId: string;
      role?: ShareWith['role'];
    },
  ) {
    return this.http.request({
      url: `/users/${userId}/conversations?${querystring.encode(params)}`,
      method: 'get',
    });
  }

  async updateAidaConversationRating(
    userId: string,
    conversation_id: string,
    data,
  ) {
    return this.http.request({
      url: `/users/${userId}/conversation/${conversation_id}`,
      method: 'patch',
      data,
    });
  }

  async dismissAidaConversation(userId: string, conversationId: string) {
    return this.http.request({
      url: `/users/${userId}/conversation/${conversationId}/dismiss`,
      method: 'patch',
    });
  }
}
