import { computed, action, makeAutoObservable, toJS } from 'mobx';

const goalCycleDataProps = [
  'id',
  'name',
  'start',
  'end',
  'description',
  'goal_weighting_enabled',
];

export interface GoalCycleIF {
  entityType: 'client' | 'user';
  id?: string;
  name?: string;
  start?: number;
  end?: number;
  goals?: Goal[];
  performanceCycleId?: string;
}
export class GoalCycle {
  entityType: 'client' | 'user';
  id?: string;
  name: string;
  start?: number;
  end?: number;
  goals: Goal[] = [];
  description: string;
  goal_weighting_enabled = false;
  goal_submission_deadline?: number;
  goal_edit_deadline?: number;
  goal_evaluation_start?: number;
  goal_evaluation_end?: number;
  performanceCycleId?: string;

  constructor(goalCycle: GoalCycleIF) {
    makeAutoObservable(this, {
      data: computed,
      mergeData: action,
    });
    this.mergeData(goalCycle);
  }

  mergeData(goalCycle) {
    Object.keys(goalCycle).forEach(prop => {
      this[prop] = goalCycle[prop];
    });
  }

  get data() {
    return goalCycleDataProps.reduce((acc, prop) => {
      acc[prop] = toJS(this[prop]);
      return acc;
    }, {});
  }
}
