import React, { FC, useState, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import { DialogTitle, Button, Avatar } from '@src/components/sc-design-system';
import Client from '@src/models/Client';
import { useStore } from '@src/models/Store';
import ClientMembersList from '@src/components/common/ClientMembersList/ClientMembersList';
import FindAndAddMember from '@src/components/common/FindAndAddMember';
import {
  TabPanelWrapper,
  generateTabProps,
} from '@src/components/common/TabPanelWrapper';

interface ClientMembersModalProps {
  client: Client;
  onClose: () => void;
  open: boolean;
}

const ClientMembersModal: FC<ClientMembersModalProps> = ({
  client,
  onClose,
  open,
}) => {
  const store = useStore();
  const [activeTab, setActiveTab] = useState(0);
  const tabs = useMemo(() => {
    return [
      { label: 'Current Members', ...generateTabProps(0) },
      { label: 'Add Members', ...generateTabProps(1) },
    ];
  }, []);
  return (
    <Dialog maxWidth='lg' onClose={onClose} open={open}>
      <DialogTitle
        buttonOnClick={onClose}
        title={
          <Stack direction='row' alignItems='center' spacing={2}>
            <Avatar srcId={client.id} alt={client.name}>
              {/* TODO: this is a fallback until logos are fully migrated */}
              {client.logo_image ? (
                <img
                  src={client.logo_image}
                  alt={client.name}
                  style={{
                    width: 'inherit',
                    height: 'inherit',
                  }}
                />
              ) : null}
            </Avatar>
            <Typography variant='h3'>{client.name}</Typography>
          </Stack>
        }
      />
      <DialogContent>
        <Tabs
          onChange={(_, v) => {
            setActiveTab(v);
          }}
          value={activeTab}
          sx={{ marginBottom: '2em' }}
        >
          {tabs.map(tab => (
            <Tab key={tab.label} label={tab.label} />
          ))}
        </Tabs>
        <TabPanelWrapper value={activeTab} index={0}>
          <ClientMembersList
            client={client}
            currentUserId={store.user.id}
            isFetchingClient={false}
            enableRowSelectionColumn={false}
            hrisEnabled={false}
          />
        </TabPanelWrapper>
        <TabPanelWrapper value={activeTab} index={1}>
          <FindAndAddMember client={client} isAdmin={store.user.is_admin} />
        </TabPanelWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} text='OK' formatText={false} />
      </DialogActions>
    </Dialog>
  );
};

export { ClientMembersModal };
