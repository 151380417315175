import React from 'react';
import { Button } from '@src/components/sc-design-system';
import { theme } from '@src/theme';
import Add from '@mui/icons-material/Add';
import Check from '@mui/icons-material/Check';
import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined';
import Close from '@mui/icons-material/Close';
import Schedule from '@mui/icons-material/Schedule';
import { ButtonProps } from '@src/components/sc-design-system/Button';

export const BookSession: React.FunctionComponent<{
  onClick?: ButtonProps['onClick'];
}> = ({ onClick }) => {
  return (
    <Button
      sx={{
        width: '100%',
        color: theme.palette.primary[500],
        borderColor: theme.palette.primary[500],
        backgroundColor: theme.palette.primary[100],
        '&:focus': {
          color: theme.palette.primary[500],
          borderColor: theme.palette.primary[500],
          backgroundColor: theme.palette.primary[100],
        },
        '&:hover': {
          color: theme.palette.white.main,
          borderColor: theme.palette.primary[500],
          backgroundColor: theme.palette.primary[400],
        },
        '&:active': {
          color: theme.palette.white.main,
          borderColor: theme.palette.primary[500],
          backgroundColor: theme.palette.primary[500],
        },
        whiteSpace: 'nowrap',
      }}
      shape='rounded-square'
      text='Book Session'
      startIcon={<Add />}
      variant='outlined'
      onClick={onClick}
    />
  );
};

export const Placeholder: React.FunctionComponent<{ text: string }> = ({
  text,
}) => {
  return (
    <Button
      sx={{
        width: '100%',
        '&.Mui-disabled': {
          color: theme.palette.grey[700],
          border: `1px dashed ${theme.palette.grey[500]}`,
          backgroundColor: theme.palette.grey[200],
        },
      }}
      shape='rounded-square'
      text={text}
      variant='outlined'
      disabled={true}
    />
  );
};

export const Scheduled: React.FunctionComponent<{
  onClick?: ButtonProps['onClick'];
}> = ({ onClick }) => {
  return (
    <Button
      sx={{
        width: '100%',
        color: theme.palette.white.main,
        backgroundColor: theme.palette.primary[500],
        '&:focus': {
          color: theme.palette.white.main,
          backgroundColor: theme.palette.primary[500],
        },
        '&:hover': {
          color: theme.palette.white.main,
          backgroundColor: theme.palette.primary[600],
        },
        '&:active': {
          color: theme.palette.white.main,
          backgroundColor: theme.palette.primary[700],
        },
        cursor: !onClick ? 'default' : 'pointer',
      }}
      shape='rounded-square'
      text='Scheduled'
      startIcon={<CalendarTodayOutlined />}
      variant='contained'
      onClick={onClick}
    />
  );
};

export const Completed: React.FunctionComponent<{
  onClick?: ButtonProps['onClick'];
}> = ({ onClick }) => {
  return (
    <Button
      sx={{
        width: '100%',
        color: theme.palette.grey[900],
        backgroundColor: theme.palette.tertiary[500],
        '&:focus': {
          color: theme.palette.grey[900],
          backgroundColor: theme.palette.tertiary[500],
        },
        '&:hover': {
          color: theme.palette.grey[900],
          backgroundColor: theme.palette.tertiary[600],
        },
        '&:active': {
          color: theme.palette.white.main,
          backgroundColor: theme.palette.tertiary[700],
        },
        cursor: !onClick ? 'default' : 'pointer',
      }}
      shape='rounded-square'
      text='Completed'
      startIcon={<Check />}
      variant='contained'
      onClick={onClick}
    />
  );
};

export const Started: React.FunctionComponent<{
  onClick?: ButtonProps['onClick'];
}> = ({ onClick }) => {
  return (
    <Button
      sx={{
        width: '100%',
        color: theme.palette.grey[900],
        backgroundColor: theme.palette.tertiary[500],
        '&:focus': {
          color: theme.palette.grey[900],
          backgroundColor: theme.palette.tertiary[500],
        },
        '&:hover': {
          color: theme.palette.grey[900],
          backgroundColor: theme.palette.tertiary[600],
        },
        '&:active': {
          color: theme.palette.white.main,
          backgroundColor: theme.palette.tertiary[700],
        },
        cursor: !onClick ? 'default' : 'pointer',
      }}
      shape='rounded-square'
      text='Started'
      startIcon={<Schedule />}
      variant='contained'
      onClick={onClick}
    />
  );
};

export const Missed: React.FunctionComponent<{
  onClick?: ButtonProps['onClick'];
}> = ({ onClick }) => {
  return (
    <Button
      sx={{
        width: '100%',
        color: theme.palette.white.main,
        backgroundColor: theme.palette.error[500],
        '&:focus': {
          color: theme.palette.white.main,
          backgroundColor: theme.palette.error[500],
        },
        '&:hover': {
          color: theme.palette.white.main,
          backgroundColor: theme.palette.error[600],
        },
        '&:active': {
          color: theme.palette.white.main,
          backgroundColor: theme.palette.error[700],
        },
        cursor: !onClick ? 'default' : 'pointer',
      }}
      shape='rounded-square'
      text='Missed'
      startIcon={<Close />}
      variant='contained'
      onClick={onClick}
    />
  );
};
