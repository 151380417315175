import { computed, action, makeObservable, observable, toJS } from 'mobx';
import { session as SessionSchema } from '@shared/schemas';
import Base from './_Base';
import { message } from 'antd';
import { api } from '@src/lib/client';

export const allowedStatuses = [
  'requested',
  'scheduled',
  'started',
  'completed',
  'canceled',
];

const sessionDataProps = [
  'id',
  'short_id',
  'type',
  'members',
  'program_id',
  'sow_id',
  'client_id',
  'scheduled_date',
  'scheduled_end_date',
  'scheduled_link',
  'session_activity',
  'calendly_event',
  'current_status',
  'status_overridden',
];

export class SessionStatusOverride {
  original_status: string = 'completed';
  notes?: string;
  timestamp?: number = Date.now();
  user_id?: string;
  constructor(statusOverride) {
    makeObservable(this, {
      original_status: observable,
      notes: observable,
      timestamp: observable,
      user_id: observable,
      data: computed,
    });
    this.original_status = statusOverride?.original_status;
    this.notes = statusOverride?.notes;
    this.timestamp = statusOverride?.timestamp;
    this.user_id = statusOverride?.user_id;
  }

  get data() {
    return {
      original_status: toJS(this.original_status),
      notes: toJS(this.notes),
      timestamp: toJS(this.timestamp),
      user_id: toJS(this.user_id),
    };
  }
}

export class CalendlyEvent {
  uuid: string;
  status: 'active' | 'canceled';
  start_time: number;
  end_time: number;
  cancel_url: string;
  reschedule_url: string;

  constructor(event = {}) {
    makeObservable(this, {
      uuid: observable,
      status: observable,
      start_time: observable,
      end_time: observable,
      cancel_url: observable,
      reschedule_url: observable,
    });

    Object.keys(event).forEach(prop => {
      this[prop] = event[prop];
    });
  }

  get data() {
    return JSON.parse(
      JSON.stringify({
        uuid: this.uuid,
        status: this.status,
        start_time: this.start_time,
        end_time: this.end_time,
        cancel_url: this.cancel_url,
        reschedule_url: this.reschedule_url,
      }),
    );
  }
}

export class SessionMember extends Base {
  id: string = undefined;
  role: string = 'member';
  joinedAt: number = Date.now();
  first_name: string;
  last_name: string;
  profile_image: any;
  search_topics: string[];
  sessions_allocated?: number = 0;
  program_info?: any;

  constructor(member) {
    super();
    makeObservable(this, {
      id: observable,
      role: observable,
      joinedAt: observable,
      // Computeds
      data: computed,
    });
    Object.keys(member).forEach(prop => {
      this[prop] = member[prop];
    });
  }

  get data() {
    return JSON.parse(
      JSON.stringify({
        id: toJS(this.id),
        role: toJS(this.role),
        joinedAt: toJS(this.joinedAt),
      }),
    );
  }
}

export default class Session extends Base {
  id: string;
  short_id?: string;
  type: string = 'interview';
  members: SessionMember[] = [];
  program_id: string;
  sow_id: string;
  client_id: string;
  session_activity: object[] = [];
  calendly_event: CalendlyEvent;
  current_status: string;
  current_status_data: string;
  member_info: any;
  client_info: any;
  program_info: any;
  status_overridden?: SessionStatusOverride;
  zoom_link?: string;
  notes?: any[] = [];
  ratings?: any[] = [];
  feedback?: any[] = [];
  missed_reason?: any[] = [];
  start_time?: number;
  end_time?: number;
  followUpObject?: any;

  constructor(session: SessionSchema.SessionType = {}) {
    super();
    makeObservable(this, {
      id: observable,
      short_id: observable,
      type: observable,
      members: observable,
      program_id: observable,
      sow_id: observable,
      client_id: observable,
      calendly_event: observable,
      current_status: observable,
      current_status_data: observable,
      session_activity: observable,
      member_info: observable,
      client_info: observable,
      status_overridden: observable,
      // computeds
      data: computed,
      // actions
      save: action,
    });
    this.mergeData(session);
  }

  mergeData = (session: SessionSchema.SessionType) => {
    Object.keys(session).forEach(prop => {
      if (prop === 'members') {
        this[prop] = session[prop].map(m => new SessionMember(m));
      } else if (prop === 'calendly_event') {
        this[prop] = new CalendlyEvent(session[prop]);
      } else if (prop === 'status_overridden') {
        this[prop] = new SessionStatusOverride(this[prop]);
      } else {
        this[prop] = session[prop];
      }
    });
  };

  get data() {
    return sessionDataProps.reduce((acc, prop) => {
      if (prop === 'members') {
        acc[prop] = this[prop].map(m => m.data);
      } else if (['calendly_event', 'status_overridden'].includes(prop)) {
        acc[prop] = this[prop]?.data;
      } else {
        acc[prop] = toJS(this[prop]);
      }
      return acc;
    }, {});
  }

  save = async (silenceUIError?: boolean, coachee?: string) => {
    try {
      const res = await api.sessions.saveSession(this.data, coachee);
      this.mergeData(res.data); // Update local
      if (coachee) {
        message.success('Session created');
      }
    } catch (e) {
      !silenceUIError && message.error('Could not save session record');
      throw e;
    }
  };

  updateStatus = async (silenceUIError?: boolean) => {
    try {
      const res = await api.sessions.saveSession({
        id: this.id,
        current_status: this.current_status,
        status_overridden: this.status_overridden,
      });
      this.mergeData(res.data); // Update local
    } catch (e) {
      !silenceUIError && message.error('Could not update session status');
      throw e;
    }
  };

  get isCancelled(): boolean {
    return (
      this.current_status === 'canceled' ||
      this.current_status_data === 'canceled'
    );
  }

  get isCompleted(): boolean {
    if (this.current_status === 'completed') {
      return true;
    }
    if (
      this.current_status === 'missed' &&
      this.current_status_data === 'completed'
    ) {
      return true;
    }
    return false;
  }

  get isScheduled(): boolean {
    return this.current_status === 'scheduled';
  }

  get isMissed(): boolean {
    return this.isScheduled && !!this.status_overridden;
  }

  get isUsed(): boolean {
    if (
      this.current_status === 'missed' &&
      this.current_status_data !== 'canceled'
    ) {
      return true;
    } else if (
      this.current_status !== 'missed' &&
      this.current_status !== 'canceled'
    ) {
      return true;
    }
    return false;
  }
}
