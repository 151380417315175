import React from 'react';
import dayjs from 'dayjs';
import { Typography, Link, Stack, IconButton } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { observer } from 'mobx-react';
import { ConfirmDialog, TableMUI } from '@src/components/sc-design-system';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

interface Invitee {
  invitedAt: string;
  invitee_email: string;
  invitee_first_name: string;
  invitee_last_name: string;
  joinedAt: string;
  revokedAt: string;
}

function Revoked(props: {
  revokedAt: string;
  invitee_email: string;
  undoRevokeInvite: (s: string) => void;
}) {
  const [openUndoRevokeDialog, setOpenUndoRevokeDialog] = React.useState(false);
  const { revokedAt, invitee_email, undoRevokeInvite } = props;
  return (
    <Stack direction='row' alignContent='center'>
      {dayjs(revokedAt).format('M/DD/YY h:mm A')}
      <ConfirmDialog
        key={`undo-revoke-${invitee_email}`}
        open={openUndoRevokeDialog}
        setOpenModal={setOpenUndoRevokeDialog}
        buttonProps={{
          text: 'OK',
          onClick: async () => {
            await undoRevokeInvite(invitee_email);
          },
        }}
        title='Undo Revoke Invitation'
        body='By undoing the revoke action, the member will be sent a new email inviting them to the program.'
      />
      <Link
        component='button'
        variant='a2'
        onClick={() => setOpenUndoRevokeDialog(true)}
        sx={{ marginLeft: '.25em' }}
      >
        Undo
      </Link>
    </Stack>
  );
}

function Revoke(props: {
  invitee_email: string;
  revokeInvite: (s: string) => void;
}) {
  const [openRevokeDialog, setOpenRevokeDialog] = React.useState(false);
  const { invitee_email, revokeInvite } = props;
  return (
    <>
      <ConfirmDialog
        open={openRevokeDialog}
        setOpenModal={setOpenRevokeDialog}
        buttonProps={{
          text: 'OK',
          onClick: async () => await revokeInvite(invitee_email),
        }}
        title='Revoke Invitation'
        body={`Are you sure you want to revoke the invite to ${invitee_email}?`}
      />
      <IconButton
        onClick={() => setOpenRevokeDialog(true)}
        size='small'
        color='error'
      >
        <CancelOutlinedIcon fontSize='inherit' />
      </IconButton>
    </>
  );
}

const CurrentInvitations = ({
  isLoading,
  invitees,
  revokeInvite,
  undoRevokeInvite,
}: {
  invitees: Invitee[];
  isLoading: boolean;
  revokeInvite: (e: string) => void;
  undoRevokeInvite: (e: string) => void;
}) => {
  const columnHelper = createColumnHelper<Invitee>();
  const columns = [
    columnHelper.accessor('invitee_first_name', {
      cell: info => (
        <span>
          {info.row.original.invitee_first_name}{' '}
          {info.row.original.invitee_last_name}
        </span>
      ),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor('invitee_email', {
      header: () => <span>Email</span>,
    }),
    columnHelper.accessor('invitedAt', {
      cell: info => (
        <span>{dayjs(info.getValue()).format('M/DD/YY h:mm A')}</span>
      ),
      header: () => <span>Invited</span>,
    }),
    columnHelper.accessor('revokedAt', {
      cell: info => {
        const { revokedAt, invitee_email } = info.row.original;
        if (revokedAt) {
          return (
            <Revoked
              revokedAt={revokedAt}
              invitee_email={invitee_email}
              undoRevokeInvite={undoRevokeInvite}
            />
          );
        }
      },
      header: () => <span>Revoked</span>,
    }),
    columnHelper.accessor('joinedAt', {
      cell: info => (
        <span>
          {info.getValue() && dayjs(info.getValue()).format('M/DD/YY h:mm A')}
        </span>
      ),
      header: () => <span>Joined</span>,
    }),
    columnHelper.accessor('invitee_last_name', {
      cell: info => {
        const { revokedAt, joinedAt, invitee_email } = info.row.original;
        return (
          !revokedAt &&
          !joinedAt && (
            <Revoke invitee_email={invitee_email} revokeInvite={revokeInvite} />
          )
        );
      },
      header: () => <span>Revoke</span>,
    }),
  ];

  return (
    <>
      <Typography variant='h4' component='h3' sx={{ marginBottom: '1em' }}>
        Current Invitations
      </Typography>
      <TableMUI
        data={invitees}
        columns={columns}
        dataIsLoading={isLoading}
        emptyState={`Nobody's been invited yet.`}
      />
    </>
  );
};

export default observer(CurrentInvitations);
