// Heavily influenced by: https://stackoverflow.com/a/76965111

import React from 'react';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { InputAdornment } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

interface EditableTypographyProps {
  key: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  multiline?: boolean;
}

const InputBaseWithChildren = ({
  children,
  ...props
}: InputBaseProps & { children?: React.ReactNode; isUpdating?: boolean }) => {
  return (
    <InputBase
      {...props}
      sx={{
        cursor: 'pointer',
        padding: '8px 15px',
        '&.Mui-focused': {
          border: '1px solid',
          borderColor: 'primary.main',
          borderRadius: '5px',
        },
      }}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          props.onChange && props.onChange(event);
          event.preventDefault();
          event.target.blur();
        }
      }}
      value={children}
      endAdornment={
        <InputAdornment position='end' disablePointerEvents>
          <EditIcon sx={{ color: 'primary.main', fontSize: '1em' }} />
        </InputAdornment>
      }
    />
  );
};

const EditableTypography = ({
  onChange: propsOnChange,
  ...props
}: EditableTypographyProps & TypographyProps) => {
  const [internalValue, setInternalValue] = React.useState(props.children);

  return (
    <Typography
      {...props}
      children={internalValue}
      component={InputBaseWithChildren}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        propsOnChange && propsOnChange(e.target.value);
        setInternalValue(e.target.value);
      }}
    />
  );
};

export { EditableTypography };
