import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { Button, ButtonProps } from '@src/components/sc-design-system/Button';

interface NavActionProps {
  path: string;
  buttonText: string;
  variant?: ButtonProps['variant'];
}

const NavAction: FC<NavActionProps> = ({
  path,
  buttonText,
  variant = 'contained',
}) => {
  const navigate = useNavigate();

  return (
    <Button
      text={buttonText}
      formatText={false}
      onClick={() => navigate(path)}
      size='small'
      variant={variant}
      sx={{ width: { md: '195px', xs: 'fit-content' } }}
    />
  );
};

export { NavAction };
