import React, { FC } from 'react';

import './Stamp.less';

const stamps = {
  notification: {
    id: 'triangle-exclamation',
  },
  group: {
    id: 'user-group',
  },
  userSearch: {
    id: 'user-magnifying-glass',
  },
  clipboard: {
    id: 'clipboard',
  },
  calendar: {
    id: 'calendar',
  },
  edit: {
    id: 'pen-to-square',
  },
  plus: {
    id: 'circle-plus',
  },
  goal: {
    id: 'bullseye',
  },
};

export interface IStamp {
  name: string;
  color?: string;
}

const Stamp: FC<IStamp> = ({ name, color }) => {
  return (
    <i
      className={`sc-stamp sc-stamp-color-${color} fa-duotone fa-${stamps[name]?.id}`}
    ></i>
  );
};

export { Stamp };
