import React, { FC, useState, useMemo, useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Checkbox, Form, Space } from 'antd';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';
import {
  Stack,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
} from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Program from '@src/models/Program';
import {
  Button,
  InputField,
  useToast,
  DatePicker,
  Select,
  ConfirmDialog,
  DialogTitle,
} from '@src/components/sc-design-system';
import './GoalManagerModal.less';
import WYSIWYG from '../WYSIWYG';
import { GoalAlignment, CycleSelect } from './steps';
import { api } from '@src/lib/client';

const PERSONAL_GOAL_HEADER_TEXT =
  'Enter a new goal related to your role, your professional development, or your own personal growth.';
const ALIGNED_GOAL_HEADER_TEXT =
  'Goals created within this cycle will be visible to members in your organization with administrative access as well as your managers and leaders.';
const CLIENT_GOAL_HEADER_TEXT =
  'Goals created here will be visible to your members, who can use them to align their own goals and share with their coaches.';

const getHeaderText = (
  cycle: GoalCycle,
  entityType: string,
  isClientCycle?: boolean,
) => {
  if (!isClientCycle) return PERSONAL_GOAL_HEADER_TEXT;
  return `Add a goal to ${cycle.name}. ${
    entityType === 'user' ? ALIGNED_GOAL_HEADER_TEXT : CLIENT_GOAL_HEADER_TEXT
  }`;
};

const isActive = (program: Program) => {
  return (
    program?.is_active &&
    dayjs().isBetween(program?.start_date, program?.end_date)
  );
};

export enum GoalManagerStep {
  alignment = 'alignment',
  cycleSelect = 'cycleSelect',
  form = 'form',
}

interface GoalManagerModalProps {
  userId?: string;
  clientId?: string;
  performanceCycleId?: string;
  open: false | 'edit' | 'add';
  onClose(): void;
  selectedGoalCycle: GoalCycle & { performanceCycleId?: string };
  selectedGoal?: Goal | null;
  goalCycles?: GoalCycle[];
  programs?: Program[];
  onSave: Function;
  entityType: 'user' | 'client';
  defaultActiveStep?: GoalManagerStep;
  onChangeCycle?: Function;
  alignedGoalId?: string | null;
}

const GoalManagerModal: FC<GoalManagerModalProps> = observer(
  ({
    userId,
    clientId,
    performanceCycleId,
    entityType,
    open,
    selectedGoalCycle,
    selectedGoal,
    goalCycles,
    programs,
    onClose,
    onSave,
    onChangeCycle,
    defaultActiveStep = GoalManagerStep.form,
    alignedGoalId,
  }) => {
    const queryClient = useQueryClient();
    const toast = useToast();
    const [activeStep, setActiveStep] =
      useState<GoalManagerStep>(defaultActiveStep);
    const [stagedCycle, setStagedCycle] = useState(selectedGoalCycle);
    const [leaveFlowModalOpen, setLeaveFlowModalOpen] = useState(false);
    const [formTouched, setFormTouched] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const isClientCycle = Boolean(
      performanceCycleId || selectedGoalCycle.performanceCycleId,
    );
    programs?.sort((a, b) => b.start_date - a.start_date); // Sort by start date descending
    const initialValues = {
      id: selectedGoal?.id,
      title: selectedGoal?.title ?? '',
      type: selectedGoal?.type ?? '',
      description: selectedGoal?.description ?? '',
      target_date: selectedGoal?.target_date,
      program_ids:
        selectedGoal?.program_ids ||
        programs?.filter(p => isActive(p))?.map(p => p.id),
      goal_cycle_id: selectedGoalCycle?.id,
      user_ids:
        selectedGoal?.user_ids || (entityType === 'user' ? [userId] : []),
      client_id:
        selectedGoal?.client_id || entityType === 'client' || isClientCycle
          ? clientId
          : undefined,
      aligned_goal_id: selectedGoal?.aligned_goal_id || alignedGoalId,
    };
    const [formValues, setFormValues] = useState<{
      id?: string;
      title: string;
      type?: string;
      description?: string;
      target_date?: number;
      program_ids?: string[];
      goal_cycle_id?: string;
      user_ids?: string[];
      client_id?: string;
      aligned_goal_id?: string;
    }>(initialValues as GoalBase);

    const { mutateAsync: saveGoal, isPending } = useMutation({
      mutationFn: async (goal: Partial<Goal>) => {
        return api.goals_competencies.saveGoal(
          goal,
          selectedGoalCycle.performanceCycleId || performanceCycleId,
        );
      },
      onSuccess: async () => {
        toast.success('Goal saved');
        await onSave();
        queryClient.invalidateQueries({
          queryKey: ['user', userId, 'summary-inputs', clientId],
        });
        onClose();
      },
      onError() {
        toast.error('Could not save goal');
      },
    });

    const setAlignedGoalId = id => {
      setFormValues({
        ...formValues,
        aligned_goal_id: id,
        client_id: clientId,
      });
    };

    const setGoalCycleId = cycle => {
      setFormValues({
        ...formValues,
        goal_cycle_id: cycle.id,
        client_id: cycle.performanceCycleId ? clientId : null,
        aligned_goal_id: cycle.performanceCycleId
          ? formValues.aligned_goal_id
          : null,
      });
      setFormTouched(true);
      validateForm();
    };

    const validateForm = (): void => {
      setIsFormValid(
        Boolean(
          formValues?.title &&
            formValues?.type &&
            formValues?.description &&
            // the WYSIWYG editor adds a <br> tag after deleting a description,
            // so it's not actually empty
            formValues?.description !== '<br>' &&
            formValues?.target_date,
        ),
      );
    };

    const onFieldChange = (name: string, val: any): void => {
      const current = formValues;
      current[name] = val;
      setFormValues(Object.assign({}, current));
      setFormTouched(true);
      validateForm();
    };

    const handleSubmit = async () => {
      await saveGoal(formValues as Goal);
    };

    const handleClose = useCallback(() => {
      if (formTouched) {
        setLeaveFlowModalOpen(true);
      } else {
        onClose();
      }
    }, [formTouched, onClose]);

    const goalTypeOptions = useMemo(() => {
      const options = [
        {
          value: 'professional_development',
          label: 'Professional Development',
        },
        { value: 'business', label: 'Business' },
      ];
      if (!isClientCycle) {
        options.unshift({
          value: 'personal',
          label: 'Personal',
        });
      }
      return [{ options }];
    }, [isClientCycle]);

    const footerButtons = useMemo(() => {
      let buttons = [];
      if (entityType !== 'client') {
        if (activeStep === GoalManagerStep.alignment) {
          buttons.push(
            <Button
              text='Next'
              onClick={() => setActiveStep(GoalManagerStep.form)}
            />,
          );
        }

        if (activeStep === GoalManagerStep.cycleSelect) {
          buttons = [
            <Button
              key='back'
              text='Back'
              onClick={() => {
                setStagedCycle(selectedGoalCycle);
                setActiveStep(GoalManagerStep.alignment);
              }}
              variant='outlined'
            />,
            <Button
              key='save'
              text='Save'
              disabled={selectedGoalCycle.id === stagedCycle.id}
              onClick={() => {
                onChangeCycle(stagedCycle);
                setActiveStep(GoalManagerStep.alignment);
              }}
            />,
          ];
        }
      }

      if (activeStep === GoalManagerStep.form) {
        buttons = [
          <Button
            key={entityType === 'user' ? 'back' : 'cancel'}
            text={entityType === 'user' ? 'Back' : 'Cancel'}
            onClick={() => {
              if (entityType === 'user') {
                setActiveStep(GoalManagerStep.alignment);
              } else {
                handleClose();
              }
            }}
            variant='outlined'
          />,
          <Button
            key='add-goal'
            text={`${formValues.id ? 'Save' : 'Add'} Goal`}
            form='main'
            type='submit'
            disabled={!isFormValid || isPending}
            loading={isPending}
          />,
        ];
      }

      return buttons;
    }, [
      activeStep,
      isFormValid,
      isPending,
      onChangeCycle,
      stagedCycle,
      selectedGoalCycle,
      entityType,
      handleClose,
      formValues.id,
    ]);

    return (
      <Dialog
        open={!!open}
        onClose={handleClose}
        className='goal-manager-modal'
      >
        <DialogTitle
          title={
            <Space direction='vertical'>
              <Typography variant='h3'>
                {activeStep === GoalManagerStep.cycleSelect
                  ? 'Where does your goal live?'
                  : `${selectedGoal ? 'Edit' : 'Create'} Goal`}
              </Typography>
              {activeStep !== GoalManagerStep.cycleSelect && (
                <Typography variant='body1'>
                  {getHeaderText(selectedGoalCycle, entityType, isClientCycle)}
                </Typography>
              )}
            </Space>
          }
          buttonOnClick={handleClose}
        />
        <DialogContent>
          {entityType === 'user' && (
            <>
              {activeStep === GoalManagerStep.alignment && (
                <GoalAlignment
                  selectedGoalCycle={selectedGoalCycle}
                  hasMultipleCycles={goalCycles.length > 1}
                  onClickChange={() =>
                    setActiveStep(GoalManagerStep.cycleSelect)
                  }
                  onSelectGoal={setAlignedGoalId}
                  alignedGoalId={formValues.aligned_goal_id}
                />
              )}
              {activeStep === GoalManagerStep.cycleSelect && (
                <CycleSelect
                  goalCycles={goalCycles}
                  stagedCycle={stagedCycle}
                  onSelectCycle={cycle => {
                    setStagedCycle(cycle);
                    setGoalCycleId(cycle);
                  }}
                />
              )}
            </>
          )}
          {activeStep === GoalManagerStep.form && (
            <Form
              id='main'
              layout='vertical'
              style={{ marginBottom: 0 }}
              onFinish={handleSubmit}
              initialValues={initialValues}
            >
              <Typography variant='h4'>Add Details</Typography>
              <InputField
                label='Goal Name'
                rules={[{ required: true }]}
                placeholder='Enter name here'
                onChange={val => onFieldChange('title', val)}
                value={formValues?.title}
              />
              <Stack direction='row' spacing={2} padding={1}>
                <Select
                  sx={{ legend: { display: 'none' } }} // @TODO remove this once antd is gone
                  fullWidth
                  required={true}
                  label='Goal Type'
                  onChange={event => onFieldChange('type', event.target.value)}
                  value={formValues?.type}
                  defaultValue={initialValues?.type}
                  options={goalTypeOptions}
                />
                <DatePicker
                  sx={{ legend: { display: 'none' } }} // @TODO remove this once antd is gone
                  label='Target Date'
                  required
                  fullWidth
                  onChange={val => onFieldChange('target_date', +val || null)}
                  value={
                    formValues?.target_date
                      ? dayjs(formValues?.target_date)
                      : null
                  }
                  name='target_date'
                  minDate={dayjs(selectedGoalCycle.start)}
                  maxDate={
                    selectedGoalCycle.name !== 'Personal Goals'
                      ? dayjs(selectedGoalCycle.end)
                      : null
                  }
                />
              </Stack>
              <Form.Item
                label='Description'
                required={true}
                rules={[{ required: true }]}
              >
                <WYSIWYG
                  value={formValues?.description}
                  onChange={val => {
                    onFieldChange('description', val);
                  }}
                />
              </Form.Item>
              {entityType === 'user' && (
                <>
                  <Divider />
                  <div className='program-wrapper'>
                    <Typography variant='h4' style={{ marginBottom: 0 }}>
                      Share Your Goal
                    </Typography>
                    <Typography variant='body2' sx={{ margin: '1em 0' }}>
                      This shared goal will be visible to the coaches or mentors
                      you interview and/or select in the following programs:
                    </Typography>
                    {Boolean(programs?.length) && (
                      <Checkbox.Group
                        defaultValue={formValues?.program_ids}
                        options={programs.map(p => {
                          const coachMentorName = p?.coach?.first_name;
                          return {
                            label: coachMentorName
                              ? `${p.name} (Shared with: ${coachMentorName})`
                              : p.name,
                            value: p.id,
                            disabled: !isActive(p),
                          };
                        })}
                        onChange={checkedValues => {
                          onFieldChange('program_ids', checkedValues);
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </Form>
          )}
        </DialogContent>
        <DialogActions>
          {entityType === 'user' && (
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              marginRight='auto'
            >
              <Typography variant='subtitle2'>
                Who can see this goal?
              </Typography>
              <Tooltip title="Goals that you enter within your organization's goal cycles will be visible to members of your organization with administrative access as well as your managers and leaders.">
                <InfoOutlined
                  sx={{ width: '.7em', height: '.7em' }}
                  color='action'
                />
              </Tooltip>
            </Stack>
          )}
          {footerButtons}
        </DialogActions>
        {leaveFlowModalOpen && (
          <ConfirmDialog
            open={leaveFlowModalOpen}
            setOpenModal={setLeaveFlowModalOpen}
            title='Leave this flow?'
            body='Any changes will not be saved.'
            cancelText='Leave'
            onCancel={onClose}
            buttonProps={{
              text: 'Continue Editing',
            }}
          />
        )}
      </Dialog>
    );
  },
);

export { GoalManagerModal };
