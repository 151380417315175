import React from 'react';
import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import AppBar from '@mui/material/AppBar';
import { Icon, Breadcrumbs, Avatar } from '@src/components/sc-design-system';
import { useStore } from '@src/models/Store';
import { UserProfileDrawer } from '@src/components/common/UserProfileDrawer';
import { useActiveClient, ActiveClientKey } from '@src/hooks/useActiveClient';
import { useLocation } from 'react-router';

const AccountMenu = ({
  user,
  open,
  handleClose,
  anchorEl,
  id,
  setIsProfileOpen,
}) => {
  return (
    <StyledMenu
      open={open}
      onClose={handleClose}
      aria-describedby={id}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className='sh-account-menu'>
        <Box
          sx={{ display: 'flex', alignItems: 'center', paddingBottom: 2 }}
          className='sh-account-menu-header'
        >
          <span>
            <Avatar
              srcId={user.id}
              size='xxxl'
              alt={`${user.first_name} ${user.last_name}`}
            />
          </span>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='body1'>
              {user.first_name} {user.last_name}
            </Typography>
            <Typography variant='body2'>{user.email}</Typography>
          </Box>
        </Box>
        <Divider style={{ marginBottom: 14 }} />
        <MenuItem
          onClick={() => {
            setIsProfileOpen(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <Icon name='accountCircleOutlined' />
          </ListItemIcon>{' '}
          Profile Settings
        </MenuItem>
        <MenuItem onClick={() => user.signOut()}>
          <ListItemIcon>
            <Icon name='logout' />
          </ListItemIcon>{' '}
          Sign Out
        </MenuItem>
      </div>
    </StyledMenu>
  );
};

const ShellHeader = ({ isFullWidth }) => {
  const store = useStore();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [isProfileOpen, setIsProfileOpen] = React.useState<boolean>(false);
  const { pathname } = useLocation();
  const { client, isLoading, isFetching } = useActiveClient({
    queryKey: store.user?.is_admin
      ? ActiveClientKey.sc_admin_active_client_id
      : ActiveClientKey.profile_active_client_id,
  });
  const showClientBranding =
    client &&
    !(isLoading || isFetching) &&
    pathname !== '/performance-and-growth' &&
    pathname !== '/my-team';

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'account-menu' : undefined;
  return (
    <StyledAppBar
      aria-label='Header Nav'
      sx={{
        width: {
          lg: `calc(100% - ${isFullWidth ? 60 : 240}px)`,
          md: `calc(100% - ${isFullWidth ? 60 : 240}px)`,
          xs: 'calc(100% - 60px)',
        },
        left: {
          lg: isFullWidth ? 60 : 240,
          md: isFullWidth ? 60 : 240,
          xs: 60,
        },
        paddingTop: {
          lg: '8px',
          md: '8px',
          xs: '6px',
        },
      }}
    >
      <div className='shell-header-inner-container'>
        <Breadcrumbs />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {showClientBranding && (
            <>
              <Avatar srcId={client?.id} alt={client?.name}>
                {/* TODO: this is a fallback until logos are fully migrated */}
                {client.logo_image ? (
                  <img
                    src={client.logo_image}
                    alt={client.name}
                    style={{
                      width: 'inherit',
                      height: 'inherit',
                    }}
                  />
                ) : null}
              </Avatar>
              <Typography>{client?.name ?? ''}</Typography>
              <Divider orientation='vertical' flexItem sx={{ margin: '8px' }} />
            </>
          )}
          <IconButton
            onClick={handleClick}
            size='small'
            aria-controls={id}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            disableRipple
            style={{ border: 'none', backgroundColor: 'white' }}
          >
            <Avatar
              srcId={store.user.id}
              variant='interactive'
              size='xl'
              alt={`${store.user.first_name} ${store.user.last_name}`}
            />
          </IconButton>
        </Box>
        <AccountMenu
          user={store.user}
          id={id}
          open={open}
          handleClose={handleClose}
          anchorEl={anchorEl}
          setIsProfileOpen={setIsProfileOpen}
        />
      </div>
      <UserProfileDrawer
        open={isProfileOpen}
        onClose={() => setIsProfileOpen(false)}
      />
    </StyledAppBar>
  );
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  '&.MuiAppBar-root': {
    height: '4em',
    zIndex: 3,
    background: 'white',
    borderBottom: `.5px solid ${theme.palette.grey[300]}`,
    transition: '150ms',
    transitionTimingFunction: 'ease',
    transitionProperty: 'transform, width, left',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'black',
    '.shell-header-inner-container': {
      width: '100%',
      maxWidth: 1400,
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      padding: '0 2rem 0 3rem',
      justifyContent: 'space-between',
    },
  },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  '.MuiMenu-paper': {
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: 'none',
    marginLeft: '-8px',
    '.sh-account-menu': {
      width: 400,
      height: 250,
      padding: '1.5em',
      '.sh-account-menu-header': {
        '.gc-avatar': {
          height: 80,
          width: 80,
          marginRight: 0,
        },
        gap: '1.25em',
        display: 'flex',
        width: '100%',
        paddingBottom: 0,
      },
    },
  },
}));

export { ShellHeader };
