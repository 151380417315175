import React, { FC, useState, useMemo, useEffect } from 'react';
import startCase from 'lodash/startCase';
import Fuse from 'fuse.js';
import { InputAdornment, Stack } from '@mui/material';
import { Select, TextField } from '@src/components/sc-design-system';
import SearchIcon from '@mui/icons-material/Search';

interface FuzzySearchBarProps {
  params: string[];
  paramKey: string;
  emptyParam?: string;
  data: any[];
  searchKeys: string[];
  placeholder?: string;
  onFilter: Function;
}

const FuzzySearchBar: FC<FuzzySearchBarProps> = ({
  paramKey,
  params,
  emptyParam = 'All',
  data,
  searchKeys,
  placeholder = '',
  onFilter,
}) => {
  const [query, setQuery] = useState('');
  const [paramValue, setParamValue] = useState(emptyParam);
  const formattedParams = useMemo(
    () =>
      [emptyParam, ...params].map(p => ({
        label: startCase(p),
        value: p,
      })),
    [emptyParam, params],
  );

  const filteredByParam = useMemo(() => {
    return paramValue === emptyParam
      ? data
      : data.filter(d => d[paramKey] === paramValue);
  }, [paramValue, emptyParam, paramKey, data]);

  const filteredData = useMemo(() => {
    const fuse = new Fuse(filteredByParam, {
      keys: searchKeys,
      minMatchCharLength: 3,
      ignoreLocation: true,
    });

    return query
      ? fuse.search(query)?.map(({ item }) => item)
      : filteredByParam;
  }, [filteredByParam, query, searchKeys]);

  useEffect(() => {
    onFilter(filteredData);
  }, [onFilter, filteredData]);

  return (
    <Stack direction='row' alignItems='center' sx={{ marginBottom: '2em' }}>
      <Select
        sx={{
          borderRadius: '24px 0 0 24px',
          margin: 0,
          backgroundColor: 'grey.50',
          width: '150px',
        }}
        defaultValue={emptyParam}
        value={paramValue}
        size='small'
        onChange={event => setParamValue(event.target.value as string)}
        options={[{ options: formattedParams }]}
      />
      <TextField
        fullWidth
        size='small'
        id='search'
        placeholder={placeholder}
        value={query}
        onChange={event => {
          setQuery(event.target.value as string);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon fontSize='small' />
            </InputAdornment>
          ),
          sx: {
            borderRadius: '0 24px 24px 0',
            backgroundColor: 'grey.50',
            fieldset: { borderLeftWidth: '0' },
          },
        }}
      />
    </Stack>
  );
};

export { FuzzySearchBar };
