import obey from 'obey';
import { v4 as uuidv4 } from 'uuid';

obey.modifier('forceString', val => (val ? val.toString() : 0));

obey.creator('uuid', () => uuidv4());

export const QuestionSchema = {
  id: { type: 'string', required: true, creator: 'uuid' },
  label: { type: 'string', required: true },
  description: { type: 'string', empty: true },
  type: {
    type: 'string',
    allow: [
      'string',
      'text',
      'number',
      'boolean',
      'range',
      'array',
      'select-single',
      'select-multiple',
    ],
    required: true,
    default: 'string',
  },
  config: {
    type: 'object',
    keys: {
      range_maximum: { type: 'number', default: 10 },
      range_labels: {
        type: 'array',
        values: {
          type: 'object',
          keys: {
            value: { type: 'number' },
            text: { type: 'string' },
          },
        },
      },
      options: {
        type: 'array',
        empty: true,
        default: [],
        values: {
          type: 'object',
          keys: {
            label: { type: 'string' },
            value: { type: 'any', modifier: 'forceString' },
          },
        },
      },
    },
  },
};

// eslint-disable-next-line no-unused-vars
export type QuestionType = { [K in keyof typeof QuestionSchema]?: any };

export const QuestionModel = obey.model(QuestionSchema);
