import { computed, makeObservable, observable, toJS } from 'mobx';

export const addressProps = [
  'type',
  'street_address_1',
  'street_address_2',
  'city',
  'state',
  'country',
  'zip',
];

export default class Address {
  type: string;
  street_address_1: string;
  street_address_2?: string;
  city: string;
  state: string;
  country: string;
  zip: string;

  constructor(address = {}) {
    makeObservable(this, {
      type: observable,
      street_address_1: observable,
      street_address_2: observable,
      city: observable,
      state: observable,
      country: observable,
      zip: observable,
      // computeds
      data: computed,
      isValid: computed,
    });

    if (address && Object.keys(address).length) {
      Object.keys(address).forEach(prop => {
        this[prop] = address[prop];
      });
    }
  }

  get isValid() {
    return (
      this.type &&
      this.type.length > 0 &&
      this.street_address_1 &&
      this.street_address_1.length > 0 &&
      this.city &&
      this.city.length > 0 &&
      this.state &&
      this.state.length === 2 &&
      this.zip &&
      this.zip.toString().length === 5
    );
  }

  get data() {
    return JSON.parse(
      JSON.stringify(
        addressProps.reduce((acc: object, prop: string) => {
          if (prop === 'zip') {
            acc[prop] =
              this[prop] && this[prop] ? this[prop].toString() : undefined;
          } else {
            acc[prop] = this[prop] ? toJS(this[prop]) : undefined;
          }
          return acc;
        }, {}),
      ),
    );
  }
}
